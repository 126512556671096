import React from 'react'
import { Portal } from '../Portal/Portal'
import type { AnchorProps } from '../_hooks/Anchor'
import { Anchor } from '../_hooks/Anchor'

export interface OverlayProps extends AnchorProps {
  /**
   * @since 10.19.0
   */
  shrinkOverlay?: boolean
  /**
   * @since 10.19.0
   */
  container?: HTMLElement

  arrow?: boolean
}

/**
 * @deprecatedSince 11
 * @deprecated Intended for internal library development.
 */
export const Overlay = React.forwardRef<HTMLElement, OverlayProps>(
  function Overlay(
    {
      canFlip = true,
      children,
      padding = 2,
      placement = 'bottom',
      shrinkOverlay = false,
      targetRef,
      container,
      isFlyout,
    },
    overlayRef
  ) {
    const ref =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      (overlayRef as React.RefObject<HTMLElement>) || React.useRef(null)

    return (
      <Portal container={container}>
        <Anchor
          {...{
            canFlip,
            dependencies: [children],
            onLayout: (anchor) => {
              if (!ref.current) {
                return
              }

              const left = Math.floor(anchor.left)
              const top = Math.floor(anchor.top)

              ref.current.style.transform = `translateZ(0) translate(${left}px, ${top}px)`
            },
            overlayRef: ref,
            padding,
            placement,
            targetRef,
            shrinkOverlay,
            isFlyout,
          }}
        >
          {children}
        </Anchor>
      </Portal>
    )
  }
)
