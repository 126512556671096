import thumbnailLg1 from '../../images/thumbnail/thumb_lg_01.png'
import thumbnailLg2 from '../../images/thumbnail/thumb_lg_02.png'
import thumbnailLg3 from '../../images/thumbnail/thumb_lg_03.png'
import thumbnailLg4 from '../../images/thumbnail/thumb_lg_04.png'
import thumbnailSm1 from '../../images/thumbnail/thumb_sm_01.png'
import thumbnailSm2 from '../../images/thumbnail/thumb_sm_02.png'
import thumbnailSm3 from '../../images/thumbnail/thumb_sm_03.png'
import thumbnailSm4 from '../../images/thumbnail/thumb_sm_04.png'

export const EXAMPLE_IMAGES = {
  sm: [thumbnailSm1, thumbnailSm2, thumbnailSm3, thumbnailSm4],
  lg: [thumbnailLg1, thumbnailLg2, thumbnailLg3, thumbnailLg4],
}
