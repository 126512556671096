import type { Rect } from './dom'
import { defaultRect } from './dom'
import type { Placement } from './types'

interface Components {
  align: Placement
  edge: Placement
}

type Validator = (container: Rect, target: Rect, overlay: Rect) => boolean
type Updater = (target: Rect, overlay: Rect) => object
type ValidatorMap = { [s: string]: Validator }
type UpdaterMap = { [s: string]: Updater }
type PlacementMap = { [s: string]: Placement }

interface ComponentUpdater {
  validator: ValidatorMap
  updater: UpdaterMap
}

interface Config {
  container: Rect
  target: Rect
  overlay: Rect
  canFlip?: boolean
  placement: Placement
}

const optionHeight = 36

// UTILS

function getComponents(placement: Placement): Components {
  const [primary, secondary] = placement.split('-')
  const edge = primary as Placement
  const align = (secondary as Placement) || 'center'
  return { edge, align }
}

function fromComponents({ align, edge }: Components): Placement {
  return align === 'center' ? edge : (`${edge}-${align}` as Placement)
}

function getContainer(): Rect {
  return {
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    bottom: window.innerWidth,
    right: window.innerWidth,
  }
}

function flip(current: Placement) {
  const opposite: PlacementMap = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
    center: 'center',
  }

  return opposite[current]
}

// GENERIC POSITION UPDATER (Edge, Align)
// Flip updater if validator fails and opposite passes
function getPositionComponent({ validator, updater }: ComponentUpdater) {
  return ({
    container,
    target,
    overlay,
    placement: current,
    canFlip = true,
  }: Config) => {
    const opposite = flip(current)

    const shouldFlip =
      !validator[current](container, target, overlay) &&
      validator[opposite](container, target, overlay)

    const placement = canFlip && shouldFlip ? opposite : current

    return {
      placement,
      ...updater[placement](target, overlay),
    }
  }
}

// PRIMARY
const Edge: ComponentUpdater = {
  updater: {
    left: (target, overlay) => ({
      left: target.left - overlay.width,
    }),
    right: (target, overlay) => ({ left: target.right }),
    top: (target, overlay) => ({
      top: target.top - overlay.height,
    }),
    bottom: (target, overlay) => ({
      top: target.bottom,
    }),
  },
  validator: {
    top: (container, target, overlay) =>
      target.top - container.top >= overlay.height,
    left: (container, target, overlay) =>
      target.left - container.left >= overlay.width,
    bottom: (container, target, overlay) =>
      container.height - target.bottom >= overlay.height,
    right: (container, target, overlay) =>
      container.width - target.right >= overlay.width,
  },
}

// SECONDARY
export const Alignment: ComponentUpdater = {
  updater: {
    left: (target, overlay) => ({ left: target.left }),
    right: (target, overlay) => ({ left: target.right - overlay.width }),
    top: (target, overlay) => ({ top: target.top }),
    bottom: (target, overlay) => ({ top: target.bottom - overlay.height }),
    center: (target, overlay) => ({
      left: target.left + (target.width - overlay.width) / 2,
      top: target.top + (target.height - overlay.height) / 2,
    }),
  },
  validator: {
    top: (container, target, overlay) =>
      container.height - target.top >= overlay.height,
    right: (container, target, overlay) =>
      target.right - container.left >= overlay.width,
    left: (container, target, overlay) =>
      container.width - target.left >= overlay.width,
    bottom: (container, target, overlay) =>
      target.bottom - container.top >= overlay.height,
    center: () => true,
  },
}

export const getAlignmentPosition = getPositionComponent(Alignment)
export const getEdgePosition = getPositionComponent(Edge)
export const getFlyoutAlignmentPosition = getPositionComponent({
  updater: {
    ...Alignment.updater,
    left: (target, overlay) => ({ left: target.right }),
    right: (target, overlay) => ({ left: target.left - overlay.width }),
    center: (target, overlay) => ({
      left: target.right,
      top: target.top + (target.height - overlay.height) / 2,
    }),
  },
  validator: Alignment.validator,
})

export const getFlyoutEdgePosition = getPositionComponent({
  updater: {
    ...Edge.updater,
    bottom: (target, overlay) => ({ top: target.bottom - optionHeight }), // Aligns to height of menu option
  },
  validator: Edge.validator,
})

export const padding = (pad: number = 0, shape: Rect) => ({
  ...shape,
  width: shape.width + pad * 2,
  height: shape.height + pad * 2,
  top: shape.top - pad,
  bottom: shape.bottom + pad,
  left: shape.left - pad,
  right: shape.right + pad,
})

export function getAnchorPosition(
  placement: Placement,
  pad: number = 0,
  container: Rect = getContainer(),
  target: Rect,
  overlay: Rect,
  canFlip: boolean = true,
  isFlyout: boolean
): any {
  if (!target || !overlay) {
    return defaultRect
  }

  let components = getComponents(placement)
  const defaultConfig = {
    canFlip,
    container,
    target,
    overlay,
    placement,
  }

  const isFlyoutDefaultButton =
    target.width === 24 || target.width === 36 || target.width === 48
  const isFlyoutAdjustment = isFlyout && !isFlyoutDefaultButton

  const align = isFlyoutAdjustment
    ? getFlyoutAlignmentPosition({
        ...defaultConfig,
        placement: components.align,
      })
    : getAlignmentPosition({
        ...defaultConfig,
        placement: components.align,
      })

  const edge = isFlyoutAdjustment
    ? getFlyoutEdgePosition({
        ...defaultConfig,
        target: padding(pad, target),
        placement: components.edge,
      })
    : getEdgePosition({
        ...defaultConfig,
        target: padding(pad, target),
        placement: components.edge,
      })

  return {
    ...align,
    ...edge,
    placement: fromComponents({ align: align.placement, edge: edge.placement }),
    minWidth: target.width,
  }
}
