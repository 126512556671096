import type { GridBreakpoint } from './Grid.types'

export const breakpointRawMinWidthValues: Record<GridBreakpoint, number> = {
  mobileLg: 0,
  tabletSm: 576,
  tabletMd: 768,
  tabletLg: 992,
  desktopSm: 1200,
  desktopMd: 1400,
}

export const breakpointMinWidthValues: Record<GridBreakpoint, string> = {
  mobileLg: `${breakpointRawMinWidthValues.mobileLg}px`,
  tabletSm: `${breakpointRawMinWidthValues.tabletSm}px`,
  tabletMd: `${breakpointRawMinWidthValues.tabletMd}px`,
  tabletLg: `${breakpointRawMinWidthValues.tabletLg}px`,
  desktopSm: `${breakpointRawMinWidthValues.desktopSm}px`,
  desktopMd: `${breakpointRawMinWidthValues.desktopMd}px`,
}

export const breakpointsSmallToLarge: GridBreakpoint[] = [
  'mobileLg',
  'tabletSm',
  'tabletMd',
  'tabletLg',
  'desktopSm',
  'desktopMd',
]
