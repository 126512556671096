import type { Id } from '../TieredSelect/TieredSelect.types'

export const empty: any[] = []

export const hideKeys = {
  overlay: ['Escape', 'Esc'],
  target: ['Escape', 'Esc'],
}

export function defaultGetGroupId<
  T extends {
    groupId: Id
  }
>(tier: T) {
  return tier.groupId
}

export function defaultGetId<
  T extends {
    id: Id
  }
>(tier: T) {
  return tier.id
}

export function defaultGetLabel<T extends { label: string }>(tier: T) {
  return tier.label
}

export function defaultGetNextGroupId<T extends { nextGroupId?: Id }>(tier: T) {
  return tier.nextGroupId
}

export function defaultIsTierDisabled<T extends { disabled?: boolean }>(
  tier: T
) {
  return Boolean(tier.disabled)
}

export function noop() {}

export function always() {
  return true
}

/**
 * Helper function to find path in tiered structure
 * @param optionsByNextGroupId
 * @param item
 * @param getGroupId
 * @param getNextGroupId
 * @returns
 */
export function findPath<T>(
  optionsByNextGroupId: Record<string | number, any>,
  item: T,
  getGroupId: (t: T) => Id
) {
  const path: T[] = [item]
  let currentItem = item

  while (getGroupId(currentItem)) {
    const groupId = getGroupId(currentItem)
    if (groupId) {
      const parent = optionsByNextGroupId[groupId]
      path.push(parent)
      currentItem = parent
    } else {
      break
    }
  }

  return path.reverse()
}

/**
 * Helper function to generate a path key
 * @param path
 * @param getLabel
 * @returns
 */
export function getPathKey<T>(
  path: T[] = [],
  getLabel: (tier: T) => string | undefined
) {
  return path.map((node) => getLabel(node)).join(' > ')
}
