export function addSubcomponents<
  Subcomponents extends { [key: string]: any },
  Parent extends Record<string, any>
>(subs: Subcomponents, parent: Parent) {
  const assigned = parent as Record<string, any>

  Object.keys(subs).forEach((key) => {
    assigned[key] = subs[key]
  })

  return assigned as Parent & Subcomponents
}
