import React, { useImperativeHandle, useRef } from 'react'
import { Button } from '../../Button/Button'
import { useDropzone } from '../../Dropzone/Dropzone.hooks'
import type { DropzoneContentProps } from '../../Dropzone/Dropzone.types'
import { dropErrors } from '../../Dropzone/Dropzone.types'
import { Flex } from '../../Flex/Flex'
import { Tooltip } from '../../Tooltip/Tooltip'
import { useI18nContext } from '../../_hooks/I18n'
import {
  dropzoneContainerAttr,
  StyledDropzone,
  StyledDropzoneMessage,
  StyledUploadButtonWrapper,
} from './FileSelectDropzone.styles'
import type {
  FileSelectDropzoneContentProps,
  FileSelectDropzoneProps,
  FileSelectDropzoneRef,
} from './FileSelectDropzone.types'

const DefaultContent = React.forwardRef<
  HTMLButtonElement,
  FileSelectDropzoneContentProps
>(function DefaultContent(
  {
    disabled,
    errorMessage,
    hideDropzone = false,
    multiple,
    onAttachFromProject,
    tooltip,
    qa,
  },
  ref
) {
  const I18n = useI18nContext()

  const attachFilesButton = (
    <Tooltip
      trigger={hideDropzone && tooltip ? 'hover' : 'none'}
      placement="right"
      overlay={tooltip}
    >
      {/* this wrapper is required for the tooltip to work with disabled button */}
      <StyledUploadButtonWrapper>
        <Button
          ref={ref}
          variant="secondary"
          onClick={onAttachFromProject}
          disabled={disabled}
          data-qa={qa?.attachFiles}
        >
          {I18n.t('core.fileAttacher.attachFiles', {
            count: multiple ? Infinity : 1,
          })}
        </Button>
      </StyledUploadButtonWrapper>
    </Tooltip>
  )

  return hideDropzone ? (
    attachFilesButton
  ) : (
    <Flex direction="column" justifyContent="center" alignItems="center">
      {attachFilesButton}
      <StyledDropzoneMessage error={Boolean(errorMessage)}>
        {errorMessage || I18n.t('core.dropzone.dragAndDrop')}
      </StyledDropzoneMessage>
    </Flex>
  )
})

export const FileSelectDropzone = React.forwardRef<
  FileSelectDropzoneRef,
  FileSelectDropzoneProps
>(function FileSelectDropzone(
  {
    accept,
    maxFileNumber,
    maxFileSize,
    minFileSize,
    disabled,
    value,
    onDrop,
    multiple,
    noDrag,
    onAttachFromProject,
    contentRenderer,
    hideDropzone = false,
    tooltip,
    qa,
  },
  ref
) {
  const dropzoneState = useDropzone({
    accept,
    maxFileNumber,
    maxFileSize,
    minFileSize,
    disabled,
    value,
    onDrop,
    multiple,
    noDrag,
  })

  const attachButtonRef = useRef<HTMLButtonElement>(null)

  useImperativeHandle(ref, () => ({
    clearErrors: () => {
      dropzoneState.dispatchDropError(dropErrors.reset)
    },
    attachButton: attachButtonRef.current,
  }))

  const defaultContent = (props: DropzoneContentProps) => (
    <DefaultContent
      {...props}
      ref={attachButtonRef}
      tooltip={tooltip}
      hideDropzone={hideDropzone}
      multiple={multiple}
      onAttachFromProject={onAttachFromProject}
      qa={{ attachFiles: qa?.attachFiles }}
    />
  )

  const dropzoneContent = contentRenderer || defaultContent

  return (
    <StyledDropzone
      {...dropzoneState}
      tooltip={hideDropzone ? null : tooltip}
      contentRenderer={dropzoneContent}
      hidden={hideDropzone}
      rootProps={{ [dropzoneContainerAttr]: '' }}
      {...(hideDropzone ? { isIconVisible: false } : {})}
    />
  )
})

FileSelectDropzone.displayName = 'FileSelectDropzone'
