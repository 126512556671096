import React from 'react'

export const defaultZIndex = 9999

/* TYPES */

interface ZIndexConfig {
  initialValue?: number
}

interface ZIndexApi {
  increment: () => void
  value: number
}

interface Props extends ZIndexConfig {
  children?: React.ReactNode
}

interface RenderProps extends ZIndexConfig {
  children: (props: ZIndexApi) => {}
}

/* HOOK */

export function useZIndex({
  initialValue = defaultZIndex,
}: ZIndexConfig): ZIndexApi {
  const [value, setValue] = React.useState(initialValue)

  const increment = () => setValue(value + 1)

  return {
    increment,
    value,
  }
}

/* RENDER PROP */

/**
 * @deprecatedSince 11
 * @deprecated Please use hooks instead
 */
export function ZIndex({ children, ...props }: RenderProps) {
  const zIndex = useZIndex(props)

  return typeof children === 'function' ? children(zIndex) : children
}

/* CONTEXT */

const noop = (method: string) => () =>
  console.warn(
    `Could not call \`${method}\`, please wrap your component in a \`<ZIndexProvider>\``
  )

export const ZIndexContext = React.createContext<ZIndexApi>({
  increment: noop('increment'),
  value: defaultZIndex,
})

export function ZIndexProvider({ children, ...props }: Props) {
  return (
    <ZIndexContext.Provider value={useZIndex(props)}>
      {children}
    </ZIndexContext.Provider>
  )
}

export function useZIndexContext() {
  const ctx = React.useContext(ZIndexContext)

  ctx.value++

  return ctx
}
