import 'normalize.css' // Is this actually loading?
import * as React from 'react'
import 'reset-css' // Is this actually loading?
import styled, { createGlobalStyle } from 'styled-components'
import ActionLogger from '../partials/ActionLogger/ActionLogger'
import { SEO } from '../partials/SEO'

const GlobalStyle = createGlobalStyle`
  html body {
    font-family: Inter, Noto, Arial, sans-serif;
    height: 100vh;
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
    width: 100vw;
  }

  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  dl,
  dd,
  ol,
  ul,
  form,
  fieldset,
  legend,
  figure,
  table,
  th,
  td,
  caption,
  hr {
    margin: 0;
    padding: 0;
  }

  body {
    color: black;
  }
`

const LayoutView = styled.div`
  background: #f1f3f3;
  animation: fade-in 0.5s;
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export default ({ children }: any) => {
  return (
    <LayoutView>
      <GlobalStyle />
      <SEO />
      {children}
      <ActionLogger />
    </LayoutView>
  )
}
