import { useLocation } from '@reach/router'
import { graphql, Link, useStaticQuery } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import { useAuth0 } from '../utils/auth'
import { colors, layout } from '../utils/styles'

interface Page {
  id: string
  fields: {
    parentPath: string
    path: string
    slug: string
    slugs: string[]
    tags: string[]
  }
  frontmatter: {
    title: string
    sidebar: boolean
    private: boolean
  }
}

interface SidebarQueryResult {
  allMdx: {
    nodes: Page[]
  }
}

function isLinkActive(className: string) {
  return function ({ isCurrent, isPartiallyCurrent }: GetPropsObj) {
    if (isCurrent || isPartiallyCurrent)
      return { className: `${className} active` }
    return { className }
  }
}

const MenuItemLink: React.FC<{
  page: Page
  depth: number
  className?: string
}> = function MenuLink({ className = '', page, depth = 0 }) {
  const title = page.frontmatter.title

  return (
    <Link to={page.fields.path} getProps={isLinkActive(className)}>
      {title}
    </Link>
  )
}

const SidebarView = styled.div`
  padding: ${layout.pagePadding}px;
`

const MenuView = styled.div<{ depth: number }>`
  margin: ${(props) => (props.depth === 1 ? '8px 0' : 0)};
  display: ${(props) => (props.depth > 0 ? 'none' : 'block')};
`

const MenuItemView = styled(MenuItemLink)`
  align-items: center;
  border-left: ${(props) => (props.depth > 0 ? 4 : 0)}px solid ${colors.e6e6e6};
  color: ${colors._202020};
  cursor: pointer;
  display: flex;
  height: 32px;
  font-size: 14px;
  padding-left: ${(props) => (props.depth || 0) * 20}px;
  text-decoration-line: none;
  user-select: none;
  &.active {
    color: ${colors._006ddf};

    + ${MenuView} {
      display: block;
    }
  }

  &.active {
    border-left-color: ${colors._006ddf};
  }
`

function trimSlashes(str: string) {
  return str.replace(/^\/|\/$/g, '')
}

export const Sidebar = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  const { pathname } = useLocation()

  const { isAuthenticated } = useAuth0()

  const { allMdx } = useStaticQuery<SidebarQueryResult>(
    graphql`
      query SidebarQuery {
        allMdx(
          sort: {
            order: ASC
            fields: [
              frontmatter___priority
              fields___parentPath
              frontmatter___title
            ]
          }
          filter: {
            frontmatter: { hidden: { ne: true }, sidebar: { ne: false } }
          }
        ) {
          nodes {
            fields {
              parentPath
              path
              slug
              slugs
              tags
            }
            frontmatter {
              hidden
              title
              redirect
              sidebar
              private
            }
          }
        }
      }
    `
  )

  const pathArr = trimSlashes(pathname).split('/')
  const section = // for example: web
    process.env.NODE_ENV === 'development'
      ? pathArr[0] // in development we don't prefix paths with version (localhost:8000/web/introduction/)
      : pathArr[1] // in production we do prefix paths with version (core.procore.com/latest/web/introduction/)

  const pages: Page[] = allMdx.nodes.filter((node) => {
    // Case 1: This page belongs to another section
    if (node.fields.slugs[0] !== section) return false

    // Case 2: If user is not logged in, only show page link if it's not private
    if (!isAuthenticated) return !node.frontmatter.private

    // Case 3: The page is in this section and the user is logged in
    return true
  })

  return (
    <SidebarView ref={ref} {...props}>
      <Menu
        allPages={pages}
        parentPath={`/${section}/`}
        parentSlugs={[section]}
      />
    </SidebarView>
  )
})

interface GetPropsObj {
  isCurrent: boolean
  isPartiallyCurrent: boolean
  location: {
    pathname: string
  }
  href: string
}

const Menu: React.FC<{
  allPages: Page[]
  parentPath: string
  parentSlugs: string[]
  depth?: number
}> = function Menu({ allPages, parentPath, parentSlugs, depth = 0 }) {
  function getChildren(parentPath: string, parentSlugs: string[]): Page[] {
    return allPages.filter((p) => {
      return (
        p.fields.path.startsWith(parentPath) &&
        p.fields.path.length > parentPath.length &&
        p.fields.slugs.length === parentSlugs.length + 1 &&
        p.frontmatter?.sidebar !== false
      )
    })
  }

  const pages = getChildren(parentPath, parentSlugs)

  return (
    <MenuView depth={depth}>
      {pages.map((page) => {
        const children = getChildren(page.fields.path, page.fields.slugs)

        return (
          <div key={page.fields.path}>
            <MenuItemView depth={depth} page={page} />
            {children.length > 0 && (
              <Menu
                allPages={allPages}
                parentPath={page.fields.path}
                parentSlugs={page.fields.slugs}
                depth={depth + 1}
              />
            )}
          </div>
        )
      })}
    </MenuView>
  )
}
