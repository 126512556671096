import type { Scope } from '../_hooks/EventListener'
import { useEventListener } from '../_hooks/EventListener'
import type { TriggerVariant } from '../_hooks/Trigger'

interface Config {
  value?: boolean
  off: (event: any) => any
  on: (event: any) => any
  trigger: TriggerVariant | TriggerVariant[]
  scope: Scope
}

function noop() {}

const availableTriggers = [
  'click',
  'hover',
  'none',
  'contextmenu',
  'focus',
  'always',
]

export function triggerListeners({ value, off, on, trigger, scope }: Config) {
  const targetTriggers = Array.isArray(trigger) ? trigger : [trigger]

  const handlerOrNoop = (fn: (event: any) => any, trigger: TriggerVariant) => {
    const isTargetTrigger = targetTriggers.includes(trigger)
    return {
      handler: isTargetTrigger ? fn : noop,
      noopHandler: !isTargetTrigger,
    }
  }

  availableTriggers.forEach((trigger) => {
    switch (trigger) {
      case 'click':
        const handler = handlerOrNoop(value ? off : on, trigger)
        useEventListener({
          dependencies: [handler],
          event: 'click',
          scope,
          ...handler,
        })
        break

      case 'contextmenu':
        const ctxMenuHandler = handlerOrNoop(value ? off : on, trigger)
        useEventListener({
          dependencies: [ctxMenuHandler],
          event: 'contextmenu',
          scope,
          ...ctxMenuHandler,
        })
        break

      case 'hover':
        const mouseLeaveHandler = handlerOrNoop(off, trigger)
        useEventListener({
          event: 'mouseleave',
          scope,
          ...mouseLeaveHandler,
        })
        const mouseEnterHandler = handlerOrNoop(on, trigger)
        useEventListener({
          event: 'mouseenter',
          scope,
          ...mouseEnterHandler,
        })
        break

      case 'focus':
        const blurHandler = handlerOrNoop(off, trigger)
        useEventListener({
          event: 'blur',
          scope,
          ...blurHandler,
        })

        const focusHandler = handlerOrNoop(on, trigger)
        useEventListener({
          event: 'focus',
          scope,
          ...focusHandler,
        })
        break
    }
  })
}
