import styled from 'styled-components'
import { Button } from '../Button'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { getShadow } from '../_styles/shadows'
import { spacing } from '../_styles/spacing'
import type { ToastVariant } from './Toast.types'

export const StyledToast = styled.div<{ $variant: ToastVariant }>`
  ${getShadow(4, 'bottom')}

  align-items: center;
  background-color: ${({ $variant }) =>
    $variant === 'success' ? colors.green30 : colors.red50};
  border-radius: ${borderRadius.md}px;
  color: ${colors.white};
  display: flex;
  min-height: 48px;
  padding: ${spacing.md}px ${spacing.xl}px;
  width: 550px;
`

// $legacy is direct string inside Toast. Next is with subcomponents
export const StyledToastText = styled.span<{ $legacy?: boolean }>`
  ${getTypographyIntent('body')}
  font-weight: ${typographyWeights.semibold};
  overflow: hidden;

  ${({ $legacy }) =>
    $legacy ? `margin-left: ${spacing.lg}px;` : `padding: 0 ${spacing.lg}px;`}
`

export const StyledToastButton = styled(Button)`
  line-height: 0;
  color: ${colors.white};
  padding: 0;
  margin-left: auto;
`
