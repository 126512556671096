import React from 'react'
import type { InputValue, UseNumberFormatConfig } from './NumberInput.types'
import {
  defaultLocale,
  format,
  getLengthInfo,
  verifyDecimalScale,
} from './NumberInput.utils'

export function usePrevious<T>(value: T) {
  const ref = React.useRef<T>()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current as T
}

export function useNumberFormat({
  decimalScale: _decimalScale = true,
  fillDecimalScale = 'none',
  locale: _locale = defaultLocale,
}: UseNumberFormatConfig) {
  const locale = React.useMemo(
    () =>
      [
        ...(Array.isArray(_locale) ? _locale : [_locale]),
        defaultLocale,
      ] as string[],
    [_locale]
  )

  const { fixedDecimalScale, fillDecimalScaleOnBlur } = React.useMemo(
    () => ({
      fixedDecimalScale: fillDecimalScale === 'always',
      fillDecimalScaleOnBlur: fillDecimalScale === 'onBlur',
    }),
    [fillDecimalScale]
  )

  const decimalScale = React.useMemo(
    () => verifyDecimalScale(_decimalScale),
    [_decimalScale]
  )

  const formatValue = React.useCallback(
    (value: InputValue) =>
      format(locale, decimalScale, fillDecimalScale, value),
    [locale, decimalScale, fillDecimalScale]
  )

  const getValueLengthInfo = React.useCallback(
    (value: InputValue, isNumericString = false) =>
      getLengthInfo(
        locale,
        value,
        decimalScale,
        fillDecimalScale,
        isNumericString
      ),
    [locale, decimalScale, fillDecimalScale]
  )

  return {
    formatValue,
    getValueLengthInfo,
    locale,
    fixedDecimalScale,
    fillDecimalScaleOnBlur,
    decimalScale,
  }
}
