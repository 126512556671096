import styled from 'styled-components'
import { Dropzone, DropzoneErrorBanner } from '../../Dropzone/Dropzone'
import { colors } from '../../_styles/colors'
import { mediaIE11Hack } from '../../_styles/media'
import { spacing } from '../../_styles/spacing'
import { ThumbnailList } from '../ThumbnailList/ThumbnailList'

export const uploaderHeight = 411
export const dropzoneHeight = 96
export const errorBannerMarginTop = spacing.lg
export const errorBannerMarginBottom = 3
export const listMarginTop = spacing.sm
export const listBorderWidth = 1

const contentHeight = uploaderHeight + 2 * spacing.xl
const dropzoneHeightWithIcon = 176
const sourceWidth = 568

export const StyledLocalSource = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  height: ${contentHeight}px;
  max-height: ${contentHeight}px;
  padding: ${spacing.xl}px ${spacing.xl + spacing.lg}px ${spacing.xl}px
    ${spacing.xl}px;
  overflow-y: hidden;
`

export const StyledDropzoneErrorBanner = styled(DropzoneErrorBanner)`
  // Preserve element's shadows
  margin-top: ${errorBannerMarginTop}px;
  margin-bottom: ${errorBannerMarginBottom}px;
  margin-right: 2px;
`

export const StyledDropzoneWrapper = styled(Dropzone)`
  display: flex;
  height: auto;
  min-height: ${({ isIconVisible }) =>
    isIconVisible ? dropzoneHeightWithIcon : dropzoneHeight}px;

  > div:first-child {
    display: flex;
    height: auto;
  }

  // hide the default error banner
  > ${DropzoneErrorBanner} {
    display: none;
  }

  @media ${mediaIE11Hack} {
    width: ${sourceWidth}px;

    > div:first-child {
      height: 100%;
    }
  }
`

export const StyledThumbnailList = styled(ThumbnailList)`
  margin-top: ${listMarginTop}px;
  border: ${listBorderWidth}px solid ${colors.gray85};
`
