import { Clear } from '@procore/core-icons/dist'
import { useId } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { clamp, isNil } from 'ramda'
import React from 'react'
import type {
  FixedSizeList as List,
  ListChildComponentProps,
} from 'react-window'
import { ProgressBar } from '../../ProgressBar/ProgressBar'
import { useI18nContext } from '../../_hooks/I18n'
import type { DivAttributes } from '../../_utils/types'
import {
  StyledDeleteIcon,
  StyledProgress,
  StyledThumbnail,
  StyledThumbnailList,
  StyledThumbnailListContainer,
  StyledThumbnailListItem,
} from './ThumbnailList.styles'
import type { ThumbnailListProps } from './ThumbnailList.types'

export const defaultListHeight = 305
export const defaultRowHeight = 56

const progressValueText = ({ value }: { value: number }) => `${value}%`

const Row = ({
  data: { items, onCancel, isFocused, setNavigationPosition },
  style,
  index,
}: ListChildComponentProps) => {
  const i18n = useI18nContext()
  const progressBarLabelId = useId()
  const item = items[index]
  const { src, name, progress, error } = item
  const focused = isFocused(index)
  const ariaLive = error ? 'assertive' : undefined

  return (
    <div
      style={style}
      onMouseEnter={() => setNavigationPosition(index)}
      aria-live={ariaLive}
    >
      <StyledThumbnailListItem error={error} focused={focused}>
        <StyledThumbnail
          src={src}
          size="sm"
          clickable={false}
          caption={name}
          error={error}
        />
        <StyledProgress>
          {error ||
            (!isNil(progress) && (
              <>
                <VisuallyHidden>
                  <span id={progressBarLabelId}>
                    {i18n.t('core.thumbnailList.uploading')}
                  </span>
                </VisuallyHidden>
                <ProgressBar
                  aria-labelledby={progressBarLabelId}
                  value={progress}
                  aria-valuetext={progressValueText}
                  animated
                />
              </>
            ))}
        </StyledProgress>
        {onCancel && (
          <StyledDeleteIcon
            aria-label={i18n.t('core.thumbnailList.removeUpload')}
            icon={<Clear size="sm" />}
            size="sm"
            tabIndex={-1}
            variant="tertiary"
            onClick={() => onCancel(item)}
          />
        )}
      </StyledThumbnailListItem>
    </div>
  )
}

// See https://github.com/bvaughn/react-window/issues/130
const outerElementType = React.forwardRef<HTMLDivElement, DivAttributes>(
  (props, ref) => <div ref={ref} tabIndex={-1} {...props} />
)

const getNewNavigationPosition = (next: number, max: number): number =>
  clamp(0, Math.max(0, max - 1), next)

export const ThumbnailList = React.forwardRef<
  HTMLDivElement,
  ThumbnailListProps
>(
  (
    {
      items,
      onCancel,
      listHeight = defaultListHeight,
      rowHeight = defaultRowHeight,
      onKeyDown,
      onFocus,
      onBlur,
      ...props
    },
    ref
  ) => {
    const { length: rowCount } = items
    const height = Math.min(rowCount * defaultRowHeight, listHeight)

    const listRef = React.useRef<List | null>(null)
    const listInstance = listRef.current
    const [isListFocused, setIsListFocused] = React.useState(false)

    const [navigationPosition, setNavigationPosition] = React.useState(0)

    const isFocused = React.useCallback(
      (index: number) => isListFocused && navigationPosition === index,
      [isListFocused, navigationPosition]
    )

    const updateNavigationPosition = React.useCallback(
      (newPosition: number) => {
        setNavigationPosition(newPosition)
        listInstance?.scrollToItem(newPosition)
      },
      [listInstance]
    )

    React.useEffect(() => {
      if (isListFocused) {
        listInstance?.scrollToItem(navigationPosition)
      }
    }, [isListFocused])

    React.useEffect(() => {
      if (rowCount > 0 && rowCount <= navigationPosition) {
        setNavigationPosition(rowCount - 1)
      }
      if (rowCount <= 0) {
        setIsListFocused(false)
      }
    }, [rowCount])

    const handleNavigation = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isListFocused) return

      switch (e.key) {
        case 'Enter':
          e.preventDefault()

          const highlightedItem = items[navigationPosition]
          onCancel?.(highlightedItem)
          // Ensure scrolling only after the item was deleted
          setTimeout(() => {
            updateNavigationPosition(
              getNewNavigationPosition(navigationPosition, rowCount - 1)
            )
          }, 0)
          if (rowCount - 1 === 0) {
            setIsListFocused(false)
          }
          break
        case 'ArrowDown':
        case 'Down':
          e.preventDefault()
          updateNavigationPosition(
            getNewNavigationPosition(navigationPosition + 1, rowCount)
          )
          break
        case 'ArrowUp':
        case 'Up':
          e.preventDefault()
          updateNavigationPosition(
            getNewNavigationPosition(navigationPosition - 1, rowCount)
          )
          break
      }

      onKeyDown?.(e)
    }

    if (!rowCount) return null

    return (
      <StyledThumbnailListContainer
        tabIndex={rowCount !== 0 ? 0 : -1}
        onFocus={(e) => {
          setIsListFocused(true)
          onFocus?.(e)
        }}
        onBlur={(e) => {
          setIsListFocused(false)
          onBlur?.(e)
        }}
        onKeyDown={handleNavigation}
        {...props}
        ref={ref}
      >
        <StyledThumbnailList
          ref={listRef}
          width="100%"
          height={height}
          itemCount={rowCount}
          itemSize={rowHeight}
          itemData={{ items, onCancel, isFocused, setNavigationPosition }}
          style={{ minHeight: height }}
          outerElementType={outerElementType}
        >
          {Row}
        </StyledThumbnailList>
      </StyledThumbnailListContainer>
    )
  }
)

ThumbnailList.displayName = 'ThumbnailList'
