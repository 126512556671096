import { fadeInClassName, fadeOutClassName } from '../Modal/Modal.styles'
import {
  closingPhasesInOrder,
  openingPhasesInOrder,
} from './Tearsheet.constants'
import type { AnimationPhase, AnimationStatus } from './Tearsheet.types'

export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms))

function getIsPhaseInProgressOrComplete(
  targetPhase: AnimationPhase,
  currentPhase: AnimationPhase,
  status: AnimationStatus
) {
  if (currentPhase === 'disabled') {
    return false
  }

  if (status === 'opening') {
    return (
      openingPhasesInOrder.indexOf(targetPhase) <=
      openingPhasesInOrder.indexOf(currentPhase)
    )
  }

  if (status === 'closing') {
    return (
      closingPhasesInOrder.indexOf(targetPhase) <=
      closingPhasesInOrder.indexOf(currentPhase)
    )
  }

  return false
}

export function getScrimClassName(
  status: AnimationStatus,
  phase: AnimationPhase
) {
  if (status === 'open') {
    return fadeInClassName
  }

  if (status === 'closed') {
    return fadeOutClassName
  }

  if (status === 'closing') {
    return getIsPhaseInProgressOrComplete('scrim', phase, status)
      ? fadeOutClassName
      : fadeInClassName
  }

  if (status === 'opening') {
    return getIsPhaseInProgressOrComplete('scrim', phase, status)
      ? fadeInClassName
      : fadeOutClassName
  }
}

export function getAnimationState(
  targetPhase: AnimationPhase,
  currentPhase: AnimationPhase,
  status: AnimationStatus
): { $open: boolean; $opening?: boolean; $closing?: boolean } {
  if (status === 'open') {
    return { $open: true }
  }

  if (status === 'closed') {
    return { $open: false }
  }

  const inProgressOrComplete = getIsPhaseInProgressOrComplete(
    targetPhase,
    currentPhase,
    status
  )

  if (status === 'closing') {
    return {
      $open: !inProgressOrComplete,
      $closing: targetPhase === currentPhase,
    }
  }

  if (status === 'opening') {
    return {
      $open: inProgressOrComplete,
      $opening: targetPhase === currentPhase,
    }
  }

  return { $open: false }
}
