import React from 'react'

export function useResizeObserver<T extends Element = HTMLElement>(
  callback: ResizeObserverCallback
): (el: T | null) => void {
  const [el, setEl] = React.useState<T | null>(null)

  React.useEffect(() => {
    if (!el || !callback) {
      return
    }

    const resizeObserver = new ResizeObserver(callback)
    resizeObserver.observe(el)

    return () => {
      resizeObserver.disconnect()
    }
  }, [el, callback])

  return setEl
}
