import React from 'react'

const useLogger = () => {
  const [messages, setMessages] = React.useState<string[]>([])
  const push = (message: string) =>
    setMessages((currentMessages) => currentMessages.concat([message]))
  const pop = () => setMessages((currentMessages) => currentMessages.slice(1))

  return { messages, pop, push }
}

export interface LoggerRenderProps {
  logger: {
    log: (message: string, options: any) => void
  }
  messages: string[]
}

const Logger: React.FC = ({ children }) => {
  const { messages, push, pop } = useLogger()
  const log = (message: string, options: any = { hideDelay: 3000 }) => {
    push(message)
    setTimeout(pop, options.hideDelay)
  }

  return typeof children === 'function'
    ? children({ logger: { log }, messages })
    : children
}

export default Logger
