import React from 'react'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { StyledBadge, StyledBadgeLabel } from './Badge.styles'
import type { BadgeLabelProps, BadgeProps } from './Badge.types'

const Badge_ = React.forwardRef<HTMLDivElement, BadgeProps>((props, ref) => {
  return <StyledBadge ref={ref} {...props} />
})

const Label = React.forwardRef<HTMLSpanElement, BadgeLabelProps>(
  (props, ref) => {
    return <StyledBadgeLabel ref={ref} {...props} />
  }
)

Badge_.displayName = 'Badge'

Label.displayName = 'Badge.Label'

export const Badge = addSubcomponents(
  {
    Label,
  },
  Badge_
)
