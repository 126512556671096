import React from 'react'
import { StyledLink } from './Link.styles'
import type { LinkProps } from './Link.types'

/**

 Links provide users with quick references to relevant Procore or affiliate
 websites that will provide more contextual information to a page or feature.

 Links should not be used to reference a page or modal in Procore’s product.
 In that case, use a button.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-link--demo)

 @see [Design Guidelines](https://design.procore.com/link)

 */
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, ...props }, ref) => {
    return (
      <StyledLink {...props} ref={ref}>
        {children}
      </StyledLink>
    )
  }
)

Link.displayName = 'Link'
