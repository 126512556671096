import { ChevronDown, ChevronRight } from '@procore/core-icons/dist'
import cx from 'classnames'
import React from 'react'
import { ulid } from 'ulid'
import { Button } from '../Button/Button'
import { getHeadingComponent } from '../Semantic/Semantic'
import type { HeadingProps } from '../Semantic/Semantic.types'
import { Title } from '../Title/Title'
import {
  sectionClassnames,
  StyledSection,
  StyledSectionBanner,
  StyledSectionCollapseHeading,
  StyledSectionContentRegion,
  StyledSectionHeader,
  StyledSectionInner,
  StyledTitle,
} from './Section.styles'
import type {
  SectionContextAPI,
  SectionProps,
  SectionProviderProps,
} from './Section.types'

const isFunction = (obj: unknown): boolean => typeof obj === 'function'

// Header levels start at level 2
export const LevelContext = React.createContext(2)

const SectionContext = React.createContext<SectionContextAPI>({
  open: {},
  setSection: () => {},
  toggleSection: () => {},
})

export function Heading({
  level: level_,
  ...props
}: HeadingProps & { level?: number }) {
  const level = React.useContext(LevelContext)
  const H = getHeadingComponent(level_ || level)
  return <H {...props} />
}

export const SectionProvider = ({ children }: SectionProviderProps) => {
  const [open, setSectionsOpen] = React.useState<{
    [key: string]: boolean
  }>({})

  const toggleSection: SectionContextAPI['toggleSection'] = (id) =>
    setSectionsOpen((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))

  const setSection: SectionContextAPI['setSection'] = (id, open) =>
    setSectionsOpen((prev) => ({
      ...prev,
      [id]: open,
    }))

  return (
    <SectionContext.Provider
      value={{
        open,
        toggleSection,
        setSection,
      }}
    >
      {children}
    </SectionContext.Provider>
  )
}
/**
 * @deprecatedSince 11
 * @deprecated Intended for internal library development. See Panel.Section or DetailPage.Section
 */
export const Section = React.forwardRef<HTMLDivElement, SectionProps>(
  function Section(
    {
      actions,
      banner,
      children,
      className,
      expandId,
      heading,
      initialIsOpen = true,
      pills,
      subtext,
      assets,
      ...props
    },
    ref
  ) {
    const level = React.useContext(LevelContext)

    const sections = React.useContext(SectionContext)
    const toggleOpen = React.useCallback(() => {
      if (expandId) {
        sections.toggleSection(expandId)
      }
    }, [expandId])
    const open = expandId ? sections.open[expandId] : true

    React.useEffect(() => {
      if (expandId && open === undefined) {
        sections.setSection(expandId, initialIsOpen)
      }
    }, [])

    const { contentRegionId, headingTextId } = React.useMemo(() => {
      return {
        contentRegionId: `content-${ulid()}`,
        headingTextId: `heading-${ulid()}`,
      }
    }, [])

    const expandClosed = !open || !children
    const divide = level >= 3 || !heading
    const hasHeader = heading || actions

    return (
      <LevelContext.Provider value={level + 1}>
        <StyledSection
          ref={ref}
          className={cx(className, {
            [sectionClassnames.divide]: divide,
            [sectionClassnames.levelAnonymous]: !heading,
            [sectionClassnames.level(level)]: heading && level,
          })}
          {...props}
        >
          <StyledSectionInner>
            {banner && <StyledSectionBanner>{banner}</StyledSectionBanner>}

            {hasHeader ? (
              <StyledSectionHeader>
                {expandId ? (
                  <StyledSectionCollapseHeading
                    aria-labelledby={headingTextId}
                    aria-level={level}
                    role="heading"
                  >
                    <Button
                      aria-controls={contentRegionId}
                      aria-expanded={open}
                      aria-labelledby={headingTextId}
                      icon={open ? <ChevronDown /> : <ChevronRight />}
                      onClick={toggleOpen}
                      variant="tertiary"
                    />
                  </StyledSectionCollapseHeading>
                ) : null}
                <StyledTitle>
                  <Title.Text>
                    {heading && (
                      <Heading
                        aria-hidden={expandId ? true : false}
                        id={headingTextId}
                        // Pass level since Heading component renders
                        // under the incremented (level + 1) LevelProvider.
                        // Alternative, render Heading as a variable outside of return.
                        level={level}
                      >
                        {heading}
                      </Heading>
                    )}
                  </Title.Text>
                  <Title.Subtext>{subtext}</Title.Subtext>
                  <Title.Pills>{pills}</Title.Pills>
                  <Title.Actions>
                    {isFunction(actions)
                      ? (actions as Function)({ open })
                      : actions}
                  </Title.Actions>
                  <Title.Assets>{assets}</Title.Assets>
                </StyledTitle>
              </StyledSectionHeader>
            ) : null}
            <StyledSectionContentRegion
              aria-hidden={!open}
              aria-labelledby={heading ? headingTextId : undefined}
              id={contentRegionId}
              role="region"
              $closed={expandClosed}
              $noHeader={!hasHeader}
            >
              {open ? children : null}
            </StyledSectionContentRegion>
          </StyledSectionInner>
        </StyledSection>
      </LevelContext.Provider>
    )
  }
)

Section.displayName = 'Section'
