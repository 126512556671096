import styled, { css } from 'styled-components'
import { Button } from '../Button/Button'
import { colors } from '../_styles/colors'

export const StyledToggleButton = styled(Button)<{ $selected: boolean }>`

  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${colors.blue50};
      color: ${colors.white};

      &:hover {
        background-color: ${colors.blue40};
        color: ${colors.white};
      }

      &:focus {
        background-color: ${colors.blue50};
        color: ${colors.white};
      }

      &:disabled {
        background-color: ${colors.blue85};
        color: ${colors.white};
      }
    `}
}
`
