import React from 'react'
import { StyledToggleButton } from './ToggleButton.styles'
import type { ToggleButtonProps } from './ToggleButton.types'

/**

 A toggle button can be used when an element needs to remain active or open
 for extended periods of time. The affects of a toggle button should visibly
 change content on the page.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-togglebutton--demo)

 @see [Design Guidelines](https://design.procore.com/toggle-button)

 @a11y  @a11y Important: it is critical the label on a toggle does not change when its `selected` prop changes. Alternatively, if the design were to call for the button label to change from "Mute" to "Unmute," the `aria-pressed` prop should be set to `undefined`.

 @see https://www.w3.org/WAI/ARIA/apg/patterns/button/

 */
export const ToggleButton = React.forwardRef<
  HTMLButtonElement,
  ToggleButtonProps
>(function ToggleButton(
  { children, selected = false, icon, size, ...props },
  ref
) {
  return (
    <StyledToggleButton
      aria-pressed={selected}
      {...props}
      $selected={selected}
      icon={icon}
      size={size}
      variant="tertiary"
      ref={ref}
    >
      {children || null}
    </StyledToggleButton>
  )
})

ToggleButton.displayName = 'ToggleButton'
