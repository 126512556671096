import React from 'react'

export const useEventRegistry = () => {
  const registry = React.useRef<Record<string, Function[]>>({})

  const addListener = React.useCallback((e: string, cb: Function) => {
    const currentCbs = registry.current[e] ?? []
    currentCbs.push(cb)
    registry.current[e] = currentCbs

    return () => {
      const cbs = registry.current[e] ?? []
      registry.current[e] = cbs.filter((callback) => callback !== cb)
    }
  }, [])

  return { registry, addListener }
}
