import { css } from 'styled-components'

type BorderRadiusSize = keyof typeof borderRadius

export const borderRadius = {
  sm: 2,
  md: 4,
  lg: 4,
}

export function getBorderRadius(size: BorderRadiusSize) {
  return css`
    border-radius: ${borderRadius[size]}px;
  `
}
