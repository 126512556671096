import styled, { css } from 'styled-components'
import { StyledCheckboxLabel } from '../Checkbox/Checkbox.styles'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

export const StyledRequiredMark = styled.span<{ $showLabel: boolean }>`
  color: ${colors.red50};

  ${({ $showLabel }) => {
    if ($showLabel) {
      return
    }

    return css`
      ${getTypographyIntent('body')}
      font-weight: ${typographyWeights.semibold};
    `
  }}
`

export const StyledRequiredContainer = styled.span`
  ${getTypographyIntent('small')}

  ${StyledCheckboxLabel} > & {
    margin-left: ${spacing.xs}px;
  }
`

export const StyledRequiredText = styled.span`
  font-style: italic;
  color: ${colors.gray45};
  margin-left: 4px;
`
