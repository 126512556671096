import {
  AttentionBanner,
  Banner,
  ErrorBanner,
  InfoBanner,
  P,
} from '@procore/core-react'
import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'

interface NoteBannerProps {
  children: React.ReactNode
  variant: string
}

const BannerView = styled.div`
  margin: 16px 0;
`

const messages: { [key: string]: string } = {
  portals: 'This component uses Portals internally, which requires React 16',
  zLevel:
    'This component uses ZLevel context internally, which requires React 16',
}

export default function ({ children, variant }: NoteBannerProps) {
  if (variant == 'deletion') {
    return (
      <BannerView>
        <ErrorBanner>
          <Banner.Content>
            <Banner.Title>DO NOT USE</Banner.Title>
            <Banner.Body>
              <P>
                This has been deprecated and will be deleted in a future
                release. Please refrain from new implementations.
              </P>
              {children}
            </Banner.Body>
          </Banner.Content>
        </ErrorBanner>
      </BannerView>
    )
  }
  if (variant == 'i18n')
    return (
      <BannerView>
        <InfoBanner>
          <Banner.Content>
            <Banner.Body>
              See <Link to="/web/hooks/I18n/">the I18n documentation</Link> for
              more on Core's approach to translations
            </Banner.Body>
          </Banner.Content>
        </InfoBanner>
      </BannerView>
    )
  if (variant === 'new-date')
    return (
      <BannerView>
        <InfoBanner>
          <Banner.Content>
            <Banner.Title>
              Examples in California Standard Time Zone
            </Banner.Title>
            <Banner.Body>
              Static code samples using <code>new Date</code> were ran in
              California during winter when clocks 'fallback' to standard time
              PST -8, not daylight savings time PDT -7. If you run{' '}
              <code>new Date()</code> locally, it will be relative to your time
              zone.
            </Banner.Body>
          </Banner.Content>
        </InfoBanner>
      </BannerView>
    )
  if (variant === 'intl-polyfill')
    return (
      <BannerView>
        <AttentionBanner>
          <Banner.Content>
            <Banner.Title>IE11 Requires Time Zone polyfill</Banner.Title>
            <Banner.Body>
              Core React uses the brower's <code>Intl DateTimeFormat</code>
              APIs in tandem with{' '}
              <a href="https://www.npmjs.com/package/date-fns">
                date-fns
              </a> and{' '}
              <a href="https://www.npmjs.com/package/date-fns-tz">
                date-fns-tz
              </a>
              . In order to support IE11, client applications will require a{' '}
              <Link to="/web/guides/date-time#polyfill">
                polyfill to enable time zone features
              </Link>
              .
            </Banner.Body>
          </Banner.Content>
        </AttentionBanner>
      </BannerView>
    )
  if (variant === 'env-tz-logging')
    return (
      <BannerView>
        <InfoBanner>
          <Banner.Content>
            <Banner.Title>Time Zone Console Logging</Banner.Title>
            <Banner.Body>
              Enable time zone console logging by setting the environment
              variable <i>TZ_LOG</i> to <i>true</i>. This can be used during
              development to compare inputs and outputs of date shifting.
            </Banner.Body>
          </Banner.Content>
        </InfoBanner>
      </BannerView>
    )

  if (variant === 'tz-collab')
    return (
      <BannerView>
        <ErrorBanner>
          <Banner.Content>
            <Banner.Title>Outreach and Collaboration Required</Banner.Title>
            <Banner.Body>
              Time zone shifting is in beta and is necessary for improving and
              standardizing date management and storage that affect everyone in
              Product & Technology. Collaboration between an app team,
              localization, and design system is strongly encouraged so we can
              make steps towards improving utilities or documention (Core Docs
              or elsewhere like Data Migration guides) for everyone.
            </Banner.Body>
          </Banner.Content>
        </ErrorBanner>
      </BannerView>
    )

  if (variant === 'text-capitalization') {
    return (
      <AttentionBanner>
        <Banner.Content>
          <Banner.Title>Design Guideline: Text Capitalization</Banner.Title>
          <Banner.Body>
            <P>
              Core design requires this component to be used with capitalized
              text, but this component does not provide the capitalization. Use
              capitalized text from an I18n translation when possible; it could
              require adding a new key. When using capitalized text from I18n is
              not possible, use{' '}
              <a
                target="_blank"
                href="https://developer.mozilla.org/en-US/docs/Web/CSS/text-transform"
              >
                CSS text-transform
              </a>{' '}
              'capitalize' for title styling or 'uppercase' for all caps.
            </P>
          </Banner.Body>
        </Banner.Content>
      </AttentionBanner>
    )
  }

  if (variant === 'codemod') {
    return (
      <BannerView>
        <InfoBanner>
          <Banner.Content>
            <Banner.Title>Codemod available</Banner.Title>
            <Banner.Body>
              To make this migration easier, there's a codemod to update your
              code automatically:
              {children}
            </Banner.Body>
          </Banner.Content>
        </InfoBanner>
      </BannerView>
    )
  }

  if (variant === 'optional-peer-dep-required') {
    return (
      <BannerView>
        <AttentionBanner>
          <Banner.Content>
            <Banner.Title>Peer Dependency is Required</Banner.Title>
            <Banner.Body>
              The dependency "{children}" is required for this component, hook,
              and context to work.
            </Banner.Body>
          </Banner.Content>
        </AttentionBanner>
      </BannerView>
    )
  }

  if (variant == 'locale-fallback')
    return (
      <BannerView>
        <AttentionBanner>
          <Banner.Content>
            <Banner.Title>Important follow-up</Banner.Title>
            <Banner.Body>
              <P>
                We deliver translations to your repo for all the major locales
                in Procore
                <a
                  href="https://github.com/procore/core/blob/f02789227a025e791a30ffdb2720cd4e563ec0cd/packages/core-react/src/_hooks/getI18nProviderLocale.ts#L3-L19"
                  target="_blank"
                >
                  (see list here)
                </a>
                , but there are some locales that you <strong>must</strong>{' '}
                provide the proper fallbacks for in your code so they use the
                correct translations.
              </P>
              <P>
                If you are using core-react, there is a standard function called{' '}
                <a
                  href="https://github.com/procore/core/blob/f02789227a025e791a30ffdb2720cd4e563ec0cd/packages/core-react/src/_hooks/getI18nProviderLocale.ts#L36"
                  target="blank"
                >
                  getI18nProviderLocale
                </a>{' '}
                that is available starting in version 11.31.0 that you should
                use to grab the proper fallback locale. See the{' '}
                <a
                  href="https://github.com/procore/backstage-templates/blob/9100bfd94c9705201cdc65d4fa2b37e857546ecc/examples/skeleton-ui-service/src/components/EnvironmentLayout/Environment.tsx#L25"
                  target="blank"
                >
                  MFE Backstage Template
                </a>
                for an example of how to implement this.
              </P>
              <P>
                If you are not using core-react, you will need to implement the
                fallbacks as they exist in the{' '}
                <a
                  href="https://github.com/procore/core/blob/f02789227a025e791a30ffdb2720cd4e563ec0cd/packages/core-react/src/_hooks/getI18nProviderLocale.ts#L36"
                  target="blank"
                >
                  getI18nProviderLocale
                </a>{' '}
                function (which should in turn match the{' '}
                <a
                  href="https://github.com/procore/procore/blob/58946c1852a71eb0cb9b6ba0e9764f8af1fd188a/config/application.rb#L283-L289"
                  target="blank"
                >
                  monolith
                </a>
                ).
              </P>
              <P>
                Reach out to{' '}
                <a
                  href="https://procore.enterprise.slack.com/archives/C037J98SPHD"
                  target="blank"
                >
                  #team-internationalization
                </a>{' '}
                if you have questions about locale fallbacks.
              </P>
            </Banner.Body>
          </Banner.Content>
        </AttentionBanner>
      </BannerView>
    )

  return (
    <BannerView>
      <AttentionBanner>
        <Banner.Content>
          <Banner.Title>Note</Banner.Title>
          <Banner.Body>{messages[variant]}</Banner.Body>
        </Banner.Content>
      </AttentionBanner>
    </BannerView>
  )
}
