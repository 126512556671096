import { Virtuoso } from 'react-virtuoso'
import styled, { css } from 'styled-components'
import { Box } from '../Box/Box'
import { Button } from '../Button'
import { StyledButton } from '../Button/Button.styles'
import { Card } from '../Card/Card'
import { StyledCheckboxLabel } from '../Checkbox/Checkbox.styles'
import { Pill } from '../Pill/Pill'
import { StyledCircle } from '../Spinner/Spinner.styles'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { getEllipsis, getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const dataQa = {
  focused: 'core-select-focused',
  hovered: 'core-select-hovered',
}

const optionMinHeight = 32

const checkedIconPlaceholderWidth = 32

const styledSelectButtonDefaultWidth = 248

export const extendedSelectMenuWidth = 360

export const PillSelectOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.xs}px ${spacing.lg}px;
`

export const PillSelectLabel = styled(Pill)`
  ${getEllipsis}
`

export const PillSelectLabelWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  max-width: 100%;
  pointer-events: auto;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`

export const StyledCheckboxContainer = styled.div`
  padding-left: ${spacing.lg}px;
`

export const StyledCheckmarkContainer = styled.div`
  align-items: center;
  color: ${colors.blue50};
  display: inline-flex;
  flex-shrink: 0;
  padding: 0 ${spacing.lg}px 0 ${spacing.sm}px;
`

// Do not merge with StyledMenu, needed for max-height working in IE
export const StyledWrapper = styled.div`
  display: flex;
  max-height: inherit;
  min-width: inherit;
  outline: none;
  width: inherit;
`

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  outline: 0;
  width: 100%;
`

export const StyledOptions = styled.div<{ $scrollable?: boolean }>`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: ${optionMinHeight}px;
  ${({ $scrollable = true }) => $scrollable && `overflow-y: auto`};
  padding: ${spacing.sm}px 0;
  position: relative;
  overflow: hidden;

  &:focus {
    ${getFocus()}
    margin: -1px;
  }
`

export const StyledItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledOptionGroupLabel = styled.div`
  padding: ${spacing.md}px 0 ${spacing.xs}px ${spacing.lg}px;
`

export const StyledItemLabel = styled.div<{
  $draggable?: boolean
  $selectionStyle?: 'highlight' | 'checkbox' | 'checkmark'
}>`
  ${({ $draggable }) => {
    return css`
      padding: ${spacing.xs}px ${$draggable ? 0 : spacing.lg}px;
      overflow-wrap: anywhere;
    `
  }}
`

export const StyledDraggableWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const StyledGrip = styled.div`
  padding: 0 2px;
  height: 24px;
  visibility: hidden;
`

export const StyledFooter = styled(Box)`
  border-top: 1px solid ${colors.gray85};
`

export const StyledHeader = styled.div``

export const StyledEmptyMessage = styled.div`
  ${getTypographyIntent('body')};

  padding: ${spacing.md}px ${spacing.lg}px;
  color: ${colors.gray45};
`

export const StyledSearchContainer = styled.div`
  padding: ${spacing.lg}px ${spacing.lg}px ${spacing.sm}px;

  ${StyledButton} > * {
    pointer-events: none;
  }
`

export const StyledGroup = styled.div<{ $clickable?: boolean }>`
  ${getTypographyIntent('body')}

  color: ${colors.gray15};
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
  font-weight: ${typographyWeights.semibold};
  padding: ${spacing.xs}px ${spacing.lg}px;

  &:not(:first-child) {
    padding-top: ${spacing.md + spacing.xs}px;
  }

  && {
    padding: 0;
  }

  ${StyledOptionGroupLabel} {
    padding: ${spacing.xs}px ${spacing.lg} px;
  }

  &:not(:first-child) {
    ${StyledOptionGroupLabel} {
      padding-top: ${spacing.md + spacing.xs} px;
    }
  }
`

export const StyledItem = styled.div<{
  $disabled?: boolean
  $selected?: boolean
  $highlighted?: boolean
  $isDraggable?: boolean
  $isDragging?: boolean
  $emptyMinHeight?: number
  $isDraggingOver?: boolean
  $isDraggingDisabled?: boolean
}>`
  ${getTypographyIntent('body')};

  align-items: center;
  cursor: pointer;
  list-style-type: none;
  display: flex;
  padding: 0;

  ${({ $emptyMinHeight }) => {
    return css`
      &:empty {
        min-height: ${$emptyMinHeight}px;
      }
    `
  }}

  ${({ $isDraggingOver, $highlighted }) => {
    return (
      $isDraggingOver &&
      $highlighted &&
      css`
        background-color: initial;
      `
    )
  }}

  ${({ $isDraggingOver, $disabled, $isDraggingDisabled }) => {
    return (
      ($isDraggingOver || $disabled || $isDraggingDisabled) &&
      css`
        &:hover {
          ${StyledGrip} {
            visibility: hidden;
          }
        }
      `
    )
  }}

  a {
    // We decided to use a negative margin trick here to allow us to continue
    // to use listNavigation without having to rewrite a bunch of code in js
    color: ${colors.gray15};
    display: block;
    margin: -${spacing.xs}px -${spacing.lg}px;
    padding: ${spacing.xs}px ${spacing.lg}px;
    text-decoration: none;

    &:hover {
      color: ${colors.gray15};
      text-decoration: none;
    }
  }

  &[data-highlighted='true'] {
    background-color: ${colors.gray96};
  }

  ${({ $highlighted }) => $highlighted && `background-color: ${colors.gray96};`}

  &:hover,
  &:active {
    ${StyledGrip} {
      visibility: visible;
    }
  }

  ${StyledCheckboxLabel} {
    color: inherit;
    cursor: pointer;
  }

  ${({ $disabled, $selected }) => {
    if ($disabled) {
      return css`
        &,
        ${StyledCheckboxLabel} {
          color: ${colors.gray85};
          cursor: default;
        }
      `
    } else if ($selected) {
      return css`
        color: ${colors.blue50};
        font-weight: 700;

        a,
        a:hover {
          color: ${colors.blue50};
          text-decoration: none;
        }

        &[data-highlighted='true'] {
          background-color: ${colors.gray96};
        }
      `
    }

    return css`
      color: ${colors.gray15};

      &[data-highlighted='true'] {
        background-color: ${colors.gray96};
      }
    `
  }}

  ${({ $isDraggable }) => {
    if ($isDraggable) {
      return css`
        ${StyledCheckboxLabel} {
          cursor: grab;

          // The unchecked and checked icons
          &:before,
          &:after {
            cursor: pointer;
          }
        }

        &&& {
          padding-left: 0;
        }
      `
    }
  }}

  ${({ $isDragging }) => {
    if ($isDragging) {
      return css`
        background-color: ${colors.gray96};

        ${StyledGrip} {
          visibility: visible;
        }
      `
    }
  }}
`

export const StyledDroppable = styled.div`
  ${StyledGroup}, ${StyledItem} {
    padding-left: 28px;
  }
`

export const StyledSelectMenu = styled(Card)`
  display: flex;
  max-height: 40vh;
  max-width: ${styledSelectButtonDefaultWidth}px;
  min-width: inherit;
`

export const StyledLabel = styled.div<{ $hoverable: boolean }>`
  ${getEllipsis()}
  flex-grow: 1;
  margin-right: ${spacing.sm}px;
  outline: none;
  pointer-events: ${({ $hoverable }) => ($hoverable ? 'initial' : 'none')};
  user-select: none;
`

export const StyledIndicators = styled.div`
  align-items: center;
  align-self: flex-start;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  margin-left: ${spacing.xs}px;
  min-width: 40px;
  width: 40px;
`

export const StyledClearIcon = styled(Button)``

export const StyledSpinner = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  line-height: 0;
`

export const StyledArrowContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 16px;
  justify-content: flex-end;
  margin-left: ${spacing.sm}px;
  width: 8px;
`

export const StyledArrow = styled.span`
  display: inline-flex;

  svg {
    fill: ${colors.gray45};
  }
`

export const StyledArrowOutline = styled.polygon``

export const StyledArrowFill = styled.polygon``

export const StyledTrigger = styled.div<{
  $block?: boolean
  $disabled?: boolean
  $error?: boolean
  $hasClearIcon?: boolean
  $loading?: boolean
  $multiple?: boolean
  $open?: boolean
  $placeholder?: boolean
}>`
  ${getTypographyIntent('body')}

  align-items: center;
  background-color: ${colors.white};
  border-color: ${colors.gray70};
  border-radius: ${borderRadius.md}px;
  border-style: solid;
  border-width: 1px;
  color: ${colors.gray15};
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  min-height: 36px;
  outline: none;
  padding: 0 ${spacing.md}px;
  position: relative;
  white-space: nowrap;
  width: ${({ $block }) =>
    $block ? '100%' : `${styledSelectButtonDefaultWidth}px`};

  &::placeholder {
    border-color: ${colors.gray45};
  }

  &:hover,
  &:active,
  &[data-qa=${dataQa.hovered}] {
    background-color: ${colors.white};
    border-color: ${colors.gray45};
    color: ${colors.gray15};
  }

  &:focus,
  &[data-qa=${dataQa.focused}] {
    ${getFocus()}

    ${StyledCircle} {
      stroke: ${colors.blue50};
    }
  }

  ${({ $placeholder = false }) =>
    $placeholder &&
    css`
      ${StyledLabel} {
        color: ${colors.gray45};
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${colors.gray94};
      border-color: ${colors.gray70};
      color: ${colors.gray45};
      cursor: default;
      pointer-events: none;

      ${StyledLabel} {
        color: ${colors.gray70};
      }

      ${StyledArrow} {
        svg {
          fill: ${colors.gray70};
        }
      }
    `}

  ${({ $error, $disabled }) => {
    if ($error && !$disabled) {
      return css`
        &,
        &:hover {
          border-color: ${colors.red50};
          border-color: var(--core-input-error-border-color, ${colors.red50});
        }
        &:hover:focus {
          ${getFocus()}
        }
      `
    }
  }}

  ${({ $hasClearIcon }) => css`
    ${StyledClearIcon} {
      opacity: ${$hasClearIcon ? 1 : 0};
    }
  `}

  ${({ $multiple }) =>
    $multiple &&
    css`
      height: auto;

      &:focus-within {
        ${getFocus()}
      }
    `}

    ${({ $open, $hasClearIcon }) =>
      $open &&
      $hasClearIcon &&
      css`
        ${StyledClearIcon} {
          opacity: 1;
          pointer-events: all;
        }
      `}

    ${({ $open, $placeholder = false }) =>
      $open &&
      $placeholder &&
      css`
        ${StyledLabel} {
          opacity: 1;
          pointer-events: all;
        }
      `}
  }
`

export const StyledOverlay = styled(Card)`
  display: flex;
  flex-direction: column;
`

export const StyledVirtuoso = styled(Virtuoso)`
  flex: 1 1 auto;
`

export const StyledEllipses = styled.div`
  ${getEllipsis()}
  display: inline-flex;

  > div {
    display: inline-flex;
    width: 100%;
  }
`

export const StyledMultiValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
  margin-right: ${spacing.xs}px;
  margin-top: 2px;
  min-width: 0;
  width: 100%;
`

export const StyledMultiValue = styled.div`
  margin-bottom: 2px;
  margin-right: ${spacing.xs}px;
  margin-top: 2px;
  flex: 0 1 auto;

  > div {
    max-width: 178px;
    width: 100%;
  }
`

export const StyledMultiInputContainer = styled.div`
  margin-bottom: 2px;
  margin-right: ${spacing.xs}px;
  margin-top: 2px;
  flex: 1;
  max-width: 100%;
  min-width: 24px;
`

export const StyledMultiInput = styled.input<{
  $isNavigatingTokens?: boolean
}>`
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  padding: 0;
  height: 24px;
  width: 100%;

  ${getTypographyIntent('body')}

  ${({ $isNavigatingTokens }) =>
    $isNavigatingTokens &&
    css`
      color: transparent;
    `}

  &::-ms-clear {
    display: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &::placeholder {
    color: ${colors.gray45};
  }
`
