import styled from 'styled-components'
import { getSearchInputStyles } from '../Search/Search.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

const inputHeight = 36

export const StyledInput = styled.input`
  ${getSearchInputStyles()}

  padding-right: ${spacing.xxl}px;
`

export const StyledIcon = styled.div<{ disabled?: boolean }>`
  align-items: center;
  color: ${(p) => (p.disabled ? colors.gray70 : colors.gray45)};
  display: flex;
  height: ${inputHeight}px;
  justify-content: center;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
  position: absolute;
  right: 0;
  top: 0;
  width: ${inputHeight}px;
`

export const StyledTypeahead = styled.div`
  position: relative;
  width: 100%;
`
