import React from 'react'
import { useI18nContext } from '../_hooks/I18n'

type ConditionalCloseFunction<
  onClose extends undefined | ((...args: any[]) => any)
> = onClose extends (...args: any[]) => any ? onClose : undefined

interface UseCloseWithConfirmState {
  closeWithConfirm: <onClose extends undefined | ((...args: any[]) => any)>(
    func: onClose
  ) => ConditionalCloseFunction<onClose>
  setFormIsDifferent: React.Dispatch<React.SetStateAction<boolean>>
}

export const useCloseWithConfirmState = (): UseCloseWithConfirmState => {
  const I18n = useI18nContext()
  const [isFormDifferent, setFormIsDifferent] = React.useState(false)

  const closeWithConfirm = React.useCallback(
    (onClose: Function | undefined) =>
      onClose
        ? (...args: any[]) => {
            if (isFormDifferent && onClose) {
              if (
                window.confirm(
                  I18n.t('core.form.closeWithConfirm.confirmMessage')
                )
              ) {
                return onClose(...args)
              }
            } else {
              return onClose?.(...args)
            }
          }
        : undefined,
    [isFormDifferent]
  ) as unknown as UseCloseWithConfirmState['closeWithConfirm']

  return {
    closeWithConfirm,
    setFormIsDifferent,
  }
}

export const CloseWithConfirmContext =
  React.createContext<UseCloseWithConfirmState>({
    setFormIsDifferent: () => {},
    // @ts-ignore
    closeWithConfirm: (onClose) => onClose,
  })

export const useCloseWithConfirmContext = () => {
  return React.useContext(CloseWithConfirmContext)
}
