import React from 'react'
import { Popover } from '../../Popover'
import { Typography } from '../../Typography'
import { StyledFilterPresetPopoverContent } from '../SuperSelect.presets.styles'
import { StyledLabel, StyledTrigger } from '../SuperSelect.styles'
import type {
  SuperSelectConfig,
  SuperSelectPresetProps,
} from '../SuperSelect.types'
import { useSuperSelectContext } from '../useSuperSelect'

const getFilterPresetProps = (props?: SuperSelectPresetProps) => {
  return props?.filter ?? {}
}

const getValueLabel = (
  isValueEmpty: boolean,
  value?: string,
  placeholder?: string,
  filterName?: string
) => {
  const prefixLabel = isValueEmpty ? placeholder || filterName : filterName
  const valueLabel = value ? `${prefixLabel ? ': ' : ''}${value}` : ''
  return `${prefixLabel}${valueLabel}`
}

export const filterPreset: SuperSelectConfig = {
  components: {
    Label: React.forwardRef(() => {
      const ctx = useSuperSelectContext()
      const presetProps = getFilterPresetProps(ctx.config.presetProps)
      const isValueEmpty = ['', null, undefined].includes(
        ctx.state.value as string
      )

      return (
        <StyledLabel $hoverable={false}>
          {getValueLabel(
            isValueEmpty,
            ctx.state.selectedLabel,
            ctx.config.placeholder,
            presetProps.filterName
          )}
        </StyledLabel>
      )
    }),
    TriggerContainer: React.forwardRef((props, ref) => {
      const ctx = useSuperSelectContext()

      if (!Array.isArray(ctx.state.value)) {
        return <StyledTrigger $block={ctx.config.block} {...props} ref={ref} />
      }

      return (
        <Popover
          placement="top"
          trigger={ctx.state.value.length > 1 ? 'hover' : 'none'}
          overlay={
            <StyledFilterPresetPopoverContent>
              <Typography>
                {ctx.state.value
                  .map((v) => {
                    const option = ctx.state.options.find(
                      (option) => ctx.option.value(option) === v
                    )
                    return option ? ctx.option.label(option) : ''
                  })
                  .join(', ')}
              </Typography>
            </StyledFilterPresetPopoverContent>
          }
        >
          <StyledTrigger $block={ctx.config.block} {...props} ref={ref} />
        </Popover>
      )
    }),
    MultiValueContainer: () => {
      const ctx = useSuperSelectContext()
      const presetProps = getFilterPresetProps(ctx.config.presetProps)

      if (Array.isArray(ctx.state.value)) {
        const isValueEmpty = !ctx.state.value.length
        const value =
          ctx.state.value.length > 1
            ? `(${ctx.state.value.length})`
            : ctx.state.value.length === 1
            ? `${ctx.state.selectedLabel}`
            : ''

        return (
          <StyledLabel $hoverable={false}>
            {getValueLabel(
              isValueEmpty,
              value,
              ctx.config.placeholder,
              presetProps.filterName
            )}
          </StyledLabel>
        )
      }

      return <div>Value is not an array</div>
    },
  },
  selectionStyle: 'highlight',
}
