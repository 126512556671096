import type { NumberOptions } from '@procore/globalization-toolkit'
import { NumberFormatter } from '@procore/globalization-toolkit'
import { getFormatterLocale } from '../_utils/i18n'
import type {
  CursorPosition,
  FillDecimalScaleVariant,
  InputValue,
  Locale,
  ParsedInputValue,
  PressedKey,
  Separators,
} from './NumberInput.types'

export const defaultLocale = 'en-US'
export const minus = '-'
export const emptyString = ''
export const leftBrace = '('
export const rightBrace = ')'
export const backspaceKey = 'Backspace'
export const deleteKey = 'Delete'
export const numericKeypadDel = 'Del' // for delete key from numeric keypad in IE
export const periodKey = '.'
export const numericKeypadDecimal = 'Decimal' // for period key from numeric keypad in IE
export const numericKeypadKey = 'Del' // for delete key from numeric keypad in IE
export const androidUnidentifiedKey = 'Unidentified'
export const maxDigitLength = 15 // Safe length to avoid the loss of precision
export const maxDecimalScale = maxDigitLength - 1
export const commaKey = ','
export const metaKeyIdentifier = 'Meta'
export const controlKey = 'Control'
export const highlightAllKey = 'a'
export const copyKey = 'c'

// fixes binary rounding issues (e.g: (0.615).toFixed(2) returns 0.61)
export function toFixed(value: number, precision: number): number {
  const isExponentialRecord = value.toString().includes('e')

  if (isExponentialRecord) return value

  return Number(
    Number(
      Math.round(Number(value + 'e' + precision)) + 'e-' + precision
    ).toFixed(precision)
  )
}

export function formatterFactory(options: NumberOptions): NumberFormatter {
  try {
    return new NumberFormatter(options)
  } catch (error) {
    console.error(
      `Deprecated. @procore/core-react Intl.NumberFormat error for locale ${options.locale}. Will fall back to en-US. In a future major, this will become an error and not provide a fallback.`,
      error
    )
    return new NumberFormatter({ ...options, locale: 'en-US' })
  }
}

export function getSeparators(locale: Locale): Separators {
  const formatterLocale = getFormatterLocale(locale as string)
  const separators = new NumberFormatter({
    locale: formatterLocale,
  }).getNumberSeparators()

  return { group: separators.group, decimal: separators.decimal || '' }
}

// Check if a valid numeric string is a negative value
export const isValidNegative = (value: InputValue) => {
  value = String(value)

  const minusRegExp = new RegExp(`${minus}`, 'g')
  const numberOfMinuses = value.match(minusRegExp)?.length
  const containsValidParenthesis =
    value.indexOf(leftBrace) === 0 &&
    value.indexOf(rightBrace) === value.length - 1
  const isNegative =
    (numberOfMinuses === 1 && !containsValidParenthesis) ||
    (!numberOfMinuses && containsValidParenthesis)
  return isNegative
}

export function verifyDecimalScale(decimalScale: boolean | number): number {
  if (decimalScale === true) {
    return maxDecimalScale
  }
  if (decimalScale === false) {
    return 0
  }
  if (decimalScale < 0) {
    console.warn(
      `WARNING: Decimal scale (${decimalScale}) is out of range. It was reset to 0. Possible values are from 0 to ${maxDecimalScale}.`
    )
    return 0
  }
  if (decimalScale > maxDecimalScale) {
    console.warn(
      `WARNING: Decimal scale (${decimalScale}) is out of range. It was reset to the max value of ${maxDecimalScale}. Possible values are from 0 to ${maxDecimalScale}.`
    )
    return maxDecimalScale
  }
  return decimalScale
}

export function unformat(
  locale: Locale,
  value: InputValue,
  isNumericString = false
): ParsedInputValue {
  const isValueNegative = isValidNegative(value)
  const decimal =
    isNumericString || typeof value === 'number'
      ? periodKey
      : getSeparators(locale).decimal
  const numericRegExp = new RegExp(`[^0-9${decimal}]`, 'g')

  let strValue = String(value)

  // Normalize value with numeric characters
  strValue = strValue.replace(numericRegExp, emptyString)

  // Normalize "minus" sign for negatives
  if (isValueNegative) {
    strValue = `${minus}${strValue}`
  }

  // Normalize integer and fraction parts of the value
  const [integer, ...fractions] = strValue.split(decimal)
  const fraction = fractions.join(emptyString)

  return {
    integer,
    decimal: strValue.includes(decimal) ? decimal : emptyString,
    fraction,
    parsedNumber: parseFloat(`${integer}${periodKey}${fraction}`),
  }
}

export function refineIntegerFromLeadingZeroes(integer: string) {
  const isNegativeValue = isValidNegative(integer)
  const refinedFromMinus = integer.replace(minus, emptyString)
  const hasLeadingZeroes = refinedFromMinus.slice(0, 1)
  const refinedFromLeadingZeroes = refinedFromMinus.replace(/^0+/, emptyString)
  const zeroPadded =
    hasLeadingZeroes && refinedFromLeadingZeroes === emptyString

  return `${isNegativeValue ? minus : emptyString}${
    zeroPadded ? 0 : refinedFromLeadingZeroes
  }`
}

export function getLengthInfo(
  locale: Locale,
  value: InputValue,
  decimalScale: number = 0,
  fillDecimalScale: FillDecimalScaleVariant = 'none',
  isNumericString = false
) {
  const isNegativeValue = isValidNegative(value)

  const { integer, decimal, fraction } = unformat(
    locale,
    value,
    isNumericString
  )

  const integerRefinedFromLeadingZeros = refineIntegerFromLeadingZeroes(integer)

  const getIntegerLength = () => {
    const accountEmptyInteger =
      integer === emptyString && decimal && fraction.length > 0

    if (accountEmptyInteger) return 1

    const accountEmptyNegativeInteger =
      integer === minus && decimal && fraction.length > 0

    if (accountEmptyNegativeInteger) return 2

    const accountInteger =
      integer.length > 0 && integer !== emptyString && integer !== minus

    if (accountInteger) return integerRefinedFromLeadingZeros.length

    return 0
  }

  const integerLength = getIntegerLength()
  const fractionLength = fraction.length
  const length = integerLength + fractionLength

  const safeLength = maxDigitLength + (isNegativeValue ? 1 : 0)
  const isSafeLength = safeLength - length > -1

  const isDecimalScaleNonFillable = fillDecimalScale === 'none'

  const safeIntegerLength = isDecimalScaleNonFillable
    ? safeLength
    : safeLength - decimalScale
  const safeFractionLength = isDecimalScaleNonFillable
    ? Math.min(
        safeLength -
          integerRefinedFromLeadingZeros.slice(0, safeIntegerLength).length,
        decimalScale
      )
    : decimalScale

  const isSafeIntegerLength = safeIntegerLength - integerLength > -1
  const isSafeFractionLength = safeFractionLength - fractionLength > -1

  const safelyTruncatedInteger = isSafeIntegerLength
    ? integerRefinedFromLeadingZeros
    : integerRefinedFromLeadingZeros.slice(0, safeIntegerLength)
  const safelyTruncatedFraction = isSafeFractionLength
    ? fraction
    : fraction.slice(0, safeFractionLength)

  const safelyTruncatedValue = `${safelyTruncatedInteger}${decimal}${safelyTruncatedFraction}`

  return {
    length,
    safeLength,
    isSafeLength,
    safelyTruncatedValue,
    integerLength,
    safeIntegerLength,
    isSafeIntegerLength,
    fractionLength,
    safeFractionLength,
    isSafeFractionLength,
  }
}

export function format(
  locale: Locale,
  decimalScale: number,
  fillDecimalScale: FillDecimalScaleVariant,
  value: InputValue,
  isNumericString = false
): string {
  const fixedDecimalScale = fillDecimalScale === 'always'

  const fillDecimalScaleOnBlur = fillDecimalScale === 'onBlur'

  const autoFilledScale = fixedDecimalScale || fillDecimalScaleOnBlur

  const { length, safeLength, isSafeLength, safelyTruncatedValue } =
    getLengthInfo(
      locale,
      value,
      decimalScale,
      fillDecimalScale,
      isNumericString
    )

  const isTruncatedBeforeFormatting = autoFilledScale || !isSafeLength

  const valueOfVerifiedLength = isTruncatedBeforeFormatting
    ? safelyTruncatedValue
    : value

  const { integer, decimal, fraction } = unformat(
    locale,
    valueOfVerifiedLength,
    isNumericString || typeof value === 'number'
  )

  const options = {
    minimumFractionDigits: fixedDecimalScale
      ? decimalScale
      : Math.min(Math.max(0, fraction?.length || 0), decimalScale),
    maximumFractionDigits: decimalScale,
  }

  const formatterLocale = getFormatterLocale(locale as string)
  const formatter = formatterFactory({ locale: formatterLocale, ...options })

  const isDecimalAllowed = decimalScale > 0

  const isEmptyValue = integer === emptyString && !decimal && !fraction

  if (isEmptyValue) return emptyString

  const isMinusValue = integer === minus && !decimal && !fraction

  if (isMinusValue) return minus

  const isDecimalValue =
    integer === emptyString && decimal && !fraction && !fixedDecimalScale

  if (isDecimalValue) return isDecimalAllowed ? decimal : emptyString

  const isMinusDecimalValue =
    integer === minus && decimal && !fraction && !fixedDecimalScale

  if (isMinusDecimalValue)
    return `${minus}${isDecimalAllowed ? decimal : emptyString}`

  const isFractionValueWithEmptyInteger =
    (integer === minus || integer === emptyString) &&
    decimal &&
    (fraction || (!fraction && fixedDecimalScale))

  if (isFractionValueWithEmptyInteger) {
    const numberOfZerosToPad = decimalScale - fraction.length

    const preparedFraction =
      numberOfZerosToPad > 0 && fixedDecimalScale
        ? fraction.concat(
            Array.from({ length: numberOfZerosToPad }, (_) => '0').join(
              emptyString
            )
          )
        : fraction.slice(0, decimalScale)

    return `${integer}${
      isDecimalAllowed ? decimal : emptyString
    }${preparedFraction}`
  }

  if (typeof valueOfVerifiedLength === 'number') {
    return formatter.formatNumber(valueOfVerifiedLength)
  }

  if (fraction) {
    const number = parseFloat(
      `${integer}${periodKey}${fraction.slice(
        0,
        options.maximumFractionDigits
      )}`
    )

    if (isNaN(number)) return emptyString

    return formatter.formatNumber(number)
  }

  if (integer) {
    const number = parseInt(integer, 10)

    if (isNaN(number)) return emptyString

    const formatted = formatter.formatNumber(number)
    const isDecimalAllowed = !(
      options.minimumFractionDigits === 0 && options.maximumFractionDigits === 0
    )
    const isDecimalAvailable =
      decimal && !formatted.includes(decimal) && length < safeLength

    return isDecimalAllowed && isDecimalAvailable
      ? `${formatted}${decimal}`
      : formatted
  }

  return emptyString
}

export function identifyPressedKey(
  decimalSeparatorKey: string,
  inputType: string,
  pressedKey: PressedKey,
  prevValue: string,
  value: string
) {
  if (pressedKey !== androidUnidentifiedKey) return pressedKey

  if (inputType === 'deleteContentBackward') return backspaceKey

  if (inputType === 'insertText') {
    const diff = value
      .split(emptyString)
      .find(
        (char: string, i: number) => char !== prevValue.split(emptyString)[i]
      )

    switch (diff) {
      case decimalSeparatorKey:
        return decimalSeparatorKey
      case minus:
        return minus
      default:
        return pressedKey
    }
  }

  return pressedKey
}

export function getPressedKeyInfo(
  decimalSeparatorKey: string,
  group: string,
  pressedKey: PressedKey,
  prevCursorPosition: CursorPosition,
  prevValue: string,
  verifiedDecimalScale: number
) {
  const { selectionStart, selectionEnd, selection } = prevCursorPosition

  const backspaceWasPressed = pressedKey === backspaceKey
  const backspacedChar = backspaceWasPressed
    ? prevValue.slice(selectionStart + (selection ? 0 : -1), selectionEnd)
    : null
  const decimalWasPressed = [
    decimalSeparatorKey,
    numericKeypadDecimal,
  ].includes(pressedKey as string)
  const decimalPressedForInteger =
    decimalWasPressed && verifiedDecimalScale === 0
  const deleteWasPressed = [deleteKey, numericKeypadDel].includes(
    pressedKey as string
  )
  const deletedChar = deleteWasPressed
    ? prevValue.slice(selectionStart, selectionEnd + (selection ? 0 : 1))
    : null

  const thousandsSeparatorWasBackspaced =
    backspaceWasPressed && backspacedChar === group
  const thousandsSeparatorWasDeleted = deleteWasPressed && deletedChar === group

  return {
    backspaceWasPressed,
    backspacedChar,
    decimalWasPressed,
    decimalPressedForInteger,
    deleteWasPressed,
    deletedChar,
    thousandsSeparatorWasBackspaced,
    thousandsSeparatorWasDeleted,
  }
}

export function prepareValueToFormatting(
  decimal: string,
  decimalSeparatorKey: string,
  group: string,
  pressedKey: PressedKey,
  prevCursorPosition: CursorPosition,
  prevValue: string,
  verifiedDecimalScale: number,
  value: string
) {
  const {
    decimalPressedForInteger,
    thousandsSeparatorWasBackspaced,
    thousandsSeparatorWasDeleted,
  } = getPressedKeyInfo(
    decimalSeparatorKey,
    group,
    pressedKey,
    prevCursorPosition,
    prevValue,
    verifiedDecimalScale
  )

  // Remove preceding/following integer digit instead of thousands separator
  if (thousandsSeparatorWasBackspaced || thousandsSeparatorWasDeleted) {
    const { selectionEnd, selection } = prevCursorPosition

    const getCursorShift = () => {
      if (thousandsSeparatorWasDeleted) {
        const deletedAsSelection = selection === group
        return deletedAsSelection ? 0 : 1
      }
      if (thousandsSeparatorWasBackspaced) return -2
      return 0
    }

    const indexOfIntegerDigitToRemove = selectionEnd + getCursorShift()

    return prevValue
      .split(emptyString)
      .filter((_, i) => i !== indexOfIntegerDigitToRemove)
      .join(emptyString)
  }

  // Ignore typing decimal separator for integer
  if (decimalPressedForInteger) {
    return value.replace(new RegExp(`[${decimal}]`, 'g'), emptyString)
  }

  return value
}

export function getNewCursorPosition(
  decimal: string,
  decimalSeparatorKey: string,
  group: string,
  newValue: string,
  pressedKey: PressedKey,
  prevCursorPosition: CursorPosition,
  prevValue: string,
  value: string,
  verifiedDecimalScale: number,
  locale: Locale,
  fillDecimalScale: FillDecimalScaleVariant
) {
  const fixedDecimalScale = fillDecimalScale === 'always'

  const { length: prevLength, fractionLength: prevFractionLength } =
    getLengthInfo(locale, prevValue, verifiedDecimalScale, fillDecimalScale)

  const {
    length: newLength,
    safeLength: newSafeLength,
    integerLength: newIntegerLength,
    safeIntegerLength: newSafeIntegerLength,
    fractionLength: newFractionLength,
    safeFractionLength: newSafeFractionLength,
  } = getLengthInfo(locale, newValue, verifiedDecimalScale, fillDecimalScale)

  const {
    backspaceWasPressed,
    backspacedChar,
    decimalWasPressed,
    deleteWasPressed,
    thousandsSeparatorWasBackspaced,
  } = getPressedKeyInfo(
    decimalSeparatorKey,
    group,
    pressedKey,
    prevCursorPosition,
    prevValue,
    verifiedDecimalScale
  )

  const digitTyped = pressedKey && /\d/g.test(pressedKey)
  const digitBackspaced =
    backspaceWasPressed && backspacedChar && /\d/g.test(backspacedChar)
  const decimalBackspaced = backspaceWasPressed && backspacedChar === decimal

  const { selectionStart, selectionEnd, selection } = prevCursorPosition

  const defaultPosition = Math.max(
    selectionEnd + (newValue.length - prevValue.length),
    0
  )

  const isPrevValueNegative = isValidNegative(prevValue)

  const isNewValueNegative = isValidNegative(newValue)

  const isDecimalAllowed = verifiedDecimalScale > 0

  const digitTypedBeforeMinus =
    isPrevValueNegative && selectionEnd === 0 && digitTyped

  const leadingZeroAdded =
    pressedKey === '0' && selectionEnd === (isPrevValueNegative ? 1 : 0)

  const prevIndexOfDecimalSeparator = prevValue.indexOf(decimal)
  const prevCursorPositionInDecimalArea =
    prevIndexOfDecimalSeparator > -1 &&
    prevIndexOfDecimalSeparator < selectionEnd
  const prevCursorPositionInIntegerArea =
    prevIndexOfDecimalSeparator === -1 ||
    prevIndexOfDecimalSeparator >= selectionEnd

  const filledIntegerScaleDigitWasChanged =
    digitTyped &&
    prevCursorPositionInIntegerArea &&
    selectionEnd !== prevValue.length &&
    newLength === prevLength &&
    (newLength === newSafeLength || newIntegerLength === newSafeIntegerLength)

  const isDecimalScaleFilled =
    isDecimalAllowed &&
    newFractionLength === prevFractionLength &&
    newFractionLength === newSafeFractionLength

  const filledDecimalScaleDigitWasBackspaced =
    isDecimalScaleFilled && prevCursorPositionInDecimalArea && digitBackspaced

  const filledDecimalScaleDigitWasChanged =
    isDecimalScaleFilled && prevCursorPositionInDecimalArea && digitTyped

  // Manage cursor when decimalSeparator typed in a new place of a decimal-allowed number
  const decimalNewPosition = newValue.indexOf(decimal)
  const restructuredByDecimal =
    isDecimalAllowed && decimalWasPressed && decimalNewPosition > -1

  // Preserve cursor after first typed digit and before decimal separator
  const fixedDecimalScaleFirstTyping =
    fixedDecimalScale &&
    value.length === (isNewValueNegative ? 2 : 1) &&
    newValue.charAt(isNewValueNegative ? 1 : 0) === pressedKey

  // Preserve cursor before "negative" sign
  const isLeadingZerosRemovedOnPrecedingDigitBackspace =
    backspaceWasPressed &&
    defaultPosition === 0 &&
    selectionEnd - defaultPosition > 1
  const keepCursorBeforeNegativeSign =
    isNewValueNegative && isLeadingZerosRemovedOnPrecedingDigitBackspace

  const getPositionOnRestructuringByDecimal = () => {
    return prevCursorPositionInDecimalArea
      ? newValue.length
      : decimalNewPosition + 1
  }

  const getPositionOnDecimalBackspace = () => {
    const removedCharShift = 1
    const valueLength = newValue.length

    if (prevFractionLength === 0) {
      return valueLength
    }

    let delta = 0
    let anchorDigitIndexFromTheEnd = 0

    newValue
      .split(emptyString)
      .reverse()
      .forEach((char, i) => {
        if (char === group) {
          delta++
        }
        if (i - delta === prevFractionLength - 1) {
          anchorDigitIndexFromTheEnd = i
        }
      })

    return valueLength - removedCharShift - anchorDigitIndexFromTheEnd
  }

  const getPositionOnDelete = () => {
    const firstSymbolDeleted = selectionEnd === 0

    if (firstSymbolDeleted) {
      return 0
    }

    const removedCharShift = 1
    const anchorDigitIndex =
      prevValue
        .slice(0, selectionEnd)
        .replace(new RegExp(`[${group}]`, 'g'), emptyString).length - 1

    let delta = 0
    let cursorPosition = 0

    newValue.split(emptyString).forEach((char, i) => {
      if (char === group) {
        delta++
      }
      if (i - delta === anchorDigitIndex) {
        cursorPosition = i
      }
    })

    return cursorPosition + removedCharShift
  }

  const getPositionOnFilledIntegerScaleDigitChange = () => {
    const getGroupsNumber = (value: string, start?: number, end?: number) => {
      return (
        value.slice(start, end).match(new RegExp(`[${group}]`, 'g'))?.length ||
        0
      )
    }

    const numberOfGroupsBeforeCursorPrevValue = getGroupsNumber(
      prevValue,
      0,
      selectionEnd
    )
    const numberOfGroupsBeforeCursorNewValue = getGroupsNumber(
      newValue,
      0,
      selectionEnd + 1
    )
    const groupsDiffBeforeCursor =
      numberOfGroupsBeforeCursorNewValue - numberOfGroupsBeforeCursorPrevValue

    const typedDigitShift = leadingZeroAdded ? 0 : 1

    return selectionEnd + groupsDiffBeforeCursor + typedDigitShift
  }

  const getPositionOnSelectionChange = () => {
    if (restructuredByDecimal) return getPositionOnRestructuringByDecimal()

    const getPasteText = () => {
      const beforeSelectionPartLength = prevValue.slice(
        0,
        selectionStart
      ).length
      const afterSelectionPartLength = prevValue.slice(selectionEnd).length
      const valueWithoutBeforePart = value.slice(beforeSelectionPartLength)

      const pasteText = valueWithoutBeforePart.slice(
        0,
        valueWithoutBeforePart.length - afterSelectionPartLength
      )

      return pasteText
    }

    const {
      integer: pasteInteger,
      decimal: pasteDecimal,
      fraction: pasteFraction,
    } = unformat(locale, getPasteText())

    const refinedPasteText = `${
      selectionStart === 0
        ? refineIntegerFromLeadingZeroes(pasteInteger)
        : pasteInteger
    }${pasteDecimal}${pasteFraction}`

    const refinedPasteTextLength = refinedPasteText.length

    const anchorDigitIndexRaw =
      prevValue
        .slice(0, selectionStart)
        .replace(new RegExp(`[${group}${decimal}]`, 'g'), emptyString).length -
      1

    const anchorDigitIndexBase =
      anchorDigitIndexRaw > -1 ? anchorDigitIndexRaw : 0

    const anchorDigitIndexShift =
      anchorDigitIndexRaw > -1
        ? refinedPasteTextLength
        : Math.max(refinedPasteTextLength - 1, 0)

    const anchorIndex = anchorDigitIndexBase + anchorDigitIndexShift

    const getCursorPositionShift = () => {
      const negativeToPositiveConversion =
        isValidNegative(refinedPasteText) && isPrevValueNegative

      const integerZeroBasedSelectionRemoval =
        refinedPasteTextLength === 0 && selectionStart === 0

      const integerPartRemoved =
        integerZeroBasedSelectionRemoval &&
        selectionEnd === prevIndexOfDecimalSeparator

      const fractionZeroBasedSelectionRemoval =
        (refinedPasteTextLength === 0 || refinedPasteText === minus) &&
        selectionStart === prevIndexOfDecimalSeparator + 1

      const groupSelectionBackspaced =
        selection === group && thousandsSeparatorWasBackspaced

      if (negativeToPositiveConversion || integerPartRemoved) return -1
      if (integerZeroBasedSelectionRemoval || groupSelectionBackspaced) return 0
      if (fractionZeroBasedSelectionRemoval) return 2

      return 1
    }

    let delta = 0
    let cursorPosition = 0

    for (let i = 0; i < newValue.length; i++) {
      const char = newValue.charAt(i)

      const isDeltaIncrementer =
        char === group || (char === decimal && !pasteDecimal)

      if (isDeltaIncrementer) {
        delta++
      }

      if (i - delta === anchorIndex) {
        cursorPosition = i + getCursorPositionShift()
        break
      }

      if (i + 1 === newValue.length) {
        cursorPosition = newValue.length
      }
    }

    return cursorPosition
  }

  const getNewPosition = () => {
    if (selection) {
      return getPositionOnSelectionChange()
    }

    if (digitTypedBeforeMinus) {
      return 2
    }

    if (filledIntegerScaleDigitWasChanged) {
      return getPositionOnFilledIntegerScaleDigitChange()
    }

    if (deleteWasPressed) {
      return getPositionOnDelete()
    }

    if (decimalBackspaced) {
      return getPositionOnDecimalBackspace()
    }

    if (filledDecimalScaleDigitWasBackspaced) {
      return selectionEnd - 1
    }

    if (filledDecimalScaleDigitWasChanged) {
      return selectionEnd + 1
    }

    if (restructuredByDecimal) {
      return getPositionOnRestructuringByDecimal()
    }

    if (fixedDecimalScaleFirstTyping) {
      return isNewValueNegative ? 2 : 1
    }

    if (keepCursorBeforeNegativeSign) {
      return 1
    }

    return defaultPosition
  }

  return getNewPosition()
}

export function identicalLocales(one: Locale, two: Locale) {
  const array1 = Array.isArray(one) ? one : [one]
  const array2 = Array.isArray(two) ? two : [two]

  return array1.join() === array2.join()
}
