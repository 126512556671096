import React from 'react'
import { useEventListener } from '../_hooks/EventListener'
import { mergeRefs } from '../_utils/mergeRefs'
import type { Props } from '../_utils/types'

interface ClickOutsideProps extends Props {
  /**
   * @since 10.19.0
   */
  container?: any
  /**
   * @since 10.19.0
   */
  containerClass?: string
  /**
   * @since 10.19.0
   */
  onClickOutside?: (event: React.MouseEvent<Element, Event>) => any
  /**
   * @since 10.19.0
   */
  tabIndex?: number
}

/**
 * @deprecatedSince 11
 * @deprecated Intended for internal library development.
 */
export const ClickOutside = React.forwardRef<HTMLElement, ClickOutsideProps>(
  function ClickOutside(
    { children, container, containerClass, onClickOutside, ...props },
    ref
  ) {
    const Component = container || 'div'

    const scope = React.useRef<HTMLElement>(null)

    // need useCallback?
    const handler: React.EventHandler<any> = (event) => {
      const current = scope.current as HTMLElement

      if (!current) {
        return
      }

      onClickOutside && !current.contains(event.target) && onClickOutside(event)
    }

    const options = { capture: true }

    useEventListener({ event: 'click', handler, options })

    useEventListener({ event: 'touchend', handler, options })

    return (
      <Component
        {...props}
        className={containerClass}
        ref={mergeRefs(ref, scope)}
      >
        {children}
      </Component>
    )
  }
)

ClickOutside.displayName = 'ClickOutside'
