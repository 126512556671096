import { ModalProps, ModalCloseableHeaderProps, ModalBodyProps, ConfirmModalProps } from '@procore/core-react/dist/_typedoc/Modal/Modal.types.json';
import { Props } from '@procore/core-react/dist/_typedoc/_utils/types.json';
import * as React from 'react';
export default {
  ModalProps,
  ModalCloseableHeaderProps,
  ModalBodyProps,
  ConfirmModalProps,
  Props,
  React
};