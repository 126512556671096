import React from 'react'
import ReactDOM from 'react-dom'
import { useZIndexContext } from '../_hooks/ZIndex'
import { StyledPortal } from './Portal.styles'
import type { PortalProps } from './Portal.types'

/**
 * @deprecatedSince 11
 * @deprecated Intended for internal library development.
 * @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_coverage-portal--defaults)
 */
export function Portal({
  children,
  container,
  ...props
}: PortalProps): React.ReactPortal {
  const zIndexCtx = useZIndexContext()

  return ReactDOM.createPortal(
    <StyledPortal {...props} style={{ zIndex: zIndexCtx.value }}>
      {children}
    </StyledPortal>,
    container || document.body
  )
}
