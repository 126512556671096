import {
  Box,
  Form,
  SegmentedController,
  useFormContext,
} from '@procore/core-react'
import { pick } from 'ramda'
import React from 'react'

const styles = {
  backgroundColor: '#444',
  color: '#f1f1f1',
  fontSize: '0.8rem',
}

export function FormDebugger(props: any = {}) {
  const fields = useFormContext()

  let record = {}
  if (props.all) {
    record = fields
  } else {
    record = pick(Object.keys(props), fields)
  }

  return (
    <Box flex="1" padding="md" margin="lg none" style={styles}>
      <pre>{JSON.stringify(record, null, 2)}</pre>
    </Box>
  )
}

type FormViewControllerProps = {
  setView: (view: React.ComponentProps<typeof Form>['view']) => void
  view: React.ComponentProps<typeof Form>['view']
}

export function FormViewController({ setView, view }: FormViewControllerProps) {
  const Component = () => (
    <SegmentedController block>
      <SegmentedController.Segment
        active={view === 'create'}
        onClick={(e) => setView('create')}
      >
        Create
      </SegmentedController.Segment>
      <SegmentedController.Segment
        active={view === 'read'}
        onClick={(e) => setView('read')}
      >
        Read
      </SegmentedController.Segment>
      <SegmentedController.Segment
        active={view === 'update'}
        onClick={(e) => setView('update')}
      >
        Update
      </SegmentedController.Segment>
    </SegmentedController>
  )
  return (
    <Box marginBottom="lg">
      <Form.Row>
        <Form.Field
          as={Component}
          colStart={3}
          colWidth={8}
          label="Controller for docs only"
          name="view_controller"
        />
      </Form.Row>
    </Box>
  )
}

type FormViewControllerState = {
  setView?: FormViewControllerProps['setView']
  view?: FormViewControllerProps['view']
}

export function useFormViewControllerState(
  initial: FormViewControllerState = {}
) {
  const [view, setView] = React.useState(initial.view || 'create')

  return {
    setView,
    view,
  }
}
