import React from 'react'

/* TYPES */

interface InjectedScriptConfig {
  id: string
  src: string
}

interface InjectedScriptApi {
  isLoaded: boolean
  setLoaded: (isLoaded: boolean) => void
}

interface Props extends InjectedScriptConfig {
  children?: React.ReactNode
}

interface RenderProps extends InjectedScriptConfig {
  children?: ((props: InjectedScriptApi) => React.ReactNode) | null
}

/* HOOK */

/**
 * @deprecatedSince 11
 * @deprecated Not a concern of the Design System. See source to duplicate https://github.com/procore/core/blob/%40procore/core-react%4010.30.0/packages/core-react/src/state/InjectedScript.tsx#L25
 */
export function useInjectedScript({ id, src }: InjectedScriptConfig) {
  const [isLoaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    const existingScript = document.getElementById(id)

    if (existingScript) {
      setLoaded(true)
    } else {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.id = id
      script.onload = () => {
        setLoaded(true)
      }
      script.src = src

      document.head && document.head.appendChild(script)
    }
  }, [id, src])

  return { isLoaded, setLoaded }
}

/* RENDER PROP */
/**
 * @deprecatedSince 11
 * @deprecated Not a concern of the Design System. See source to duplicate https://github.com/procore/core/blob/%40procore/core-react%4010.30.0/packages/core-react/src/state/InjectedScript.tsx#L25
 */
export function InjectedScript({ children = null, ...props }: RenderProps) {
  const injectedScript = useInjectedScript(props)

  return typeof children === 'function' ? children(injectedScript) : children
}
