import React from 'react'

function useFirstMountState(): boolean {
  const isFirst = React.useRef(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}

/**
 * @description useUpdateEffect can be used to perform a side effect when the component updates, except when it's first mounted
 */
export const useUpdateEffect: typeof React.useEffect = (effect, deps) => {
  const isFirstMount = useFirstMountState()

  React.useEffect(() => {
    if (!isFirstMount) {
      return effect()
    }
  }, deps)
}
