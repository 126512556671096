import styled, { css } from 'styled-components'
import {
  breakpointMinWidthValues,
  breakpointRawMinWidthValues,
} from '../Grid/Grid.constants'
import { H2 } from '../Semantic'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

const MIN_CARD_HEIGHT = 364

export const StyledSplitViewCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: ${spacing.sm}px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  min-height: ${MIN_CARD_HEIGHT}px;
  position: relative;
  width: 100%;

  @media (max-width: ${breakpointMinWidthValues.tabletMd}) {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

export const StyledSplitViewCardMain = styled.div<{ $open: boolean }>`
  background-color: ${colors.white};
  border-top-left-radius: ${spacing.sm}px;
  border-bottom-left-radius: ${spacing.sm}px;
  position: relative;
  width: 60%;
  transition: all ease 500ms;

  @media (max-width: ${breakpointMinWidthValues.tabletMd}) {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: ${spacing.sm}px;
    margin-bottom: ${spacing.sm}px;
    width: 100%;
  }

  ${({ $open }) =>
    !$open &&
    css`
      border-radius: ${spacing.sm}px;
      width: 100%;
    `}
`

export const StyledSplitViewCardAside = styled.div<{
  $open: boolean
  $hasShadow: boolean
}>`
  background-color: ${colors.white};
  border-left: 1px solid ${colors.gray85};
  border-top-right-radius: ${spacing.sm}px;
  border-bottom-right-radius: ${spacing.sm}px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 40%;
  min-height: ${MIN_CARD_HEIGHT}px;
  min-width: 400px;
  transition: all ease 500ms;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;

  @media screen and (max-width: ${breakpointRawMinWidthValues.tabletSm}px) {
    min-width: unset;
  }

  @media (max-width: ${breakpointMinWidthValues.tabletMd}) {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: ${spacing.sm}px;
    position: relative;
    width: 100%;
  }

  ${({ $open }) =>
    !$open &&
    css`
      border: none;
      border-radius: ${spacing.sm}px;
      overflow: hidden !important;

      @media (min-width: ${breakpointMinWidthValues.tabletMd}) {
        min-width: 0;
        width: 0 !important;
      }

      @media (max-width: ${breakpointMinWidthValues.tabletMd}) {
        display: none;
      }
    `}

  ${({ $hasShadow }) =>
    $hasShadow &&
    css`
      box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.1);
    `}
`

export const StyledSplitViewCardHeading = styled(H2)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`

export const StyledSplitViewCardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.sm}px;
  min-height: 36px;
`

export const StyledSplitViewCardHeader = styled.div`
  border-bottom: 1px solid ${colors.gray85};
  display: flex;
  flex-direction: column;
  padding: ${spacing.lg}px ${spacing.lg}px 0;
`

export const StyledSplitViewCardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${spacing.lg}px ${spacing.lg}px 0;
  overflow-y: auto;
`

export const StyledSplitViewCardFooter = styled.div<{ $hasShadow?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${spacing.lg}px;

  ${({ $hasShadow }) =>
    $hasShadow &&
    css`
      box-shadow: 0 -4px 6px 0 #0000001a;
    `}
`
