import React from 'react'
import type { Props } from '../_utils/types'
import type {
  SplitViewCardContextAPI,
  SplitViewCardProps,
} from './SplitViewCard.types'

export const SplitViewCardContext =
  React.createContext<SplitViewCardContextAPI>({
    open: false,
    onOpen: () => {},
    onClose: () => {},
    isClosable: true,
    isScrolling: false,
    setIsScrolling: () => {},
  })

export const SplitViewCardContextProvider = ({
  children,
  isInitiallyOpen = false,
  onClose,
  isClosable,
}: React.PropsWithChildren<Props & SplitViewCardProps>) => {
  const [open, setOpen] = React.useState(isInitiallyOpen)
  const [isScrolling, setIsScrolling] = React.useState(false)

  const onOpen = () => {
    setOpen(true)
  }

  const handleClose = React.useCallback(() => {
    setOpen(false)

    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <SplitViewCardContext.Provider
      value={{
        open,
        onOpen,
        onClose: handleClose,
        isClosable,
        isScrolling,
        setIsScrolling,
      }}
    >
      {children}
    </SplitViewCardContext.Provider>
  )
}

export const useSplitViewCard = () => React.useContext(SplitViewCardContext)
