import type {
  Middleware,
  MiddlewareData,
  offset,
  Placement as FloatingPlacement,
  ReferenceType,
} from '@floating-ui/react-dom'
import { autoUpdate, useFloating } from '@floating-ui/react-dom'
import type { CSSProperties } from 'react'
import type { Placement } from '../_utils/placement'
import { getPlacement } from '../_utils/placement'

export type OverlayOffsetValue = Parameters<typeof offset>[0]

export type OverlayRefType = (node: HTMLElement | null) => void

export type ReferenceRefType = (node: ReferenceType | null) => void

export type OverlayMiddleware = Middleware

export type OverlayPlacement = FloatingPlacement

export function useOverlay({
  placement,
  middleware,
}: {
  placement: Placement
  middleware?: Middleware[]
}): {
  isPositioned: boolean
  overlayRef: (node: HTMLElement | null) => void
  referenceRef: (node: ReferenceType | null) => void
  /** Dimensions of the floating element should not change before and after being positioned
   * https://floating-ui.com/docs/computeposition#initial-layout
   * https://github.com/floating-ui/floating-ui/issues/1740 */
  overlayStyle: CSSProperties
  middlewareData: MiddlewareData
  placement: OverlayPlacement
} {
  const {
    isPositioned,
    middlewareData,
    placement: currentPlacement,
    refs: { setReference: referenceRef, setFloating: overlayRef },
    x,
    y,
    strategy,
  } = useFloating({
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    placement: getPlacement(placement),
    middleware,
  })

  return {
    isPositioned,
    overlayStyle: {
      left: `${x}px`,
      top: `${y}px`,
      position: strategy,
      width: 'max-content',
    },
    overlayRef,
    referenceRef,
    middlewareData,
    placement: currentPlacement,
  }
}
