import React from 'react'
import { useListNavigation } from '../../_hooks/ListNavigation'
import { isFunction } from '../../_utils/isFunction'
import type { DivAttributes } from '../../_utils/types'
import type { FileTokenEntry, FileTokenListProps } from './FileToken.types'
import { StyledFileToken, StyledTokenList } from './FileTokenList.styles'

function noop() {}

function getProgress({ progress }: FileTokenEntry) {
  return progress ?? 100
}

function isUndefined(value: any): value is undefined {
  return typeof value === 'undefined'
}

export const FileTokenList = React.forwardRef<
  HTMLDivElement,
  FileTokenListProps & Omit<DivAttributes, 'onClick'>
>(function FileTokenList(
  { tokens, onClick, onClose = noop, qa, onKeyDown, ...props },
  ref
) {
  const focusedTokenRef = React.useRef<HTMLDivElement>(null)
  const [isListFocused, setIsListFocused] = React.useState(false)

  const navigation = useListNavigation({
    circular: false,
    initialIndex: 0,
    size: tokens.length,
  })

  React.useEffect(() => {
    navigation.setSize(tokens.length)
    if (tokens.length === 0) setIsListFocused(false)
  }, [navigation, tokens.length])

  React.useEffect(() => {
    if (focusedTokenRef.current && isListFocused) {
      focusedTokenRef.current.focus()
    }
  }, [isListFocused, navigation])

  const onKeyDownHandler = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isListFocused) return

      const token = tokens[navigation.index]

      switch (e.key) {
        case 'Backspace':
        case 'Delete':
          e.preventDefault()
          if (!e.repeat) {
            onClose(token)
            if (tokens.length >= 1) {
              navigation.set(Math.max(0, navigation.index - 1))
            }
          }
          break
        case 'Space Bar':
        case ' ':
          e.preventDefault()
          const { error } = token

          if (isUndefined(error) && getProgress(token) === 100) {
            onClick?.(token)
          }
          break
        case 'ArrowUp':
        case 'Up':
        case 'ArrowLeft':
        case 'Left':
          e.preventDefault()
          navigation.decrement()
          break
        case 'ArrowDown':
        case 'Down':
        case 'ArrowRight':
        case 'Right':
          e.preventDefault()
          navigation.increment()
          break
        default:
      }
      onKeyDown && onKeyDown(e)
    },
    [isListFocused, navigation, onClick, onClose, onKeyDown, tokens]
  )

  return (
    <StyledTokenList
      {...props}
      ref={ref}
      onFocus={() => setIsListFocused(true)}
      onBlur={() => setIsListFocused(false)}
      onKeyDown={onKeyDownHandler}
    >
      {tokens.map((token, index) => {
        const isFocused = navigation.index === index

        return (
          <StyledFileToken
            data-qa={qa?.fileToken?.(token.id)}
            qa={qa}
            key={token.id}
            ref={isFocused ? focusedTokenRef : null}
            tabIndex={isFocused ? 0 : -1}
            progressValue={getProgress(token)}
            errorMessage={token.error}
            fileName={token.name}
            onFocus={() => navigation.set(index)}
            onLabelClick={isFunction(onClick) ? () => onClick(token) : onClick}
            onClose={() => onClose(token)}
          />
        )
      })}
    </StyledTokenList>
  )
})

FileTokenList.displayName = 'FileTokenList'
