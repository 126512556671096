import type { OffsetByBreakpoint, SpanByBreakpoint } from '../Grid/Grid.types'
import type { DetailPageWidth } from './DetailPage.types'

export const gridConfig: Record<
  DetailPageWidth,
  { width: SpanByBreakpoint | number; offset: OffsetByBreakpoint | number }
> = {
  md: {
    width: {
      desktopMd: 8,
      desktopSm: 8,
      tabletLg: 8,
      tabletMd: 10,
      tabletSm: 12,
      mobileLg: 12,
    },
    offset: {
      desktopMd: 2,
      desktopSm: 2,
      tabletLg: 2,
      tabletMd: 1,
      tabletSm: 0,
      mobileLg: 0,
    },
  },
  lg: {
    width: {
      desktopMd: 10,
      desktopSm: 10,
      tabletLg: 10,
      tabletMd: 10,
      tabletSm: 12,
      mobileLg: 12,
    },
    offset: {
      desktopMd: 1,
      desktopSm: 1,
      tabletLg: 1,
      tabletMd: 1,
      tabletSm: 0,
      mobileLg: 0,
    },
  },
  xl: {
    width: 12,
    offset: 0,
  },
  block: {
    width: 12,
    offset: 0,
  },
}
