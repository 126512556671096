import React from 'react'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { DivAttributes, Props } from '../_utils/types'
import {
  StyledBodyCell,
  StyledBodyRow,
  StyledHead,
  StyledHeadCell,
  StyledShelf,
} from './TableShelf.styles'

export const Shelf = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function Shelf({ children, ...props }, ref) {
    return (
      <StyledShelf ref={ref} {...props}>
        {children}
      </StyledShelf>
    )
  }
)

export const Head = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function Head({ children, ...props }, ref) {
    return (
      <StyledHead ref={ref}>
        <StyledBodyRow {...props}>{children}</StyledBodyRow>
      </StyledHead>
    )
  }
)

export const HeadCell = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function HeadCell({ children, ...props }, ref) {
    return (
      <StyledHeadCell ref={ref} {...props}>
        {children}
      </StyledHeadCell>
    )
  }
)

export const Body = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function Body({ children, ...props }, ref) {
    return (
      <div ref={ref} {...props}>
        {children}
      </div>
    )
  }
)

export const BodyCell = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function BodyCell({ children, ...props }, ref) {
    return (
      <StyledBodyCell ref={ref} {...props}>
        {children}
      </StyledBodyCell>
    )
  }
)

export const BodyRow = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function BodyRow({ children, ...props }, ref) {
    return (
      <StyledBodyRow ref={ref} {...props}>
        {children}
      </StyledBodyRow>
    )
  }
)

Shelf.displayName = 'Shelf'

Body.displayName = 'Shelf.Body'

BodyCell.displayName = 'Shelf.BodyCell'

BodyRow.displayName = 'Shelf.BodyRow'

Head.displayName = 'Shelf.Head'

HeadCell.displayName = 'Shelf.HeadCell'

export const TableShelf = addSubcomponents(
  {
    Body,
    BodyCell,
    BodyRow,
    Head,
    HeadCell,
  },
  Shelf
)
