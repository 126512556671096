import { InteractableTileProps, LinkTileProps, SelectableTileProps, HeroTextProps, HeroMediaProps } from '@procore/core-react/dist/_typedoc/Tile/Tile.types.json';
import { Props } from '@procore/core-react/dist/_typedoc/_utils/types.json';
import * as React from 'react';
export default {
  InteractableTileProps,
  LinkTileProps,
  SelectableTileProps,
  HeroTextProps,
  HeroMediaProps,
  Props,
  React
};