import React from 'react'
import { Avatar } from '../Avatar/Avatar'
import type { TypographyProps } from '../Typography/Typography.types'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { SpanAttributes } from '../_utils/types'
import {
  ContactItemAvatar,
  ContactItemAvatarWrapper,
  ContactItemText,
  ContactItemTextWrapper,
  ContactItemWrapper,
} from './ContactItem.styles'
import type { ContactItemProps } from './ContactItem.types'

const ContactItem_ = React.forwardRef<HTMLDivElement, ContactItemProps>(
  function ContactItem(
    {
      imageUrl,
      icon,
      initials,
      selected = false,
      disabled = false,
      clickable = false,
      children,
      ...props
    },
    ref
  ) {
    const showAvatar = imageUrl || icon || initials

    return (
      <ContactItemWrapper
        $selected={selected}
        $disabled={disabled}
        $clickable={clickable}
        ref={ref}
        {...props}
      >
        {showAvatar && (
          <ContactItemAvatarWrapper>
            <ContactItemAvatar aria-hidden size="lg" disabled={disabled}>
              {(() => {
                if (imageUrl) return <Avatar.Portrait imageUrl={imageUrl} />
                if (icon) return <Avatar.Icon icon={icon} />
                if (initials) return <Avatar.Label>{initials}</Avatar.Label>
              })()}
            </ContactItemAvatar>
          </ContactItemAvatarWrapper>
        )}
        <ContactItemTextWrapper>{children}</ContactItemTextWrapper>
      </ContactItemWrapper>
    )
  }
)

const Title = React.forwardRef<
  HTMLDivElement,
  SpanAttributes & TypographyProps
>(function Title({ children, ...props }, ref) {
  return (
    <ContactItemText weight="bold" color="gray15" ref={ref} {...props}>
      {children}
    </ContactItemText>
  )
})

const Description = React.forwardRef<
  HTMLDivElement,
  SpanAttributes & TypographyProps
>(function Description({ children, ...props }, ref) {
  return (
    <ContactItemText weight="regular" color="gray45" ref={ref} {...props}>
      {children}
    </ContactItemText>
  )
})

ContactItem_.displayName = 'ContactItem'

Title.displayName = 'ContactItem.Title'

Description.displayName = 'ContactItem.Description'

/**

 The contact item component is a more complex alternative to a standard line
 of text found in a list or form component. Use this component when you have
 a list or set of items that can benefit from an additional line of content or
 an avatar.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-contact-item--demo)

 @see [Design Guidelines](https://design.procore.com/contact-item)

*/
export const ContactItem = addSubcomponents(
  {
    Title,
    Description,
  },
  ContactItem_
)
