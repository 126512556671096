import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../utils/styles'

export const FooterView = styled.footer`
  margin: ${layout.pagePadding}px;
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 0 0 auto;
  font-size: 12px;
  height: ${layout.footerHeight}px;
  justify-content: center;

  a {
    color: ${colors._006ddf};
    display: inline-block;
    padding-left: 16px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export function Footer() {
  return (
    <FooterView>
      ©{new Date().getFullYear()} Procore. All rights reserved
      <a target="_blank" href="https://www.procore.com/legal/privacy">
        Privacy Policy
      </a>
      <a target="_blank" href="https://www.procore.com/legal/terms-of-service">
        Procore Terms of Use
      </a>
      <a
        target="_blank"
        href="https://developers.procore.com/terms_and_conditions"
      >
        API Terms of Use
      </a>
    </FooterView>
  )
}
