import { Check, Clear, Error } from '@procore/core-icons'
import React from 'react'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type {
  ButtonAttributes,
  DivAttributes,
  Props,
  SpanAttributes,
} from '../_utils/types'
import { StyledToast, StyledToastButton, StyledToastText } from './Toast.styles'
import type { ToastProps } from './Toast.types'

/**
 * Toasts are concise and are written in sentence case with punctuation.
 * Toasts have a suggested character count limit of 65.

  @since 11.12.0
 */
const Text = React.forwardRef<HTMLSpanElement, SpanAttributes & Props>(
  function Label({ children, ...props }, ref) {
    return (
      <StyledToastText
        aria-live="assertive"
        aria-atomic="true"
        ref={ref}
        {...props}
      >
        {children}
      </StyledToastText>
    )
  }
)

/**
 Button to dismiss/close/hide the Toast. For accessible 'enough time' patterns,
 Toasts can be extended and have an action to dismiss the Toast. Otherwise,
 Toasts do not present the user with an action. If an action needs to be performed
 because of the particular message, use a banner.

 @since 11.12.0

 */
const Dismiss = React.forwardRef<HTMLButtonElement, ButtonAttributes & Props>(
  function ToastDismiss({ ...props }, ref) {
    const I18n = useI18nContext()
    return (
      <StyledToastButton
        ref={ref}
        variant="tertiary"
        aria-hidden="false"
        aria-label={I18n.t('core.toast.a11y.dismiss')}
        {...props}
      >
        <Clear />
      </StyledToastButton>
    )
  }
)

export const Toast_ = React.forwardRef<
  HTMLDivElement,
  DivAttributes & ToastProps
>(function Toast({ children, variant, ...props }, ref) {
  return (
    <StyledToast {...props} $variant={variant} ref={ref}>
      {variant === 'success' ? <Check size="lg" /> : <Error size="lg" />}
      {typeof children === 'string' ||
      (children && typeof (children as Array<unknown>)[0] === 'string') ? (
        // legacy path automates and wraps direct text with the subcomponent
        <StyledToastText $legacy>{children}</StyledToastText>
      ) : (
        children
      )}
    </StyledToast>
  )
})

Toast_.displayName = 'Toast'
Dismiss.displayName = 'Toast.Dismiss'
Text.displayName = 'Toast.Text'

/**

 We use toasts to temporarily display information to a user. For example,
 as a confirmation that an action was successfully or unsuccessfully completed.

 Toasts do not present the user with an action. If an action needs to be
 performed because of the particular message, use a banner. However, for
 accessible 'enough time' patterns, Toasts can be extended and can have
 an action to dismiss the Toast.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-toast--demo)

 @see [Design Guidelines](https://design.procore.com/toast)

 */
export const Toast = addSubcomponents(
  {
    Dismiss,
    Text,
  },
  Toast_
)
