import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { groupBy } from 'ramda'
import { Box, H3, UL } from '@procore/core-react'
import styled from 'styled-components'

function getDisplayTitle(node) {
  return node.frontmatter.sidebar === false
    ? `${node.fields.parentTitle} - ${node.frontmatter.title}` // For components, the page name is often "React" so you need the parent name for a full description
    : node.frontmatter.title // For all non-component pages
}

const CapitalizedLink = styled(Link)`
  text-transform: capitalize;
`

const HiddenPagesList: React.FC<{}> = function HiddenPagesList() {
  const data = useStaticQuery(graphql`
    query HiddenPagesListQuery {
      allMdx(
        filter: {
          frontmatter: { hidden: { eq: true } }
          slug: { regex: "/^(?!.*css)/" }
        }
        sort: { fields: slug }
      ) {
        nodes {
          frontmatter {
            title
            sidebar
          }
          fields {
            parentTitle
          }
          slug
        }
      }
    }
  `)

  // start of slug is "web"
  const sections = groupBy((node) => node.slug.split('/')[1], data.allMdx.nodes)

  return Object.entries(sections).map(([groupName, nodes]) => (
    <>
      <Box marginTop="lg" marginBottom="md">
        <H3 style={{ textTransform: 'capitalize' }}>{groupName}</H3>
      </Box>

      <UL>
        {nodes
          .sort((a, b) => getDisplayTitle(a) > getDisplayTitle(b))
          .map((node: any) => {
            return (
              <li>
                <CapitalizedLink to={'/' + node.slug} key={node.slug}>
                  {getDisplayTitle(node)}
                </CapitalizedLink>
              </li>
            )
          })}
      </UL>
    </>
  ))
}

export default HiddenPagesList
