import React from 'react'
import { triggerListeners } from '../_utils/triggerListeners'
import type { RenderProp } from '../_utils/types'
import { useHotkey } from './Hotkey'

/* TYPES */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export type TriggerVariant =
  | 'click'
  | 'hover'
  | 'none'
  | 'contextmenu'
  | 'focus'
  | 'always'

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface TriggerConfig {
  disable: React.EventHandler<any>
  disableKeys?: string[]
  enable: React.EventHandler<any>
  enableKeys?: string[]
  isEnabled?: boolean
  trigger?: TriggerVariant | TriggerVariant[]
  triggerRef: React.RefObject<HTMLElement>
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface TriggerProps extends TriggerConfig {
  children: React.ReactNode
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface TriggerRenderProps
  extends TriggerConfig,
    RenderProp<TriggerApi> {}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface TriggerApi {
  disable: React.EventHandler<any>
  enable: React.EventHandler<any>
  isVisible: boolean
  toggle: React.EventHandler<any>
}

/* HOOK */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useTrigger({
  disable,
  disableKeys = [],
  enable,
  enableKeys = [],
  isEnabled = false,
  trigger = 'click',
  triggerRef,
}: TriggerConfig): TriggerApi {
  triggerListeners({
    off: disable,
    on: enable,
    scope: triggerRef,
    trigger,
    value: isEnabled,
  })

  useHotkey({
    key: enableKeys,
    handler: enable,
    preventDefault: true,
    scope: triggerRef,
  })

  useHotkey({
    key: disableKeys,
    handler: disable,
    preventDefault: true,
    scope: triggerRef,
  })

  // prevent keyup event from firing on FF
  useHotkey({
    event: 'keyup',
    key: [...enableKeys, ...disableKeys],
    handler: () => {},
    preventDefault: true,
    scope: triggerRef,
  })

  return {
    disable,
    enable,
    isVisible: isEnabled,
    toggle: isEnabled ? disable : enable,
  }
}

/* RENDER PROP */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function Trigger({
  children,
  triggerRef,
  ...props
}: TriggerRenderProps) {
  const trigger = useTrigger({ ...props, triggerRef })

  const element =
    typeof children === 'function'
      ? (children as (props: TriggerApi) => React.ReactElement)(trigger)
      : (children as React.ReactElement)

  return React.cloneElement(element, {
    open: trigger.isVisible,
  })
}
