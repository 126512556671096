import { Error, Help } from '@procore/core-icons/dist'
import styled, { css } from 'styled-components'
import { checkboxSize } from '../Checkbox/Checkbox.styles'
import { StyledFieldHeader } from '../Field/Field.styles'
import { Required } from '../Required/Required'
import { Label } from '../Semantic/Semantic'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getGapOutlineFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

const columnGutter = '24px'
const defaultLineHeight = '20px'
const textEditorOutputListPadding = '10px 0 10px 40px'

const traditionalBorder = 'rgb(216, 216, 216)'
const traditionalBgGray = 'rgb(242, 242, 242)'
const traditionalPadding = '10px'
const traditionalFontSize = '13px'
const traditionalLineHeight = '20px'

function getGridColumns(colStart: number, colEnd: number, colIeSpan: number) {
  return css`
    -ms-grid-column: ${colStart};
    grid-column-start: ${colStart};
    grid-column-end: ${colEnd};
    -ms-grid-column-span: ${colIeSpan};
  `
}

function applyStylesOnlyToIE11(styles: string) {
  return css`
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      ${styles}
    }
  `
}

export const StyledFormFieldMain = styled.div<{
  $read: boolean
  $colStart: number
  $colEnd: number
  $colIeSpan: number
}>`
  -ms-grid-row: 2;
  grid-row: 2;

  ${({ $colStart, $colEnd, $colIeSpan }) =>
    getGridColumns($colStart, $colEnd, $colIeSpan)}

  ${({ $read }) =>
    $read &&
    css`
      word-wrap: break-word;
      color: ${colors.gray15};
      ${getTypographyIntent('body')};
    `}

  // IE11 support grid-column-gap
  // bug: if a field's first column isn't at 1,
  // the field will fill the padding and be wider
  ${applyStylesOnlyToIE11(`
      padding-left: ${columnGutter};

      &:nth-child(2) {
        padding-left: 0;
      }
  `)}
`

export const StyledLabel = styled(Label)`
  word-wrap: break-word;
  word-break: break-word;
`

export const StyledDescription = styled.div`
  ${getTypographyIntent('body')}
  color: ${colors.gray45};
  word-wrap: break-word;
  word-break: break-word;
`

export const StyledCheckboxInlineDescription = styled(StyledDescription)<{
  $read: boolean
}>`
  margin-top: ${spacing.xs}px;
  ${({ $read }) => {
    if (!$read) {
      return css`
        padding-left: ${checkboxSize + spacing.md}px;
      `
    }
  }}
`

export const StyledFormFieldHeader = styled(StyledFieldHeader)<{
  $colStart: number
  $colEnd: number
  $colIeSpan: number
}>`
  -ms-grid-row: 1;
  grid-row: 1;
  align-self: end;
  margin-bottom: ${spacing.xs}px;

  ${({ $colStart, $colEnd, $colIeSpan }) =>
    getGridColumns($colStart, $colEnd, $colIeSpan)}

  // IE11 support grid-column-gap
  // bug: if a field's first column isn't at 1,
  // the field will fill the padding and be wider
  ${applyStylesOnlyToIE11(`
    padding-left: ${columnGutter};

    &:first-child {
      padding-left: 0;
    }

    ${StyledFormFieldMain} {
      padding-left: ${columnGutter};

      &:nth-child(2) {
        padding-left: 0;
      }
    }
  `)}

  * + ${StyledDescription} {
    margin-top: ${spacing.xs}px;
  }
`

export const StyledFormFieldRequiredMark = styled(Required)`
  padding-left: ${spacing.xs}px;
  vertical-align: top;
`

export const dataQaFieldTooltipIcon = 'form-field-tooltip-icon'

export const StyledFormFieldTooltipIcon = styled(Help).attrs(() => ({
  'data-qa': dataQaFieldTooltipIcon,
}))`
  margin-left: ${spacing.sm}px;
  color: ${colors.gray45};
  vertical-align: middle;

  &:focus-visible {
    border-radius: 50%;
    ${getGapOutlineFocus}
    // Icon does not touch viewBox borders. Already has 1px gap
    outline-offset: 0;
  }
`

export const StyledFormFieldErrorIcon = styled(Error)`
  vertical-align: sub;
`

export const StyledFormFieldBanner = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 22px;
  margin-top: ${spacing.xs}px;
  margin-bottom: 2px;
  word-break: break-word;
  word-wrap: break-word;
  color: ${colors.red50};

  ${getTypographyIntent('body')}
`
export const StyledTraditionalFormColumn = styled.div<{
  $fullWidth?: boolean
  $output?: boolean
}>`
  padding: ${traditionalPadding};
  align-self: center;
  height: 100%;

  &:nth-child(1) {
    -ms-grid-column: 1;
  }
  &:nth-child(2) {
    -ms-grid-column: 2;
  }
  &:nth-child(3) {
    -ms-grid-column: 3;
  }
  &:nth-child(4) {
    -ms-grid-column: 4;
  }

  ${({ $fullWidth = false }) =>
    $fullWidth &&
    css`
      grid-column: 2 / 5;
      -ms-grid-column-span: 3;
    `}

  ${({ $output = false }) =>
    $output &&
    css`
      font-weight: bold;
      font-size: ${traditionalFontSize};
      line-height: ${traditionalLineHeight};
      word-wrap: break-word;
    `}
`

export const StyledFormOutputTextArea = styled.span`
  white-space: pre-wrap;
`

export const StyledFormOutputFiledset = styled.fieldset`
  border: none;
  padding: 0;
  margin-top: ${spacing.xs}px;
`
export const StyledFormRow = styled.div<{
  $traditional?: boolean
  $read?: boolean
}>`
  ${({ $traditional = false, $read = false }) =>
    $traditional
      ? css`
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: 20% 30% 20% 30%;
          grid-template-columns: 20% 30% 20% 30%;
          -ms-grid-rows: auto;
          grid-template-rows: auto;
          border-top: 1px solid ${traditionalBorder};
          background-color: ${colors.white};

          &:last-of-type {
            border-bottom: 1px solid ${traditionalBorder};
          }

          ${$read &&
          `
            &:nth-child(2n + 1) {
              background-color: ${traditionalBgGray};
            }
            
            & > ${StyledTraditionalFormColumn}:nth-child(2) {
              border-right: 1px solid ${traditionalBorder};
      
              &:last-child {
                border-right: none;
              }
            }
          `}
        `
      : css`
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
            minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
            minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
            minmax(0, 1fr);
          grid-template-columns: repeat(12, minmax(0, 1fr));
          -ms-grid-rows: minmax(${defaultLineHeight}, max-content)
            minmax(${defaultLineHeight}, max-content);
          grid-template-rows: minmax(${defaultLineHeight}, auto) minmax(
              ${defaultLineHeight},
              auto
            );
          justify-content: space-between;
          grid-column-gap: ${columnGutter};
        `}
`

export const formWrapper = css`
  display: inherit;
  flex-direction: inherit;
  height: inherit;
  max-height: inherit;
`

export const StyledForm = styled.div`
  ${formWrapper}
`
