import { EllipsisVertical } from '@procore/core-icons/dist'
import type { MouseEvent } from 'react'
import React from 'react'
import type { ButtonProps } from '../Button/Button.types'
import { Card } from '../Card/Card'
import { DropdownButton } from '../Dropdown/Dropdown'
import { Portal } from '../Portal'
import { useClickOutside } from '../_hooks/ClickOutside'
import { useI18nContext } from '../_hooks/I18n'
import { mergeRefs } from '../_utils/mergeRefs'
import type { ReactElementWithRef } from '../_utils/types'
import {
  defaultOptionRenderer,
  DropdownFlyoutContext,
  noop,
  transformOption,
  useDropdownFlyout,
  useDropdownFlyoutContext,
} from './DropdownFlyout.helpers'
import {
  StyledDropdownFlyout,
  StyledDropdownFlyoutItem,
} from './DropdownFlyout.styles'
import type {
  DropdownFlyoutProps,
  FlyoutCaptionProps,
  FlyoutItemProps,
  FlyoutListProps,
} from './DropdownFlyout.types'
import { rootId } from './DropdownFlyout.types'
import { useDropdownFlyoutOverlay } from './useDropdownFlyoutOverlay'

export const FlyoutCaption = React.forwardRef<
  HTMLDivElement,
  FlyoutCaptionProps
>(function FlyoutCaption(
  { option, highlighted, optionRenderer, ...props },
  ref
) {
  return (
    <StyledDropdownFlyoutItem
      {...props}
      ref={ref}
      data-highlighted={highlighted}
      data-flyout
    >
      {optionRenderer(option)}
    </StyledDropdownFlyoutItem>
  )
})

FlyoutCaption.displayName = 'FlyoutCaption'

export function FlyoutItem({ option }: FlyoutItemProps) {
  const overlayElRef = React.useRef<HTMLDivElement>(null)
  const {
    isHighlighted,
    isExpanded,
    onClick: _onClick,
    expand,
    collapse,
    optionRenderer,
  } = useDropdownFlyoutContext()

  const onMouseEnter = React.useCallback(() => expand(option), [option, expand])
  const onMouseLeave = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        e.relatedTarget instanceof Node &&
        overlayElRef.current &&
        !overlayElRef.current.contains(e.relatedTarget as Node)
      ) {
        collapse(option)
      }
    },
    [collapse, option]
  )

  const onClick = React.useCallback(() => _onClick(option), [_onClick, option])

  const [referenceEl, setReferenceEl] = React.useState<HTMLElement | null>(null)

  return (
    <>
      <FlyoutCaption
        ref={setReferenceEl}
        role="listitem"
        option={option.origin}
        onClick={onClick}
        optionRenderer={optionRenderer}
        highlighted={isHighlighted(option)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {Array.isArray(option.children) && isExpanded(option) ? (
        <Portal>
          <FlyoutList
            referenceEl={referenceEl}
            options={option.children}
            overlayRef={overlayElRef}
            placement="right-top"
          />
        </Portal>
      ) : null}
    </>
  )
}

export function FlyoutList({
  options,
  placement,
  referenceEl,
  overlayRef: overlayElRef,
  offset = 0,
}: FlyoutListProps) {
  const { overlayStyle, referenceRef, overlayRef } = useDropdownFlyoutOverlay({
    placement,
    offset: { mainAxis: offset },
  })

  React.useEffect(() => {
    referenceRef(referenceEl)
  }, [referenceEl, referenceRef])

  return (
    <Card
      ref={mergeRefs(overlayRef, overlayElRef)}
      shadowStrength={2}
      style={overlayStyle}
    >
      <StyledDropdownFlyout role="list">
        {options.map((option) => (
          <FlyoutItem key={option.id} option={option} />
        ))}
      </StyledDropdownFlyout>
    </Card>
  )
}

const DefaultButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function DefaultButton({ children, icon, ...props }, ref) {
    return (
      <DropdownButton
        {...props}
        icon={
          props.loading ? undefined : !icon && !children ? (
            <EllipsisVertical />
          ) : (
            icon
          )
        }
        arrow={Boolean(React.Children.count(children))}
        ref={ref}
      >
        {children}
      </DropdownButton>
    )
  }
)

/**

  The dropdown flyout allows for additional menu items to be nested within
  parent items, which allows more menu options for users.
  @a11y WARN: DropdownFlyotus hold no value and take an action. Focus is expected
    to be moved from a Dropdown to something else, to continue the user flow.
    If the action is inert and does not move focus, this is left to be added
    by the consumer.
  @since 10.19.0

  @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-dropdownflyout--demo)

  @see [Design Guidelines](https://design.procore.com/dropdown-flyout)

 */
export const DropdownFlyout = React.forwardRef<
  HTMLDivElement,
  DropdownFlyoutProps
>(function DropdownFlyout(
  {
    disabled,
    icon,
    label,
    loading,
    options: _options,
    onClick: _onClick = noop,
    optionRenderer = defaultOptionRenderer,
    children,
    placement = 'right-bottom',
    onKeyDown: _onKeyDown,
    onFocus: onFocus_,
    onBlur: onBlur_,
    onMouseDown: onMouseDown_,
    onMouseEnter: onMouseEnter_,
    onMouseLeave: onMouseLeave_,
    size,
    variant = 'secondary',
    ...props
  },
  ref
) {
  const innerRef = React.useRef<HTMLDivElement>(null)
  const containerRef = (ref as React.RefObject<HTMLInputElement>) || innerRef
  const overlayElRef = React.useRef<HTMLDivElement>(null)
  const targetRef = React.useRef<HTMLElement>(null)

  const flyoutOptions = React.useMemo(
    () => transformOption(_options, rootId),
    [_options]
  )

  const {
    options,
    collapse,
    expand,
    closeSelectedDropdown,
    openDropdown,
    isOpen,
    onClick,
    onKeyDown,
    setMouseOver,
    setFocused,
    isExpanded,
    isHighlighted,
  } = useDropdownFlyout({
    flyoutOptions,
    onClick: _onClick,
    onKeyDown: _onKeyDown,
  })

  useClickOutside({
    onClickOutside: isOpen ? closeSelectedDropdown : noop,
    refs: [containerRef],
  })

  const element =
    typeof children === 'function' ? children({ isOpen }) : children

  const I18n = useI18nContext()

  const ariaLabel =
    props['aria-label'] ||
    label ||
    I18n.t(`core.dropdown.${loading ? 'loading' : 'moreOptions'}`)

  const trigger: ReactElementWithRef = React.isValidElement(element) ? (
    element
  ) : (
    <DefaultButton
      aria-label={ariaLabel}
      children={label}
      disabled={disabled}
      loading={loading}
      icon={icon}
      size={size}
      variant={variant}
    />
  )

  function onBlur(e: React.FocusEvent<HTMLDivElement>) {
    setFocused(false)
    closeSelectedDropdown(e)
    onBlur_?.(e)
  }

  function onFocus(e: React.FocusEvent<HTMLDivElement>) {
    setFocused(true)
    onFocus_?.(e)
  }

  function onMouseDown(e: React.MouseEvent<HTMLDivElement>) {
    // Discard firing of blur event
    // when click was inside the dropdown
    if ((e.target as HTMLElement).dataset.flyout) {
      e.preventDefault()
      return
    }
    if (e.target instanceof Node && containerRef.current?.contains(e.target)) {
      e.preventDefault()
      targetRef.current?.focus()
    }
    onMouseDown_?.(e)
  }

  function onMouseLeave(e: React.MouseEvent<HTMLDivElement>) {
    setMouseOver(false)
    onMouseLeave_?.(e)
  }

  function onMouseEnter(e: React.MouseEvent<HTMLDivElement>) {
    setMouseOver(true)
    onMouseEnter_?.(e)
  }

  const [referenceEl, setReferenceEl] = React.useState<HTMLElement | null>(null)

  return (
    <div
      {...props}
      aria-label={undefined}
      ref={containerRef}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onMouseDown={onMouseDown}
      onBlur={onBlur}
    >
      {React.cloneElement(trigger, {
        ref: trigger.ref
          ? mergeRefs(trigger.ref, targetRef, setReferenceEl)
          : mergeRefs(targetRef, setReferenceEl),
        onClick: (e: MouseEvent<HTMLElement, 'click'>) => {
          trigger.props.onClick?.(e)
          openDropdown()
        },
        tabIndex: 0,
      })}
      <DropdownFlyoutContext.Provider
        value={{
          expand,
          collapse,
          onClick,
          isExpanded,
          isHighlighted,
          optionRenderer,
        }}
      >
        {isOpen && (
          <Portal>
            <FlyoutList
              options={options}
              overlayRef={overlayElRef}
              placement={placement}
              referenceEl={referenceEl}
              offset={4}
            />
          </Portal>
        )}
      </DropdownFlyoutContext.Provider>
    </div>
  )
})
