import * as IconsWithIconSVG from '@procore/core-icons/dist/icons/index'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../utils/styles'

const { IconSVG, ...Icons } = IconsWithIconSVG

const SearchView = styled.input`
  border: 1px solid ${colors.cccccc};
  box-sizing: border-box;
  color: ${colors._333333};
  font-size: 24px;
  outline: none;
  padding: 6px 16px;
  width: 100%;

  &:focus {
    border: 1px solid ${colors._3397ff};
    box-shadow: 0 0 0 2px ${colors.dcedff};
  }

  &::-webkit-input-placeholder {
    color: ${colors.cccccc};
  }
`

const IconListView = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  span {
    margin-right: 8px;
    text-align: right;
    width: 36px;
  }

  li {
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: default;
    display: inline-flex;
    height: 36px;
    margin: 2px 0;
    width: 25%;

    svg {
      margin: 0 8px;
    }

    &:hover {
      background: ${colors._006ddf};
      color: white;
    }
  }

  @media only screen and (max-width: 1280px) {
    li {
      width: 33.33%;
    }
  }

  @media only screen and (max-width: 960px) {
    li {
      width: 50%;
    }
  }

  @media only screen and (max-width: 640px) {
    li {
      width: 100%;
    }
  }
`

export const IconsListNew: React.FC<{}> = function () {
  const [search, setSearch] = React.useState('')

  return (
    <>
      <SearchView
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
      />
      <IconListView>
        {Object.keys(Icons)
          .filter((icon) =>
            icon.toLowerCase().includes(search.toLowerCase().trim())
          )
          .map((icon) => {
            const Icon = (Icons as any)[icon]

            return (
              <li key={icon}>
                <Icon /> {icon}
              </li>
            )
          })}
      </IconListView>
    </>
  )
}
