import React from 'react'
import type { MenuRef } from '../MenuImperative/MenuImperative.types'
import { OverlayTrigger } from '../OverlayTrigger/OverlayTrigger'
import { Select } from '../Select/Select'
import { isEventSource } from '../_hooks/ClickOutside'
import { useI18nContext } from '../_hooks/I18n'
import { TieredSelectContext } from './TieredSelect'
import type {
  Id,
  InternalTieredSelectProps,
  Tier,
  TierSelection,
} from './TieredSelect.types'
import { TieredSelectMenu } from './TieredSelectMenu'

function stopProp(e: React.KeyboardEvent<HTMLDivElement>) {
  if (e.key === 'Escape' || e.key === 'Esc') {
    e.stopPropagation()
  }
}
const empty: any[] = []

const hideKeys = {
  overlay: ['Escape', 'Esc'],
  target: ['Escape', 'Esc'],
}

export const LeafSelectionTieredSelect = React.forwardRef<
  HTMLDivElement,
  InternalTieredSelectProps
>(function LeafSelectionTieredSelect(
  {
    className,
    disabled = false,
    error = false,
    loading = false,
    onKeyDown, // goes no where
    options = empty,
    value = empty,
    ...props
  },
  ref
) {
  const {
    afterHide,
    afterShow,
    beforeHide,
    beforeShow,
    block,
    getGroupId,
    getNextGroupId,
    getValueString,
    i18nScope,
    isLeaf,
    onChange,
    onClear,
    onNavigate,
    onSearch,
    tabIndex,
  } = React.useContext(TieredSelectContext)
  const I18n = useI18nContext()

  const tieredSelectMenuRef = React.useRef<MenuRef>(null)
  const tieredSelectButtonRef = React.useRef<HTMLDivElement>(null)
  const clearRef = React.createRef<HTMLButtonElement>()

  const [valueString, setValueString] = React.useState<string | undefined>('')
  const [currentTier, setCurrentTier] = React.useState<Id>(null)

  // internal value used for navigation
  const [internalValue, setInternalValue] = React.useState<Tier[]>(value)
  // value to reset to if navigation occurs but no selection has been made
  const [previousValue, setPreviousValue] = React.useState<Tier[]>(value)

  // if value prop changes, update value state, current tier, and set button label
  // else open to root
  React.useEffect(
    function () {
      const lastEntryInValue = value[value.length - 1]

      if (value.length > 0) {
        setInternalValue(value)
        setPreviousValue(value)
        setValueString(getValueString(value))
        // show children of last tier in value if not a leaf node
        // otherwise we want to show the parent of the last tier in value
        if (isLeaf && !isLeaf(lastEntryInValue)) {
          setCurrentTier(getNextGroupId(lastEntryInValue))
        } else {
          setCurrentTier(getGroupId(lastEntryInValue))
        }
      } else {
        setCurrentTier(null)
        setInternalValue([])
        setPreviousValue([])
        setValueString('')
      }
    },
    [getGroupId, getValueString, getNextGroupId, isLeaf, value]
  )

  const internalOnChange = React.useCallback(
    function (selection: TierSelection) {
      setInternalValue(selection.value)
      setPreviousValue(selection.value)
      setValueString(getValueString(selection.value))
      setCurrentTier(getGroupId(selection.item))
      onChange(selection)
    },
    [getGroupId, onChange, getValueString]
  )

  const internalOnNavigate = React.useCallback(
    function (nextGroupId: Id, value: any[]) {
      setInternalValue(value)
      setCurrentTier(nextGroupId)
      onNavigate && onNavigate(nextGroupId, value)
    },
    [onNavigate]
  )

  const internalOnClear = React.useCallback(
    function (event: React.MouseEvent<HTMLButtonElement>) {
      setValueString('')
      setInternalValue([])
      setPreviousValue([])
      internalOnNavigate(null, [])
      onClear && onClear(event)
    },
    [internalOnNavigate, onClear]
  )

  const internalOnSearch = React.useCallback(
    function (event: any) {
      onSearch && onSearch(event)
    },
    [onSearch]
  )

  const internalBeforeHide = React.useCallback(
    function (e: Event) {
      const isEscapeKey =
        e instanceof KeyboardEvent && (e.key === 'Escape' || e.key === 'Esc')

      const isClickOutside =
        e instanceof MouseEvent &&
        !isEventSource({ current: tieredSelectMenuRef?.current?.el }, e)

      const lastValue = previousValue[previousValue.length - 1]

      if (valueString && (isClickOutside || isEscapeKey)) {
        setInternalValue(previousValue)
        if (isLeaf && isLeaf(lastValue)) {
          setCurrentTier(getGroupId(lastValue))
        } else {
          setCurrentTier(getNextGroupId(lastValue))
        }
      }
      return beforeHide && beforeHide(e)
    },
    [
      beforeHide,
      getGroupId,
      getNextGroupId,
      isLeaf,
      tieredSelectMenuRef,
      previousValue,
      valueString,
    ]
  )

  return (
    <OverlayTrigger
      autoFocus
      afterHide={afterHide}
      afterShow={afterShow}
      beforeHide={internalBeforeHide}
      beforeShow={(e: Event) => {
        return beforeShow && e.target !== clearRef.current && beforeShow(e)
      }}
      canFlip={true}
      hideKeys={hideKeys}
      overlay={
        <TieredSelectMenu
          currentTier={currentTier}
          options={options}
          loading={loading}
          menuRef={tieredSelectMenuRef}
          onNavigate={internalOnNavigate}
          onSearch={internalOnSearch}
          onChange={internalOnChange}
          value={internalValue}
          previousValue={previousValue}
          {...props}
          onKeyDown={stopProp}
        />
      }
      placement="bottom-left"
      ref={ref}
      trigger="click"
    >
      <Select.Button
        block={block}
        className={className}
        clearRef={clearRef}
        disabled={disabled}
        error={error}
        label={valueString}
        loading={loading}
        placeholder={I18n.t('placeholder', { scope: i18nScope })}
        onClear={internalOnClear}
        ref={tieredSelectButtonRef}
        tabIndex={tabIndex}
      >
        {valueString || I18n.t('placeholder', { scope: i18nScope })}
      </Select.Button>
    </OverlayTrigger>
  )
})
