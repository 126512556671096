import { breakpointRawMinWidthValues } from '../Grid/Grid.constants'
import { adjustMaxWidth } from './PageLayout.styles'

export const getSmallScreenBreakpointValue = () =>
  `(max-width: ${adjustMaxWidth(breakpointRawMinWidthValues.tabletMd)}px)`

export const getIsUsingSmallScreen = () => {
  return globalThis.matchMedia?.(getSmallScreenBreakpointValue()).matches
}

export const wait = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms))

export function getBottomOffsetForFooterUseCase(
  footerEl: HTMLDivElement,
  globalBottomOffset: number
) {
  const viewportHeight = globalThis.innerHeight
  const footerRect = footerEl.getBoundingClientRect()
  const footerTop = footerRect.y ?? footerRect.top
  const footerHeight = footerRect.height
  const isSmallScreen = getIsUsingSmallScreen()
  const isPageFooterSticky = footerEl.dataset.sticky === 'sticky'

  if (isSmallScreen) {
    if (isPageFooterSticky) {
      return globalBottomOffset + viewportHeight - footerTop
    }

    return globalBottomOffset + footerHeight
  }

  if (isPageFooterSticky) {
    // align with the sticky footer in the rest of use cases
    return viewportHeight - footerRect.bottom
  }

  return null
}

export function getGlobalBottomOffset(containerEl: HTMLDivElement) {
  const lowerBoundary = containerEl.getBoundingClientRect()
  const lowerBoundaryTopOffset = lowerBoundary.bottom
  const viewportHeight = document.documentElement.clientHeight
  return Math.max(viewportHeight - lowerBoundaryTopOffset, 0)
}

export function getGlobalTopOffset(containerEl: HTMLDivElement) {
  const upperBoundary = containerEl.getBoundingClientRect()
  const upperBoundaryTopOffset = upperBoundary.y ?? upperBoundary.top
  return Math.max(upperBoundaryTopOffset, 0)
}

export function getRightOffset(containerEl: HTMLDivElement) {
  const containerRight = containerEl.getBoundingClientRect().right
  const viewportWidth = document.documentElement.clientWidth

  return viewportWidth - containerRight
}

export function getIsIntersectingVertically(
  upperEl: HTMLElement,
  lowerEl: HTMLElement
) {
  return (
    upperEl.getBoundingClientRect().bottom > lowerEl.getBoundingClientRect().top
  )
}
