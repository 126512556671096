import React from 'react'

/* TYPES */

interface BufferConfig {
  initialValue: string
}

interface BufferApi {
  clear: () => void
  reset: () => void
  update: (value: string) => void
  value: string
}

interface Props extends BufferConfig {
  children?: React.ReactNode
}

interface RenderProps extends BufferConfig {
  children: (props: BufferApi) => {}
}

/* HOOK */

/**
 * @deprecatedSince 9.7.0
 * @deprecated A trivial hook for storing a string. Write your own or use a hook utility lib.
 * 
 * **Source:**
 * ```
  export function useBuffer({ initialValue = '' }) {
  const [value, setValue] = React.useState(initialValue)

  const clear = () => setValue('')

  const reset = () => setValue(initialValue)

  const update = (value: string) => setValue(value)

  return {
    clear,
    reset,
    update,
    value,
  }
}
```
 */
export function useBuffer({ initialValue = '' }: BufferConfig): BufferApi {
  const [value, setValue] = React.useState(initialValue)

  const clear = () => setValue('')
  const reset = () => setValue(initialValue)
  const update = (value: string) => setValue(value)

  return {
    clear,
    reset,
    update,
    value,
  }
}

/* RENDER PROP */

/**
 * @deprecatedSince 9.7.0
 * @deprecated
 */
export function Buffer({ children, ...props }: RenderProps) {
  const buffer = useBuffer(props)

  return typeof children === 'function' ? children(buffer) : children
}
