import type { Ref } from 'react'
import type { DropdownProps } from '../Dropdown/Dropdown.types'
import type { OverlayProps } from '../Overlay/Overlay'
import type { DivAttributes, Placement, Props } from '../_utils/types'

export const rootId = 'ROOT_ID'

export type Action =
  | { type: 'highlight'; option: FlyoutOption }
  | { type: 'expand'; option: FlyoutOption }
  | { type: 'collapse'; option: FlyoutOption }
  | { type: 'close' }
  | { type: 'open' }
  | { type: 'setMouseOver'; isMouseOver: boolean }
  | { type: 'setFocused'; isFocused: boolean }

type ExpandedItem = FlyoutOptionId

export type State = {
  highlighted: FlyoutOption
  expanded: ExpandedItem[]
  options: FlyoutOption[]
  isMouseOver: boolean
  isFocused: boolean
  isOpen: boolean
}

export interface DropdownOption {
  value: any
  label: string
  children?: DropdownOption[]
}

type OptionId = string
export interface FlyoutOption {
  /**
   * @since 10.19.0
   */
  id: OptionId
  /**
   * @since 10.19.0
   */
  parentId: OptionId | typeof rootId
  /**
   * @since 10.19.0
   */
  origin: DropdownOption
  /**
   * @since 10.19.0
   */
  children?: FlyoutOption[]
}

export type FlyoutOptionId = FlyoutOption['id'] | typeof rootId

export interface FlyoutCaptionProps extends DivAttributes {
  option: DropdownOption
  highlighted: boolean
  optionRenderer: (option: DropdownOption) => React.ReactNode
}

export interface FlyoutListProps extends DivAttributes {
  options: FlyoutOption[]
  overlayRef: Ref<HTMLDivElement>
  placement: Placement
  referenceEl: HTMLElement | null
  offset?: number
}

export interface DropdownFlyoutProps extends Omit<DivAttributes, 'onClick'> {
  /**
   * @since 10.19.0
   */
  disabled?: DropdownProps['disabled']

  /**
   * The text of the button.
   * @since 10.19.0
   */
  label?: DropdownProps['label']
  /**
   * @since 10.19.0
   */
  loading?: DropdownProps['loading']

  /**
   * The icon of the button.
   * @since 10.19.0
   */
  icon?: DropdownProps['icon']

  /**
   * <div>
   *  Data of the dropdown options - <Code>Option[]</Code>
   *  <CodeBlock>
   *    {`type Option = { label: string; children?: Option[] }`}
   *  </CodeBlock>
   * </div>
   * @since 10.19.0
   */
  options: DropdownOption[]

  /**
   * <div>
   *  Callback for click on dropdown option
   *  <CodeBlock>{'(option: Option) => void'}</CodeBlock>
   * </div>
   * @since 10.19.0
   */
  onClick?: (option: DropdownOption) => void

  /**
   * <div>
   *  Render prop to specify custom dropdown option content
   *  <CodeBlock>{'(option: Option) => ReactNode'}</CodeBlock>
   * </div>
   * @since 10.19.0
   */
  optionRenderer?: (option: DropdownOption) => React.ReactNode

  /**
   * @default 'right-bottom'
   * @since 10.19.0
   */
  placement?: OverlayProps['placement']

  /**
   * The button size
   * @default 'md'
   * @since 10.19.0
   */
  size?: DropdownProps['size']

  /**
   * @default 'secondary'
   * @since 10.19.0
   */
  variant?: DropdownProps['variant']
}

export interface FlyoutItemProps extends Props {
  option: FlyoutOption
}
