import React from 'react'

export interface SetHookConfig<T> {
  /**
   * @since 10.19.0
   */
  initialValue?: Set<T>
  /**
   * @since 10.19.0
   */
  onChange?: (newCollection: Set<T>) => void
  /**
   * @since 10.19.0
   */
  onAdd?: (item: T) => void
  /**
   * @since 10.19.0
   */
  onRemove?: (item: T) => void
}

export function useSet<T>({
  initialValue = new Set(),
  onChange,
  onAdd,
  onRemove,
}: SetHookConfig<T> = {}) {
  const [collection, setCollection] = React.useState<Set<T>>(initialValue)

  const removeFromSet = React.useCallback(
    (collection: Set<T>, item: T) => {
      collection.delete(item)
      onRemove?.(item)
      return new Set(collection)
    },
    [onRemove]
  )

  const addToSet = React.useCallback(
    (collection: Set<T>, item: T) => {
      collection.add(item)
      onAdd?.(item)
      return new Set(collection)
    },
    [onAdd]
  )

  const updateCollection = React.useCallback(
    (updater: (prevCollection: Set<T>) => Set<T>) => {
      setCollection((prevCollection) => {
        const newCollection = updater(prevCollection)
        onChange?.(newCollection)
        return newCollection
      })
    },
    [onChange]
  )

  const has = React.useCallback((item: T) => collection.has(item), [collection])

  const add = React.useCallback(
    (item: T) =>
      updateCollection((prevCollection) => addToSet(prevCollection, item)),
    [addToSet, updateCollection]
  )

  const remove = React.useCallback(
    (item: T) =>
      updateCollection((prevCollection) => removeFromSet(prevCollection, item)),
    [removeFromSet, updateCollection]
  )

  const set = React.useCallback(
    (newCollection: Set<T>) => updateCollection(() => newCollection),
    [updateCollection]
  )

  const toggle = React.useCallback(
    (item: T) =>
      updateCollection((prevCollection) =>
        prevCollection.has(item)
          ? removeFromSet(prevCollection, item)
          : addToSet(prevCollection, item)
      ),
    [addToSet, removeFromSet, updateCollection]
  )

  const removeAll = React.useCallback(
    () => updateCollection(() => new Set()),
    [updateCollection]
  )

  return {
    collection,
    setCollection: set,
    has,
    add,
    remove,
    removeAll,
    toggle,
  }
}
