import React from 'react'
import { StyledAnchor, StyledUnstyledButton } from '../Semantic/Semantic.styles'
import { Tooltip } from '../Tooltip/Tooltip'
import type {
  ActionIconPolyType,
  ActionIconProps,
  ActionIconRef,
} from './ActionIcon.types'

const ActionIconLink = React.forwardRef(function (
  { 'aria-label': label, href, children, ...props }: ActionIconProps<'link'>,
  ref: ActionIconRef<'link'>
) {
  return (
    <Tooltip overlay={label} trigger={['hover', 'focus']}>
      <StyledAnchor aria-label={label} href={href} {...props} ref={ref}>
        {children}
      </StyledAnchor>
    </Tooltip>
  )
})

const ActionIconButton = React.forwardRef(function (
  { 'aria-label': label, children, ...props }: ActionIconProps<'button'>,
  ref: ActionIconRef<'button'>
) {
  return (
    <Tooltip overlay={label} trigger={['hover', 'focus']}>
      <StyledUnstyledButton aria-label={label} {...props} ref={ref}>
        {children}
      </StyledUnstyledButton>
    </Tooltip>
  )
})

function ActionIconInner<T extends ActionIconPolyType>(
  props: ActionIconProps<T>,
  ref: ActionIconRef<T>
) {
  if (props.role === 'link' || (props as any as ActionIconProps<'link'>).href) {
    return (
      <ActionIconLink
        {...(props as any as ActionIconProps<'link'>)}
        ref={ref as ActionIconRef<'link'>}
      />
    )
  }

  if (props.role === 'button') {
    return (
      <ActionIconButton
        {...(props as ActionIconProps<'button'>)}
        ref={ref as ActionIconRef<'button'>}
      />
    )
  }
  return (
    <ActionIconButton
      {...(props as ActionIconProps<'button'>)}
      ref={ref as ActionIconRef<'button'>}
    />
  )
}

export const ActionIcon = React.forwardRef(ActionIconInner) as <
  Role extends ActionIconPolyType
>(
  props: ActionIconProps<Role>
) => ReturnType<typeof ActionIconInner>
