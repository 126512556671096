import React from 'react'
import type { Props } from '../_utils/types'
import { StyledActions, StyledContent } from './Content.styles'

export function Actions(props: Props) {
  return <StyledActions {...props} />
}

export function Content(props: Props) {
  return <StyledContent {...props} />
}
