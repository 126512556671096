import React from 'react'

/* TYPES */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface VisibilityConfig {
  afterHide?: (e?: Event) => void
  afterShow?: () => void
  initialIsVisible?: boolean
}

interface VisibilityApi {
  hide: (e?: Event) => any
  isVisible: boolean
  show: () => any
  toggle: () => any
}

interface Props extends VisibilityConfig {
  children?: React.ReactNode
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface RenderProps extends VisibilityConfig {
  children: (props: VisibilityApi) => React.ReactElement<{}>
}

/* HOOK */

function noop() {}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useVisibility({
  afterHide = noop,
  afterShow = noop,
  initialIsVisible = false,
}: VisibilityConfig): VisibilityApi {
  const [isVisible, setVisibility] = React.useState(initialIsVisible)

  const hide = (e?: Event) => {
    setVisibility(false)

    if (isVisible) {
      afterHide(e)
    }
  }

  const show = () => {
    setVisibility(true)

    if (!isVisible) {
      afterShow()
    }
  }

  const toggle = isVisible ? hide : show

  return {
    hide,
    isVisible,
    show,
    toggle,
  }
}

/* RENDER PROP */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function Visibility({ children, ...props }: RenderProps) {
  const visibility = useVisibility(props)

  return typeof children === 'function' ? children(visibility) : children
}
