import styled, { css } from 'styled-components'
import type { TypographyProps } from '../Typography'
import { Typography } from '../Typography'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { getEllipsis, getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'
import type { LayoutVariant, QaTags, SizeVariant } from './Thumbnail.types'
import { DocumentIcon } from './ThumbnailDocumentIcon'

const thumbnailSizeSm = 40
const thumbnailSizeLg = 104
const labelHeight = 24
const labelFontSize = 12
const tickSize = 24
const rowLayoutCaptionWidth = 288
const multilineCaptionMaxHeight = spacing.lg * 2

interface CaptionProps {
  layout: LayoutVariant
  error: boolean
  disabled: boolean
}

interface StyledThumbnailProps {
  focused: boolean
  disabled: boolean
  selected: boolean
  clickable: boolean
  size: SizeVariant
}

interface StyledTickIconProps {
  selected: boolean
  size: SizeVariant
}

const captionBase = css<CaptionProps>`
  color: ${colors.gray15};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-weight: 500;
  ${({ layout }) =>
    layout === 'row'
      ? `
        align-items: center;
        margin-left: ${spacing.sm}px;
        width: ${rowLayoutCaptionWidth}px;
      `
      : ''}
  ${({ layout }) =>
    layout === 'column'
      ? `
        margin-top: ${spacing.xs / 2}px;
        width: ${thumbnailSizeLg}px;
      `
      : ''}

  ${({ error }) => (error ? `color: ${colors.red50};` : '')}
  ${({ disabled }) => (disabled ? `color: ${colors.gray70};` : '')}
`

export const StyledMultilineCaption = styled.div<CaptionProps>`
  ${captionBase}
  display: flex;
  word-wrap: break-word;
  // for IE11, use word-wrap property
  overflow-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  // limit height for browsers that do not support line-clamp CSS prop
  max-height: ${multilineCaptionMaxHeight}px;
`

export const StyledCaptionText = styled.span`
  // fix word wrap issue in IE
  width: 100%;
`

export const StyledPlaceholderCaption = styled.div`
  ${captionBase}
`

export const StyledSingleLineCaption = styled.div`
  display: flex;
  ${captionBase}
`

export const StyledSinglelineCaptionBody = styled.span`
  ${getEllipsis}
`

export const StyledSinglelineCaptionEnding = styled.span`
  max-width: 50%;
  white-space: nowrap;
  flex-shrink: 0;
`

export const StyledLabelText = styled(Typography)`
  ${getEllipsis()}
`

export const StyledThumbnailFigCaption = styled.figcaption<{
  $color?: TypographyProps['color']
}>`
  ${getTypographyIntent('small')}
  ${getEllipsis()}
  color: ${({ $color = 'gray15' }) => colors[$color]};
  font-weight: ${typographyWeights.bold};
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.85;
  height: ${labelHeight}px;
  padding: ${spacing.sm / 2}px ${spacing.sm}px;
  background-color: ${colors.white};
`

export const StyledLabel = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.85;
  height: ${labelHeight}px;
  padding: ${spacing.sm / 2}px ${spacing.sm}px;
  font-size: ${labelFontSize}px;
  background-color: ${colors.white};
`

const thumbnailBase = css<StyledThumbnailProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border: 1px solid ${colors.gray85};
  border-radius: ${borderRadius.md}px;
  margin: 0;

  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}

  ${({ selected }) =>
    selected
      ? `
        ${StyledTickIcon} {
          opacity: 1;
          transform: scale(1);
        }
      `
      : ''}

  &:focus {
    ${getFocus()};
  }

  ${({ focused }) => focused && getFocus()}

  ${({ disabled, clickable }) =>
    disabled && clickable ? 'cursor: not-allowed;' : ''}

  ${({ size }) => {
    const sizeInPx = `${size === 'lg' ? thumbnailSizeLg : thumbnailSizeSm}px`
    return `
      width: ${sizeInPx};
      height: ${sizeInPx};
    `
  }}
`

export const StyledTickIcon = styled.svg<StyledTickIconProps>`
  position: absolute;
  top: ${spacing.sm}px;
  right: ${spacing.sm}px;
  width: ${tickSize}px;
  height: ${tickSize}px;
  opacity: 0;
  transform: scale(0.75);
  transition: transform 250ms ease-out, opacity 100ms ease-out;

  ${({ selected }) =>
    selected
      ? `
        opacity: 1;
        transform: scale(1);
      `
      : ''}

  ${({ size }) =>
    size === 'sm'
      ? `
        top: 50%;
        left: 50%;
        margin-top: ${-tickSize / 2}px;
        margin-left: ${-tickSize / 2}px;
      `
      : ''}
`

export const StyledFileIcon = styled(DocumentIcon)`
  width: 36.5%;
  height: auto;
`

export const StyledImageThumbnailPreview = styled.div<{ $bgImage: string }>`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${({ $bgImage }) => css`
    background-image: url(${$bgImage});
  `}
`

export const StyledImageThumbnailOverlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const blackOverlay = `
  background-color: ${colors.black};
  opacity: 0.2;
`

const whiteOverlay = `
  background-color: ${colors.white};
  opacity: 0.5;
`

export const StyledImageThumbnailImage = styled.img``

export const StyledImageThumbnail = styled.div<StyledThumbnailProps>`
  ${thumbnailBase}
  flex-direction: column;

  ${({ disabled }) =>
    disabled
      ? `
        &,
        &:hover {
          ${StyledImageThumbnailOverlay} {
            display: block;
            ${whiteOverlay}
          }
        }
        ${StyledImageThumbnailPreview},
        ${StyledTickIcon},
        ${StyledImageThumbnailImage} {
          filter: saturate(0);
        }
        ${StyledFileIcon} {
          filter: saturate(0);
          z-index: -1;
        }
      `
      : ''}

  ${({ selected, focused, disabled }) =>
    focused && disabled
      ? selected
        ? `
          ${StyledTickIcon} {
            z-index: 0;
          }
        `
        : `
          ${StyledTickIcon} {
            z-index: 1;
          }
        `
      : ''}

  ${({ selected, focused }) =>
    selected && focused
      ? `
        ${StyledTickIcon} {
          z-index: 1;
        }
      `
      : ''}

  ${({ selected, focused, disabled }) =>
    selected && focused && disabled
      ? `
        ${StyledTickIcon} {
          z-index: 0;
        }
      `
      : ''}

  ${({ focused, disabled }) =>
    focused && !disabled
      ? `
        ${StyledImageThumbnailOverlay} {
          display: block;
        }
      `
      : ''}

  ${({ clickable, disabled }) =>
    clickable && !disabled
      ? `
        &:hover {
          ${StyledImageThumbnailOverlay} {
            display: block;
            ${blackOverlay}
          }
        }
      `
      : ''}
`

export const StyledThumbnailWrapper = styled.div<{
  layout: LayoutVariant
  size: SizeVariant
  qa?: QaTags
}>`
  display: flex;
  ${({ size }) =>
    size === 'lg'
      ? `
        width: ${thumbnailSizeLg}px;
      `
      : ''}
  ${({ layout }) => {
    const isRowLayout = layout === 'row'
    return isRowLayout
      ? `
        flex-direction: row;
        aligh-items: center;
      `
      : `
        flex-direction: column;
        aligh-items: flex-start;
      `
  }}
`

export const StyledPlaceholderThumbnail = styled.div<StyledThumbnailProps>`
  ${thumbnailBase}

  ${({ clickable }) =>
    clickable
      ? `
        &:hover {
          background-color: ${colors.gray96};
        }

      `
      : ''}

  ${({ selected, size }) =>
    selected && size === 'sm'
      ? `
        ${StyledFileIcon} {
          display: none;
        }
      `
      : ''}

  ${({ disabled }) =>
    disabled
      ? `
        ${StyledFileIcon},
        ${StyledTickIcon} {
          filter: saturate(0);
          opacity: 0.5;
        }

        &:hover {
          background-color: inherit;
        }
      `
      : ''}

  ${({ disabled, selected }) =>
    disabled && !selected
      ? `
        ${StyledTickIcon} {
          opacity: 0;
          transform: scale(0.75);
        }
      `
      : ''}
`
