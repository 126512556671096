import React from 'react'
import type {
  OverflowObserverProps,
  OverflowState,
} from './OverflowObserver.types'

// Browsers occasionally miscalculate scroll and client width/height by one pixel up or down
//  so we're use |x| > 1 instead of x > 0 assertion eliminating the issue at the cost of
//  a false-negative result if the content is overflowing by just 1 pixel
function isContentOverflowing(length: number, visibleLength: number) {
  return Math.abs(length - visibleLength) > 1
}

function isContentOverflowingX(node: HTMLElement) {
  return isContentOverflowing(node.scrollWidth, node.clientWidth)
}

function isContentOverflowingY(node: HTMLElement) {
  return isContentOverflowing(node.scrollHeight, node.clientHeight)
}

/**
 * @deprecatedSince 11
 * @deprecated Never officially documented/supported
 */
export function useOverflowObserver(): OverflowState {
  const [isOverflowingX, setIsOverflowingX] = React.useState(false)
  const [isOverflowingY, setIsOverflowingY] = React.useState(false)

  const ref = (node: HTMLElement | null) => {
    if (!node) {
      return
    }

    const newIsOverflowingX = isContentOverflowingX(node)
    if (newIsOverflowingX !== isOverflowingX) {
      setIsOverflowingX(newIsOverflowingX)
    }

    const newIsOverflowingY = isContentOverflowingY(node)
    if (newIsOverflowingY !== isOverflowingY) {
      setIsOverflowingY(newIsOverflowingY)
    }
  }

  return {
    ref,
    isOverflowingX,
    isOverflowingY,
    isOverflowing: isOverflowingX || isOverflowingY,
  }
}

/**
 * @deprecatedSince 11
 * @deprecated Never officially documented/supported
 */
export function OverflowObserver({ children }: OverflowObserverProps) {
  const { ref, isOverflowing, isOverflowingX, isOverflowingY } =
    useOverflowObserver()

  if (typeof children !== 'function') {
    console.warn(
      `OverflowObserver: expected a function as "children" prop, received ${typeof children}`
    )
    return children as JSX.Element
  }

  return children({ ref, isOverflowing, isOverflowingX, isOverflowingY })
}
