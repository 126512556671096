import { SettingsPageProps, SettingsPageFooterProps } from '@procore/core-react/dist/_typedoc/SettingsPage/SettingsPage.types.json';
import { PageHeaderProps } from '@procore/core-react/dist/_typedoc/PageLayout/PageLayout.types.json';
import { SectionProps } from '@procore/core-react/dist/_typedoc/Section/Section.types.json';
import * as React from 'react';
export default {
  SettingsPageProps,
  SettingsPageFooterProps,
  PageHeaderProps,
  SectionProps,
  React
};