import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const dataQa = {
  focused: 'core-checkbox-focused',
}

export const checkboxSize = 20

export const StyledCheckboxContainer = styled.div`
  color: ${colors.gray15};
  display: block;
  position: relative;

  & + & {
    margin-top: ${spacing.md}px;
  }
`

function StyledIndeterminate() {
  return css`
    background-color: ${colors.white};
    content: '';
    height: 2px;
    left: 5px;
    position: absolute;
    top: 9px;
    width: 10px;
    border: none;
  `
}

function StyledChecked() {
  return css`
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCI+PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik00MzMuNzkyIDcwNGwtMTc3Ljc5Mi0xNzIuNDE2IDY4LjkyOC01OS45NjggMTA4Ljg2NCAxMDUuNiAyNjUuMjgtMjU3LjIxNiA2OC45MjggNTkuOTY4eiI+PC9wYXRoPjwvc3ZnPg==');
    content: '';
    border: none;
    height: 20px;
    width: 20px;
    top: 0;
    left: 0;
    position: absolute;
  `
}

function StyledFakeChecked($disabled = false, $error = false) {
  return css`
    ${StyledChecked()};

    ${() => {
      if ($disabled && $error) {
        return css`
          background-color: ${colors.red85};
        `
      }
      if ($disabled) {
        return css`
          background-color: ${colors.blue85};
        `
      }
      if ($error) {
        return css`
          background-color: ${colors.red50};
        `
      }
      return css`
        background-color: ${colors.blue50};
      `
    }}
  `
}

function StyledFakeIndeterminate($disabled = false, $error = false) {
  return css`
    border: none;

    &:before {
      ${StyledIndeterminate()};
    }

    ${() => {
      if ($disabled) {
        return css`
          background-color: ${colors.gray85};
        `
      }
      if ($error) {
        return css`
          background-color: ${colors.red50};
        `
      }
      return css`
        background-color: ${colors.blue50};
      `
    }}
  `
}

export const StyledCheckboxLabelWrapper = styled.div<{ $hasLabel: boolean }>`
  padding-left: ${({ $hasLabel }) =>
    $hasLabel ? checkboxSize + spacing.md : checkboxSize}px;
  min-height: ${checkboxSize}px;
  position: relative;
  ${getTypographyIntent('body')}
`

export const StyledCheckboxLabel = styled.label`
  user-select: none;

  &:before {
    background-color: ${colors.white};
    border-radius: 2px;
    border: 2px solid ${colors.gray70};
    content: '';
    display: inline-block;
    height: ${checkboxSize}px;
    width: ${checkboxSize}px;
    min-width: ${checkboxSize}px;
    top: 0;
    left: 0;
    position: absolute;
  }
`

export const StyledCheckboxInput = styled.input<{
  $error: boolean
  $indeterminate: boolean
}>`
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;

  &:disabled:checked
    + ${StyledCheckboxLabelWrapper}
    > ${StyledCheckboxLabel}:before {
    background-color: ${colors.blue85};
    border-color: ${colors.blue85};
  }

  &:disabled + ${StyledCheckboxLabelWrapper} > ${StyledCheckboxLabel}:before {
    background-color: ${({ $indeterminate }) =>
      $indeterminate ? colors.gray85 : colors.white};
    border-color: ${colors.gray85};
  }

  ${({ $error }) => {
    if ($error) {
      return css`
        & + ${StyledCheckboxLabelWrapper} > ${StyledCheckboxLabel}:before {
          border-color: ${colors.red50};
        }

        &:disabled:checked
          + ${StyledCheckboxLabelWrapper}
          > ${StyledCheckboxLabel}:before {
          background-color: ${colors.gray85};
          border-color: ${colors.gray85};
        }
      `
    }
  }}

  &:checked + ${StyledCheckboxLabelWrapper} > ${StyledCheckboxLabel} {
    &:before {
      background-color: ${({ $error }) =>
        $error ? colors.red50 : colors.blue50};
      border-color: ${({ $error }) => ($error ? colors.red50 : colors.blue50)};
      color: ${colors.white};
    }

    &:after {
      ${StyledChecked()}
    }
  }

  &:focus:not(:disabled),
  &[data-qa=${dataQa.focused}]:not(:disabled) {
    + ${StyledCheckboxLabelWrapper} > ${StyledCheckboxLabel}:before {
      ${getFocus()}
    }
  }

  ${({ $indeterminate, $error }) => {
    if ($indeterminate) {
      return css`
        &
          + ${StyledCheckboxLabelWrapper}
          > ${StyledCheckboxLabel}:before,
          &:checked
          + ${StyledCheckboxLabelWrapper}
          > ${StyledCheckboxLabel}:before {
          background-color: ${$error ? colors.red50 : colors.blue50};
          border-color: ${$error ? colors.red50 : colors.blue50};
          color: ${colors.white};
        }

        &
          + ${StyledCheckboxLabelWrapper}
          > ${StyledCheckboxLabel}:after,
          &:checked
          + ${StyledCheckboxLabelWrapper}
          > ${StyledCheckboxLabel}:after {
          ${StyledIndeterminate()}
        }
      `
    }
  }}
`

export const StyledFakeCheckboxLabel = styled.span<{
  $checked: boolean
  $disabled: boolean
}>`
  ${getTypographyIntent('body')}

  display: block;
  min-height: ${checkboxSize}px;
  padding-left: ${({ children }) =>
    children ? checkboxSize + spacing.md : checkboxSize}px;
  position: relative;
  user-select: none;
  cursor: pointer;

  ${({ $checked, $disabled }) => {
    if ($checked && $disabled) {
      return css`
        color: ${colors.gray50};
      `
    }
    if ($disabled) {
      return css`
        color: ${colors.gray85};
      `
    }
  }}
`

export const StyledFakeCheckboxInput = styled.div<{
  $checked: boolean
  $error: boolean
  $disabled: boolean
  $indeterminate: boolean
}>`
  width: ${checkboxSize}px;
  height: ${checkboxSize}px;
  border-radius: 2px;
  border: 2px solid ${colors.gray70};
  position: absolute;

  ${({ $checked, $disabled, $error }) => {
    if ($checked) {
      return StyledFakeChecked($disabled, $error)
    }
  }}

  ${({ $indeterminate, $disabled, $error }) => {
    if ($indeterminate) {
      return StyledFakeIndeterminate($disabled, $error)
    }
  }}

  ${({ $error }) => {
    if ($error) {
      return css`
        border-color: ${colors.red50};
      `
    }
  }}

  ${({ $disabled }) => {
    if ($disabled) {
      return css`
        border-color: ${colors.gray85};
      `
    }
  }}
`
