import React from 'react'

/* TYPES */

interface TimerConfig {}

interface Props {
  children: React.ReactNode
}

type TimerId = number | null

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface TimerApi {
  cancel: () => void
  reset: () => void
  setTimer: (callback: () => void, duration: number) => void
  timerId: TimerId
}

/* HOOK */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useTimer(config: TimerConfig): TimerApi {
  const timer = React.useRef<TimerId>(null)

  return {
    cancel: () => {
      timer.current && clearTimeout(timer.current)
    },
    reset: () => {
      timer.current && clearTimeout(timer.current)
    },
    setTimer: (callback, duration) => {
      timer.current && clearTimeout(timer.current)
      timer.current = window.setTimeout(callback, duration)
    },
    timerId: timer.current,
  }
}

/* RENDER PROP */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function Timer({
  children,
}: {
  children: (timer: TimerApi) => unknown
}) {
  const timer = useTimer({})

  return typeof children === 'function' ? children(timer) : children
}
