import { ChevronRight, Help, Home, Plus } from '@procore/core-icons/dist'
import React from 'react'
import { Box } from '../Box/Box'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { Button } from '../Button/Button'
import { FlexList } from '../FlexList/FlexList'
import { Input } from '../Input/Input'
import {
  MenuImperative,
  useMenuImperativeControlNavigation,
} from '../MenuImperative/MenuImperative'
import type { Selection } from '../MenuImperative/MenuImperative.types'
import {
  CloseOnFocus,
  useOverlayTriggerContext,
} from '../OverlayTrigger/OverlayTrigger'
import { Spinner } from '../Spinner/Spinner'
import { Tooltip } from '../Tooltip/Tooltip'
import { Typography } from '../Typography/Typography'
import { useI18nContext } from '../_hooks/I18n'
import type { Props } from '../_utils/types'
import { TieredSelectContext, useHighlightItemEffects } from './TieredSelect'
import {
  StyledTieredSelect,
  StyledTieredSelectBreadcrumbs,
  StyledTieredSelectHome,
  StyledTieredSelectIcon,
  StyledTieredSelectLoadingMore,
  StyledTieredSelectOption,
  StyledTieredSelectOverlay,
  StyledTieredSelectSpinnerOverlay,
} from './TieredSelect.styles'
import type {
  ArrowButtonProps,
  OptionsProps,
  QuickCreateInputProps,
  Tier,
  TieredSelectMenuProps,
} from './TieredSelect.types'

function ArrowButton(props: ArrowButtonProps) {
  return (
    <StyledTieredSelectIcon alignItems="center" justifyContent="center">
      <ChevronRight />
    </StyledTieredSelectIcon>
  )
}

function ItemTooltipIcon() {
  return (
    <StyledTieredSelectIcon alignItems="center" justifyContent="center">
      <Help />
    </StyledTieredSelectIcon>
  )
}

const QuickCreateInput = React.forwardRef<
  HTMLInputElement,
  QuickCreateInputProps
>(function QuickCreateInput({ autoFocus, onSubmit, placeholder }, ref) {
  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      onSubmit(e.currentTarget.value)
    }
  }

  return (
    <Box flexGrow="1" justifyContent="center" padding="md">
      <Input
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={ref}
      />
    </Box>
  )
})

function Options({ children, emptyMessage, ...props }: OptionsProps & Props) {
  if (React.Children.count(children) === 0) {
    return (
      <MenuImperative.Options>
        <Box paddingLeft="lg" {...props}>
          <Typography color="gray45" intent="small" italic>
            {emptyMessage}
          </Typography>
        </Box>
      </MenuImperative.Options>
    )
  }
  return <MenuImperative.Options>{children}</MenuImperative.Options>
}

interface RenderMenuItemProps {
  tier: Tier
  index: number
  selected: boolean
  onNavigateForward: (tier: Tier) => void
}
const RenderMenuItem: React.FC<RenderMenuItemProps> = ({
  tier,
  selected,
  index,
  onNavigateForward,
}) => {
  const { isTierDisabled, getNextGroupId, onQuickCreate, getLabel, getId } =
    React.useContext(TieredSelectContext)

  const tooltip = isTierDisabled?.(tier) ?? false
  const isDisabled = Boolean(tooltip)
  const showNextTierArrow =
    !tooltip && (Boolean(getNextGroupId(tier)) || Boolean(onQuickCreate))

  const overlay = typeof tooltip === 'string' ? tooltip : null

  const menuItem = (
    <StyledTieredSelectOption
      disabled={isDisabled}
      item={tier}
      selected={selected}
    >
      <>
        {getLabel(tier)}
        {showNextTierArrow && (
          <Box paddingLeft="sm">
            <ArrowButton
              data-qa="tiered-select-arrow"
              onClick={() => onNavigateForward(tier)}
            />
          </Box>
        )}
        {tooltip && (
          <Box paddingLeft="sm">
            <ItemTooltipIcon />
          </Box>
        )}
      </>
    </StyledTieredSelectOption>
  )

  if (isDisabled && overlay) {
    return (
      <Tooltip
        key={`${getId(tier)}-${index}-tooltip`}
        overlay={overlay}
        trigger={'hover'}
        placement="right"
      >
        {menuItem}
      </Tooltip>
    )
  }

  return menuItem
}

export const TieredSelectMenu = React.forwardRef<
  HTMLDivElement,
  TieredSelectMenuProps
>(function TieredSelectMenu(
  {
    children,
    currentTier,
    footer: customFooter,
    loading,
    loadingMore,
    menuRef,
    onChange,
    onNavigate,
    onSearch,
    options = [],
    previousValue,
    value,
    ...props
  },
  ref
) {
  const {
    getGroupId,
    getId,
    getLabel,
    getNextGroupId,
    i18nScope,
    isTierDisabled,
    onQuickCreate,
    onScrollBottom,
  } = React.useContext(TieredSelectContext)
  const quickCreateRef = React.useRef<HTMLInputElement>(null)

  const { hide } = useOverlayTriggerContext()
  const I18n = useI18nContext()

  const [isQuickCreating, setIsQuickCreating] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')
  const { menuProps, menuNavigationTriggerProps } =
    useMenuImperativeControlNavigation(menuRef)

  const searchIsEmpty = searchValue === ''

  const lastEntryInValue = value[value.length - 1]

  const internalOnSearch = React.useCallback(
    function (e: React.ChangeEvent<HTMLInputElement>) {
      setSearchValue(e.target.value)
      onSearch && onSearch(e)
    },
    [onSearch]
  )

  // When searching (all options have been flattened) we dont filter by id
  const filteredOptions = React.useMemo(() => {
    return searchIsEmpty
      ? options.filter((tier) => {
          return getGroupId(tier) === currentTier
        })
      : options
  }, [currentTier, getGroupId, options, searchIsEmpty])

  useHighlightItemEffects({
    currentTier,
    searchValue,
    menuRef,
    loading,
    loadingMore,
  })

  const onNavigateHome = React.useCallback(
    function () {
      onNavigate(null, [])
      menuRef.current?.highlightFirst()
    },
    [menuRef, onNavigate]
  )

  const onBreadCrumbNavigate = React.useCallback(
    function (tier: Tier) {
      const newTier = value.indexOf(tier)
      onNavigate(getNextGroupId(tier), value.slice(0, newTier + 1))
      menuRef.current?.highlightFirst()
    },
    [getNextGroupId, menuRef, onNavigate, value]
  )

  function onNavigateForward(tier: Tier) {
    if (searchIsEmpty && !isQuickCreating && tier && !isLeaf(tier)) {
      // if last value was a leaf, replace it when performing next navigation
      const newValue =
        lastEntryInValue && isLeaf(lastEntryInValue)
          ? [...value.slice(0, value.length - 1), tier]
          : [...value, tier]
      onNavigate(getNextGroupId(tier), newValue)
      menuRef.current?.highlightFirst()
    }
  }

  /**
   * need to skip back two levels if coming from a leaf node;
   * out from the leaf and then out of the parent. this is because
   * when tiers aren't selectectable we show the parent of the leaf
   */
  function onNavigateBackward() {
    if (value.length !== 0 && searchIsEmpty) {
      let newValue
      let newTier
      if (isLeaf(value[value.length - 1])) {
        newValue = value.slice(0, Math.max(0, value.length - 2))
        newTier = value[Math.max(0, value.length - 2)]
      } else {
        newValue = value.slice(0, Math.max(0, value.length - 1))
        newTier = value[value.length - 1]
      }

      !isQuickCreating && onNavigate(getGroupId(newTier), newValue)
      menuRef.current?.highlightFirst()
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    const tier = menuRef.current?.highlighted()
    const isDisabled = isTierDisabled?.(tier)

    if (e.key === 'Right' || e.key === 'ArrowRight') {
      if (isDisabled) {
        return
      }
      onNavigateForward(tier)
      return
    }

    if (e.key === 'Left' || e.key === 'ArrowLeft') {
      if (isDisabled) {
        return
      }
      onNavigateBackward()
      return
    }

    menuNavigationTriggerProps.onKeyDown?.(e)
  }

  const isLeaf = React.useCallback(
    function (tier: Tier) {
      return !getNextGroupId(tier)
    },
    [getNextGroupId]
  )

  function onTierSelect(selection: Selection) {
    if (isLeaf(selection.item)) {
      const isSameGroup = value.length
        ? getGroupId(lastEntryInValue) === getGroupId(selection.item)
        : false

      // if the selection is in the same group as the old selection, replace the old selection
      const newValue = isSameGroup
        ? [...value.slice(0, value.length - 1), selection.item]
        : [...value, selection.item]

      onChange({ ...selection, value: newValue })
      searchValue &&
        internalOnSearch({
          target: { value: '' },
        } as React.ChangeEvent<HTMLInputElement>)
      hide(selection.event)
    } else {
      onNavigateForward(selection.item)
    }
  }

  // non-selectable tiers can have a leaf node in the root tier, so if selected
  // it will reopen to the root tier and we should not show breadcrumbs
  function showBreadcrumbs() {
    return (
      value.length &&
      searchIsEmpty &&
      !(value.length === 1 && isLeaf(value[value.length - 1]))
    )
  }

  const menuEmptyMessage = searchIsEmpty
    ? I18n.t('emptyMessage', { scope: i18nScope })
    : I18n.t('searchEmptyMessage', { scope: i18nScope })

  const handleQuickCreate = React.useCallback(
    function (e: React.KeyboardEvent<HTMLButtonElement>) {
      // stops menu from selecting item and closing on enter press
      e.stopPropagation()
      if (e.key === 'Enter') {
        setIsQuickCreating(true)
      }
    },
    [setIsQuickCreating]
  )

  const onQuickCreateSubmit = React.useCallback(
    function () {
      onQuickCreate &&
        quickCreateRef.current &&
        onQuickCreate(quickCreateRef.current.value)
      setIsQuickCreating(false)
    },
    [onQuickCreate, quickCreateRef, setIsQuickCreating]
  )

  function isTierSelected(tier: Tier) {
    if (previousValue.length) {
      return getId(tier) === getId(previousValue[previousValue.length - 1])
    }
    return false
  }

  function RenderLoadingMore() {
    if (loadingMore && !loading) {
      return (
        <StyledTieredSelectLoadingMore data-qa="tiered-select-loading-more">
          <Spinner size="sm" />
        </StyledTieredSelectLoadingMore>
      )
    }

    return null
  }

  const RenderFooter = React.useCallback(() => {
    if (customFooter) {
      // Render custom footer, if defined.
      if (typeof customFooter === 'function') {
        return <MenuImperative.Footer>{customFooter()}</MenuImperative.Footer>
      }

      return <MenuImperative.Footer>{customFooter}</MenuImperative.Footer>
    }

    if (onQuickCreate === undefined) {
      // no footer if `onQuickCreate` is not defined.
      return null
    }

    if (isQuickCreating) {
      // Render `Cancel` and `Create` labels if "quick creating".
      return (
        <MenuImperative.Footer data-something="true">
          <FlexList size="xs" justifyContent="flex-end">
            <Button
              onClick={() => setIsQuickCreating(false)}
              size="sm"
              variant="tertiary"
            >
              {I18n.t('quickCreateCancelLabel', {
                scope: i18nScope,
              })}
            </Button>
            <Button onClick={onQuickCreateSubmit} size="sm">
              {I18n.t('quickCreateCreateLabel', {
                scope: i18nScope,
              })}
            </Button>
          </FlexList>
        </MenuImperative.Footer>
      )
    }

    return (
      <MenuImperative.Footer data-something="true">
        <Button
          block
          size="sm"
          icon={<Plus />}
          onClick={() => setIsQuickCreating(true)}
          onKeyDown={handleQuickCreate}
        >
          {I18n.t('quickCreateActionLabel', {
            scope: i18nScope,
          })}
        </Button>
      </MenuImperative.Footer>
    )
  }, [
    I18n,
    customFooter,
    handleQuickCreate,
    i18nScope,
    isQuickCreating,
    onQuickCreate,
    onQuickCreateSubmit,
  ])

  return (
    <StyledTieredSelectOverlay ref={ref} shadowStrength={2}>
      <StyledTieredSelectSpinnerOverlay
        data-qa="tiered-select-loading"
        data-loading={loading}
        label={I18n.t('spinnerLabel', { scope: i18nScope }) || ''}
        loading={loading}
        size="md"
      >
        <StyledTieredSelect
          onScrollBottom={onScrollBottom}
          onSelect={onTierSelect}
          ref={menuRef}
          {...props}
          {...menuProps}
          role="none"
        >
          <MenuImperative.Header>
            {!isQuickCreating && (
              <MenuImperative.Search
                autoComplete="false"
                onChange={internalOnSearch}
                placeholder={I18n.t('searchPlaceholder', {
                  scope: i18nScope,
                })}
                {...menuNavigationTriggerProps}
                onKeyDown={onKeyDown}
              />
            )}
            {showBreadcrumbs() ? (
              <StyledTieredSelectBreadcrumbs $nonInteractive={isQuickCreating}>
                <Breadcrumbs.Crumb onClick={onNavigateHome}>
                  <StyledTieredSelectHome
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Home data-qa="tiered-select-home" />
                  </StyledTieredSelectHome>
                </Breadcrumbs.Crumb>
                {value
                  .filter((tier) => {
                    // we reopen to the parent of leaf nodes, so don't show that breadcrumb
                    return !isLeaf(tier)
                  })
                  .map((tier, index, array) => {
                    return (
                      <Breadcrumbs.Crumb
                        active={index === array.length - 1}
                        key={`${getId(tier)}-${index}`}
                        onClick={() => onBreadCrumbNavigate(tier)}
                      >
                        {getLabel(tier)}
                      </Breadcrumbs.Crumb>
                    )
                  })}
              </StyledTieredSelectBreadcrumbs>
            ) : null}
          </MenuImperative.Header>
          {!isQuickCreating ? (
            <Options emptyMessage={menuEmptyMessage}>
              {filteredOptions
                .map((tier, index) => (
                  <RenderMenuItem
                    key={`menu-item-${getId(tier)}-${index}}`}
                    tier={tier}
                    selected={isTierSelected(tier)}
                    index={index}
                    onNavigateForward={onNavigateForward}
                  />
                ))
                .concat(<RenderLoadingMore key="tiered-select-loading-more" />)}
            </Options>
          ) : (
            <QuickCreateInput
              autoFocus={isQuickCreating}
              onSubmit={onQuickCreateSubmit}
              placeholder={I18n.t('quickCreatePlaceholder', {
                scope: i18nScope,
              })}
              ref={quickCreateRef}
            />
          )}
          <RenderFooter />
          <CloseOnFocus hide={hide} />
        </StyledTieredSelect>
      </StyledTieredSelectSpinnerOverlay>
    </StyledTieredSelectOverlay>
  )
})
