import { colors } from '../_styles/colors'
import type { PillColor } from './Pill.types'

interface ColorField {
  backgroundColor: string
  borderColor: string
  color: string
}
export const PillColorPalets: Record<PillColor, ColorField> = {
  blue: {
    backgroundColor: colors.blue94,
    borderColor: colors.blue85,
    color: colors.blue45,
  },
  cyan: {
    backgroundColor: colors.cyan94,
    borderColor: colors.cyan85,
    color: colors.cyan30,
  },
  gray: {
    backgroundColor: colors.gray94,
    borderColor: colors.gray85,
    color: colors.gray30,
  },
  green: {
    backgroundColor: colors.green94,
    borderColor: colors.green70,
    color: colors.green30,
  },
  magenta: {
    backgroundColor: colors.magenta94,
    borderColor: colors.magenta85,
    color: colors.magenta40,
  },
  red: {
    backgroundColor: colors.red94,
    borderColor: colors.red85,
    color: colors.red45,
  },
  UNSAFE_orange: {
    backgroundColor: colors.orange98,
    borderColor: colors.orange85,
    color: colors.orange40,
  },
  yellow: {
    backgroundColor: colors.yellow90,
    borderColor: colors.yellow60,
    color: colors.orange30,
  },
}
