import React from 'react'
import { Box } from '../Box/Box'
import type { FlexDirection, MarginSize } from '../Box/Box.types'
import type { DivAttributes } from '../_utils/types'
import { StyledFlex } from './FlexList.styles'
import type { FlexListProps } from './FlexList.types'

function getSingleSize(size: string = ''): MarginSize {
  return size.split(' ')[0] as MarginSize
}

function getItemMargin(direction: FlexDirection, size: MarginSize): string {
  return direction.includes('row') ? `0 ${size}` : `${size} 0`
}

function getMargin(
  direction: FlexDirection,
  space?: MarginSize,
  size: MarginSize = 'md'
) {
  if (space === undefined) {
    return { margin: getItemMargin(direction, size) }
  }

  if (space && direction.includes('row')) {
    return { marginRight: space }
  }

  if (space && direction.includes('column')) {
    return { marginBottom: space }
  }

  return {}
}

/**
 * `FlexList` combines `Flex` and `Box`, **each child element is wrapped in a `Box`**
 * having a margin (leading and trailing space removed). This isolates the
 * children React components from the `display: flex` on direct children in CSS.
 */
export const FlexList = React.forwardRef<
  HTMLDivElement,
  DivAttributes & FlexListProps
>(function FlexList(
  { boxProps, children, direction = 'row', size = 'md', space, ...props },
  ref
) {
  const singleSize = React.useMemo(() => getSingleSize(size), [size])

  const margin = React.useMemo(
    () => getMargin(direction, space, singleSize),
    [direction, space, singleSize]
  )

  return (
    <StyledFlex
      {...props}
      ref={ref}
      direction={direction}
      $direction={direction}
    >
      {React.Children.toArray(children)
        .filter((child) => child)
        .map(
          (child: any, i) =>
            child && (
              <Box {...boxProps} {...margin} key={child.key || i}>
                {child}
              </Box>
            )
        )}
    </StyledFlex>
  )
})

FlexList.displayName = 'FlexList'
