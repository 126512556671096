import styled from 'styled-components'
import { spacing } from '../_styles/spacing'
import { FileTokenList } from './FileTokenList'

export const StyledFileTokenList = styled(FileTokenList)`
  margin-top: ${spacing.md}px;
`

export const StyledFileSelect = styled.div`
  display: flex;
  flex-direction: column;
`
