import { FixedSizeList as List } from 'react-window'
import styled from 'styled-components'
import { Button } from '../../Button/Button'
import { Thumbnail } from '../../Thumbnail/Thumbnail'
import { getTypographyIntent } from '../../Typography/Typography.styles'
import { colors } from '../../_styles/colors'
import { spacing } from '../../_styles/spacing'

export const StyledThumbnailListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  outline: none;
`

export const StyledThumbnailList = styled(List)`
  outline: none;
`

export const StyledThumbnail = styled(Thumbnail)`
  flex-grow: 1;
  justify-content: flex-start;
`

export const StyledProgress = styled.div`
  width: 146px;
  padding: 0 ${spacing.lg}px 0 ${spacing.xl}px;
  margin-left: auto;
`

export const StyledThumbnailListItem = styled.div<{
  focused: boolean
  error: boolean
}>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: ${spacing.sm}px ${spacing.lg}px ${spacing.sm}px ${spacing.sm}px;
  ${getTypographyIntent('body')}

  ${({ error }) =>
    !error &&
    `
      &:hover {
        background-color: ${colors.gray96};
      }
    `}

  ${({ focused, error }) =>
    focused &&
    !error &&
    `
      background-color: ${colors.gray96};
    `}

  ${({ error }) =>
    error &&
    `
      background-color: ${colors.red98};

      ${StyledProgress} {
        color: ${colors.red50};
      }
    `}
`

export const StyledDeleteIcon = styled(Button)``
