import styled from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { arrowRight } from '../_styles/arrows'
import { colors } from '../_styles/colors'
import { getEllipsis } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const StyledDropdownFlyout = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  max-height: 855px;
  padding: ${spacing.sm}px 0;
`

export const StyledDropdownFlyoutItem = styled.div`
  display: flex;
  justify-content: space-between;

  cursor: pointer;

  width: 100%;
  padding: ${spacing.xs}px ${spacing.lg}px;
  color: ${colors.gray20};
  ${getTypographyIntent('body')}

  &[data-highlighted='true'],
  &:hover {
    background-color: ${colors.gray96};
  }
`

export const StyledDropdownFlyoutLabel = styled.span`
  ${getEllipsis()}
`

export const StyledDropdownFlyoutExpandIcon = styled.span`
  position: relative;
  display: inline-flex;
  align-self: center;
  flex-shrink: 0;
  margin-left: ${spacing.lg}px;
  white-space: nowrap;

  ${arrowRight(4, 'black')}
`
