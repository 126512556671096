import styled, { css } from 'styled-components'
import type { Spacing } from '../_styles/spacing'
import { spacing } from '../_styles/spacing'
import type { $BoxProps, MarginSize, PaddingSize } from './Box.types'

function appendPx(value?: number | string): string | undefined {
  if (!value) {
    return
  }

  return typeof value === 'string' ? value : `${value}px`
}

function sizeStrToPx(str?: string): string | undefined {
  /**
   * hacky usage of "as" here, but since we have a default fallback if the
   * lookup fails it should be fine
   */
  return spacing[str as Spacing] !== undefined
    ? `${spacing[str as Spacing]}px`
    : str
}

function shorthandSizeToPx(shorthand?: string): string | undefined {
  return shorthand?.split(' ').map(sizeStrToPx).join(' ')
}

function sizeToPx(size?: MarginSize | PaddingSize): string | undefined {
  return sizeStrToPx(size) || size
}

export const StyledBox = styled.div<$BoxProps>`
  ${({ $as }) => {
    if ($as === 'ol' || $as === 'ul') {
      return css`
        list-style: none;
        margin: 0;
        padding: 0;
      `
    }
  }}
  ${(p) => css`
    align-content: ${p.$alignContent};
    align-items: ${p.$alignItems};
    align-self: ${p.$alignSelf};
    display: ${p.$display};
    flex-basis: ${p.$flexBasis};
    flex-direction: ${p.$flexDirection};
    flex-grow: ${p.$flexGrow};
    flex-shrink: ${p.$flexShrink};
    flex-wrap: ${p.$flexWrap};
    flex: ${p.$flex};
    gap: ${shorthandSizeToPx(p.$gap)};
    justify-content: ${p.$justifyContent};
    margin: ${shorthandSizeToPx(p.$margin)};
    margin-bottom: ${sizeToPx(p.$marginBottom)};
    margin-left: ${sizeToPx(p.$marginLeft)};
    margin-right: ${sizeToPx(p.$marginRight)};
    margin-top: ${sizeToPx(p.$marginTop)};
    order: ${p.$order};
    padding: ${shorthandSizeToPx(p.$padding)};
    padding-bottom: ${sizeToPx(p.$paddingBottom)};
    padding-left: ${sizeToPx(p.$paddingLeft)};
    padding-right: ${sizeToPx(p.$paddingRight)};
    padding-top: ${sizeToPx(p.$paddingTop)};
    text-align: ${p.$textAlign};
  `}
`
