import { useFocusManager } from '@react-aria/focus'
import { useEffect } from 'react'
import { useModalChildRegistryContext } from './ChildRegistry.context'

interface InitializeModalFocusProps {
  modalContainerRef: React.RefObject<HTMLDivElement>
  hasOnClose: boolean
}

export function useInitializeModalFocus({
  modalContainerRef,
  hasOnClose,
}: InitializeModalFocusProps) {
  const focusManager = useFocusManager()
  const childrenRegistryStatus = useModalChildRegistryContext()

  useEffect(() => {
    // the same reason that Modal has a FocusScope with autoFocus={!!onClose}
    if (!hasOnClose) return
    const closeButtonElement = modalContainerRef?.current?.querySelector(
      '[data-internal="close-button"]'
    )
    const anyFocusedItem =
      childrenRegistryStatus.body?.current?.querySelector(':focus')

    if (closeButtonElement == null) {
      // if no focus header button is represented then no need to change focus on next elements
      return
    }
    if (childrenRegistryStatus.body?.current) {
      // if nothing has been focused within body then focus in the scrollable body
      if (anyFocusedItem == null) {
        childrenRegistryStatus.body?.current?.focus()
      }
      // if there is a footer section the focus on the left most interactive element
    } else if (childrenRegistryStatus.closeableButtons?.current) {
      focusManager?.focusNext({
        from: closeButtonElement ?? undefined,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenRegistryStatus])
}
