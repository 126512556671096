import photo1 from './img/1.png'
import photo2 from './img/2.png'
import photo3 from './img/3.png'
import photo4 from './img/4.png'
import photo5 from './img/5.png'

import logo1 from './logo/1.png'
import logo2 from './logo/2.png'
import logo3 from './logo/3.png'
import logo4 from './logo/4.png'
import logo5 from './logo/5.png'

export const photos = [photo1, photo2, photo3, photo4, photo5]

export const logos = [logo1, logo2, logo3, logo4, logo5]
