import { Grip } from '@procore/core-icons/dist'
import { useLabel } from '@react-aria/label'
import { useId } from '@react-aria/utils'
import React, { isValidElement, useEffect, useMemo, useState } from 'react'
import type {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DragStart,
  DragUpdate,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import {
  DragDropContext,
  Draggable,
  Droppable as DndDroppable,
  useMouseSensor,
  useTouchSensor,
} from 'react-beautiful-dnd'
import { Checkbox, FakeCheckbox } from '../Checkbox/Checkbox'
import type { CheckboxProps } from '../Checkbox/Checkbox.types'
import { Typeahead } from '../Typeahead/Typeahead'
import type { TypeaheadProps } from '../Typeahead/Typeahead.types'
import { useI18nContext } from '../_hooks/I18n'
import { useZIndexContext } from '../_hooks/ZIndex'
import { colors } from '../_styles/colors'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { mergeRefs } from '../_utils/mergeRefs'
import type { PolymorphicAs } from '../_utils/polymorphic'
import type { DivAttributes } from '../_utils/types'
import {
  StyledDroppable,
  StyledFooter,
  StyledGrip,
  StyledGroup,
  StyledHeader,
  StyledItem,
  StyledMenu,
  StyledOptions,
  StyledSearch,
  StyledWrapper,
} from './MenuImperative.styles'
import type {
  DroppableProps,
  FooterProps,
  GroupProps,
  HeaderProps,
  ItemProps,
  ItemWithDraggingProps,
  MenuContext as IMenuContext,
  MenuItemDefaultElement,
  MenuItemProps,
  MenuProps,
  MenuRef,
  MenuSearchProps,
  OptionsProps,
  Ref,
  Selection,
} from './MenuImperative.types'
import { createSensors } from './sensors'

const onScrollBottomThreshold = 8
const NO_ANNOUCEMENT = ''

function noop() {}

function isScrolledToBottom(e: React.UIEvent<HTMLDivElement, UIEvent>) {
  return (
    e.currentTarget instanceof HTMLElement &&
    e.currentTarget.scrollTop >=
      e.currentTarget.scrollHeight -
        e.currentTarget.clientHeight -
        onScrollBottomThreshold
  )
}

// TODO use the shared mergeRefs utility
export function useMergeRef<T = HTMLElement>(externalRef: Ref<T>) {
  const ref = React.useRef<T>()

  return [
    ref,
    React.useCallback(
      (node) => {
        ref.current = node

        if (externalRef) {
          if (typeof externalRef === 'function') {
            externalRef(node)
          } else {
            externalRef.current = node
          }
        }
      },
      [externalRef]
    ),
  ] as const
}

const MenuContext = React.createContext<IMenuContext>({
  a11yOptionsProps: {},
  currentlyDroppableIn: [],
  onHoverItem: noop,
  onScrollBottom: noop,
  onSelect: noop,
  role: 'none',
})

export function useMenuContext(): IMenuContext {
  const context = React.useContext(MenuContext)

  if (context === null) {
    throw new Error(
      'Cannot find `Menu` context, please wrap your component in `<MenuContext.Provider>`'
    )
  }

  return context
}

interface ControlNavigationProps {
  menuId?: MenuProps['id']
}

interface ControlNavigationReturn {
  menuNavigationTriggerProps: {
    'aria-activedescendant'?: string
    'aria-controls'?: string
    onKeyDown?: React.KeyboardEventHandler<Element>
  }
  menuProps: {
    onChangeActiveDescendant?: React.Dispatch<
      React.SetStateAction<string | undefined>
    >
    id: string
  }
}
/**
 * Usefully if navigation is controlled by search input or another input.
 *
 * @param ref MenuImperative ref
 * @param enable True by default. Setting to false will change all props
 * to undefined (except id in menuProps), so navigation controlled by menu itself is restored.
 * @param options.menuId provide id otherwise it will be generated
 * @since 11.5.0
 */
export const useMenuImperativeControlNavigation = (
  ref: React.RefObject<MenuRef>,
  enable = true,
  options: ControlNavigationProps = {}
): ControlNavigationReturn => {
  const { menuId: _id } = options
  const id = useId(_id)
  const [activeDescendant, setActiveDescendant] = React.useState<
    string | undefined
  >()
  const [onKeyDown, setOnKeyDown] = useState<React.KeyboardEventHandler>()
  // ref is null on first render
  React.useEffect(() => {
    setOnKeyDown(() => ref.current?.defaultKeyDownNavigationHandler)
  }, [])

  if (enable) {
    const menuNavigationTriggerProps = {
      'aria-activedescendant': activeDescendant,
      'aria-controls': id,
      onKeyDown,
    }

    const menuProps = {
      onChangeActiveDescendant: setActiveDescendant,
      id,
    }

    return {
      menuNavigationTriggerProps,
      menuProps,
    }
  } else {
    return {
      menuNavigationTriggerProps: {},
      menuProps: { id },
    }
  }
}

function changeAriaActiveDescendantOnOptions(id = '', menuRef: Element | null) {
  const options = menuRef?.querySelector<HTMLElement>(
    '[data-internal="menuimperative-options"]'
  )

  options?.setAttribute('aria-activedescendant', id)
}

const MenuImperative_ = React.forwardRef<MenuRef, MenuProps>(function Menu(
  {
    circular = false,
    onDragEnd: onDragEnd_ = noop,
    onScrollBottom: onScrollBottom_ = noop,
    onSelect: onSelect_ = noop,
    role = 'none',
    onChangeActiveDescendant,
    onKeyboardNavigation: onKeyboardNavigation_,
    id,
    multiple,
    ...props
  },
  forwardRef
) {
  const ref = React.useRef<HTMLDivElement>(null)
  const [currentlyDroppableIn, setCurrentlyDroppableIn] = useState<string[]>([])

  // for context, used by Menu.Item
  const onSelect = React.useCallback(
    function (selection: Selection) {
      onSelect_(selection)
    },
    [onSelect_]
  )

  const onChangeHighlight = onChangeActiveDescendant
    ? onChangeActiveDescendant
    : changeAriaActiveDescendantOnOptions

  const {
    handleItemHover,
    handleKeyDown: defaultKeyDownNavigationHandler,
    highlight,
    highlighted,
    highlightFirst,
    highlightLast,
    highlightSelected,
    highlightSuggested,
    rehighlightCurrent,
    prev,
    next,
    select,
    updateSelectCallback,
    useKeyboardSensor,
  } = useMemo(
    () => createSensors(ref, circular, onChangeHighlight),
    [circular, onChangeHighlight]
  )

  const commonA11yOptionProps = {
    role,
    id,
    ['aria-multiselectable']: multiple,
  }

  const a11yOptionsProps = !onChangeActiveDescendant
    ? {
        ...commonA11yOptionProps,
        onKeyDown: onKeyboardNavigation_ ?? defaultKeyDownNavigationHandler,
        tabIndex: 0,
      }
    : {
        ...commonA11yOptionProps,
        onKeyDown: undefined,
        tabIndex: undefined,
      }

  updateSelectCallback(onSelect)

  // exposes internal menu API
  React.useImperativeHandle(forwardRef, () => ({
    el: ref.current,
    highlight,
    highlighted,
    highlightFirst,
    highlightLast,
    highlightSelected,
    highlightSuggested,
    next,
    prev,
    select,
    defaultKeyDownNavigationHandler,
  }))

  useEffect(rehighlightCurrent)

  function onDragStart(start: DragStart, provided: ResponderProvided) {
    // TODO Replace with localized instructions
    provided.announce(NO_ANNOUCEMENT)
    const itemInfo = JSON.parse(start.draggableId)
    setCurrentlyDroppableIn(itemInfo.droppableIn)
  }

  function onDragUpdate(_update: DragUpdate, provided: ResponderProvided) {
    // TODO Replace with localized instructions
    provided.announce(NO_ANNOUCEMENT)
  }

  const onDragEnd = React.useCallback(
    function (result: DropResult, provided: ResponderProvided) {
      // TODO Replace with localized instructions
      provided.announce(NO_ANNOUCEMENT)
      setCurrentlyDroppableIn([])
      if (result.reason === 'DROP' && result.destination) {
        const itemInfo = JSON.parse(result.draggableId)
        onDragEnd_({
          draggableId: itemInfo.draggableId,
          source: result.source,
          destination: result.destination,
        })
      }
    },
    [onDragEnd_]
  )

  // for context, used by Menu.Options
  const onScrollBottom = React.useCallback(
    function (e: React.UIEvent<HTMLDivElement, UIEvent>) {
      if (isScrolledToBottom(e)) {
        onScrollBottom_(e)
      }
    },
    [onScrollBottom_]
  )

  return (
    <MenuContext.Provider
      value={{
        a11yOptionsProps,
        currentlyDroppableIn,
        onHoverItem: handleItemHover,
        onScrollBottom,
        onSelect,
        role,
      }}
    >
      <StyledWrapper ref={ref}>
        <DragDropContext
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
          enableDefaultSensors={false}
          sensors={[useMouseSensor, useTouchSensor, useKeyboardSensor]}
        >
          <StyledMenu {...props} />
        </DragDropContext>
      </StyledWrapper>
    </MenuContext.Provider>
  )
})

export const Group = React.forwardRef<
  HTMLDivElement,
  DivAttributes & GroupProps
>(function Group({ clickable, item = 'group', id, children, ...props }, ref) {
  const { fieldProps, labelProps } = useLabel({
    id,
    label: children,
    labelElementType: 'span',
  })

  return (
    <StyledGroup
      ref={ref}
      onClick={(e) => {
        // TODO: implement selecting groups?
      }}
      data-group={true}
      data-value={JSON.stringify(item)}
      role="separator"
      {...fieldProps}
      {...props}
    >
      <span {...labelProps}>{children}</span>
    </StyledGroup>
  )
})

function findDraggableRecursively(
  children: React.ReactNode,
  draggableId: any
): React.ReactElement | null {
  const nodeChildren = React.Children.toArray(children)
  for (let i = 0; i < nodeChildren.length; i++) {
    const child = nodeChildren[i]
    if (isValidElement(child)) {
      if ('draggableId' in child.props) {
        if (child.props.draggableId === draggableId) {
          return child
        }
      } else {
        const matchingChild = findDraggableRecursively(
          child.props.children,
          draggableId
        )
        if (matchingChild) {
          return matchingChild
        }
      }
    }
  }
  return null
}

export function Droppable({ id, children }: DroppableProps) {
  const ctx = useMenuContext()
  const cloneZIndex = useZIndexContext().value

  function renderItemClone(
    provided: DraggableProvided,
    _snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
  ) {
    const draggableInfo = JSON.parse(rubric.draggableId)
    const draggingChild = findDraggableRecursively(
      children,
      draggableInfo.draggableId
    )
    const cloneProps = draggingChild?.props ?? {}

    return (
      <ClonedItem
        hasCheckbox={draggingChild?.type === CheckboxItem}
        provided={provided}
        cloneZIndex={cloneZIndex}
        {...cloneProps}
      />
    )
  }

  return (
    <DndDroppable
      droppableId={id}
      isDropDisabled={!ctx.currentlyDroppableIn.includes(id)}
      renderClone={renderItemClone}
    >
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <StyledDroppable ref={provided.innerRef} data-droppable>
          {children}
          {provided.placeholder}
        </StyledDroppable>
      )}
    </DndDroppable>
  )
}

// creates a version of a draggable item that can be positioned correctly within modals
// this should always have the same appearance of an Item or CheckboxItem, but doesn't
// need to handle selection events, which can't be done while the item is being dragged
const ClonedItem = ({
  hasCheckbox = false,
  children = null,
  selected = false,
  provided,
  ...props
}: DivAttributes &
  ItemProps &
  Omit<CheckboxProps, 'checked'> & {
    provided: DraggableProvided
    hasCheckbox: boolean
    cloneZIndex: number
  }) => {
  const itemProps = {
    ...props,
    $selected: selected,
  }

  const itemChildren = hasCheckbox ? (
    <Checkbox
      indeterminate={props.indeterminate}
      error={props.error}
      checked={selected}
      onChange={noop}
    >
      {children}
    </Checkbox>
  ) : (
    children
  )

  return (
    <StyledItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      {...itemProps}
      $isDraggable
      $isDragging
      style={{
        ...provided.draggableProps.style,
        ...props.style,
        zIndex: props.cloneZIndex,
      }}
    >
      <StyledGrip>
        <Grip color={colors.gray45} />
      </StyledGrip>
      {itemChildren}
    </StyledItem>
  )
}

export const Item = React.forwardRef(function Item<
  E extends React.ElementType = MenuItemDefaultElement
>(
  {
    as,
    /* @ts-ignore */
    children = null,
    item = null,
    /* @ts-ignore */
    onClick: onClick_ = noop,
    selected = false,
    suggested = false,
    disabled = false,
    id: _id,
    role,
    restoreFocus,
    ...props
  }: PolymorphicAs.ComponentPropsWithoutRef<MenuItemProps, E>,
  /**
   * TODO: although it is typed just fine from the outside (`<Menu.Item ref>` that is),
   *  an effort is required to type it properly here too
   */
  forwardRef: React.Ref<any>
) {
  const [ref, setRef] = useMergeRef<HTMLDivElement>(forwardRef)
  const id = useId(_id)

  const ctx = useMenuContext()

  const onClick = React.useCallback(
    function (event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      if (disabled) return

      onClick_(event)

      ctx.onSelect({
        item,
        event,
        group: false,
        action: selected ? 'unselected' : 'selected',
        ...(restoreFocus === undefined ? {} : { restoreFocus }),
      })
    },
    [ctx, item, onClick_, disabled, selected]
  )

  let contextRole
  let ariaSelected

  if (ctx.role === 'listbox') {
    contextRole = 'option'
    ariaSelected = selected ? true : undefined
  } else if (ctx.role === 'menu') {
    contextRole = 'menuitem'
  }

  const itemProps = {
    'aria-disabled': disabled ? true : undefined,
    'aria-selected': ariaSelected,
    ...props,
    id,
    role: role ?? contextRole,
    onClick,
    onMouseMove: (e: any) => ctx.onHoverItem(e.currentTarget),
    'data-group': false,
    'data-value': JSON.stringify(item),
    'data-selected': selected,
    'data-suggested': suggested,
    'data-disabled': disabled,
    'data-restorefocus': restoreFocus,
    $disabled: disabled,
    $selected: selected,
  }

  if (
    'droppableIn' in props &&
    'draggableId' in props &&
    'draggableIndex' in props
  ) {
    // encoding id and valid droppables as the draggableId, so we can use them to determine where this item can be dropped
    const droppableInfo = {
      draggableId: props.draggableId,
      droppableIn: Array.isArray(props.droppableIn)
        ? props.droppableIn
        : [props.droppableIn],
    }
    return (
      <Draggable
        draggableId={JSON.stringify(droppableInfo)}
        index={props.draggableIndex}
        isDragDisabled={disabled}
      >
        {(provided: DraggableProvided) => (
          <StyledItem
            ref={mergeRefs(provided.innerRef, setRef)}
            as={as}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...itemProps}
            $isDraggable={!disabled}
            style={{ ...provided.draggableProps.style, ...itemProps.style }}
            tabIndex={-1}
          >
            {!disabled && (
              <StyledGrip>
                <Grip color={colors.gray45} />
              </StyledGrip>
            )}
            {children}
          </StyledItem>
        )}
      </Draggable>
    )
  }

  return (
    <StyledItem ref={setRef} as={as} {...itemProps}>
      {children}
    </StyledItem>
  )
}) as PolymorphicAs.ComponentWithForwardedRef<
  MenuItemProps,
  MenuItemDefaultElement
>

export const CheckboxItem = React.forwardRef<
  HTMLDivElement,
  DivAttributes &
    (ItemProps | ItemWithDraggingProps) &
    Omit<CheckboxProps, 'checked'>
>(function CheckboxItem(
  {
    selected = false,
    disabled = false,
    error = false,
    indeterminate = false,
    children = null,
    role,
    ...props
  },
  forwardRef
) {
  const ctx = useMenuContext()

  let contextRole
  let ariaChecked: React.AriaAttributes['aria-checked']
  let ariaSelected

  if (ctx.role === 'listbox') {
    contextRole = 'option'
    ariaSelected = selected ? true : undefined
  } else if (ctx.role === 'menu') {
    contextRole = 'menuitemcheckbox'

    if (indeterminate) {
      ariaChecked = 'mixed'
    } else {
      ariaChecked = selected ? 'true' : 'false'
    }
  }

  return (
    <Item
      aria-checked={ariaChecked}
      aria-disabled={disabled ? true : undefined}
      aria-selected={ariaSelected}
      {...props}
      role={role ?? contextRole}
      selected={selected}
      disabled={disabled}
      ref={forwardRef}
    >
      <FakeCheckbox
        indeterminate={indeterminate}
        error={error}
        disabled={disabled}
        checked={selected}
      >
        {children}
      </FakeCheckbox>
    </Item>
  )
})
export const Options = React.forwardRef<HTMLDivElement, OptionsProps>(
  function Options(props, ref) {
    const ctx = useMenuContext()

    return (
      <StyledOptions
        {...props}
        {...ctx.a11yOptionsProps}
        data-internal="menuimperative-options"
        ref={ref}
        onScroll={ctx.onScrollBottom}
      />
    )
  }
)

export const Search = ({
  className,
  i18nScope = 'core.menu',
  placeholder,
  onChange: _onChange,
  ...props
}: MenuSearchProps) => {
  const I18n = useI18nContext()

  const [value, setValue] = React.useState('')

  const onChange: TypeaheadProps['onChange'] = React.useCallback(
    function (value, event) {
      setValue(value)
      _onChange?.(event)
    },
    [_onChange]
  )

  // intentionally changed to type text + searchbox,
  // voiceOver focus goes to clear button when no results in Chrome
  return (
    <StyledSearch className={className}>
      <Typeahead
        {...props}
        type="text"
        role="searchbox"
        autoFocus={true}
        placeholder={placeholder || I18n.t('search', { scope: i18nScope })}
        onChange={onChange}
        value={value}
      />
    </StyledSearch>
  )
}

export const Header = React.forwardRef<
  HTMLDivElement,
  DivAttributes & HeaderProps
>(function Header({ ...props }, ref) {
  return <StyledHeader ref={ref} {...props} />
})

export const Footer = React.forwardRef<
  HTMLDivElement,
  DivAttributes & FooterProps
>(function Footer({ padding = 'md lg', ...props }, ref) {
  return <StyledFooter ref={ref} padding={padding} {...props} />
})

MenuImperative_.displayName = 'Menu'

Droppable.displayName = 'Menu.Droppable'

Footer.displayName = 'Menu.Footer'

Group.displayName = 'Menu.Group'

Header.displayName = 'Menu.Header'

Item.displayName = 'Menu.Item'

Options.displayName = 'Menu.Options'

Search.displayName = 'Menu.Search'

/**

   @since 10.19.0

   @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-menu-imperative--demo)

 */
export const MenuImperative = addSubcomponents(
  {
    CheckboxItem,
    Droppable,
    Footer,
    Group,
    Header,
    Item,
    Options,
    Search,
  },
  MenuImperative_
)
