import type { OverlayPlacement } from '../Overlay/useOverlay'

export type Placement =
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'center'
  | 'left'
  | 'left-bottom'
  | 'left-top'
  | 'right'
  | 'right-bottom'
  | 'right-top'
  | 'top'
  | 'top-left'
  | 'top-right'
export const getPlacement = (p: Placement): OverlayPlacement => {
  const jointPlacements: Partial<Record<Placement, OverlayPlacement>> = {
    'right-top': 'right-start',
    'right-bottom': 'right-end',
    'left-top': 'left-start',
    'left-bottom': 'left-end',
    'bottom-left': 'bottom-start',
    'bottom-right': 'bottom-end',
    'top-left': 'top-start',
    'top-right': 'top-end',
  }

  return jointPlacements[p] ?? (p as OverlayPlacement)
}
