import styled from 'styled-components'
import { colors } from '../../_styles/colors'
import { SourceItem } from '../SourceItem/SourceItem'

export const StyledSidebar = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 300px;
  border-right: 1px solid ${colors.gray85};
`

export const StyledNavigation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:focus {
    outline: none;
  }
`

export const StyledSourceItem = styled(SourceItem)`
  width: 100%;
  padding-left: 10px;

  &:focus {
    padding-left: 9px;
  }
`
