import { FlexList, Toast } from '@procore/core-react'
import React from 'react'
import styled from 'styled-components'
import { isBrowser } from '../../utils/browser'
import Logger, { LoggerRenderProps } from './Logger'

const ActionLayout = styled.div`
  bottom: 24px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
`

const getLatest = (list: string[]) => list.slice(-10).reverse()

const ActionLogger: React.FC = () => {
  return isBrowser ? (
    <Logger>
      {({ logger, messages }: LoggerRenderProps) => {
        const mutableWindow: any = window || {}
        mutableWindow.logger = logger

        const latestMessages = getLatest(messages)

        return (
          <ActionLayout>
            <FlexList size="xs" direction="column" alignItems="center">
              {latestMessages.map((msg, index) => {
                return (
                  <Toast key={`${msg}-${index}`} variant="success">
                    {msg}
                  </Toast>
                )
              })}
            </FlexList>
          </ActionLayout>
        )
      }}
    </Logger>
  ) : (
    <div></div>
  )
}

export default ActionLogger
