import * as React from 'react'
import { Search, Flex, Card, Menu, Typography } from '@procore/core-react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  Configure,
  connectSearchBox,
  connectHits,
  connectHighlight,
} from 'react-instantsearch-dom'
import algoliaLogo from '../images/algolia-logo.png'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID || '',
  process.env.GATSBY_ALGOLIA_API_KEY || ''
)

interface HitProps {
  hit: Hit
}

type Hit = {
  id: string
  path: string
  rawBody: string
  title: string
  parentTitle?: string
}

const PaddedText = styled.span`
  display: inline-block;
  padding: 0px 2px;
`

function Hit({ hit }: HitProps) {
  return (
    <Flex direction="row" justifyContent="space-between">
      {hit.parentTitle ? (
        <Flex direction="row">
          <Highlight attribute="parentTitle" hit={hit} />
          <PaddedText>{'>'}</PaddedText>
          <Highlight attribute="title" hit={hit} />
        </Flex>
      ) : (
        <Highlight attribute="title" hit={hit} />
      )}
      <Highlight attribute="section" hit={hit} />
    </Flex>
  )
}

const HighlightedFont = styled(Typography)`
  text-shadow: 0px 0px 1px black;
`

const Highlight = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  })

  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <HighlightedFont key={index}>{part.value}</HighlightedFont>
        ) : (
          <Typography key={index}>{part.value}</Typography>
        )
      )}
    </span>
  )
})

const StyledSearch = styled(Search)`
  width: 250px;
`

interface SearchBoxProps {
  refine: (value: string) => void
  setHitsDisplayed: (value: boolean) => void
}
const SearchBox = connectSearchBox(
  ({ refine, setHitsDisplayed }: SearchBoxProps) => {
    const [searchValue, setSearchValue] = React.useState('')

    return (
      <StyledSearch
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value)
          refine(e.target.value)
        }}
        onClear={() => {
          refine('')
          setHitsDisplayed(false)
        }}
        onSelect={() => {
          setHitsDisplayed(true)
        }}
        placeholder="Search"
      />
    )
  }
)

const FloatingHits = styled.div`
  position: fixed;
  top: 60px;
  width: 350px;
`

const AlgoliaLogoView = styled.img`
  height: 20px;
  align-self: flex-end;
  margin: 12px;
`

interface HitsProps {
  hits: Hit[]
  hitsDisplayed: boolean
  setHitsDisplayed: (value: boolean) => void
}

const Hits = connectHits(
  ({ hits, hitsDisplayed, setHitsDisplayed }: HitsProps) => {
    return hits.length && hitsDisplayed ? (
      <FloatingHits>
        <Card>
          <Flex direction="column" alignItems="stretch">
            <Menu
              onSelect={({ item }) => {
                setHitsDisplayed(false)
                navigate(item)
              }}
            >
              <Menu.Options>
                {hits.map((hit) => (
                  <Menu.Item item={hit.path}>
                    <Hit hit={hit} />
                  </Menu.Item>
                ))}
              </Menu.Options>
            </Menu>
            <AlgoliaLogoView src={algoliaLogo} />
          </Flex>
        </Card>
      </FloatingHits>
    ) : null
  }
)

const isBrowser = typeof window !== 'undefined'

interface GlobalSearchProps {
  hitsDisplayed: boolean
  setHitsDisplayed: (value: boolean) => void
}

const GlobalSearch = React.forwardRef<HTMLDivElement, GlobalSearchProps>(
  ({ hitsDisplayed, setHitsDisplayed }, ref) => {
    return isBrowser ? (
      <div ref={ref}>
        <InstantSearch
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME || ''}
          searchClient={searchClient}
        >
          <Configure hitsPerPage={8} />
          <SearchBox setHitsDisplayed={setHitsDisplayed} />
          <Hits
            hitsDisplayed={hitsDisplayed}
            setHitsDisplayed={setHitsDisplayed}
          />
        </InstantSearch>
      </div>
    ) : (
      <div />
    )
  }
)

export default GlobalSearch
