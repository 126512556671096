import React from 'react'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { slotify } from '../_utils/slotify'
import type { DivAttributes } from '../_utils/types'
import {
  StyledAssets,
  StyledSubtext,
  StyledText,
  StyledTitle,
  StyledTitleActions,
  StyledTitleContent,
  StyledTitlePill,
} from './Title.styles'
import type { TitleAssetsProps, TitleProps } from './Title.types'

function isValidElement(element: unknown) {
  return Boolean(element)
}

const Title_ = React.forwardRef<HTMLDivElement, TitleProps>(function Title_(
  { children, ...props },
  ref
) {
  const { pills, text, subtext, actions, assets } = slotify(children, {
    pills: TitlePills,
    text: TitleText,
    subtext: TitleSubtext,
    actions: TitleActions,
    assets: TitleAssets,
  })

  const hasActions = isValidElement(actions.slotChildren)
  const hasAssets = isValidElement(assets.slotChildren)
  const hasPills = isValidElement(pills.slotChildren)
  const hasText = isValidElement(text.slotChildren)
  const hasSubtext = isValidElement(subtext.slotChildren)
  const isEmpty = [hasActions, hasAssets, hasPills, hasText, hasSubtext].every(
    (el) => !el
  )

  if (isEmpty) {
    return null
  }

  return (
    <StyledTitle ref={ref} {...props}>
      {hasAssets && (
        <StyledAssets ref={assets.slotRef} {...assets.slotProps}>
          {assets.slotChildren}
        </StyledAssets>
      )}
      <StyledTitleContent $hasActions={hasActions}>
        {hasText && (
          <StyledText ref={text.slotRef} {...text.slotProps}>
            {text.slotChildren}
          </StyledText>
        )}
        {hasPills && (
          <StyledTitlePill ref={pills.slotRef} {...pills.slotProps}>
            {pills.slotChildren}
          </StyledTitlePill>
        )}
        {hasSubtext && (
          <StyledSubtext
            ref={subtext.slotRef}
            {...subtext.slotProps}
            color="gray45"
          >
            {subtext.slotChildren}
          </StyledSubtext>
        )}
      </StyledTitleContent>
      {hasActions && (
        <StyledTitleActions ref={actions.slotRef} {...actions.slotProps}>
          {actions.slotChildren}
        </StyledTitleActions>
      )}
    </StyledTitle>
  )
})

const TitlePills = React.forwardRef<HTMLDivElement, DivAttributes>(
  function TitlePills() {
    return <div />
  }
)
const TitleText = React.forwardRef<HTMLDivElement, DivAttributes>(
  function TitleText() {
    return <div />
  }
)
const TitleSubtext = React.forwardRef<HTMLDivElement, DivAttributes>(
  function TitleSubtext() {
    return <div />
  }
)
const TitleActions = React.forwardRef<HTMLDivElement, DivAttributes>(
  function TitleActions() {
    return <div />
  }
)
const TitleAssets = React.forwardRef<
  HTMLDivElement,
  DivAttributes & TitleAssetsProps
>(function TitleAssets() {
  return <div />
})

export const Title = addSubcomponents(
  {
    Pills: TitlePills,
    Text: TitleText,
    Subtext: TitleSubtext,
    Actions: TitleActions,
    Assets: TitleAssets,
  },
  Title_
)
