import React from 'react'

/* TYPES */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface ListNavigationConfig {
  circular?: boolean
  initialIndex?: number
  size?: number
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface ListNavigationApi {
  clear: () => void
  decrement: () => void
  decrementBy: (amount: number) => void
  increment: () => void
  incrementBy: (amount: number) => void
  index: number
  reset: () => void
  set: (index: number) => void
  setSize: (index: number) => void
  size: number
}

interface Props extends ListNavigationConfig {
  children?: React.ReactNode
}

interface RenderProps extends ListNavigationConfig {
  children: (props: ListNavigationApi) => {}
}

function clamp(max: number, n: number) {
  return Math.min(max - 1, Math.max(0, n))
}

function wrap(max: number, n: number) {
  if (max === 0) {
    return n
  }

  return n < 0 ? max + n : n % max
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useListNavigation({
  circular = false,
  initialIndex = 0,
  size: initialSize = 0,
}: ListNavigationConfig): ListNavigationApi {
  const [index, setIndex] = React.useState(initialIndex)

  const [size, setSize] = React.useState(initialSize)

  const set = React.useCallback(
    function (i: number) {
      if (size === 0) {
        setIndex(i)
      } else {
        setIndex(clamp(size, circular ? wrap(size, i) : i))
      }
    },
    [circular, size]
  )

  function clear() {
    set(0)
  }

  function increment() {
    set(index + 1)
  }

  function incrementBy(amount: number) {
    set(index + amount)
  }

  function decrement() {
    set(index - 1)
  }

  function decrementBy(amount: number) {
    set(index - amount)
  }

  function reset() {
    set(initialIndex)
  }

  return {
    clear,
    decrement,
    decrementBy,
    increment,
    incrementBy,
    index,
    reset,
    set,
    setSize,
    size,
  }
}

/* RENDER PROP */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function ListNavigation({ children, ...props }: RenderProps) {
  const listNavigation = useListNavigation(props)

  return typeof children === 'function' ? children(listNavigation) : children
}
