import React from 'react'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { StyledGridCol, StyledGridRow } from './Grid.styles'
import type {
  GridColProps,
  GridContextShape,
  GridProps,
  GridRowProps,
} from './Grid.types'
import { overrideIfNotDefined } from './Grid.utils'

const defaultContextShape: GridContextShape = {
  gutterX: 'none',
  gutterY: 'none',
  colStackCap: 'mobileLg',
}
const GridContext = React.createContext<GridContextShape>(defaultContextShape)

function BaseGrid({ gutterX, gutterY, colStackCap, ...props }: GridProps) {
  const gridContextValue = overrideIfNotDefined(defaultContextShape, {
    gutterX,
    gutterY,
    colStackCap,
  })
  return (
    <GridContext.Provider value={gridContextValue}>
      <div {...props} />
    </GridContext.Provider>
  )
}

const GridRow = React.forwardRef<HTMLDivElement, GridRowProps>(function GridRow(
  props,
  ref
) {
  const { gutterX, gutterY } = React.useContext(GridContext)
  return (
    <StyledGridRow {...props} $gutterX={gutterX} $gutterY={gutterY} ref={ref} />
  )
})

const GridCol = React.forwardRef<HTMLDivElement, GridColProps>(function GridCol(
  { colWidth = 'auto', colStart: offset, ...props },
  ref
) {
  const {
    gutterX,
    gutterY,
    colStackCap: colStackBreakpoint,
  } = React.useContext(GridContext)
  return (
    <StyledGridCol
      {...props}
      ref={ref}
      $span={colWidth}
      $offset={offset}
      $gutterX={gutterX}
      $gutterY={gutterY}
      $colStackBreakpoint={colStackBreakpoint}
    />
  )
})

export const Grid = addSubcomponents(
  {
    Row: GridRow,
    Col: GridCol,
  },
  BaseGrid
)
