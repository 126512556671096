import type { DateTimeOptions } from '@procore/globalization-toolkit'
import { DateTimeFormatter } from '@procore/globalization-toolkit'
import * as R from 'ramda'
import { getFormatterLocale } from './i18n'

const dateTimePresets = {
  time: {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  } as Intl.DateTimeFormatOptions,
  'abbr-weekday-abbr-date': {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions,
  date: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions,
  'weekday-date': {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions,
  none: {} as Intl.DateTimeFormatOptions,
  'numeric-date': {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions,
}

export type IntlDateTimeOptionPresets = keyof typeof dateTimePresets

export const getPresetDateTimeOptions = (type: IntlDateTimeOptionPresets) => {
  return dateTimePresets[type] || dateTimePresets.none
}

const pseudoDate = (date: string) => date.replace(/[\/\-\,\:]/g, '.')

const pseudoFormatPart = ({
  type,
  value,
}: Intl.DateTimeFormatPart): Intl.DateTimeFormatPart =>
  type === 'literal' ? { type, value: '.' } : { type, value }

function formatterFactory(
  formatterLocale: string,
  options: Intl.DateTimeFormatOptions
): DateTimeFormatter {
  try {
    return new DateTimeFormatter(transformDateOptions(formatterLocale, options))
  } catch (error) {
    console.error(
      `@procore/core-react Intl.DateTimeFormat error for locale ${formatterLocale}. Will fall back to en-US`,
      error
    )
    return new DateTimeFormatter(transformDateOptions('en-US', options))
  }
}

function transformDateOptions(
  formatterLocale: string,
  options: Intl.DateTimeFormatOptions
): DateTimeOptions {
  const isDefined = (val: string | undefined) => val !== undefined
  return R.pickBy(isDefined, { ...{ locale: formatterLocale }, ...options })
}

const mapFormatToParts = (
  parts: Intl.DateTimeFormatPart[]
): Intl.DateTimeFormatPart[] => parts.map(pseudoFormatPart)

export function intlDateTimeFormat(
  locale: string,
  options: Intl.DateTimeFormatOptions = {}
) {
  if (!locale) {
    throw new Error('Locale is a required parameter.')
  }
  const formatterLocale = getFormatterLocale(locale)
  const formatter = formatterFactory(formatterLocale, options)

  return locale === 'pseudo'
    ? {
        format: (date: Date) => pseudoDate(formatter.formatDateTime(date)),
        formatToParts: (date: Date) =>
          mapFormatToParts(formatter.formatDateTimeToParts(date)),
        resolvedOptions: () => formatter.formatter.resolvedOptions(),
      }
    : {
        format: (date: Date) => formatter.formatDateTime(date),
        formatToParts: (date: Date) => formatter.formatDateTimeToParts(date),
        resolvedOptions: () => formatter.formatter.resolvedOptions(),
      }
}

export function toDate(date: string | Date, log?: string) {
  if (date instanceof Date) {
    return date
  }
  if (!isNaN(Date.parse(date))) {
    return new Date(date)
  }
  if (log) {
    console.warn(
      `@procore/core-react: ${log}; toDate invalid date format`,
      date
    )
  }
  return date as any as Date
}

// Date should already be shifted to the time zone, meaning we already have the date in location and
// now we need it in a specific style https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time#valid_datetime_values
// toLocaleDateString uses Intl DateTimeFormat
// alternative toLocaleDateString('en-GB', { timeZone })
export function formatMachineDate(date: Date) {
  return date.toLocaleDateString('en-GB').split('/').reverse().join('-')
}
