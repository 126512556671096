import React from 'react'
import type { FileExplorerSidebarProps } from './FileExplorer.types'
import {
  StyledNavigation,
  StyledSidebar,
  StyledSourceItem,
} from './FileExplorerSidebar.styles'
import { useSidebarNavigation } from './useSidebarNavigation'

export function FileExplorerSidebar({
  value: selectedSource,
  onChange,
  sources,
  isOpen,
}: FileExplorerSidebarProps) {
  const [isNavFocused, setIsNavFocused] = React.useState(false)
  const [isFocusVisible, setIsFocusVisible] = React.useState(true)
  const focusedSourceRef = React.useRef<HTMLDivElement>(null)

  const selectedSourceIndex = sources.findIndex(
    ({ sourceId }) => sourceId === selectedSource
  )

  const sidebarNavigation = useSidebarNavigation({
    initialIndex: selectedSourceIndex,
    size: sources.length,
    selectedIndex: selectedSourceIndex,
  })

  React.useEffect(() => {
    sidebarNavigation.set(selectedSourceIndex)
  }, [selectedSourceIndex])

  React.useEffect(() => {
    if (focusedSourceRef.current && isOpen) {
      focusedSourceRef.current.focus()
    }
  }, [sidebarNavigation.index, isOpen])

  React.useEffect(() => {
    sidebarNavigation.setSize(sources.length)
  }, [sources.length])

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (!isNavFocused) return

    switch (e.nativeEvent.key) {
      case 'Enter':
        e.preventDefault()
        onChange(sources[sidebarNavigation.index].sourceId)
        break

      case 'ArrowUp':
      case 'Up':
        e.preventDefault()

        sidebarNavigation.up()
        break

      case 'ArrowDown':
      case 'Down':
        e.preventDefault()
        sidebarNavigation.down()
        break
    }
  }

  return (
    <StyledSidebar>
      <StyledNavigation>
        {sources.map((source, idx) => {
          const isFocused = idx === sidebarNavigation.index

          return (
            <StyledSourceItem
              key={source.sourceId}
              loading={source.isUploading}
              error={source.hasError}
              icon={source.icon}
              selected={selectedSource === source.sourceId}
              focused={isNavFocused && isFocusVisible && isFocused}
              tabIndex={isFocused ? 0 : -1}
              ref={isFocused ? focusedSourceRef : null}
              onMouseDown={() => {
                // prevent focus blink on the latest focused item
                setIsFocusVisible(false)
              }}
              onMouseUp={() => {
                setIsFocusVisible(true)
              }}
              count={source.value.length}
              onFocus={() => {
                setIsNavFocused(true)
              }}
              onBlur={() => {
                setIsNavFocused(false)
              }}
              onKeyDown={onKeyDown}
              onClick={() => {
                sidebarNavigation.set(idx)
                onChange(source.sourceId)
              }}
            >
              {source.title}
            </StyledSourceItem>
          )
        })}
      </StyledNavigation>
    </StyledSidebar>
  )
}
