import React from 'react'
import type { InputAttributes } from '../_utils/types'
import { StyledInput } from './Input.styles'
import type { InputProps } from './Input.types'

/**

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-input--demo)

 @see [Design Guidelines](https://design.procore.com/text-input)

 */
export const Input = React.forwardRef<
  HTMLInputElement,
  InputAttributes & InputProps
>(function Input({ children, ...props }, ref) {
  return <StyledInput {...props} ref={ref} />
})

Input.displayName = 'Input'
