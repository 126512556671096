import React from 'react'

interface Config {
  /**
   * @since 10.19.0
   */
  when?: any
  /**
   * @since 10.19.0
   */
  oldThing: string
  /**
   * @since 10.19.0
   */
  newThing?: string
  /**
   * @since 10.19.0
   */
  link?: string
}

/* HOOK */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useDeprecation(config: Config) {
  const { oldThing, newThing, link } = config
  let when = 'when' in config ? typeof config.when !== 'undefined' : true

  React.useLayoutEffect(() => {
    if (
      when &&
      ['test', 'development'].indexOf(process.env.NODE_ENV || '') >= 0
    ) {
      const notice = `${oldThing} is deprecated`
      const recommendation = newThing ? `, please use ${newThing}` : ''

      console.warn(`${notice}${recommendation}`, link ?? '')
    }
  }, [when, newThing, oldThing, link])
}
