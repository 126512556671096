import React from 'react'
import { colors } from '../_styles/colors'
import {
  PlaceholderItem,
  RoundPlaceholderItem,
} from './GhostPlaceholder.styles'
import type { GhostPlaceholderProps } from './GhostPlaceholder.types'

const GhostPlaceholder: React.FC<GhostPlaceholderProps> = React.memo(
  ({
    shape,
    small = false,
    startGradientColor = colors.gray94,
    endGradientColor = colors.gray20,
    ...props
  }) => {
    if (shape === 'round') {
      return (
        <RoundPlaceholderItem
          $small={small}
          $startGradientColor={startGradientColor}
          $endGradientColor={endGradientColor}
          {...props}
        />
      )
    }
    return (
      <PlaceholderItem
        $startGradientColor={startGradientColor}
        $endGradientColor={endGradientColor}
        {...props}
      />
    )
  }
)

export default GhostPlaceholder
