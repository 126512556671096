import styled from 'styled-components'
import { Page } from '../PageLayout'
import { spacing } from '../_styles/spacing'

export const StyledTitleOuter = styled(Page.Title)`
  align-items: center;
  display: flex;
  gap: ${spacing.md}px;
`

export const StyledTitleInner = styled.div`
  width: 100%;
`

export const StyledToolLandingPageBodyContent = styled.div`
  > * + * {
    margin-top: ${spacing.sm}px;
  }
`
