export interface Rect extends Omit<DOMRect, 'x' | 'y' | 'toJSON'> {
  x?: number
  y?: number
}

export const defaultRect: Rect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

function isDOMRect(rect: Rect | DOMRect): rect is DOMRect {
  return (rect as DOMRect).x !== undefined
}

export function getCrossbrowserBoundingRect(rect: Rect | DOMRect): Rect {
  return isDOMRect(rect)
    ? rect
    : {
        x: rect.left,
        y: rect.top,
        width: rect.width,
        height: rect.height,
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
      }
}
export function getBoundingRect(node: HTMLElement) {
  const rect =
    node && node.getBoundingClientRect
      ? node.getBoundingClientRect()
      : defaultRect

  return getCrossbrowserBoundingRect(rect)
}
