import React from 'react'
import type { ChangeEvent } from '../_hooks/TextEntry'
import type { SearchHook, SearchHookConfig } from './Search.types'

// TODO remove this and do a breaking change on Search to `useImperativeHandle`
// to support controlled/uncontrolled behavior
export function useSearch({
  onChange: externalOnChange = () => {},
  onClear = () => {},
  onSubmit = () => {},
  initialValue = '',
}: SearchHookConfig): SearchHook {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const isEmpty = value === ''

  function clear() {
    setValue('')
    onChange({ target: { value: '' } } as ChangeEvent)
    onClear()
  }

  function submit() {
    onSubmit(value)
  }

  function onChange(e: ChangeEvent) {
    setValue(e.target.value)

    externalOnChange(e)
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      submit()
    } else if (e.key === 'Escape' || e.key === 'Esc') {
      // only let parent listeners (modal, overlay) know when empty
      if (value) {
        e.stopPropagation()
      }
      clear()
    }
  }

  return {
    clear,
    domHandlers: {
      onChange,
      onKeyDown,
    },
    isEmpty,
    submit,
    value,
  }
}
