import { ExternalLink } from '@procore/core-icons/dist'
import { Card } from '@procore/core-react'
import { graphql, Link, navigate } from 'gatsby'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { AuthPage } from '../partials/Auth'
import { ContentBody } from '../partials/ContentBody'
import { TOC } from '../partials/TOC'
import { colors, layout } from '../utils/styles'
import { useScrollToAnchor } from '../utils/useScrollToAnchor'

interface Heading {
  value: string
  depth: number
}

interface PageProps {
  data: {
    parent: {
      frontmatter: {
        title: string
        designGuidelines: string
      }
    }
    page: {
      headings: Heading[]
      body: string
      frontmatter: {
        title: string
        private: boolean
        redirect: string
        sidebar: boolean
      }
    }
    siblings: {
      nodes: Array<{
        fields: {
          path: string
        }
        frontmatter: {
          title: string
          private: boolean
          sidebar: boolean
        }
        id: string
      }>
    }
  }
  location: {
    hash: string
  }
  pageContext: {
    title: string
  }
}

const ContentView = styled.div`
  padding: ${layout.pagePadding}px;
`

const ContentHeaderView = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.d9d9d9};
  display: flex;
  flex: 1 1 auto;
  height: 128px;
  padding: 0 ${layout.pagePadding}px;

  h1 {
    flex: 0 0 auto;
    font-size: 32px;
    font-weight: bold;
    line-height: 32px;
    margin-right: 16px;
  }
`

const getTabLinkStyles = () => css`
  border-bottom: 2px solid transparent;
  color: ${colors._707070};
  display: inline-block;
  font-size: 16px;
  margin-left: 16px;
  padding: 2px 2px 6px 2px;
  position: relative;
  text-decoration: none;
  top: 6px;

  &.active {
    border-color: ${colors.f47e42};
  }

  &:hover,
  &.active {
    color: ${colors._202020};
  }
`
const TabLinkView = styled(Link)`
  ${getTabLinkStyles()}
`

const TabExternalLinkView = styled.a`
  ${getTabLinkStyles()}
`

const ContentBodyView = styled.div`
  display: flex;
  flex: 1 1 0;
`

const TOCView = styled.div`
  flex: 1 1 100%;
  max-width: 20%;
  padding-top: ${layout.pagePadding}px;
`

const CardView = styled(Card)`
  max-width: 1200px;
`

const isBrowser = typeof window !== 'undefined'

export function ContentTemplate({ data, pageContext }: PageProps) {
  useScrollToAnchor()

  if (isBrowser && data.page.frontmatter.redirect) {
    navigate(data.page.frontmatter.redirect, { replace: true })

    return null
  }

  const title =
    data.page.frontmatter.sidebar === false
      ? data.parent?.frontmatter.title
      : data.page.frontmatter.title

  const headings = data.page.headings ? data.page.headings : []

  const designGuidelines = data.parent?.frontmatter.designGuidelines

  return (
    <AuthPage privateRoute={data.page.frontmatter.private}>
      <ContentView>
        <CardView>
          <>
            <ContentHeaderView>
              <h1>{title}</h1>
              {data.siblings.nodes.map((node, i) =>
                node.frontmatter.sidebar === false ? (
                  <TabLinkView
                    key={i}
                    to={node.fields.path}
                    activeClassName="active"
                  >
                    {node.frontmatter.title}
                  </TabLinkView>
                ) : null
              )}
              {designGuidelines && (
                <TabExternalLinkView
                  href={designGuidelines}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Design Guidelines{' '}
                  <ExternalLink
                    size="sm"
                    style={{ verticalAlign: 'text-bottom' }}
                  />
                </TabExternalLinkView>
              )}
            </ContentHeaderView>
            <ContentBodyView>
              <ContentBody>{data.page.body}</ContentBody>
              {headings.length > 1 && (
                <TOCView>
                  <TOC headings={headings} />
                </TOCView>
              )}
            </ContentBodyView>
          </>
        </CardView>
      </ContentView>
    </AuthPage>
  )
}

export const pageQuery = graphql`
  query PageQuery($id: String!, $parentPath: String!) {
    parent: mdx(fields: { path: { eq: $parentPath } }) {
      frontmatter {
        title
        designGuidelines
      }
    }

    page: mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        private
        redirect
        sidebar
      }
      headings(depth: h1) {
        value
        depth
      }
      fields {
        slugs
      }
    }

    siblings: allMdx(
      filter: { fields: { parentPath: { eq: $parentPath } } }
      sort: { fields: [frontmatter___priority, frontmatter___title] }
    ) {
      nodes {
        fields {
          path
        }
        frontmatter {
          title
          sidebar
        }
      }
    }
  }
`

export default ContentTemplate
