import { createI18njs } from '@procore/core-i18n-js'
import * as CoreIcons from '@procore/core-icons/dist'
import * as CoreReact from '@procore/core-react'
import { useLocation } from '@reach/router'
import * as DateFns from 'date-fns'
import * as R from 'ramda'
import * as React from 'react'
import styled from 'styled-components'
import { ulid } from 'ulid'
import * as yup from 'yup'
import { PrivateContent } from '../partials/Auth'
import { isIE11 } from '../utils/browser'
import { generateThumbnails } from '../utils/generators/generateThumbnails'
import { layout } from '../utils/styles'
import { wait } from '../utils/wait'
import { Code } from './Code'
import {
  CollapsibleSection,
  CollapsibleSectionItem,
} from './ContentComponents/CollapsibleSection'
import { JSSColorTable } from './ContentComponents/ColorTable/ColorTable'
import {
  DateTimeLocaleCalendars,
  DateTimeLocaleDateSelects,
  DateTimeLocaleFormats,
  DateTimeLocaleFormatsTable,
} from './ContentComponents/DateTimeLocaleFormats'
import { Deprecations } from './ContentComponents/Deprecations'
import {
  FormDebugger,
  FormViewController,
  useFormViewControllerState,
} from './ContentComponents/FormDebugger'
import IpsumContent from './ContentComponents/IpsumContent'
import * as MarkdownComponents from './ContentComponents/MDX'
import NoteBanner from './ContentComponents/NoteBanner'
import { LabPackage, Package } from './ContentComponents/Package'
import RichContent from './ContentComponents/RichContent'
import { SpacingTable } from './ContentComponents/SpacingTable'
import { generateAvatars } from './mocks/AvatarStack/generateAvatars'
import {
  DOCUMENT_TREE_SAMPLE,
  FILES_SAMPLE,
  FORMS_SAMPLE,
  REMOTE_FORMS_SAMPLE,
} from './mocks/FileExplorer'
import { EXAMPLE_IMAGES } from './mocks/Thumbnail'
import * as TILE_SAMPLES from './mocks/Tile'
import { VIRTUALIZATION_SAMPLE } from './mocks/Tree'
import PropsTable from './PropsTable'

const { MDXProvider } = require('@mdx-js/react')
const { MDXRenderer } = require('gatsby-plugin-mdx')

interface ContentBodyProps {
  children: React.ReactNode
  scope?: any
}

const i18n = createI18njs({
  locale: 'es',
  translations: {
    en: {
      hello: 'hello',
      world: 'world',
      nested: {
        translation: {
          goodbye: 'goodbye',
        },
      },
    },
    es: {
      hello: 'hola',
      world: 'mundo',
      nested: {
        translation: {
          goodbye: 'adios',
        },
      },
    },
  },
})

const jsxComponents = {
  Deprecations,
  JSSColorTable,
  SpacingTable,
  PropsTable,
  NoteBanner,
  CollapsibleSection,
  CollapsibleSectionItem,
  DateTimeLocaleCalendars,
  DateTimeLocaleDateSelects,
  DateTimeLocaleFormats,
  DateTimeLocaleFormatsTable,
  Package,
  LabPackage,
  PrivateContent,
}

const ContentBodyView = styled.div`
  flex: 1 1 auto;
  max-width: 80%;
  padding: ${layout.pagePadding}px;

  ${!isIE11() &&
  `
    &:only-child {
      max-width: 100%;
    }
  `}

  [aria-label*='permalink'] {
    display: none;
    left: 0;
    position: absolute;
    top: 8px;

    svg {
      height: 16px;
      vertical-align: middle;
      width: 16px;
    }
  }

  strong {
    font-weight: bold;
  }
`

export function ContentBody({ children }: ContentBodyProps) {
  const location = useLocation()
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? ''
      : `/${location.pathname.split('/')[1]}`

  const codeComponents = Code({
    ...CoreIcons,
    ...CoreReact,
    DateFns,
    Example: CoreReact.Box,
    Examples: CoreReact.FlexList,
    SuperSelect: CoreReact.UNSAFE_SuperSelect,
    FormDebugger,
    FormViewController,
    baseUrl,
    i18n,
    IpsumContent,
    generateThumbnails,
    R,
    RichContent,
    useFormViewControllerState,
    ulid,
    wait,
    yup,
    DATA_SAMPLES: {
      THUMBNAIL: {
        EXAMPLE_IMAGES,
      },
      AVATAR_STACK: {
        generateAvatars,
      },
      FILE_EXPLORER: {
        DOCUMENT_TREE_SAMPLE,
        FILES_SAMPLE,
        FORMS_SAMPLE,
        REMOTE_FORMS_SAMPLE,
      },
      TILE: {
        TILE_SAMPLES,
      },
      TREE: {
        VIRTUALIZATION_SAMPLE,
      },
    },
  })

  const components = {
    ...MarkdownComponents,
    ...jsxComponents,
    code: codeComponents,
  }

  return (
    <ContentBodyView className="markdown-body">
      <MDXProvider components={components}>
        <MDXRenderer key={new Date().getTime()}>{children}</MDXRenderer>
      </MDXProvider>
    </ContentBodyView>
  )
}
