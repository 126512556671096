import React from 'react'

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export type Scope =
  | Window
  | React.RefObject<HTMLElement>
  | React.RefObject<HTMLElement>[]

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface Config {
  /**
   * @since 10.19.0
   */
  dependencies?: any[]
  /**
   * @since 10.19.0
   */
  event: string
  /**
   * @since 10.19.0
   */
  handler: React.EventHandler<any>
  /**
   * @since 10.19.0
   */
  options?: AddEventListenerOptions
  /**
   * @since 10.19.0
   */
  scope?: Scope
  /**
   * @since 10.19.0
   */
  noopHandler?: boolean
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface EventCallbackConfig {
  /**
   * @since 10.19.0
   */
  handler: React.EventHandler<any>
  /**
   * @since 10.19.0
   */
  dependencies?: Array<any>
}

// TODO this is kind of hacky and just to satisfy gatsby. There are other usages of
// window in the library which don't trigger this error, so we should probably figure
// out what's going on here
function getWindow() {
  if (typeof window !== 'undefined') {
    return window
  }
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useLayoutEventListener({
  dependencies = [],
  event,
  handler,
  options = {},
  scope,
  noopHandler = false,
}: Config) {
  if (!event) {
    console.warn(`"event" option missing, no listeners added`)
  } else if (!handler) {
    console.warn(`"handler" option missing, no listeners added`)
  }

  const actualScope =
    scope === getWindow() || !scope
      ? { current: getWindow() }
      : (scope as React.RefObject<HTMLElement>) || {}

  const listener = useEventCallback({ handler, dependencies })

  React.useLayoutEffect(() => {
    if (noopHandler) {
      return
    }

    const { current } = actualScope

    if (current) {
      current.addEventListener(event, listener, options)

      return () => current.removeEventListener(event, listener, options)
    }
  }, [noopHandler, event, listener, actualScope.current])
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useEventListener({
  dependencies = [],
  event,
  handler,
  options = {},
  scope,
  noopHandler = false,
}: Config) {
  if (!event) {
    console.warn(`"event" option missing, no listeners added`)
  } else if (!handler) {
    console.warn(`"handler" option missing, no listeners added`)
  }

  let actualScope: {
    current?: Window | HTMLElement | null
  }

  if (scope && !('current' in scope) && !Array.isArray(scope)) {
    actualScope = { current: scope }
  } else if (!scope) {
    actualScope = { current: getWindow() }
  } else {
    actualScope = (scope as React.RefObject<HTMLElement>) || {}
  }

  const listener = useEventCallback({ handler, dependencies })

  React.useEffect(() => {
    if (noopHandler) {
      return
    }

    const { current } = actualScope

    if (current) {
      current.addEventListener(event, listener, options)

      return () => current.removeEventListener(event, listener, options)
    }
  }, [noopHandler, event, listener, actualScope.current])
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useEventCallback({
  handler,
  dependencies = [],
}: EventCallbackConfig): React.EventHandler<any> {
  const ref = React.useRef(handler)

  React.useLayoutEffect(() => {
    ref.current = handler
  }, [handler, ...dependencies])

  return React.useCallback((event) => ref.current(event), [])
}
