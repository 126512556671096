function generateRandomString(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  let result = ''

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

interface TreeNode {
  id: string | number
  name: string
  parentId: string | number
  type: 'leaf' | 'branch'
}

const root: TreeNode = {
  id: 1,
  name: 'Root Folder',
  parentId: 'ROOT_ID',
  type: 'branch',
}

export function createMockData(amountOfNodes: number): TreeNode[] {
  const ids = [root.id]
  const mockData = [root]
  for (let i = 0, id = 2; i < amountOfNodes; i++, id++) {
    mockData.push({
      id,
      name: generateRandomString(5),
      parentId: ids[Math.floor(Math.random() * ids.length)],
      type: 'branch',
    })
    ids.push(id)
  }
  const foldersIds = mockData.map((item) => item.parentId)
  const uniqueFolders = new Set(foldersIds)

  return mockData.map((item) =>
    uniqueFolders.has(item.id) ? item : { ...item, type: 'leaf' }
  )
}

export const VIRTUALIZATION_SAMPLE = createMockData(300).map((item) =>
  item.parentId === 'ROOT_ID'
    ? item
    : {
        ...item,
        parentId: 1,
        file: true,
      }
)
