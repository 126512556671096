import type { SuperSelectConfig, SuperSelectPreset } from '../SuperSelect.types'
import { contactsPreset } from './contactsPreset'
import { filterPreset } from './filterPreset'
import { pillPreset } from './pillPreset'

export const presets: Record<SuperSelectPreset, SuperSelectConfig> = {
  '': {},
  contacts: contactsPreset,
  filter: filterPreset,
  pill: pillPreset,
}
