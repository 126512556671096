import { H2, LinkButton, Tile, Title, Typography } from '@procore/core-react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

export interface Deprecation {
  interface: string
  property: string
  message: string
  deprecatedSince: string
  deprecationKind: 'component' | 'prop'
}

const StyledDeprecation = styled.div`
  margin-bottom: 48px;

  pre {
    margin: 12px 0;
  }
`

export function Deprecations({
  deprecations,
}: {
  deprecations: Deprecation[]
}) {
  return (
    <div>
      {deprecations.map((d) => {
        const id = `${d.interface}-${d.property}`
        const isComponent = d.deprecationKind === 'component'
        return (
          <StyledDeprecation>
            <Tile orientation="landscape">
              <Tile.Content>
                <Title>
                  <Title.Text id={id}>
                    <H2>
                      {isComponent ? 'Component' : 'Interface'}: {d.interface}
                      {!isComponent && (
                        <Typography intent="h3" as="div">
                          Property: {d.property}
                        </Typography>
                      )}
                    </H2>
                  </Title.Text>
                  {d.deprecatedSince && (
                    <Title.Subtext>
                      Deprecated Since: {d.deprecatedSince}
                    </Title.Subtext>
                  )}
                  <Title.Actions>
                    <LinkButton variant="tertiary" href={`#${id}`}>
                      #
                    </LinkButton>
                  </Title.Actions>
                </Title>
                <ReactMarkdown>{d.message}</ReactMarkdown>
              </Tile.Content>
            </Tile>
          </StyledDeprecation>
        )
      })}
    </div>
  )
}
