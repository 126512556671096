import thumbnailLg1 from '../../images/thumbnail/thumb_lg_01.png'
import thumbnailLg2 from '../../images/thumbnail/thumb_lg_02.png'
import thumbnailLg3 from '../../images/thumbnail/thumb_lg_03.png'
import thumbnailLg4 from '../../images/thumbnail/thumb_lg_04.png'
import thumbnailSm1 from '../../images/thumbnail/thumb_sm_01.png'
import thumbnailSm2 from '../../images/thumbnail/thumb_sm_02.png'
import thumbnailSm3 from '../../images/thumbnail/thumb_sm_03.png'
import thumbnailSm4 from '../../images/thumbnail/thumb_sm_04.png'

const imagesBySize = {
  sm: [thumbnailSm1, thumbnailSm2, thumbnailSm3, thumbnailSm4],
  lg: [thumbnailLg1, thumbnailLg2, thumbnailLg3, thumbnailLg4],
}

export const generateThumbnails = (size: 'sm' | 'lg', length: number = 10) => {
  const thumbnailImages = imagesBySize[size]
  const images = [
    thumbnailImages[0],
    thumbnailImages[1],
    '',
    thumbnailImages[2],
    '',
    thumbnailImages[3],
  ]
  const extensions = ['psd', 'zip', 'rtf', 'csv', 'pdf', 'jpeg', 'ppt', '']

  const files = Array.from({ length }, (_, idx) => {
    const ext = extensions[idx % extensions.length]
    const name = `file_${idx}.${ext}`
    const src = images[idx % images.length]
    return {
      id: idx,
      name,
      src,
    }
  })
  return files
}
