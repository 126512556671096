import styled, { css } from 'styled-components'
import { getBorderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { focusable } from '../_styles/mixins'
import type { LinkProps } from './Link.types'

function getLinkColors({ variant = 'default' }) {
  switch (variant) {
    case 'gray':
      return css`
        color: ${colors.gray30};

        &:hover {
          color: ${colors.orange50};
        }
      `
    case 'white':
      return css`
        color: ${colors.white};

        &:hover {
          color: ${colors.gray85};
        }
      `
    case 'default':
    default:
      return css`
        color: ${colors.blue45};

        &:hover {
          color: ${colors.blue30};
        }
      `
  }
}

export const StyledLink = styled.a<LinkProps>`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${focusable}

  // the focusable mixin applies the class and pseudo selector
  &.focus,
  &:focus {
    ${getBorderRadius('md')};
  }

  ${getLinkColors}
`
