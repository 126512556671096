import styled, { css } from 'styled-components'
import { Typography } from '../Typography/Typography'
import { colors } from '../_styles/colors'

const TRACK_HEIGHT = 4
const THUMB_HEIGHT = 14

function applyStylesOnlyToFF(styles: string) {
  return css`
    @supports (-moz-appearance: none) {
      ${styles}
    }
  `
}

const getThumbStyles = css<{ isDragging: boolean }>`
  box-shadow: 0px 1px 4px rgba(40, 40, 40, 0.2), 0px 0px 3px rgba(0, 0, 0, 0.14),
    0px 2px 3px rgba(0, 0, 0, 0.12);
  height: ${THUMB_HEIGHT}px;
  width: ${THUMB_HEIGHT}px;
  border-radius: 15px;
  background: ${colors.white};
  border: 2px solid ${colors.blue50};
  z-index: 3;
  ${(props) => (props.isDragging ? `cursor: grabbing` : `cursor: pointer`)};
`

const getTrackStyles = css<{ isDragging: boolean }>`
  width: 100%;
  height: ${TRACK_HEIGHT}px;
  animate: 0.2s;
  border-radius: 1px;
  border: 0px solid ${colors.black};
  z-index: 1;
  ${(props) => (props.isDragging ? `cursor: grabbing` : `cursor: pointer`)};
`

const getTrackBackground = (percent: number): string =>
  `background: linear-gradient(to right, ${colors.blue50} 0%, ${colors.blue50} ${percent}%, ${colors.gray70} ${percent}%, ${colors.gray70} 100%);`

const getTrackBackgroundDisabled = (percent: number): string =>
  `background: linear-gradient(to right, ${colors.blue85} 0%, ${colors.blue85} ${percent}%, ${colors.gray90} ${percent}%, ${colors.gray90} 100%);`

const getThumbDisabledStyles = css`
  cursor: not-allowed;
  border: 2px solid ${colors.blue85};
`

export const StyledContainer = styled.div<{ showLabel?: boolean }>`
  position: relative;
  ${(props) => props.showLabel && 'padding-bottom: 10px'};
`

export const StyledSlider = styled.input<{
  $percent: number
  ref: any
  isDragging: boolean
}>`
  -webkit-appearance: none;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  border: 1px solid transparent;
  background-color: transparent;
  font-size: 12px;

  &:focus {
    outline: none;
    ${(props) =>
      props.isDragging
        ? `border: 1px solid transparent`
        : `border: 1px solid ${colors.blue40}`};
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 2px;
  }

  &::-webkit-slider-runnable-track {
    ${getTrackStyles}
    ${(props) => getTrackBackground(props.$percent)}
  }
  &:disabled::-webkit-slider-runnable-track {
    cursor: not-allowed;
    ${(props) => getTrackBackgroundDisabled(props.$percent)}
  }

  &::-webkit-slider-thumb {
    ${getThumbStyles}
    -webkit-appearance: none;
    margin-top: -5px;
  }
  &:disabled::-webkit-slider-thumb {
    ${getThumbDisabledStyles}
  }

  &::-moz-range-track {
    ${getTrackStyles}
    ${(props) => getTrackBackground(props.$percent)}
  }
  &:disabled::-moz-range-track {
    cursor: not-allowed;
    ${(props) => getTrackBackgroundDisabled(props.$percent)}
  }

  &::-moz-range-thumb {
    ${getThumbStyles}
    height: 12px;
    width: 12px;
  }
  &:disabled::-moz-range-thumb {
    ${getThumbDisabledStyles}
  }

  &::-ms-track {
    ${getTrackStyles}
    height: ${THUMB_HEIGHT + 2}px;
    color: transparent;
    ${(props) => getTrackBackground(props.$percent)}
  }
  &:disabled::-ms-track {
    cursor: not-allowed;
    ${(props) => getTrackBackgroundDisabled(props.$percent)}
  }

  &::-ms-fill-lower {
    background: ${colors.blue50};
    border: 0px solid ${colors.black};
    border-radius: 2px;
    box-shadow: 1px 1px 1px ${colors.black};
  }

  &::-ms-fill-upper {
    background: ${colors.gray70};
    border: 0px solid ${colors.black};
    border-radius: 2px;
    box-shadow: 1px 1px 1px ${colors.black};
  }

  &::-ms-thumb {
    ${getThumbStyles}
  }
  &:disabled::-ms-thumb {
    ${getThumbDisabledStyles}
  }

  &:focus::-ms-fill-lower {
    background: ${colors.blue50};
  }

  &:focus::-ms-fill-upper {
    background: ${colors.gray70};
  }
`

export const StyledIncrementOverlay = styled.div`
  position: relative;
  width: 0;
  &::before {
    content: '';
    height: ${TRACK_HEIGHT}px;
    width: 2px;
    background-color: white;
    display: block;
  }
  z-index: 2;
`

export const StyledIncrementContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 6px;
  ${applyStylesOnlyToFF(`
    top: 12px;
  `)}
  left: 5px;
  right: 5px;

  > :first-child {
    visibility: hidden;
  }

  > :last-child {
    visibility: hidden;
  }
`

export const StyledLabel = styled(Typography)`
  visibility: visible;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
`
