import { Error } from '@procore/core-icons/dist'
import React from 'react'
import { Spinner } from '../../Spinner/Spinner'
import { Tooltip } from '../../Tooltip/Tooltip'
import { OverflowObserver } from '../../_hooks/OverflowObserver'
import type { DivAttributes, Props } from '../../_utils/types'
import { StyledIcon, StyledLabel, StyledSourceItem } from './SourceItem.styles'

export interface SourceItemProps extends Props {
  /**
   * @since 10.19.0
   */
  icon?: React.ReactNode
  /**
   * @since 10.19.0
   */
  count?: number
  /**
   * @since 10.19.0
   */
  countTruncationThreshold?: number
  /**
   * @since 10.19.0
   */
  loading?: boolean
  /**
   * @since 10.19.0
   */
  error?: boolean
  /**
   * @since 10.19.0
   */
  selected?: boolean
  /**
   * @since 10.19.0
   */
  disabled?: boolean
  /**
   * @since 10.19.0
   */
  focused?: boolean
}

function formatCount(count: number, truncationThreshold: number): string {
  if (count === 0) {
    return ''
  } else if (count > truncationThreshold) {
    return `(${truncationThreshold}+)`
  } else {
    return `(${count})`
  }
}

export const SourceItem = React.forwardRef<
  HTMLDivElement,
  DivAttributes & SourceItemProps
>(function SourceItem(
  {
    icon = '',
    count = 0,
    countTruncationThreshold = 99,
    loading = false,
    error = false,
    selected = false,
    disabled = false,
    focused = false,
    children,
    tabIndex = 0,
    ...props
  },
  ref
) {
  const tabIndexAttribute = !disabled ? { tabIndex } : {}

  const iconElement = (
    <StyledIcon>
      {(() => {
        if (error) {
          return <Error />
        }

        if (loading) {
          return <Spinner size="xs" />
        }

        return icon
      })()}
    </StyledIcon>
  )

  const labelElement = (
    <OverflowObserver>
      {({ isOverflowing, ref }: any) => {
        const item = <StyledLabel ref={ref}>{children}</StyledLabel>

        return isOverflowing ? (
          <Tooltip
            showDelay={500}
            trigger="hover"
            overlay={<Tooltip.Content>{children}</Tooltip.Content>}
          >
            {item}
          </Tooltip>
        ) : (
          item
        )
      }}
    </OverflowObserver>
  )

  const countElement = (
    <div>&nbsp;{formatCount(count, countTruncationThreshold)}</div>
  )

  return (
    <StyledSourceItem
      ref={ref}
      {...tabIndexAttribute}
      {...props}
      error={error}
      selected={selected}
      disabled={disabled}
      focused={focused}
    >
      {iconElement}
      {labelElement}
      {countElement}
    </StyledSourceItem>
  )
})
