import styled from 'styled-components'
import { getSmallIconBodyTextRules, StyledP } from '../Semantic/Semantic.styles'

export const StyledField = styled.div`
  > ${StyledP} {
    ${getSmallIconBodyTextRules}
  }
`

export const StyledFieldHeader = styled.div`
  ${getSmallIconBodyTextRules}
  > ${StyledP} {
    ${getSmallIconBodyTextRules}
  }
`

export const StyledFieldFooter = styled.div`
  > ${StyledP} {
    ${getSmallIconBodyTextRules}
  }
`
