import styled from 'styled-components'

export const StyledPortal = styled.div`
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;

  > * {
    pointer-events: all;
  }
`
