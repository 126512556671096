import styled, { css } from 'styled-components'
import {
  StyledSelectArrow,
  StyledSelectArrowContainer,
  StyledSelectButton,
  StyledSelectClearIcon,
  StyledSelectMenu,
} from '../Select/Select.styles'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const StyledMultiSelectValues = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
  margin-left: ${spacing.md}px;
  margin-right: ${spacing.xs}px;
  margin-top: 2px;
  min-width: 0;
  width: 100%;
`

export const StyledMultiSelectToken = styled.div`
  margin-bottom: 2px;
  margin-right: ${spacing.xs}px;
  margin-top: 2px;
  flex: 0 1 auto;

  > div {
    max-width: 178px;
    width: 100%;
  }
`

export const StyledMultiSelectSearchInput = styled.input<{
  $isNavigatingTokens?: boolean
}>`
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  padding: 0;
  height: 24px;
  width: 100%;

  ${getTypographyIntent('body')}

  ${({ $isNavigatingTokens }) =>
    $isNavigatingTokens &&
    css`
      color: transparent;
    `}

  &::-ms-clear {
    display: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &::placeholder {
    color: ${colors.gray45};
  }
`

export const StyledMultiSelectSearch = styled.div`
  margin-bottom: 2px;
  margin-right: ${spacing.xs}px;
  margin-top: 2px;
  flex: 1;
  max-width: 100%;
  min-width: 24px;
`

export const StyledMultiSelectSearchIcon = styled.div`
  align-items: center;
  align-self: flex-start;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  margin-left: ${spacing.xs}px;
  margin-right: ${spacing.md}px;
  min-width: 40px;
  width: 40px;
`

export const StyledMultiSelectClearIcon = StyledSelectClearIcon

export const StyledMultiSelectArrowContainer = StyledSelectArrowContainer

export const StyledMultiSelectArrow = StyledSelectArrow

export const StyledMultiSelectMenu = StyledSelectMenu

export const StyledMultiSelectButton = styled(StyledSelectButton)<{
  $emptyValue: boolean
}>`
  height: auto;
  padding: 0;
  white-space: normal;

  &:focus-within {
    ${getFocus()}
  }

  ${({ $emptyValue }) =>
    $emptyValue &&
    css`
      ${StyledMultiSelectSearchInput} {
        cursor: pointer;
      }
    `}
`
