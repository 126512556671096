import { useFocusRing } from '@react-aria/focus'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import React from 'react'
import type { InputAttributes } from '../_utils/types'
import {
  StyledSwitchContainer,
  StyledSwitchLabel,
  StyledToggle,
  StyledTrack,
} from './Switch.styles'
import type { SwitchProps } from './Switch.types'

/**

 We use switches to allow users to choose between two mutually exclusive choices
 in the same line. This is often associated with enabling vs. disabling.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-switch--demo)

 @see [Design Guidelines](https://design.procore.com/switch)

 */
export const Switch = React.forwardRef<
  HTMLInputElement,
  InputAttributes & SwitchProps
>(function Switch({ children, ...props }, ref) {
  const { isFocusVisible, focusProps } = useFocusRing()

  return (
    <StyledSwitchContainer
      $focused={isFocusVisible}
      $disabled={props.disabled}
      $checked={props.checked}
    >
      <VisuallyHidden>
        <input
          ref={ref}
          {...props}
          {...focusProps}
          aria-checked={props.checked}
          role="switch"
          type="checkbox"
        />
      </VisuallyHidden>
      <StyledTrack>
        <StyledToggle />
      </StyledTrack>
      {children && <StyledSwitchLabel>{children}</StyledSwitchLabel>}
    </StyledSwitchContainer>
  )
})

Switch.displayName = 'Switch'
