import React from 'react'
import type { OverlayTriggerProps } from '../OverlayTrigger'
import { OverlayTrigger } from '../OverlayTrigger'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { StyledContainer, StyledTooltip } from './Tooltip.styles'
import type { TooltipContentProps, TooltipProps } from './Tooltip.types'

const Content = React.forwardRef<HTMLSpanElement, TooltipContentProps>(
  function Content({ children, ...props }, ref) {
    return (
      <StyledContainer ref={ref}>
        <StyledTooltip {...props}>{children}</StyledTooltip>
      </StyledContainer>
    )
  }
)

const Tooltip_ = React.forwardRef<
  HTMLElement,
  OverlayTriggerProps & TooltipProps
>(function Tooltip(
  {
    children,
    initialIsVisible = false,
    overlay,
    placement = 'top',
    trigger = 'hover',
    ...props
  },
  ref
) {
  const overlayNode = React.isValidElement(overlay) ? (
    React.cloneElement(overlay as React.ReactElement, { placement })
  ) : (
    <Content placement={placement}>{overlay}</Content>
  )

  return (
    <OverlayTrigger
      {...{
        ...props,
        initialIsVisible,
        overlay: overlayNode,
        placement,
        ref,
        restoreFocusOnHide: false,
        trigger,
        shrinkOverlay: true,
        arrow: true,
      }}
    >
      {children}
    </OverlayTrigger>
  )
})

Tooltip_.displayName = 'Tooltip'

Content.displayName = 'Tooltip.Content'

/**

 We use tooltips to display additional descriptive information about an element
 or item to the user. For example, we could use them to explain a specific term
 or phrase, to denote the specific format required (e.g. mm/dd/yyy), or to
 clarify certain information (e.g. why a button is disabled).

 They can be displayed either by hovering over an element in the product or by
 hovering over an icon next to an element.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-tooltip--demo)

 @see [Design Guidelines](https://design.procore.com/tooltip)

 */
export const Tooltip = addSubcomponents(
  {
    Content,
  },
  Tooltip_
)
