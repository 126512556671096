import React from 'react'
import { useI18nContext } from '../_hooks/I18n'
import type { Props, SVGAttributes } from '../_utils/types'
import { StyledPill, StyledRemove } from './Pill.styles'
import type { PillColor, PillProps } from './Pill.types'

type SVGAttributesWithPillColor = Omit<SVGAttributes, 'color'> & {
  color: PillColor
}

const Remove = React.forwardRef<
  SVGSVGElement,
  SVGAttributesWithPillColor & Props
>(function TokenRemove({ color = 'gray', ...props }, ref) {
  const i18n = useI18nContext()
  return (
    <StyledRemove
      ref={ref}
      aria-hidden="false"
      aria-label={i18n.t('core.token.remove')}
      size="sm"
      $color={color}
      {...props}
    />
  )
})

/**

 Pills are used to highlight important information in a list or form such as
 a status.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-pill--demo)

 @see [Design Guidelines](https://design.procore.com/pill)

 */
export const Pill = React.forwardRef<HTMLSpanElement, PillProps>(function Pill(
  { color = 'gray', onClear, ...props },
  ref
) {
  return (
    <StyledPill $color={color} {...props} ref={ref}>
      {props.children}
      {onClear && <Remove color={color} onClick={onClear} tabIndex={0} />}
    </StyledPill>
  )
})
