import styled, { css } from 'styled-components'
import { spacing } from '../_styles/spacing'
import {
  breakpointMinWidthValues,
  breakpointsSmallToLarge,
} from './Grid.constants'
import type { GridBreakpoint, GridColProps, GridProps } from './Grid.types'

const gridColumns = 12

const getIsColStackActive = (
  targetBreakpoint: GridBreakpoint,
  colStackBreakpoint: GridBreakpoint
) => {
  const targetBrkIndex = breakpointsSmallToLarge.indexOf(targetBreakpoint)
  const stackBrkIndex = breakpointsSmallToLarge.indexOf(colStackBreakpoint)

  return targetBrkIndex <= stackBrkIndex
}

const getSpanCSS = (
  span: GridColProps['colWidth'],
  targetBreakpoint: GridBreakpoint,
  colStackBreakpoint: GridBreakpoint
) => {
  if (getIsColStackActive(targetBreakpoint, colStackBreakpoint)) {
    return css`
      width: 100%;
    `
  }

  const spanValue = typeof span !== 'object' ? span : span[targetBreakpoint]

  if (typeof spanValue === 'undefined') {
    return ''
  }

  if (spanValue === 'auto') {
    return css`
      flex: 1 1 0%;
      max-width: 100%;
    `
  }

  return css`
    flex: 0 0 auto;
    width: ${(spanValue / gridColumns) * 100}%;
  `
}

const getOffsetCSS = (
  $offset: GridColProps['colStart'],
  targetBreakpoint: GridBreakpoint,
  colStackBreakpoint: GridBreakpoint
) => {
  if (getIsColStackActive(targetBreakpoint, colStackBreakpoint)) {
    return ''
  }

  const offsetValue =
    typeof $offset === 'object' ? $offset[targetBreakpoint] : $offset

  if (typeof offsetValue === 'undefined' || offsetValue >= gridColumns) {
    return ''
  }

  const ratio = offsetValue / gridColumns

  if (ratio === 0) {
    return css`
      margin-left: 0;
    `
  }

  return css`
    margin-left: ${ratio * 100}%;
  `
}

const getColGutterHorizontal = (
  gutterX: GridProps['gutterX'],
  breakpoint: GridBreakpoint
) => {
  const gutterKey = typeof gutterX === 'object' ? gutterX[breakpoint] : gutterX

  if (typeof gutterKey === 'undefined') {
    return ''
  }

  const gutterCSS = css`
    padding-right: ${spacing[gutterKey] / 2}px;
    padding-left: ${spacing[gutterKey] / 2}px;
  `

  return gutterCSS
}

const getColGutterVertical = (
  gutterX: GridProps['gutterX'],
  breakpoint: GridBreakpoint
) => {
  const gutterKey = typeof gutterX === 'object' ? gutterX[breakpoint] : gutterX

  if (typeof gutterKey === 'undefined') {
    return ''
  }

  const gutterCSS = css`
    margin-top: ${spacing[gutterKey]}px;
  `

  return gutterCSS
}

const getRowGutterHorizontal = (
  gutterX: GridProps['gutterX'],
  breakpoint: GridBreakpoint
) => {
  const gutterKey = typeof gutterX === 'object' ? gutterX[breakpoint] : gutterX

  if (typeof gutterKey === 'undefined') {
    return ''
  }

  const gutterCSS = css`
    margin-right: ${spacing[gutterKey] / -2}px;
    margin-left: ${spacing[gutterKey] / -2}px;
  `

  if (breakpoint === breakpointsSmallToLarge[0]) {
    return gutterCSS
  }

  return css`
    @media (min-width: ${breakpointMinWidthValues[breakpoint]}) {
      ${gutterCSS}
    }
  `
}

const getRowGutterVertical = (
  gutterY: GridProps['gutterY'],
  breakpoint: GridBreakpoint
) => {
  const gutterKey = typeof gutterY === 'object' ? gutterY[breakpoint] : gutterY

  if (typeof gutterKey === 'undefined') {
    return ''
  }

  const gutterCSS = css`
    margin-top: ${spacing[gutterKey] * -1}px;
  `

  if (breakpoint === breakpointsSmallToLarge[0]) {
    return gutterCSS
  }

  return css`
    @media (min-width: ${breakpointMinWidthValues[breakpoint]}) {
      ${gutterCSS}
    }
  `
}

export const StyledGridRow = styled.div<{
  $gutterX: GridProps['gutterX']
  $gutterY: GridProps['gutterY']
}>`
  ${({ $gutterX, $gutterY }) => {
    return breakpointsSmallToLarge.map((breakpoint) => {
      const gutterXCSS = getRowGutterHorizontal($gutterX, breakpoint)
      const gutterYCSS = getRowGutterVertical($gutterY, breakpoint)
      return css`
        ${gutterXCSS}

        // only adjust margins on first rows
        &:first-child {
          ${gutterYCSS}
        }
      `
    })
  }}

  display: flex;
  flex-wrap: wrap;
`

export const StyledGridCol = styled.div<{
  $offset?: GridColProps['colStart']
  $span?: GridColProps['colWidth']
  $gutterX: GridProps['gutterX']
  $gutterY: GridProps['gutterY']
  $colStackBreakpoint: GridBreakpoint
}>`
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;

  ${({ $span, $offset, $gutterX, $gutterY, $colStackBreakpoint }) => {
    return breakpointsSmallToLarge.map((breakpoint) => {
      const spanCSS = getSpanCSS($span, breakpoint, $colStackBreakpoint)
      const offsetCSS = getOffsetCSS($offset, breakpoint, $colStackBreakpoint)
      const gutterXCSS = getColGutterHorizontal($gutterX, breakpoint)
      const gutterYCSS = getColGutterVertical($gutterY, breakpoint)

      const resultCSS = css`
        ${offsetCSS}
        ${gutterXCSS}
        ${gutterYCSS}
        ${spanCSS}
      `

      if (breakpoint === breakpointsSmallToLarge[0]) {
        return resultCSS
      }

      return css`
        @media (min-width: ${breakpointMinWidthValues[breakpoint]}) {
          ${resultCSS}
        }
      `
    })
  }}
`
