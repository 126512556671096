import { colors } from '@procore/core-react'
import React from 'react'
import {
  StyledColorTable,
  StyledGroupNameTh,
  StyledHeaderTh,
  StyledPreviewTd,
  StyledTd,
  StyledValueTd,
} from './ColorTable.styles'

export const JSSColorTable = () => {
  return (
    <StyledColorTable>
      <tbody>
        <tr>
          <StyledGroupNameTh />
          <StyledHeaderTh $snugfit>Name</StyledHeaderTh>
          <StyledHeaderTh $nowrap>Value</StyledHeaderTh>
        </tr>
        {Object.entries(colors).map(([color, hsl]) => {
          return (
            <tr key={color}>
              <StyledPreviewTd style={{ backgroundColor: hsl }} />
              <StyledTd>{color}</StyledTd>
              <StyledValueTd>{hsl}</StyledValueTd>
            </tr>
          )
        })}
      </tbody>
    </StyledColorTable>
  )
}
