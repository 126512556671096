import styled from 'styled-components'
import { Box } from '../Box/Box'
import {
  StyledForm,
  StyledFormFieldBanner,
  StyledFormRow,
} from '../Form/Form.styles'
import { mediaIE11Hack } from '../_styles/media'
import { spacing } from '../_styles/spacing'

export const StyledContent = styled(Box)`
  // any element that isn't first start with this space above
  // less specific than :first-child and one less rule
  > * + *,
  // these tags as components apply a browser reset
  // XComp and Content both have 10 specifity, which will be at mercy
  // of the CSS load order. Make these slighty more specific 11.
  // Keeping general spacing rules here (and less specific) rather than sprinkled everywhere
  > * + h1,
  > * + h2,
  > * + h3,
  > * + h4,
  > * + p,
  > * + ul,
  > * + ol {
    margin-top: ${spacing.lg}px;
  }

  > ${StyledFormRow} + * {
    margin-top: 0;
  }

  > ${StyledFormRow}, > ${StyledForm} > ${StyledFormRow}, > ${StyledFormRow} {
    ${StyledFormFieldBanner} {
      min-height: 0;
      margin-bottom: ${spacing.md}px;
    }
    &:last-child ${StyledFormFieldBanner} {
      margin-bottom: 0;
    }
  }
`

export const StyledActions = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: ${spacing.sm}px;

  // Support horizontal space
  // Does not support vertical space when content wraps
  @media ${mediaIE11Hack} {
    & > * + * {
      margin-left: ${spacing.sm}px;
    }
  }
`
