import { Clear, Search } from '@procore/core-icons/dist'
import React from 'react'
import { Button } from '../Button/Button'
import { useLayoutEventListener } from '../_hooks/EventListener'
import { mergeRefs } from '../_utils/mergeRefs'
import { StyledIcon, StyledInput, StyledTypeahead } from './Typeahead.styles'
import type { TypeaheadInputProps, TypeaheadProps } from './Typeahead.types'

/**
 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-typeahead--demo)

 */
export const Typeahead = React.forwardRef<
  HTMLInputElement,
  TypeaheadInputProps & TypeaheadProps
>(function (
  {
    className,
    disabled,
    onChange: onChange_,
    style,
    value,
    type = 'search',
    onKeyDown: onKeyDown_,
    ...props
  },
  ref
) {
  const onChange = React.useCallback(
    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
      onChange_?.(e.target.value, e)
    },
    [onChange_]
  )

  const innerRef = React.useRef(null)

  useLayoutEventListener({
    dependencies: [onChange_, onKeyDown_],
    event: 'keydown',
    handler: function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === 'Escape' || e.key === 'Esc') {
        // stop propagation once to clear input
        if ((e.target as HTMLInputElement).value) {
          e.stopPropagation()
        }
        onChange_?.('', { ...e, target: { ...e.target, value: '' } })
      }

      onKeyDown_?.(e)
    },
    scope: innerRef,
  })

  const onClickClear = React.useCallback(
    function onClickClear(e: React.MouseEvent<HTMLButtonElement>) {
      if (!disabled) {
        onChange_?.('', { ...e, target: { ...e.target, value: '' } })
      }
    },
    [disabled, onChange_]
  )

  return (
    <StyledTypeahead
      className={className}
      style={style}
      data-qa={props['data-qa']}
    >
      <StyledInput
        {...props}
        type={type}
        data-qa="core-typeahead-input"
        ref={mergeRefs(innerRef, ref)}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      <StyledIcon disabled={disabled}>
        {value ? (
          <Button
            aria-label="Clear the input"
            data-qa="core-typeahead-clear-icon"
            size="sm"
            variant="tertiary"
            icon={<Clear />}
            disabled={disabled}
            onClick={onClickClear}
            tabIndex={-1} // TODO revisit this accessibility
          />
        ) : (
          <Search data-qa="core-typeahead-search-icon" size="sm" />
        )}
      </StyledIcon>
    </StyledTypeahead>
  )
})
