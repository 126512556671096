import React from 'react'
import { isIE11 } from '../_utils/isIE11'

export function useScrollLock(shouldScrollLock: boolean) {
  React.useEffect(() => {
    const IE11 = isIE11()
    const bodyRightPadding = document.body.style.paddingRight
    const bodyMaxWidth = document.body.style.maxWidth
    const scrollBarWidthBefore =
      window.innerWidth - document.documentElement.clientWidth

    if (!IE11 && shouldScrollLock) {
      const bodyPosition = document.body.style.position
      const bodyTop = document.body.style.top
      document.body.style.maxWidth = '100vw'

      if (scrollBarWidthBefore > 0) {
        const scrollbarWidthAfter =
          document.documentElement.clientWidth -
          document.documentElement.offsetWidth
        const scrollbarWidth = scrollBarWidthBefore - scrollbarWidthAfter
        document.body.style.paddingRight = `${scrollbarWidth}px`
      }

      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = 'fixed'

      return () => {
        const scrollY = document.body.style.top
        document.body.style.position = bodyPosition
        document.body.style.top = bodyTop
        document.body.style.paddingRight = bodyRightPadding
        document.body.style.maxWidth = bodyMaxWidth

        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    }
  }, [shouldScrollLock])
}
