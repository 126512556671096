import React from 'react'

import { createIntersectionObserver } from './createIntersectionObserver'

export function useIntersectionObserver<T extends Element = HTMLElement>(
  callback: IntersectionObserverCallback,
  options: IntersectionObserverInit = {}
): (el: T | null | undefined) => void {
  const [el, setEl] = React.useState<T | null | undefined>(null)

  React.useEffect(() => {
    if (!el || !callback) {
      return
    }
    const observer = createIntersectionObserver(callback, options)
    observer.observe(el)
    return () => {
      observer.disconnect()
    }
  }, [el, callback, options])

  return setEl
}
