import React from 'react'
import type { DivAttributes } from './types'

type Ref = React.ForwardedRef<HTMLDivElement>

type Slots = Record<
  string,
  {
    slotChildren: React.ReactNode | null
    slotProps: DivAttributes
    slotRef: Ref | null
  }
>

type ReactComponent =
  | React.FC
  | React.Component
  | React.ForwardRefExoticComponent<HTMLDivElement>

export const slotify = (
  rawChildren: React.ReactNode,
  slotMap: Record<string, ReactComponent>
): Slots => {
  const serializedConfig = Object.entries(slotMap) as [string, ReactComponent][]
  const slots = serializedConfig.reduce((acc, [slotKey]) => {
    acc[slotKey] = {
      slotProps: {},
      slotChildren: null,
      slotRef: null,
    }
    return acc
  }, {} as Slots)

  React.Children.toArray(rawChildren).forEach((child) => {
    const slot = child as React.FunctionComponentElement<any>

    if (!slot || !slot.type) {
      return
    }
    const slotConfig = serializedConfig.find(([, type]) => slot.type === type)

    if (!slotConfig) {
      return
    }

    const [slotKey] = slotConfig

    const { children, ...props } = slot.props
    slots[slotKey] = {
      slotChildren: children,
      slotProps: props,
      slotRef: slot.ref as Ref,
    }
  })

  return slots
}
