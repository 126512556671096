import React from 'react'
import { Tooltip } from '../Tooltip/Tooltip'
import { useOverflowObserver } from '../_hooks/OverflowObserver'
import { parseFilename } from '../_utils/filename'
import type { Props } from '../_utils/types'
import {
  StyledCaptionText,
  StyledMultilineCaption,
  StyledPlaceholderCaption,
  StyledSingleLineCaption,
  StyledSinglelineCaptionBody,
  StyledSinglelineCaptionEnding,
} from './Thumbnail.styles'
import type {
  CaptionProps,
  CaptionTooltipProps,
  MultilineCaptionProps,
  SinglelineCaptionProps,
} from './Thumbnail.types'

function CaptionTooltip({ children, overlay, isVisible }: CaptionTooltipProps) {
  return (
    <Tooltip
      overlay={<Tooltip.Content>{overlay}</Tooltip.Content>}
      placement="bottom"
      trigger={isVisible ? 'hover' : 'none'}
    >
      {children}
    </Tooltip>
  )
}

function MultilineCaption({
  disabled,
  caption,
  error,
  layout,
  qa,
  ...props
}: MultilineCaptionProps & Props) {
  const { ref, isOverflowing } = useOverflowObserver()
  return (
    <CaptionTooltip overlay={caption} isVisible={isOverflowing}>
      {/* additional container is needed because Tooltip overrides the passed `ref` */}
      <span data-qa={qa}>
        <StyledMultilineCaption
          ref={ref}
          disabled={disabled}
          error={error}
          layout={layout}
          {...props}
        >
          <StyledCaptionText>{caption}</StyledCaptionText>
        </StyledMultilineCaption>
      </span>
    </CaptionTooltip>
  )
}

function SinglelineCaption({
  caption,
  error,
  disabled,
  layout,
  qa,
  ...props
}: SinglelineCaptionProps & Props) {
  const { ref, isOverflowing } = useOverflowObserver()
  const { filename, extension, isFilename } = parseFilename(caption)

  return (
    <CaptionTooltip overlay={caption} isVisible={isOverflowing}>
      <StyledSingleLineCaption
        error={error}
        disabled={disabled}
        layout={layout}
        data-qa={qa}
        {...props}
      >
        <StyledSinglelineCaptionBody ref={ref}>
          {isFilename ? `${filename}.` : caption}
        </StyledSinglelineCaptionBody>
        {isFilename && (
          <StyledSinglelineCaptionEnding>
            {extension}
          </StyledSinglelineCaptionEnding>
        )}
      </StyledSingleLineCaption>
    </CaptionTooltip>
  )
}

export function ThumbnailCaption({
  layout,
  disabled = false,
  error = false,
  caption,
  hasCaptionPlaceholder,
  multiline,
  qa = '',
  ...props
}: CaptionProps) {
  if (!caption) {
    const captionPlaceholder = hasCaptionPlaceholder && '- -'

    if (captionPlaceholder) {
      return (
        <StyledPlaceholderCaption
          {...props}
          disabled={disabled}
          error={error}
          layout={layout}
          data-qa={qa}
        >
          <StyledCaptionText>{captionPlaceholder}</StyledCaptionText>
        </StyledPlaceholderCaption>
      )
    }

    return null
  }

  if (multiline) {
    return (
      <MultilineCaption
        disabled={disabled}
        caption={caption}
        error={error}
        layout={layout}
        qa={qa}
        {...props}
      />
    )
  }

  return (
    <SinglelineCaption
      caption={caption}
      disabled={disabled}
      error={error}
      layout={layout}
      qa={qa}
      {...props}
    />
  )
}
