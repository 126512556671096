import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import * as React from 'react'
import type { IntlDateTimeOptionPresets } from '../../_utils/dateTime'
import {
  getPresetDateTimeOptions,
  intlDateTimeFormat,
} from '../../_utils/dateTime'
import { useI18nContext } from '../I18n'

const enableLogs = process.env.TZ_LOG?.toLowerCase() === 'true'

function computerTimeZone() {
  return window?.Intl?.DateTimeFormat?.().resolvedOptions?.().timeZone
}

function consoleMessageFormat({
  log = '',
  result,
  timeZone,
  type,
  value,
}: any) {
  if (enableLogs) {
    console.log(
      `core-react: ${log} useDateTime format ${type}; destintation time zone: ${timeZone}, argument ${value}, result ${result}`
    )
  }
}

function consoleMessageCompareShift({
  log = '',
  result,
  timeZone,
  value,
}: any) {
  if (enableLogs) {
    console.log(
      `core-react: ${log}; destination time zone ${timeZone}. Your time zone ${computerTimeZone()}.`,
      '\nInput:  ',
      value,
      '\nOutput: ',
      result,
      '\nIn ISO: ',
      {
        input: value.toISOString(),
        output: result.toISOString(),
      }
    )
  }
}

function consoleMessageShiftNoShift({ log = '', timeZone, value }: any) {
  if (enableLogs) {
    console.log(
      `core-react: ${log} utcToZonedTime; destination time zone ${timeZone}. No argument, return arugment.\n`,
      value
    )
  }
}

export const dateTimeFormats = {
  'abbr-weekday-abbr-date': 'abbr-weekday-abbr-date', // Fri, Jan 8, 2021
  'abbr-weekday-abbr-date-at-time': 'abbr-weekday-abbr-date-at-time', // Fri, Jan 8, 2021 at 8:00 AM PST
  date: 'date', // January 8, 2021
  'date-at-time': 'date-at-time', // January 8, 2021 at 8:00 AM PST
  'numeric-date': 'numeric-date', // 1/8/2021
  'numeric-date-at-time': 'numeric-date-at-time', // 8:00 AM PST at 1/8/2021
  time: 'time', // 8:00 AM PST
  'time-on-abbr-weekday-abbr-date': 'time-on-abbr-weekday-abbr-date', // 8:00 AM PST on Fri, Jan 8, 2021
  'time-on-weekday-date': 'time-on-weekday-date', // 8:00 AM PST on Friday, January 8, 2021
  'time-on-date': 'time-on-date', // 8:00 AM PST on January 8, 2021
  'time-on-numeric-date': 'time-on-numeric-date', // 8:00 AM PST on January 8, 2021
  'weekday-date': 'weekday-date', // Friday, January 8, 2021
  'weekday-date-at-time': 'weekday-date-at-time', // Friday, January 8, 2021 at 8:00 AM PST
  none: 'none', // browser default 1/8/2021
} as const

export type DateTimeFormatTypes =
  (typeof dateTimeFormats)[keyof typeof dateTimeFormats]

export type DateTimeContextConfig = Pick<
  Intl.DateTimeFormatOptions,
  'hour12' | 'timeZone'
>

const DateTimeContext = React.createContext<DateTimeContextConfig>({})

export type DateTimeProviderProps = {
  children: React.ReactNode
} & DateTimeContextConfig

export function DateTimeProvider({
  children,
  hour12,
  timeZone,
}: DateTimeProviderProps) {
  return (
    <DateTimeContext.Provider value={{ hour12, timeZone }}>
      {children}
    </DateTimeContext.Provider>
  )
}

interface DateTimeAPI {
  format: (
    value: Date,
    type: DateTimeFormatTypes,
    options?: Intl.DateTimeFormatOptions,
    timeOptions?: Intl.DateTimeFormatOptions,
    /** @internal */
    /** For internal development use, logging with env TZ_LOG. Unversioned API */
    UNSAFE_log?: string
  ) => string
  hour12?: boolean
  /** @internal */
  newDate: (...args: Partial<ConstructorParameters<DateConstructor>>) => Date
  /** @internal */
  shiftUtcToZonedTime: <Arg extends Date | null | undefined>(
    value?: Date | null,
    log?: string
  ) => Arg | Date
  /** @internal */
  shiftZonedTimeToUtc: <Arg extends Date | null | undefined>(
    value?: Date | null,
    log?: string
  ) => Arg | Date
  timeZone?: string
}

const emptyCache: { [key: string]: Intl.DateTimeFormat } = {}
export function useDateTime(): DateTimeAPI {
  const I18n = useI18nContext()
  const { hour12, timeZone } = React.useContext(DateTimeContext)

  const [cache, setCache] = React.useState(emptyCache)
  const getDateFormatter = React.useCallback(
    (...args: Parameters<typeof intlDateTimeFormat>) => {
      const key = JSON.stringify(args)
      if (cache[key]) {
        return cache[key]
      }
      const newFormat = intlDateTimeFormat(...args)
      setCache((state) => ({ ...state, [key]: newFormat }))

      return newFormat
    },
    [cache, setCache]
  )

  const format: DateTimeAPI['format'] = React.useCallback(
    (value, type, options, timeOptions, UNSAFE_log) => {
      if (type.startsWith('time-on-')) {
        const time = getDateFormatter(I18n.locale, {
          ...getPresetDateTimeOptions('time'),
          hour12,
          timeZone,
          ...timeOptions,
        }).format(value)

        const date = getDateFormatter(I18n.locale, {
          ...getPresetDateTimeOptions(
            type.replace('time-on-', '') as IntlDateTimeOptionPresets
          ),
          hour12,
          timeZone,
          ...options,
        }).format(value)

        const result = I18n.t('timeOnDate', {
          time,
          date,
          scope: 'core.dateTimeFormat',
        })
        if (UNSAFE_log) {
          consoleMessageFormat({
            log: UNSAFE_log,
            result,
            timeZone,
            type,
            value,
          })
        }
        return result
      }

      if (type.endsWith('-at-time')) {
        const time = getDateFormatter(I18n.locale, {
          ...getPresetDateTimeOptions('time'),
          hour12,
          timeZone,
          ...timeOptions,
        }).format(value)

        const date = getDateFormatter(I18n.locale, {
          ...getPresetDateTimeOptions(
            type.replace('-at-time', '') as IntlDateTimeOptionPresets
          ),
          hour12,
          timeZone,
          ...options,
        }).format(value)

        const result = I18n.t('dateAtTime', {
          time,
          date,
          scope: 'core.dateTimeFormat',
        })

        if (UNSAFE_log) {
          consoleMessageFormat({
            log: UNSAFE_log,
            result,
            timeZone,
            type,
            value,
          })
        }
        return result
      }

      const result = getDateFormatter(I18n.locale, {
        ...getPresetDateTimeOptions(type as IntlDateTimeOptionPresets),
        hour12,
        timeZone,
        ...options,
      }).format(value)

      if (UNSAFE_log) {
        consoleMessageFormat({
          log: UNSAFE_log,
          timeZone,
          type,
          result,
          value,
        })
      }
      return result
    },
    [getDateFormatter, I18n.locale, hour12, timeZone]
  )

  function shiftUtcToZonedTime<Arg extends Date | null | undefined>(
    value?: Date | null,
    log?: string
  ) {
    if (value && timeZone) {
      const result = utcToZonedTime(value, timeZone)
      if (log) {
        consoleMessageCompareShift({
          log: `${log} utcToZonedTime`,
          timeZone,
          result,
          value,
        })
      }
      return result
    }
    if (log) {
      consoleMessageShiftNoShift({
        log: `${log} utcToZonedTime`,
        timeZone,
        value,
      })
    }
    return value as Arg
  }

  function shiftZonedTimeToUtc<Arg extends Date | null | undefined>(
    value?: Date | null,
    log?: string
  ) {
    if (value && timeZone) {
      const result = zonedTimeToUtc(value, timeZone)
      if (log) {
        consoleMessageCompareShift({
          log: `${log} zonedTimeToUtc`,
          timeZone,
          result,
          value,
        })
      }
      return result
    }
    if (log) {
      consoleMessageShiftNoShift({
        log: `${log} zonedTimeToUtc`,
        timeZone,
        value,
      })
    }
    return value as Arg
  }

  const newDate = (...args: Partial<ConstructorParameters<DateConstructor>>) =>
    timeZone
      ? utcToZonedTime(
          new Date(...(args as ConstructorParameters<DateConstructor>)),
          timeZone
        )
      : new Date(...(args as ConstructorParameters<DateConstructor>))

  return {
    format,
    hour12,
    newDate,
    shiftUtcToZonedTime,
    shiftZonedTimeToUtc,
    timeZone,
  }
}
