import { ariaHideOutside } from '@react-aria/overlays'
import { useId, useLabels } from '@react-aria/utils'
import type { AriaAttributes } from 'react'
import React from 'react'
import type { OverlayTriggerRole } from './OverlayTrigger.types'

const emptyObj = {}
const returnEmpty: GetA11yProps = () => ({})

type GetA11yProps = (props: {
  role: OverlayTriggerRole
  isVisible: boolean
  overlayId: string
}) => AriaAttributes & { id?: string }

const a11yPresets: Record<
  'tooltip' | 'haspopup',
  {
    getOverlayProps: GetA11yProps
    getTriggerProps: GetA11yProps
  }
> = {
  haspopup: {
    getOverlayProps: ({ role, overlayId }) => ({
      id: overlayId,
      role,
    }),
    getTriggerProps: ({ role, isVisible, overlayId }) => ({
      'aria-expanded': isVisible,
      'aria-controls': isVisible ? overlayId : undefined,
      'aria-haspopup': role as 'dialog' | 'listbox' | 'menu',
    }),
  },
  tooltip: {
    getOverlayProps: ({ overlayId }) => ({
      role: 'tooltip',
      id: overlayId,
    }),
    getTriggerProps: ({ isVisible, overlayId }) =>
      isVisible
        ? {
            'aria-describedby': overlayId,
          }
        : {},
  },
}

export function getA11yPreset(role?: OverlayTriggerRole) {
  switch (role) {
    case 'dialog':
    case 'listbox':
    case 'menu':
      return a11yPresets.haspopup
    case 'tooltip':
      return a11yPresets.tooltip
    case 'none':
      return {
        getOverlayProps: (() => ({ role: 'none' })) as GetA11yProps,
        getTriggerProps: returnEmpty,
      }
    default:
      return {
        getOverlayProps: returnEmpty,
        getTriggerProps: returnEmpty,
      }
  }
}

type LabelConfig = {
  'aria-describedby'?: string
  'aria-details'?: string
  'aria-labelledby'?: string
  'aria-label'?: string
  /**
   * ID for the widget itself
   */
  id?: string
}

type DialogBase = LabelConfig & {
  isOpen: boolean
  role?: 'dialog'
}

type NonModalDialogConfig = DialogBase & {
  isModal?: never | false
}

type ModalDialogConfig = DialogBase & {
  /**
   *
   * To enable `ariaHideOutside`. Set `isModal` to false to disable.
   * @a11y Replicates `aria-modal` inert outside content
   * @default false
   */
  isModal: true
  ref: React.RefObject<HTMLElement>
}

type ModalDialogLikeConfig = NonModalDialogConfig | ModalDialogConfig

type OverlayTriggerA11yConfig = LabelConfig & {
  /**
   * Assure `aria-hidden` is not applied. A code side-effect
   * from `ariaHideOutside` hiding everthing outside the scope.
   */
  alwaysAriaVisible?: boolean
  /**
   * When properties should be passed to the overlay UI itself,
   * and not the wrapper element.
   */
  canPropOverlayUp: boolean
  isOpen: boolean
  role?: OverlayTriggerRole
  // ref: React.RefObject<HTMLElement> // Only necessary for modal
}

/**
 * Manages labelling for an element and the other DOM. Defaults an ID for `aria-labelledby` usage.
 *
 * When `aria-label` and `aria-labelledby` both exist, it combines them into `aria-labelledby` for a screen reader chain.
 * @link [W3 naming with aria-labelledby](https://www.w3.org/WAI/ARIA/apg/practices/names-and-descriptions/#naming_with_aria-labelledby)
 */
export function useLabelled(props?: LabelConfig) {
  const {
    'aria-describedby': ariaDescribedby,
    'aria-details': ariaDetails,
    'aria-labelledby': ariaLabelledby,
    'aria-label': ariaLabel,
    id,
  } = props || (emptyObj as LabelConfig)
  const ariaLabelOnly = ariaLabel && !ariaLabelledby

  // Generate an ID. We want to use this unless they are using only aria-label
  const labelledId = useId(ariaLabelledby)

  // Merges aria-label and aria-labelledby into aria-labelledby when both exist
  const widgetId = useId(id)
  let fieldProps = useLabels({
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelOnly ? undefined : labelledId,
    id: widgetId,
  })

  return {
    descriptionProps: { id: ariaDescribedby },
    labelProps: { id: ariaLabelOnly ? undefined : labelledId },
    widgetProps: {
      ...fieldProps,
      'aria-describedby': ariaDescribedby,
      'aria-details': ariaDetails,
    },
  }
}

/**
 * Cover the label links for the trigger (button), the popup element (dialog), and the popup element title (heading).
 * Similar to [React Aria useOverlayTrigger](https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/overlays/src/useOverlayTrigger.ts)
 * but with element title support.
 * @link [MDN aria-haspopup](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-haspopup)
 */
export function useLabelledPopup({
  'aria-describedby': ariaDescribedby,
  'aria-details': ariaDetails,
  'aria-labelledby': ariaLabelledby,
  'aria-label': ariaLabel,
  id: id_,
  isOpen,
  type = 'button',
  popupRole,
  popupId: popupId_,
}: LabelConfig & {
  isOpen: boolean
  popupId?: string
  type?: 'button' | 'combobox' // Role? Not used. Show concept that popups manage 2 roles
  popupRole: 'dialog' | 'menu' | 'listbox'
}) {
  /** Web spec default for aria-haspopup=true is menu, unless element has role combobox, which have an implicit aria-haspopup value of listbox. */
  // const popupRole = popupRole_ || type === 'combobox' ? 'listbox' : 'menu'
  const id = useId(id_)
  const popupId = useId(popupId_)

  const presetArgs = {
    isVisible: isOpen,
    role: popupRole,
    overlayId: popupId,
  }

  const triggerProps = getA11yPreset(popupRole).getTriggerProps?.(presetArgs)
  const overlayProps = getA11yPreset(popupRole).getOverlayProps?.(presetArgs)

  const { labelProps, widgetProps } = useLabelled({
    'aria-describedby': ariaDescribedby,
    'aria-details': ariaDetails,
    'aria-labelledby': ariaLabelledby,
    'aria-label': ariaLabel,
    id,
  })

  return {
    labelProps,
    popupProps: { role: popupRole, ...widgetProps, ...overlayProps },
    triggerProps,
  }
}

/**
 * For dialog experiences:
 * - Has role dialog and aria linked title props
 * - Focus management props to work with FocusScope
 *
 * For modal dialog experiences (full screen locked experiences):
 * - Hides old and new content outside with aria-hidden with ariaHideOutside (like aria-modal)
 * - (it will in breaking) Prevent scroll
 *
 * For either experiences, you still MUST support:
 * - have Escape key to close (It should!)
 * - have click outside, like scrim click
 *
 * _More about 'modal dialog' and `isModal` not using `aria-modal`:_
 * Setting aria-modal="true" tells assistive technologies to let the user know the ability to interact with,
 * or access other content on the page requires the modal dialog to be closed or otherwise lose focus.
 *  Modal dialogs are when content is displayed and the user's interaction is limited to only that section until it is dismissed.
 * [MDN aria modal](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-modal).
 * To support the most, like Narrator in Edge, Core React uses `ariaHideOutside` to set `aria-hidden` on
 * all elements outside the dialog, and **all elements outside added while opened**, so the dialog will act as a
 * _modal_ even without `aria-modal` on the dialog itself. See Future Considerations below.
 * [Screen Reader aria modal support table](https://a11ysupport.io/tech/aria/aria-modal_attribute) - [MS Narrator Edge aria modal support](https://a11ysupport.io/tests/apg__modal-dialog-example/aria__aria-modal_attribute/convey_presence/narrator/edge)
 * _Future Considerations:_ Replace ariaHideOutside with aria-modal="true".
 * Using aria-modal, then we would need to involve Portal and so tooltips
 * or Toasts could mount within the visible aria-modal container.
 */

export function useModalDialogLike({
  'aria-describedby': ariaDescribedby,
  'aria-details': ariaDetails,
  'aria-labelledby': ariaLabelledby,
  'aria-label': ariaLabel,
  id,
  isModal,
  isOpen,
  // @ts-expect-error
  ref,
  role = 'dialog',
}: ModalDialogLikeConfig) {
  const { labelProps, widgetProps } = useLabelled({
    'aria-describedby': ariaDescribedby,
    'aria-details': ariaDetails,
    'aria-labelledby': ariaLabelledby,
    'aria-label': ariaLabel,
  })

  const dialogProps = {
    // 'aria-modal': true,
    ...widgetProps,
    id,
    role,
    tabIndex: -1,
  }

  // usePreventScroll({
  //   isDisabled: isModal ? !isOpen : true,
  // })

  // Fills aria-modal=true
  React.useLayoutEffect(() => {
    if (isModal && isOpen && ref.current) {
      // Could add additional visible element refs here
      return ariaHideOutside([ref.current])
    }
  }, [isModal, isOpen, ref])

  return {
    dialogProps,
    labelProps,
    focusScopeProps: {
      autoFocus: true,
      contain: true,
      restoreFocus: true,
    },
  }
}

/**
 * Takes many roles and determines props necessary for DOM/components.
 * **Side effect of role="dialog"**, current and any content added later
 * outside of the element (like by portals) will get `aria-hidden=true`
 * to replace the `aria-modal=true` `inert` nature.
 * @see useModalDialogLike */
export function useOverlayTriggerA11y({
  alwaysAriaVisible,
  'aria-describedby': ariaDescribedby,
  'aria-details': ariaDetails,
  'aria-labelledby': ariaLabelledby,
  'aria-label': ariaLabel,
  // can we remove this? have it role based? be optional?
  canPropOverlayUp,
  id: id_,
  isOpen,
  role,
}: OverlayTriggerA11yConfig) {
  const isDialog = role === 'dialog'

  const id = useId(id_)

  const { getTriggerProps, getOverlayProps } = getA11yPreset(role)

  const triggerProps = getTriggerProps?.({
    isVisible: isOpen,
    role: role!,
    overlayId: id,
  })

  const overlayA11yProps = getOverlayProps?.({
    isVisible: isOpen,
    role: role!,
    overlayId: id,
  })

  const wrapperA11yProps = !canPropOverlayUp ? overlayA11yProps : emptyObj
  const overlayProps = canPropOverlayUp ? overlayA11yProps : emptyObj

  const { dialogProps, labelProps, focusScopeProps } = useModalDialogLike({
    'aria-describedby': ariaDescribedby,
    'aria-details': ariaDetails,
    'aria-labelledby': ariaLabelledby,
    'aria-label': ariaLabel,
    id,
    isModal: false,
    isOpen,
  })

  /**  This is a code side-effect from ariaHideOutside hiding everthing */
  const portalProps =
    role === 'tooltip' || alwaysAriaVisible // || !role
      ? { 'data-react-aria-top-layer': true, 'data-live-announcer': true }
      : emptyObj

  /**
   * If it is a dialog, we can merge the dialog props with any haspopup props
   */
  const wrapperFinalProps = isDialog
    ? {
        ...wrapperA11yProps,
        ...dialogProps,
      }
    : wrapperA11yProps

  /**
   * If it is a dialog, we have opinions on `FocusScope` props.
   */
  const focusScopeFinalProps = isDialog
    ? focusScopeProps
    : (emptyObj as {
        autoFocus?: boolean
        contain?: boolean
        restoreFocus?: boolean
      })

  return {
    focusScopeProps: focusScopeFinalProps,
    labelProps,
    overlayProps,
    portalProps,
    triggerProps,
    wrapperProps: wrapperFinalProps,
  }
}

// WIP Example combobox
// function useCombox({
//   controls,
//   isOpen = false,
// }: {
//   controls: 'dialog' | 'menu' | 'listbox'
//   isOpen: boolean
// }) {
//   const { labelProps, popupProps, triggerProps } = useLabelledPopup({
//     role: controls,
//     type: 'combobox',
//     isOpen,
//   })
// dispatch between elements.
// NOTE combobox has two labels, that could be different
// one for the trigger input (typically the form label)
// second for the thing it controls (listbox, dialog)
// - label id=1
// - input role='combobox' aria-labelledby=1
// - div role={controls} aria-label
// Also, any icon only buttons like clear or open should have a
// label and -1 tabindex. No nested interactive roles.
// }
