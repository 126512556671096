import styled, { css } from 'styled-components'
import { Button } from '../Button/Button'
import { getInputStyles } from '../Input/Input.styles'
import { spacing } from '../_styles/spacing'

// Search has the buttons embedded into the input, So we have to do some manual
// absolute positioning of the clear and search buttons and also adjust the
// padding of the input so that the input text does not run into the buttons.
// This used to be accomplished with IconLayout but that was a rarely used and
// messy abstraction which was also intended to be used with clickable Icons
// and not the new icon button style.

const inputHeight = 36

const buttonSize = 24

const buttonTop = (inputHeight - buttonSize) / 2 // vertically center the buttons

const searchRight = 6 // search icon sits 6px from the right edge

const clearRight = searchRight + buttonSize + spacing.xs // clear icon shifted

const inputRight = clearRight + buttonSize + spacing.sm // padding-right on the input to account for buttons

const legacyTypeahedInputRight = searchRight + buttonSize + spacing.sm // padding-right on the input to account for legacy typeahead prop

export function getSearchInputStyles() {
  return css`
    ${getInputStyles}
    -webkit-appearance: textfield;
    padding-left: ${spacing.md}px;

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  `
}

export const StyledInput = styled.input`
  ${getSearchInputStyles}
`

const StyledButton = styled(Button)`
  position: absolute;
  top: ${buttonTop}px;
`

export const StyledSearchButton = styled(StyledButton)``

export const StyledClearButton = styled(StyledButton)``

export const StyledSearch = styled.div<{
  disabled?: boolean
}>`
  position: relative;
  width: 100%;

  ${StyledInput} {
    padding-right: ${inputRight}px;
  }

  ${StyledSearchButton} {
    right: ${searchRight}px;
  }

  ${StyledClearButton} {
    right: ${clearRight}px;
  }
`
