import { useButton } from '@react-aria/button'
import React from 'react'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { DivAttributes } from '../_utils/types'
import {
  StyledAvatarContainer,
  StyledAvatarOverlay,
  StyledIconContainer,
  StyledLabelContainer,
  StyledPortraitContainer,
} from './Avatar.styles'
import type {
  AvatarIconProps,
  AvatarLabelProps,
  AvatarPortraitProps,
  AvatarProps,
  AvatarRef,
  AvatarRoles,
  AvatarRoleType,
  BaseAvatarProps,
} from './Avatar.types'

function AvatarInner(
  {
    as,
    clickable,
    disabled,
    // @ts-ignore link only
    href,
    size,
    children,
    onBlur,
    onFocus,
    // @ts-ignore link only
    rel,
    // @ts-ignore not on legacy
    role,
    // @ts-ignore link only
    target,
    ...props
  }: AvatarProps<AvatarRoleType>,
  ref: AvatarRef<AvatarRoleType>
) {
  const { onPress } = props as AvatarProps<'button'>

  let { buttonProps } = useButton(
    {
      ...props,
      onBlur: onBlur as (e: React.FocusEvent) => void,
      onFocus: onFocus as (e: React.FocusEvent) => void,
      elementType: (as as any as string) === 'a' ? 'a' : 'div',
      href,
      isDisabled: disabled,
      onPress,
      rel,
      target,
      type: 'button',
    },
    ref as any // TODO fix
  )

  const linkDisabled = disabled || (role === 'link' && !href)

  const a11yProps =
    role === 'button'
      ? { ...buttonProps, as: as || 'div', $clickable: !disabled }
      : role === 'link'
      ? {
          ['aria-disabled']: linkDisabled ? true : undefined,
          as: as || 'a',
          $clickable: !linkDisabled,
          href: linkDisabled ? undefined : href,
          onBlur,
          onFocus,
          rel,
          role: 'link',
          target,
        }
      : role === 'img'
      ? {
          ['aria-disabled']: disabled ? true : undefined,
          role: 'img',
          onBlur,
          onFocus,
        }
      : { onBlur, onFocus }

  return (
    <StyledAvatarContainer
      ref={ref as any}
      $clickable={clickable}
      $disabled={disabled}
      $size={size}
      data-qa="core-avatar"
      {...a11yProps}
      {...(props as any)} // TODO fix
    >
      {React.Children.only(children)}
      <StyledAvatarOverlay aria-hidden />
    </StyledAvatarContainer>
  )
}

const Avatar_ = React.forwardRef(AvatarInner) as <Role extends AvatarRoles>(
  props:
    | AvatarProps<Role>
    // Legacy
    | (BaseAvatarProps & { ref?: React.ComponentPropsWithRef<'div'>['ref'] })
) => ReturnType<typeof AvatarInner>

const NextAvatar_ = React.forwardRef(AvatarInner) as <Role extends AvatarRoles>(
  props: AvatarProps<Role>
) => ReturnType<typeof AvatarInner>

export const Icon = React.forwardRef<
  HTMLDivElement,
  DivAttributes & AvatarIconProps
>(function Icon({ icon, ...props }, ref) {
  return (
    <StyledIconContainer ref={ref} {...props}>
      {icon}
    </StyledIconContainer>
  )
})

export const Label = React.forwardRef<
  HTMLDivElement,
  DivAttributes & AvatarLabelProps
>(function Label({ children, ...props }, ref) {
  // Breaking change: Option to make aria-hidden since parent requires a label.
  // Can add in major once labelling is enforced
  return (
    <StyledLabelContainer ref={ref} {...props}>
      {children}
    </StyledLabelContainer>
  )
})

export const Portrait = React.forwardRef<
  HTMLDivElement,
  DivAttributes & AvatarPortraitProps
>(function Portrait({ imageUrl, ...props }, ref) {
  return (
    <StyledPortraitContainer ref={ref} $imageUrl={imageUrl} {...props}>
      <StyledAvatarOverlay />
    </StyledPortraitContainer>
  )
})

// @ts-ignore
Avatar_.displayName = 'Avatar'

Icon.displayName = 'Avatar.Icon'

Label.displayName = 'Avatar.Label'

Portrait.displayName = 'Avatar.Portrait'

// @ts-ignore
NextAvatar_.displayName = 'Avatar'

/**

 We use avatars to visually represent our users, places, and things in the app.
 These can be in the form of rich media or representative illustrations.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-avatar--demo)

 @see [Design Guidelines](https://design.procore.com/avatar)

 @a11y Avatar's need a `aria-label` and can be enhanced with `role` for interaction. If decorative only, add `aria-hidden`. The inaccessible `clickable` prop is for UI only

 */
export const NextAvatar = addSubcomponents(
  {
    Icon,
    Label,
    Portrait,
  },
  NextAvatar_
)

/**

 We use avatars to visually represent our users, places, and things in the app.
 These can be in the form of rich media or representative illustrations.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-avatar--demo)

 @see [Design Guidelines](https://design.procore.com/avatar)

 @a11y Avatar's need a `aria-label` and can be enhanced with `role` for interaction. If decorative only, add `aria-hidden`. The inaccessible `clickable` prop is for UI only

 */
export const Avatar = addSubcomponents(
  {
    Icon,
    Label,
    Portrait,
  },
  Avatar_
)
