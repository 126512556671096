/**
 * This implementation provides item and item's index to the predicate function and works only with arrays.
 * ramda's partition - doesn't provide indexes, but works with any iterable object
 * */
export function partition<T>(
  predicate: (item: T, index: number) => boolean,
  list: readonly T[]
): [T[], T[]] {
  return list.reduce(
    ([resolved, rejected], item, index) => {
      predicate(item, index) ? resolved.push(item) : rejected.push(item)

      return [resolved, rejected]
    },
    [[] as T[], [] as T[]]
  )
}
