import styled, { css } from 'styled-components'
import { MultiSelect } from '../MultiSelect/MultiSelect'
import { colors } from '../_styles/colors'

export const StyledGroupSelectOption = styled(MultiSelect.Option)<{
  disabled: boolean
}>`
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.gray70};
      cursor: not-allowed;

      a,
      a:hover {
        color: ${colors.gray70};
        text-decoration: none;
      }
    `}
`
