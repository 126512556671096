import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { FocusScope } from '@react-aria/focus'
import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Virtuoso } from 'react-virtuoso'
import { presets } from './presets'
import { DraggableOptionContent, OptionContent } from './SuperSelect.components'
import type {
  SuperSelectApi,
  SuperSelectConfig,
  SuperSelectProps,
} from './SuperSelect.types'
import { SuperSelectContext, useSuperSelect } from './useSuperSelect'

export const InternalDragDropContext = React.createContext({
  isDraggingOver: false,
})

const InternalDragDropProvider = ({
  isDraggingOver,
  children,
}: {
  isDraggingOver: boolean
  children: React.ReactNode
}) => {
  const value = React.useMemo(() => {
    return {
      isDraggingOver,
    }
  }, [isDraggingOver])

  return (
    <InternalDragDropContext.Provider value={value}>
      {children}
    </InternalDragDropContext.Provider>
  )
}

function SelectComponent(
  props: SuperSelectProps,
  ref: React.ForwardedRef<SuperSelectApi>
) {
  const search = props.search === undefined || props.search === true

  const preset = props.preset || ''

  const config: SuperSelectConfig = {
    ...presets[preset],
    ...props,
    search,
  }

  const ctx = useSuperSelect(config)

  React.useImperativeHandle(ref, () => ctx)

  React.useEffect(() => {
    if (props.value && props.onChange === undefined) {
      console.warn(
        'Must provide both a `value` and `onChange` prop for a controlled Select'
      )
    }
  }, [])

  return (
    <SuperSelectContext.Provider value={ctx}>
      <ctx.components.Trigger
        ref={ctx.refs.floating.refs.setReference}
        {...ctx.props.trigger()}
      />
      <FloatingPortal>
        {ctx.state.open && (
          // FocusScope is needed to allow move focus from Modal and Tearsheet
          <FocusScope>
            <FloatingFocusManager
              context={ctx.refs.floating.context}
              order={
                config.search
                  ? ['content', 'reference']
                  : ['reference', 'content']
              }
              initialFocus={config.multiple ? undefined : 0}
            >
              <ctx.components.Overlay
                ref={ctx.refs.floating.refs.setFloating}
                {...ctx.props.overlay()}
              >
                {(config.search === true ||
                  (config.search === undefined && !config.multiple)) && (
                  <ctx.components.SearchContainer
                    {...ctx.props.searchContainer()}
                  >
                    <ctx.components.Search {...ctx.props.search()} />
                  </ctx.components.SearchContainer>
                )}
                {config.header && (
                  <ctx.components.Header {...ctx.props.header()}>
                    {config.header}
                  </ctx.components.Header>
                )}
                {ctx.state.options.length > 0 ? (
                  <ctx.components.Options>
                    {config.draggable ? (
                      <DragDropContext onDragEnd={ctx.state.onDragEnd}>
                        <Droppable
                          droppableId="droppable"
                          mode="virtual"
                          renderClone={(provided, snapshot, dragged) => {
                            const option =
                              ctx.state.options[dragged.source.index]

                            return (
                              <ctx.components.Item
                                isDragging
                                data-index={dragged.source.index}
                                {...provided.draggableProps}
                              >
                                <ctx.components.DragHandle />
                                <ctx.components.OptionLabel option={option} />
                              </ctx.components.Item>
                            )
                          }}
                        >
                          {(provided, snapshot) => {
                            return (
                              <InternalDragDropProvider
                                isDraggingOver={snapshot.isDraggingOver}
                              >
                                <Virtuoso
                                  {...ctx.props.virtuoso()}
                                  scrollerRef={(el) =>
                                    provided.innerRef(el as HTMLElement)
                                  }
                                  itemContent={(i, data) => (
                                    <DraggableOptionContent
                                      index={i}
                                      option={data}
                                    />
                                  )}
                                />
                              </InternalDragDropProvider>
                            )
                          }}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <Virtuoso
                        {...ctx.props.virtuoso()}
                        ref={ctx.refs.virtuoso}
                        itemContent={(i, option) => (
                          <OptionContent option={option} />
                        )}
                      />
                    )}
                  </ctx.components.Options>
                ) : (
                  <ctx.components.EmptyMessage {...ctx.props.emptyMessage()} />
                )}
                {config.footer && (
                  <ctx.components.Footer {...ctx.props.footer()} />
                )}
              </ctx.components.Overlay>
            </FloatingFocusManager>
          </FocusScope>
        )}
      </FloatingPortal>
    </SuperSelectContext.Provider>
  )
}

export const SuperSelect = React.forwardRef(SelectComponent)
