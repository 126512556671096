import React from 'react'
import type { MenuRef } from '../MenuImperative/MenuImperative.types'
import { LeafSelectionTieredSelect } from './LeafSelectionTieredSelect'
import type {
  GetValueStringFn,
  Id,
  Tier,
  TieredSelectApi,
  TieredSelectProps,
} from './TieredSelect.types'
import { TierSelectionTieredSelect } from './TierSelectionTieredSelect'

function defaultGetGroupId(tier: Tier) {
  return tier.groupId
}

function defaultGetId(tier: Tier) {
  return tier.id
}

function defaultGetLabel(tier: Tier) {
  return tier.label
}

function defaultGetNextGroupId(tier: Tier) {
  return tier.nextGroupId as Id
}

function getFullPath<T>(
  getLabel: TieredSelectProps['getLabel'] = defaultGetLabel
): GetValueStringFn {
  return function getValueString(value: T[]) {
    return value.map((tier) => getLabel!(tier)).join(' > ')
  }
}

function getLeafOnly<T>(
  getLabel: TieredSelectProps['getLabel'] = defaultGetLabel
): GetValueStringFn {
  return function getValueString(value: T[]) {
    return value.length ? getLabel(value[value.length - 1]) : ''
  }
}

function getValueStringFn(
  valueStringResolution: TieredSelectProps['getValueString'],
  getLabel: TieredSelectProps['getLabel'] = defaultGetLabel
): GetValueStringFn {
  if (valueStringResolution === 'full-path') {
    return getFullPath(getLabel)
  }

  if (valueStringResolution === 'leaf-only') {
    return getLeafOnly(getLabel)
  }

  return valueStringResolution!
}

function noop() {}

function always() {
  return true
}

export const useHighlightItemEffects = ({
  currentTier,
  searchValue,
  menuRef,
  loading,
  loadingMore,
}: {
  currentTier: Id
  searchValue: string
  menuRef: React.RefObject<MenuRef>
  loading: TieredSelectProps['loading']
  loadingMore: TieredSelectProps['loadingMore']
}) => {
  const [previousTier, setPreviousTier] = React.useState<Id>()
  React.useEffect(() => {
    setPreviousTier(currentTier)
  }, [currentTier])

  const [previousSearch, setPreviousSearch] = React.useState<String>('')
  React.useEffect(() => {
    setPreviousSearch(searchValue)
  }, [searchValue])

  const currentMenuRef = menuRef.current
  React.useEffect(() => {
    if (previousSearch !== searchValue || previousTier !== currentTier) {
      currentMenuRef?.highlightFirst()
    }
  }, [currentMenuRef, currentTier, previousTier, searchValue, previousSearch])

  React.useEffect(() => {
    if (loadingMore) {
      currentMenuRef?.highlightLast(false)
    }
  }, [currentMenuRef, loadingMore])

  React.useEffect(() => {
    if (!loading && !currentMenuRef?.highlighted()) {
      currentMenuRef?.highlightFirst()
    }
  }, [currentMenuRef, loading])
}

export const TieredSelectContext = React.createContext<TieredSelectApi>({
  afterHide: noop,
  afterShow: noop,
  beforeHide: always,
  beforeShow: always,
  getGroupId: defaultGetGroupId,
  getId: defaultGetId,
  getLabel: defaultGetLabel,
  getNextGroupId: defaultGetNextGroupId,
  getValueString: getFullPath(defaultGetLabel),
  i18nScope: 'core.tieredSelect',
  onChange: noop,
})

/**

 We use tiered selects to allow users to choose a single parent or child option
 that is nested within a tiered list. For example, choosing a location from
 within a list of tiered locations. We typically see these selects on forms.

 Other select components are group select, multi select, and select.
 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-tieredselect--demo)

 @see [Design Guidelines](https://design.procore.com/tiered-select)

 */
export const TieredSelect = React.forwardRef<HTMLDivElement, TieredSelectProps>(
  function TieredSelect(
    {
      afterHide = noop,
      afterShow = noop,
      beforeHide = always,
      beforeShow = always,
      block,
      getGroupId = defaultGetGroupId,
      getId = defaultGetId,
      getLabel = defaultGetLabel,
      getNextGroupId = defaultGetNextGroupId,
      getValueString = getFullPath(getLabel),
      i18nScope = 'core.tieredSelect',
      onChange = noop,
      onClear,
      onNavigate,
      onQuickCreate,
      onScrollBottom,
      onSearch,
      selectableTiers = true,
      isTierDisabled = () => false,
      tabIndex,
      ...props
    },
    ref
  ) {
    const isLeaf = React.useCallback(
      function (tier: Tier) {
        return !getNextGroupId(tier)
      },
      [getNextGroupId]
    )

    return (
      <TieredSelectContext.Provider
        value={{
          afterHide,
          afterShow,
          beforeHide,
          beforeShow,
          block,
          getGroupId,
          getId,
          getLabel,
          getNextGroupId,
          getValueString: getValueStringFn(getValueString),
          i18nScope,
          isLeaf,
          isTierDisabled,
          onChange,
          onClear,
          onNavigate,
          onQuickCreate,
          onScrollBottom,
          onSearch,
          tabIndex,
        }}
      >
        {selectableTiers ? (
          <TierSelectionTieredSelect ref={ref} {...props} />
        ) : (
          <LeafSelectionTieredSelect ref={ref} {...props} />
        )}
      </TieredSelectContext.Provider>
    )
  }
)
