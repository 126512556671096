import throttle from 'lodash.throttle'
import React from 'react'
import type { AnchorNavigationElementSection } from './AnchorNavigation.types'

export const AnchorNavigationContext = React.createContext<{
  offset: number
  sections: AnchorNavigationElementSection[]
  active: string
  registerSection: (id: string, label: string, element: HTMLElement) => void
  unregisterSection: (id: string) => void
  setActive: (id: string, withScroll?: boolean) => void
}>({
  offset: 0,
  sections: [],
  active: '',
  registerSection: () => {},
  unregisterSection: () => {},
  setActive: () => {},
})

export const useAnchorNavigationContext = () =>
  React.useContext(AnchorNavigationContext)

interface AnchorNavigationProviderProps {
  offset?: number
}

function findScrollContainer(element: HTMLElement): HTMLElement {
  let parent = element.parentElement
  while (parent) {
    const { overflow } = window.getComputedStyle(parent)
    if (overflow.split(' ').every((o) => o === 'auto' || o === 'scroll')) {
      return parent
    }
    parent = parent.parentElement
  }

  return document.documentElement
}

function getElementScrollPosition(
  element: HTMLElement,
  scroller: HTMLElement | null
): number {
  let y = 0
  let el: HTMLElement | null = element

  do {
    y += el.offsetTop
    el = el.offsetParent as HTMLElement | null
  } while (el && el !== scroller)

  return y
}

export function AnchorNavigationProvider({
  children,
  offset = 0,
}: React.PropsWithChildren<AnchorNavigationProviderProps>) {
  const [active, setActive] = React.useState('')
  const ref = React.useRef<{
    sections: AnchorNavigationElementSection[]
    blockScrollTimestamp: number
    scroller: HTMLElement | null
  }>({
    sections: [],
    blockScrollTimestamp: 0,
    scroller: null,
  })

  const changeActive = (id: string) => {
    const { sections, scroller } = ref.current
    setActive(id)

    const element = sections.find((section) => section.id === id)?.element

    if (element) {
      ;(scroller || window).scrollTo({
        top: getElementScrollPosition(element, scroller) - offset,
        behavior: 'smooth',
      })

      // Disable scroll listener to avoid changing active element
      ref.current.blockScrollTimestamp = new Date().getTime()
    }
  }

  React.useEffect(() => {
    const onScroll = (event?: Event) => {
      const { blockScrollTimestamp, sections, scroller } = ref.current
      const now = new Date().getTime()

      if (!sections.length) return

      // Disable scroll event when auto scrolling by clicking on the anchor
      if (now - blockScrollTimestamp < 200) {
        ref.current.blockScrollTimestamp = now
        return
      }

      const y =
        (scroller
          ? scroller.scrollTop
          : window.scrollY || document.documentElement.scrollTop) + offset

      // Before the first element
      if (getElementScrollPosition(sections[0].element, scroller) > y) {
        if (event) {
          setActive(sections[0]?.id)
        }
        return
      }

      // Get first element overflowing top, get the previous one
      const selectedIndex = sections.findIndex(
        (item) => getElementScrollPosition(item.element, scroller) > y
      )

      const selectedElement =
        selectedIndex === -1
          ? sections[sections.length - 1]
          : sections[Math.max(selectedIndex - 1, 0)]

      if (selectedElement) {
        setActive(selectedElement.id)
      }
    }

    const throttleScrollEvent = throttle(onScroll, 100)
    addEventListener('scroll', throttleScrollEvent, {
      capture: false,
      passive: true,
    })
    onScroll()

    return () => {
      removeEventListener('scroll', throttleScrollEvent, { capture: false })
      throttleScrollEvent.cancel()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const registerSection = (id: string, label: string, element: HTMLElement) => {
    if (!ref.current.scroller) {
      ref.current.scroller = findScrollContainer(element)
    }

    const { sections, scroller } = ref.current

    if (sections.find((section) => section.id === id)) {
      return
    }

    const y = getElementScrollPosition(element, scroller)
    const index = sections.findIndex(
      (section) => getElementScrollPosition(section.element, scroller) > y
    )

    if (index === -1) {
      sections.push({ id, label, element })
    } else {
      sections.splice(index, 0, { id, label, element })
    }

    if (sections.length === 1) {
      setActive(id)
    }
  }

  const unregisterSection = (id: string) => {
    const { sections } = ref.current
    const index = sections.findIndex((section) => section.id === id)
    if (index !== -1) {
      sections.splice(index, 1)
    }
  }

  return (
    <AnchorNavigationContext.Provider
      value={{
        offset,
        active,
        sections: ref.current.sections,
        setActive: changeActive,
        registerSection,
        unregisterSection,
      }}
    >
      {children}
    </AnchorNavigationContext.Provider>
  )
}
