import type { Props } from '../_utils/types'

/**
 * @deprecated The `list` variant describes the change better, `ol` `li`. The component always renders a `nav`.
 *
 * BEFORE variant="nav"
 * AFTER variant="list"
 * @deprecatedSince 11.33.0
 * @since 11.6.0
 */
export enum BreadcrumbVariants {
  NAV = 'nav',
  LEGACY = 'legacy',
}

export interface BreadcrumbProps extends Props {
  /**
   * @a11y Set `variant="list"` to enable `ol` and `li` semantic markup with the crumbs. Add the hyperlinks **inside** each crumb as children.
   * @description Enables the next version of semantic breacrumbs. Set `variant="list"` and add the hyperlinks **inside** each crumb as children.
   *
   * BEFORE undefined | variant="nav", anchors outside crumb
   *
   * AFTER variant="list", anchors inside crumb
   * @since 11.33.0
   */
  variant?: BreadcrumbVariants | 'list'
}

export interface BreadcrumbCrumbProps extends Props {
  /**
   * @since 10.19.0
   */
  active?: boolean
  /**
   * @since 11.6.0
   */
  isLast?: boolean
  /**
   * @deprecated Handled by parent prop. Use `Breadcrumb variant="list"` with anchors INSIDE `Breadcumb.Crumb`.
   * @since 11.6.0
   */
  variant?: BreadcrumbVariants
}
