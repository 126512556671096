import styled, { css } from 'styled-components'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { Button } from '../Button/Button'
import { Card } from '../Card/Card'
import { Flex } from '../Flex/Flex'
import { MenuImperative } from '../MenuImperative/MenuImperative'
import { Spinner } from '../Spinner/Spinner'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

export const StyledTieredSelect = styled(MenuImperative)`
  align-items: stretch;
  max-height: 40vh;
`

export const StyledTieredSelectIcon = styled(Flex)`
  height: 24px;
  width: 24px;
`

export const StyledTieredSelectArrowButton = styled(Button)`
  border: 1px solid transparent;
`

export const StyledTieredSelectBreadcrumbs = styled(Breadcrumbs)<{
  $nonInteractive: boolean
}>`
  background-color: ${colors.gray96};
  flex-wrap: wrap;
  margin-top: ${spacing.sm}px;
  max-height: 68px;
  overflow-y: auto;
  padding: ${spacing.xs}px ${spacing.md}px;

  ${({ $nonInteractive }) => {
    if ($nonInteractive) {
      return css`
        margin-top: ${spacing.md}px;
        pointer-events: none;
      `
    }
  }}
`

export const StyledTieredSelectHome = styled(Flex)`
  height: 20px;
`

export const StyledTieredSelectOption = styled(MenuImperative.Item)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2px ${spacing.lg}px;
`

export const StyledTieredSelectLoadingMore = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: ${spacing.xs}px ${spacing.lg}px 0px ${spacing.lg}px;
`

export const StyledTieredSelectOverlay = styled(Card)`
  max-width: 248px;
  min-width: inherit;
`

export const StyledTieredSelectSpinnerOverlay = styled(Spinner)`
  min-height: auto;
`
