import React from 'react'
import type { SpanAttributes } from '../_utils/types'
import { StyledTypography } from './Typography.styles'
import type { TypographyProps } from './Typography.types'

/**

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-typography--demo)

 */
export const Typography = React.forwardRef<
  HTMLSpanElement,
  SpanAttributes & TypographyProps
>(function Typography({ children, color, breakWord, ...props }, ref) {
  return (
    <StyledTypography $color={color} breakWord={breakWord} {...props} ref={ref}>
      {children}
    </StyledTypography>
  )
})

Typography.displayName = 'Typography'
