import {
  Clear,
  EllipsisVertical,
  Error,
  Info,
  Warning,
} from '@procore/core-icons/dist'
import { partition } from 'ramda'
import React from 'react'
import { Button } from '../Button/Button'
import { Card } from '../Card/Card'
import { Menu, useMenu } from '../Menu/Menu'
import { OverlayTrigger } from '../OverlayTrigger/OverlayTrigger'
import { useI18nContext } from '../_hooks/I18n'
import { OverflowObserver } from '../_hooks/OverflowObserver'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { mergeRefs } from '../_utils/mergeRefs'
import type { ButtonAttributes, DivAttributes, Props } from '../_utils/types'
import {
  StyledAction,
  StyledActionsTrigger,
  StyledBannerContainer,
  StyledBody,
  StyledContent,
  StyledExpandableContent,
  StyledExpandableContentContainer,
  StyledIconContainer,
  StyledMiniBannerContainer,
  StyledTitle,
} from './Banner.styles'
import type {
  BannerExpandableContentProps,
  BannerIconProps,
  BannerProps,
  BannerVariant,
} from './Banner.types'

const Banner_ = React.forwardRef<HTMLDivElement, DivAttributes & BannerProps>(
  function Banner({ children, variant, ...props }, ref) {
    const [expandableContentNodes, content] = partition(
      (element: React.ReactNode) =>
        React.isValidElement(element) && element.type === ExpandableContent,
      React.Children.toArray(children)
    ) as [React.ReactElement[], React.ReactElement[]]

    const isExpandable = expandableContentNodes.length !== 0

    return (
      <StyledBannerContainer
        ref={ref}
        isExpandable={isExpandable}
        variant={variant}
        {...props}
      >
        {isExpandable ? (
          <>
            <StyledExpandableContentContainer
              justifyContent="space-between"
              alignItems="center"
            >
              {content}
            </StyledExpandableContentContainer>
            {expandableContentNodes}
          </>
        ) : (
          content
        )}
      </StyledBannerContainer>
    )
  }
)

const Content = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function Content({ children, ...props }, ref) {
    return (
      <StyledContent {...props} ref={ref}>
        {children}
      </StyledContent>
    )
  }
)

const ExpandableContent = React.forwardRef<
  HTMLDivElement,
  DivAttributes & BannerExpandableContentProps
>(function ExpandableContent(
  { children, expanded = false, expandableContentMaxHeight = 120, ...props },
  ref
) {
  const containerRef = React.useRef<HTMLDivElement>(null)

  return expanded ? (
    <OverflowObserver>
      {({ ref: overflowRef, isOverflowingY }) => (
        <StyledExpandableContent
          ref={mergeRefs(ref, containerRef)}
          data-qa="core-banner-expandable-content"
          onAnimationEnd={() => overflowRef(containerRef.current)}
          isOverflowingY={isOverflowingY}
          expandableContentMaxHeight={expandableContentMaxHeight}
          {...props}
          {...(isOverflowingY && { tabIndex: 0 })}
        >
          {children}
        </StyledExpandableContent>
      )}
    </OverflowObserver>
  ) : null
})

const Title = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function Title({ children, ...props }, ref) {
    return (
      <StyledTitle {...props} ref={ref}>
        {children}
      </StyledTitle>
    )
  }
)

const Body = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function Body({ children, ...props }, ref) {
    return (
      <StyledBody {...props} ref={ref}>
        {children}
      </StyledBody>
    )
  }
)

const Action = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function Action({ children, ...props }, ref) {
    return (
      <StyledAction {...props} ref={ref}>
        {children}
      </StyledAction>
    )
  }
)

const DropdownAction = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function DropdownAction({ children, ...props }, ref) {
    return (
      <Menu.Item ref={ref} item={null} {...(props as any)}>
        {children}
      </Menu.Item>
    )
  }
)

const Actions = React.forwardRef<HTMLButtonElement, ButtonAttributes & Props>(
  function Actions({ children, ...props }, ref) {
    const actionButtons = React.Children.toArray(children).filter(
      (element: React.ReactNode) =>
        React.isValidElement(element) && element.type === Action
    ) as React.ReactElement[]

    return (
      <OverlayTrigger
        overlay={
          <Card>
            <Menu
              ref={(node) => node?.focus()}
              usingHook={useMenu({
                isSelectable: ({ type }) => type === DropdownAction,
              })}
            >
              <Menu.Options>
                {actionButtons.map(({ props: actionButtonProps }, idx) => (
                  <DropdownAction key={idx} {...actionButtonProps} />
                ))}
              </Menu.Options>
            </Menu>
          </Card>
        }
        ref={ref}
        placement="bottom-right"
      >
        <StyledActionsTrigger
          data-qa="core-banner-actions-trigger"
          variant="tertiary"
          arrow={false}
          icon={<EllipsisVertical />}
          {...props}
        />
      </OverlayTrigger>
    )
  }
)

const Icon = React.forwardRef<HTMLDivElement, DivAttributes & BannerIconProps>(
  function Icon({ children, icon, ...props }, ref) {
    return (
      <StyledIconContainer ref={ref} aria-hidden="true" {...props}>
        {icon}
      </StyledIconContainer>
    )
  }
)

const Dismiss = React.forwardRef<HTMLButtonElement, ButtonAttributes & Props>(
  function Dismiss({ children, ...props }, ref) {
    const I18n = useI18nContext()

    return (
      <Button
        aria-label={I18n.t('core.banner.dismissAlert')}
        ref={ref}
        {...props}
        icon={<Clear />}
        variant="tertiary"
      />
    )
  }
)

export const ErrorBanner = React.forwardRef<
  HTMLDivElement,
  DivAttributes & Props
>(function ErrorBanner({ children, ...props }, ref) {
  return (
    <Banner ref={ref} variant="error" {...props}>
      <Icon icon={<Error size="lg" />} />
      {children}
    </Banner>
  )
})

export const InfoBanner = React.forwardRef<
  HTMLDivElement,
  DivAttributes & Props
>(function InfoBanner({ children, ...props }, ref) {
  return (
    <Banner ref={ref} variant="info" {...props}>
      <Icon icon={<Info size="lg" />} />
      {children}
    </Banner>
  )
})

/**
 * @since 11.26.0
 */
export const AttentionBanner = React.forwardRef<
  HTMLDivElement,
  DivAttributes & Props
>(function AttentionBanner({ children, ...props }, ref) {
  return (
    <Banner ref={ref} variant="attention" {...props}>
      <Icon icon={<Warning size="lg" />} />
      {children}
    </Banner>
  )
})

/**
 * @deprecated ActionBanner was renamed to AttentionBanner.
 * @deprecatedSince 11.26.0
 */
export const ActionBanner = AttentionBanner

export const UNSAFE_MiniBanner = React.forwardRef<
  HTMLDivElement,
  DivAttributes & Props & { variant?: BannerVariant }
>(function MiniBanner({ children, variant = 'info', ...props }, ref) {
  return (
    <StyledMiniBannerContainer ref={ref} variant={variant} {...props}>
      {children}
    </StyledMiniBannerContainer>
  )
})

Banner_.displayName = 'Banner'

Body.displayName = 'Banner.Body'

Content.displayName = 'Banner.Content'

ExpandableContent.displayName = 'Banner.ExpandableContent'

Title.displayName = 'Banner.Title'

Action.displayName = 'Banner.Action'

Actions.displayName = 'Banner.Actions'

DropdownAction.displayName = 'Banner.DropdownAction'

Icon.displayName = 'Banner.Icon'

Dismiss.displayName = 'Banner.Dismiss'

ErrorBanner.displayName = 'ErrorBanner'

InfoBanner.displayName = 'InfoBanner'

AttentionBanner.displayName = 'AttentionBanner'

ActionBanner.displayName = 'ActionBanner'

UNSAFE_MiniBanner.displayName = 'MiniBanner'

/**


 We use banners to contextually convey complicated or supplemental information
 to a user. Depending on the type of banner, we can provide more information
 about a feature or page the user is on, an action the user needs to perform,
 or an error the user has encountered.

 Procore banners are informative and actionable. Always explain what the user
 needs to know (and in some cases, why they’re seeing the banner), and provide
 an actionable next step.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-banner--demo)

 @see [Design Guidelines](https://design.procore.com/banner)

 */
export const Banner = addSubcomponents(
  {
    Body,
    Content,
    ExpandableContent,
    Title,
    Action,
    Actions,
    Icon,
    Dismiss,
  },
  Banner_
)
