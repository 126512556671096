import React from 'react'
import type { TimerApi } from './Timer'
import { useTimer } from './Timer'

/* TYPES */

export type BeforeResult = Promise<any> | boolean | undefined | void

export type BeforeCallback = (event: Event) => BeforeResult

interface DelayedCallbackConfig {
  after?: (event: Event) => void
  before?: BeforeCallback
  delay: number
  timer?: TimerApi
}

function noop() {}

function returnTrue() {
  return true
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function isPromise(obj: any): obj is Promise<any> {
  return typeof obj === 'object' && typeof obj.then === 'function'
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function wrapPromise(result: BeforeResult): Promise<any> {
  if (isPromise(result)) {
    return result
  }

  if (typeof result === 'boolean') {
    return Promise.resolve(result)
  }

  return Promise.resolve(true)
}

/* HOOK */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useDelayedCallback({
  after = noop,
  before = returnTrue,
  delay = 0,
  timer,
}: DelayedCallbackConfig) {
  const defaultTimer = useTimer({})

  const t = timer || defaultTimer

  React.useEffect(() => {
    return () => {
      // cancel timeout on unmount
      t.cancel()
    }
  }, [])

  return React.useCallback(
    (event: any) => {
      return wrapPromise(before(event)).then(
        (shouldUpdate: boolean) =>
          shouldUpdate && t.setTimer(() => after(event), delay)
      )
    },
    [delay, before, after, t]
  )
}
