import type React from 'react'
import type {
  DropzoneInputProps as DropzoneInputPropsBase,
  DropzoneOptions,
  DropzoneRootProps as DropzoneRootPropsBase,
  DropzoneState,
} from 'react-dropzone'
import {
  type FileError as FileRejectionError,
  type FileRejection,
} from 'react-dropzone'

export type DropzoneFile = any

export type FileRejectionReason = FileRejectionError['code']

export const fileRejectionReason: Record<string, FileRejectionReason> = {
  maxFiles: 'too-many-files',
  maxFileSize: 'file-too-large',
  minFileSize: 'file-too-small',
  fileType: 'file-invalid-type',
}

export type { FileRejection, FileRejectionError }

export interface DropzoneErrorBannerProps {
  /**
   * @since 10.19.0
   */
  error: DropzoneHookState['dropError']

  /**
   * Rejected files and why they were rejected
   * <CodeBlock>
   *  {`{
   *   file: File,
   *   errors: FileError[]
   *  }[]`}
   * </CodeBlock>
   * @since 10.19.0
   */
  fileRejections: DropzoneHookState['fileRejections']
  /**
   * @since 10.19.0
   */
  onDismiss: () => void
  /**
   * @since 10.19.0
   */
  className?: string
  /**
   * @since 10.19.0
   */
  qa?: {
    showErrorDetails?: string
    hideError?: string
  }
}

export interface MultipleErrorsProps {
  /**
   * @since 10.19.0
   */
  fileRejections: FileRejection[]
  /**
   * @since 10.19.0
   */
  maxFiles?: number
  /**
   * @since 10.19.0
   */
  maxSize?: number
  /**
   * @since 10.19.0
   */
  minSize?: number
}

export interface ContainerProps {
  /**
   * Additional props for dropzone container element
   * @since 10.19.0
   */
  rootProps: DropzoneRootProps

  /**
   * Returns the props you should apply to the root drop container you render
   * @since 10.19.0
   */
  getRootProps: DropzoneState['getRootProps']
  /**
   * @since 10.19.0
   */
  active: boolean

  /**
   * Enable/disable the dropzone container
   * @since 10.19.0
   */
  disabled: boolean
}
export interface ContentProps {
  /**
   * Force icon visibility
   * <Code>NOTICE:</Code> passing <Code>true</Code> or <Code>false</Code>
   * value explicitly will make component ignore resizing.
   * @since 10.19.0
   */
  isIconVisible: boolean

  /**
   * Enable/disable the dropzone content
   * @since 10.19.0
   */
  disabled: boolean
  /**
   * @since 10.19.0
   */
  contentMessage: React.ReactNode
}

export interface DropzoneContentProps {
  /**
   * Open the native file selection dialog
   * @since 10.19.0
   */
  open: () => void

  /**
   * Enable/disable the dropzone content
   * @since 10.19.0
   */
  disabled: boolean
  /**
   * @since 10.19.0
   */
  errorMessage?: string
}

export interface DropzoneHookProps
  extends Partial<Omit<DropzoneOptions, 'minSize' | 'maxSize'>> {
  /**
   * Array of already selected items
   * @since 10.19.0
   */
  value?: DropzoneFile[]

  /**
   * Maximum number files to the dropzone is allowed. All files above the limit
   * will be rejected.
   * @since 10.19.0
   */
  maxFileNumber?: number

  /**
   * Minimum file size (in bytes)
   * @since 10.19.0
   */
  minFileSize?: DropzoneOptions['minSize']

  /**
   * Maximum file size (in bytes)
   * @since 10.19.0
   */
  maxFileSize?: DropzoneOptions['maxSize']
}

export const dropErrors = {
  fileType: 'FILE_TYPE_ERROR',
  fileAmount: 'FILE_AMOUNT_ERROR',
  maxFileSize: 'MAX_FILE_SIZE',
  minFileSize: 'MIN_FILE_SIZE',
  oneFileAtATime: 'ONE_FILE_AT_A_TIME',
  multipleErrors: 'MULTIPLE_ERRORS',
  reset: 'RESET',
} as const

export type DropErrorType = (typeof dropErrors)[keyof typeof dropErrors]

export type DropError = {
  /**
   * @since 10.19.0
   */
  type: DropErrorType
  /**
   * @since 10.19.0
   */
  title: string
  /**
   * @since 10.19.0
   */
  message: string
  /**
   * @since 10.19.0
   */
  body?: React.ReactNode
}

export type DropzoneHookState = DropzoneState & {
  /**
   * Allow drag 'n' drop (or selection from the file dialog) of multiple files
   * @default false
   * @since 10.19.0
   * */
  multiple: boolean

  /**
   * Error message if dragged files is rejected
   * <CodeBlock>
   *  {`{ type: DropErrorType, title: string, message: string }`}
   * </CodeBlock>
   * @since 10.19.0
   */
  dragError: string

  /**
   * Error message if dropped files is rejected
   * <CodeBlock>
   *  {`{ type: DropErrorType, title: string, message: string }`}
   * </CodeBlock>
   * @since 10.19.0
   */
  dropError: DropError

  /**
   * Enable/disable the dropzone
   * @default false
   * @since 10.19.0
   */
  disabled: boolean

  /**
   * Sets <Code>dropError</Code>.
   * <CodeBlock>{'(error: DropErrorType) => void'}</CodeBlock>
   * @since 10.19.0
   */
  dispatchDropError: (error: DropErrorType) => void
}

/**
 * WARNING
 * To keep component work as expected according to props passed to `useDropzone` hook
 * Some properties are omitted
 */
type FrozenDropzoneRootKeys = 'disabled'

type FrozenDropzoneRootProps = {
  [key in FrozenDropzoneRootKeys]?: never
}

type FrozenDropzoneInputKeys = 'accept' | 'multiple' | 'type'

type FrozenDropzoneInputProps = {
  [key in FrozenDropzoneInputKeys]: never
}

export type DropzoneRootProps = DropzoneRootPropsBase & FrozenDropzoneRootProps

export type DropzoneInputProps = DropzoneInputPropsBase &
  FrozenDropzoneInputProps

export interface QaTags {
  /**
   * @since 10.19.0
   */
  dropzone?: string
  /**
   * @since 10.19.0
   */
  showErrorDetails?: string
  /**
   * @since 10.19.0
   */
  hideError?: string
}

export interface DropzoneProps extends DropzoneHookState {
  /**
   * Render prop to specify a custom dropzone body message
   * @since 10.19.0
   */
  contentRenderer?: (props: DropzoneContentProps) => React.ReactNode
  /**
   * <Code>NOTICE:</Code>
   * <br/>
   * <Code>true</Code> - will make Icon always visible, despite the component height
   * <br/>
   * <Code>false</Code> - will make Icon always hide, despite the component height
   * <br/>
   * <Code>undefined</Code> - will look on the component height to decide show or hide Icon
   * @since 10.19.0
   */
  isIconVisible?: boolean

  /**
   * Dropzone tooltip content
   * @since 10.19.0
   */
  tooltip?: React.ReactNode | string

  /**
   * Additional props for dropzone element
   * @since 10.19.0
   */
  rootProps?: DropzoneRootProps

  /**
   * Additional props for input element
   * @since 10.19.0
   */
  inputProps?: DropzoneInputProps
  /**
   * @since 10.19.0
   */
  className?: string
  /**
   * @since 10.19.0
   */
  qa?: QaTags
}

export interface DropzoneRef {
  /**
   * @since 10.19.0
   */
  rootRef: React.RefObject<HTMLElement>
  /**
   * @since 10.19.0
   */
  inputRef: React.RefObject<HTMLInputElement>

  /**
   * Open the native file selection dialog
   * @since 10.19.0
   */
  open: () => void
}
