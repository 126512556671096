import styled, { css } from 'styled-components'
import { StyledActions } from '../Content/Content.styles'
import { StyledPill } from '../Pill/Pill.styles'
import { Typography } from '../Typography/Typography'
import { spacing } from '../_styles/spacing'
import type { TitleAssetsProps } from './Title.types'

export const StyledTitle = styled.div`
  display: flex;
  align-items: flex-start;
`

export const StyledAssets = styled.div<TitleAssetsProps>`
  margin-right: ${spacing.md}px;
  ${({ alignment }) =>
    css`
      align-self: ${alignment};
    `}
`

export const StyledTitleContent = styled.div<{ $hasActions: boolean }>`
  align-self: center;

  ${({ $hasActions }) =>
    $hasActions &&
    css`
      margin-right: ${spacing.lg}px;
    `}
`

export const StyledText = styled.span`
  > h1,
  > h2,
  > h3 {
    display: inline;
  }
`

export const StyledTitlePill = styled.span`
  margin-left: ${spacing.sm}px;

  ${StyledPill} {
    vertical-align: text-top;
  }
`

export const StyledTitleActions = styled(StyledActions)`
  margin-left: auto;
`

export const StyledSubtext = styled(Typography)`
  display: block;
`
