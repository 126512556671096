import { Avatar } from '@procore/core-react'
import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'

export default function () {
  const avatarImageUrl = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "avatar" }) {
          publicURL
        }
      }
    `
  ).file.publicURL

  return (
    <div style={{ padding: '16px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar>
          <Avatar.Portrait imageUrl={avatarImageUrl} />
        </Avatar>
        <span style={{ marginLeft: '8px' }}>Contact Name</span>
      </div>
      <br />
      <p>Some information about this contact can go here.</p>
    </div>
  )
}
