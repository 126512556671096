export function scrollIntoView(index: number, element: HTMLElement) {
  if (!element.parentElement) {
    return
  }

  if (index === 0) {
    element.parentElement.scrollTop = 0

    return
  }

  const { offsetTop, clientHeight } = element

  const { scrollTop, clientHeight: parentHeight } = element.parentElement

  if (offsetTop < scrollTop) {
    element.parentElement.scrollTop = offsetTop
  } else if (offsetTop + clientHeight > scrollTop + parentHeight) {
    element.parentElement.scrollTop = offsetTop - parentHeight + clientHeight
  }
}
