import { Clear } from '@procore/core-icons/dist'
import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getEllipsis, getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const StyledLabel = styled.span`
  ${getTypographyIntent('body')}

  ${getEllipsis}

  flex: 0 1 auto;
`

export const StyledRemove = styled(Clear)`
  border-radius: 100%;
  cursor: pointer;
  flex: 0 0 auto;
  height: 16px;
  margin: 0 -${spacing.sm}px 0 ${spacing.sm}px;
  width: 16px;

  &:hover {
    background: ${colors.white};
    color: ${colors.blue40};
  }
`

export const StyledToken = styled.div<{
  $disabled: boolean
  $focused: boolean
  $error: boolean
}>`
  align-items: center;
  background-color: ${colors.blue50};
  border-radius: 200px; // TODO investigate this magic number
  border: 1px solid transparent;
  color: ${colors.white};
  cursor: default;
  display: inline-flex;
  height: 24px;
  line-height: 1; // TODO investigate this magic number
  max-width: 360px; // TODO investigate this magic number
  min-width: 48px; // TODO investigate this magic number
  padding: ${spacing.xs}px ${spacing.md}px;

  &:hover,
  &.hover {
    background-color: ${colors.blue45};
  }

  ${({ $focused = false, $disabled = false }) =>
    $focused &&
    css`
      ${getFocus()}

      background-color: ${colors.blue45};

      ${
        !$disabled &&
        css`
          ${StyledRemove} {
            background-color: ${colors.white};
            color: ${colors.blue45};
          }
        `
      }}
    `}

  ${({ $disabled = false }) =>
    $disabled &&
    css`
      background-color: ${colors.gray70};
      border-color: transparent;
      box-shadow: none;
      pointer-events: none;
    `}

  ${({ $error = false }) =>
    $error &&
    css`
      &,
      &:hover {
        background-color: ${colors.red50};
      }
    `}
`
