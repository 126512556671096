import styled from 'styled-components'
import { LinkButton } from '../Button/Button'
import { spacing } from '../_styles/spacing'

export const StyledFileListDownloadAll = styled(LinkButton)`
  margin-bottom: ${spacing.xs}px;
`

export const StyledFileListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.xs}px;
  word-break: break-word;

  &:last-child {
    margin-bottom: 0;
  }
`

export const StyledFileListItemTextInactive = styled.span`
  cursor: pointer;
`

export const StyledFileListItemDownloadIcon = styled(LinkButton)`
  margin-left: ${spacing.sm}px;
  align-self: flex-start;
`
