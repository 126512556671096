import { Ban, Check } from '@procore/core-icons'
import { useId } from '@react-aria/utils'
import conditionalStrings from 'classnames'
import type {
  Form as FormikForm,
  FormikConfig,
  FormikContextType,
} from 'formik'
import {
  Formik,
  FormikContext,
  useField as useFormikField,
  useFormikContext,
} from 'formik'
import { either, identity, is, isEmpty, prop, startsWith, toLower } from 'ramda'
import React from 'react'
import { Banner, ErrorBanner } from '../Banner'
import { Box } from '../Box'
import { Checkbox } from '../Checkbox'
import type { CheckboxProps } from '../Checkbox/Checkbox.types'
import type { DateSelectProps } from '../DateSelect'
import { DateSelect } from '../DateSelect'
import { GroupSelect } from '../GroupSelect'
import type { GroupSelectProps } from '../GroupSelect/GroupSelect.types'
import type { InputProps } from '../Input'
import { Input } from '../Input'
import type { MultiSelectProps } from '../MultiSelect'
import { MultiSelect } from '../MultiSelect'
import {
  CurrencyInput as BaseCurrencyInput,
  NumberInput as BaseNumberInput,
} from '../NumberInput'
import { getCurrencyProps } from '../NumberInput/NumberInput'
import {
  defaultCurrencyDecimalScale,
  defaultCurrencyFillDecimalScale,
  defaultCurrencyInputPrefix,
} from '../NumberInput/NumberInput.constants'
import { useNumberFormat } from '../NumberInput/NumberInput.hooks'
import type {
  NumberInputProps,
  NumberInputValueChange,
} from '../NumberInput/NumberInput.types'
import { defaultLocale } from '../NumberInput/NumberInput.utils'
import type { PageFooterProps } from '../PageLayout/PageLayout.types'
import { Pill } from '../Pill'
import { defaultGetColor, PillSelect } from '../PillSelect/PillSelect'
import type { PillSelectProps } from '../PillSelect/PillSelect.types'
import { RadioButton } from '../RadioButton'
import type {
  SelectButtonProps,
  SelectOptionProps,
  SelectProps,
} from '../Select'
import { Select } from '../Select'
import { SettingsPage } from '../SettingsPage'
import type { TextAreaProps } from '../TextArea'
import { TextArea } from '../TextArea'
import type { TextEditorProps } from '../TextEditor'
import { TextEditor } from '../TextEditor'
import { TextEditorOutput } from '../TextEditorOutput'
import type { TieredSelectProps } from '../TieredSelect'
import { TieredSelect } from '../TieredSelect'
import { Typography } from '../Typography'
import { useDateTime } from '../_hooks/DateTime'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { useCloseWithConfirmContext } from '../_utils/closeWithConfirm'
import { formatMachineDate, toDate } from '../_utils/dateTime'
import type { DivAttributes, Props } from '../_utils/types'
import {
  StyledCheckboxInlineDescription,
  StyledDescription,
  StyledForm,
  StyledFormFieldBanner,
  StyledFormFieldErrorIcon,
  StyledFormFieldHeader,
  StyledFormFieldMain,
  StyledFormFieldRequiredMark,
  StyledFormOutputFiledset,
  StyledFormOutputTextArea,
  StyledFormRow,
  StyledLabel,
  StyledTraditionalFormColumn,
} from './Form.styles'
import type {
  BaseFieldProps,
  ComponentWithFieldProp,
  FieldCheckboxComponentProps,
  FieldCheckboxesComponentProps,
  FieldCheckboxesValueType,
  FieldCheckboxValueType,
  FieldConfig,
  FieldCurrencyComponentProps,
  FieldCurrencyValueType,
  FieldDateComponentProps,
  FieldDateValueType,
  FieldGroupSelectComponentProps,
  FieldGroupSelectValueType,
  FieldMultiSelectComponentProps,
  FieldMultiSelectValueType,
  FieldNumberComponentProps,
  FieldNumberValueType,
  FieldPillSelectComponentProps,
  FieldPillSelectValueType,
  FieldRadioButtonsComponentProps,
  FieldRadioButtonsValueType,
  FieldRichTextComponentProps,
  FieldRichTextValueType,
  FieldSelectComponentProps,
  FieldSelectGroupHeader,
  FieldSelectGroupItem,
  FieldSelectOptionItem,
  FieldSelectValueType,
  FieldTextAreaComponentProps,
  FieldTextAreaValueType,
  FieldTextComponentProps,
  FieldTextValueType,
  FieldTieredSelectComponentProps,
  FieldTieredSelectValueType,
  FormCheckboxesProps,
  FormCheckboxProps,
  FormContextAPI,
  FormCurrencyProps,
  FormDateProps,
  FormErrorBannerProps,
  FormFieldAPI,
  FormFieldProps,
  FormFieldValueComponentProps,
  FormGroupSelectProps,
  FormMultiSelectProps,
  FormNumberProps,
  FormPillSelectProps,
  FormProps,
  FormRadioButtonsProps,
  FormRichTextProps,
  FormSelectProps,
  FormTextAreaProps,
  FormTextProps,
  FormTieredSelectProps,
  GroupedOptionsConfig,
  OptionList,
  TraditionalBaseFieldProps,
  View,
  ViewFieldProps,
} from './Form.types'
import { FormFieldTooltip } from './FormFieldTooltip'
import {
  StyledFormikForm,
  StyledTraditionalFormLabel,
} from './StyledFormikForm.styles'

const emptyObject = {} as any
const emptyArray = [] as any
function noop() {}
const isFunction = (obj: any): boolean => typeof obj === 'function'

function getValueComponent<Value>(
  view: View,
  asComponent: any
): React.FunctionComponent<FormFieldValueComponentProps<Value>> {
  return asComponent[view] || asComponent || TextInput
}

const SharedPropsContext = React.createContext(emptyObject)

export const FormContext = React.createContext<FormContextAPI>({
  different: false,
  disabled: false,
  enableReinitialize: false,
  setFieldDifferent: (k, d) => {},
  variant: 'wxp',
  view: 'create',
})

export function useFormContext<Values = any>(): FormikContextType<Values> &
  FormContextAPI {
  const formik = useFormikContext<Values>()
  const formulaire = React.useContext(FormContext)
  return { ...formik, ...formulaire }
}

export function getPrimitiveValue(
  value: any,
  getId = defaultGetId
): string | number {
  if (value === null || value === undefined) {
    return ''
  }
  if (value instanceof Date) {
    return value.toISOString()
  }
  if (is(Array, value)) {
    return value.map(getId).join()
  }
  if (is(Object, value)) {
    if (isEmpty(value)) {
      return ''
    }
    return getId(value)
  }
  return value
}

export function useField<Value = any>({
  disabled: _disabled,
  error: _error = false,
  getId,
  required: _required = false,
  name,
  validate,
  view: _view,
}: FieldConfig = emptyObject): FormFieldAPI<Value> {
  const [input, meta, helpers] = useFormikField<Value>({ name, validate })
  const { disabled, setFieldDifferent, validationSchema, view } =
    React.useContext(FormContext)
  const { validateOnBlur, validateOnChange } = useFormikContext()

  const requiredInSchema = React.useMemo(() => {
    const fieldSchema = validationSchema?.describe().fields[name]
    return (
      fieldSchema?.tests.findIndex(
        (test: { name: string }) => test.name === 'required'
      ) > -1
    )
  }, [validationSchema, name])

  const initialPrimitiveValue = React.useMemo(() => {
    return getPrimitiveValue(meta.initialValue, getId)
  }, [meta.initialValue, getId])

  const onChange: FormFieldAPI<Value>['input']['onChange'] = React.useCallback(
    (event: React.ChangeEvent<any>) => {
      setFieldDifferent(
        name,
        initialPrimitiveValue !== getPrimitiveValue(event.target.value, getId)
      )
      input.onChange(event)
    },
    [
      initialPrimitiveValue,
      input.onChange,
      getPrimitiveValue,
      getId,
      setFieldDifferent,
    ]
  )

  const setValue = React.useCallback(
    (
      value: Value,
      shouldValidate: boolean | undefined,
      shouldMarkDifferent = true
    ) => {
      if (shouldMarkDifferent) {
        setFieldDifferent(
          name,
          initialPrimitiveValue !== getPrimitiveValue(value, getId)
        )
      }

      helpers.setValue(value, shouldValidate)
    },
    [
      initialPrimitiveValue,
      helpers.setValue,
      getPrimitiveValue,
      getId,
      setFieldDifferent,
    ]
  )

  return {
    input: {
      ...input,
      onChange,
    },
    helpers: {
      ...helpers,
      setValue,
    },
    messages: {
      error: typeof _error === 'string' ? _error : meta.error,
    },
    meta: {
      ...meta,
      error:
        validateOnBlur || validateOnChange
          ? (meta.error && meta.touched) || Boolean(_error)
          : Boolean(meta.error) || Boolean(_error),
      disabled: _disabled ?? disabled,
      required: requiredInSchema || _required,
      view: _view || view,
    },
  }
}

const onBeforeUnload: Window['onbeforeunload'] = (e) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
  e.preventDefault()
  e.returnValue = ''
}

const useDifferent = () => {
  const [differentObject, setDifferentObject] =
    React.useState<Record<string, boolean>>(emptyObject)

  const setFieldDifferent = (name: string, isDiff: boolean) => {
    if (isDiff !== differentObject[name]) {
      // HACK: Do not change. setTimeout seemingly allows fields to update their
      // state before causing context re-render. This is specific to TinyMCE behavior.
      setTimeout(() =>
        setDifferentObject((prev) => ({
          ...prev,
          [name]: isDiff,
        }))
      )
    }
  }

  const resetDifferent = () => setDifferentObject(emptyObject)

  const different = React.useMemo(() => {
    return Object.values(differentObject).some(identity)
  }, [differentObject])

  return { different, resetDifferent, setFieldDifferent }
}

function Form_<Values = any>({
  initialValues = emptyObject,
  onSubmit: _onSubmit = noop,
  disabled = false,
  enableConfirmNavigation = false,
  variant = 'wxp',
  validationSchema,
  view = 'create',
  ...props
}: FormProps<Values>) {
  const { different, resetDifferent, setFieldDifferent } = useDifferent()
  const { setFormIsDifferent } = useCloseWithConfirmContext()

  React.useEffect(() => {
    if (enableConfirmNavigation) {
      if (different && (view === 'create' || view === 'update')) {
        window.onbeforeunload = onBeforeUnload
        setFormIsDifferent(true)
      } else if (!different && (view === 'create' || view === 'update')) {
        window.onbeforeunload = null
        setFormIsDifferent(false)
      }
    }
  }, [enableConfirmNavigation, different, view])

  const reset = () => {
    if (enableConfirmNavigation) {
      window.onbeforeunload = null
    }
    resetDifferent()
  }

  const onSubmit: FormikConfig<Values>['onSubmit'] = (values, actions) => {
    const promiseOrUndefined = _onSubmit(values, actions)

    if (promiseOrUndefined === undefined) {
      return
    }

    return promiseOrUndefined
      .then((result) => {
        reset()
        return result
      })
      .catch((result) => {
        return result
      })
  }

  return (
    <FormContext.Provider
      value={{
        different,
        disabled,
        setFieldDifferent,
        validationSchema,
        variant,
        view,
        enableReinitialize: props.enableReinitialize,
      }}
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          validateOnBlur: false,
          validateOnChange: false,
          ...props,
        }}
      >
        {isFunction(props.children) ? props.children : <>{props.children}</>}
      </Formik>
    </FormContext.Provider>
  )
}

export function FormForm({
  children,
  className,
  onKeyDown,
  style,
  ...props
}: React.ComponentPropsWithoutRef<typeof FormikForm>) {
  const { view } = React.useContext(FormContext)

  if (view === 'create' || view === 'update') {
    const preventFormSubmission = (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (
        e.key === 'Enter' &&
        e.target instanceof Element &&
        (e.target.tagName === 'INPUT' ||
          e.target.tagName === 'DIV' ||
          e.target.tagName === 'SPAN')
      ) {
        e.stopPropagation()
        e.preventDefault()
      }
      onKeyDown && onKeyDown(e)
    }

    return (
      <StyledFormikForm
        children={children}
        className={className}
        onKeyDown={preventFormSubmission}
        style={style}
        {...props}
      />
    )
  }
  return <StyledForm children={children} className={className} style={style} />
}

export function FormErrorBanner({
  item,
  i18nScope = 'core.form.errorBanner',
  ...rest
}: FormErrorBannerProps & DivAttributes) {
  const context = useFormContext()
  const I18n = useI18nContext()
  const { errors, touched } = context
  const someErrorsTouched =
    context.validateOnBlur || context.validateOnChange
      ? Object.keys(errors).some((errorField) => touched[errorField])
      : true
  if (
    (context.view === 'create' || context.view === 'update') &&
    !isEmpty(errors) &&
    someErrorsTouched
  ) {
    return (
      <ErrorBanner role="alert" {...rest}>
        <Banner.Content>
          <Banner.Title>
            {context.view === 'create'
              ? I18n.t('coundNotCreateItem', { item, scope: i18nScope })
              : I18n.t('couldNotUpdateItem', { item, scope: i18nScope })}
          </Banner.Title>
          <Banner.Body>
            {context.view === 'create'
              ? I18n.t('fixErrorsToCreate', { scope: i18nScope })
              : I18n.t('fixErrorsToUpdate', { scope: i18nScope })}
          </Banner.Body>
        </Banner.Content>
      </ErrorBanner>
    )
  }
  return null
}

export function isFormFieldEmpty(val: any) {
  return (
    val === undefined ||
    val === null ||
    val.length === 0 ||
    (is(Object, val) && isEmpty(val))
  )
}

export function WXPField<
  Value,
  ComponentProps extends FormFieldValueComponentProps<Value>
>({
  as: AsComponent,
  children,
  colStart = 1,
  colWidth = 6,
  disabled,
  description,
  error,
  label,
  name,
  required,
  tooltip,
  validate,
  view,
  inlineLabel: singleCheckboxInlineLabel,
  inlineDescription: singleCheckboxInlineDescription,
  ...props
}: BaseFieldProps<Value, ComponentProps> &
  Pick<FormCheckboxProps, 'inlineLabel' | 'inlineDescription'> & {
    getId?: OptionList<Value>['getId']
  }) {
  const i18n = useI18nContext()
  const field = useField<Value>({
    disabled,
    error,
    getId: props.getId,
    name,
    required,
    validate,
    view,
  })
  const ValueComponent = getValueComponent<Value>(field.meta.view, AsComponent)
  const labelId = useId()
  const fieldErrorId = useId()
  const descriptionId = useId()
  const inlineDescriptionId = useId()

  const hasRequiredMark =
    field.meta.required &&
    (field.meta.view === 'create' || field.meta.view === 'update')

  const errorDescribedBy =
    field.meta.error && field.messages.error ? fieldErrorId : undefined
  const descriptionDescribedBy = description ? descriptionId : undefined
  const inlineDescriptionDescribedBy = singleCheckboxInlineDescription
    ? inlineDescriptionId
    : undefined
  const valueComponentAriaProps = {
    'aria-invalid': field.meta.error ? true : undefined,
    'aria-labelledby': label ? labelId : undefined,
    'aria-describedby':
      [errorDescribedBy, descriptionDescribedBy, inlineDescriptionDescribedBy]
        .filter((value) => value)
        .join(' ') || undefined,
  }

  // Below variables is needed only for SingleCheckbox
  // only SingleCheckbox has inlineLabel
  // at least one label must be present
  // but if not we want to render requiredMark and tooltip at FieldHeader
  const hasLabelOrDoesntHaveBoth =
    label || (!label && !singleCheckboxInlineLabel)
  const hasInlineLabelAndDoesntHaveLabel = singleCheckboxInlineLabel && !label
  const singleCheckboxComponentProps = hasInlineLabelAndDoesntHaveLabel
    ? {
        tooltip,
        hasRequiredMark,
        inlineLabel: singleCheckboxInlineLabel,
      }
    : {
        inlineLabel: singleCheckboxInlineLabel,
      }

  return (
    <>
      <StyledFormFieldHeader
        $colIeSpan={colWidth}
        $colStart={colStart}
        $colEnd={colStart + colWidth}
      >
        {label && (
          <StyledLabel id={labelId} htmlFor={name}>
            {label}
          </StyledLabel>
        )}
        {hasLabelOrDoesntHaveBoth && (
          <>
            {hasRequiredMark && <StyledFormFieldRequiredMark />}
            {tooltip && <FormFieldTooltip overlay={tooltip} />}
          </>
        )}
        {description && (
          <StyledDescription id={descriptionId}>
            {description}
          </StyledDescription>
        )}
      </StyledFormFieldHeader>
      <StyledFormFieldMain
        $colIeSpan={colWidth}
        $colStart={colStart}
        $colEnd={colStart + colWidth}
        $read={field.meta.view === 'read'}
      >
        {children ? (
          isFunction(children) ? (
            (children as Function)(field)
          ) : (
            children
          )
        ) : (
          <ValueComponent
            {...valueComponentAriaProps}
            {...props}
            {...singleCheckboxComponentProps}
            field={field}
          />
        )}
        {singleCheckboxInlineDescription && (
          <StyledCheckboxInlineDescription
            id={inlineDescriptionId}
            $read={field.meta.view === 'read'}
          >
            {singleCheckboxInlineDescription}
          </StyledCheckboxInlineDescription>
        )}
        <StyledFormFieldBanner>
          {field.meta.error && (
            <Box marginRight="xs">
              <StyledFormFieldErrorIcon size="sm" />
            </Box>
          )}
          {field.meta.error && field.messages.error && (
            <Typography
              id={fieldErrorId}
              color="red50"
              intent="small"
              style={{ alignSelf: 'center' }}
            >
              {field.messages.error}
            </Typography>
          )}
        </StyledFormFieldBanner>
      </StyledFormFieldMain>
    </>
  )
}

export function TraditionalField<
  Value,
  ComponentProps extends FormFieldValueComponentProps<Value>
>({
  as: AsComponent,
  children,
  colWidth = 6,
  disabled,
  error,
  label,
  name,
  required,
  tooltip,
  validate,
  view,
  ...props
}: TraditionalBaseFieldProps<Value, ComponentProps> & {
  getId?: OptionList<Value>['getId']
}) {
  const field = useField<Value>({
    disabled,
    error,
    getId: props.getId,
    name,
    required,
    validate,
    view,
  })
  const ValueComponent = getValueComponent<Value>(field.meta.view, AsComponent)
  const labelId = useId()
  const i18n = useI18nContext()

  const valueComponentAriaProps = {
    'aria-invalid': field.meta.error ? true : undefined,
    'aria-labelledby': label ? labelId : undefined,
  }

  return (
    <>
      <StyledTraditionalFormColumn>
        {label && (
          <>
            <StyledTraditionalFormLabel $view={field.meta.view}>
              <label id={labelId} htmlFor={name}>
                {label}:
              </label>
            </StyledTraditionalFormLabel>
            {(field.meta.view === 'create' || field.meta.view === 'update') &&
              field.meta.required && <StyledFormFieldRequiredMark />}

            {tooltip && (
              <FormFieldTooltip overlay={tooltip} placement="right" />
            )}
          </>
        )}
      </StyledTraditionalFormColumn>
      <StyledTraditionalFormColumn
        $fullWidth={colWidth === 12}
        $output={field.meta.view === 'read'}
      >
        {children ? (
          isFunction(children) ? (
            (children as Function)(field)
          ) : (
            children
          )
        ) : (
          <ValueComponent
            {...valueComponentAriaProps}
            {...props}
            field={field}
          />
        )}
      </StyledTraditionalFormColumn>
    </>
  )
}

export function Row({ children, ...props }: Props) {
  const { variant, view } = React.useContext(FormContext)

  return (
    <StyledFormRow
      $traditional={variant === 'traditional'}
      $read={view === 'read'}
      {...props}
    >
      {children}
    </StyledFormRow>
  )
}

export function BaseField<
  Value,
  ComponentProps extends FormFieldValueComponentProps<Value>
>(
  props:
    | BaseFieldProps<Value, ComponentProps>
    | TraditionalBaseFieldProps<Value, ComponentProps>
) {
  const { variant } = React.useContext(FormContext)

  if (variant === 'traditional') {
    return (
      <TraditionalField<Value, ComponentProps>
        {...(props as TraditionalBaseFieldProps<Value, ComponentProps>)}
      />
    )
  } else {
    return <WXPField<Value, ComponentProps> {...props} />
  }
}

// MAKE

function makeSharedField<
  Value,
  FieldTypeProps extends FormFieldProps<Value>,
  ComponentProps extends FormFieldValueComponentProps<Value>
>(viewAs: BaseFieldProps<Value, ComponentProps>['as']) {
  return function Field({ children, ...props }: FieldTypeProps) {
    if (children) {
      return (
        <SharedPropsContext.Provider value={props}>
          {children}
        </SharedPropsContext.Provider>
      )
    }

    return (
      <BaseField<Value, ComponentProps> as={viewAs} {...props}>
        {children}
      </BaseField>
    )
  }
}

function makeViewField<
  Value,
  FieldTypeProps extends FormFieldProps<Value>,
  ComponentProps extends FormFieldValueComponentProps<Value>
>(viewAs: BaseFieldProps<Value, ComponentProps>['as'], when: View) {
  return function FieldView(
    props: ViewFieldProps<Value, FieldTypeProps, ComponentProps>
  ) {
    const { view } = React.useContext(FormContext)
    const commonProps = React.useContext(SharedPropsContext)

    if (when === view) {
      return (
        <BaseField<Value, ComponentProps>
          as={viewAs}
          {...commonProps}
          {...props}
        />
      )
    }
    return null
  }
}

export function makeField<
  Value,
  FieldTypeProps extends FormFieldProps<Value>,
  ComponentProps extends FormFieldValueComponentProps<Value>
>(
  editComponent: ComponentWithFieldProp<Value, ComponentProps>,
  showComponent: ComponentWithFieldProp<Value, ComponentProps>,
  options?: { emptyState: boolean }
) {
  const views = {
    create: editComponent,
    read:
      options && !options.emptyState
        ? showComponent
        : withDefaultEmptyState(showComponent),
    update: editComponent,
  }
  // @ts-ignore
  const Field = makeSharedField<Value, FieldTypeProps, ComponentProps>(views)
  const Create = makeViewField<Value, FieldTypeProps, ComponentProps>(
    // @ts-ignore
    views,
    'create'
  )
  const Read = makeViewField<Value, FieldTypeProps, ComponentProps>(
    // @ts-ignore
    views,
    'read'
  )
  const Update = makeViewField<Value, FieldTypeProps, ComponentProps>(
    // @ts-ignore
    views,
    'update'
  )

  return addSubcomponents(
    {
      Create,
      Read,
      Update,
    },
    Field
  )
}

/// FIELD TYPES

// @ts-ignore
const defaultGetLabel: OptionList['getLabel'] = either(
  prop('label'),
  prop('name')
)
const defaultGetId: OptionList['getId'] = prop('id')

const defaultGetGroup: OptionList['getId'] = prop('groupId')

// EMPTY STATE

const EmptyState = (props: any) => {
  return (
    <Typography
      aria-describedby={props['aria-describedby']}
      aria-labelledby={props['aria-labelledby']}
      intent="body"
      color="gray45"
      data-qa={conditionalStrings(
        {
          [`${props['data-qa']}`]: props['data-qa'],
        },
        `core-form-field-empty`
      )}
    >
      --
    </Typography>
  )
}

export function withDefaultEmptyState<
  Value,
  TProps extends FormFieldValueComponentProps
>(OutputComponent: ComponentWithFieldProp<Value, TProps>) {
  const WithDefaultEmptyState = React.forwardRef<HTMLSpanElement, TProps>(
    (props, ref) => {
      if (isFormFieldEmpty(props.field.input.value)) {
        return <EmptyState {...props} />
      }
      // ComponentWithFieldProp's third type of generic function raises an issue with the component possibly being 'undefined'
      // @ts-ignore
      return <OutputComponent ref={ref} {...props} />
    }
  )
  // @ts-ignore
  WithDefaultEmptyState.displayName = `FieldWithEmptyState`

  return WithDefaultEmptyState
}

// TEXT

export const TextInput = React.forwardRef<
  HTMLInputElement,
  FieldTextComponentProps
>(function TextInput(
  { onBlur: _onBlur, onChange: _onChange, field, ...props },
  ref
) {
  const onBlur: InputProps['onBlur'] = (e) => {
    field.input.onBlur(e)
    _onBlur && _onBlur(e)
  }

  const onChange: InputProps['onChange'] = (e) => {
    field.input.onChange(e)
    _onChange && _onChange(e)
  }

  return (
    <Input
      aria-required={field.meta.required}
      disabled={field.meta.disabled}
      error={field.meta.error}
      name={field.input.name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      value={field.input.value || ''}
      {...props}
    />
  )
})

export const TextOutput = React.forwardRef<
  HTMLSpanElement,
  FieldTextComponentProps
>(function TextOutput({ field, ...props }, ref) {
  return (
    <span ref={ref} {...props}>
      {field.input.value}
    </span>
  )
})

const FieldText = makeField<
  FieldTextValueType,
  FormTextProps,
  FieldTextComponentProps
>(TextInput, TextOutput)

// NUMBER

export const NumberInput = React.forwardRef<
  HTMLInputElement,
  FieldNumberComponentProps
>(function NumberInput(
  { onBlur: onBlur_, onChange: onChange_, field, ...props },
  ref
) {
  const onBlur: NumberInputProps['onBlur'] = (e) => {
    field.input.onBlur(e)
    onBlur_?.(e)
  }

  const onChange: NumberInputProps['onChange'] = React.useCallback(
    (valueChange: NumberInputValueChange) => {
      // field.helpers.setValue always updates. Using field.input.onChange instead.
      // setValue causes infinite loop with NumberInput changing locale logic
      // helpers difference reference each update https://github.com/formium/formik/issues/2268
      field.input.onChange({
        target: {
          name: field.input.name,
          value: valueChange.parsedNumber,
        },
      })
      onChange_?.(valueChange)
    },
    [onChange_, field.input.onChange]
  )

  return (
    <BaseNumberInput
      aria-required={field.meta.required}
      disabled={field.meta.disabled}
      error={field.meta.error}
      name={field.input.name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      value={field.input.value}
      {...props}
    />
  )
})

export const NumberOutput = React.forwardRef<
  HTMLSpanElement,
  FieldNumberComponentProps
>(function NumberOutput(
  {
    decimalScale,
    defaultValue,
    field,
    fillDecimalScale,
    locale: _locale,
    onChange,
    prefix,
    suffix,
    ...props
  },
  ref
) {
  const I18n = useI18nContext()
  const locale = _locale || I18n.locale || defaultLocale

  const { formatValue } = useNumberFormat({
    decimalScale,
    fillDecimalScale:
      fillDecimalScale === 'always' || fillDecimalScale === 'onBlur'
        ? 'always'
        : 'none',
    locale,
  })

  const value = formatValue(field.input.value)

  return (
    <span ref={ref} {...props}>
      {value && prefix ? prefix : null} {value}{' '}
      {value && suffix ? suffix : null}
    </span>
  )
})

const FieldNumber = makeField<
  FieldNumberValueType,
  FormNumberProps,
  FieldNumberComponentProps
>(NumberInput, NumberOutput)

// CURRENCY

export const CurrencyInput = React.forwardRef<
  HTMLInputElement,
  FieldCurrencyComponentProps
>(function CurrencyInput(
  { onBlur: onBlur_, onChange: onChange_, field, ...props },
  ref
) {
  const onBlur: NumberInputProps['onBlur'] = (e) => {
    field.input.onBlur(e)
    onBlur_?.(e)
  }

  const onChange: NumberInputProps['onChange'] = React.useCallback(
    (valueChange: NumberInputValueChange) => {
      // field.helpers.setValue always updates. Using field.input.onChange instead.
      // setValue causes infinite loop with NumberInput changing locale logic
      field.input.onChange({
        target: {
          name: field.input.name,
          value: valueChange.parsedNumber,
        },
      })
      onChange_?.(valueChange)
    },
    [onChange_, field.input.onChange]
  )

  return (
    <BaseCurrencyInput
      aria-required={field.meta.required}
      disabled={field.meta.disabled}
      error={field.meta.error}
      name={field.input.name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      value={field.input.value}
      {...props}
    />
  )
})

export const CurrencyOutput = React.forwardRef<
  HTMLSpanElement,
  FieldCurrencyComponentProps
>(function CurrencyOutput(
  {
    currencyIsoCode,
    currencyDisplay,
    decimalScale = defaultCurrencyDecimalScale,
    suffix,
    prefix = defaultCurrencyInputPrefix,
    ...props
  },
  ref
) {
  const i18n = useI18nContext()
  const locale = props.locale || i18n.locale || defaultLocale

  const numberProps = getCurrencyProps(
    locale as string,
    decimalScale,
    prefix || suffix,
    currencyIsoCode,
    currencyDisplay
  )

  return (
    <NumberOutput
      fillDecimalScale={defaultCurrencyFillDecimalScale}
      ref={ref}
      {...props}
      {...numberProps}
    />
  )
})

const FieldCurrency = makeField<
  FieldCurrencyValueType,
  FormCurrencyProps,
  FieldCurrencyComponentProps
>(CurrencyInput, CurrencyOutput)

// DATE

export const DateInput = React.forwardRef<
  HTMLInputElement,
  FieldDateComponentProps
>(function DateInput({ onChange: _onChange, field, ...props }, ref) {
  const value = field.input.value
    ? toDate(field.input.value, 'Form.DateSelect Create or Update Input')
    : undefined

  const onChange: DateSelectProps['onChange'] = (value) => {
    field.helpers.setValue(value)
    field.helpers.setTouched(true)
    _onChange && _onChange(value)
  }

  return (
    <DateSelect
      disabled={field.meta.disabled}
      error={field.meta.error}
      onChange={onChange}
      ref={ref}
      value={value}
      {...props}
    />
  )
})

export const DateOutput = React.forwardRef<
  HTMLSpanElement,
  FieldDateComponentProps
>(function DateOutput({ onChange, field, ...props }, ref) {
  const dateTime = useDateTime()

  const value = field.input.value
    ? dateTime.format(
        toDate(field.input.value, 'Form.DateSelect Read Output'),
        'numeric-date'
      )
    : undefined

  const machineValue = field.input.value
    ? formatMachineDate(dateTime.shiftUtcToZonedTime(toDate(field.input.value)))
    : undefined

  return (
    <span ref={ref} {...props}>
      {value && <time dateTime={machineValue}>{value}</time>}
    </span>
  )
})

const FieldDateSelect = makeField<
  FieldDateValueType,
  FormDateProps,
  FieldDateComponentProps
>(DateInput, DateOutput)

// CHECKBOX

export const CheckboxInput = React.forwardRef<
  HTMLInputElement,
  FieldCheckboxComponentProps
>(function CheckboxInput(
  {
    field,
    i18nScope,
    tooltip,
    hasRequiredMark,
    inlineLabel,
    onBlur: _onBlur,
    onChange: _onChange,
    ...props
  },
  ref
) {
  const value = field.input.value || false

  const onBlur: CheckboxProps['onBlur'] = (e) => {
    field.input.onBlur(e)
    _onBlur && _onBlur(e)
  }

  const onChange: CheckboxProps['onChange'] = (e) => {
    field.helpers.setValue(!value)
    _onChange && _onChange(e)
  }

  return (
    <Checkbox
      aria-required={field.meta.required}
      checked={value}
      disabled={field.meta.disabled}
      error={field.meta.error}
      onBlur={onBlur}
      onChange={onChange}
      name={field.input.name}
      ref={ref}
      value={String(value)}
      requiredMark={hasRequiredMark}
      tooltip={tooltip}
      {...props}
    >
      {inlineLabel}
    </Checkbox>
  )
})

export const CheckboxOutput = React.forwardRef<
  HTMLSpanElement,
  FieldCheckboxComponentProps
>(function CheckboxOutput(
  {
    field,
    tooltip,
    inlineLabel,
    i18nScope = 'core.form.checkbox',
    hasRequiredMark,
    ...props
  },
  ref
) {
  const i18n = useI18nContext()
  const yes = (
    <span
      style={{ verticalAlign: 'middle' }}
      aria-label={i18n.t('checked', {
        scope: i18nScope,
        // old default supports new key addition and backwards i18nScope support. changed 11.x
        defaultValue: i18n.t('yes', { scope: i18nScope }),
      })}
    >
      <Check size="sm" />
    </span>
  )
  const no = (
    <span
      style={{ verticalAlign: 'middle' }}
      aria-label={i18n.t('unchecked', {
        scope: i18nScope,
        defaultValue: i18n.t('no', { scope: i18nScope }),
      })}
    >
      <Ban size="sm" />
    </span>
  )

  return (
    <span ref={ref} {...props}>
      {field.input.value ? yes : no}
      {inlineLabel && (
        <>
          &nbsp;
          {inlineLabel}
        </>
      )}
      {tooltip && <FormFieldTooltip overlay={tooltip} />}
    </span>
  )
})

const FieldCheckbox = makeField<
  FieldCheckboxValueType,
  FormCheckboxProps,
  FieldCheckboxComponentProps
>(CheckboxInput, CheckboxOutput, { emptyState: false })

// TEXTAREA

export const TextAreaInput = React.forwardRef<
  HTMLTextAreaElement,
  FieldTextAreaComponentProps
>(function TextAreaInput(
  { field, onBlur: _onBlur, onChange: _onChange, ...props },
  ref
) {
  const onBlur: TextAreaProps['onBlur'] = (e) => {
    field.input.onBlur(e)
    _onBlur && _onBlur(e)
  }

  const onChange: TextAreaProps['onChange'] = (e) => {
    field.input.onChange(e)
    _onChange && _onChange(e)
  }

  return (
    <TextArea
      aria-required={field.meta.required}
      disabled={field.meta.disabled}
      error={field.meta.error}
      name={field.input.name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      value={field.input.value}
      {...props}
    />
  )
})

export const TextAreaOutput = React.forwardRef<
  HTMLSpanElement,
  FieldTextAreaComponentProps
>(function TextAreaOutput({ field, ...props }, ref) {
  return (
    <StyledFormOutputTextArea ref={ref} {...props}>
      {field.input.value}
    </StyledFormOutputTextArea>
  )
})

const FieldTextArea = makeField<
  FieldTextAreaValueType,
  FormTextAreaProps,
  FieldTextAreaComponentProps
>(TextAreaInput, TextAreaOutput)

// RICHTEXT

export const RichTextInput = React.forwardRef<
  HTMLTextAreaElement,
  FieldRichTextComponentProps
>(function RichTextInput({ onChange: _onChange, field, ...props }, ref) {
  const onChange: TextEditorProps['onChange'] = (value, isDirty) => {
    field.helpers.setValue(value, false, isDirty)

    if (isDirty) {
      field.helpers.setTouched(true)
    }

    _onChange?.(value, isDirty)
  }

  return (
    <TextEditor
      disabled={field.meta.disabled}
      error={field.meta.error}
      value={field.input.value}
      onChange={onChange}
      {...props}
    />
  )
})

export const RichTextOutput = React.forwardRef<
  HTMLDivElement,
  FieldRichTextComponentProps & Props
>(function RichTextOutput(
  {
    field,
    onBlur,
    onChange,
    onClick,
    onContextMenu,
    onCopy,
    onCut,
    onDrag,
    onDragDrop,
    onDragEnd,
    onDragGesture,
    onDragOver,
    onDrop,
    onFocus,
    onKeyDown,
    onKeyPress,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onMouseOut,
    onMouseOver,
    onMouseUp,
    onPaste,
    onReset,
    onSubmit,
    ...props
  },
  ref
) {
  return <TextEditorOutput value={field.input.value} ref={ref} {...props} />
})

const FieldRichText = makeField<
  FieldRichTextValueType,
  FormRichTextProps,
  FieldRichTextComponentProps
>(RichTextInput, RichTextOutput)

// RADIO BUTTONS

export const RadioButtonsInput = React.forwardRef<
  HTMLFieldSetElement,
  FieldRadioButtonsComponentProps
>(function RadioButtonsInput(
  {
    field,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    isDisabledOption,
    options,
    ...props
  },
  ref
) {
  if (!options || options.length === 0) return null

  const onSelect = (option: (typeof options)[number]) => () => {
    field.helpers.setValue(option)
  }

  return (
    <StyledFormOutputFiledset
      ref={ref}
      disabled={field.meta.disabled}
      {...props}
    >
      {options.map((option) => {
        return (
          <RadioButton
            checked={getId(option) === getId(field.input.value)}
            disabled={isDisabledOption && isDisabledOption(option)}
            error={field.meta.error}
            key={getId(option)}
            name={field.input.name}
            onChange={onSelect(option)}
            value={getId(option)}
          >
            {getLabel(option)}
          </RadioButton>
        )
      })}
    </StyledFormOutputFiledset>
  )
})

export const RadioButtonsOutput = React.forwardRef<
  HTMLSpanElement,
  FieldRadioButtonsComponentProps
>(function RadioButtonsOutput(
  { field, getId, getLabel = defaultGetLabel, options, ...props },
  ref
) {
  return (
    <span ref={ref} {...props}>
      {getLabel(field.input.value)}
    </span>
  )
})

const FieldRadioButtons = makeField<
  FieldRadioButtonsValueType,
  FormRadioButtonsProps,
  FieldRadioButtonsComponentProps
>(RadioButtonsInput, RadioButtonsOutput)

// CHECKBOXES

export const CheckboxesInput = React.forwardRef<
  HTMLFieldSetElement,
  FieldCheckboxesComponentProps
>(function CheckboxesInput(
  {
    field,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    isDisabledOption,
    isIndeterminateOption,
    options,
    ...props
  },
  ref
) {
  if (!options || options.length === 0) return null

  const selectedIds = (field.input.value || emptyArray).map(getId)

  const onSelect = (changedOption: (typeof options)[number]) => () => {
    if (selectedIds.includes(getId(changedOption))) {
      field.helpers.setValue(
        field.input.value.filter(
          (entry) => getId(entry) !== getId(changedOption)
        )
      )
    } else {
      field.helpers.setValue(
        (field.input.value || emptyArray).concat(changedOption)
      )
    }
  }

  return (
    <StyledFormOutputFiledset
      ref={ref}
      disabled={field.meta.disabled}
      {...props}
    >
      {options.map((option) => {
        return (
          <Checkbox
            checked={selectedIds.includes(getId(option))}
            disabled={isDisabledOption && isDisabledOption(option)}
            error={field.meta.error}
            indeterminate={
              isIndeterminateOption && isIndeterminateOption(option)
            }
            key={getId(option)}
            name={field.input.name}
            onChange={onSelect(option)}
            value={getId(option)}
          >
            {getLabel(option)}
          </Checkbox>
        )
      })}
    </StyledFormOutputFiledset>
  )
})

export const CheckboxesOutput = React.forwardRef<
  HTMLSpanElement,
  FieldCheckboxesComponentProps
>(function CheckboxesOutput(
  {
    field,
    getId,
    getLabel = defaultGetLabel,
    isDisabledOption,
    isIndeterminateOption,
    options,
    ...props
  },
  ref
) {
  return (
    <span ref={ref} {...props}>
      {field.input.value && field.input.value.map(getLabel).join(', ')}
    </span>
  )
})

const FieldCheckboxes = makeField<
  FieldCheckboxesValueType,
  FormCheckboxesProps,
  FieldCheckboxesComponentProps
>(CheckboxesInput, CheckboxesOutput)

// SELECT

const lowerStartsWith = (query: string, value = '') =>
  startsWith(toLower(query), toLower(value))

export function useOptions<OptionItem = any, GroupItem = any>({
  value,
  optgroups,
  options = emptyArray,
  getLabel,
  getId,
  groupGetId,
  getGroup = defaultGetGroup,
  comparator = lowerStartsWith,
}: GroupedOptionsConfig<OptionItem, GroupItem>) {
  const [query, setQuery] = React.useState('')
  const search = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(e.target.value)

  const filteredOptions = React.useMemo(() => {
    if (!query) {
      return options
    }
    return options.filter((option) => comparator(query, getLabel(option)))
  }, [comparator, query, options, getLabel])

  const groupedOptions = React.useMemo(() => {
    if (!optgroups || !optgroups.length) {
      return filteredOptions
    }

    const optionsByGroup: Record<number | string, OptionItem[]> =
      optgroups.reduce((acc, group) => {
        return {
          ...acc,
          [groupGetId(group)]: [],
        }
      }, {})

    const orphanOptions = [] as OptionItem[]

    filteredOptions.forEach((option) => {
      const groupId = getGroup(option)
      const groupOptions = optionsByGroup[groupId]

      if (!groupId || !groupOptions) {
        orphanOptions.push(option)
        return
      }

      optionsByGroup[groupId].push(option)
    })

    if (orphanOptions.length) {
      console.warn(
        `Could not find option groups for the options below. Make sure that every option has a proper "groupId" field or if "getGroup" is implemented correctly.`,
        orphanOptions
      )
    }

    return optgroups.flatMap((group: GroupItem) => {
      const groupHeader = {
        ...group,
        isGroupHeader: true,
      } as FieldSelectGroupHeader
      const groupOptions = optionsByGroup[groupGetId(group)]

      if (!groupOptions.length) {
        return []
      }

      return [groupHeader, ...groupOptions]
    }, [])
  }, [filteredOptions, getGroup, groupGetId, optgroups])

  const selected =
    options.find((option) => value && getId(option) === getId(value)) || value

  return { list: groupedOptions, selected, search }
}

function defaultOptionRenderer<OptionItem = FieldSelectOptionItem>(
  item: OptionItem,
  {
    getId,
    getLabel,
    ...props
  }: SelectOptionProps & {
    getId: OptionList<OptionItem>['getId']
    getLabel: OptionList<OptionItem>['getLabel']
  }
) {
  return <Select.Option {...props}>{getLabel(item)}</Select.Option>
}

function defaultGroupHeaderRenderer<GroupItem = FieldSelectGroupItem>(
  group: GroupItem,
  {
    getId,
    getLabel,
    ...props
  }: {
    getId: OptionList<GroupItem>['getId']
    getLabel: OptionList<GroupItem>['getLabel']
  }
) {
  return <Select.OptGroup {...props}>{getLabel(group)}</Select.OptGroup>
}

export const SelectInput = React.forwardRef<
  HTMLDivElement,
  FieldSelectComponentProps
>(function SelectInput(
  {
    afterHide: _afterHide,
    field,
    getGroup = defaultGetGroup,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    groupGetId = defaultGetId,
    groupGetLabel = defaultGetLabel,
    groupHeaderRenderer = defaultGroupHeaderRenderer,
    isSuggestedOption,
    onClear: _onClear,
    onSearch: _onSearch,
    onSelect: _onSelect,
    searchComparator,
    options,
    optgroups,
    optionRenderer = defaultOptionRenderer,
    ...props
  },
  ref
) {
  const menu = useOptions({
    options,
    optgroups,
    getGroup,
    getId,
    getLabel,
    groupGetId,
    value: field.input.value,
    comparator: searchComparator,
  })

  const label = menu.selected ? getLabel(menu.selected) : undefined

  const afterHide: SelectProps['afterHide'] = () => {
    field.helpers.setTouched(true)
    _afterHide && _afterHide()
  }

  const onSelect: SelectProps['onSelect'] = (selection) => {
    field.helpers.setValue(selection.item)
    _onSelect && _onSelect(selection)
  }

  const onClear: SelectButtonProps['onClear'] = (e) => {
    field.helpers.setValue(null)
    if (typeof _onClear === 'function') {
      _onClear(e)
    }
  }

  const onSearch: SelectProps['onSearch'] = (e) => {
    menu.search(e)
    if (typeof _onSearch === 'function') {
      _onSearch(e)
    }
  }

  return (
    <Select
      afterHide={afterHide}
      block
      disabled={field.meta.disabled}
      error={field.meta.error}
      label={label}
      onClear={_onClear || _onClear === undefined ? onClear : undefined}
      onSearch={
        _onSearch || _onSearch === undefined || searchComparator
          ? onSearch
          : undefined
      }
      onSelect={onSelect}
      ref={ref}
      {...props}
    >
      {menu.list.map((option) => {
        if (option.isGroupHeader) {
          return groupHeaderRenderer(option, {
            getId: groupGetId,
            getLabel: groupGetLabel,
            // @ts-ignore
            // This property is React specific, not a prop on the component renderer
            key: `group_${groupGetId(option)}`,
          })
        }

        return optionRenderer(option, {
          getId,
          getLabel,
          // @ts-ignore
          // This property is React specific, not a prop on the component renderer
          // Object literal may only specify known properties, and 'key' does not exist in type 'OptionProps'.
          key: getId(option),
          selected: getId(menu.selected) === getId(option),
          suggested: isSuggestedOption && isSuggestedOption(option),
          value: option,
        })
      })}
    </Select>
  )
})

export const SelectOutput = React.forwardRef<
  HTMLSpanElement,
  FieldSelectComponentProps
>(function SelectOutput(
  {
    afterHide,
    afterShow,
    beforeHide,
    beforeShow,
    field,
    footer,
    getId,
    getGroup,
    getLabel = defaultGetLabel,
    groupGetId,
    groupGetLabel,
    groupHeaderRenderer,
    i18nScope,
    isSuggestedOption,
    options,
    optgroups,
    optionRenderer,
    searchComparator,
    onBlur,
    onClear,
    onSearch,
    onSelect,
    ...props
  },
  ref
) {
  return (
    <span ref={ref} {...props}>
      {getLabel(field.input.value)}
    </span>
  )
})

const FieldSelect = makeField<
  FieldSelectValueType,
  FormSelectProps,
  FieldSelectComponentProps
>(SelectInput, SelectOutput)

// PILL SELECT

export const PillSelectInput = React.forwardRef<
  HTMLDivElement,
  FieldPillSelectComponentProps
>(function PillSelectInput(
  {
    field,
    onBlur: _onBlur,
    onClear: _onClear,
    onSelect: _onSelect,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    ...props
  },
  ref
) {
  const onBlur: PillSelectProps['onBlur'] = (e) => {
    field.helpers.setTouched(true)
    _onBlur && _onBlur(e)
  }

  const onClear: PillSelectProps['onClear'] = (e) => {
    field.helpers.setValue(null)
    _onClear && _onClear(e)
  }

  const onSelect: PillSelectProps['onSelect'] = (selection) => {
    field.helpers.setValue(selection.item)
    _onSelect && _onSelect(selection)
  }

  return (
    <PillSelect
      block
      disabled={field.meta.disabled}
      error={field.meta.error}
      getId={getId}
      getLabel={getLabel}
      onBlur={onBlur}
      onClear={_onClear || _onClear === undefined ? onClear : undefined}
      onSelect={onSelect}
      ref={ref}
      value={field.input.value}
      {...props}
    />
  )
})

export const PillSelectOutput = React.forwardRef<
  HTMLSpanElement,
  FieldPillSelectComponentProps
>(function PillSelectOutput(
  {
    afterHide,
    afterShow,
    beforeHide,
    beforeShow,
    color,
    field,
    footer,
    getColor = defaultGetColor,
    getGroup,
    getLabel = defaultGetLabel,
    getSuggested,
    groupGetId,
    groupGetLabel,
    i18nScope,
    onClear,
    onSearch,
    onSelect,
    options,
    optgroups,
    ...props
  },
  ref
) {
  return (
    <Pill color={getColor(field.input.value)} {...props}>
      {getLabel(field.input.value)}
    </Pill>
  )
})

const FieldPillSelect = makeField<
  FieldPillSelectValueType,
  FormPillSelectProps,
  FieldPillSelectComponentProps
>(PillSelectInput, PillSelectOutput)

// MULTISELECT

export const MultiSelectInput = React.forwardRef<
  HTMLDivElement,
  FieldMultiSelectComponentProps
>(function MultiSelectInput(
  {
    field,
    onBlur: _onBlur,
    onChange: _onChange,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    ...props
  },
  ref
) {
  const onBlur: MultiSelectProps['onBlur'] = (e) => {
    field.helpers.setTouched(true)
    _onBlur && _onBlur(e)
  }

  const onChange: MultiSelectProps['onChange'] = (value, e) => {
    field.helpers.setValue(value)
    _onChange && _onChange(value, e)
  }

  return (
    <MultiSelect
      block
      disabled={field.meta.disabled}
      error={field.meta.error}
      getId={getId}
      getLabel={getLabel}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      value={field.input.value}
      {...props}
    />
  )
})

export const MultiSelectOutput = React.forwardRef<
  HTMLSpanElement,
  FieldMultiSelectComponentProps
>(function MultiSelectOutput(
  {
    afterHide,
    afterShow,
    beforeHide,
    beforeShow,
    emptyMessage,
    field,
    getGroup,
    getId,
    getLabel = defaultGetLabel,
    groupHeaderRenderer,
    isOptionDisabled,
    onChange,
    onSearch,
    onScrollBottom,
    options,
    optgroups,
    optionRenderer,
    placement,
    placeholder,
    tokenRenderer,
    qa,
    ...props
  },
  ref
) {
  return (
    <span ref={ref} {...props}>
      {field.input.value && field.input.value.map(getLabel).join(', ')}
    </span>
  )
})

const FieldMultiSelect = makeField<
  FieldMultiSelectValueType,
  FormMultiSelectProps,
  FieldMultiSelectComponentProps
>(MultiSelectInput, MultiSelectOutput)

// GROUP SELECT

export const GroupSelectInput = React.forwardRef<
  HTMLDivElement,
  FieldGroupSelectComponentProps
>(function GroupSelectInput(
  {
    field,
    onBlur: _onBlur,
    onChange: _onChange,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    ...props
  },
  ref
) {
  const onBlur: GroupSelectProps['onBlur'] = (e) => {
    field.helpers.setTouched(true)
    _onBlur && _onBlur(e)
  }

  const onChange: GroupSelectProps['onChange'] = (value, e) => {
    field.helpers.setValue(value)
    _onChange && _onChange(value, e)
  }

  return (
    <GroupSelect
      block
      disabled={field.meta.disabled}
      error={field.meta.error}
      getId={getId}
      getLabel={getLabel}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      value={field.input.value}
      {...props}
    />
  )
})

export const GroupSelectOutput = React.forwardRef<
  HTMLSpanElement,
  FieldGroupSelectComponentProps
>(function GroupSelectOutput(
  {
    afterHide,
    afterShow,
    beforeHide,
    beforeShow,
    emptyMessage,
    field,
    getGroup,
    getId,
    getLabel = defaultGetLabel,
    getOptGroup,
    groupRenderer,
    groups,
    isOptionDisabled,
    placeholder,
    placement,
    onChange,
    onScrollBottom,
    onSearch,
    optgroups,
    options,
    optionRenderer,
    optGroupRenderer,
    tokenRenderer,
    qa,
    ...props
  },
  ref
) {
  return (
    <span ref={ref} {...props}>
      {field.input.value && field.input.value.map(getLabel).join(', ')}
    </span>
  )
})

const FieldGroupSelect = makeField<
  FieldGroupSelectValueType,
  FormGroupSelectProps,
  FieldGroupSelectComponentProps
>(GroupSelectInput, GroupSelectOutput)

// TIERED SELECT

export const TieredSelectInput = React.forwardRef<
  HTMLDivElement,
  FieldTieredSelectComponentProps
>(function TieredSelectInput(
  {
    field,
    onBlur: _onBlur,
    onChange: _onChange,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    ...props
  },
  ref
) {
  const onBlur: TieredSelectProps['onBlur'] = (e) => {
    field.helpers.setTouched(true)
    _onBlur && _onBlur(e)
  }

  const onChange: TieredSelectProps['onChange'] = (selection) => {
    field.helpers.setValue(selection.value)
    _onChange && _onChange(selection)
  }

  return (
    <TieredSelect
      block
      disabled={field.meta.disabled}
      error={field.meta.error}
      getId={getId}
      getLabel={getLabel}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      value={field.input.value}
      {...props}
    />
  )
})

export const TieredSelectOutput = React.forwardRef<
  HTMLSpanElement,
  FieldTieredSelectComponentProps
>(function TieredSelectOutput(
  {
    afterHide,
    afterShow,
    beforeHide,
    beforeShow,
    field,
    getId,
    getLabel = defaultGetLabel,
    getGroupId,
    getNextGroupId,
    getValueString,
    i18nScope,
    isLeaf,
    isTierDisabled,
    loadingMore,
    onChange,
    onClear,
    onNavigate,
    onScrollBottom,
    onSearch,
    onQuickCreate,
    options,
    selectableTiers,
    ...props
  },
  ref
) {
  return (
    <span ref={ref} {...props}>
      {field.input.value &&
        field.input.value.map((tier) => getLabel(tier)).join(' > ')}
    </span>
  )
})

const FieldTieredSelect = makeField<
  FieldTieredSelectValueType,
  FormTieredSelectProps,
  FieldTieredSelectComponentProps
>(TieredSelectInput, TieredSelectOutput)

const FieldAny = makeField<
  any,
  FormFieldProps<any> & { [key: string]: unknown },
  FormFieldValueComponentProps<any> & InputProps
>(TextInput, TextOutput)

const SettingsPageFooter = React.forwardRef<HTMLDivElement, PageFooterProps>(
  function Footer(props, ref) {
    const formik = React.useContext(FormikContext)
    const [isVisible, setIsVisible] = React.useState(formik?.dirty !== false)

    React.useEffect(() => {
      if (formik?.dirty) {
        setIsVisible(true)
      }
    }, [formik?.dirty])

    return <SettingsPage.Footer ref={ref} visible={isVisible} {...props} />
  }
)

export const Form = addSubcomponents(
  {
    Checkboxes: FieldCheckboxes,
    Checkbox: FieldCheckbox,
    Currency: FieldCurrency,
    DateSelect: FieldDateSelect,
    ErrorBanner: FormErrorBanner,
    Field: FieldAny,
    /**
     * `Form.Form` is a small wrapper around an HTML `<form>` element that automatically hooks into Formik's `handleSubmit` and `handleReset`.
     * When the `view` is `read`, it renders a `div` tag instead.
     * @see https://formik.org/docs/api/form
     */
    Form: FormForm,
    GroupSelect: FieldGroupSelect,
    MultiSelect: FieldMultiSelect,
    Number: FieldNumber,
    PillSelect: FieldPillSelect,
    RadioButtons: FieldRadioButtons,
    RichText: FieldRichText,
    Row: Row,
    Select: FieldSelect,
    Text: FieldText,
    TextArea: FieldTextArea,
    TieredSelect: FieldTieredSelect,
    SettingsPageFooter,
  },
  Form_
)
