import { getFileExtension } from '../_utils/filename'
import type { ThumbnailDocumentIconVariant } from './ThumbnailDocumentIcon'

export function getIsFilePreviewableAsImage(
  src: File | string | null | undefined
) {
  return (
    src instanceof File &&
    src.type.startsWith('image/') &&
    // not allow previewing vendor image mime types, such as "image/vnd.adobe.photoshop"
    !src.type.startsWith('image/vnd.') &&
    // not allow previewing .tiff images, as a not browser-compatible format
    src.type !== 'image/tiff'
  )
}

const extensions: { [k in ThumbnailDocumentIconVariant]?: string[] } = {
  image: ['png', 'jpg', 'jpeg', 'gif', 'psd', 'tiff', 'tif', 'bmp'],
  drawing: ['cad', 'dwg', 'dwf', 'dwfx'],
  email: ['msg', 'eml'],
  gantt: ['mpp', 'p6', 'xer'],
  zip: ['zip', 'rar', '7zip'],
  xml: ['rtf', 'txt'],
  word: ['doc', 'docx'],
  ini: ['ini', 'xml', 'exe'],
  mov: ['mov', 'avi'],
  pdf: ['pdf'],
  powerpoint: ['ppt', 'pptx'],
  excel: ['csv', 'xls', 'xlsx'],
  ['3d']: [
    'stl',
    'obj',
    'ma',
    'fbx',
    'dae',
    '3ds',
    'iges',
    'step',
    'wrl',
    'rvt',
    'ifc',
    'nwc',
    'nwd',
    'nwf',
  ],
  unknown: [],
}

const extensionsByVariant = Object.entries(extensions) as [
  ThumbnailDocumentIconVariant,
  string[]
][]

const variantByExtension = extensionsByVariant.reduce((acc, [type, exts]) => {
  exts.forEach((ext) => {
    acc[ext] = type
  })
  return acc
}, {} as Record<string, ThumbnailDocumentIconVariant>)

export function getThumbnailVariantForFilename(
  filename: string
): ThumbnailDocumentIconVariant {
  const defaultUnknownName = 'unknown'

  try {
    const ext = getFileExtension(filename)
    return ext
      ? variantByExtension[ext] || defaultUnknownName
      : defaultUnknownName
  } catch {
    return defaultUnknownName
  }
}
