import styled, { css } from 'styled-components'
import { Button } from '../Button/Button'
import { getBGColor } from '../Button/Button.styles'
import type { ButtonVariant } from '../Button/Button.types'
import { Card } from '../Card/Card'
import { Spinner } from '../Spinner/Spinner'

export const StyledDropdownMenu = styled(Card)`
  display: flex;
  max-height: 40vh;
  max-width: 248px;
  min-width: inherit;
`

export const StyledDropdownSpinner = styled(Spinner)`
  margin: 0 6px; // TODO one-off
`

export const StyledDropdownButton = styled(Button)<{
  $loading: boolean
  variant: ButtonVariant
}>`
  pointer-events: ${({ disabled, $loading }) =>
    disabled || $loading ? 'none' : 'inherit'};

  ${({ $loading, variant }) => {
    if ($loading) {
      return css`
        background: ${getBGColor(variant).hover};
      `
    }
  }}
`
