import styled, { css } from 'styled-components'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'
import type { BreadcrumbCrumbProps } from './Breadcrumbs.types'

export const StyledContainer = styled.nav`
  color: ${colors.gray50};
  display: flex;
  align-items: center;

  a,
  a:hover {
    display: inline-flex;
    text-decoration: none;
  }
`

export const StyledList = styled.ol`
  display: flex;
  margin: 0;
  padding-left: 0;
`

export const StyledSeparator = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin: 0px ${spacing.xs}px;
`

export const StyledCrumb = styled.li<BreadcrumbCrumbProps>`
  ${getTypographyIntent('body')}

  align-items:center;
  color: ${colors.gray45};
  display: inline-flex;
  min-height: 24px;
  text-decoration: none;

  &:hover {
    cursor: ${({ active }) => (active ? 'default' : 'pointer')};
    text-decoration: ${({ active }) => (active ? 'none' : 'underline')};
  }

  > a {
    color: ${colors.gray45};
    ${({ active }) =>
      active &&
      css`
        color: ${colors.gray15};
        font-weight: ${typographyWeights.semibold};
      `}
  }

  ${({ active }) =>
    active &&
    css`
      color: ${colors.gray15};
      font-weight: ${typographyWeights.semibold};
    `}
`
