import React from 'react'
import {
  StyledFooter,
  StyledGroup,
  StyledHeader,
  StyledItem,
  StyledMenu,
  StyledOptions,
  StyledSearch,
  StyledWrapper,
} from '../MenuImperative/MenuImperative.styles'
import { useOverlayTriggerContext } from '../OverlayTrigger/OverlayTrigger'
import { Typeahead } from '../Typeahead/Typeahead'
import type { TypeaheadProps } from '../Typeahead/Typeahead.types'
import { useEventListener } from '../_hooks/EventListener'
import { useI18nContext } from '../_hooks/I18n'
import { useListNavigation } from '../_hooks/ListNavigation'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { DivAttributes } from '../_utils/types'
import { usingHookOrDefault } from '../_utils/usingHookOrDefault'
import type {
  ElementTypeChecker,
  MenuFooterProps,
  MenuGroupProps,
  MenuHeaderProps,
  MenuHook,
  MenuHookConfig,
  MenuItemProps,
  MenuOptionsProps,
  MenuProps,
  MenuSearchProps,
  MenuSelection,
} from './Menu.types'

const onScrollBottomThreshold = 8

export const isItem: ElementTypeChecker = (element) => element.type === Item

function scrollIntoView(index: number, element: HTMLElement) {
  if (!element.parentElement) {
    return
  }

  if (index === 0) {
    element.parentElement.scrollTop = 0

    return
  }

  const { offsetTop, clientHeight } = element

  const { scrollTop, clientHeight: parentHeight } = element.parentElement

  if (offsetTop < scrollTop) {
    element.parentElement.scrollTop = offsetTop
  } else if (offsetTop + clientHeight > scrollTop + parentHeight) {
    element.parentElement.scrollTop = offsetTop - parentHeight + clientHeight
  }
}

function checkBottomScrollPosition(callback?: (e: Event) => void) {
  return function onScroll(e: Event) {
    if (
      callback &&
      e.currentTarget instanceof HTMLElement &&
      e.currentTarget.scrollTop >=
        e.currentTarget.scrollHeight -
          e.currentTarget.clientHeight -
          onScrollBottomThreshold
    ) {
      callback(e)
    }
  }
}

function noop() {}

export function useMenu({
  isSelectable = isItem,
  keyHandlerRef: externalKeyHandlerRef,
  multiple = false,
  onScrollBottom,
  onSearch = noop,
  onSelect = noop,
  scrollable = true,
}: MenuHookConfig): MenuHook {
  const listNavigation = useListNavigation({
    circular: !onScrollBottom,
    initialIndex: 0,
    size: 0,
  })

  const highlighted = React.useRef<HTMLDivElement>(null)

  const keyHandlerRef = externalKeyHandlerRef || React.createRef<HTMLElement>()

  const selectItem = React.useCallback(
    (selection: MenuSelection) => {
      onSelect(selection)
    },
    [onSelect]
  )

  const onKeyDown = React.useCallback(
    function (e: KeyboardEvent) {
      const { key } = e

      if (key === 'ArrowDown' || key === 'Down') {
        listNavigation.increment()
        e.preventDefault()
      } else if (key === 'ArrowUp' || key === 'Up') {
        listNavigation.decrement()
        e.preventDefault()
      } else if (key === 'Enter') {
        if (highlighted.current) {
          highlighted.current.click()
        }
      }
    },
    [listNavigation]
  )

  // TODO - deprecate keyhandlerRef, this is just for backwards compatibility since we already exposed it
  React.useEffect(
    function () {
      let el: HTMLElement

      if (keyHandlerRef.current) {
        keyHandlerRef.current.addEventListener('keydown', onKeyDown)

        el = keyHandlerRef.current
      }

      return function () {
        if (el) {
          el.removeEventListener('keydown', onKeyDown)
        }
      }
    },
    [keyHandlerRef, onKeyDown]
  )

  return {
    domHandlers: {
      onKeyDown: (e: React.KeyboardEvent<HTMLElement>) =>
        onKeyDown(e.nativeEvent),
    },
    highlighted,
    isSelectable,
    listNavigation,
    multiple,
    onScrollBottom,
    onSearch,
    scrollable,
    selectItem,
  }
}

export const MenuHookContext = React.createContext<MenuHook | null>(null)

export function useMenuHookContext(): MenuHook {
  const context = React.useContext(MenuHookContext)

  if (context === null) {
    throw new Error(
      'Cannot find `MenuHook` context, please wrap your component in `<MenuHookContext.Provider>`'
    )
  }

  return context
}

export const Menu_ = React.forwardRef<HTMLDivElement, MenuProps>(function Menu(
  {
    children,
    UNSAFE_closesOnSelect = true,
    isSelectable = isItem,
    keyHandlerRef,
    onScrollBottom,
    onSelect = noop,
    onSearch,
    scrollable = true,
    usingHook,
    ...props
  },
  ref
) {
  // Don't be tempted to put this in useMenu. In order for hooks to be portable
  // across the dom tree, they should not contain context consumers.
  const overlayTriggerContext = useOverlayTriggerContext()

  const menu = usingHookOrDefault(
    usingHook,
    useMenu
  )({
    isSelectable,
    keyHandlerRef,
    onScrollBottom,
    onSelect,
    onSearch,
    scrollable,
  })

  React.useEffect(
    function () {
      // When unmounting, reset the listNavigation index and set
      // the current highlighted item to null. we only want to run this once,
      // so don't pass any dependencies
      return function () {
        menu.listNavigation.reset()
        menu.highlighted.current = null
      }
    },
    /* eslint-disable */ [] /* eslint-enable */
  )

  function selectItem(selection: MenuSelection) {
    menu.selectItem(selection)

    if (UNSAFE_closesOnSelect) {
      overlayTriggerContext.hide(selection.event)
    }
  }

  return (
    <MenuHookContext.Provider value={{ ...menu, selectItem }}>
      <StyledWrapper {...menu.domHandlers} ref={ref} tabIndex={0}>
        <StyledMenu {...props}>{children}</StyledMenu>
      </StyledWrapper>
    </MenuHookContext.Provider>
  )
})

export const Group = React.forwardRef<
  HTMLDivElement,
  DivAttributes & MenuGroupProps
>(function Group({ children, clickable = false, item, ...props }, ref) {
  return (
    <StyledGroup
      {...props}
      ref={ref}
      onClick={(event) => {
        // TODO: implement selecting groups?
      }}
      $clickable={clickable}
    >
      {children}
    </StyledGroup>
  )
})

export const Item = React.forwardRef<
  HTMLDivElement,
  DivAttributes & MenuItemProps
>(function Item(
  {
    children,
    index = 0,
    item,
    onClick = noop,
    selected = false,
    suggested = false,
    onMouseMove,
    ...props
  },
  ref
) {
  const { highlighted, listNavigation, multiple, selectItem } =
    useMenuHookContext()

  const itemRef =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    (ref as React.RefObject<HTMLDivElement>) || React.useRef<HTMLDivElement>()

  const isHighlighted = index === listNavigation.index

  React.useEffect(
    function () {
      // When mounting, check if this item is selected or suggested, and if
      // it is, then set it as the current index and scroll to it.
      // We only want to run this once, so don't pass any dependencies

      if (multiple) {
        // don't do this behavior in multiple mode
        return
      }

      if (itemRef.current && (selected || suggested)) {
        highlighted.current = itemRef.current
        listNavigation.set(index)
      }
    },
    /* eslint-disable */ [] /* eslint-enable */
  )

  React.useEffect(
    function () {
      // If this item has become highlighted, scroll to it
      if (itemRef.current && isHighlighted) {
        highlighted.current = itemRef.current
        scrollIntoView(index, itemRef.current)
      }
    },
    [highlighted, index, isHighlighted, itemRef]
  )

  return (
    <StyledItem
      {...props}
      onClick={(event) => {
        listNavigation.set(index)

        selectItem({ event: event.nativeEvent, item, group: false })

        onClick(event)
      }}
      onMouseMove={(event) => {
        if (onMouseMove) {
          onMouseMove(event)
        }
        if (listNavigation.index !== index) {
          listNavigation.set(index)
        }
      }}
      ref={itemRef}
      $highlighted={isHighlighted}
      $selected={selected}
    >
      {children}
    </StyledItem>
  )
})

export const Options = React.forwardRef<HTMLDivElement, MenuOptionsProps>(
  function Options({ children, className, scrollable = true }, ref) {
    const {
      listNavigation,
      isSelectable,
      onScrollBottom,
      scrollable: menuScrollable,
    } = useMenuHookContext()

    const optionsRef =
      (ref as React.RefObject<HTMLDivElement>) || React.createRef()

    const size = React.Children.toArray(children).filter(isSelectable).length

    React.useEffect(
      function () {
        listNavigation.setSize(size)
      },
      [listNavigation, size]
    )

    useEventListener({
      event: 'scroll',
      handler: checkBottomScrollPosition(onScrollBottom),
      scope: optionsRef,
    })

    let index = 0

    return (
      <StyledOptions
        className={className}
        ref={optionsRef}
        $scrollable={menuScrollable || scrollable}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child) && isSelectable(child)) {
            const selectableIndex = index

            index = index + 1

            return React.cloneElement(child, {
              index: selectableIndex,
            })
          }

          return child
        })}
      </StyledOptions>
    )
  }
)

export const Search = ({
  className,
  i18nScope = 'core.menu',
  placeholder,
  onChange: _onChange,
  ...props
}: MenuSearchProps) => {
  const I18n = useI18nContext()
  const menu = useMenuHookContext()

  const [value, setValue] = React.useState('')

  const onChange: TypeaheadProps['onChange'] = React.useCallback(
    function (value: string, event: any) {
      setValue(event.target.value)
      menu.onSearch?.(event)
      menu.listNavigation.set(0)
      _onChange?.(event)
    },
    [_onChange]
  )

  return (
    <StyledSearch className={className}>
      <Typeahead
        {...props}
        autoFocus={true}
        placeholder={placeholder || I18n.t('search', { scope: i18nScope })}
        onChange={onChange}
        value={value}
      />
    </StyledSearch>
  )
}

export const Header = React.forwardRef<
  HTMLDivElement,
  DivAttributes & MenuHeaderProps
>(function Header({ ...props }, ref) {
  return <StyledHeader ref={ref} {...props} />
})

export const Footer = React.forwardRef<
  HTMLDivElement,
  DivAttributes & MenuFooterProps
>(function Footer({ padding = 'md lg', ...props }, ref) {
  return <StyledFooter ref={ref} padding={padding} {...props} />
})

Menu_.displayName = 'Menu'

Footer.displayName = 'Menu.Footer'

Group.displayName = 'Menu.Group'

Header.displayName = 'Menu.Header'

Item.displayName = 'Menu.Item'

Options.displayName = 'Menu.Options'

Search.displayName = 'Menu.Search'

/**

 Menus are used in conjunction with components that contain dropdowns.
 For example, multi select, single select, and dropdown.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-menu--demo)

 @see [Design Guidelines](https://design.procore.com/menu)

 */
export const Menu = addSubcomponents(
  {
    Footer,
    Group,
    Header,
    Item,
    Options,
    Search,
  },
  Menu_
)
