import { Archive, Download } from '@procore/core-icons/dist'
import React from 'react'
import { Box } from '../Box/Box'
import { Link } from '../Link/Link'
import { Tooltip } from '../Tooltip/Tooltip'
import { Typography } from '../Typography/Typography'
import { useI18nContext } from '../_hooks/I18n'
import type { DivAttributes } from '../_utils/types'
import {
  StyledFileListDownloadAll,
  StyledFileListItem,
  StyledFileListItemDownloadIcon,
  StyledFileListItemTextInactive,
} from './FileList.styles'
import type { FileListItemProps, FileListProps } from './FileList.types'

export const defaultGetViewUrl = (item: FileListItemProps) => {
  return item.viewUrl
}

export const defaultGetDownloadUrl = (item: FileListItemProps) => {
  return item.downloadUrl
}

export const defaultGetName = (item: FileListItemProps) => {
  return item.name
}

/**

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-filelist--demo)

 @see [Design Guidelines](https://design.procore.com/file-list)

 */
export const FileList = React.forwardRef<
  HTMLDivElement,
  FileListProps & DivAttributes
>(function FileList(
  {
    items,
    downloadAllUrl,
    getDownloadUrl = defaultGetDownloadUrl,
    getViewUrl = defaultGetViewUrl,
    getName = defaultGetName,
    qa,
    ...props
  },
  ref
) {
  const I18n = useI18nContext()
  const { length } = items

  const isDownloadAllVisible = length > 1 && downloadAllUrl

  if (length === 0) {
    return null
  }

  return (
    <Box ref={ref} {...props}>
      {isDownloadAllVisible && (
        <StyledFileListDownloadAll
          variant="tertiary"
          size="sm"
          icon={<Archive />}
          href={downloadAllUrl}
          tabIndex={0}
        >
          {I18n.t('core.fileList.downloadAll')}
        </StyledFileListDownloadAll>
      )}
      <Box as="ul">
        {items.map((item, i) => {
          const name = getName(item)
          const downloadUrl = getDownloadUrl(item)
          return (
            <Item
              name={name}
              downloadUrl={getDownloadUrl(item)}
              viewUrl={getViewUrl(item)}
              data-qa={qa?.item?.(item)}
              key={`${name}-${downloadUrl}`}
            />
          )
        })}
      </Box>
    </Box>
  )
})

export function Item({
  name,
  viewUrl,
  downloadUrl,
  ...props
}: FileListItemProps) {
  const I18n = useI18nContext()

  return (
    <StyledFileListItem {...props}>
      {viewUrl ? (
        <Typography intent="body" weight="semibold">
          <Link href={viewUrl} target="_blank" tabIndex={0}>
            {name}
          </Link>
        </Typography>
      ) : (
        <Tooltip overlay={I18n.t('core.fileList.noPreview')}>
          <Typography intent="body" color="gray15">
            <StyledFileListItemTextInactive tabIndex={0}>
              {name}
            </StyledFileListItemTextInactive>
          </Typography>
        </Tooltip>
      )}
      {downloadUrl && (
        <Tooltip overlay={I18n.t('core.fileList.download')}>
          <StyledFileListItemDownloadIcon
            aria-label={I18n.t('core.fileList.download')}
            href={downloadUrl}
            icon={<Download size="sm" />}
            size="sm"
            variant="tertiary"
          />
        </Tooltip>
      )}
    </StyledFileListItem>
  )
}
