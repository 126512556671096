import type { ElementProps, FloatingContext } from '@floating-ui/react'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react'
import { useId } from '@react-aria/utils'
import debounce from 'lodash.debounce'
import uniq from 'lodash.uniq'
import React from 'react'
import type { VirtuosoHandle, VirtuosoProps } from 'react-virtuoso'
import { ulid } from 'ulid'
import { useI18nContext } from '../_hooks/I18n'
import { useZIndexContext } from '../_hooks/ZIndex'
import { spacing } from '../_styles/spacing'
import * as defaultComponents from './SuperSelect.components'
import { draggableOptionIdSymbol } from './SuperSelect.constants'
import { extendedSelectMenuWidth } from './SuperSelect.styles'
import type {
  KeyboardSelectionProps,
  SuperSelectApi,
  SuperSelectConfig,
  SuperSelectOption,
  SuperSelectValue,
  SuperSelectValuePrimitive,
  TokenNavigationProps,
} from './SuperSelect.types'
import {
  collectGroupsInOrderOfOccurrence,
  createOptgroup,
  getBatchOptionFormatter,
  getIsAllOptionsUngrouped,
  getOptionIsOptgroup,
  getOptionsSortingAlgorithm,
  isMultiple,
  removeEmptyOptGroups,
  reorder,
  sortOptgroups,
} from './SuperSelect.utils'

const listContainerVerticalPadding = spacing.sm * 2

function noop() {}

function defaultGetOptionValue(option: SuperSelectOption) {
  return option?.value
}

function defaultGetOptionIsBatch(option: SuperSelectOption): boolean {
  return Array.isArray(option?.value)
}

function defaultGetOptionIsDisabled(option: SuperSelectOption): boolean {
  return option?.disabled ?? false
}

function defaultGetOptionGroup(option: SuperSelectOption): string {
  return option?.group ?? ''
}

function defaultSetOptionGroup(
  option: SuperSelectOption,
  group: string
): SuperSelectOption {
  return {
    ...option,
    group,
  }
}

function defaultGetOptionLabel(option: SuperSelectOption): string {
  return option?.label ?? ''
}

function stringContains(str1: string, str2: string) {
  return str1.toLowerCase().includes(str2.toLowerCase())
}

function useTokenNavigation(
  context: FloatingContext<HTMLElement>,
  {
    enabled = true,
    value = [],
    activeIndex = null,
    onNavigate = noop,
    onChange = noop,
  }: TokenNavigationProps = {}
): ElementProps {
  return {
    reference: {
      onKeyDown(e) {
        if (!enabled || !Array.isArray(value)) {
          return
        }

        if (e.key === 'ArrowLeft') {
          if (activeIndex !== null) {
            onNavigate(Math.max(0, activeIndex - 1))
          } else if (value.length > 0) {
            onNavigate(value.length - 1)
          }
        } else if (e.key === 'ArrowRight') {
          if (activeIndex !== null) {
            if (activeIndex === value.length - 1) {
              onNavigate(null)
            } else {
              onNavigate(activeIndex + 1)
            }
          }
        } else if (e.key === 'Backspace') {
          if (!e.repeat) {
            if (activeIndex !== null) {
              const nextVal = value.filter((_, i) => i !== activeIndex)

              onChange(nextVal)

              if (activeIndex >= nextVal.length) {
                onNavigate(null)
              }
            } else if (value.length > 0) {
              onChange(value.filter((_, i) => i !== value.length - 1))
            }
          }
        }
      },
    },
  }
}

function useKeyboardSelection(
  context: FloatingContext<HTMLElement>,
  { enabled = true, onSelect = () => {} }: KeyboardSelectionProps = {}
): ElementProps {
  function onKeyDown(e: React.KeyboardEvent) {
    if (!enabled) {
      return
    }

    if (e.key === 'Enter') {
      onSelect()
    }
  }

  return {
    reference: {
      onKeyDown,
    },
    floating: {
      onKeyDown,
    },
  }
}

export function useSuperSelect({
  block = false,
  components: customComponents,
  defaultValue,
  disabled = false,
  draggable = false,
  emptyMessage = 'No results',
  error,
  footer,
  getOptionGroup = defaultGetOptionGroup,
  getOptionIsBatch = defaultGetOptionIsBatch,
  getOptionIsDisabled = defaultGetOptionIsDisabled,
  getOptionLabel = defaultGetOptionLabel,
  getOptionValue = defaultGetOptionValue,
  onScrollBottom = noop,
  onOpenChange = noop,
  header,
  loading = false,
  multiple = false,
  onChange,
  onManualSort,
  options: sourceOptions = [],
  placeholder,
  preset = '',
  presetProps,
  search = true,
  selectionStyle = 'highlight',
  setOptionGroup = defaultSetOptionGroup,
  sort = true,
  tabIndex = 0,
  value: value_,
  overlayMatchesTriggerWidth = true,
}: SuperSelectConfig) {
  React.useEffect(() => {
    if (!draggable) {
      return
    }

    const isUsingDefaultGetter = getOptionGroup === defaultGetOptionGroup
    const isUsingDefaultSetter = setOptionGroup === defaultSetOptionGroup

    if (
      (isUsingDefaultGetter && !isUsingDefaultSetter) ||
      (!isUsingDefaultGetter && isUsingDefaultSetter)
    ) {
      console.warn(
        `SuperSelect: Using potentially conflicting "getOptionGroup" and "setOptionGroup" implementations.
        Group reassignment after drag-and-drop operation might be broken.`
      )
    }
  }, [draggable, getOptionGroup, setOptionGroup])

  const i18n = useI18nContext()

  const initialValue = defaultValue ?? (multiple ? [] : null)
  const [val, setVal] = React.useState<SuperSelectValue>(initialValue)
  const value = value_ !== undefined ? value_ : val
  function setValue(v: SuperSelectValue) {
    if (!value_) {
      setVal(v)
    }

    if (onChange) {
      onChange(v)
    }
  }

  const navigationList = React.useRef<Array<HTMLElement | null>>([])
  const virtuoso = React.useRef<VirtuosoHandle>(null)
  const searchRef = React.useRef<HTMLInputElement>(null)

  const overlayId = useId() // TODO use React 18 useId
  const listId = useId() // TODO use React 18 useId

  const [open, setOpen] = React.useState(false)
  const [pointer, setPointer] = React.useState(false)
  const [width, setWidth] = React.useState(248)
  const [maxHeight, setMaxHeight] = React.useState(248)
  const [listHeight, setListHeight] = React.useState(0)
  const [searchHeight, setSearchHeight] = React.useState(0)
  const [footerHeight, setFooterHeight] = React.useState(0)
  const listContainerHeight = Math.min(
    maxHeight - searchHeight - footerHeight + listContainerVerticalPadding,
    listHeight + listContainerVerticalPadding
  )
  const [searchValue, setSearchValue_] = React.useState('')
  const setSearchValue = debounce(setSearchValue_, 250) // TODO use React 18 useDeferredValue

  const [activeMenuIndex, setActiveMenuIndex] = React.useState<number | null>(
    null
  )
  const [activeTokenIndex, setActiveTokenIndex] = React.useState<number | null>(
    null
  )

  const components = {
    ...defaultComponents,
    ...(customComponents || {}),
  }

  // TODO #memogetters: consider having getOption... getter functions memoized by consumers
  // Until then, exclude these callbacks from effect and memo dependencies
  const sortOptions = React.useMemo(
    () => getOptionsSortingAlgorithm({ getOptionIsBatch, getOptionLabel }),
    // skip `getOptionIsBatch` and `getOptionLabel`
    []
  )

  const formatBatchOption = React.useMemo(
    () =>
      getBatchOptionFormatter({
        value,
        multiple,
        getOptionIsBatch,
        getOptionValue,
      }),
    // skip `getOptionIsBatch` and `getOptionValue`, refer to TODO #memogetters
    [value, multiple]
  )

  // collect groups, sort them and populate them with options, and sort the options inside the groups
  const enforceOptionsSortingOrder = React.useCallback(
    function groupAndSort(opts: SuperSelectOption[]) {
      const { groups, groupedOptions } = collectGroupsInOrderOfOccurrence(
        opts,
        getOptionGroup
      )

      if (getIsAllOptionsUngrouped(groups)) {
        return opts.sort(sortOptions)
      }

      return Object.entries(groupedOptions)
        .sort(([groupA], [groupB]) => sortOptgroups(groupA, groupB))
        .flatMap(([groupName, groupOptions]) =>
          createOptgroup(groupName, groupOptions.sort(sortOptions))
        )
    },
    // skip `getOptionGroup`, refer to TODO #memogetters
    [sortOptions]
  )

  // collect groups and populate them with options
  const deriveOptionsSortingOrder = React.useCallback(
    (opts: SuperSelectOption[]) => {
      const { groups, groupedOptions } = collectGroupsInOrderOfOccurrence(
        opts,
        getOptionGroup
      )

      if (getIsAllOptionsUngrouped(groups)) {
        return opts
      }

      // display optgroups in the order of occurrence, as they are considered pre-sorted
      return groups.flatMap((group) => {
        const groupOptions = groupedOptions[group]

        return createOptgroup(group, groupOptions)
      })
    },
    // skip `getOptionGroup`, refer to TODO #memogetters
    []
  )

  const groupAndSortAlgorithm = React.useMemo(
    () => (sort ? enforceOptionsSortingOrder : deriveOptionsSortingOrder),
    [sort, enforceOptionsSortingOrder, deriveOptionsSortingOrder]
  )

  const options = React.useMemo(
    () => {
      const queried = searchValue
        ? sourceOptions.filter((opt) =>
            stringContains(getOptionLabel(opt), searchValue)
          )
        : [...sourceOptions] // make a copy of source options to prevent the mutation when sorting

      return groupAndSortAlgorithm(queried).map(formatBatchOption)
    },
    // skip `getOptionLabel`, refer to TODO #memogetters
    [groupAndSortAlgorithm, formatBatchOption, searchValue, sourceOptions]
  )

  const [draggableOptions, setDraggableOptions] = React.useState<
    SuperSelectOption[]
  >([])

  React.useEffect(
    () => {
      if (!draggable) {
        return
      }
      // make a copy of source options to prevent the mutation when sorting
      const opts = groupAndSortAlgorithm(
        sourceOptions.map((opt) => ({
          ...opt,
          [draggableOptionIdSymbol]: ulid(),
        }))
      )
      setDraggableOptions(opts)
    },
    // in draggable mode, sourceOptions must be memoized, othewise reorder will not work
    [draggable, sourceOptions, groupAndSortAlgorithm]
  )

  const queriedDraggableOptions = React.useMemo(
    () => {
      if (!draggable) {
        return []
      }

      const queried = searchValue
        ? removeEmptyOptGroups(
            draggableOptions.filter((opt) => {
              if (!getOptionIsOptgroup(opt)) {
                return stringContains(getOptionLabel(opt), searchValue)
              }
              return true
            })
          )
        : draggableOptions

      return queried.map(formatBatchOption)
    },
    // skip `getOptionLabel`, refer to TODO #memogetters
    [draggable, draggableOptions, searchValue, formatBatchOption]
  )

  const groupIndices = React.useMemo(() => {
    return options.reduce((acc, opt, i) => {
      if (getOptionIsOptgroup(opt)) {
        acc.push(i)
      }
      return acc
    }, [] as number[])
  }, [options])

  const selectedIndex = React.useMemo(() => {
    return options.findIndex(
      (option) => isSelectableOption(option) && getOptionValue(option) === value
    )
  }, [options, value])

  const selectedOption = React.useMemo(() => {
    const val = Array.isArray(value) ? value[0] : value
    return options.find((option) => getOptionValue(option) === val)
  }, [options, value])

  const selectedLabel = React.useMemo(() => {
    return selectedOption ? getOptionLabel(selectedOption) : ''
  }, [selectedOption])

  function isSelectableOption(o: SuperSelectOption) {
    return !getOptionIsOptgroup(o) && !getOptionIsDisabled(o)
  }

  function getFirstSelectableOptionIndex() {
    return options.findIndex(isSelectableOption)
  }

  function onSelect(option: SuperSelectOption) {
    if (getOptionIsDisabled(option)) {
      return
    }

    const optionValue = getOptionValue(option)

    if (isMultiple(multiple, value)) {
      if (Array.isArray(optionValue)) {
        setValue(uniq([...value, ...optionValue]))
      } else {
        setValue(
          value.includes(optionValue)
            ? value.filter(
                (val: SuperSelectValuePrimitive) => val !== optionValue
              )
            : [...value, optionValue]
        )
      }
    } else {
      setValue(optionValue)
    }
  }

  function onKeyboardSelect() {
    if (activeMenuIndex !== null) {
      const option = options[activeMenuIndex]

      if (option) {
        onSelect(option)
      }
    }
  }

  function getOptionIsSelected(option: SuperSelectOption) {
    return Array.isArray(value)
      ? value.includes(getOptionValue(option))
      : value === getOptionValue(option)
  }

  function isEmpty() {
    return Array.isArray(value) ? value.length === 0 : value === null
  }

  const boundWidthRef = React.useRef(open)

  React.useEffect(() => {
    boundWidthRef.current = open
  }, [open])

  const floating = useFloating<HTMLElement>({
    open,
    onOpenChange: (open) => {
      setOpen(open)
      onOpenChange(open)
    },
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      offset(({ rects }) => {
        return {
          mainAxis: 2,
          crossAxis: overlayMatchesTriggerWidth
            ? 0
            : (rects.floating.width - rects.reference.width) / 2,
        }
      }),
      flip(),
      size({
        apply({ availableHeight, elements }) {
          if (boundWidthRef.current) {
            boundWidthRef.current = false
            setWidth(
              overlayMatchesTriggerWidth
                ? elements.reference.getBoundingClientRect().width
                : extendedSelectMenuWidth
            )
          }
          setMaxHeight(availableHeight)
        },
        padding: 10,
      }),
    ],
  })

  const { value: zIndex } = useZIndexContext()

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(floating.context, {
        enabled: !disabled,
        keyboardHandlers: false,
      }),
      useDismiss(floating.context, {
        enabled: !disabled,
      }),
      useListNavigation(floating.context, {
        activeIndex: activeMenuIndex,
        disabledIndices: groupIndices,
        enabled: !disabled,
        listRef: navigationList,
        loop: true,
        onNavigate: (i) => setActiveMenuIndex(() => i),
        selectedIndex,
        virtual: true,
      }),
      useTokenNavigation(floating.context, {
        activeIndex: activeTokenIndex,
        enabled: !disabled && multiple && searchValue === '',
        onChange: setValue,
        onNavigate: (i) => setActiveTokenIndex(() => i),
        value,
      }),
      useKeyboardSelection(floating.context, {
        enabled: !disabled,
        onSelect: onKeyboardSelect,
      }),
      {
        reference: {
          $block: block,
          $disabled: disabled,
          $error: error,
          $hasClearIcon: !disabled && !loading,
          $loading: loading,
          $multiple: multiple,
          $open: open,
          $placeholder: !selectedLabel,
          'aria-controls': search ? overlayId : listId,
          'aria-expanded': open,
          'aria-haspopup': search ? 'dialog' : 'listbox',
          onKeyDown() {
            setPointer(false)
          },
          role: 'combobox',
          search,
          tabIndex: disabled ? -1 : tabIndex,
        } as any, // TODO fix type
        floating: {
          role: search ? 'dialog' : 'none',
          id: overlayId,
          onKeyDown() {
            setPointer(false)
          },
          onPointerMove() {
            setPointer(true)
          },
          style: {
            position: floating.strategy,
            left: floating.x || 0,
            top: floating.y || 0,
            width,
            maxHeight,
            zIndex,
          },
        },
      },
    ]
  )

  function getLabelProps() {
    return {
      $hoverable: false,
    }
  }

  function getMultiInputProps(): React.AriaAttributes &
    React.InputHTMLAttributes<HTMLInputElement> & {
      ref: React.Ref<HTMLInputElement>
    } {
    return {
      ref: searchRef,
      placeholder: isEmpty() ? placeholder : '',
      disabled,
      'aria-controls': listId,
      onKeyDown(e) {
        if (e.key === 'Tab') {
          if (open) {
            e.preventDefault()
            e.stopPropagation()
            floating.refs.floating.current?.focus()
          }
        }
      },
      onChange(e) {
        setSearchValue(e.currentTarget.value)
        setActiveTokenIndex(null)
      },
      style: {
        opacity: activeTokenIndex === null ? 1 : 0,
      },
    }
  }

  function getMultiValueProps(index: number) {
    return {
      onClick(e: React.MouseEvent) {
        // prevent the menu from closing
        e.stopPropagation()

        if (isMultiple(multiple, value)) {
          setValue(value.filter((_, i) => i !== index))
        }
      },
    }
  }

  function getSearchContainerProps() {
    return {
      ref: (el: HTMLElement | null) => {
        setSearchHeight(el?.clientHeight ?? 0)
      },
    }
  }

  function getSearchProps() {
    return {
      'aria-controls': listId,
      onChange(value: string) {
        setSearchValue(value)
        setActiveTokenIndex(null)
      },
      placeholder: i18n.t('core.select.search'),
    }
  }

  function getHeaderProps() {
    return {
      ref: (el: HTMLElement | null) => {},
    }
  }

  function getFooterProps() {
    return {
      ref: (el: HTMLElement | null) => {
        setFooterHeight(el?.clientHeight ?? 0)
      },
      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key !== 'Escape') {
          e.stopPropagation()
        }
      },
    }
  }

  function getEmptyMessageProps() {
    return {
      emptyMessage,
    }
  }

  function getClearProps() {
    return {
      'aria-hidden': true,
      'aria-label': i18n.t('core.select.clear'),
      onClick(e: React.MouseEvent) {
        // prevent the menu from closing
        e.stopPropagation()

        setActiveTokenIndex(null)
        setValue(multiple ? [] : null)
        setOpen(true)
      },
    }
  }

  function getMenuProps() {
    return {
      scrollable: false,
      style: {
        height: listContainerHeight,
      },
    }
  }

  function getVirtuosoProps(): VirtuosoProps<{}, {}> {
    const atBottomStateChange = (atBottom: boolean) => {
      if (atBottom) {
        onScrollBottom()
      }
    }

    return {
      role: 'listbox',
      id: overlayId,
      data: draggable ? queriedDraggableOptions : options,
      components: {
        Item: components?.Item as any, // TODO fix type
      },
      initialTopMostItemIndex: selectedIndex >= 0 ? selectedIndex : 0,
      style: {
        flex: '1 1 auto',
      },
      tabIndex: -1,
      totalListHeightChanged: setListHeight,
      atBottomStateChange,
    }
  }

  const onDragEnd = React.useCallback((result) => {
    if (!result.destination) {
      return
    }
    if (result.source.index === result.destination.index) {
      return
    }

    const startIndex = result.source.index
    const destinationIndex = result.destination.index

    setDraggableOptions((options) => {
      const { nextOptions, prevGroup, nextGroup } = reorder(
        options,
        startIndex,
        destinationIndex
      )

      const adjustedOptions =
        prevGroup !== nextGroup
          ? nextOptions.map((option, index) => {
              if (index === destinationIndex) {
                return setOptionGroup(option, nextGroup)
              }
              return option
            })
          : nextOptions

      if (typeof onManualSort === 'function') {
        setTimeout(() => {
          onManualSort(
            adjustedOptions.filter((option) => !getOptionIsOptgroup(option))
          )
        }, 0)
      }

      return adjustedOptions
    })
  }, [])

  // run effect when the `open` state changes
  //
  // if it was closed and is now open, reset the highlighted index to be
  // the first selectable option
  //
  // if it was open and is now closed, reset the search value and focused token index
  React.useEffect(() => {
    if (open) {
      setActiveMenuIndex(() =>
        selectedIndex >= 0 ? selectedIndex : getFirstSelectableOptionIndex()
      )
    } else {
      setSearchValue(() => '')
      setActiveTokenIndex(() => null)
    }
  }, [open])

  // run the effect when user enters a serach value
  //
  // if the select is currently open then reset highlighted item to be the first
  // selectable option
  React.useEffect(() => {
    if (open) {
      setActiveMenuIndex(() => getFirstSelectableOptionIndex())
    }
  }, [searchValue])

  // when we change the value then close the overlay, but only if we have
  // actually selected a value
  //
  // multiple mode forces the overlay to stay open
  React.useEffect(() => {
    if (!multiple && value !== null) {
      setOpen(() => false)
    }
  }, [value])

  // recreate the array to hold dom refs when our options change
  React.useEffect(() => {
    navigationList.current = [...Array(options.length).fill(null)]
  }, [options])

  // scroll virtuoso list activeMenuIndex into view when the index changes
  React.useLayoutEffect(() => {
    if (activeMenuIndex !== null) {
      if (!pointer) {
        virtuoso.current?.scrollIntoView({ index: activeMenuIndex })
      }
    }
  }, [activeMenuIndex, open])

  return {
    config: {
      block,
      disabled,
      draggable,
      emptyMessage,
      error,
      footer,
      header,
      placeholder,
      preset,
      presetProps,
      selectionStyle,
      tabIndex,
      loading,
      multiple,
    },
    components,
    state: {
      activeMenuIndex,
      activeTokenIndex,
      isEmpty,
      listContainerHeight,
      listId,
      maxHeight,
      onDragEnd,
      onSelect,
      open,
      options: draggable ? queriedDraggableOptions : options,
      overlayId,
      searchValue,
      selectedIndex,
      selectedLabel,
      selectedOption,
      setOpen,
      setPointer,
      setSearchValue: setSearchValue,
      sourceOptions,
      value,
      width,
    },
    props: {
      clear: getClearProps,
      emptyMessage: getEmptyMessageProps,
      footer: getFooterProps,
      header: getHeaderProps,
      item: getItemProps,
      label: getLabelProps,
      menu: getMenuProps,
      multiInput: getMultiInputProps,
      overlay: getFloatingProps,
      search: getSearchProps,
      searchContainer: getSearchContainerProps,
      tokenClear: getMultiValueProps,
      trigger: getReferenceProps,
      virtuoso: getVirtuosoProps,
    },
    option: {
      group: getOptionGroup,
      isBatch: getOptionIsBatch,
      isSelected: getOptionIsSelected,
      isDisabled: getOptionIsDisabled,
      isOptgroup: getOptionIsOptgroup,
      label: getOptionLabel,
      value: getOptionValue,
    },
    refs: {
      floating,
      navigationList,
      virtuoso,
    },
  }
}

export const SuperSelectContext = React.createContext<SuperSelectApi | null>(
  null
)

export function useSuperSelectContext() {
  const ctx = React.useContext(SuperSelectContext)

  if (!ctx) {
    throw new Error('Invalid SuperSelectContext')
  }

  return ctx as SuperSelectApi
}
