import styled, { keyframes } from 'styled-components'
import { spacing } from '../_styles/spacing'
import type {
  PlaceholderItemProps,
  RoundPlaceholderItemProps,
} from './GhostPlaceholder.types'

const shimmerAnimation = keyframes`
  0% {
    background-position: -1000px 500px;
  }
  100% {
    background-position: 1000px 500px;
  }
`

export const PlaceholderItem = styled.div<PlaceholderItemProps>`
  width: 100%;
  min-height: 24px;
  padding: 10px 0;

  animation: ${shimmerAnimation} 3s ease-out infinite;
  background: radial-gradient(
    ellipse at center,
    ${(props) => props.$startGradientColor} 0%,
    ${(props) => props.$endGradientColor} 50%
  );
  background-size: 2000px 2000px;
  border-radius: ${spacing.sm}px;
`

export const RoundPlaceholderItem = styled(
  PlaceholderItem
)<RoundPlaceholderItemProps>`
  width: ${(props) => (props.$small ? '24px' : '40px')};
  height: ${(props) => (props.$small ? '24px' : '40px')};
  border-radius: 50%;
`
