import styled, { css } from 'styled-components'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { getShadow } from '../_styles/shadows'
import type { Placement } from '../_utils/placement'

export const popoverArrowSize = 6

interface StyledPopoverProps {
  $fluid: boolean
  $placement: Placement
}

export const StyledPopoverContent = styled.span<Required<StyledPopoverProps>>`
  background-color: ${colors.white};
  border-radius: ${borderRadius.md}px;

  color: ${colors.gray15};
  display: inline-block;
  max-width: 250px;
  position: relative;

  ${({ $fluid }) => {
    if ($fluid) {
      return css`
        max-width: none;
      `
    }
  }}
`

export const StyledPopover = styled.div`
  display: inline-block;
  ${getShadow(3)}

  & + [data-overlay-arrow] {
    background: ${colors.white};
  }
`
