export function parseFilename(str: string) {
  const fileExt = getFileExtension(str)
  const isFilename = Boolean(fileExt)

  if (!isFilename) {
    return { filename: '', extension: '', isFilename }
  }

  const cutoff = fileExt ? str.length - fileExt.length : str.length
  const filename = str.substring(0, cutoff - 1)
  const extension = str.substring(cutoff)

  return { filename, extension, isFilename }
}

export function getFileExtension(filename: string) {
  const extPos = filename.lastIndexOf('.')

  return extPos < 0 ? '' : filename.substring(extPos + 1)
}
