import { VisuallyHidden } from '@react-aria/visually-hidden'
import throttle from 'lodash.throttle'
import React from 'react'
import { useI18nContext } from '../../_hooks/I18n'
import type { ProgressAnnouncerProps } from './ProgressAnnouncer.types'

interface Info {
  total: number
  totalProgress: number
  failed: number
  uploaded: number
}

const getInfo = (
  items: ProgressAnnouncerProps['items'],
  uploadProgress: ProgressAnnouncerProps['uploadProgress']
): Info => {
  const info = items.reduce(
    (acc, item) => {
      if (item.error) {
        return {
          ...acc,
          failed: acc.failed + 1,
        }
      }

      const progress = uploadProgress[item.id] ?? 0
      const totalProgress = acc.totalProgress + progress
      const uploaded = progress === 100 ? acc.uploaded + 1 : acc.uploaded

      return {
        ...acc,
        totalProgress,
        uploaded,
      }
    },
    { totalProgress: 0, failed: 0, uploaded: 0 }
  )

  return {
    ...info,
    total: items.length,
    // 33.3333333333% => 33%
    totalProgress: Math.round(info.totalProgress / items.length),
  }
}

export const ProgressAnnouncer: React.FC<ProgressAnnouncerProps> = ({
  items,
  uploadProgress,
  throttleMs = 5000,
}) => {
  const i18n = useI18nContext()
  const [announce, setAnnounce] = React.useState<string>('')
  // Needed to not announce two times that progress is 100%
  const isAllUploadedAnnounced = React.useRef<boolean>(false)

  const updateAnnounce = (files: ProgressAnnouncerProps['items']) => {
    const info = getInfo(files, uploadProgress)

    const totalProgressAnnounce = info.failed
      ? i18n.t('core.dropzone.uploadTotalProgress.withFailed', info)
      : i18n.t('core.dropzone.uploadTotalProgress.withoutFailed', info)

    setAnnounce(totalProgressAnnounce)
  }

  const updateAnnounceThrottled = React.useCallback(
    throttle(
      updateAnnounce,
      throttleMs,
      // After file selection VoiceOver announces - the current location is the browser window.
      // It takes some time.
      { leading: false }
    ),
    [throttleMs]
  )
  React.useEffect(() => {
    isAllUploadedAnnounced.current = false
  }, [items.length])

  React.useEffect(() => {
    if (items.length > 0) {
      const isAllUploaded = items.every(
        (item) => uploadProgress[item.id] === 100
      )

      if (isAllUploaded && !isAllUploadedAnnounced.current) {
        // announce immediately
        isAllUploadedAnnounced.current = true
        updateAnnounceThrottled.cancel()
        updateAnnounce(items)
      } else if (!isAllUploaded) {
        updateAnnounceThrottled(items)
      }
    }
  }, [items, uploadProgress])

  React.useEffect(() => {
    return () => {
      updateAnnounceThrottled.cancel()
    }
  }, [])

  return announce ? <MemoizedAnnouncer value={announce} /> : null
}

const Announcer: React.FC<{ value: string }> = ({ value }) => {
  return (
    <VisuallyHidden>
      <div role="status">{value}</div>
    </VisuallyHidden>
  )
}

const MemoizedAnnouncer = React.memo(Announcer)
