export function getFormatterLocale(locale: string): string {
  // This method solves for Procore's nonstandard locales
  // en-owner, en-budget, en-international are legacy locales
  // pseudo locale supports pseudolocalization
  // By request of Procore Canada, CA locales use US/FR date format
  switch (locale) {
    case 'en-CA':
    case 'en-owner':
    case 'en-owner-beta':
    case 'en-specialty-contractor':
    case 'en-budget':
    case 'pseudo':
      return 'en-US' //https://github.com/procore/core/pull/9913#discussion_r795729747
    case 'en-international':
      return 'en-GB'
    case 'fr-CA':
      return 'fr-FR'
    default:
      return locale
  }
}
