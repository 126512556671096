import { Check } from '@procore/core-icons'
import React from 'react'
import { Pill } from '../../Pill/Pill'
import { PillSelectCheckedContainer } from '../../PillSelect/PillSelect.styles'
import { Tooltip } from '../../Tooltip/Tooltip'
import { OverflowObserver } from '../../_hooks/OverflowObserver'
import { Label } from '../SuperSelect.components'
import {
  PillSelectLabel,
  PillSelectLabelWrapper,
  PillSelectOptionWrapper,
  StyledLabel,
} from '../SuperSelect.styles'
import type { SuperSelectConfig } from '../SuperSelect.types'
import { useSuperSelectContext } from '../useSuperSelect'

export const pillPreset: SuperSelectConfig = {
  components: {
    Label: React.forwardRef(({ hoverable }, ref) => {
      const ctx = useSuperSelectContext()

      return (
        <StyledLabel $hoverable={hoverable}>
          <PillSelectLabelWrapper>
            <OverflowObserver>
              {({ isOverflowing, ref }) => {
                return (
                  <Tooltip
                    trigger={isOverflowing ? 'hover' : 'none'}
                    overlay={ctx.state.selectedLabel}
                  >
                    {ctx.state.isEmpty() ? (
                      <Label ref={ref} hoverable={hoverable} />
                    ) : (
                      <PillSelectLabel
                        ref={ref}
                        color={(ctx.state.selectedOption as any)?.color}
                      >
                        {ctx.state.selectedLabel}
                      </PillSelectLabel>
                    )}
                  </Tooltip>
                )
              }}
            </OverflowObserver>
          </PillSelectLabelWrapper>
        </StyledLabel>
      )
    }),
    OptionLabel: ({ option }) => {
      const ctx = useSuperSelectContext()

      return (
        <PillSelectOptionWrapper>
          <Pill color={option.color}>{ctx.option.label(option)}</Pill>
          <PillSelectCheckedContainer>
            {ctx.option.isSelected(option) && <Check size="sm" />}
          </PillSelectCheckedContainer>
        </PillSelectOptionWrapper>
      )
    },
    MultiValue: ({ option }) => {
      const ctx = useSuperSelectContext()

      return <Pill color={option.color}>{ctx.option.label(option)}</Pill>
    },
  },
}
