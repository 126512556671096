import React from 'react'
import { StyledActions } from '../Content/Content.styles'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { mergeRefs } from '../_utils/mergeRefs'
import type { Props } from '../_utils/types'
import {
  StyledFooter,
  StyledHeroMedia,
  StyledHeroOverlay,
  StyledHeroText,
  StyledInteractableTile,
  StyledLinkTile,
  StyledSelectableTile,
  StyledTileContent,
} from './Tile.styles'
import type {
  HeroMediaProps,
  HeroTextProps,
  InteractableTileProps,
  LinkTileProps,
  SelectableTileProps,
  TileCommonProps,
  TileProps,
} from './Tile.types'

const heroTextGridTemplateColumns = 'auto 1fr'

const TileContext = React.createContext<Pick<TileCommonProps, 'orientation'>>({
  orientation: 'portrait',
})

const HeroMedia = React.forwardRef<HTMLDivElement, HeroMediaProps>(
  function HeroMedia(
    { children, overlay = false, onOverlayClick, ...props },
    ref
  ) {
    const { orientation } = React.useContext(TileContext)
    const heroRef = React.useRef<HTMLDivElement>(null)

    React.useLayoutEffect(() => {
      if (orientation === 'landscape') {
        heroRef.current?.parentElement?.style.removeProperty(
          '--core-tile-grid-template-columns'
        )
      }
    }, [orientation])

    return (
      <StyledHeroMedia
        ref={mergeRefs(heroRef, ref)}
        $orientation={orientation}
        {...props}
      >
        {overlay && <StyledHeroOverlay onClick={onOverlayClick} />}
        {children}
      </StyledHeroMedia>
    )
  }
)

const HeroText = React.forwardRef<HTMLDivElement, HeroTextProps>(
  function HeroText({ children, ...props }, ref) {
    const { orientation } = React.useContext(TileContext)
    const heroRef = React.useRef<HTMLDivElement>(null)

    React.useLayoutEffect(() => {
      if (orientation === 'landscape') {
        heroRef.current?.parentElement?.style.setProperty(
          '--core-tile-grid-template-columns',
          heroTextGridTemplateColumns
        )
      }
    }, [orientation])

    return (
      <StyledHeroText
        ref={mergeRefs(heroRef, ref)}
        $orientation={orientation}
        {...props}
      >
        {children}
      </StyledHeroText>
    )
  }
)

const Content = React.forwardRef<HTMLDivElement, HeroTextProps>(
  function Content(props, ref) {
    return <StyledTileContent ref={ref} {...props} />
  }
)

const Footer = React.forwardRef<HTMLDivElement, Props>(function Footer(
  { children, ...props },
  ref
) {
  return (
    <StyledFooter ref={ref} {...props}>
      {children}
    </StyledFooter>
  )
})

const Actions = React.forwardRef<HTMLDivElement, Props>(function Actions(
  { children, ...props },
  ref
) {
  return (
    <StyledActions ref={ref} {...props}>
      {children}
    </StyledActions>
  )
})

export const InteractableTile = React.forwardRef<
  HTMLDivElement,
  Omit<InteractableTileProps, 'variant'>
>(function InteractableTile({ orientation, children, ...props }, ref) {
  return (
    <StyledInteractableTile ref={ref} $orientation={orientation} {...props}>
      <TileContext.Provider value={{ orientation }}>
        {children}
      </TileContext.Provider>
    </StyledInteractableTile>
  )
})

export const LinkTile = React.forwardRef<
  HTMLAnchorElement,
  Omit<LinkTileProps, 'variant'>
>(function LinkTile({ orientation, children, ...props }, ref) {
  return (
    <StyledLinkTile ref={ref} $orientation={orientation} {...props}>
      <TileContext.Provider value={{ orientation }}>
        {children}
      </TileContext.Provider>
    </StyledLinkTile>
  )
})

export const SelectableTile = React.forwardRef<
  HTMLDivElement,
  Omit<SelectableTileProps, 'variant'>
>(function SelectableTile(
  {
    orientation,
    selected = false,
    onClick,
    onKeyDown,
    disabled = false,
    children,
    ...props
  },
  ref
) {
  return (
    <StyledSelectableTile
      ref={ref}
      $disabled={disabled}
      $orientation={orientation}
      $selected={selected}
      tabIndex={!disabled ? 0 : undefined}
      onClick={(e) => {
        if (!disabled) {
          onClick?.()
        }
      }}
      onKeyDown={(e) => {
        const { code } = e

        if (code === 'Enter' || code === 'Space') {
          onClick?.()
        }

        onKeyDown?.(e)
      }}
      {...props}
    >
      <TileContext.Provider value={{ orientation }}>
        {children}
      </TileContext.Provider>
    </StyledSelectableTile>
  )
})

const Tile_ = React.forwardRef<HTMLElement, TileProps>(function Tile(
  { variant = 'interactive', orientation = 'portrait', ...props },
  ref
) {
  switch (variant) {
    case 'interactive':
      return (
        <InteractableTile
          ref={ref as React.Ref<HTMLDivElement>}
          orientation={orientation}
          {...(props as Omit<InteractableTileProps, 'orientation'>)}
        />
      )
    case 'selectable':
      return (
        <SelectableTile
          ref={ref as React.Ref<HTMLDivElement>}
          orientation={orientation}
          {...(props as Omit<SelectableTileProps, 'orientation'>)}
        />
      )
    case 'link':
      return (
        <LinkTile
          ref={ref as React.Ref<HTMLAnchorElement>}
          orientation={orientation}
          {...(props as Omit<LinkTileProps, 'orientation'>)}
        />
      )
  }
})

export const Tile = addSubcomponents(
  { Actions, Content, Footer, HeroText, HeroMedia },
  Tile_
)
