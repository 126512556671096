import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../utils/styles'

const slugs = require(`github-slugger`)()

const isBrowser = typeof window !== 'undefined'

interface Heading {
  value: string
  depth: number
}

interface TOCProps {
  headings: Heading[]
}

const TOCView = styled.div`
  padding-right: ${layout.pagePadding}px;
  position: sticky;
  top: 24px;
`

const LinkView = styled.a<Heading>`
  align-items: center;
  border-left: 4px solid ${colors.f3f3f3};
  color: ${colors._202020};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
  padding: 4px 0 4px ${({ depth }) => (depth < 3 ? 16 : 32)}px;
  text-decoration: none;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.active {
    color: ${colors._006ddf};
    border-left-color: ${colors._006ddf};
  }
`

export function TOC({ headings }: TOCProps) {
  slugs.reset()

  if (!isBrowser) return <div />

  return (
    <TOCView>
      {headings.map((heading) => {
        const slug = slugs.slug(heading.value)
        return (
          <LinkView
            {...heading}
            href={`#${slug}`}
            key={slug}
            title={heading.value}
            className={`#${slug}` === window.location.hash ? 'active' : ''}
          >
            <span>{heading.value}</span>
          </LinkView>
        )
      })}
    </TOCView>
  )
}
