import * as React from 'react'
import { Box } from '../Box/Box'
import type { DivAttributes } from '../_utils/types'
import type { FlexProps } from './Flex.types'

/**
 * `Flex` is a wrapper around `Box` defaulting to display flex CSS
 * and providing shorter prop names (e.g. `shrink` instead of `flexShrink`).
 * `Box` can mimic anything `Flex` can.
 */
export const Flex = React.forwardRef<HTMLDivElement, DivAttributes & FlexProps>(
  function Flex(
    {
      alignItems = 'flex-start',
      basis,
      direction = 'row',
      gap,
      grow,
      flex,
      inline = false,
      justifyContent = 'flex-start',
      shrink,
      wrap,
      ...props
    },
    ref
  ) {
    return (
      <Box
        {...props}
        ref={ref}
        display={inline ? 'inline-flex' : 'flex'}
        alignItems={alignItems}
        justifyContent={justifyContent}
        flexBasis={basis}
        flexDirection={direction}
        flexGrow={grow}
        flexShrink={shrink}
        flexWrap={wrap}
        flex={flex}
        gap={gap}
      />
    )
  }
)

Flex.displayName = 'Flex'
