import type { Method } from 'axios'
import axios from 'axios'
import { isFunction } from '../_utils/isFunction'
import { runInQueue } from '../_utils/runInQueue'
export type UNSAFE_GetEndpoint = (file: File) => string
export type GetMethod = (file: File) => Method
export type GetPayload = (file: File) => FormData
export type UNSAFE_GetPayloadKey = (file: File) => string
export type GetHeaders = (file: File) => Record<string, string>
type UploadTargetId = string | number

type UploadTarget = {
  id: UploadTargetId
  blob: File
}

interface UploadFileCallbacks {
  /**
   * @since 10.19.0
   */
  onProgress: (progress: number) => void
  /**
   * @since 10.19.0
   */
  onSuccess: (response: unknown) => void
  /**
   * @since 10.19.0
   */
  onError: (error: unknown) => void
}

export interface UNSAFE_UploadFile {
  (file: File, callbacks: UploadFileCallbacks): void
}

export interface FileUploaderProps {
  /**
   * @since 10.19.0
   */
  getEndpoint?: UNSAFE_GetEndpoint
  /**
   * @since 10.19.0
   */
  getMethod?: GetMethod
  /**
   * @since 10.19.0
   */
  getPayload?: GetPayload
  /**
   * @since 10.19.0
   */
  getPayloadKey?: UNSAFE_GetPayloadKey
  /**
   * @since 10.19.0
   */
  getHeaders?: GetHeaders
  /**
   * @since 10.19.0
   */
  uploadFile?: UNSAFE_UploadFile
}

export interface FileUploader {
  /**
   * @since 10.19.0
   */
  uploadFiles: (
    files: UploadTarget[],
    {
      onCompleteAll,
      onComplete,
      onError,
      onProgress,
    }: {
      onCompleteAll?: () => void
      onComplete?: (fileId: UploadTargetId, response: unknown) => void
      onError?: (fileId: UploadTargetId, error: unknown) => void
      onProgress?: (fileId: UploadTargetId, progress: number) => void
    }
  ) => void
}
// max number of concurrent connections in most modern browsers
const maxConcurrentUpload = 6
const progressUpdateInterval = 750

function coroutine(generatorFunction: any) {
  return function (...args: any[]) {
    let generatorObject = generatorFunction(...args)
    generatorObject.next()
    return generatorObject
  }
}

async function* uploadQueue() {
  let uploadProcess = () => Promise.resolve()
  while (true) {
    uploadProcess = yield await uploadProcess()
  }
}

type Progress = {
  progress: number
  done: boolean
}

const queue = coroutine(uploadQueue)()

export function useFileUploader({
  getEndpoint: externalGetEndpoint,
  getMethod,
  getPayload,
  getPayloadKey,
  getHeaders,
  uploadFile,
}: FileUploaderProps): FileUploader {
  if (!externalGetEndpoint && !uploadFile) {
    throw new Error(
      'FileSelect.LocalSource: neither "getEndpoint" nor "uploadFile" is defined'
    )
  }

  return {
    uploadFiles: async (
      files,
      { onCompleteAll, onComplete, onError, onProgress }
    ) => {
      await queue.next(async () => {
        const uploadProgressState = new Map<UploadTarget['id'], Progress>()

        const updateProgress = (id: UploadTarget['id'], progress: number) => {
          const isCompleted = uploadProgressState.get(id)?.done || false

          if (!isCompleted) {
            uploadProgressState.set(id, {
              progress,
              done: isCompleted,
            })
          }
        }
        const completeProgressUpdate = (fileId: UploadTarget['id']) => {
          uploadProgressState.set(fileId, {
            progress: 100,
            done: true,
          })
        }

        const notifyProgress = () => {
          if (isFunction(onProgress)) {
            uploadProgressState.forEach(({ progress, done }, fileId) => {
              if (!done) {
                onProgress(fileId, progress)
              }
            })
          }
        }

        const upload = async (file: UploadTarget) => {
          const getEndpoint = externalGetEndpoint!

          const execFileUpload =
            uploadFile ??
            uploadFileViaNetwork.bind(null, {
              getEndpoint,
              getMethod,
              getPayload,
              getPayloadKey,
              getHeaders,
            })
          try {
            const response = await new Promise<unknown>((resolve, reject) => {
              execFileUpload(file.blob, {
                onSuccess: resolve,
                onProgress: (progress: number) => {
                  updateProgress(file.id, progress)
                },
                onError: reject,
              })
            })
            onComplete?.(file.id, response)
          } catch (err) {
            onError?.(file.id, err)
          } finally {
            completeProgressUpdate(file.id)
          }
        }

        // batch progress bar updates for better performance
        const progressNotifierId = setInterval(
          notifyProgress,
          progressUpdateInterval
        )
        await Promise.all(runInQueue(files, upload, maxConcurrentUpload))
        clearInterval(progressNotifierId)

        onCompleteAll?.()
      })
    },
  }
}

function getDefaultPayload(key: string, file: File) {
  const payload = new FormData()
  payload.append(key, file)

  return payload
}

async function uploadFileViaNetwork(
  {
    getEndpoint,
    getMethod = () => 'POST',
    getHeaders = () => ({}),
    getPayload,
    getPayloadKey = () => 'file',
  }: {
    getEndpoint: UNSAFE_GetEndpoint
    getMethod?: GetMethod
    getPayload?: GetPayload
    getPayloadKey?: UNSAFE_GetPayloadKey
    getHeaders?: GetHeaders
  },
  file: File,
  { onProgress, onSuccess, onError }: UploadFileCallbacks
) {
  const endpoint = getEndpoint(file)
  const method = getMethod(file)
  const headers = getHeaders(file)
  const payload = isFunction(getPayload)
    ? getPayload(file)
    : getDefaultPayload(getPayloadKey(file), file)

  try {
    const response = await axios({
      url: endpoint,
      method,
      data: payload,
      headers,
      onUploadProgress: (e) => {
        const progress = Math.floor((e.loaded / e.total!) * 100)
        onProgress(progress)
      },
    })
    onSuccess(response.data)
  } catch (err) {
    onError(err)
  }
}
