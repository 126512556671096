import { Popover } from '@procore/core-react'
import styled from 'styled-components'
import { codeStyling } from '../ContentComponents/MDX'

export const StyledPropsContainer = styled.div`
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  margin: 16px 0;
  overflow-x: auto;
  padding: 15px;
  width: 100%;
`

export const StyledPropsTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const StyledPropsThead = styled.thead`
  border-bottom: 1px solid #d9d9d9;
`

export const StyledPropsTh = styled.th`
  padding: 0 15px 5px 0;
  text-align: left;
`

export const StyledPropsTr = styled.tr<{ deprecated: string }>`
  text-decoration: ${(props) => (props.deprecated ? 'line-through' : 'none')};
  opacity: ${(props) => (props.deprecated ? '0.5' : 1)};
`

export const StyledPropsTd = styled.td`
  padding: 10px 40px 10px 0;
  vertical-align: top;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.4;

  code {
    ${codeStyling}
  }
`

export const StyledPropsTdName = styled(StyledPropsTd)`
  color: #9cc223;
`

export const StyledPropsTdType = styled(StyledPropsTd)`
  color: #c2707e;

  > div + div {
    margin-top: 8px;
  }
`

export const StyledPropsTdDefault = styled(StyledPropsTd)``

export const StyledPropsTdRequired = styled(StyledPropsTd)`
  color: #859cc1;
`

export const StyledPropsTdDescription = styled(StyledPropsTd)`
  width: 100%;
  min-width: 250px;

  .tsd-comment-tags {
    display: none;
  }
`

export const StyledPropsA11y = styled.div`
  margin-top: 10px;
  border-top: 1px solid #d9d9d9;
`

export const StyledPropsA11yTitle = styled.div`
  font-weight: bold;
  padding: 8px 0;
`

export const StyledPropsDeprecated = styled.div`
  width: 400px;
`

export const StyledPropsMeta = styled.div`
  border-radius: 3px;
  background: #f3f3f3;
  display: inline-block;
  font-family: monospace;
  margin-top: 20px;
  padding: 5px;
`

export const StyledPropsLink = styled.a`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #ba5062;
  }
`

export const StyledPropsPopoverContent = styled(Popover.Content)`
  padding: 12px;
  max-width: 480px;
`
