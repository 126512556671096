import styled from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { getShadow } from '../_styles/shadows'
import { spacing } from '../_styles/spacing'

export const StyledTooltip = styled.span`
  ${getTypographyIntent('small')}
  color: ${colors.white};
  max-width: 200px;
  padding: ${spacing.sm}px ${spacing.md}px;
  position: relative;

  // for IE11, use word-wrap property
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
`

export const StyledContainer = styled.span`
  display: inline-flex;
  background-color: ${colors.gray15};
  border-radius: ${borderRadius.md}px;
  ${getShadow(3, 'bottom')}

  & + [data-overlay-arrow] {
    background: ${colors.gray15};
  }
`
