import React from 'react'
import { MenuImperative } from '../MenuImperative/MenuImperative'
import { MultiSelect } from '../MultiSelect/MultiSelect'
import type {
  GroupItem,
  MultiSelectProps,
} from '../MultiSelect/MultiSelect.types'
import { StyledGroupSelectOption } from './GroupSelect.styles'
import type {
  ChangeEvent,
  GroupOption,
  GroupSelectConfig,
  GroupSelectOptionRendererParams,
  GroupSelectProps,
  Option,
} from './GroupSelect.types'

const emptyArray: Option[] = []
const noop: GroupSelectProps['onChange'] = () => {}
const defaultGetId: GroupSelectProps['getId'] = (item) => item.id
const defaultGetLabel: GroupSelectProps['getLabel'] = (item) => item.label
const defaultGetGroup: GroupSelectProps['getGroup'] = (item) => item.options
const defaultGetOptGroup: GroupSelectProps['getOptGroup'] = (item) =>
  item.groupId

function defaultOptionRenderer(
  item: Option | GroupOption,
  {
    getId,
    getLabel,
    disabled = false,
    value,
    ...props
  }: GroupSelectOptionRendererParams
) {
  return (
    <StyledGroupSelectOption
      {...props}
      key={`group-select-menu-item-${getId(item)}`}
      value={value || item}
      disabled={disabled}
    >
      {getLabel(item)}
    </StyledGroupSelectOption>
  )
}

function defaultOptGroupRenderer({ id, label }: GroupItem) {
  return (
    <MenuImperative.Group key={`group_${id}`} clickable={false}>
      {label}
    </MenuImperative.Group>
  )
}

function useGroupSelect({
  value,
  options,
  groups,
  getId,
  getLabel,
  getGroup,
  onChange,
  onSearch: _onSearch,
}: GroupSelectConfig) {
  const [searchValue, setSearchValue] = React.useState('')

  const groupOptionsDictionary: Record<string | number, Option> = React.useMemo(
    () =>
      groups.reduce(
        (acc, curr) => ({
          ...acc,
          [getId(curr)]: getGroup(curr),
        }),
        {}
      ),
    [getGroup, getId, groups]
  )

  const collectOptionIds = React.useCallback(
    (value: Option[]) =>
      value.reduce<Set<number | string>>(
        (acc, cur) => acc.add(getId(cur)),
        new Set()
      ),
    [getId]
  )

  const groupIds = React.useMemo(
    () => collectOptionIds(groups),
    [collectOptionIds, groups]
  )

  const isGroup = (groupdId: string | number) => groupIds.has(groupdId)

  const externalOnSearch = React.useCallback(
    (e: ChangeEvent) => {
      if (_onSearch) {
        _onSearch(e)
      }
    },
    [_onSearch]
  )

  const internalOnSearch = React.useCallback((e: ChangeEvent) => {
    setSearchValue(e.target.value)
  }, [])

  const onSearch = _onSearch ? externalOnSearch : internalOnSearch

  const selectedOptions = React.useMemo(
    () => value.filter((option) => !groupIds.has(getId(option))),
    [getId, groupIds, value]
  )

  const selectedOptionIds = React.useMemo(
    () => collectOptionIds(selectedOptions),
    [collectOptionIds, selectedOptions]
  )

  const selectedGroups = React.useMemo(
    () =>
      groups.filter(
        (group) =>
          getGroup(group).length &&
          getGroup(group).every((option: Option) =>
            selectedOptionIds.has(getId(option))
          )
      ),
    [getGroup, getId, groups, selectedOptionIds]
  )

  const selectedGroupIds = React.useMemo(
    () => collectOptionIds(selectedGroups),
    [collectOptionIds, selectedGroups]
  )

  const getGroupOptions = (groupOptiodId: string | number) => {
    const groupOptions = groupOptionsDictionary[groupOptiodId] || []
    return groupOptions.filter(
      (groupOption: Option) => !selectedOptionIds.has(getId(groupOption))
    )
  }

  const onSelect = (selection: (Option | GroupOption)[]) => {
    const newSelected = selection.reduce((acc, option) => {
      const optionId = getId(option)

      if (isGroup(optionId)) {
        return !selectedGroupIds.has(optionId)
          ? [...acc, ...getGroupOptions(optionId)]
          : acc
      }

      return [...acc, option]
    }, [])

    setSearchValue('')

    onChange(newSelected)
  }

  const searchFilterCallback = React.useCallback(
    (option) =>
      getLabel(option).toLowerCase().includes(searchValue.toLowerCase()),
    [getLabel, searchValue]
  )

  const computedOptions = _onSearch
    ? options
    : options.filter(searchFilterCallback)

  const filteredGroups = _onSearch
    ? groups
    : groups.filter(searchFilterCallback)

  const computedGroups = React.useMemo(
    () => filteredGroups.filter((group: Option) => getGroup(group).length),
    [filteredGroups, getGroup]
  )

  return {
    selectedOptions,
    computedOptions: [...computedGroups, ...computedOptions],
    selectedOptionIds,
    selectedGroupIds,
    isGroup,
    onSearch,
    onSelect,
  }
}

/**

 Group select allows users to select multiple options from a single item in a
 menu. Other select components are multi select, and select, tiered select.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-groupselect--custom-search)

 @see [Design Guidelines](https://design.procore.com/group-select)

 */
export const GroupSelect = React.forwardRef<
  HTMLDivElement & MultiSelectProps,
  GroupSelectProps
>(function GroupSelect(
  {
    value = emptyArray,
    options = emptyArray,
    groups = emptyArray,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    getGroup = defaultGetGroup,
    getOptGroup = defaultGetOptGroup,
    onChange = noop,
    onSearch: _onSearch,
    optionRenderer,
    groupRenderer,
    optGroupRenderer = defaultOptGroupRenderer,
    ...props
  },
  ref
) {
  const {
    selectedOptions,
    selectedOptionIds,
    selectedGroupIds,
    computedOptions,
    isGroup,
    onSelect,
    onSearch,
  } = useGroupSelect({
    value,
    options,
    groups,
    getId,
    getLabel,
    getGroup,
    onChange,
    onSearch: _onSearch,
  })

  const multiselectOptionRenderer = React.useCallback(
    (option: Option) => {
      const optionId = getId(option)
      const renderer =
        (isGroup(optionId) ? groupRenderer : optionRenderer) ||
        defaultOptionRenderer

      const isSelected = selectedOptionIds.has(optionId)
      const isDisabled = selectedGroupIds.has(optionId)

      return renderer(option, {
        getId,
        getLabel,
        value: option,
        selected: isSelected,
        disabled: isDisabled,
      })
    },
    [
      getId,
      getLabel,
      groupRenderer,
      isGroup,
      optionRenderer,
      selectedGroupIds,
      selectedOptionIds,
    ]
  )

  return (
    <MultiSelect
      ref={ref}
      {...props}
      value={selectedOptions}
      options={computedOptions}
      getId={getId}
      getLabel={getLabel}
      onChange={onSelect}
      onSearch={onSearch}
      getGroup={getOptGroup}
      optionRenderer={multiselectOptionRenderer}
      groupHeaderRenderer={optGroupRenderer}
    />
  )
})

GroupSelect.displayName = 'GroupSelect'
