import type { Config as EventListenerConfig } from './EventListener'
import { useEventListener } from './EventListener'

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface UseScrollConfig {
  /**
   * @since 10.19.0
   */
  onScroll: EventListenerConfig['handler']
  /**
   * @since 10.19.0
   */
  scope?: EventListenerConfig['scope']
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useScroll({ onScroll, scope = window }: UseScrollConfig) {
  return useEventListener({ event: 'scroll', handler: onScroll, scope })
}
