import { useListNavigation } from '../../_hooks/ListNavigation'
import type {
  SidebarNavigationApi,
  SidebarNavigationConfig,
} from './FileExplorer.types'

export function useSidebarNavigation({
  selectedIndex,
  ...props
}: SidebarNavigationConfig): SidebarNavigationApi {
  const listNavigation = useListNavigation({
    circular: true,
    ...props,
  })

  const up = () => {
    let shouldJumpOver: boolean

    if (listNavigation.index === 0) {
      shouldJumpOver = selectedIndex === listNavigation.size - 1
    } else {
      shouldJumpOver =
        (listNavigation.index - 1) % listNavigation.size === selectedIndex
    }

    if (shouldJumpOver) {
      listNavigation.decrementBy(2)
    } else {
      listNavigation.decrement()
    }
  }

  const down = () => {
    const shouldJumpOver =
      (listNavigation.index + 1) % listNavigation.size === selectedIndex

    if (shouldJumpOver) {
      listNavigation.incrementBy(2)
    } else {
      listNavigation.increment()
    }
  }

  return {
    ...listNavigation,
    up,
    down,
  }
}
