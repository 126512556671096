import { Dropdown } from '@procore/core-react'
import axios from 'axios'
import * as React from 'react'

import { version as currentVersion } from '../../../core-react/package.json'

type Versions = string[]

const VersionDropdown: React.FC<{}> = function VersionDropdown() {
  const [versionUrls, setVersionUrls] = React.useState<null | Versions>(null)

  React.useEffect(() => {
    axios
      .get('https://core.procore.com/versions')
      .then((response) => response.data)
      .then((versions: string[]) =>
        versions.map((version) => `https://core.procore.com/${version}`)
      )
      .then((versionUrls) => setVersionUrls(versionUrls))
      .catch((err) => console.log(err))
  }, [])

  if (!versionUrls) {
    return null
  }

  return (
    <Dropdown label={currentVersion}>
      {versionUrls.map((versionUrl) => {
        const urlArr = versionUrl.split('/')
        const version = urlArr[urlArr.length - 1]
        return (
          <Dropdown.Item item={versionUrl} key={versionUrl}>
            <a href={versionUrl} target="__blank" rel="noreferrer">
              {version}
            </a>
          </Dropdown.Item>
        )
      })}
    </Dropdown>
  )
}

export default VersionDropdown
