import { Checkbox, Content, P, Typography } from '@procore/core-react'
import * as React from 'react'
import styled from 'styled-components'

const GrayBox = styled.div`
  padding: 24px;
  border: 1.25px solid #eef0f1;
  background-color: #f9fafa;
`

const WhiteBox = styled.div`
  padding: 24px;
  border: 1.25px solid #eef0f1;
  background-color: #fff;
`

const StyledSection = styled.div`
  margin-bottom: 36px;
`

interface SectionProps {
  step: number
  title: string
  moreInfo: string
  contentPreview: string
}

export const CollapsibleSection: React.FC<SectionProps> = ({
  step,
  title,
  contentPreview,
  children,
  moreInfo,
}) => {
  return (
    <StyledSection>
      <GrayBox>
        <Content>
          <div>
            <Typography intent="small">STEP {step}</Typography>
          </div>
          <Typography intent="h1">{title}</Typography>
          <P>{contentPreview}</P>
        </Content>
      </GrayBox>
      <WhiteBox>{children}</WhiteBox>
      {moreInfo && (
        <GrayBox>
          <P>{moreInfo}</P>
        </GrayBox>
      )}
    </StyledSection>
  )
}

export const CollapsibleSectionItem: React.FC<{ title: string }> = ({
  title,
  children,
}) => {
  const [checked, setChecked] = React.useState(false)
  return (
    <Checkbox checked={checked} onClick={() => setChecked(!checked)}>
      {title && (
        <div>
          <Typography weight="bold">{title}</Typography>
        </div>
      )}
      {children && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </Checkbox>
  )
}
