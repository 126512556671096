import styled, { css } from 'styled-components'
import {
  getTypographyIntent,
  semiboldRules,
} from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getEllipsis, getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const StyledLabel = styled.span`
  ${getTypographyIntent('body')}

  ${getEllipsis}

  ${semiboldRules}

  flex: 0 1 auto;
`

export const StyledFilterToken = styled.div<{
  $focused: boolean
}>`
  align-items: center;
  background-color: ${colors.blue96};
  border-radius: 4px; // TODO investigate this magic number
  border: 1px solid transparent;
  color: ${colors.blue45};
  cursor: default;
  display: inline-flex;
  height: 36px;
  justify-content: space-between;
  line-height: 1; // TODO investigate this magic number
  max-width: 360px; // TODO investigate this magic number
  min-width: 48px; // TODO investigate this magic number
  padding: ${spacing.sm}px ${spacing.md}px;

  &:hover,
  &.hover {
    background-color: ${colors.blue90};
  }

  ${({ $focused = false }) =>
    $focused &&
    css`
      ${getFocus()}

      background-color: ${colors.blue96};
      border-color: ${colors.blue40}
      border-width: 2px;
    `}
`
