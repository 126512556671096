import { CalendarProps, CalendarContainerProps, NavigationProps, NextPrevProps, CalendarSelectProps, BodyProps, WeekdaysProps, WeekProps, DayProps } from '@procore/core-react/dist/_typedoc/Calendar/Calendar.types.json';
import * as React from 'react';
export default {
  CalendarProps,
  CalendarContainerProps,
  NavigationProps,
  NextPrevProps,
  CalendarSelectProps,
  BodyProps,
  WeekdaysProps,
  WeekProps,
  DayProps,
  React
};