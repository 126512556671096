import type { Config as EventListenerConfig } from './EventListener'
import { useEventListener } from './EventListener'

interface Config extends EventListenerConfig {
  /**
   * @since 10.19.0
   */
  onResize: EventListenerConfig['handler']
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useResize({
  onResize,
  scope = window,
}: Omit<Config, 'event' | 'handler'>) {
  return useEventListener({ event: 'resize', handler: onResize, scope })
}
