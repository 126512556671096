import React from 'react'
import {
  StyledCircle,
  StyledSpinnerContainer,
  StyledSpinnerContainerWithLabel,
  StyledSpinnerOverlay,
  StyledSpinnerOverlayContainer,
  StyledSpinnerOverlayLabel,
  StyledSpinnerSVG,
} from './Spinner.styles'
import type { SpinnerProps, SpinnerSize } from './Spinner.types'

const radiusMap: { [size in SpinnerSize]: number } = {
  xs: 18.5,
  sm: 18,
  md: 20,
  lg: 20.5,
}

export const defaultSpinnerSize = 'lg'

/**

 Spinners indicate a loading state on either a full page or smaller component,
 like a menu, select or button.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-spinner--demo)

 @see [Design Guidelines](https://design.procore.com/spinner)

 */
export const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
  function Spinner(
    {
      children,
      className = '',
      color,
      label,
      loading = false,
      size = defaultSpinnerSize,
      variant,
      centered = false,
      ...props
    },
    ref
  ) {
    const mountTime = React.useRef(Date.now())

    const circle = (
      <StyledSpinnerContainer
        className={!children ? className : ''}
        $color={color}
        $size={size}
        $variant={variant}
        ref={ref}
      >
        <StyledSpinnerSVG
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
          mountTime={mountTime.current}
        >
          <StyledCircle
            cx="24"
            cy="24"
            r={radiusMap[size]}
            mountTime={mountTime.current}
          />
        </StyledSpinnerSVG>
      </StyledSpinnerContainer>
    )

    if (!children) {
      return circle
    }

    return (
      <StyledSpinnerOverlayContainer
        $loading={loading}
        className={className}
        $centered={centered}
        {...props}
      >
        <StyledSpinnerOverlay />
        <StyledSpinnerContainerWithLabel>
          {circle}
          <StyledSpinnerOverlayLabel>{label}</StyledSpinnerOverlayLabel>
        </StyledSpinnerContainerWithLabel>
        {children}
      </StyledSpinnerOverlayContainer>
    )
  }
)

Spinner.displayName = 'Spinner'
