import styled, { css } from 'styled-components'

const headerStyling = css`
  margin-left: -24px;
  padding-left: 24px;

  &:hover {
    [aria-label*='permalink'] {
      display: block;
    }
  }
`

const textStyling = css`
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
`

export const p = styled.p`
  ${textStyling}
  padding: 10px 0;
`

export const h1 = styled.h1`
  font-size: 22px;
  letter-spacing: 0.15px;
  line-height: 32px;
  padding: 10px 0;
  ${headerStyling}
`

export const h2 = styled.h2`
  font-size: 22px;
  letter-spacing: 0.15px;
  line-height: 32px;
  padding: 10px 0;
  ${headerStyling}
`

export const h3 = styled.h3`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding: 10px 0;
  ${headerStyling}
`

export const h4 = styled.h4`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 20px;
  padding: 10px 0;
  ${headerStyling}
`

export const h5 = styled.h5`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 16px;
  padding: 10px 0;
  ${headerStyling}
`

export const h6 = styled.h6`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 16px;
  padding: 10px 0;
  ${headerStyling}
`

export const blockquote = styled.blockquote`
  border-left: 6px solid whitesmoke;
  margin-left: 8px;
  padding-left: 8px;
`

export const ul = styled.ul`
  ${textStyling}
  list-style: disc;
  list-style-position: outside;
  padding: 10px 0;

  ul {
    padding-bottom: 0;
  }

  li + li {
    margin-top: 8px;
  }
`

export const ol = styled.ol`
  ${textStyling}
  list-style: decimal;
  list-style-image: none;
  padding: 10px 0 10px 16px;

  li + li {
    margin-top: 8px;
  }
`

export const li = styled.li`
  margin-left: 16px;
`

export const table = styled.table`
  display: block;
  width: 100%;
  overflow: auto;
`

export const thead = styled.thead``

export const tbody = styled.tbody``

export const tr = styled.tr`
  background-color: #fff;
  border-top: 1px solid #c6cbd1;

  &:nth-child(2n) {
    background-color: #f6f8fa;
  }
`

export const th = styled.th`
  border: 1px solid #dfe2e5;
  font-weight: 600;
  padding: 6px 13px;
`

export const td = styled.td`
  border: 1px solid #dfe2e5;
  padding: 6px 13px;
`

export const em = styled.em`
  font-style: italic;
`

export const strong = styled.strong`
  font-weight: bold;
`

export const pre = styled.div`
  border-radius: 3px;
  margin: 8px 0;

  pre,
  textarea.prism-code,
  textarea.npm__react-simple-code-editor__textarea {
    overflow-x: scroll;
    line-height: 16px;
    font-family: monospace;
    padding: 16px !important; /* needed to override MDX inline styles */
  }
`

export const codeStyling = `
  border-radius: 3px;
  padding: 2px 6px 2px 6px;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  color: #707070;
`

export const code = styled.code`
  ${codeStyling}
`

export const inlineCode = styled.span`
  ${codeStyling}
`

export const hr = styled.hr``

export const a = styled.a`
  color: #006ddf;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const img = styled.img`
  max-width: 100%;
`
