export const overrideIfNotDefined = <T>(blueprint: T, obj: Partial<T>): T => {
  const result = {} as T

  Object.entries(blueprint).forEach(([key, value]) => {
    const key_ = key as keyof T
    result[key_] = obj[key_] ?? value
  })

  return result
}
