import styled from 'styled-components'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

const tableShelfShadow = '-4px 0px 5px 0px rgba(112, 112, 112, 0.1)'

export const StyledShelf = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: ${tableShelfShadow};
  background-color: ${colors.white};
`

export const StyledHead = styled.div`
  background-color: ${colors.gray96};
  transition: background-color 0.2s ease-in-out;
`

export const StyledBodyRow = styled.div`
  border-top: 1px solid ${colors.gray85};
  transition: background-color 0.2s ease-in-out;
`

export const StyledHeadCell = styled.div`
  text-align: left;
  padding: ${spacing.lg}px;
  line-height: 13px;
`

export const StyledBodyCell = styled.div`
  padding: ${spacing.lg}px;
  line-height: 13px;
`
