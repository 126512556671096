import { times } from 'ramda'
import React from 'react'
import { DropdownButton } from '../Dropdown/Dropdown'
import { MenuImperative } from '../MenuImperative/MenuImperative'
import type { MenuRef, Selection } from '../MenuImperative/MenuImperative.types'
import {
  OverlayTrigger,
  useOverlayTriggerContext,
} from '../OverlayTrigger/OverlayTrigger'
import { StyledOverlay } from './Pagination.styles'
import type { PageSelectProps, PaginationMenuProps } from './Pagination.types'

const noop = () => {}

const PaginationMenu = React.forwardRef<HTMLDivElement, PaginationMenuProps>(
  function PaginationMenu(
    { activePage, menuRef, onSelect: onSelect_ = noop, pages, ...props },
    ref
  ) {
    const ctx = useOverlayTriggerContext()

    const lastItemRef = React.useRef<HTMLDivElement>(null)

    const hasFooter = pages > 10

    React.useEffect(() => {
      menuRef.current?.highlightFirst()
    }, [menuRef])

    React.useEffect(() => {
      menuRef.current?.highlightSuggested()
      menuRef.current?.highlightSelected()
    }, [menuRef])

    function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
      const currentPage = menuRef.current?.highlighted()

      if (event.key === 'ArrowDown' || event.key === 'Down') {
        // when on the second to last item, want to jump to the last item (in the footer)
        if (currentPage === pages - 1 && lastItemRef.current) {
          menuRef.current?.highlight(lastItemRef.current)
        }
      } else if (event.key === 'ArrowUp' || event.key === 'Up') {
        // on the last item (in the footer), want to jump back to the second to last
        // item back in the menu
        if (hasFooter && currentPage === pages) {
          event.stopPropagation()
          menuRef.current?.highlightLast()
        }
      }
    }

    function onSelect(selection: Selection) {
      onSelect_(selection)

      ctx.hide(selection.event)
    }

    const children = times((index: number) => {
      const page = index + 1

      return (
        <MenuImperative.Item
          item={page}
          key={index}
          selected={page === activePage}
          ref={lastItemRef}
        >
          {page}
        </MenuImperative.Item>
      )
    }, pages)

    return (
      <StyledOverlay ref={ref} shadowStrength={2}>
        <MenuImperative
          circular
          {...props}
          ref={menuRef}
          onKeyDown={onKeyDown}
          onSelect={onSelect}
        >
          {hasFooter ? (
            <>
              <MenuImperative.Options>
                {children.slice(0, -1)}
              </MenuImperative.Options>
              <MenuImperative.Footer padding="xs none">
                {children[children.length - 1]}
              </MenuImperative.Footer>
            </>
          ) : (
            <MenuImperative.Options>{children}</MenuImperative.Options>
          )}
        </MenuImperative>
      </StyledOverlay>
    )
  }
)

export const PaginationSelect = React.forwardRef<
  HTMLButtonElement,
  PageSelectProps
>(function PaginationSelect(
  {
    activePage = 1,
    [`aria-labelledby`]: ariaLabelledby,
    disabled,
    id,
    onSelect,
    pages = 0,
    ...props
  },
  ref
) {
  const menuRef = React.useRef<MenuRef>(null)

  // Safari has an issue with giving focus to buttons after clicking on them
  // if the button or menu do not have focus, the keyboard navigation does not work,
  // so just focus the menu when we open it
  function afterShow() {
    // this is brittle, if MenuImperative dom structure changes this could break
    // but super specific to pagination, not really a concern elsewhere
    ;(menuRef.current?.el?.firstChild as HTMLDivElement)?.focus()
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLButtonElement>) {
    if (e.key === 'Up' || e.key === 'ArrowUp') {
      e.preventDefault()
      menuRef.current?.prev()
    } else if (e.key === 'Down' || e.key === 'ArrowDown') {
      e.preventDefault()
      menuRef.current?.next()
    } else if (e.key === 'Enter') {
      e.preventDefault()
      menuRef.current?.select(e)
    }
  }

  return (
    <OverlayTrigger
      role="listbox"
      passA11yPropsToOverlay
      autoFocus
      afterShow={afterShow}
      overlay={
        <PaginationMenu
          activePage={activePage}
          menuRef={menuRef}
          onSelect={onSelect}
          pages={pages}
        />
      }
      placement="bottom-left"
      ref={ref}
      trigger="click"
      {...props}
    >
      <DropdownButton
        aria-labelledby={ariaLabelledby}
        arrow
        disabled={disabled}
        id={id}
        onKeyDown={onKeyDown}
        variant="tertiary"
      >
        {activePage}
      </DropdownButton>
    </OverlayTrigger>
  )
})
