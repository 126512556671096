import { Computer } from '@procore/core-icons/dist'
import type { Method } from 'axios'
import { findIndex, propEq, remove } from 'ramda'
import React from 'react'
import { ulid } from 'ulid'
import { DropzoneDefaultMessage } from '../../Dropzone/Dropzone'
import { useDropzone } from '../../Dropzone/Dropzone.hooks'
import type {
  DropError,
  DropzoneContentProps,
  DropzoneRef,
} from '../../Dropzone/Dropzone.types'
import { dropErrors } from '../../Dropzone/Dropzone.types'
import { useFileSelectContext } from '../../FileSelect/FileSelect'
import { useI18nContext } from '../../_hooks/I18n'
import { useResizeObserver } from '../../_hooks/ResizeObserver'
import { useFileUploader } from '../../_hooks/useFileUploader'
import { mergeRefs } from '../../_utils/mergeRefs'
import { ProgressAnnouncer } from '../ProgressAnnouncer'
import {
  defaultListHeight,
  defaultRowHeight,
} from '../ThumbnailList/ThumbnailList'
import type { ThumbnailListItem } from '../ThumbnailList/ThumbnailList.types'
import {
  dropzoneHeight,
  errorBannerMarginBottom,
  errorBannerMarginTop,
  listBorderWidth,
  listMarginTop,
  StyledDropzoneErrorBanner,
  StyledDropzoneWrapper,
  StyledLocalSource,
  StyledThumbnailList,
  uploaderHeight,
} from './LocalSource.styles'
import type {
  LocalSourceProps,
  UploaderProps,
  ValueId,
} from './LocalSource.types'

function defaultGetMethod(): Method {
  return 'POST'
}

function defaultGetPayloadKey() {
  return 'file'
}

function defaultGetHeaders() {
  return {}
}

export const LocalSource = React.forwardRef<HTMLDivElement, LocalSourceProps>(
  function LocalSource(
    {
      sourceId,
      getEndpoint,
      getMethod = defaultGetMethod,
      getPayloadKey = defaultGetPayloadKey,
      getPayload,
      getHeaders = defaultGetHeaders,
      uploadFile,
      maxFileSize,
      minFileSize,
      accept,
      dropzoneContentRenderer,
      qa,
      beforeLocalFileUpload,
    },
    ref
  ) {
    const I18n = useI18nContext()
    const [uploadProgress, setUploadProgress] = React.useState<
      Record<ValueId, number | null>
    >({})
    const fileUploader = useFileUploader({
      getEndpoint,
      getMethod,
      getPayloadKey,
      getPayload,
      getHeaders,
      uploadFile,
    })
    const {
      register,
      currentSource,
      maxFileNumber,
      onChange,
      setIsUploading,
      setHasError: setIsError,
      onResetValue,
      isModalOpen,
    } = useFileSelectContext()

    const disabled = maxFileNumber < 1

    const [value, setValue] = React.useState<ThumbnailListItem[]>([])

    const [dropError, setDropError] = React.useState<DropError | undefined>()

    React.useEffect(() => {
      const unregister = register(sourceId, {
        title: I18n.t('core.fileSelect.localFilesSource'),
        icon: <Computer />,
      })
      const unsubscribe = onResetValue(() => {
        setUploadProgress({})
        setValue([])
        setDropError(undefined)
      })
      return () => {
        unsubscribe()
        unregister()
      }
    }, [])

    const uploadedValue = React.useMemo(
      () =>
        value.filter((v) => {
          const hasError = Boolean(v.error)
          const hasResponse = 'response' in v
          return !hasError && hasResponse
        }),
      [value]
    )

    React.useEffect(() => {
      onChange(sourceId, uploadedValue)
    }, [uploadedValue])

    React.useEffect(() => {
      if (!isModalOpen) {
        setDropError(undefined)
      }
    }, [isModalOpen])

    // update error/uploading state in FileSelect sidebar
    React.useEffect(() => {
      const noItems = !value.length
      const hasItemsPendingUpload = Object.values(uploadProgress)
        .map((progress) => progress ?? 100)
        .some((progress) => progress < 100)
      const hasItemsWithUploadError = value.some(({ error }) => error)

      if (noItems) {
        setIsUploading(sourceId, false)
        setIsError(sourceId, false)
      } else {
        setIsUploading(sourceId, hasItemsPendingUpload)
        setIsError(sourceId, hasItemsWithUploadError)
      }
    }, [value, uploadProgress])

    if (currentSource !== sourceId) {
      return null
    }

    const onChangeInternal = async (files: ThumbnailListItem[]) => {
      if (!files.length) return

      const shouldAttach = await beforeLocalFileUpload(files)
      if (!shouldAttach) return

      const newFiles = files.map((item) => ({
        ...item,
        error: '',
        progress: 0,
      }))

      setValue((prevItems) => [...prevItems, ...newFiles])

      const updateFile = (
        fileId: ValueId,
        payload: Partial<ThumbnailListItem>
      ) => {
        setValue((prev) =>
          prev.map((v) => {
            if (v.id === fileId) {
              return {
                ...v,
                ...payload,
              }
            }
            return v
          })
        )
      }

      const filesToUpload = files.map((e) => ({
        id: e.id,
        blob: e.src as File,
      }))

      const initialUploadProgress = filesToUpload.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: 0 }),
        {}
      )

      setUploadProgress((prev) => ({
        ...prev,
        ...initialUploadProgress,
      }))

      fileUploader.uploadFiles(filesToUpload, {
        onComplete: (id, response) => {
          updateFile(id, { response })
          setUploadProgress((prev) => ({
            ...prev,
            [id]: 100,
          }))
        },
        onCompleteAll: () => {
          setIsUploading(sourceId, false)
        },
        onProgress: (id, progress) => {
          setIsUploading(sourceId, true)
          setUploadProgress((prev) => ({
            ...prev,
            [id]: Math.min(99, progress),
          }))
        },
        onError: (id) => {
          const error = I18n.t('core.fileExplorer.uploadFailed')
          updateFile(id, { error })
          setUploadProgress((prev) => ({
            ...prev,
            [id]: 100,
          }))
        },
      })
    }

    const onCancel = (item: ThumbnailListItem) => {
      setValue((prevItems) =>
        remove(findIndex(propEq('id', item.id), prevItems), 1, prevItems)
      )
      setUploadProgress((prev) => ({
        ...prev,
        [item.id]: null,
      }))
    }

    return (
      <>
        <ProgressAnnouncer items={value} uploadProgress={uploadProgress} />
        <Uploader
          ref={ref}
          qa={qa}
          multiple
          accept={accept}
          disabled={disabled}
          maxFileNumber={maxFileNumber}
          maxFileSize={maxFileSize}
          minFileSize={minFileSize}
          dropError={dropError}
          onDropError={setDropError}
          uploadedValue={uploadedValue}
          value={value.map((e) => ({
            ...e,
            progress: uploadProgress[e.id] ?? 0,
          }))}
          onChange={onChangeInternal}
          onCancel={onCancel}
          dropzoneContentRenderer={dropzoneContentRenderer}
        />
      </>
    )
  }
)

const collapsedBannerThreshold = 116
const spareHeightForListAccountingErrorBannerMaxHeight = 14

const Uploader = React.forwardRef<HTMLDivElement, UploaderProps>(
  function Uploader(
    {
      value,
      uploadedValue,
      onChange,
      onCancel,
      onDrop,
      disabled,
      multiple,
      accept,
      dropError,
      onDropError,
      qa,
      maxFileNumber = Infinity,
      minFileSize,
      maxFileSize,
      dropzoneContentRenderer,
    },
    ref
  ) {
    const dropzoneRootRef = React.useRef<DropzoneRef>(null)
    const errorBannerRef = React.useRef<HTMLDivElement | null>(null)
    const valueWithoutErrors = value.filter((v) => !v.error).map((v) => v.src)
    const dropzoneState = useDropzone({
      value: valueWithoutErrors,
      onDrop: (acceptedFiles, rejectedFiles, event) => {
        onChange(
          acceptedFiles.map((src) => ({
            id: ulid(),
            src,
            name: src.name,
          }))
        )
        onDrop && onDrop(acceptedFiles, rejectedFiles, event)
      },
      multiple,
      accept,
      maxFileSize,
      minFileSize,
      maxFileNumber: maxFileNumber + uploadedValue.length,
      disabled,
    })

    // copy and lift dropError to make it controlled
    React.useLayoutEffect(() => {
      onDropError(dropzoneState.dropError)
    }, [dropzoneState.dropError])

    const dropErrorInternal = dropError || {
      message: '',
      title: '',
      type: 'RESET',
    }

    const [errorBannerHeight, setErrorBannerHeight] = React.useState(0)
    const [isErrorBannerCollapsed, setIsErrorBannerCollapsed] =
      React.useState(true)

    const { length: itemsCount } = value
    const isErrorBannerShown = Boolean(
      dropErrorInternal.title || dropErrorInternal.message
    )

    const filesLimitForIcon = 5
    const isIconVisible =
      itemsCount < filesLimitForIcon && isErrorBannerCollapsed

    const getDropzoneHeight = () => {
      const errorBannerHeight = getErrorBannerHeight()
      const listHeight = getListHeight(false)

      return Math.max(
        uploaderHeight - listHeight - errorBannerHeight,
        dropzoneHeight
      )
    }

    const getErrorBannerHeight = () => {
      const errorBanner = errorBannerRef?.current

      return errorBanner && isErrorBannerShown
        ? Math.floor(errorBanner.getBoundingClientRect().height) +
            errorBannerMarginTop +
            errorBannerMarginBottom
        : 0
    }

    const getListHeight = (clientHeight = true) => {
      const maxListHeight = Math.min(
        itemsCount * defaultRowHeight,
        defaultListHeight
      )
      const listMarginsAndBorders = itemsCount
        ? listMarginTop + 2 * listBorderWidth
        : 0
      const listHeight = itemsCount
        ? maxListHeight + (clientHeight ? 0 : listMarginsAndBorders)
        : 0
      const minListHeight = ((itemsCount) => {
        switch (itemsCount) {
          case 0:
            return 0
          case 1:
            return defaultRowHeight
          default:
            // Ensure that the list is scrollable
            return (
              defaultRowHeight +
              spareHeightForListAccountingErrorBannerMaxHeight
            )
        }
      })(itemsCount)

      return Math.max(
        listHeight - errorBannerHeight,
        minListHeight + (clientHeight ? 0 : listMarginsAndBorders)
      )
    }

    const updateDropzoneHeight = () => {
      const dropzoneRootEl = dropzoneRootRef?.current?.rootRef.current
      if (dropzoneRootEl) {
        dropzoneRootEl.style.height = getDropzoneHeight() + 'px'
      }
    }

    const [listHeight, setListHeight] = React.useState(getListHeight())

    const setResizeObserverTarget = useResizeObserver(() => {
      const errorBannerHeight = getErrorBannerHeight()
      setErrorBannerHeight(errorBannerHeight)
      setIsErrorBannerCollapsed(errorBannerHeight < collapsedBannerThreshold)
    })

    React.useLayoutEffect(() => {
      const listHeight = getListHeight()
      setListHeight(listHeight)
      updateDropzoneHeight()
    }, [itemsCount, errorBannerHeight, isErrorBannerShown])

    React.useEffect(() => {
      if (!isErrorBannerShown) {
        setErrorBannerHeight(0)
        setIsErrorBannerCollapsed(true)
      }
    }, [isErrorBannerShown])

    return (
      <StyledLocalSource ref={ref} data-qa={qa?.dropzone}>
        <StyledDropzoneWrapper
          {...dropzoneState}
          isIconVisible={isIconVisible}
          contentRenderer={(props: DropzoneContentProps) => (
            <>
              <DropzoneDefaultMessage {...{ ...props, multiple }} />
              {dropzoneContentRenderer?.(props)}
            </>
          )}
          rootProps={{
            style: { height: 'auto', transition: 'none' },
          }}
          ref={dropzoneRootRef}
        />
        <StyledDropzoneErrorBanner
          error={dropErrorInternal}
          qa={{
            showErrorDetails: qa?.showErrorDetails,
            hideError: qa?.hideError,
          }}
          fileRejections={dropzoneState.fileRejections}
          onDismiss={() => dropzoneState.dispatchDropError(dropErrors.reset)}
          ref={mergeRefs(errorBannerRef, setResizeObserverTarget)}
        />
        <StyledThumbnailList
          items={value}
          onCancel={onCancel}
          listHeight={listHeight}
          rowHeight={defaultRowHeight}
        />
      </StyledLocalSource>
    )
  }
)
