import {
  Card,
  Flex,
  FlexList,
  Portal,
  Switch,
  useClickOutside,
} from '@procore/core-react'
import '@fontsource/lato'
import * as React from 'react'
import styled from 'styled-components'
import { Footer, FooterView } from './src/partials/Footer'
import HamburgerIcon from './src/partials/HamburgerIcon'
import { Header } from './src/partials/Header'
import { Sidebar } from './src/partials/Sidebar'
import { Auth0Provider, useAuth0 } from './src/utils/auth'

const DeferredRender = ({ children }) => {
  const [deferred, setDeferred] = React.useState(true)
  setTimeout(() => setDeferred(false), 0)

  return deferred ? null : <div>{children}</div>
}

export const wrapRootElement = ({ element }) => {
  return (
    <DeferredRender>
      <Auth0Provider
        domain={process.env.GATSBY_AUTH0_DOMAIN || ''}
        client_id={process.env.GATSBY_AUTH0_CLIENTID || ''}
      >
        {element}
      </Auth0Provider>
    </DeferredRender>
  )
}

const IconView = styled(HamburgerIcon)`
  position: absolute;
  top: 30px;
  left: 240px;
  z-index: 100000;
  display: none;
  @media only screen and (max-width: 1100px) {
    display: block;
  }
`

const SidebarView = styled.div`
  width: 300px;
  height: 100%;
  overflow-y: scroll;

  @media only screen and (max-width: 1100px) {
    top: 80px;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
    display: ${(props) => (props.sidebarShown ? 'block' : 'none')};
    z-index: 1000;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.26),
      0px 10px 20px rgba(0, 0, 0, 0.19);
  }

  ${FooterView} {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 48px;

    a {
      margin-top: 16px;
      padding: 0;
    }
  }
`

const ContentView = styled.div`
  flex: 1;
  overflow-y: scroll;
  height: 100%;
`

const PageView = styled(Flex)`
  background: #f1f3f3;
  font-family: ${(props) =>
    props.legacyFont
      ? 'Lato, Arial, sans-serif'
      : 'Inter, Noto, Arial, sans-serif'};
  height: 100vh;
  width: 100vw;
`

const SidebarContentView = styled(Flex)`
  height: 100%;
`

const StyledCard = styled(Card)`
  position: fixed;
  bottom: 20px;
  right: 20px;
`

const FontToggler = ({ legacyFont, setLegacyFont }) => {
  const { isAuthenticated } = useAuth0()

  return isAuthenticated ? (
    <Portal>
      <StyledCard level="30">
        <FlexList padding="md" justifyContent="space-between">
          Show using Lato
          <Switch
            checked={legacyFont}
            onChange={() => setLegacyFont(!legacyFont)}
          />
        </FlexList>
      </StyledCard>
    </Portal>
  ) : null
}

const PageWrapper = ({ children }) => {
  const [legacyFont, setLegacyFont] = React.useState(false)
  const [sidebarShown, setSidebarShown] = React.useState(false)
  const menuIconRef = React.useRef(null)
  const sidebarRef = React.useRef(null)

  function handleOnClickOutside(e) {
    setSidebarShown(false)
  }

  useClickOutside({
    onClickOutside: handleOnClickOutside,
    refs: [sidebarRef, menuIconRef],
  })

  return (
    <PageView direction="column" alignItems="stretch" legacyFont={legacyFont}>
      <div>
        <IconView
          open={sidebarShown}
          onClick={() => setSidebarShown(!sidebarShown)}
          ref={menuIconRef}
        />
        <Header fillBackground />
      </div>
      <SidebarContentView direction="row">
        <SidebarView sidebarShown={sidebarShown}>
          <Sidebar ref={sidebarRef} />
          <Footer />
        </SidebarView>
        <ContentView>{children}</ContentView>
      </SidebarContentView>
      <FontToggler legacyFont={legacyFont} setLegacyFont={setLegacyFont} />
    </PageView>
  )
}

export const wrapPageElement = ({
  element,
  props: {
    pageContext: { layout },
  },
}) => {
  return layout === 'home' ? element : <PageWrapper>{element}</PageWrapper>
}
