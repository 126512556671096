import styled from 'styled-components'
import { Box } from '../Box/Box'
import { Card } from '../Card/Card'
import { Page } from '../PageLayout/PageLayout'
import { getActions, StyledPageBanner } from '../PageLayout/PageLayout.styles'
import {
  sectionClassnames,
  StyledSection,
  StyledSectionInner,
} from '../Section/Section.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

export const StyledDetailPage = styled.div`
  background-color: ${colors.gray96};
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const StyledDetailPageBody = styled.div`
  padding-bottom: ${spacing.xl}px;
`

export const StyledDetailPageFooterNotation = styled.div`
  flex-grow: 1;
  z-index: 1;
`

export const StyledDetailPageFooter = styled(Page.Footer)`
  display: flex;
  padding: ${spacing.lg}px 0;
  background-color: ${colors.white};
  justify-content: center;
`

export const StyledDetailPageFooterActions = styled(Box)`
  ${getActions()}
`

const maxFooterContentWidth = 1920

export const StyledDetailPageFooterContent = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0 ${spacing.xl}px;
  max-width: ${maxFooterContentWidth}px;
`

const detailPageCardBorderRadius = 8

export const StyledDetailPageCard = styled(Card)`
  width: 100%;
  border-radius: ${detailPageCardBorderRadius}px;
  margin-top: ${spacing.lg}px;
  margin-bottom: ${spacing.sm}px;

  & + & {
    margin-top: ${spacing.sm}px;
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: ${spacing.lg}px;
  }

  & > ${StyledPageBanner} {
    padding: ${spacing.lg}px ${spacing.lg}px 0 ${spacing.lg}px;
    max-width: 100%;

    &:empty {
      padding: 0;
    }
  }

  & > ${StyledPageBanner} + ${StyledSection} > ${StyledSectionInner} {
    border-top: 0;
  }

  > .${sectionClassnames.levelAnonymous}:first-child
    + .${sectionClassnames.level(2)},
    > ${StyledPageBanner}
    + .${sectionClassnames.levelAnonymous}
    + .${sectionClassnames.level(2)} {
    margin-top: ${spacing.xxl * -1}px;
  }
`
