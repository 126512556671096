import type { Method } from 'axios'
import React from 'react'
import { ulid } from 'ulid'
import type { PortalProps } from '../Portal/Portal.types'
import { useI18nContext } from '../_hooks/I18n'
import { useFileUploader } from '../_hooks/useFileUploader'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { DivAttributes } from '../_utils/types'
import { FileExplorer } from './FileExplorer/FileExplorer'
import { StyledFileSelect, StyledFileTokenList } from './FileSelect.styles'
import type {
  FileSelectContextApi,
  FileSelectProps,
  FileSelectRef,
  FileSelectSource,
  FileSelectSourceIdType,
  FileSelectValue,
  FileSelectValueEntry,
} from './FileSelect.types'
import { FileSelectDropzone } from './FileSelectDropzone/FileSelectDropzone'
import type { FileSelectDropzoneRef } from './FileSelectDropzone/FileSelectDropzone.types'
import { GridSource } from './GridSource/GridSource'
import { LocalSource } from './LocalSource/LocalSource'
import type { ValueId } from './LocalSource/LocalSource.types'
import { ProgressAnnouncer } from './ProgressAnnouncer'
import { TreeSource } from './TreeSource/TreeSource'

export const FileSelectLocalSourceId = 'FileSelectLocalSource'

const defaultGetTokenLabel = (sourceId: string, sourceValueEntry: unknown) =>
  (sourceValueEntry as { name: string }).name

const defaultGetMethod = () => 'POST' as Method
const defaultGetPayloadKey = () => 'file'
const defaultGetHeaders = () => ({})

const getValueTokenLabel = (
  sourceId: FileSelectSourceIdType,
  entry: FileSelectValueEntry,
  getTokenLabel: FileSelectProps['getTokenLabel'] = defaultGetTokenLabel
) => {
  const namePlaceholder = 'Untitled'
  const name = getTokenLabel(sourceId, entry) ?? entry.name

  if (!name) {
    console.warn(
      `FileSelect: Couldn't derive the name of "${sourceId}" source value. Check the source or use "getTokenLabel" prop`
    )
  }

  return name ?? namePlaceholder
}

type FileSelectDOMAttributes = Omit<DivAttributes, 'onError' | 'onProgress'>

const FileSelectBase = React.forwardRef<
  FileSelectRef,
  FileSelectProps & FileSelectDOMAttributes & PortalProps
>(
  (
    {
      onAdd,
      beforeLocalFileUpload = () => new Promise((resolve) => resolve(true)),
      onRemove,
      onProgress,
      onUploadStateChange,
      onError,
      onFileTokenClick,
      afterHide,

      dropzoneContentRenderer,
      hideDropzone = false,
      getTokenLabel = defaultGetTokenLabel,

      hideLocalSource = false,
      localSourceDropzoneContentRenderer,

      maxFileNumber = Infinity,
      maxFileSize,
      minFileSize,
      accept,

      getEndpoint,
      getMethod = defaultGetMethod,
      getPayloadKey = defaultGetPayloadKey,
      getPayload,
      getHeaders = defaultGetHeaders,
      uploadFile,

      children,
      qa,
      ...props
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [currentSource, setCurrentSource] =
      React.useState<FileSelectSourceIdType>('')
    const [sources, setSources] = React.useState<FileSelectSource[]>([])
    const I18n = useI18nContext()
    const fileUploader = useFileUploader({
      getEndpoint,
      getMethod,
      getPayloadKey,
      getPayload,
      getHeaders,
      uploadFile,
    })
    const [value, setValue] = React.useState<FileSelectValue>([])
    const [uploadProgress, setUploadProgress] = React.useState<
      Record<ValueId, number | null>
    >({})
    const [pendingUploadsCount, setPendingUploadsCount] = React.useState(0)

    const modalElRef = React.useRef<HTMLDivElement>(null)
    const rootElRef = React.useRef<HTMLDivElement>(null)
    const dropzoneRef = React.useRef<FileSelectDropzoneRef>(null)

    React.useEffect(() => {
      onUploadStateChange?.(pendingUploadsCount > 0)
    }, [pendingUploadsCount])

    React.useImperativeHandle(ref, () => ({
      setValue,
      open: (sourceId) => {
        setIsOpen(true)
        const newCurrentSource = sourceId ?? sources[0]?.sourceId
        if (newCurrentSource) {
          setCurrentSource(newCurrentSource)
        }
      },
      close: () => {
        setIsOpen(false)
      },
      resetSources: () => {
        resetListeners.current?.forEach((cb) => cb())
      },
      modalEl: modalElRef,
      rootEl: rootElRef,
    }))

    const uploadedValues = value.filter((entry) => !entry.error)
    const adjustedMaxFileNumber = maxFileNumber - uploadedValues.length
    const hasReachedMaxFileNumber = adjustedMaxFileNumber < 1

    const unsubmittedSourceValues = sources.flatMap((source) => source.value)

    const updateFile = (
      fileId: ValueId,
      payload: Partial<FileSelectValueEntry>
    ) => {
      setValue((prev) =>
        prev.map((v) => {
          if (v.id === fileId) {
            return {
              ...v,
              ...payload,
            }
          }
          return v
        })
      )
    }

    function onProgressInternal(id: ValueId, progress: number) {
      onProgress?.(id, progress)
      setUploadProgress((prev) => ({
        ...prev,
        [id]: Math.min(99, progress),
      }))
    }

    function onErrorInternal(id: ValueId, error: unknown) {
      updateFile(id, { error: I18n.t('core.fileSelect.uploadFailed') })
      onError?.(id, error)
      setUploadProgress((prev) => ({
        ...prev,
        [id]: 100,
      }))
    }

    const uploadFiles = (newFiles: FileSelectValue) => {
      function onComplete(id: ValueId, response: unknown) {
        const localFile = newFiles.find(
          (entry) => entry.id === id
        ) as FileSelectValueEntry
        updateFile(id, { response })

        const uploadedFile = {
          ...localFile,
          sourceId: FileSelectLocalSourceId,
          response,
        }
        onAdd?.([uploadedFile])
        setUploadProgress((prev) => ({
          ...prev,
          [id]: 100,
        }))
      }

      setPendingUploadsCount((prev) => prev + 1)
      fileUploader.uploadFiles(
        newFiles.map((entry) => ({ id: entry.id, blob: entry.src as File })),
        {
          onComplete,
          onProgress: onProgressInternal,
          onError: onErrorInternal,
          onCompleteAll: () => {
            setPendingUploadsCount((prev) => prev - 1)
          },
        }
      )
    }

    const onDrop = async (acceptedFiles: File[]) => {
      const newFiles: FileSelectValue = acceptedFiles
        .slice(0, adjustedMaxFileNumber)
        .map((file) => ({
          src: file,
          id: ulid(),
          name: file.name,
          sourceId: FileSelectLocalSourceId,
        }))

      const shouldAttach = await beforeLocalFileUpload(newFiles)
      if (!shouldAttach) return

      setUploadProgress((prev) =>
        newFiles.reduce((acc, item) => {
          acc[item.id] = 0
          return acc
        }, prev)
      )

      setValue((prev) => [
        ...prev,
        ...newFiles.map((file) => ({
          ...file,
          name: getValueTokenLabel(
            FileSelectLocalSourceId,
            file,
            getTokenLabel
          ),
        })),
      ])

      uploadFiles(newFiles)
    }

    const onFileExplorerSubmit = () => {
      setIsOpen(false)

      const values = sources.flatMap((source) => {
        if (source.sourceId === FileSelectLocalSourceId) {
          return source.value.map((v) => {
            const name = getValueTokenLabel(source.sourceId, v, getTokenLabel)
            return {
              ...v,
              name,
              sourceId: FileSelectLocalSourceId,
            }
          })
        }

        return source.value.map((entry) => {
          const name = getValueTokenLabel(source.sourceId, entry, getTokenLabel)

          return {
            id: ulid(),
            name,
            sourceId: source.sourceId,
            origin: entry,
          }
        })
      })
      setValue((prev) => [...prev, ...values])
      onAdd?.(values)

      clearErrors()
      resetListeners.current.forEach((resetListener) => resetListener?.())
      setCurrentSource(sources[0]?.sourceId)
      dropzoneRef.current?.attachButton?.focus()
      afterHide?.('submit')
    }

    const onFileExplorerCancel = () => {
      setIsOpen(false)
      resetListeners.current.forEach((resetListener) => resetListener?.())
      setCurrentSource(sources[0]?.sourceId)
      dropzoneRef.current?.attachButton?.focus()
      afterHide?.('cancel')
    }

    const clearErrors = () => {
      dropzoneRef.current?.clearErrors()
    }

    const handleFileRemoval = (value: FileSelectValueEntry) => {
      setValue((prev) => prev.filter((entry) => entry.id !== value.id))
      onRemove?.(value)
      setUploadProgress((prev) => ({
        ...prev,
        [value.id]: null,
      }))
      clearErrors()
    }

    const updateSource = (
      sourceId: FileSelectSourceIdType,
      props: Partial<FileSelectSource>
    ) => {
      setSources((prev) =>
        prev.map((source) => {
          if (source.sourceId === sourceId) {
            return {
              ...source,
              ...props,
            }
          }
          return source
        })
      )
    }

    const onAttachFromProject = () => {
      setIsOpen(true)
      if (!currentSource) {
        setCurrentSource(sources[0]?.sourceId)
      }
    }

    const resetListeners = React.useRef<(() => void)[]>([])

    return (
      <StyledFileSelect {...props} ref={rootElRef}>
        <FileExplorer
          ref={modalElRef}
          container={props.container}
          qa={{ attachButton: qa?.attachButton }}
          unsubmittedValueCount={unsubmittedSourceValues.length}
          onCancel={onFileExplorerCancel}
          onSubmit={onFileExplorerSubmit}
          sources={sources}
          currentSource={currentSource}
          onCurrentSourceChange={setCurrentSource}
          isOpen={isOpen}
        >
          <FileSelectContext.Provider
            value={{
              register: (sourceId, source) => {
                setSources((prev) => [
                  ...prev,
                  {
                    title: '',
                    icon: '',
                    isUploading: false,
                    hasError: false,
                    value: [],
                    ...source,
                    sourceId,
                  },
                ])
                return () => {
                  setSources((prev) =>
                    prev.filter((source) => source.sourceId !== sourceId)
                  )
                }
              },
              currentSource,
              onChange: (sourceId, value) => {
                updateSource(sourceId, { value })
              },
              onResetValue: (listener) => {
                resetListeners.current = [...resetListeners.current, listener]
                return () => {
                  resetListeners.current = resetListeners.current.filter(
                    (listenerEntry) => listener !== listenerEntry
                  )
                }
              },
              setIsUploading: (sourceId, isUploading) => {
                updateSource(sourceId, { isUploading })
              },
              setHasError: (sourceId, hasError) => {
                updateSource(sourceId, { hasError })
              },
              isModalOpen: isOpen,
              maxFileNumber:
                adjustedMaxFileNumber - unsubmittedSourceValues.length,
            }}
          >
            {!hideLocalSource ? (
              <LocalSource
                beforeLocalFileUpload={beforeLocalFileUpload}
                dropzoneContentRenderer={localSourceDropzoneContentRenderer}
                sourceId={FileSelectLocalSourceId}
                getEndpoint={getEndpoint}
                getMethod={getMethod}
                getPayloadKey={getPayloadKey}
                getPayload={getPayload}
                getHeaders={getHeaders}
                uploadFile={uploadFile}
                accept={accept}
                maxFileSize={maxFileSize}
                minFileSize={minFileSize}
                qa={qa?.localSource}
              />
            ) : null}
            {children}
          </FileSelectContext.Provider>
        </FileExplorer>
        <FileSelectDropzone
          ref={dropzoneRef}
          hideDropzone={hideDropzone}
          tooltip={
            hasReachedMaxFileNumber
              ? I18n.t('core.fileSelect.maxNumberOfFilesSelected')
              : ''
          }
          contentRenderer={
            dropzoneContentRenderer
              ? (dropzoneProps) => {
                  return dropzoneContentRenderer({
                    openFileExplorer: onAttachFromProject,
                    openLocalFiles: dropzoneProps.open,
                    disabled: dropzoneProps.disabled,
                    errorMessage: dropzoneProps.errorMessage,
                  })
                }
              : undefined
          }
          onAttachFromProject={onAttachFromProject}
          accept={accept}
          maxFileNumber={maxFileNumber}
          maxFileSize={maxFileSize}
          minFileSize={minFileSize}
          disabled={hasReachedMaxFileNumber}
          value={uploadedValues}
          onDrop={onDrop}
          multiple
          noDrag={hideDropzone}
        />
        <ProgressAnnouncer
          items={value.filter((v) => uploadProgress[v.id])}
          uploadProgress={uploadProgress}
        />
        <StyledFileTokenList
          tokens={value.map((v) => ({
            ...v,
            progress: uploadProgress[v.id] ?? 100,
          }))}
          onClick={onFileTokenClick}
          onClose={handleFileRemoval}
          qa={{ closeButton: qa?.removeEntry }}
        />
      </StyledFileSelect>
    )
  }
)

function noop() {}

export const FileSelectContext = React.createContext<FileSelectContextApi>({
  register: () => noop,
  currentSource: '',
  isModalOpen: false,
  onChange: noop,
  onResetValue: () => noop,
  maxFileNumber: Infinity,
  setIsUploading: noop,
  setHasError: noop,
})

export const useFileSelectContext = () => {
  return React.useContext(FileSelectContext)
}

/**

 The file explorer enables users to select permitted files from various document
 sources in procore to attach to items.

 @since 10.19.0

 @see [Storybook](https://procore.github.io/core/latest/?path=/story/demos-fileselect--demo)

 @see [Design Guidelines](https://design.procore.com/file-select)
 @see [New File Select Repo](https://github.com/procore/documents-js-monorepo/tree/main/packages/file-select)

 @deprecated
 This component has been removed from the system. The File Select base component is now managed in documents-js-monorepo.
 @deprecatedSince 11.23.3

 */

export const FileSelect = addSubcomponents(
  {
    LocalSource,
    GridSource,
    TreeSource,
  },
  FileSelectBase
)
