import styled from 'styled-components'
import { Dropzone } from '../../Dropzone/Dropzone'
import { colors } from '../../_styles/colors'
import { spacing } from '../../_styles/spacing'

export const dropzoneContainerAttr = 'data-dropzone-container'

export const StyledDropzone = styled(Dropzone)<{ hidden: boolean }>`
  ${({ hidden }) =>
    hidden &&
    `
    [${dropzoneContainerAttr}] {
      align-self: flex-start;
      margin-bottom: ${spacing.xs}px;

      &,
      &:hover {
        width: auto;
        min-height: auto;
        border: none;
        padding: 0;
        background: transparent;
        cursor: default;
      }
    }
  
  `}
`

export const StyledDropzoneMessage = styled.span<{ error: boolean }>`
  ${({ error }) =>
    error &&
    `
    color: ${colors.gray15};
  `}
`

export const StyledUploadButtonWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing.sm}px;
`
