export const layout = {
  footerHeight: 80,
  headerHeight: 80,
  maxWidth: 1440,
  pagePadding: 48,
  sidebarWidth: 240,
}

export const colors = {
  _006ddf: '#006ddf',
  _202020: '#202020',
  _282828: '#282828',
  _333333: '#333333',
  _3397ff: '#3397ff',
  _707070: '#707070',
  b3b3b3: '#b3b3b3',
  cccccc: '#cccccc',
  d9d9d9: '#d9d9d9',
  dcedff: '#dcedff',
  e36937: '#e36937',
  e6e6e6: '#e6e6e6',
  f1f3f3: '#f1f3f3',
  f3f3f3: '#f3f3f3',
  f47e42: '#f47e42',
}
