import * as React from 'react'

// we have this here because gatsby-remark-autolink-headers doesn't properly
// inject its JS when loaded through gatsby-plugin-mdx. Can go away once they
// fix it on their side

const HEADER_OFFSET = 100

export function useScrollToAnchor() {
  React.useEffect(() => {
    const hash = window.decodeURI(location.hash.replace('#', ''))

    if (hash === '') return

    const element = document.getElementById(hash)

    if (element) {
      // Wait for the browser to finish rendering before scrolling.
      setTimeout(function () {
        window.scrollTo(0, element.offsetTop - HEADER_OFFSET)
      }, 0)
    }
  }, [])
}
