export const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined'

export function isIE11() {
  return (
    typeof window !== 'undefined' &&
    !!window.MSInputMethodContext &&
    !!(document as any).documentMode
  )
}
