import styled from 'styled-components'
import { Button } from '../Button/Button'
import { Card } from '../Card/Card'
import { Typography } from '../Typography/Typography'
import { spacing } from '../_styles/spacing'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledPageCount = styled(Typography)`
  margin-right: ${spacing.xl}px;
`

export const StyledCurrentPage = styled(Typography)`
  margin-right: ${spacing.sm}px;
`

export const StyledButton = styled(Button)`
  margin-left: ${spacing.sm}px;
`

export const StyledOverlay = styled(Card)`
  max-height: 300px; // TODO one-off
  max-width: 248px; // TODO one-off
`
