import styled from 'styled-components'
import { FileToken } from '../../FileToken/FileToken'
import { spacing } from '../../_styles/spacing'

export const StyledTokenList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const StyledFileToken = styled(FileToken)`
  margin-bottom: ${spacing.sm}px;
  margin-right: ${spacing.sm}px;
`
