import styled, { css } from 'styled-components'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { getShadow } from '../_styles/shadows'
import type { CardProps } from './Card.types'

export const StyledCardContainer = styled.div<{
  $level?: CardProps['level']
  $shadowStrength: Required<CardProps>['shadowStrength']
  $variant?: CardProps['variant']
}>`
  background-color: ${colors.white};
  border-radius: ${borderRadius.md}px;
  transition: box-shadow 0.2s ease-in-out;
  ${({ $level, $shadowStrength }) => {
    const strength = $level ? Number($level) / 10 : $shadowStrength

    return getShadow(strength)
  }}
  ${({ $variant, $shadowStrength }) =>
    $variant === 'hoverable' &&
    css`
      &:hover {
        ${getShadow($shadowStrength + 1)};
      }
    `}
`
