import styled, { css, keyframes } from 'styled-components'
import { StyledLoader } from '../Loader/Loader.styles'
import { StyledLabel, StyledToken } from '../Token/Token.styles'
import { colors } from '../_styles/colors'

export const pulseAnimationDuration = 600

const focusPulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${colors.blue60}, 0 0 0 0 ${colors.blue90}, 0 0 0 0 ${colors.blue94};
  }
  25% {
    box-shadow: 0 0 0 0 ${colors.blue60}, 0 0 0 0 ${colors.blue90}, 0 0 0 0 ${colors.blue94};
  }
  50% {
    box-shadow: 0 0 0 1px ${colors.blue60}, 0 0 0 3px ${colors.blue90}, 0 0 0 2px ${colors.blue94};
  }
  100% {
    box-shadow: 0 0 0 1.5px ${colors.blue60}, 0 0 0 3px ${colors.blue90}, 0 0 0 6px ${colors.blue94};
  }
`

export const StyledFilenameShortcut = styled.div`
  display: flex;
`

export const StyledFilename = styled.span`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledExtension = styled.span`
  flex-shrink: 0;
`

export const StyledFileToken = styled.div<{
  $error?: boolean
  $finalized?: boolean
  $progressComplete?: boolean
}>`
  background-color: ${colors.gray96};
  border-radius: 200px;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  position: relative;

  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px ${colors.blue40};
    outline: none;

    ${StyledToken} {
      border-color: transparent;

      ${({ $progressComplete }) =>
        $progressComplete &&
        css`
          background-color: ${colors.blue45};
        `}
    }
  }

  ${StyledToken} {
    background: none;
    color: ${colors.blue40};
    display: flex;
    user-select: none;
  }

  ${StyledLoader} {
    overflow: hidden;

    ${StyledToken} {
      border-radius: 200px 0 0 200px;
      color: ${colors.white};
      padding-right: 0;
      transition: color 0s linear, background-color 0s linear;
      user-select: none;

      ${StyledLabel} {
        flex: 0 0 auto;
      }
    }
  }

  ${({ $error, $finalized, $progressComplete }) => {
    if ($error) {
      return css`
        background-color: ${colors.red50};

        &:focus {
          background-color: ${colors.red50};
        }

        ${StyledToken} {
          color: ${colors.white};
        }

        ${StyledLoader} {
          display: none;
        }
      `
    } else if ($finalized || $progressComplete) {
      return css`
        ${$finalized &&
        css`
          animation-delay: 0;
          animation-duration: ${pulseAnimationDuration}ms;
          animation-name: ${focusPulse};
          animation-timing-function: ease-out;
        `};

        ${StyledLoader} {
          opacity: 0;
          pointer-events: none;
        }

        ${StyledToken} {
          background-color: ${colors.blue50};
          color: ${colors.white};

          &:hover,
          &:focus {
            background-color: ${colors.blue45};
          }
        }

        &.hover,
        &.focus {
          ${StyledToken} {
            background-color: ${colors.blue45};
          }
        }
      `
    }

    return css``
  }}
`
