import { PanelHeaderProps, PanelTitleTooltipProps, PanelHeaderActionProps, PanelBodyProps } from '@procore/core-react/dist/_typedoc/Panel/Panel.types.json';
import { SectionProps } from '@procore/core-react/dist/_typedoc/Section/Section.types.json';
import * as React from 'react';
export default {
  PanelHeaderProps,
  PanelTitleTooltipProps,
  PanelHeaderActionProps,
  PanelBodyProps,
  SectionProps,
  React
};