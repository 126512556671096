import styled, { css } from 'styled-components'
import { StyledBox } from '../Box/Box.styles'
import type { FlexDirection } from '../Box/Box.types'
import { Flex } from '../Flex/Flex'

export const StyledFlex = styled(Flex)<{ $direction?: FlexDirection }>`
  > ${StyledBox} {
    ${({ $direction }) => {
      if ($direction?.includes('reverse')) {
        return css`
          &:first-child {
            margin-bottom: 0;
            margin-right: 0;
          }
          &:last-child {
            margin-left: 0;
            margin-top: 0;
          }
        `
      }

      return css`
        &:first-child {
          margin-left: 0;
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
          margin-right: 0;
        }
      `
    }}
  }
`
