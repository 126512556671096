import React from 'react'
import { ContactItem } from '../../ContactItem'
import type { SuperSelectConfig } from '../SuperSelect.types'

export const contactsPreset: SuperSelectConfig = {
  selectionStyle: 'checkmark',
  components: {
    OptionLabel: ({ option, ...props }) => {
      return (
        <ContactItem
          {...props}
          initials={option.initials}
          icon={option.icon}
          disabled={option.disabled}
        >
          <ContactItem.Title>{option.title}</ContactItem.Title>
          <ContactItem.Description>
            {option.description}
          </ContactItem.Description>
        </ContactItem>
      )
    },
  },
}
