import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

const emptyStateWidth = 440
const emptyStateWidthCompact = 352

export const StyledSvg = styled.svg`
  width: 200px;
  height: 200px;
`

export const StyledImage = styled.div``

export const StyledTitle = styled.div`
  margin-top: ${spacing.xl}px;
  text-align: center;
  color: ${colors.gray15};
  width: ${emptyStateWidth}px;
  ${getTypographyIntent('h2')}
`

export const StyledDescription = styled.div`
  margin-top: ${spacing.lg}px;
  text-align: center;
  color: ${colors.gray45};
  width: ${emptyStateWidth}px;
  ${getTypographyIntent('h3')}
`

export const StyledActions = styled.div`
  margin-top: ${spacing.xl}px;
`

export const StyledEmptyState = styled.div<{ compact: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${({ compact }) => (compact ? 0 : 96)}px;
  margin-bottom: ${({ compact }) => (compact ? 0 : 64)}px;

  ${StyledImage} {
    > img {
      max-width: 200px;
    }
  }

  ${({ compact = false }) =>
    compact &&
    css`
      ${StyledImage} {
        > img {
          max-width: 120px;
        }
      }

      ${StyledSvg} {
        width: 120px;
        height: 120px;
      }

      ${StyledTitle} {
        width: ${emptyStateWidthCompact}px;
        ${getTypographyIntent('h3')}
      }

      ${StyledDescription} {
        width: ${emptyStateWidthCompact}px;
        ${getTypographyIntent('body')}
      }
    `}
}
`
