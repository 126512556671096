import styled, { css } from 'styled-components'
import { H1 } from '../Semantic/Semantic'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'
import type { ToolHeaderBadgeVariant } from './ToolHeader.types'

export const StyledHeaderBadge = styled.div<{
  $variant: ToolHeaderBadgeVariant
}>`
  ${getTypographyIntent('small')}
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  margin-bottom: ${spacing.sm}px;
  padding: 1px ${spacing.sm}px;

  ${({ $variant }) => {
    if ($variant) {
      if ($variant === 'trial') {
        return css`
          background-color: ${colors.purple50};
          border-color: ${colors.purple50};
          color: ${colors.white};
        `
      }
    }
  }}
`

// TODO in v12 remove cursor: pointer;
export const StyledToolHeaderIconContainer = styled.div`
  color: ${colors.orange50};
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
`

export const StyledHeaderTabs = styled.div`
  align-self: flex-end;
  flex: 1 1 auto;
  min-width: 100px;
  overflow: hidden;
`

export const StyledHeaderActions = styled.div`
  flex: 0 0 auto;
  margin-left: auto;
  padding-left: ${spacing.lg}px;
`

export const StyledToolHeaderTitle = styled(H1)`
  margin-right: ${spacing.xl}px;
  white-space: nowrap;
`

export const StyledToolHeader = styled.div`
  align-items: center;
  display: flex;
  min-height: 36px;
  width: 100%;

  ${StyledToolHeaderIconContainer} + ${StyledToolHeaderTitle},
  a + ${StyledToolHeaderTitle}, button + ${StyledToolHeaderTitle} {
    margin-left: ${spacing.lg}px;
  }
`
