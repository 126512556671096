import type { PropsWithChildren } from 'react'
import React from 'react'
import { getBoundingRect } from '../_utils/dom'
import { getAnchorPosition } from '../_utils/getAnchorPosition'
import type { Placement } from '../_utils/types'
import { useLayoutEventListener } from './EventListener'

/* TYPES */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface AnchorConfig {
  canFlip?: boolean
  dependencies?: Array<any>
  onLayout?: (anchor: AnchorApi) => any
  overlayRef: React.RefObject<HTMLElement>
  padding?: number
  placement?: Placement
  targetRef: React.RefObject<HTMLElement>
  shrinkOverlay?: boolean
  isFlyout?: boolean
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface AnchorProps extends PropsWithChildren<AnchorConfig> {}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface AnchorApi {
  left: number
  /**
   * @deprecated
   *
   * use `shrinkOverlay` boolean property to set `minWidth`
   *
   * @deprecatedSince 10.20.0
   */
  minWidth: number
  placement: Placement
  top: number
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export const initAnchor: AnchorApi = {
  left: 0,
  minWidth: 0,
  placement: 'bottom-left',
  top: 0,
}

/* HOOK */
/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useAnchor({
  canFlip = true,
  dependencies = [],
  onLayout = () => {},
  padding = 0,
  placement = 'bottom-left',
  overlayRef,
  targetRef,
  shrinkOverlay = false,
  isFlyout = false,
}: AnchorConfig): AnchorApi {
  const [anchor, setAnchor] = React.useState<AnchorApi>({
    ...initAnchor,
    placement,
  })

  const viewChangeHandler = () => {
    if (overlayRef.current && targetRef.current) {
      const target = getBoundingRect(targetRef.current)

      if (!shrinkOverlay) {
        // we should set minWidth before calculation
        overlayRef.current.style.minWidth = `${target.width}px`
      }

      const newAnchor = getAnchorPosition(
        placement,
        padding,
        undefined,
        target,
        getBoundingRect(overlayRef.current),
        canFlip,
        isFlyout
      )
      if (newAnchor.placement !== anchor.placement) {
        setAnchor(newAnchor)
      }
      onLayout(newAnchor)
    }
  }

  useLayoutEventListener({
    event: 'scroll',
    handler: viewChangeHandler,
    options: {
      capture: true,
    },
  })

  useLayoutEventListener({
    event: 'resize',
    handler: viewChangeHandler,
  })

  useLayoutEventListener({
    event: 'load',
    handler: viewChangeHandler,
  })

  React.useLayoutEffect(() => {
    viewChangeHandler()
  }, [overlayRef, ...dependencies])

  return anchor
}

/* RENDER PROP */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function Anchor({
  children,
  overlayRef,
  targetRef,
  ...props
}: AnchorProps) {
  const anchor = useAnchor({
    ...props,
    overlayRef,
    targetRef,
  })

  const content =
    typeof children === 'function'
      ? (children as (props: AnchorApi) => React.ReactElement)(anchor)
      : (children as React.ReactElement)

  return (
    content &&
    React.cloneElement(content, {
      ...content.props,
      placement: anchor.placement,
      ref: overlayRef,
    })
  )
}
