import { useId } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import React from 'react'
import { Loader } from '../Loader/Loader'
import { loaderTransitionDuration } from '../Loader/Loader.styles'
import { Token } from '../Token/Token'
import { Tooltip } from '../Tooltip/Tooltip'
import { useI18nContext } from '../_hooks/I18n'
import { parseFilename } from '../_utils/filename'
import type { DivAttributes } from '../_utils/types'
import {
  pulseAnimationDuration,
  StyledExtension,
  StyledFilename,
  StyledFilenameShortcut,
  StyledFileToken,
} from './FileToken.styles'
import type { FileTokenProps } from './FileToken.types'

/**
 * @deprecatedSince 11
 * @deprecated Intended for internal library development.
 */
export const FileToken = React.forwardRef<
  HTMLDivElement,
  FileTokenProps & DivAttributes
>(function FileToken(
  {
    fileName,
    progressValue = 0,
    errorMessage = '',
    onLabelClick,
    onClose,
    qa,
    ...props
  },
  ref
) {
  const i18n = useI18nContext()
  const isError = Boolean(errorMessage)

  const isProgressValueComplete = !isError && progressValue >= 100

  const [isProgressComplete, setProgressComplete] = React.useState(
    isProgressValueComplete
  )

  const [isFinalized, setFinalized] = React.useState(false)

  const didMountRef = React.useRef(false)

  const { filename, extension, isFilename } = parseFilename(fileName)

  const progressBarLabelId = useId()
  const name = isFilename ? `${filename}.` : fileName

  React.useEffect(() => {
    const timerId: number | null = isProgressValueComplete
      ? window.setTimeout(() => {
          setProgressComplete(true)
        }, loaderTransitionDuration)
      : null

    if (!isProgressValueComplete) {
      setProgressComplete(false)
    }

    return () => {
      timerId && clearTimeout(timerId)
    }
  }, [isProgressValueComplete])

  React.useEffect(() => {
    const isFinalized = didMountRef.current && isProgressComplete
    const timerId: number | null = isFinalized
      ? window.setTimeout(() => {
          setFinalized(false)
        }, pulseAnimationDuration)
      : null

    setFinalized(didMountRef.current && isProgressComplete)

    if (!didMountRef.current) {
      didMountRef.current = true
    }

    return () => {
      timerId && clearTimeout(timerId)
    }
  }, [isProgressComplete])

  const token = (
    <Token>
      <Token.Label onClick={onLabelClick}>
        <StyledFilenameShortcut>
          <StyledFilename>{name}</StyledFilename>
          <StyledExtension>{extension}</StyledExtension>
        </StyledFilenameShortcut>
      </Token.Label>
      <Token.Remove onClick={onClose} data-qa={qa?.closeButton} />
    </Token>
  )

  // prevent empty tooltip blinks when navigating via the keyboard by wrapping in tooltip conditionally
  if (isError) {
    return (
      <Tooltip ref={ref} trigger="hover" overlay={errorMessage}>
        <StyledFileToken
          {...props}
          $error={isError}
          $finalized={isFinalized}
          $progressComplete={isProgressComplete}
        >
          {token}
          <Loader value={progressValue} animated>
            {token}
          </Loader>
        </StyledFileToken>
      </Tooltip>
    )
  }

  return (
    <StyledFileToken
      {...props}
      $error={isError}
      $finalized={isFinalized}
      $progressComplete={isProgressComplete}
      ref={ref}
    >
      {token}
      <Loader value={progressValue} animated aria-hidden>
        {token}
      </Loader>
      <VisuallyHidden>
        <span id={progressBarLabelId}>
          {i18n.t('core.fileToken.uploading')}
        </span>
        <div
          aria-labelledby={progressBarLabelId}
          role="progressbar"
          aria-valuenow={progressValue}
          aria-valuemin={0}
          aria-valuemax={100}
          aria-valuetext={`${progressValue}%`}
        />
      </VisuallyHidden>
    </StyledFileToken>
  )
})

FileToken.displayName = 'FileToken'
