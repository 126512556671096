import { EXAMPLE_IMAGES } from './Thumbnail'
import { createMockData } from './Tree'

const buildThumbnails = (size: 'sm' | 'lg') => {
  const thumbnailImages = EXAMPLE_IMAGES[size]
  const images = [
    thumbnailImages[0],
    '',
    thumbnailImages[1],
    '',
    thumbnailImages[2],
    '',
  ]
  const extensions = ['psd', 'zip', 'rtf', 'csv', 'pdf', 'jpeg', 'ppt', '']
  const files = Array.from({ length: 40 }, (_, idx) => ({
    id: idx,
    name: `file_${idx}.${extensions[idx % extensions.length]}`,
    src: images[idx % images.length],
  }))
  return files
}

export const FILES_SAMPLE = {
  lg: buildThumbnails('lg'),
  sm: buildThumbnails('sm'),
}

const generateForms = (amount: number) => {
  return Array.from({ length: amount }, (_, idx) => ({
    id: Math.random(),
    name: `Test form ${idx}`,
  }))
}

const formsData = generateForms(50)

export const FORMS_SAMPLE = formsData.slice(0, 20)
export const REMOTE_FORMS_SAMPLE = formsData.slice(20)

export const DOCUMENT_TREE_SAMPLE = createMockData(50)
