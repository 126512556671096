import { ExternalLink } from '@procore/core-icons/dist'
import { H2, Link, P, UL } from '@procore/core-react'
import React from 'react'

interface PackageProps {
  /**
   * @description The full name of the package a consumer would reference, i.e. @procore/something
   */
  name: string
  description: string
  docs?: string
  source?: string
  changelog?: string
}

export function Package(props: PackageProps) {
  return (
    <>
      <H2>{props.name}</H2>
      <br />
      <P>{props.description}</P>
      <UL>
        {props.docs && (
          <li>
            <Link href={props.docs} target="_blank" rel="noopener noreferrer">
              Documentation{' '}
              <ExternalLink
                size="sm"
                style={{ verticalAlign: 'text-bottom' }}
              />
            </Link>
          </li>
        )}
        {props.changelog && (
          <li>
            <Link
              href={props.changelog}
              target="_blank"
              rel="noopener noreferrer"
            >
              Changelog{' '}
              <ExternalLink
                size="sm"
                style={{ verticalAlign: 'text-bottom' }}
              />
            </Link>
          </li>
        )}
        {props.source && (
          <li>
            <Link href={props.source} target="_blank" rel="noopener noreferrer">
              Source{' '}
              <ExternalLink
                size="sm"
                style={{ verticalAlign: 'text-bottom' }}
              />
            </Link>
          </li>
        )}
        <li>
          <Link
            href={`https://www.npmjs.com/${props.name}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            NPM{' '}
            <ExternalLink size="sm" style={{ verticalAlign: 'text-bottom' }} />
          </Link>
        </li>
      </UL>
    </>
  )
}

export function LabPackage(props: PackageProps) {
  return (
    <Package
      docs={`https://procore.github.io/core-labs/storybook/${props.name}/index.html`}
      source={`https://github.com/procore/core-labs/tree/master/packages/${props.name.replace(
        '@procore/labs-',
        ''
      )}`}
      {...props}
    />
  )
}
