import { ChevronLeft, ChevronRight } from '@procore/core-icons/dist'
import { getStartDayOfTheWeek } from '@procore/globalization-toolkit'
import { isAfter, startOfDay } from 'date-fns'
import { range } from 'ramda'
import React from 'react'
import { Select as SelectBase } from '../Select/Select'
import { useDateTime } from '../_hooks/DateTime'
import type { Locale } from '../_hooks/I18n'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import {
  addMonths,
  format,
  getDates,
  getDayOfWeekLabel,
  getMonth,
  getMonthLabel,
  getMonthOptions,
  getRows,
  getYear,
  getYearOptions,
  isSameDay,
  isSameMonth,
  isWithinRange,
  subMonths,
  updateMonth,
  updateYear,
} from '../_utils/CalendarHelpers'

import { getFormatterLocale } from '../_utils/i18n'
import {
  StyledContainer,
  StyledDayCell,
  StyledDayHeaderCell,
  StyledNavigation,
  StyledPaginator,
  StyledSelect,
  StyledWeekRow,
} from './Calendar.styles'
import type {
  BodyProps,
  CalendarContainerProps,
  CalendarProps,
  DayProps,
  NavigationProps,
  NextPrevProps,
  SelectProps,
  WeekdaysProps,
  WeekProps,
} from './Calendar.types'

function noop() {}

function returnFalse() {
  return false
}

/**
 * @parent Calendar
 * @deprecatedSince 11.6.0
 * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release,
 * please use the parent as a self closing tag.
 *
 * AFTER `<Calendar onSelect />`
 */
export function Body({
  dueDateTime: dueDateTime_,
  displayDate: displayDate_,
  disabledDate,
  onSelect: onSelect_ = noop,
  selectedEnd: selectedEnd_,
  selectedStart: selectedStart_,
  ...props
}: BodyProps) {
  const dateTime = useDateTime()

  const displayDate =
    dateTime.shiftUtcToZonedTime(displayDate_) || dateTime.newDate()

  const { locale } = useI18nContext()
  const formatterLocale = getFormatterLocale(locale) as Locale

  const dates = getDates(displayDate, formatterLocale)

  const dueDateTime = dateTime.shiftUtcToZonedTime(dueDateTime_)

  const selectedEnd: CalendarProps['selectedEnd'] =
    dateTime.shiftUtcToZonedTime(selectedEnd_)

  const selectedStart: CalendarProps['selectedStart'] =
    dateTime.shiftUtcToZonedTime(selectedStart_)

  const onSelect: CalendarProps['onSelect'] = (day) => {
    onSelect_(dateTime.shiftZonedTimeToUtc(day, 'Calendar Body onSelect'))
  }

  return (
    <>
      <Weekdays />
      {getRows(dates).map((row) => (
        <Week key={`week-${format(row[0], 'yyyy-MMMM-dddd')}`}>
          {row.map((day) => (
            <Day
              aria-label={dateTime.format(day, 'weekday-date', {
                timeZone: undefined,
              })}
              disabled={
                disabledDate &&
                disabledDate(day, startOfDay(dateTime.newDate()))
              }
              isCurrentMonth={isSameMonth(day, displayDate)}
              isRangeEnd={selectedEnd && isSameDay(day, selectedEnd)}
              isRangeStart={selectedStart && isSameDay(day, selectedStart)}
              isToday={isSameDay(day, startOfDay(dateTime.newDate()))}
              key={`day-${format(day, 'yyyy-MMMM-dddd')}`}
              onClick={() => onSelect(day)}
              selected={isWithinRange(day, selectedStart, selectedEnd)}
              {...props}
            >
              {format(day, 'd')}
              {dueDateTime && isSameDay(day, startOfDay(dueDateTime)) && (
                <div>
                  <em
                    style={
                      isAfter(dateTime.newDate(), dueDateTime)
                        ? {
                            color: 'red',
                          }
                        : undefined
                    }
                  >
                    {dateTime.format(dueDateTime_!, 'time')}
                  </em>
                </div>
              )}
            </Day>
          ))}
        </Week>
      ))}
    </>
  )
}

/**
 * @parent Calendar
 * @deprecatedSince 11.6.0
 * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release,
 * please use the parent as a self closing tag.
 *
 * AFTER `<Calendar onSelect />`
 */
export const Container = React.forwardRef<
  HTMLDivElement,
  CalendarContainerProps
>(function Container(props, ref) {
  return <StyledContainer ref={ref} {...props} />
})

/**
 * @parent Calendar
 * @deprecatedSince 11.6.0
 * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release,
 * please use the parent as a self closing tag.
 *
 * AFTER `<Calendar onSelect />`
 */
export const Day = React.forwardRef<HTMLDivElement, DayProps>(function Day(
  {
    disabled = false,
    isCurrentMonth = false,
    isRangeEnd = false,
    isRangeStart = false,
    isToday = false,
    onClick,
    selected = false,
    ...props
  },
  ref
) {
  return (
    <StyledDayCell
      aria-current={isToday ? 'date' : undefined}
      aria-disabled={disabled || undefined}
      aria-selected={selected || undefined}
      {...props}
      onClick={disabled ? undefined : onClick}
      ref={ref}
      $disabled={disabled}
      $selected={selected}
      $isCurrentMonth={isCurrentMonth}
      $isRangeStart={isRangeStart}
      $isRangeEnd={isRangeEnd}
      $isToday={isToday}
    />
  )
})

/**
 * @parent Calendar
 * @deprecatedSince 11.6.0
 * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release,
 * please use the parent as a self closing tag.
 *
 * AFTER `<Calendar onSelect />`
 */
export const Navigation = React.forwardRef<HTMLDivElement, NavigationProps>(
  function Navigation(props, ref) {
    return <StyledNavigation {...props} ref={ref} />
  }
)

/**
 * @parent Calendar
 * @deprecatedSince 11.6.0
 * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release,
 * please use the parent as a self closing tag.
 *
 * AFTER `<Calendar onSelect />`
 */
export const Next = React.forwardRef<HTMLButtonElement, NextPrevProps>(
  function Next({ ...props }, ref) {
    return (
      <StyledPaginator
        {...props}
        ref={ref}
        icon={<ChevronRight />}
        variant="tertiary"
      />
    )
  }
)

/**
 * @parent Calendar
 * @deprecatedSince 11.6.0
 * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release,
 * please use the parent as a self closing tag.
 *
 * AFTER `<Calendar onSelect />`
 */
export const Prev = React.forwardRef<HTMLButtonElement, NextPrevProps>(
  function Prev(props, ref) {
    return (
      <StyledPaginator
        {...props}
        ref={ref}
        icon={<ChevronLeft />}
        variant="tertiary"
      />
    )
  }
)

/**
 * @parent Calendar
 * @deprecatedSince 11.6.0
 * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release,
 * please use the parent as a self closing tag.
 *
 * AFTER `<Calendar onSelect />`
 */
export const Week = React.forwardRef<HTMLDivElement, WeekProps>(function Week(
  props,
  ref
) {
  return <StyledWeekRow ref={ref} {...props} />
})

/**
 * @parent Calendar
 * @deprecatedSince 11.6.0
 * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release,
 * please use the parent as a self closing tag.
 *
 * AFTER `<Calendar onSelect />`
 */
export const Weekdays = React.forwardRef<HTMLDivElement, WeekdaysProps>(
  function Weekdays(props, ref) {
    const { locale } = useI18nContext()
    const formatterLocale = getFormatterLocale(locale) as Locale
    const startDayOfWeek: number = getStartDayOfTheWeek(formatterLocale)

    return (
      <StyledWeekRow ref={ref} aria-hidden="true" {...props}>
        {range(startDayOfWeek, startDayOfWeek + 7).map((day) => (
          <StyledDayHeaderCell key={`day-${day}`}>
            {getDayOfWeekLabel(day, formatterLocale)}
          </StyledDayHeaderCell>
        ))}
      </StyledWeekRow>
    )
  }
)

/**
 * @parent Calendar
 * @deprecatedSince 11.6.0
 * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release,
 * please use the parent as a self closing tag.
 *
 * AFTER `<Calendar onSelect />`
 */
export const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  function Select(
    { children, label, onSelect, options = [], selected = '', ...props },
    ref
  ) {
    return (
      <StyledSelect>
        <SelectBase
          {...props}
          block={true}
          label={label}
          onSelect={(selection) => {
            onSelect(selection.item)
          }}
          optionsRef={ref as React.RefObject<HTMLDivElement>}
          tabIndex={0}
        >
          {options.map(({ value, label }) => {
            return (
              <SelectBase.Option
                value={value}
                selected={value === selected}
                key={value}
              >
                {label}
              </SelectBase.Option>
            )
          })}
        </SelectBase>
      </StyledSelect>
    )
  }
)

export const Calendar_ = React.forwardRef<HTMLDivElement, CalendarProps>(
  function Calendar(
    {
      dueDateTime,
      displayDate: displayDate_,
      disabledDate = returnFalse,
      monthRef,
      onNavigate = noop,
      onSelect = noop,
      selectedEnd,
      selectedStart,
      yearRef,
      ...props
    },
    ref
  ) {
    const i18n = useI18nContext()
    const dateTime = useDateTime()
    const formatterLocale = getFormatterLocale(i18n.locale) as Locale
    const { newDate, shiftUtcToZonedTime } = useDateTime()
    const monthSelectRef = monthRef || React.createRef()
    const yearSelectRef = yearRef || React.createRef()

    const displayDate: CalendarProps['displayDate'] =
      shiftUtcToZonedTime(displayDate_) || newDate()

    const nextNavigationDateLabel = i18n.t('core.calendar.navigationLabel', {
      monthYear: dateTime.format(addMonths(displayDate, 1), 'date', {
        month: 'long',
        year: 'numeric',
        day: undefined,
      }),
    })
    const prevNavigationDateLabel = i18n.t('core.calendar.navigationLabel', {
      monthYear: dateTime.format(subMonths(displayDate, 1), 'date', {
        month: 'long',
        year: 'numeric',
        day: undefined,
      }),
    })

    return (
      <Container {...props} ref={ref}>
        <Navigation>
          <Prev
            aria-label={prevNavigationDateLabel}
            onClick={() => onNavigate(subMonths(displayDate, 1))}
          />
          <Select
            options={getMonthOptions(formatterLocale)}
            label={getMonthLabel(getMonth(displayDate), formatterLocale)}
            onSelect={(value) => {
              onNavigate(updateMonth(displayDate, value))
            }}
            selected={getMonth(displayDate)}
            ref={monthSelectRef}
          />
          <Select
            options={getYearOptions(displayDate)}
            label={String(getYear(displayDate)).padStart(4, '0')}
            onSelect={(value) => {
              onNavigate(updateYear(displayDate, value))
            }}
            selected={getYear(displayDate)}
            ref={yearSelectRef}
          />
          <Next
            aria-label={nextNavigationDateLabel}
            onClick={() => onNavigate(addMonths(displayDate, 1))}
          />
        </Navigation>
        <Body
          dueDateTime={dueDateTime}
          // intentionally pass unshifted value. next component's responsibility to manage
          displayDate={displayDate_}
          disabledDate={disabledDate}
          onSelect={onSelect}
          selectedEnd={selectedEnd}
          selectedStart={selectedStart}
        />
      </Container>
    )
  }
)

Calendar_.displayName = 'Calendar'

Body.displayName = 'Calendar.Body'

Container.displayName = 'Calendar.Container'

Day.displayName = 'Calendar.Day'

Navigation.displayName = 'Calendar.Navigation'

Next.displayName = 'Calendar.Next'

Prev.displayName = 'Calendar.Prev'

Select.displayName = 'Calendar.Select'

Week.displayName = 'Calendar.Week'

Weekdays.displayName = 'Calendar.Weekdays'

export const Calendar = addSubcomponents(
  {
    /**
     * @deprecatedSince 11.6.0
     * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release.
     *
    AFTER
    ```
      <Calendar onSelect />
    ```
    */
    Body,
    /**
     * @deprecatedSince 11.6.0
     * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release.
     *
    AFTER
    ```
      <Calendar onSelect />
    ```
    */
    Container,
    /**
     * @deprecatedSince 11.6.0
     * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release.
     *
    AFTER
    ```
      <Calendar onSelect />
    ```
    */
    Day,
    /**
     * @deprecatedSince 11.6.0
     * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release.
     *
    AFTER
    ```
      <Calendar onSelect />
    ```
    */
    Navigation,
    /**
     * @deprecatedSince 11.6.0
     * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release.
     *
    AFTER
    ```
      <Calendar onSelect />
    ```
    */
    Next,
    /**
     * @deprecatedSince 11.6.0
     * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release.
     *
    AFTER
    ```
      <Calendar onSelect />
    ```
    */
    Prev,
    /**
     * @deprecatedSince 11.6.0
     * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release.
     *
    AFTER
    ```
      <Calendar onSelect />
    ```
    */
    Select,
    /**
     * @deprecatedSince 11.6.0
     * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release.
     *
    AFTER
    ```
      <Calendar onSelect />
    ```
    */
    Week,
    /**
     * @deprecatedSince 11.6.0
     * @deprecated Subcomponents intended for internal library development. Export will be removed in a future release.
     *
    AFTER
    ```
      <Calendar onSelect />
    ```
    */
    Weekdays,
  },
  Calendar_
)
