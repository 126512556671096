import type { Editor } from '@tinymce/tinymce-react'
import type { Locale } from '../_hooks/I18n'

export const scriptId = 'tiny-mce-core-source'
export const tinyMCESource = 'https://s3.amazonaws.com/procore-core/tinymce'
export const tinyMCEVersion = '5.8.1'

type TextEditorLocales = { [key in Locale]: string }

// TinyMCE Supported Languages: https://www.tiny.cloud/docs/configure/localization/#supportedlanguages
const textEditorLocales: TextEditorLocales = {
  en: 'en',
  'en-CA': 'en',
  'fr-CA': 'fr_FR',
  'fr-FR': 'fr_FR',
  es: 'es',
  'es-ES': 'es',
  'en-AE': 'en',
  'en-AU': 'en',
  'en-GB': 'en',
  'en-SG': 'en',
  'th-TH': 'th_TH',
  'ja-JP': 'ja',
  'pt-BR': 'pt_BR',
  'is-IS': 'en',
  'de-DE': 'de',
  'zh-SG': 'zh_CN',
  pseudo: 'en',
  'pl-PL': 'pl',
}

export const defaultPlugins = [
  'autolink',
  'autoresize',
  'lists',
  'nonbreaking',
  'paste',
  'advlist',
]

export const plugins = [
  'advlist',
  'anchor',
  'autolink',
  'autoresize',
  'autosave',
  'bbcode',
  'charmap',
  'code',
  'codesample',
  'colorpicker',
  'contextmenu',
  'directionality',
  'emoticons',
  'fullpage',
  'fullscreen',
  'help',
  'hr',
  'image',
  'imagetools',
  'importcss',
  'insertdatetime',
  'legacyoutput',
  'link',
  'lists',
  'media',
  'nonbreaking',
  'noneditable',
  'pagebreak',
  'paste',
  'preview',
  'print',
  'save',
  'searchreplace',
  'spellchecker',
  'tabfocus',
  'table',
  'template',
  'textpattern',
  'toc',
  'visualblocks',
  'visualchars',
  'wordcount',
]

/**
 *  Historically, `nonbreaking_force_tab` has been set to `true`
 *  and this takes the `Tab` keypress as input to add to the content.
 *  e.g indent the start of the text block like a paragraph.
 *  This is different from the TinyMCE tool and command 'Indent', which
 *  indents the entire block. e.g. indent this entire text block and the
 *  wrapped lines.
 *  This is the HTML it outputs when `nonbreaking_force_tab: true`
 */
export const tinyMCETabMarkup = `<span class=\"mce-nbsp-wrap\" contenteditable=\"false\">&nbsp;&nbsp;&nbsp;</span>`

export const tinyMCEConfig: any & Editor = {
  autoresize_overflow_padding: 12,
  autoresize_bottom_margin: 0,
  browser_spellcheck: true,
  convert_url: false,
  content_css: `${tinyMCESource}/${tinyMCEVersion}/skins/content/default/content.min.css`,
  extended_valid_elements:
    'table[class=mce-table|border:1px double #000|cellpadding: 3px|style=border-collapse: collapse; border-color: #000]',
  min_height: 96,
  invalid_elements: 'form,script',
  menubar: false,
  nonbreaking_force_tab: true,
  paste_preprocess: (_: any, args: any) => {
    args.content = args.content.replace(/<pre>/gi, '<p>')
    args.content = args.content.replace(/<\/pre>/gi, '</p>')
  },
  paste_remove_styles_if_webkit: true,
  paste_retain_style_properties: 'font-size,text-decoration',
  paste_word_valid_elements:
    '@[style],pre,-strong/b,-em/i,-span,-p,-ol,-ul,-li,-table,-tr,-td[colspan|rowspan],-th,-thead,-tfoot,-tbody,-a[href|name],sub,sup,strike,br,u',
  relative_urls: false,
  remove_script_host: false,
  statusbar: false,
  skin_url: `${tinyMCESource}/${tinyMCEVersion}/skins/ui/oxide`,
  toolbar:
    'bold italic underline strikethrough | alignleft aligncenter alignright | bullist numlist | outdent indent | cut paste pastetext | fontsizeselect | forecolor backcolor | link table image | undo redo',
  toolbar_mode: 'floating',
}

export function getValidLookupLocale(locale: Locale): string {
  return textEditorLocales[locale] || textEditorLocales.en
}

function usesBuiltInText(locale: string) {
  return locale === textEditorLocales.en
}

export function generateLanguageUrl(locale: Locale) {
  const lookupLocale = getValidLookupLocale(locale)
  return usesBuiltInText(lookupLocale)
    ? ''
    : `${tinyMCESource}/${tinyMCEVersion}/langs/${lookupLocale}.js`
}

function generatePluginUrl(plugin: string) {
  return `${tinyMCESource}/${tinyMCEVersion}/plugins/${plugin}/plugin.min.js`
}

export function generateExternalPlugins(
  plugins: Array<string>,
  basePlugins = defaultPlugins
) {
  return Array.from(new Set(plugins.concat(basePlugins))).reduce(
    (acc, plugin) => {
      return plugins.includes(plugin)
        ? Object.assign(acc, { [plugin]: generatePluginUrl(plugin) })
        : acc
    },
    {}
  )
}
