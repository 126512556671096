import React from 'react'
import type { OverlayTriggerProps } from '../OverlayTrigger'
import type { TooltipProps } from '../Tooltip'
import { Tooltip } from '../Tooltip'
import { useI18nContext } from '../_hooks/I18n'
import { StyledFormFieldTooltipIcon } from './Form.styles'

export const FormFieldTooltip: React.FC<
  Omit<OverlayTriggerProps & TooltipProps, 'children'>
> = (props) => {
  const i18n = useI18nContext()

  return (
    <Tooltip role="tooltip" trigger={['hover', 'focus']} {...props}>
      <StyledFormFieldTooltipIcon
        aria-label={i18n.t('core.form.field.tooltipHelp')}
        aria-hidden={false}
        size="sm"
        tabIndex={0}
      />
    </Tooltip>
  )
}
