import { css } from 'styled-components'
import type { Color } from './colors'
import { colors } from './colors'

export function arrowUp(size: number, color: Color) {
  return css`
    border-bottom: ${size}px solid ${colors[color]};
    border-left: ${size}px solid transparent;
    border-right: ${size}px solid transparent;
    border-top: 0;
  `
}

export function arrowDown(size: number, color: Color) {
  return css`
    border-bottom: 0;
    border-left: ${size}px solid transparent;
    border-right: ${size}px solid transparent;
    border-top: ${size}px solid ${colors[color]};
  `
}

export function arrowRight(size: number, color: Color) {
  return css`
    border-bottom: ${size}px solid transparent;
    border-left: ${size}px solid ${colors[color]};
    border-right: 0;
    border-top: ${size}px solid transparent;
  `
}

export function arrowLeft(size: number, color: Color) {
  return css`
    border-bottom: ${size}px solid transparent;
    border-left: 0;
    border-right: ${size}px solid ${colors[color]};
    border-top: ${size}px solid transparent;
  `
}
