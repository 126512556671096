import styled, { css } from 'styled-components'
import { defaultSpinnerSize, Spinner } from '../Spinner/Spinner'
import { spinnerDimensions } from '../Spinner/Spinner.styles'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import type { Intent } from '../Typography/Typography.types'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { mediaIE11Hack } from '../_styles/media'
import { focusable } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'
import type { ButtonSizes, ButtonVariant, SizingObject } from './Button.types'

const heights: SizingObject = {
  sm: 24,
  md: 36,
  lg: 48,
}

const paddingX: SizingObject = {
  sm: spacing.xs,
  md: 6, // TODO one-off
  lg: spacing.md,
}

const typographySizes: { [key in ButtonSizes]: Intent } = {
  sm: 'small',
  md: 'body',
  lg: 'h3',
}

const buttonTextColors: {
  [key in ButtonVariant]: { main: string; disabled: string }
} = {
  primary: {
    main: colors.white,
    disabled: colors.white,
  },
  secondary: {
    main: colors.gray15,
    disabled: colors.gray70,
  },
  tertiary: {
    main: colors.gray15,
    disabled: colors.gray70,
  },
  tertiaryContrast: {
    main: colors.blue45,
    disabled: colors.blue45,
  },
  UNSAFE_helixNav: {
    main: colors.white,
    disabled: colors.gray40,
  },
}

const buttonBGColors: {
  [key in ButtonVariant]: {
    main: string
    hover: string
    disabled: string
  }
} = {
  primary: {
    main: colors.orange50,
    hover: colors.orange45,
    disabled: colors.orange85,
  },
  secondary: {
    main: colors.gray90,
    hover: colors.gray85,
    disabled: colors.gray94,
  },
  tertiary: {
    main: 'transparent',
    hover: colors.gray90,
    disabled: 'transparent',
  },
  tertiaryContrast: {
    main: 'transparent',
    hover: 'white',
    disabled: 'transparent',
  },
  UNSAFE_helixNav: {
    main: 'transparent',
    hover: colors.gray50,
    disabled: 'transparent',
  },
}

export function getBGColor(variant: ButtonVariant) {
  const colors = buttonBGColors[variant]
  if (!colors) {
    console.error(
      `@procore/core-react: Using invalid Button variant ${variant}. Please update, falling back to primary.`
    )
    return buttonBGColors.primary
  }
  return colors
}

export function getTextColor(variant: ButtonVariant) {
  const colors = buttonTextColors[variant]
  if (!colors) {
    console.error(
      `@procore/core-react: Using invalid Button variant ${variant}. Please update, falling back to primary.`
    )
    return buttonTextColors.primary
  }
  return colors
}

function getColors(loading: boolean, variant: ButtonVariant) {
  return css`
    background: ${loading
      ? getBGColor(variant).hover
      : getBGColor(variant).main};

    color: ${getTextColor(variant).main};

    &.active,
    &:active,
    &.focus,
    &:focus,
    &.hover,
    &:hover {
      background: ${getBGColor(variant).hover};
      color: ${getTextColor(variant).main};
    }

    &:disabled,
    &[aria-disabled='true'] {
      background: ${loading
        ? getBGColor(variant).hover
        : getBGColor(variant).disabled};
      color: ${getTextColor(variant).disabled};
    }
  `
}

export const StyledContent = styled.span`
  align-items: center;
  display: flex;
  overflow: hidden;
`

export const StyledSpinner = styled(Spinner)`
  box-sizing: border-box;
  display: none;
  position: absolute;
  transform: translateZ(0);

  @media ${mediaIE11Hack} {
    left: 50%;
    top: 50%;
    ${({ size }) => {
      const margin = -1 * (spinnerDimensions[size ?? defaultSpinnerSize] / 2)
      return css`
        margin-left: ${margin}px;
        margin-top: ${margin}px;
      `
    }}
  }
`

export const StyledLabel = styled.span`
  overflow: hidden;
  padding: 0 6px; // TODO one-off
  text-overflow: ellipsis;
  white-space: nowrap;

  & + span {
    padding-left: 0;
  }
`

interface StyledButtonProps {
  disabled: boolean
  $block: boolean
  $loading: boolean
  $size: ButtonSizes
  $variant: ButtonVariant
}

function getButtonStyles(
  block: boolean,
  disabled: boolean,
  loading: boolean,
  size: ButtonSizes,
  variant: ButtonVariant
) {
  return css`
    ${getTypographyIntent(typographySizes[size])}

    ${focusable}

    /**
     TODO: This might be a new pattern for focus states,
     but leaving it just here for now
    */
    ${variant === 'UNSAFE_helixNav' &&
    css`
      &.focus,
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px ${colors.blue70};
      }
    `}

    ${getColors(loading, variant)}

    align-items: center;
    border-radius: ${borderRadius.md}px;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    font-family: inherit;
    font-weight: ${typographyWeights.semibold};
    height: ${heights[size]}px;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    padding: 0 ${paddingX[size]}px;
    position: relative;
    pointer-events: ${disabled || loading ? 'none' : 'inherit'};
    text-overflow: ellipsis;
    user-select: ${disabled || loading ? 'none' : 'inherit'};
    vertical-align: middle;
    white-space: nowrap;
    width: ${block && '100%'};

    [data-icon-prop='true'] {
      pointer-events: none;
      height: ${size === 'sm' ? 16 : 24}px;
      width: ${size === 'sm' ? 16 : 24}px;
    }

    ${loading &&
    css`
      ${StyledContent} {
        pointer-events: none;
        visibility: hidden;
      }

      ${StyledSpinner} {
        display: block;
      }
    `}
  `
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${({ $block, disabled, $loading, $size, $variant }) =>
    getButtonStyles($block, disabled, $loading, $size, $variant)}
`

interface StyledLinkButtonProps
  extends Pick<StyledButtonProps, '$block' | '$size' | '$variant'> {
  $disabled: boolean
}

export const StyledLinkButton = styled.a<StyledLinkButtonProps>`
  &,
  &:hover {
    text-decoration: none;
  }

  ${({ $block, $disabled, $size, $variant }) =>
    getButtonStyles($block, $disabled, false, $size, $variant)}
`
