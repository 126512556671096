import { isEmpty } from 'ramda'
import type React from 'react'
import { useBuffer } from './Buffer'

/* TYPES */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export type ChangeEvent = React.ChangeEvent<HTMLInputElement>

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface TextEntryConfig {
  initialValue?: string
  onChange: (event: ChangeEvent) => void
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface TextEntryApi {
  clear: () => void
  empty: boolean
  onChange: (event: ChangeEvent) => void
  reset: () => void
  value: string
}

interface Props extends TextEntryConfig {
  children?: React.ReactNode
}

interface RenderProps extends TextEntryConfig {
  children: (props: TextEntryApi) => {}
}

/* HOOK */
/**
 * @deprecatedSince 9.7.0
 * @deprecated A wrapper around useBuffer (deprecated) that would also call onChange. A trivial hook for storing a string. Write your own or use a hook utility lib.
 * 
 * **Source:**
 * ```
export function useTextEntry({
  initialValue = '',
  onChange = () => {},
}) {
  const { value, update, clear, reset } = useBuffer({ initialValue })

  return {
    clear: () => {
      onChange({ target: { value: '' } })
      clear()
    },
    empty: Ramda.isEmpty(value),
    onChange: (event) => {
      onChange(event)
      update(event.target.value)
    },
    reset: () => {
      onChange({ target: { value } })
      reset()
    },
    value,
  }
}
```
 */
export function useTextEntry({
  initialValue = '',
  onChange = () => {},
}: TextEntryConfig) {
  const { value, update, clear, reset } = useBuffer({ initialValue })

  return {
    clear: () => {
      onChange({ target: { value: '' } } as ChangeEvent)
      clear()
    },
    empty: isEmpty(value),
    onChange: (event: ChangeEvent) => {
      onChange(event)
      update(event.target.value)
    },
    reset: () => {
      onChange({ target: { value } } as ChangeEvent)
      reset()
    },
    value,
  }
}

/* RENDER PROP */
/**
 * @deprecatedSince 9.7.0
 * @deprecated
 */
export function TextEntry({ children, ...props }: RenderProps) {
  const textEntry = useTextEntry(props)

  return typeof children === 'function' ? children(textEntry) : children
}
