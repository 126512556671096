import React from 'react'

const ChildRegistryContext = React.createContext<ChildRegistry>({})
const ChildRegistryDispatch = React.createContext<
  React.Dispatch<ChildToRegister>
>((childToRegister: ChildToRegister) => ({}))

/**
 *  Tracks the children of the modal, primarily for managing focus
 */
interface ChildRegistry {
  header?: React.RefObject<HTMLDivElement>
  body?: React.RefObject<HTMLDivElement>
  closeableButtons?: React.RefObject<HTMLDivElement>
}

const initState: ChildRegistry = {
  header: undefined,
  body: undefined,
  closeableButtons: undefined,
}

interface ChildToRegister {
  child: keyof ChildRegistry
  ref: React.MutableRefObject<null>
}

function registerChild(state: ChildRegistry, childToRegister: ChildToRegister) {
  return {
    ...state,
    [childToRegister.child]: childToRegister.ref,
  }
}

export function ChildRegistryProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [state, dispatch] = React.useReducer(registerChild, initState)
  return (
    <ChildRegistryContext.Provider value={state}>
      <ChildRegistryDispatch.Provider value={dispatch}>
        {children}
      </ChildRegistryDispatch.Provider>
    </ChildRegistryContext.Provider>
  )
}

export function useModalChildRegistryContext() {
  const context = React.useContext(ChildRegistryContext)
  if (!context) {
    throw new Error(
      'useModalChildRegistryContext must be used within a ChildRegistryProvider'
    )
  }
  return context
}

export function useModalChildRegistryDispatch() {
  const context = React.useContext(ChildRegistryDispatch)
  if (!context) {
    throw new Error(
      'useModalChildRegistryDispatch must be used within a ChildRegistryProvider'
    )
  }
  return context
}
