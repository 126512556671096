import { css } from 'styled-components'
import { typographyLineHeight } from '../Typography/Typography.styles'
import type { Intent } from '../Typography/Typography.types'
import { colors } from './colors'

export const focusBlue = colors.blue40

/**
 * Using box-shadow only, will not change size with a border.
 */
export const focusable = css`
  &.focus,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${focusBlue}, inset 0 0 0 1px ${focusBlue};
  }
`

export function getGapOutlineFocus() {
  return css`
    outline: ${focusBlue} solid 2px;
    outline-offset: 1px;
  `
}

export const defaultTransition = css`
  transition: all 0.2s ease-in;
`

export function getEllipsis() {
  return css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
}

/**
 * `border` is used instead of `outline` intentionally - as `outline` can't handle `border-radius`
 * (rounded elements will still have square outline)
 */
export function getFocus() {
  return css`
    border-style: solid;
    border-width: 1px;
    border-color: ${focusBlue};
    box-shadow: 0 0 0 1px ${focusBlue};
    outline: none;
  `
}

export function getLineClamp(lines = 2) {
  return css<{ intent?: Intent }>`
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    ${({ intent = 'body' }) => css`
      // limit height for browsers that do not support line-clamp CSS prop
      max-height: ${typographyLineHeight[intent] * 2}px;
    `}
  `
}
