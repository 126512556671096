import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css, keyframes } from 'styled-components'
import { Card } from '../Card/Card'
import { StyledPageContainer } from '../PageLayout/PageLayout.styles'
import { colors } from '../_styles/colors'
import { getShadow } from '../_styles/shadows'
import { spacing } from '../_styles/spacing'
import { isIE11 } from '../_utils/isIE11'
import type { Placement } from './Tearsheet.types'

export const animationSpeed = {
  disabled: 0,
  tearsheet: 800,
  scrim: 300,
  closeButton: 150,
}

const slideDistance = '100%'

const positioningProps: Record<
  Placement,
  Record<
    'open' | 'closed' | 'placement' | 'shadow',
    string | FlattenSimpleInterpolation
  >
> = {
  right: {
    open: `translateX(0);`,
    closed: `translateX(${slideDistance});`,
    placement: css`
      flex-direction: row;
      justify-content: flex-end;
    `,
    shadow: getShadow(4, 'left'),
  },
  // @ts-ignore
  top: {
    open: `translateY(0);`,
    closed: `translateY(-${slideDistance});`,
    placement: css`
      flex-direction: column-reverse;
      justify-content: flex-end;
    `,
    shadow: getShadow(4, 'bottom'),
  },
  bottom: {
    open: `translateY(0);`,
    closed: `translateY(${slideDistance});`,
    placement: css`
      flex-direction: column;
      justify-content: flex-end;
    `,
    shadow: getShadow(4, 'top'),
  },
  left: {
    open: `translateX(0);`,
    closed: `translateX(-${slideDistance});`,
    placement: css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `,
    shadow: getShadow(4, 'right'),
  },
}

const getSlideInAnimation = ($placement: Placement) => keyframes`
  from {
    transform: ${positioningProps[$placement].closed};
  }
  to {
    transform: ${positioningProps[$placement].open};
  }
`

const getSlideOutAnimation = ($placement: Placement) => keyframes`
  from {
    transform: ${positioningProps[$placement].open};
  }
  to {
    transform: ${positioningProps[$placement].closed};
  }
`

export const StyledTearsheetContent = styled.div<{
  $placement: Placement
  $open: boolean
  $opening?: boolean
  $closing?: boolean
}>`
  display: flex;
  z-index: 2;
  width: 100%;

  ${({ $open, $placement }) =>
    $open
      ? css`
          transform: ${positioningProps[$placement].open};
        `
      : css`
          transform: ${positioningProps[$placement].closed};
        `}

  ${({ $placement }) => positioningProps[$placement].placement}

  ${({ $opening, $placement }) =>
    $opening &&
    css`
      animation: ${getSlideInAnimation($placement)}
        ${animationSpeed.tearsheet}ms ease-out;
      transform: ${positioningProps[$placement].open};
    `}

  ${({ $closing, $placement }) =>
    $closing &&
    css`
      animation: ${getSlideOutAnimation($placement)}
        ${animationSpeed.tearsheet}ms ease-out;
      transform: ${positioningProps[$placement].closed};
    `}
`

export const minScrimSize = spacing.xxl * 3

export const StyledTearsheetBody = styled.div<{
  $placement: Placement
  $stretch: boolean
}>`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  background-color: ${colors.white};

  ${({ $placement, $stretch }) => {
    const size = ['top', 'bottom'].includes($placement) ? '100vh' : '100vw'

    return (
      $stretch &&
      css`
        flex: 0 0 calc(${size} - ${minScrimSize}px);
      `
    )
  }}

  ${({ $placement }) => {
    if (!isIE11()) {
      return ''
    }

    if (['top', 'bottom'].includes($placement)) {
      return css`
        ${StyledPageContainer} {
          width: 100vw;
        }
      `
    }

    return css`
      ${StyledPageContainer} {
        width: 95vw;
      }
    `
  }}}
`

const zoomIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

const zoomOut = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
`

export const StyledScrimContainer = styled.div<{ $placement: Placement }>`
  ${({ $placement }) => {
    const minSizeProp = ['top', 'bottom'].includes($placement)
      ? 'min-height'
      : 'min-width'

    return css`
      ${minSizeProp}: ${minScrimSize}px;
      flex-grow: 1;
    `
  }}
`

export const StyledButtonContainer = styled.div<{ $placement: Placement }>`
  ${({ $placement }) => {
    switch ($placement) {
      case 'bottom':
        return css`
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          height: 100%;
        `
      case 'right':
        return css`
          display: flex;
          justify-content: flex-end;
        `
      case 'left':
      default:
        return
    }
  }}
`

export const StyledButtonCard = styled(Card)<{
  $open: boolean
  $opening?: boolean
  $closing?: boolean
}>`
  display: inline-flex;
  margin: ${spacing.xl}px;

  ${({ $open }) =>
    $open
      ? css`
          transform: scale(1);
        `
      : css`
          transform: scale(0);
        `}

  ${({ $opening }) =>
    $opening &&
    css`
      animation: ${zoomIn} ${animationSpeed.closeButton}ms;
      transform: scale(1);
    `}

  ${({ $closing }) =>
    $closing &&
    css`
      animation: ${zoomOut} ${animationSpeed.closeButton}ms;
      transform: scale(0);
    `}
`
