import React from 'react'
import type { TextAreaAttributes } from '../_utils/types'
import { StyledTextArea } from './TextArea.styles'
import type { TextAreaProps } from './TextArea.types'

/**

 Text areas give our users space to input unformatted text. All text areas are
 manually expandable by dragging the bottom right corner to the desired size.

 If users need to add formatted text, see Text Editor.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-textarea--demo)

 @see [Design Guidelines](https://design.procore.com/text-area)

 */
export const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaAttributes & TextAreaProps
>(function TextArea({ resize = 'both', ...props }, ref) {
  return <StyledTextArea resize={resize} {...props} ref={ref} />
})

TextArea.displayName = 'TextArea'
