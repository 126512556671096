import { AttentionBanner, Banner, Popover } from '@procore/core-react'
import { Link } from 'gatsby'
import * as React from 'react'
import JsxParser from 'react-jsx-parser'
import {
  StyledPropsA11y,
  StyledPropsA11yTitle,
  StyledPropsContainer,
  StyledPropsDeprecated,
  StyledPropsLink,
  StyledPropsMeta,
  StyledPropsPopoverContent,
  StyledPropsTable,
  StyledPropsTdDefault,
  StyledPropsTdDescription,
  StyledPropsTdName,
  StyledPropsTdRequired,
  StyledPropsTdType,
  StyledPropsTh,
  StyledPropsThead,
  StyledPropsTr,
} from './PropsTable.styles'

interface Prop {
  a11y: string
  name: string
  type: string | { name: string }
  required: boolean
  description: string
  defaultValue: string | string[]
  deprecated: string
  remarks: string
}

interface PropsTableProps {
  props: {
    description: string
    properties: Prop[]
  }
}

const Code: React.FC = (props) => {
  return (
    <div
      style={{
        borderRadius: '3px',
        background: '#f3f3f3',
        display: 'inline-block',
        fontFamily: 'monospace',
        padding: '5px',
      }}
    >
      {props.children}
    </div>
  )
}

const CodeBlock: React.FC = (props) => {
  return (
    <div
      style={{
        borderRadius: '3px',
        background: '#f3f3f3',
        display: 'inline-block',
        fontFamily: 'monospace',
        margin: '15px 0',
        padding: '5px',
      }}
    >
      {props.children}
    </div>
  )
}

const padLeft = (depth: number) => ({ paddingLeft: `${depth * 20}px` })

const oneOfVal = (val: any) => (typeof val === 'string' ? `'${val}'` : `${val}`)

const oneOfTypeVal = (type: any) => type.name

const metaArr = (arr: [], fn: any) => `[${arr.map(fn).join(', ')}]`

const ColName: React.FC<{ children: any; depth: number }> = ({
  children,
  depth = 0,
}) => <StyledPropsTdName style={padLeft(depth)}>{children}</StyledPropsTdName>

const ColType: React.FC<{ type: string; typeDetail: string }> = ({
  type,
  typeDetail,
}) => {
  const hasDetail = typeDetail && typeDetail != type

  return (
    <StyledPropsTdType>
      {hasDetail ? (
        <StyledPropsLink>
          <Popover
            overlay={
              <StyledPropsPopoverContent>
                <div dangerouslySetInnerHTML={{ __html: typeDetail }} />
              </StyledPropsPopoverContent>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: type }} />
          </Popover>
        </StyledPropsLink>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: type }} />
      )}
    </StyledPropsTdType>
  )
}

const ColRequired: React.FC<Prop> = ({ required }) => (
  <StyledPropsTdRequired>{required ? 'true' : 'false'}</StyledPropsTdRequired>
)

const ColDefault: React.FC<Prop> = ({ defaultValue, children }) => {
  let renderedVal: any = ''

  if (typeof children === 'function') {
    renderedVal = defaultValue.toString()
  } else if (typeof defaultValue === 'boolean') {
    renderedVal = defaultValue ? 'true' : 'false'
  } else if (typeof defaultValue === 'string') {
    renderedVal = defaultValue ? defaultValue : "''"
  } else if (typeof defaultValue === 'number') {
    renderedVal = defaultValue
  } else if (Array.isArray(defaultValue)) {
    renderedVal = `[${defaultValue.map(oneOfVal).join(', ')}]`
  } else {
    renderedVal = JSON.stringify(defaultValue)
  }

  return <StyledPropsTdDefault>{renderedVal}</StyledPropsTdDefault>
}

const ColDescription: React.FC<{ prop: any }> = ({ prop }) => {
  // JSX-Parser uses document, so this check is required to prevent SSR build
  // from failing
  if (prop.descriptionHtml) {
    return (
      <StyledPropsTdDescription>
        <div dangerouslySetInnerHTML={{ __html: prop.descriptionHtml }} />
        {prop.a11y && (
          <StyledPropsA11y>
            <StyledPropsA11yTitle>Accessibility notes:</StyledPropsA11yTitle>
            <div dangerouslySetInnerHTML={{ __html: prop.a11y }} />
          </StyledPropsA11y>
        )}
      </StyledPropsTdDescription>
    )
  }

  if (typeof document !== 'undefined') {
    const parser = new JsxParser({
      jsx: prop.description,
      components: { Code, CodeBlock, Link: Link as any },
    })

    return (
      <StyledPropsTdDescription>
        <Meta {...{ prop }} />
        {parser.render()}
      </StyledPropsTdDescription>
    )
  } else {
    return <></>
  }
}

const BaseColumns: React.FC<{ name: string; prop: any; depth: number }> = ({
  name,
  prop,
  depth = 0,
}) => (
  <React.Fragment>
    <ColName {...{ depth }}>{name}</ColName>
    <ColType {...prop} />
    <ColRequired {...prop} />
    <ColDefault {...prop} />
  </React.Fragment>
)

const Meta: React.FC<{ prop: any }> = ({ prop }) => {
  let meta = ''

  if (typeof prop.type !== 'object') {
    return null
  }

  if (prop.type.name === 'oneOf') {
    meta = `oneOf: ${metaArr(prop.type.arr, oneOfVal)}`
  } else if (prop.type.name === 'oneOfType') {
    meta = `oneOf: ${metaArr(prop.type.types, oneOfTypeVal)}`
  } else if (prop.type.name === 'arrayOf') {
    meta = `arrayOf: [${prop.type.type.name}]`
  } else if (prop.type.name === 'objectOf') {
    meta = `objectOf: ${[prop.type.type.name]}`
  }

  return <>{meta && <StyledPropsMeta>{meta}</StyledPropsMeta>}</>
}

const ShapeRow: React.FC<{ name: string; prop: any; depth: number }> = ({
  name,
  prop,
  depth = 0,
}) => (
  <React.Fragment>
    <tr>
      <BaseColumns {...{ name, prop, depth }} />
    </tr>
    {Object.keys(prop.props).map((name, i) => (
      <Row key={i} depth={depth + 1} name={name} prop={prop.props[name]} />
    ))}
  </React.Fragment>
)

const Row: React.FC<{ name: string; prop: Prop; depth: number }> = ({
  name,
  prop,
  depth = 0,
}) => {
  const row = (
    <StyledPropsTr deprecated={prop.deprecated}>
      <BaseColumns {...{ name, prop, depth }} />
      <ColDescription {...{ prop }} />
    </StyledPropsTr>
  )

  if (typeof prop.type == 'object' && prop.type?.name === 'shape') {
    return <ShapeRow {...{ name, prop, depth }} />
  }

  if (prop.deprecated) {
    return (
      <Popover
        trigger={prop.deprecated ? 'hover' : 'none'}
        overlay={
          <Popover.Content style={{ maxWidth: 468 }}>
            <AttentionBanner>
              <Banner.Content>
                <Banner.Title>Deprecated</Banner.Title>
                <Banner.Body>
                  <StyledPropsDeprecated>
                    {prop.deprecated}
                  </StyledPropsDeprecated>
                </Banner.Body>
              </Banner.Content>
            </AttentionBanner>
          </Popover.Content>
        }
      >
        {row}
      </Popover>
    )
  }

  return row
}

const PropsTable = ({ props }: PropsTableProps) => {
  return (
    <>
      {props?.description && (
        <div dangerouslySetInnerHTML={{ __html: props.description }} />
      )}
      <StyledPropsContainer>
        <StyledPropsTable>
          <StyledPropsThead>
            <tr>
              <StyledPropsTh>Name</StyledPropsTh>
              <StyledPropsTh>Type</StyledPropsTh>
              <StyledPropsTh>Required</StyledPropsTh>
              <StyledPropsTh>Default</StyledPropsTh>
              <StyledPropsTh>Description</StyledPropsTh>
            </tr>
          </StyledPropsThead>
          <tbody>
            {props?.properties?.map((prop, i) => (
              <Row key={i} name={prop.name} prop={prop} depth={0} />
            ))}
          </tbody>
        </StyledPropsTable>
      </StyledPropsContainer>
    </>
  )
}

export default PropsTable
