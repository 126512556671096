import styled, { css } from 'styled-components'
import { StyledFormRow } from '../Form/Form.styles'
import { Title } from '../Title/Title'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

export const StyledSectionBanner = styled.div`
  margin-bottom: ${spacing.sm}px;

  > * + * {
    margin-top: ${spacing.sm}px;
  }
`

export const StyledSectionInner = styled.div``

export const StyledSectionCollapseHeading = styled.div`
  align-self: flex-start;
  margin-right: ${spacing.sm}px;
`

export const sectionClassnames = {
  divide: 'section--divide',
  levelAnonymous: 'section--level-anon',
  level: (num: number) => `section--level-${num}`,
}

export const StyledSection = styled.div`
  padding-left: ${spacing.lg}px;
  padding-right: ${spacing.lg}px;
  padding-bottom: ${spacing.lg}px;

  & > ${StyledSectionInner} {
    padding-top: ${spacing.lg}px;
  }

  // horizontal divider
  &.${sectionClassnames.divide} {
    & > ${StyledSectionInner} {
      border-top: 1px solid ${colors.gray85};
    }
  }

  // if leading, no horizontal divider
  &:first-child > ${StyledSectionInner} {
    border-top: 0;
  }

  & & {
    padding-left: 0;
    padding-right: 0;

    // if leading in nested, add the division back
    &:first-child > ${StyledSectionInner} {
      border-top: 1px solid ${colors.gray85};
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  // form rows have trailing space for error message
  ${StyledFormRow}:last-of-type {
    margin-bottom: ${spacing.lg * -1}px;
  }

  // form rows have trailing space for error message
  ${StyledFormRow} + &&& {
    padding-top: ${spacing.xs}px;
  }
`

export const StyledSectionContentRegion = styled.div<{
  $closed: boolean
  $noHeader: boolean
}>`
  padding-top: ${spacing.sm}px;

  ${({ $closed }) =>
    $closed &&
    css`
      padding-top: 0;
    `}

  ${({ $noHeader }) =>
    $noHeader &&
    css`
      padding-top: 0;
    `}

  // if a nested section isn't first
  // Section
  //   P
  //   Section <-- space above
  & > :not(${StyledSection}) + ${StyledSection} {
    padding-top: ${spacing.xl}px;
  }

  // nested sections, the last one doesn't need more trailing space
  ${StyledSection} ${StyledSection} &:last-of-type {
    padding-bottom: 0;
  }
`

export const StyledSectionHeader = styled.div`
  align-items: center;
  display: flex;
  // min-height on header for alignment:
  //  - when heading line height is less than the collapse button height
  //  - when heading line height is less than the button size it will shift
  //      the heading text when the section is opened and buttons appear
  min-height: 36px;
  word-break: break-word;
`

export const StyledTitle = styled(Title)`
  width: 100%;
`
