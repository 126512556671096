import React from 'react'
import { Button } from '../Button/Button'
import type { MenuRef } from '../MenuImperative/MenuImperative.types'
import { OverlayTrigger } from '../OverlayTrigger/OverlayTrigger'
import { TieredSelectContext } from '../TieredSelect/TieredSelect'
import type {
  Id,
  Tier,
  TieredSelectMenuProps,
} from '../TieredSelect/TieredSelect.types'
import { TieredSelectMenu } from '../TieredSelect/TieredSelectMenu'
import {
  always,
  defaultGetGroupId,
  defaultGetId,
  defaultGetLabel,
  defaultGetNextGroupId,
  defaultIsTierDisabled,
  empty,
  findPath,
  getPathKey,
  hideKeys,
  noop,
} from './TieredDropdown.helpers'
import type {
  InternalTieredDropdownProps,
  SearchablePath,
  TieredDropdownProps,
} from './TieredDropdown.types'
import { useSearch } from './useSearch'

const TieredDropdownInternal = React.forwardRef<
  HTMLDivElement,
  InternalTieredDropdownProps
>(function TieredDropdownInternal(
  {
    className,
    disabled = false,
    loading = false,
    onKeyDown,
    options = empty,
    value = empty,
    style,
    ...props
  },
  ref
) {
  const {
    afterHide,
    afterShow,
    beforeHide,
    beforeShow,
    block,
    getLabel,
    getGroupId,
    getNextGroupId,
    isLeaf,
    onChange,
    onNavigate,
    onSearch,
    tabIndex,
  } = React.useContext(TieredSelectContext)

  const searchablePaths = React.useMemo<SearchablePath[]>(() => {
    if (onSearch) {
      return []
    }

    const paths: SearchablePath[] = []

    const optionsByNextGroupId = options.reduce((map, option) => {
      const nextGroupId = getNextGroupId(option)
      if (nextGroupId) {
        return {
          ...map,
          [nextGroupId]: option,
        }
      }
      return map
    }, {})

    options.forEach((item) => {
      if (!getNextGroupId(item)) {
        const path = findPath(optionsByNextGroupId, item, getGroupId)

        const pathKey = getPathKey(path, getLabel)
        paths.push({
          path,
          pathKey,
          label: pathKey,
          node: item,
        })
      }
    })

    return paths
  }, [getGroupId, getLabel, getNextGroupId, onSearch, options])

  const { searchTerm, filteredItems, setSearch } = useSearch({
    items: searchablePaths,
    keysToSearch: ['pathKey'],
  })

  const menuOptions = React.useMemo(() => {
    return searchTerm?.length > 0 ? filteredItems : options
  }, [filteredItems, options, searchTerm])

  const tieredSelectMenuRef = React.useRef<MenuRef>(null)
  const [currentTier, setCurrentTier] = React.useState<Id>(null)

  // internal value used for navigation
  const [internalValue, setInternalValue] = React.useState<Tier[]>(value)

  const internalOnNavigate = React.useCallback(
    function (nextGroupId: Id, value: any[]) {
      setInternalValue(value)
      setCurrentTier(nextGroupId)
      onNavigate?.(nextGroupId, value)
    },
    [onNavigate]
  )

  const internalOnSearch = React.useCallback(
    function (event: any) {
      if (onSearch === undefined) {
        setSearch(event.target.value || '')
      } else {
        onSearch(event)
      }
    },
    [onSearch, setSearch]
  )

  /**
   * Emulates on select
   */
  const internalOnChange = React.useCallback<TieredSelectMenuProps['onChange']>(
    (selection) => {
      if (searchTerm?.length > 0) {
        onChange?.({
          ...selection,
          value: (selection.item as SearchablePath).path,
          item: (selection.item as SearchablePath).node,
        })
      } else {
        onChange?.(selection)
      }
    },
    [onChange, searchTerm]
  )

  /**
   * Used to reset state after selection
   */
  const internalAfterHide = React.useCallback(() => {
    setCurrentTier(null)
    setInternalValue([])
    afterHide && afterHide()
  }, [afterHide])

  // if value prop changes, update value state, current tier, and set button label
  // else open to root
  React.useEffect(
    function () {
      if (value?.length > 0) {
        const lastEntryInValue = value[value.length - 1]
        setInternalValue(value)
        // show children of last tier in value if not a leaf node
        // otherwise we want to show the parent of the last tier in value
        if (isLeaf && !isLeaf(lastEntryInValue)) {
          setCurrentTier(getNextGroupId(lastEntryInValue))
        } else {
          setCurrentTier(getGroupId(lastEntryInValue) || null)
        }
      } else {
        setCurrentTier(null)
        setInternalValue([])
      }
    },
    [getGroupId, getNextGroupId, isLeaf, value]
  )

  return (
    <OverlayTrigger
      afterHide={internalAfterHide}
      afterShow={afterShow}
      beforeHide={beforeHide}
      beforeShow={(e: Event) => {
        return beforeShow && beforeShow(e)
      }}
      canFlip={true}
      hideKeys={hideKeys}
      overlay={
        disabled ? null : (
          <TieredSelectMenu
            currentTier={currentTier}
            options={menuOptions}
            loading={loading}
            menuRef={tieredSelectMenuRef}
            onNavigate={internalOnNavigate}
            onSearch={internalOnSearch}
            onChange={internalOnChange}
            value={internalValue}
            previousValue={[]}
            {...props}
          />
        )
      }
      placement="bottom-left"
      ref={ref}
      trigger="click"
    >
      <Button
        data-qa={props['data-qa']}
        block={block}
        className={className}
        disabled={disabled}
        icon={props.icon}
        iconRight={props.iconRight}
        loading={loading}
        size={props.size}
        style={style}
        tabIndex={tabIndex}
        variant={props.variant}
      >
        {props.children}
      </Button>
    </OverlayTrigger>
  )
})

export const TieredDropdown = React.forwardRef<
  HTMLDivElement,
  TieredDropdownProps
>(function TieredDropdown(
  {
    afterHide = noop,
    afterShow = noop,
    beforeHide = always,
    beforeShow = always,
    block,
    getGroupId = defaultGetGroupId,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    getNextGroupId = defaultGetNextGroupId,
    i18nScope = 'core.tieredDropdown',
    isTierDisabled = defaultIsTierDisabled,
    onSelect = noop,
    onNavigate,
    onScrollBottom,
    onSearch,
    tabIndex,
    ...props
  },
  ref
) {
  const isLeaf = React.useCallback(
    function (tier: Tier) {
      return !getNextGroupId(tier)
    },
    [getNextGroupId]
  )

  return (
    <TieredSelectContext.Provider
      value={{
        afterHide,
        afterShow,
        beforeHide,
        beforeShow,
        block,
        getGroupId,
        getId,
        getLabel,
        getNextGroupId,
        getValueString: () => '',
        i18nScope,
        isTierDisabled,
        isLeaf,
        onChange: onSelect,
        onNavigate,
        onScrollBottom,
        onSearch,
        tabIndex,
      }}
    >
      <TieredDropdownInternal ref={ref} {...props} value={[]} />
    </TieredSelectContext.Provider>
  )
})
