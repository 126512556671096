import styled, { css } from 'styled-components'
import { StyledActions, StyledContent } from '../Content/Content.styles'
import {
  StyledForm as SForm,
  StyledFormRow as SFormRow,
} from '../Form/Form.styles'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { mediaIE11Hack } from '../_styles/media'
import { getFocus } from '../_styles/mixins'
import { getShadow } from '../_styles/shadows'
import { spacing } from '../_styles/spacing'
import { isIE11 } from '../_utils/isIE11'
import type { TileCommonProps } from './Tile.types'

interface StyledTile {
  $orientation: TileCommonProps['orientation']
}

const getHover = ($selected = false) => css`
  cursor: pointer;
  &:hover,
  &.hover {
    background-color: ${$selected ? colors.blue94 : colors.gray96};
  }
`

const getSelected = () => css`
  background-color: ${colors.blue98};
`

const getDisabled = () => css`
  cursor: not-allowed;
`

interface GetTileStylesProps {
  $orientation: TileCommonProps['orientation']
  $selected?: boolean
  $hoverable?: boolean
  $disabled?: boolean
  $focused?: boolean
}

const oneThirdTwoThirds = 'minmax(0, 1fr) minmax(0, 2fr)'

const getLandscapeTemplate = () => {
  return css`
    --core-tile-grid-template-columns: ${oneThirdTwoThirds};
    grid-template-areas:
      'hero body'
      'hero footer';
    grid-template-columns: var(
      --core-tile-grid-template-columns,
      ${oneThirdTwoThirds}
    );
  `
}

const getPortraitTemplate = () => {
  return css`
    grid-template-areas:
      'hero'
      'body'
      'footer';
    grid-template-columns: 1fr;
  `
}

const getTileStyles = ({
  $orientation,
  $selected = false,
  $hoverable = false,
  $disabled = false,
  $focused = false,
}: GetTileStylesProps) => {
  return css`
    display: grid;
    ${$orientation === 'landscape'
      ? getLandscapeTemplate()
      : getPortraitTemplate()};

    background-color: ${colors.white};
    border-radius: ${borderRadius.md}px;
    width: 100%;
    ${getShadow(1, 'bottom')}

    &:focus, &.focus {
      ${getFocus()}
    }
    ${$focused ? getFocus() : ''}

    ${$disabled ? getDisabled() : ''}
    ${$selected ? getSelected() : ''}
    ${$hoverable && !$disabled ? getHover($selected) : ''}
  `
}

export const StyledLinkTile = styled.a<StyledTile>`
  ${({ $orientation }) => getTileStyles({ $orientation, $hoverable: true })}
  text-decoration: none;
  color: inherit;
`

export const StyledSelectableTile = styled.div<
  StyledTile & { $selected: boolean; $disabled?: boolean; $focused?: boolean }
>`
  ${({ $orientation, $selected, $disabled, $focused }) =>
    getTileStyles({
      $orientation,
      $selected,
      $disabled,
      $focused,
      $hoverable: true,
    })}
`

export const StyledInteractableTile = styled.div<StyledTile>`
  ${({ $orientation }) => getTileStyles({ $orientation })}
`

export const StyledFooter = styled.div`
  grid-area: footer;
  padding: ${spacing.lg}px;

  > ${StyledActions} {
    flex-wrap: wrap-reverse;
  }
`

export const StyledTileContent = styled(StyledContent)`
  grid-column-start: hero-start;
  grid-column-end: body-end;
  padding: ${spacing.lg}px;

  + ${StyledFooter} {
    padding-top: 0;
  }

  > ${SFormRow}, > ${SForm} > ${SFormRow}, > ${SFormRow} {
    grid-column-gap: ${spacing.md}px;
  }
`

export const StyledHeroMedia = styled.figure<{
  $orientation: TileCommonProps['orientation']
}>`
  grid-area: hero;
  margin: 0;
  position: relative;
  overflow: hidden;

  font-size: 0;
  > * {
    font-size: initial;
  }

  + ${StyledTileContent} {
    grid-area: body;
  }

  > video {
    background-color: ${colors.black};
  }
  > img,
  > picture > img {
    object-fit: cover;
  }
  ${({ $orientation }) =>
    $orientation === 'landscape' && !isIE11()
      ? css`
          border-top-left-radius: inherit;
          border-bottom-left-radius: inherit;
          > *,
          > picture > img {
            height: 100%;
            width: 100%;
            position: absolute;
          }

          @media ${mediaIE11Hack} {
            > picture > img,
            > img {
              width: auto;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        `
      : css`
          min-height: 156px;
          max-height: 567px;
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
          > *,
          > picture > img {
            min-height: inherit;
            max-height: inherit;
            height: auto;
            width: 100%;
          }
        `}
`

export const StyledHeroOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

export const StyledHeroText = styled.div<{
  $orientation: TileCommonProps['orientation']
}>`
  grid-area: hero;

  + ${StyledTileContent} {
    grid-area: body;
  }

  padding: ${spacing.lg}px;
  border-radius: inherit;
  ${({ $orientation }) =>
    $orientation === 'landscape'
      ? css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: 0;
        `
      : css`
          padding-bottom: 0;
        `}
`
