import React from 'react'
import { Button } from '../../Button/Button'
import { Modal } from '../../Modal/Modal'
import type { PortalProps } from '../../Portal/Portal.types'
import { Tooltip } from '../../Tooltip/Tooltip'
import { Typography } from '../../Typography/Typography'
import { useI18nContext } from '../../_hooks/I18n'
import { FileExplorerSidebar } from '../FileExplorer/FileExplorerSidebar'
import {
  StyledAttachButton,
  StyledModalBody,
  StyledModalContent,
  StyledModalHeader,
  StyledModalInnerBody,
  StyledSource,
} from './FileExplorer.styles'
import type { FileExplorerProps } from './FileExplorer.types'
import { FileExplorerModal } from './FileExplorerModal'

export const FileExplorer = React.forwardRef<
  HTMLDivElement,
  FileExplorerProps & PortalProps
>(function FileExplorer(
  {
    maxFileNumber = Infinity,
    children,
    onSubmit,
    sources,
    isOpen,
    currentSource,
    onCurrentSourceChange,
    onCancel,
    unsubmittedValueCount,
    qa,
    ...props
  },
  ref
) {
  const I18n = useI18nContext()
  const hasPendingUploadSources = sources.some(({ isUploading }) => isUploading)

  const isAttachButtonDisabled =
    hasPendingUploadSources || unsubmittedValueCount === 0

  return (
    <FileExplorerModal
      {...props}
      ref={ref}
      open={isOpen}
      onKeyDown={(e) => {
        if (e.key === 'Esc' || e.key === 'Escape') {
          onCancel()
        }
      }}
    >
      <StyledModalHeader
        onClose={() => {
          onCancel()
        }}
      >
        {I18n.t('core.fileExplorer.modalTitle')}
      </StyledModalHeader>
      <StyledModalBody>
        <StyledModalInnerBody>
          <FileExplorerSidebar
            value={currentSource}
            onChange={onCurrentSourceChange}
            sources={sources}
            isOpen={isOpen}
          />
          {/* prevent <div /> from being focused in FF: https://bugzilla.mozilla.org/show_bug.cgi?id=616594 */}
          <StyledModalContent tabIndex={-1}>
            <StyledSource>{children}</StyledSource>
          </StyledModalContent>
        </StyledModalInnerBody>
      </StyledModalBody>
      <Modal.Footer>
        <Modal.FooterNotation>
          <Typography color="gray45" italic>
            {maxFileNumber === Infinity
              ? I18n.t('core.fileExplorer.filesSelected', {
                  count: unsubmittedValueCount,
                })
              : I18n.t('core.fileExplorer.filesSelectedWithLimit', {
                  count: unsubmittedValueCount,
                  maxFileNumber: maxFileNumber,
                })}
          </Typography>
        </Modal.FooterNotation>
        <Modal.FooterButtons>
          <Button
            data-qa={qa?.cancelButton}
            variant="tertiary"
            onClick={() => {
              onCancel()
            }}
          >
            {I18n.t('core.fileExplorer.cancelAction')}
          </Button>
          <Tooltip
            showKeys={[]}
            trigger={isAttachButtonDisabled ? 'hover' : 'none'}
            overlay={
              hasPendingUploadSources
                ? I18n.t('core.fileExplorer.filesPendingUpload')
                : I18n.t('core.fileExplorer.noSelectedItems')
            }
          >
            {/* this wrapper is required for the tooltip to work with disabled button */}
            <StyledAttachButton>
              <Button
                data-qa={qa?.attachButton}
                variant="primary"
                disabled={isAttachButtonDisabled}
                onClick={() => {
                  onSubmit?.()
                }}
              >
                {I18n.t('core.fileExplorer.attachAction')}
              </Button>
            </StyledAttachButton>
          </Tooltip>
        </Modal.FooterButtons>
      </Modal.Footer>
    </FileExplorerModal>
  )
})
