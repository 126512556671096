import styled, { css } from 'styled-components'

export const StyledColorTable = styled.table`
  border-collapse: separate;
  border-spacing: 0px 5px;
  margin: 0px;
  width: 100%;
`

export const StyledHeaderTh = styled.th<{
  $snugfit?: boolean
  $nowrap?: boolean
}>`
  padding: 10px 20px;
  border: 0px;
  border-bottom: 1px solid #dfe2e5;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  ${({ $snugfit }) => {
    if ($snugfit) {
      return css`
        white-space: nowrap;
        width: 3.14%;
      `
    }
  }}
  ${({ $nowrap }) => {
    if ($nowrap) {
      return css`
        white-space: nowrap;
      `
    }
  }}
`

export const StyledGroupNameTh = styled(StyledHeaderTh)`
  padding: 10px 20px 10px 0px;
  text-transform: capitalize;
`

export const StyledTd = styled.td`
  padding: 10px 20px;
  border: 0px;
`

export const StyledValueTd = styled(StyledTd)`
  width: 150px;
`

export const StyledPreviewTd = styled(StyledTd)`
  width: 150px;
`
