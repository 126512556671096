import * as React from 'react'
import { useAuth0 } from '../utils/auth'

interface Props {
  children: React.ReactNode
}

interface AuthPageProps extends Props {
  privateRoute: boolean
}

function PrivatePage({ children }: Props) {
  const { isAuthenticated, login } = useAuth0()
  if (!isAuthenticated) {
    login()

    return <></>
  }

  return <>{children}</>
}

function PublicPage({ children }: Props) {
  return <>{children}</>
}

export function AuthPage({ privateRoute, children }: AuthPageProps) {
  return privateRoute ? (
    <PrivatePage>{children}</PrivatePage>
  ) : (
    <PublicPage>{children}</PublicPage>
  )
}

export function PrivateContent({ children }: Props) {
  const { isAuthenticated } = useAuth0()
  return isAuthenticated ? children : null
}
