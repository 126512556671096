import React from 'react'
import {
  StyledAnchor,
  StyledAnchorNavigation,
  StyledAnchorSection,
} from './AnchorNavigation.styles'
import type { AnchorNavigationSection } from './AnchorNavigation.types'

type AnchorNavigationProps = {
  sections: AnchorNavigationSection[]
  active: string
  setActive: (id: string) => void
}

type AnchorProps = {
  label: string
  active: boolean
  onClick: () => void
}

const Anchor = ({ label, active, onClick }: AnchorProps) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick()
    }
  }
  return (
    <StyledAnchorSection
      $active={active}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    >
      <StyledAnchor aria-current={active} $active={active} tabIndex={0}>
        {label}
      </StyledAnchor>
    </StyledAnchorSection>
  )
}

export const AnchorNavigation = React.forwardRef<
  HTMLUListElement,
  AnchorNavigationProps
>(({ sections, active, setActive }, ref) => {
  return (
    <StyledAnchorNavigation ref={ref}>
      {sections.map(({ id, label }) => {
        return (
          <Anchor
            key={id}
            onClick={() => setActive(id)}
            active={active === id}
            label={label}
          />
        )
      })}
    </StyledAnchorNavigation>
  )
})
