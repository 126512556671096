import { useCheckbox } from '@react-aria/checkbox'
import { useFocusRing } from '@react-aria/focus'
import type React from 'react'
import { useRef } from 'react'
import { useToggleState } from 'react-stately'
import type { DivAttributes } from '../_utils/types'
import type { LayoutVariant, ThumbnailProps } from './Thumbnail.types'
import { getIsFilePreviewableAsImage } from './Thumbnail.utils'

const defaultSize = 'lg'

export const useThumbnail = ({
  selected,
  disabled = false,
  clickable = true,
  focused = false,
  hasCaptionPlaceholder = false,
  src,
  filename: filename_,
  size = defaultSize,
  label = '',
  // @ts-ignore  Checkbox only
  name,
  caption = '',
  // @ts-ignore  Checkbox only
  onChange,
  // @ts-ignore  Checkbox only
  value,
  ...props
}:
  | ThumbnailProps<'checkbox'>
  | ThumbnailProps<'figure'>
  | ThumbnailProps<undefined>) => {
  const a11yProps =
    props.role === 'figure'
      ? { as: 'figure' as React.ElementType }
      : { as: 'div' }
  const inputRef = useRef<HTMLInputElement>(null)
  const toggleState = useToggleState({
    defaultSelected: selected,
    onChange,
  })
  const layout: LayoutVariant = size === 'sm' ? 'row' : 'column'
  const isFile = src instanceof File
  const isImageURL = typeof src === 'string'
  const canPreviewFileAsImage = getIsFilePreviewableAsImage(src)
  const isFileOrNonPreviewableImage = isFile && !canPreviewFileAsImage
  const shouldUsePlaceholder = isFileOrNonPreviewableImage || !src
  const filename =
    filename_ ?? (isFileOrNonPreviewableImage ? (src as File).name : caption)
  const ariaLabel = label && caption ? `${label} ${caption}` : caption || label
  const { inputProps } = useCheckbox(
    { isSelected: selected, 'aria-label': ariaLabel, name, onChange, value },
    toggleState,
    inputRef
  )
  const { isFocusVisible, focusProps } = useFocusRing()

  return {
    alt: props.alt || props.alt === '' ? props.alt : caption || label,
    ...props,
    ...a11yProps,
    label,
    caption,
    selected: toggleState.isSelected,
    disabled,
    clickable,
    focused,
    isImageURL,
    hasCaptionPlaceholder,
    shouldUsePlaceholder,
    src,
    filename,
    size,
    layout,
    inputRef,
    inputProps,
    /**
     * Appeared 23-1-18 https://github.com/procore/core/pull/12250
     * Possible issue with types from React Aria during dependency update
     * > The inferred type of 'useThumbnail' cannot be named without a
     * > reference to '@react-aria/focus/node_modules/@react-types/shared'.
     * > This is likely not portable. A type annotation is necessary.
     */
    focusProps: focusProps as DivAttributes,
    isFocusVisible,
    toggleState,
  }
}
