import * as React from 'react'
import type { DivAttributes } from '../_utils/types'
import { StyledBox } from './Box.styles'
import type { BoxProps, Display, FlexDirection } from './Box.types'

// TODO remove this after we decommission the LegacyDisplay type
function getLegacyDisplayAndDirection(d?: Display) {
  if (!d) {
    return {}
  }

  let display = d

  let direction: FlexDirection | undefined = display.includes('flex')
    ? 'row'
    : undefined

  if (d.includes('flex-inline') || d.includes('inline-flex')) {
    display = 'inline-flex'
  } else if (d.includes('flex')) {
    display = 'flex'
  }

  if (d.includes('column-reverse')) {
    direction = 'column-reverse'
  } else if (d.includes('column')) {
    direction = 'column'
  }

  if (d.includes('row-reverse')) {
    direction = 'row-reverse'
  } else if (d.includes('row')) {
    direction = 'row'
  }

  return {
    display,
    direction,
  }
}

/**
 * `Box` is a general layout utility for CSS properties like `display`, `text-align`,
 * flex, spacing, and layout properties.
 */
export const Box = React.forwardRef<HTMLDivElement, DivAttributes & BoxProps>(
  function Box(
    {
      as,
      alignContent,
      alignItems,
      alignSelf,
      children,
      display,
      flex,
      flexBasis,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      gap,
      justifyContent,
      margin,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      order,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      textAlign,
      ...props
    },
    ref
  ) {
    const legacyProps = getLegacyDisplayAndDirection(display)

    return (
      <StyledBox
        {...props}
        ref={ref}
        $as={as}
        $alignContent={alignContent}
        $alignItems={alignItems}
        $alignSelf={alignSelf}
        $display={legacyProps.display}
        $flex={flex}
        $flexBasis={flexBasis}
        $flexDirection={flexDirection ?? legacyProps.direction}
        $flexGrow={flexGrow}
        $flexShrink={flexShrink}
        $flexWrap={flexWrap}
        $gap={gap}
        $justifyContent={justifyContent}
        $margin={margin}
        $marginBottom={marginBottom}
        $marginLeft={marginLeft}
        $marginRight={marginRight}
        $marginTop={marginTop}
        $order={order}
        $padding={padding}
        $paddingBottom={paddingBottom}
        $paddingLeft={paddingLeft}
        $paddingRight={paddingRight}
        $paddingTop={paddingTop}
        $textAlign={textAlign}
      >
        {children}
      </StyledBox>
    )
  }
)

Box.displayName = 'Box'
