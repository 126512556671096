import * as React from 'react'
import styled from 'styled-components'

const StyledBurger = styled.button<{ open: boolean }>`
  position: absolute;
  top: 90px;
  left: 15px;
  z-index: 1001;
  display: none;
  @media only screen and (max-width: 1100px) {
    display: flex;
  }

  flex-direction: column;
  justify-content: space-around;
  width: 1rem;
  height: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 1rem;
    height: 0.125rem;
    background: gray;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

interface HamburgerIconProps {
  open: boolean
  onClick: () => void
}

const HamburgerIcon = React.forwardRef<HTMLButtonElement, HamburgerIconProps>(
  ({ open, onClick }, ref) => {
    return (
      <StyledBurger open={open} onClick={onClick} ref={ref}>
        <div />
        <div />
        <div />
      </StyledBurger>
    )
  }
)

export default HamburgerIcon
