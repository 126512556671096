import { flip, limitShift, offset, shift } from '@floating-ui/react-dom'
import type { CSSProperties } from 'react'
import type {
  OverlayOffsetValue,
  OverlayRefType,
  ReferenceRefType,
} from '../Overlay/useOverlay'
import { useOverlay } from '../Overlay/useOverlay'
import type { Placement } from '../_utils/placement'

export function useDropdownFlyoutOverlay({
  placement,
  offset: offsetConfig,
}: {
  placement: Placement
  offset?: OverlayOffsetValue
}): {
  overlayRef: OverlayRefType
  referenceRef: ReferenceRefType
  overlayStyle: CSSProperties
} {
  return useOverlay({
    placement,
    middleware: [
      offset(offsetConfig),
      flip(),
      shift({ limiter: limitShift() }),
    ],
  })
}
