import { DetailPageProps } from '@procore/core-react/dist/_typedoc/DetailPage/DetailPage.types.json';
import { PageAsideProps, PageHeaderProps } from '@procore/core-react/dist/_typedoc/PageLayout/PageLayout.types.json';
import { SectionProps } from '@procore/core-react/dist/_typedoc/Section/Section.types.json';
import * as React from 'react';
export default {
  DetailPageProps,
  PageAsideProps,
  PageHeaderProps,
  SectionProps,
  React
};