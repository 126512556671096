import React from 'react'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type {
  DivAttributes,
  Props,
  SpanAttributes,
  SVGAttributes,
} from '../_utils/types'
import { StyledLabel, StyledRemove, StyledToken } from './Token.styles'
import type { TokenProps } from './Token.types'

const Parent = React.forwardRef<HTMLDivElement, DivAttributes & TokenProps>(
  function Token(
    {
      children,
      disabled = false,
      focused = false,
      error = false,
      variant,
      ...props
    },
    ref
  ) {
    return (
      <StyledToken
        {...props}
        ref={ref}
        $disabled={disabled || variant === 'disabled'}
        $focused={focused || variant === 'focused'}
        $error={error}
      >
        {children}
      </StyledToken>
    )
  }
)

const Remove = React.forwardRef<SVGSVGElement, SVGAttributes & Props>(
  function TokenRemove({ ...props }, ref) {
    const i18n = useI18nContext()
    return (
      <StyledRemove
        ref={ref}
        aria-hidden="false"
        aria-label={i18n.t('core.token.remove')}
        {...props}
      />
    )
  }
)

const Label = React.forwardRef<HTMLSpanElement, SpanAttributes & Props>(
  function Label({ children, ...props }, ref) {
    return (
      <StyledLabel ref={ref} {...props}>
        {children}
      </StyledLabel>
    )
  }
)

Parent.displayName = 'Token'

Remove.displayName = 'Token.Remove'

Label.displayName = 'Token.Label'

/**

 We use tokens in our multi select dropdowns and file attachers to indicate what
 has been selected or attached. Tokens in these components are removable.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-token--demo)

 @see [Design Guidelines](https://design.procore.com/tokens)

 */
export const Token = addSubcomponents(
  {
    Label,
    Remove,
  },
  Parent
)
