import { Form as FormikForm } from 'formik'
import styled, { css } from 'styled-components'
import { Typography } from '../Typography'
import { spacing } from '../_styles/spacing'
import { formWrapper } from './Form.styles'
import type { View } from './Form.types'

export const StyledFormikForm = styled(FormikForm)`
  ${formWrapper}
`

export const StyledTraditionalFormLabel = styled(Typography)<{ $view: View }>`
  display: inline-block;
  word-break: break-word;
  word-wrap: break-word;

  ${({ $view }) =>
    ['create', 'update'].includes($view) &&
    css`
      padding-top: ${spacing.sm}px;
    `}
`
