import { ChevronLeft, ChevronRight } from '@procore/core-icons/dist'
import { NumberFormatter } from '@procore/globalization-toolkit'
import { useId } from '@react-aria/utils'
import React from 'react'
import type { Selection } from '../MenuImperative/MenuImperative.types'
import { useI18nContext } from '../_hooks/I18n'
import {
  StyledButton,
  StyledContainer,
  StyledCurrentPage,
  StyledPageCount,
} from './Pagination.styles'
import type {
  PaginationHook,
  PaginationHookConfig,
  PresetPaginationProps,
} from './Pagination.types'
import { PaginationSelect } from './PaginationSelect'

function noop() {}

const usePagination = ({
  activePage = 0,
  items = 0,
  onSelectPage = () => {},
  perPage = 0,
}: PaginationHookConfig): PaginationHook => {
  const pages = Math.ceil(items / perPage)
  activePage = pages === 0 ? 0 : activePage

  return {
    end: Math.min(activePage * perPage, items),
    isFirst: activePage < 2,
    isLast: activePage === pages,
    pages,
    selectNext: () => onSelectPage(activePage + 1),
    selectPage: (selection: Selection) => onSelectPage(selection.item),
    selectPrev: () => onSelectPage(activePage - 1),
    start: Math.max((activePage - 1) * perPage + 1, 0),
  }
}

/**

 We paginate pages that display large data sets to our users. This helps reduce
 page load time and makes the data easier to navigate.

 Pagination is commonly seen on tool landing pages, where we display 150 objects
 or table rows per page.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/demos-pagination--demo)

 @see [Design Guidelines](https://design.procore.com/pagination)

 */
export const Pagination = React.forwardRef<
  HTMLDivElement,
  PresetPaginationProps
>(function Pagination(
  { activePage = 1, items, onSelectPage = noop, perPage = 50, ...props },
  ref
) {
  const {
    end,
    isFirst,
    isLast,
    pages,
    selectNext,
    selectPage,
    selectPrev,
    start,
  } = usePagination({
    activePage,
    items,
    onSelectPage,
    perPage,
  })

  const i18n = useI18nContext()
  const labelId = useId()
  const selectId = useId()

  function formattedNumber(items: number) {
    const formatter = new NumberFormatter({ locale: i18n.locale })

    return formatter.formatNumber(items)
  }

  return (
    <StyledContainer ref={ref} {...props}>
      <StyledPageCount ref={ref} italic color="gray45">
        {i18n.t('core.pagination.notation', {
          end: formattedNumber(end),
          start: formattedNumber(start),
          totalItems: formattedNumber(items || 0),
        })}
      </StyledPageCount>
      <StyledCurrentPage id={labelId}>
        {i18n.t('core.pagination.page')}
      </StyledCurrentPage>
      <PaginationSelect
        activePage={activePage}
        aria-labelledby={`${labelId} ${selectId}`}
        disabled={pages <= 1}
        id={selectId}
        onSelect={selectPage}
        pages={pages}
      />
      <StyledButton
        disabled={isFirst}
        variant="tertiary"
        aria-label={i18n.t('core.pagination.prevPage')}
        icon={<ChevronLeft />}
        onClick={selectPrev}
      />
      <StyledButton
        disabled={isLast}
        variant="tertiary"
        aria-label={i18n.t('core.pagination.nextPage')}
        icon={<ChevronRight />}
        onClick={selectNext}
      />
    </StyledContainer>
  )
})

Pagination.displayName = 'Pagination'
