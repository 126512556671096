import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const dataQa = {
  focused: 'core-input-focused',
  hovered: 'core-input-hovered',
}

const inputHeight = 36

export function getInputStyles({
  error,
  variant,
}: {
  error?: boolean
  variant?: 'error' | ''
}) {
  return css`
    ${getTypographyIntent('body')}

    background-color: ${colors.white};
    border-color: ${colors.gray70};
    border-radius: ${borderRadius.md}px;
    border-style: solid;
    border-width: 1px;
    color: ${colors.gray15};
    display: block;
    font-family: inherit;
    height: ${inputHeight}px;
    width: 100%;

    &::placeholder {
      color: ${colors.gray45};
    }

    /* Firefox 19+ */
    &::-moz-placeholder {
      color: ${colors.gray45};
      opacity: 1;
    }

    ${!error &&
    css`
      &:hover,
      &[data-qa=${dataQa.hovered}] {
        border-color: ${colors.gray45};
      }
    `}

    &:focus,
    &[data-qa=${dataQa.focused}] {
      ${getFocus()}
    }

    &:disabled {
      background-color: ${colors.gray94};
      border-color: ${colors.gray70};
      color: ${colors.gray45};
      // Safari: fixes lighter color of disabled input text
      -webkit-text-fill-color: ${colors.gray45};
      opacity: 1;
    }

    ${(error || variant === 'error') &&
    css`
      border-color: ${colors.red50};
      background-color: ${colors.white};

      // TODO we can clean up these css vars when we convert Form to JSS
      border-color: var(--core-input-error-border-color, ${colors.red50});
      background-color: var(
        --core-input-error-background-color,
        ${colors.white}
      );
    `}
  `
}

export const StyledInput = styled.input`
  ${getInputStyles}

  padding: 0 ${spacing.md}px;

  &::-ms-clear {
    display: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
