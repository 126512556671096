import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

const trackHeight = 20
const trackWidth = 36
const toggleSize = 16

export const StyledTrack = styled.div`
  background-color: ${colors.gray70};
  border-radius: ${trackHeight}px;
  height: ${trackHeight}px;
  width: ${trackWidth}px;
  position: relative;
  transition: 0.2s;
`

export const StyledToggle = styled.span`
  background-color: ${colors.white};
  border-radius: 50%;
  bottom: 2px;
  height: ${toggleSize}px;
  left: 2px;
  position: absolute;
  transition: all 0.2s;
  width: ${toggleSize}px;
`

export const StyledSwitchLabel = styled.span`
  ${getTypographyIntent('body')}
  padding-left: ${spacing.md}px;
  user-select: none;
`

export const StyledSwitchContainer = styled.label<{
  $disabled?: boolean
  $checked?: boolean
  $focused: boolean
}>`
  cursor: pointer;
  display: grid;
  grid-template-columns: ${trackWidth}px 1fr;
  position: relative;

  & + & {
    margin-top: ${spacing.md}px;
  }

  ${({ $checked }) => {
    return (
      $checked &&
      css`
        ${StyledTrack} {
          background-color: ${colors.blue50};

          ${StyledToggle} {
            transform: translateX(${spacing.lg}px);
          }
        }
      `
    )
  }}

  ${({ $focused }) => {
    return (
      $focused &&
      css`
        ${getFocus()}
        border-radius: 12px;
      `
    )
  }}

  ${({ $disabled = false, $checked }) =>
    $disabled &&
    css`
      cursor: default;

      ${StyledTrack} {
        background-color: ${$checked ? colors.blue85 : colors.gray85};
      }
    `}
`
