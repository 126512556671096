import React from 'react'
import { Calendar as CalendarBase } from '../Calendar/Calendar'
import type { CalendarProps } from '../Calendar/Calendar.types'
import { Card } from '../Card/Card'
import { DateInput, isValidYearRange } from '../DateInput/DateInput'
import {
  OverlayTrigger,
  useOverlayTriggerContext,
} from '../OverlayTrigger/OverlayTrigger'
import { isEventSource } from '../_hooks/ClickOutside'
import { useDateTime } from '../_hooks/DateTime'
import type { DateSelectProps } from './DateSelect.types'

const enableLogs = process.env.TZ_LOG?.toLowerCase() === 'true'

function consoleMessageChange({ date }: any) {
  if (enableLogs) {
    console.log(
      `core-react: DateInput onChange, relative start of day in destination time zone.\n`,
      date
    )
  }
}

const showKeys: string[] = []

const hideKeys = {
  target: ['Esc', 'Escape', 'Enter'],
  overlay: ['Esc', 'Escape'],
}

function lifecycleDefault() {
  return true
}

function noop() {}

const Calendar = React.forwardRef<HTMLDivElement, CalendarProps>(
  function Calendar({ onSelect, ...props }, ref) {
    const { hide } = useOverlayTriggerContext()

    return (
      <Card ref={ref} shadowStrength={2}>
        <CalendarBase
          {...props}
          onSelect={(date) => {
            onSelect?.(date)
            hide(true)
          }}
        />
      </Card>
    )
  }
)

/**

 We use date selects to allow users to enter a date into a form by either
 selecting it from a calendar or typing it into a date field.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-dateselect--demo)

 @see [Design Guidelines](https://design.procore.com/date-select)

 */
export const DateSelect = React.forwardRef<HTMLDivElement, DateSelectProps>(
  function DateSelect(
    {
      beforeHide = lifecycleDefault,
      beforeShow = lifecycleDefault,
      afterHide = noop,
      afterShow = noop,
      disabledDate,
      onChange = noop,
      onClear = noop,
      value,
      ...props
    },
    ref
  ) {
    const dateTime = useDateTime()

    const clearRef = React.useRef<HTMLButtonElement>(null)

    const dateInputRef = ref as React.RefObject<HTMLDivElement>
    React.useRef<HTMLDivElement>(null)

    const monthRef = React.useRef<HTMLDivElement>(null)

    const yearRef = React.useRef<HTMLDivElement>(null)

    const segmentRefs = {
      segmentThree: React.useRef<HTMLDivElement>(null),
    }

    const [selected, setSelected] = React.useState(value)

    const [displayed, setDisplayed] = React.useState(
      value || dateTime.newDate()
    )

    React.useEffect(() => {
      setSelected(value)
      setDisplayed(value || dateTime.newDate())
    }, [value])

    const overlay = (
      <Calendar
        data-qa="core-date-select-calendar"
        disabledDate={disabledDate}
        displayDate={displayed}
        monthRef={monthRef}
        onNavigate={setDisplayed}
        onSelect={(date) => {
          segmentRefs.segmentThree.current &&
            segmentRefs.segmentThree.current.focus()

          setDisplayed(date)
          setSelected(date)
          onChange(date)
        }}
        selectedStart={selected}
        selectedEnd={selected}
        yearRef={yearRef}
      />
    )

    return (
      <OverlayTrigger
        afterHide={afterHide}
        afterShow={afterShow}
        beforeHide={(e) => {
          if (e instanceof KeyboardEvent) {
            segmentRefs.segmentThree.current &&
              segmentRefs.segmentThree.current.focus()

            return beforeHide(e)
          }

          if (isEventSource(dateInputRef, e) || isEventSource(clearRef, e)) {
            return false
          }
          return beforeHide(e)
        }}
        beforeShow={(e) => {
          if (isEventSource(clearRef, e)) {
            return false
          }
          return beforeShow(e)
        }}
        clickOutsideIgnoreRefs={[monthRef, yearRef]}
        hideKeys={hideKeys}
        overlay={overlay}
        placement="bottom-left"
        ref={dateInputRef}
        showKeys={showKeys}
      >
        <DateInput
          {...props}
          clearRef={clearRef}
          segmentRefs={segmentRefs}
          onChangeSegment={(type, value) => {
            if (value === -1) {
              return
            }

            if (type === 'month') {
              setDisplayed(dateTime.newDate(displayed.setMonth(value - 1)))
            } else if (type === 'year') {
              // isValidYearRange prevents calendar from changing, the display date
              if (isValidYearRange(value)) {
                setDisplayed(dateTime.newDate(displayed.setFullYear(value)))
              }
            }
          }}
          onChange={(date: Date | null) => {
            consoleMessageChange({ date })
            if (date) {
              setSelected(date)
              setDisplayed(date)
            } else {
              setDisplayed(dateTime.newDate())
              setSelected(undefined)
            }

            onChange(date)
          }}
          onClear={onClear}
          value={selected}
        />
      </OverlayTrigger>
    )
  }
)
