import { useLabel } from '@react-aria/label'
import React from 'react'
import { Required } from '../Required/Required'
import {
  StyledCheckboxContainer,
  StyledCheckboxInput,
  StyledCheckboxLabel,
  StyledCheckboxLabelWrapper,
  StyledFakeCheckboxInput,
  StyledFakeCheckboxLabel,
} from './Checkbox.styles'
import type { CheckboxProps } from './Checkbox.types'
import { CheckboxTooltip } from './CheckboxTooltip'

/**


 We use checkboxes to provide users with the ability to either select or
 deselect multiple values from a list of options (e.g. items on list pages),
 or to select or deselect a single option (e.g. settings on configure pages).

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-checkbox--demo)

 @see [Design Guidelines](https://design.procore.com/checkbox)

 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(
    {
      checked,
      children,
      className,
      error = false,
      indeterminate = false,
      style,
      requiredMark,
      tooltip,
      ...props
    },
    ref
  ) {
    const { fieldProps, labelProps } = useLabel({
      ...props,
      // to always generate labelProps, so checkbox icon can be clickable for empty labels
      label: true,
    })
    return (
      <StyledCheckboxContainer className={className} style={style}>
        <StyledCheckboxInput
          {...props}
          {...fieldProps}
          aria-checked={indeterminate ? 'mixed' : checked}
          type="checkbox"
          checked={checked}
          $error={error}
          $indeterminate={indeterminate}
          ref={ref}
        />
        <StyledCheckboxLabelWrapper $hasLabel={Boolean(children)}>
          <StyledCheckboxLabel {...labelProps}>
            {children}
            {requiredMark && <Required />}
          </StyledCheckboxLabel>
          {tooltip && <CheckboxTooltip overlay={tooltip} />}
        </StyledCheckboxLabelWrapper>
      </StyledCheckboxContainer>
    )
  }
)

Checkbox.displayName = 'Checkbox'

/**
 *  This checkbox was created to be used as a non-interactive Checkbox component
 *  for VoiceOver inside MenuImperative.CheckboxItem.
 *
 *  @since 11.3.0
 */

export const FakeCheckbox = React.forwardRef<
  HTMLDivElement,
  Omit<CheckboxProps, 'requiredMark' | 'tooltip'>
>(function FakeCheckbox(
  {
    checked = false,
    children,
    className,
    disabled = false,
    error = false,
    indeterminate = false,
    style,
    ...props
  },
  ref
) {
  return (
    <StyledCheckboxContainer className={className} style={style}>
      <StyledFakeCheckboxInput
        {...props}
        $checked={checked}
        $disabled={disabled}
        $error={error}
        $indeterminate={indeterminate}
        ref={ref}
      />
      <StyledFakeCheckboxLabel $checked={checked} $disabled={disabled}>
        {children}
      </StyledFakeCheckboxLabel>
    </StyledCheckboxContainer>
  )
})

FakeCheckbox.displayName = 'FakeCheckbox'
