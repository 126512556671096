import { AvatarStackItem } from '@procore/core-react'
import { logos, photos } from './images'

export const generateAvatars = (slice = 23) => {
  const avatars: AvatarStackItem[] = [
    {
      id: 392,
      type: 'group',
      name: 'Architects',
      description: '12 members',
    },
    {
      id: 39,
      type: 'company',
      name: 'Rush Hour',
      description: 'No rush',
      initials: 'whatever',
    },
    {
      id: 1,
      type: 'user',
      imageUrl: photos[0],
      name: 'Jack Canty',
      description: 'PM at Lumber Jack',
      initials: 'JC',
      inactive: true,
    },
    {
      id: 2,
      type: 'user',
      imageUrl: photos[1],
      name: 'Bob Smith',
      description: 'PM at Building Inspired',
      initials: 'BS',
    },
    {
      id: 312,
      type: 'company',
      name: 'Planes and Stuff',
      description: 'We build planes and stuff',
    },
    {
      id: 3,
      type: 'user',
      name: 'Alex Waters',
      description: 'HR at Work Push',
      initials: 'AW',
      inactive: true,
    },
    {
      id: 83,
      type: 'group',
      name: 'Accountants',
      description: '21 members',
    },
    {
      id: 4,
      type: 'user',
      name: 'Tracey Molly',
      description: 'Architect at Brick Ltd.',
      initials: 'TM',
    },
    {
      id: 5,
      type: 'user',
      imageUrl: photos[2],
      linkUrl: 'http://somewhere.com',
      name: 'Andrew Arctur',
      description: 'Builder at Building Inspired',
      initials: 'AA',
    },
    {
      id: 311,
      type: 'company',
      name: 'OSCORP',
      description: 'From comics',
    },
    {
      id: 18,
      type: 'user',
      name: 'Jimmy Alexander',
      imageUrl: photos[3],
      description: 'Builder at Company That Build Really Huge Things All Time',
    },
    {
      id: 34,
      type: 'company',
      name: 'Brick Ltd.',
      description: 'Laying them',
      initials: 'whatever',
    },
    {
      id: 19,
      type: 'user',
      linkUrl: 'http://somewhere.com',
      imageUrl: photos[4],
      name: 'Louis George Maurice Adolphe Roche Albert Jullien',
      description: 'Engineer at Work Push',
    },
    {
      id: 6,
      type: 'user',
      imageUrl: photos[0],
      name: 'Pete Valentine',
      description: 'PM at Building Inspired',
      initials: 'PV',
    },
    {
      id: 7,
      type: 'user',
      name: 'Zack Mayer',
      linkUrl: 'http://somewhere.com',
      description: 'Engineer at Lumber Jack',
      initials: 'ZM',
      inactive: true,
    },
    {
      id: 8,
      type: 'user',
      imageUrl: photos[1],
      name: 'Jackson Vally',
      description: 'Architect at Brick Ltd.',
      initials: 'JV',
    },
    {
      id: 36,
      type: 'company',
      imageUrl: logos[2],
      name: 'ABC Corp',
      initials: 'whatever',
    },
    {
      id: 9,
      type: 'user',
      name: 'Artemiy Matiz',
      description: 'Software Engineer at Rush Hour Inc.',
      initials: 'AM',
    },
    {
      id: 33,
      type: 'company',
      imageUrl: logos[4],
      name: 'Work Push',
      description: 'Pushing some work',
      initials: 'whatever',
      inactive: true,
    },
    {
      id: 10,
      type: 'user',
      name: 'Andre Johnson',
      linkUrl: 'http://somewhere.com',
      description: 'Unit Manager at Brick Ltd.',
      initials: 'AJ',
    },
    {
      id: 11,
      type: 'user',
      imageUrl: photos[3],
      linkUrl: 'http://somewhere.com',
      name: 'Nick Drywood',
      description: 'No Company',
    },
    {
      id: 31,
      type: 'company',
      imageUrl: logos[3],
      name: 'Lumber Jack',
      description: 'Wood provider',
      initials: 'whatever',
      inactive: true,
    },
    {
      id: 12,
      type: 'user',
      imageUrl: photos[4],
      name: 'Olly Jack',

      description: 'Sr. Unit Manager at Building Inspired',
    },
    {
      id: 13,
      type: 'user',
      imageUrl: photos[1],
      name: 'Andre Agassi',
      linkUrl: 'http://somewhere.com',
      description: 'Brick Ltd.',
    },
    {
      id: 14,
      type: 'user',
      name: 'Pete Valentine',
      description: 'Engineer at Lumber Jack',
      initials: 'LJ',
    },
    {
      id: 32,
      type: 'company',
      imageUrl: logos[2],
      name: 'Building Inspired',
      description: 'Building things',
      initials: 'whatever',
    },
    {
      id: 15,
      type: 'user',
      imageUrl: photos[2],
      name: 'Angelo B.',
      description: 'No Company',
    },
    {
      id: 16,
      type: 'user',
      imageUrl: photos[3],
      name: ' Ryan',
      linkUrl: 'http://somewhere.com',
      description: 'PM at Work Push',
    },
    {
      id: 17,
      type: 'user',
      name: 'George',
      description: 'Builder at Building Homeland',
      initials: 'G',
    },
    {
      id: 20,
      type: 'user',
      imageUrl: photos[4],
      name: 'Martin Lewis',
      linkUrl: 'http://somewhere.com',
      description: 'HR at ENCO Corp',
    },
    {
      id: 21,
      type: 'user',
      imageUrl: photos[0],
      name: 'Rafael Nadal',
      description: 'No Company',
    },
    {
      id: 35,
      type: 'company',
      imageUrl: logos[1],
      name: 'Building Inspired',
      description: 'Building things',
      initials: 'whatever',
    },
    {
      id: 22,
      type: 'user',
      imageUrl: photos[1],
      name: 'Alex Baldwin',
      linkUrl: 'http://somewhere.com',
      description: 'Recruiter at ENCO Corp',
    },
    {
      id: 23,
      type: 'user',
      imageUrl: photos[2],
      name: 'Jean Luke Andre Peter Drywoodshernhensonbergerzungermanalexander',
      description:
        'Compliance Officer at at Serious Company With Strong Culture',
    },
  ]
  return avatars.slice(0, slice)
}
