import { Lock } from '@procore/core-icons/dist'
import { Card } from '@procore/core-react'
import { Link } from 'gatsby'
import * as React from 'react'
import 'reset-css'
import styled from 'styled-components'
import blueprintsImgSrc from '../images/homepage-blueprints.png'
import dotsImgSrc from '../images/homepage-dots.png'
import { Footer } from '../partials/Footer'
import { Header } from '../partials/Header'
import { useAuth0 } from '../utils/auth'
import { colors, layout } from '../utils/styles'

const BackgroundView = styled.div`
  position: relative;
  z-index: 1;
`

const FixedViewportView = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`

const BlueprintView = styled(FixedViewportView)`
  background-image: url(${blueprintsImgSrc});
  background-size: cover;
  z-index: 1;
`

const BlueprintOverlayView = styled(FixedViewportView)`
  background: white;
  opacity: 0.85;
  z-index: 2;
`

const GradientView = styled(FixedViewportView)`
  background: linear-gradient(to right, rgba(245, 245, 245, 1), white);
  left: 50%;
  z-index: 3;
`

const DotsView = styled(FixedViewportView)`
  background-image: url(${dotsImgSrc});
  background-repeat: repeat;
  opacity: 0.5;
  top: ${layout.headerHeight}px;
  z-index: 5;
`

const HomeView = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${layout.headerHeight}px);
  padding: 0 48px;
  position: relative;
  z-index: 2;
`

const BodyView = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  max-width: 1064px;
  margin-top: 20px;

  h1 {
    color: ${colors._707070};
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 24px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const TilesView = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;
`

const CardBackgroundView = styled(Card)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.2s all ease-out;
  width: 100%;
  z-index: 1;
`

const CardDetailsView = styled.div`
  position: relative;
  pointer-events: none;
  z-index: 2;

  h3 {
    font-size: 22px;
    line-height: 32px;

    &::after {
      background: ${colors.e36937};
      content: '';
      display: block;
      height: 2px;
      margin: 16px 0;
      transition: 0.2s all ease-out;
      width: 24px;
    }
  }

  p {
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
  }
`

const CardDetailsOwner = styled.p`
  color: ${colors._707070};
  margin-top: 10px;
`

const CardView = styled.div`
  color: ${colors._282828};
  padding: 24px;
  position: relative;
  min-height: 194px;
  cursor: pointer;

  &:hover {
    ${CardBackgroundView} {
      transform: scale(1.05);
    }

    ${CardDetailsView} h3::after {
      width: 18px;
    }
  }
`

const LockIconView = styled(Lock)`
  position: absolute;
  right: 0;
`

const StyledHeader = styled.h2`
  font-size: 28px;
  color: #666;
  text-align: left;
  width: 100%;
  margin: 20px 0;
`

const Divider = styled.div`
  height: 1px;
  background: #ccc;
  width: 100%;
  margin-top: 40px;
`

interface SharedHomeCardProps {
  title: string
  description: string
  path?: string
  onClick?: () => void
  lock?: boolean
}

const HomeCardContents: React.FC<SharedHomeCardProps> = function ({
  title,
  description,
  lock,
}) {
  return (
    <CardView>
      <CardBackgroundView level="20" variant="hoverable" />
      <CardDetailsView>
        {lock ? <LockIconView size="sm"></LockIconView> : null}
        <h3>{title}</h3>
        <p>{description}</p>
      </CardDetailsView>
    </CardView>
  )
}

const HomeCard: React.FC<SharedHomeCardProps> = function HomeCard({
  path,
  onClick,
  ...rest
}) {
  return (
    <>
      {onClick ? (
        <div onClick={onClick}>
          <HomeCardContents {...rest} />
        </div>
      ) : (
        <Link to={path || ''}>
          <HomeCardContents {...rest} />
        </Link>
      )}
    </>
  )
}

const HomeTemplate: React.FC<{}> = function HomeTemplate() {
  const { login, isAuthenticated } = useAuth0()
  return (
    <>
      <BackgroundView>
        <BlueprintView />
        <BlueprintOverlayView />
        <GradientView />
        <DotsView />
      </BackgroundView>
      <Header fillBackground />
      <HomeView>
        <BodyView>
          <h1>
            Procore’s CORE design system assists designers and developers in
            creating consistent, delightful user experiences for Procore's
            applications. The system consists of working code, design
            guidelines, and summaries of best practices, tips, and other useful
            resources.
          </h1>
          <StyledHeader>Design System</StyledHeader>
          <TilesView>
            <HomeCard
              path="/web/introduction"
              title="Developer Guides"
              description="Explore the CORE React front end component library and learn how to use the library to develop your application."
            />
            <HomeCard
              path="https://design.procore.com"
              title="Design Guidelines"
              description="Guidelines to ensure a consistent and unified experience for Procore's users."
            />
            {isAuthenticated ? (
              <HomeCard
                path="https://stories.core.procore.com/"
                title="Core Storybook"
                description="An interactive playground for viewing components in the Design System."
              />
            ) : (
              <HomeCard
                onClick={() => login()}
                title="Employee Login"
                description="Additional resources for Procore employees."
                lock={true}
              />
            )}
          </TilesView>
          {isAuthenticated && (
            <>
              <Divider />
              <StyledHeader>Other Resources</StyledHeader>
              <TilesView>
                <>
                  <HomeCard
                    path="https://procore.github.io/core-labs/"
                    title="CORE Labs"
                    description="Contribute to or reference experiments in Core Labs: a repository for evolving web components available to Procore employees."
                  />
                  <HomeCard
                    path="https://stories.other-libraries.procore.com/"
                    title="Storybook Aggregator"
                    description="This is a collection of storybooks that is built by different teams to demonstrate different components in a central place."
                  />
                  <HomeCard
                    path="https://procoretech.atlassian.net/wiki/spaces/RDOPS/pages/1995931750/Pendo"
                    title="Pendo Guidelines"
                    description="Learn how we use Pendo to make announcements, guide users through new releases or solicit feedback."
                  />
                  <HomeCard
                    path="https://procore.github.io/globalization-toolkit/"
                    title="Globalization Toolkit"
                    description="i18n formatting library"
                  />
                </>
              </TilesView>
            </>
          )}
        </BodyView>
        <Footer />
      </HomeView>
    </>
  )
}

export default HomeTemplate
