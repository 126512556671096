import { ChevronLeft, Clear, EllipsisVertical } from '@procore/core-icons/dist'
import React from 'react'
import { Button } from '../Button'
import { Menu } from '../Menu'
import { OverlayTrigger } from '../OverlayTrigger'
import { LevelContext, Section, SectionProvider } from '../Section'
import { Tooltip } from '../Tooltip'
import type { TypographyProps } from '../Typography'
import { useI18nContext } from '../_hooks/I18n'
import { useOverflowObserver } from '../_hooks/OverflowObserver'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { isReactElement } from '../_utils/isReactElement'
import { mergeRefs } from '../_utils/mergeRefs'
import { partition } from '../_utils/partition'
import type { DivAttributes } from '../_utils/types'
import {
  StyledBody,
  StyledCloseButton,
  StyledFooter,
  StyledFooterActions,
  StyledFooterNotation,
  StyledHeader,
  StyledHeaderActions,
  StyledHeaderBackAction,
  StyledMoreMenu,
  StyledPanel,
  StyledTitle,
} from './Panel.styles'
import type {
  PanelBodyProps,
  PanelContextShape,
  PanelHeaderActionProps,
  PanelHeaderProps,
  PanelProps,
  PanelQaTags,
  PanelTitleTooltipProps,
} from './Panel.types'

const PanelContext = React.createContext<PanelContextShape>({
  isScrolling: false,
  setIsScrolling: () => {},
})

const usePanelContext = () => React.useContext(PanelContext)

const maxVisibleIcons = 3

const HeaderAction = React.forwardRef<HTMLSpanElement, PanelHeaderActionProps>(
  function HeaderAction(
    { title, children, variant = 'tertiary', ...props },
    ref
  ) {
    return (
      <Tooltip overlay={title} trigger={['hover', 'focus']} ref={ref}>
        <Button aria-label={title} {...props} variant={variant}>
          {children}
        </Button>
      </Tooltip>
    )
  }
)

const MoreMenu = React.forwardRef<HTMLDivElement, DivAttributes>(
  function MoreMenu({ children }, ref) {
    return (
      <StyledMoreMenu ref={ref}>
        <Menu ref={(node) => node?.focus()}>
          <Menu.Options>
            {React.Children.map(children, (child) => {
              if (isReactElement<PanelHeaderActionProps>(child)) {
                return (
                  <Menu.Item
                    item={null}
                    key={child.props.title}
                    onClick={
                      child.props.onClick as (e: React.MouseEvent) => void
                    }
                  >
                    {child.props.title}
                  </Menu.Item>
                )
              }
            })}
          </Menu.Options>
        </Menu>
      </StyledMoreMenu>
    )
  }
)

export const MoreIcon: React.FC<
  DivAttributes & { qa?: Pick<PanelQaTags, 'moreIcon'> }
> = ({ children, qa }) => {
  const i18n = useI18nContext()

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-right"
      overlay={<MoreMenu>{children}</MoreMenu>}
    >
      <Button
        aria-label={i18n.t('core.dropdown.moreOptions')}
        data-qa={qa?.moreIcon}
        variant="tertiary"
        icon={<EllipsisVertical />}
      />
    </OverlayTrigger>
  )
}

const HeaderActions = React.forwardRef<
  HTMLDivElement,
  DivAttributes & { qa?: Pick<PanelQaTags, 'moreIcon'> }
>(function HeaderActions({ children, qa, ...props }, ref) {
  const iconsAmount = React.Children.count(children)

  const [visibleIcons, hiddenIcons] =
    iconsAmount > maxVisibleIcons
      ? partition(
          (_, index) => index > iconsAmount - maxVisibleIcons,
          React.Children.toArray(children)
        )
      : [children, []]

  return (
    <StyledHeaderActions {...props} ref={ref}>
      {visibleIcons}
      {hiddenIcons.length > 0 && <MoreIcon qa={qa}>{hiddenIcons}</MoreIcon>}
    </StyledHeaderActions>
  )
})

export const TitleTooltip: React.FC<PanelTitleTooltipProps> = ({
  children,
  overlay,
  isVisible,
}) => {
  if (!isVisible) {
    return children as React.ReactElement
  }

  return (
    <Tooltip
      trigger={['hover', 'focus']}
      overlay={<Tooltip.Content>{overlay}</Tooltip.Content>}
      placement="bottom"
    >
      {children}
    </Tooltip>
  )
}

const Title = React.forwardRef<
  HTMLSpanElement,
  TypographyProps & { qa?: Pick<PanelQaTags, 'title'> }
>(function Title({ children, qa, ...props }, ref) {
  const { isOverflowingX, ref: overflowRef } = useOverflowObserver()

  return (
    <TitleTooltip isVisible={isOverflowingX} overlay={children}>
      <StyledTitle
        {...props}
        ref={mergeRefs(overflowRef, ref)}
        intent="h2"
        data-qa={qa?.title}
      >
        {children}
      </StyledTitle>
    </TitleTooltip>
  )
})

const Header = React.forwardRef<HTMLDivElement, PanelHeaderProps>(
  function Header({ children, onClickBack, onClose, ...props }, ref) {
    const I18n = useI18nContext()
    return (
      <StyledHeader {...props} ref={ref}>
        <StyledHeaderBackAction active={onClickBack !== undefined}>
          <Button
            aria-label={I18n.t('core.panel.goBack')}
            variant="tertiary"
            onClick={onClickBack}
            icon={<ChevronLeft />}
          />
        </StyledHeaderBackAction>
        {children}
        {typeof onClose === 'function' && (
          <StyledCloseButton>
            <HeaderAction
              title={I18n.t('core.panel.close')}
              icon={<Clear />}
              onClick={onClose}
            />
          </StyledCloseButton>
        )}
      </StyledHeader>
    )
  }
)

const Body = React.forwardRef<HTMLDivElement, PanelBodyProps>(function Body(
  { children, ...props },
  ref
) {
  const { setIsScrolling } = usePanelContext()

  const timer = React.useRef<NodeJS.Timer | null>(null)

  const handleScroll = React.useCallback(() => {
    setIsScrolling(true)

    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      setIsScrolling(false)
    }, 100)
  }, [setIsScrolling])

  return (
    <SectionProvider>
      <LevelContext.Provider value={3}>
        <StyledBody {...props} ref={ref} onScroll={handleScroll}>
          {children}
        </StyledBody>
      </LevelContext.Provider>
    </SectionProvider>
  )
})

const FooterNotation = React.forwardRef<HTMLDivElement, DivAttributes>(
  function FooterNotation({ children, ...props }, ref) {
    return (
      <StyledFooterNotation {...props} ref={ref}>
        {children}
      </StyledFooterNotation>
    )
  }
)

const FooterActions = React.forwardRef<HTMLDivElement, DivAttributes>(
  function FooterActions({ children, ...props }, ref) {
    return (
      <StyledFooterActions {...props} ref={ref}>
        {children}
      </StyledFooterActions>
    )
  }
)

const Footer = React.forwardRef<HTMLDivElement, DivAttributes>(function Footer(
  { children, ...props },
  ref
) {
  const { isScrolling } = usePanelContext()

  return (
    <StyledFooter {...props} ref={ref} $hasShadow={isScrolling}>
      {children}
    </StyledFooter>
  )
})

const Panel_ = React.forwardRef<HTMLDivElement, PanelProps>(function Panel(
  { children, ...props },
  ref
) {
  const [isScrolling, setIsScrolling] = React.useState(false)

  return (
    <PanelContext.Provider
      value={{
        isScrolling,
        setIsScrolling,
      }}
    >
      <StyledPanel {...props} ref={ref}>
        {children}
      </StyledPanel>
    </PanelContext.Provider>
  )
})

Panel_.displayName = 'Panel'
Body.displayName = 'Panel.Body'
Footer.displayName = 'Panel.Footer'
FooterActions.displayName = 'Panel.FooterActions'
FooterNotation.displayName = 'Panel.FooterNotation'
Header.displayName = 'Panel.Header'
HeaderAction.displayName = 'Panel.HeaderAction'
HeaderActions.displayName = 'Panel.HeaderActions'
Title.displayName = 'Panel.Title'

/**

 Panels help users complete tasks or view additional information alongside the
 main content of a page.

 Panels are often initiated by clicking on an item somewhere on the main page,
 and they often contain additional information or options specific to that item.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-panel--demo)

 @see [Design Guidelines](https://design.procore.com/panel)

 */
export const Panel = addSubcomponents(
  {
    Body,
    Footer,
    FooterActions,
    FooterNotation,
    Header,
    HeaderAction,
    HeaderActions,
    Section,
    Title,
  },
  Panel_
)
