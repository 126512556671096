import { Locale } from '@procore/core-react'

export const LOCALES: Locale[] = [
  'en',
  'de-DE',
  'en-CA',
  'fr-CA',
  'fr-FR',
  'es',
  'es-ES',
  'en-AU',
  'en-AE',
  'en-GB',
  'en-SG',
  'ja-JP',
  'th-TH',
  'pl-PL',
  'pt-BR',
  'is-IS',
  'zh-SG',
  'pseudo',
]
