import React from 'react'

export const useGridNavigation = ({
  indicesRows: initialIndicesRows,
}: {
  indicesRows: number[][]
}) => {
  const [index, setIndex] = React.useState(0)
  const [indicesRows, setIndicesRows] = React.useState(initialIndicesRows)
  const [gridSize, setGridSize] = React.useState(0)

  React.useEffect(() => {
    setGridSize(indicesRows.flat().length)
  }, [indicesRows])

  React.useEffect(() => {
    setIndex((prev) => (prev < gridSize ? prev : 0))
  }, [gridSize])

  const finalIndex = gridSize - 1

  const up = (e: React.KeyboardEvent) => {
    e.preventDefault()

    const getNextIndex = () => {
      const rowIndex = indicesRows.findIndex((rowCells) =>
        rowCells.includes(index)
      )
      const nextRowIndex = rowIndex - 1

      if (nextRowIndex < 0) {
        return indicesRows[rowIndex][0]
      }

      const nextRow = indicesRows[nextRowIndex]
      const nextCellIndex = indicesRows[rowIndex].findIndex(
        (navigationIndex) => navigationIndex === index
      )
      const nextNavigationIndex = nextRow[nextCellIndex]

      return nextNavigationIndex ?? nextRow[nextRow.length - 1]
    }

    const nextIndex = getNextIndex()

    setIndex(nextIndex)

    return nextIndex
  }

  const down = (e: React.KeyboardEvent) => {
    e.preventDefault()

    const getNextIndex = () => {
      const rowIndex = indicesRows.findIndex((rowCells) =>
        rowCells.includes(index)
      )
      const nextRowIndex = rowIndex + 1
      const finalRowIndex = indicesRows.length - 1

      if (nextRowIndex > finalRowIndex) {
        const row = indicesRows[rowIndex]

        return row[row.length - 1]
      }

      const nextRow = indicesRows[nextRowIndex]
      const nextCellIndex = indicesRows[rowIndex].findIndex(
        (navigationIndex) => navigationIndex === index
      )
      const nextNavigationIndex = nextRow[nextCellIndex]

      return nextNavigationIndex ?? nextRow[nextRow.length - 1]
    }
    const nextIndex = getNextIndex()

    setIndex(nextIndex)

    return nextIndex
  }

  const right = (e: React.KeyboardEvent) => {
    if (index === finalIndex) {
      return index
    }

    e.preventDefault()

    const nextIndex = index + 1

    if (nextIndex <= finalIndex) {
      setIndex(nextIndex)
    }

    return nextIndex
  }

  const left = (e: React.KeyboardEvent) => {
    if (index === 0) {
      return index
    }

    e.preventDefault()

    const nextIndex = index - 1

    if (nextIndex >= 0) {
      setIndex(nextIndex)
    }

    return nextIndex
  }

  return {
    index,
    setIndex,
    setIndicesRows,
    up,
    down,
    left,
    right,
  }
}
