import styled from 'styled-components'
import { Modal } from '../../Modal/Modal'
import { colors } from '../../_styles/colors'
import { spacing } from '../../_styles/spacing'

export const modalBodyHeight = 460
const modalBodyMinHeight = 460
const sourcePadding = spacing.md * 2
const modalContainerMinWidth = 968
const modalContainerMinHeight = 637
const modalContainerMaxWidth = 1682
const modalContainerMaxHeight = 996
const modalContainerMargin = 80

export const StyledModalOverlay = styled(Modal.Overlay)<{ $open: boolean }>`
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  visibility: hidden;

  ${(props) =>
    props.$open &&
    `
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  `}
`

export const StyledModalContainer = styled(Modal.Container)`
  width: calc(100% - ${modalContainerMargin * 2}px);
  height: calc(100% - ${modalContainerMargin * 2}px);
  top: calc(50vh - ${modalContainerMargin}px);
  left: calc(50vw - ${modalContainerMargin}px);
  margin: ${modalContainerMargin}px;
  min-width: ${modalContainerMinWidth}px;
  min-height: ${modalContainerMinHeight}px;
  max-width: ${modalContainerMaxWidth}px;
  max-height: ${modalContainerMaxHeight}px;
`

export const StyledModalBody = styled(Modal.Body)`
  min-height: ${modalBodyMinHeight}px;
  height: 100%;
  margin: 0;
  max-width: none;
  padding: 0;
  border-bottom: 1px solid ${colors.gray85};
`

export const StyledModalInnerBody = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`

export const StyledModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid ${colors.gray85};
`

export const StyledModalContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  outline: none;
`

export const StyledSource = styled.div`
  width: 100%;
  height: 100%;
`

export const StyledAttachButton = styled.div`
  margin-left: ${spacing.md}px;
`

export const StyledFileTreeSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
