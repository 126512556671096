import React from 'react'

export type ThumbnailDocumentIconVariant =
  | 'image'
  | 'unknown'
  | 'zip'
  | 'drawing'
  | 'email'
  | 'excel'
  | 'gantt'
  | 'ini'
  | 'mov'
  | 'pdf'
  | 'powerpoint'
  | 'word'
  | 'xml'
  | '3d'
  | 'folder'
  | 'folderPeople'
  | 'folderLock'
  | 'file'

export interface DocumentIconProps {
  variant: ThumbnailDocumentIconVariant
  className?: string
  width?: string | number
  height?: string | number
  focusable?: boolean
}

function Unknown(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0H15L21 6V24H3V0Z"
        fill="#D6DADC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0L21 6H15V0Z"
        fill="#EEF0F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6L21 12V6H15Z"
        fill="#ACB5B9"
      />
      <path
        d="M10.3925 12.3347C10.3925 11.9288 10.4718 11.4314 10.69 11.075C10.8538 10.8075 11.1576 10.4993 11.9626 10.4993C12.9321 10.4993 13.2676 10.7795 13.385 10.9179C13.527 11.0854 13.6075 11.3503 13.6075 11.7099C13.6075 12.2888 13.2431 12.7633 12.6054 13.0819C11.748 13.5102 11.2551 14.1878 11.0249 14.5764C10.7883 14.9758 10.729 15.393 10.729 15.7154V16.6667H13.1215V15.7953C13.2275 15.6435 13.3977 15.4564 13.6376 15.3366C14.7196 14.796 16 13.6303 16 11.7099C16 11.0281 15.8562 10.0629 15.1758 9.2606C14.4707 8.42921 13.3857 8 11.9626 8C10.3751 8 9.29573 8.71368 8.67448 9.72835C8.10765 10.6541 8 11.6992 8 12.3347H10.3925Z"
        fill="#75838A"
      />
      <path
        d="M11.9591 21.3386C12.8222 21.3386 13.5219 20.6389 13.5219 19.7757C13.5219 18.9126 12.8222 18.2129 11.9591 18.2129C11.0959 18.2129 10.3962 18.9126 10.3962 19.7757C10.3962 20.6389 11.0959 21.3386 11.9591 21.3386Z"
        fill="#75838A"
      />
    </svg>
  )
}

function Image(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 0H15L21 6V24H3V0Z"
          fill="#AE76BC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 10H6V19H18V10ZM7.8 16.75H16.2L13.3125 12.25L11.2453 15.5108L9.9 13.25L7.8 16.75Z"
          fill="#3D2244"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 0L21 6H15V0Z"
          fill="#E1CCE6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 6L21 12V6H15Z"
          fill="#7B4389"
        />
      </g>
    </svg>
  )
}

function Drawing(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0H15L21 6V24H3V0Z"
        fill="#79A3EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6L21 12V6H15Z"
        fill="#1952B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0L21 6H15V0Z"
        fill="#BCD1F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8V21H19V13.0556H16V8H5ZM11 11.5V10L14 9.94444V15H17V19H11V16.5H9V19H7V15H11V13H7V10H9V11.5H11Z"
        fill="#133D86"
      />
    </svg>
  )
}

function Zip(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect x="3" width="18" height="24" rx="2" fill="#E3E6E8" />
      <path d="M10 0H12V2H10V0Z" fill="#2F3437" />
      <path d="M10 4H12V6H10V4Z" fill="#2F3437" />
      <path d="M12 2H14V4H12V2Z" fill="#2F3437" />
      <path d="M12 6H14V8H12V6Z" fill="#2F3437" />
      <path d="M10 8H12V10H10V8Z" fill="#2F3437" />
      <path d="M10 12H12V14H10V12Z" fill="#2F3437" />
      <path d="M12 10H14V12H12V10Z" fill="#2F3437" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8673 15C10.3697 15 9.94773 15.3659 9.87736 15.8586L9.16307 20.8586C9.07701 21.461 9.54447 22 10.153 22H13.847C14.4555 22 14.923 21.461 14.837 20.8586L14.1227 15.8586C14.0523 15.3659 13.6304 15 13.1327 15H10.8673ZM11 20V17H13V20H11Z"
        fill="#2F3437"
      />
    </svg>
  )
}

function Email(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0H15L21 6V24H3V0Z"
        fill="#FFDD33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0L21 6H15V0Z"
        fill="#FFF2B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6L21 12V6H15Z"
        fill="#CCAA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10H6V10.9643L12.0073 14.9553L18 10.9643V10ZM18 12.3921L11.9928 16.3409L6 12.2363V19H18V12.3921Z"
        fill="#665500"
      />
    </svg>
  )
}

function Excel(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0H15L21 6V24H3V0Z"
            fill="#2FD926"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 0L21 6H15V0Z"
            fill="#82E87D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 6L21 12V6H15Z"
            fill="#2AC322"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 21V9H18.5V21H5.5ZM12.6842 10.4118H17.1316V12.5294H12.6842V10.4118ZM11.3158 10.4118H6.86842V12.5294H11.3158V10.4118ZM11.3158 13.9412H6.86842V16.0588H11.3158V13.9412ZM11.3158 17.4706H6.86842V19.5882H11.3158V17.4706ZM12.6842 19.5882V17.4706H17.1316V19.5882H12.6842ZM12.6842 16.0588V13.9412H17.1316V16.0588H12.6842Z"
            fill="#13570F"
          />
        </g>
      </g>
    </svg>
  )
}

function Gantt(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0H15L21 6V24H3V0Z"
        fill="#59E052"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0L21 6H15V0Z"
        fill="#82E87D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6L21 12V6H15Z"
        fill="#2AC322"
      />
      <path
        d="M5.66669 8.66666C5.66669 7.93028 6.26364 7.33333 7.00002 7.33333H11C11.7364 7.33333 12.3334 7.93028 12.3334 8.66666C12.3334 9.40304 11.7364 9.99999 11 9.99999H9.66669V12.6667H17C17.7364 12.6667 18.3334 13.2636 18.3334 14C18.3334 14.7364 17.7364 15.3333 17 15.3333H15.6667V18H17C17.7364 18 18.3334 18.5969 18.3334 19.3333C18.3334 20.0697 17.7364 20.6667 17 20.6667H13C12.2636 20.6667 11.6667 20.0697 11.6667 19.3333C11.6667 18.5969 12.2636 18 13 18H14.3334V15.3333H7.00002C6.26364 15.3333 5.66669 14.7364 5.66669 14C5.66669 13.2636 6.26364 12.6667 7.00002 12.6667H8.33335V9.99999H7.00002C6.26364 9.99999 5.66669 9.40304 5.66669 8.66666Z"
        fill="#13570F"
      />
    </svg>
  )
}

function Ini(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0H15L21 6V24H3V0Z"
            fill="#919CA1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 0L21 6H15V0Z"
            fill="#ACB5B9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 6L21 12V6H15Z"
            fill="#5E696E"
          />
        </g>
        <path
          d="M16.1573 13.2666L16.1581 13.2801C16.0799 13.0944 15.9988 12.9034 15.8968 12.7246C15.7949 12.5458 15.6834 12.3865 15.5637 12.2251L15.5749 12.2326L16.2663 10.4817L13.7392 9L12.5966 10.4817L12.6049 10.4839C12.2149 10.4314 11.8169 10.4257 11.417 10.4772L11.4223 10.4742L10.266 9.00436L7.73704 10.4833L8.42897 12.2317L8.43416 12.2286C8.19015 12.5537 7.99609 12.9056 7.84599 13.2739L7.84819 13.2656L6.00186 13.5248L6 16.4854L7.8427 16.7544L7.84189 16.7408C7.92006 16.9266 8.00042 17.104 8.10235 17.2828C8.20429 17.4617 8.3218 17.6314 8.4467 17.7898L8.43551 17.7823L7.8397 19.2879L7.74629 19.5249L10.2674 20.996L11.4181 19.5166L11.3957 19.5016C11.7939 19.5564 12.1918 19.5621 12.5969 19.5075L12.5813 19.5166L13.7384 21L16.257 19.5271C16.257 19.5271 16.2186 19.4234 16.1622 19.288L15.565 17.7788L15.5599 17.7818C15.8039 17.4567 16.0031 17.1018 16.1554 16.7252L16.157 16.7523L17.9974 16.4826L18 13.5355C18 13.5355 17.8913 13.5149 17.7507 13.4989L16.1573 13.2666ZM13.0463 16.8462C12.0441 17.4323 10.7581 17.0833 10.1795 16.0681C9.60086 15.0529 9.94544 13.7504 10.9477 13.1643C11.9499 12.5782 13.2359 12.9272 13.8145 13.9424C14.3932 14.9575 14.0486 16.2601 13.0463 16.8462Z"
          fill="#2F3437"
        />
      </g>
    </svg>
  )
}

function Mov(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0H15L21 6V24H3V0Z"
            fill="#AE76BC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 0L21 6H15V0Z"
            fill="#E1CCE6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 6L21 12V6H15Z"
            fill="#7B4389"
          />
          <path d="M9 19V9L16.5 14.3333L9 19Z" fill="#3D2244" />
        </g>
      </g>
    </svg>
  )
}

function Pdf(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0H15L21 6V24H3V0Z"
            fill="#EB474D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 0L21 6H15V0Z"
            fill="#F7BABC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 6L21 12V6H15Z"
            fill="#B8141A"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 10H19V12H13V10ZM13 14H19V16H13V14ZM19 18H5V20H19V18ZM11 10H5V16H11V10Z"
          fill="#5C0A0D"
        />
      </g>
    </svg>
  )
}

function Powerpoint(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0H15L21 6V24H3V0Z"
            fill="#FF7433"
          />
          <g>
            <path d="M6 11.5H7.5V19H14.5V20.5H6V11.5Z" fill="#662000" />
            <path d="M9 9H17.5V17.5H9V9Z" fill="#662000" />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 0L21 6H15V0Z"
            fill="#FFCBB3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 6L21 12V6H15Z"
            fill="#CC4100"
          />
        </g>
      </g>
    </svg>
  )
}

function Word(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0H15L21 6V24H3V0Z"
            fill="#BCD1F5"
          />
          <g>
            <path d="M6 10H15.5V12H6V10Z" fill="#133D86" />
            <path d="M6 14H18V16H6V14Z" fill="#133D86" />
            <path d="M6 18H16V20H6V18Z" fill="#133D86" />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 0L21 6H15V0Z"
            fill="#D2E0F9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 6L21 12V6H15Z"
            fill="#79A3EC"
          />
        </g>
      </g>
    </svg>
  )
}

function Xml(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0H15L21 6V24H3V0Z"
            fill="#ACB5B9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 0L21 6H15V0Z"
            fill="#D6DADC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 6L21 12V6H15Z"
            fill="#919CA1"
          />
          <g>
            <path d="M6 10L8 10V12H6V10Z" fill="#2F3437" />
            <path d="M10 10L18 10V12H10V10Z" fill="#2F3437" />
            <path d="M6 14H8V16H6V14Z" fill="#2F3437" />
            <path d="M10 14H18V16H10V14Z" fill="#2F3437" />
            <path d="M6 18H8V20H6V18Z" fill="#2F3437" />
            <path d="M10 18H18V20H10V18Z" fill="#2F3437" />
          </g>
        </g>
      </g>
    </svg>
  )
}

function ThreeD(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0H15L21 6V24H3V0Z"
        fill="#79A3EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6L21 12V6H15Z"
        fill="#1952B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0L21 6H15V0Z"
        fill="#BCD1F5"
      />
      <path
        d="M6.375 10.8889L12 8L17.625 10.8889L12 14.1389L6.375 10.8889Z"
        fill="#133D86"
      />
      <path
        d="M6 11.6111V18.1111L11.6 21L11.625 14.8611L6 11.6111Z"
        fill="#133D86"
      />
      <path
        d="M12.4 21L18 18.1111V11.6111L12.375 14.8611L12.4 21Z"
        fill="#133D86"
      />
    </svg>
  )
}

function Folder(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <g>
          <rect y="7" width="24" height="15" fill="#BCD1F5" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 2H6L9 5H22V7H0V2Z"
            fill="#4C85E6"
          />
        </g>
      </g>
    </svg>
  )
}

function FolderPeople(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect y="7" width="24" height="15" fill="#BCD1F5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2H6L9 5H22V7H0V2Z"
        fill="#4C85E6"
      />
      <path
        d="M9.55 14.7639C7.45 14.7639 5 15.4464 5 16.8056V18.1667H14.1V16.8056C14.1 15.4464 11.65 14.7639 9.55 14.7639ZM14.8 14.7639C14.6155 14.7639 13.6227 14.7464 13.4 14.7639C14.1382 15.2539 15.15 15.7847 15.15 16.8056V18.1667H19V16.8056C19 15.4464 17.25 14.7639 14.8 14.7639Z"
        fill="#2066DF"
      />
      <path
        d="M11.475 11.8715C11.475 12.9051 10.6131 13.7431 9.55 13.7431C8.48685 13.7431 7.625 12.9051 7.625 11.8715C7.625 10.8379 8.48685 10 9.55 10C10.6131 10 11.475 10.8379 11.475 11.8715Z"
        fill="#2066DF"
      />
      <path
        d="M16.725 11.8715C16.725 12.9051 15.8631 13.7431 14.8 13.7431C13.7369 13.7431 12.875 12.9051 12.875 11.8715C12.875 10.8379 13.7369 10 14.8 10C15.8631 10 16.725 10.8379 16.725 11.8715Z"
        fill="#2066DF"
      />
    </svg>
  )
}

function FolderLock(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect y="7" width="24" height="15" fill="#BCD1F5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2H6L9 5H22V7H0V2Z"
        fill="#4C85E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13H9.09091V11.8571C9.09091 10.2792 10.3934 9 12 9C13.6066 9 14.9091 10.2792 14.9091 11.8571V13H16V19H8V13ZM13.4545 11.8571V13H10.5455V11.8571C10.5455 11.0682 11.1967 10.4286 12 10.4286C12.8033 10.4286 13.4545 11.0682 13.4545 11.8571ZM12 14.7857C11.3975 14.7857 10.9091 15.2654 10.9091 15.8571C10.9091 16.4489 11.3975 16.9286 12 16.9286C12.6025 16.9286 13.0909 16.4489 13.0909 15.8571C13.0909 15.2654 12.6025 14.7857 12 14.7857Z"
        fill="#2066DF"
      />
    </svg>
  )
}

function BlankFile(props: DocumentIconProps) {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 0H15L21 6V24H3V0Z"
        fill="#D6DADC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 0L21 6H15V0Z"
        fill="#EEF0F1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 6L21 12V6H15Z"
        fill="#ACB5B9"
      />
    </svg>
  )
}

const documentIcons: {
  [k in ThumbnailDocumentIconVariant]: React.FC<DocumentIconProps>
} = {
  image: Image,
  unknown: Unknown,
  zip: Zip,
  drawing: Drawing,
  email: Email,
  excel: Excel,
  gantt: Gantt,
  ini: Ini,
  mov: Mov,
  pdf: Pdf,
  powerpoint: Powerpoint,
  word: Word,
  xml: Xml,
  ['3d']: ThreeD,
  folder: Folder,
  folderPeople: FolderPeople,
  folderLock: FolderLock,
  file: BlankFile,
}

export function DocumentIcon({
  className,
  focusable = false,
  height = 64,
  variant,
  width = 48,
}: DocumentIconProps) {
  const Icon = documentIcons[variant] || documentIcons.unknown

  return (
    <Icon
      className={className}
      focusable={focusable}
      height={height}
      variant={variant}
      width={width}
    />
  )
}
