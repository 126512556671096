import styled, { css } from 'styled-components'
import { Button } from '../Button/Button'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

const cellSize = 36 // TODO one-off size

export const StyledContainer = styled.div`
  ${getTypographyIntent('small')};

  display: inline-flex;
  flex-direction: column;
  padding: ${spacing.lg}px ${spacing.md}px ${spacing.md}px;
`

export const StyledPaginator = styled(Button)`
  flex: 0 0 auto;
`

export const StyledSelect = styled.div`
  display: inline-flex;
  flex: 1 1 0;
  margin-left: ${spacing.sm}px;

  // TODO hack to ensure labels don't get cut off in narrow selects
  > div {
    font-size: 12px;
  }
`

export const StyledNavigation = styled.div`
  display: inline-flex;
  margin-bottom: ${spacing.sm}px;

  ${StyledPaginator} + ${StyledSelect}, ${StyledSelect} + ${StyledPaginator} {
    margin-left: ${spacing.sm}px;
  }
`

export const StyledCell = styled.div`
  align-items: center;
  display: inline-flex;
  height: ${cellSize}px;
  justify-content: center;
  width: ${cellSize}px;
`

export const StyledDayHeaderCell = styled(StyledCell)`
  cursor: default;
  font-weight: ${typographyWeights.semibold};
`

export const StyledDayCell = styled(StyledCell)<{
  $disabled: boolean
  $selected: boolean
  $isCurrentMonth: boolean
  $isToday: boolean
  $isRangeStart: boolean
  $isRangeEnd: boolean
}>`
  ${({
    $disabled = false,
    $selected = false,
    $isCurrentMonth = true,
    $isToday = false,
    $isRangeStart = false,
    $isRangeEnd = false,
  }) => {
    if ($disabled) {
      return css`
        background-color: ${colors.gray94};
        color: ${$isCurrentMonth ? colors.gray45 : colors.gray70};
        font-weight: ${$isToday && typographyWeights.bold};
        pointer-events: none;
      `
    } else if ($selected) {
      return css`
        background-color: ${$isRangeStart || $isRangeEnd
          ? colors.blue40
          : colors.blue50};
        border-top-left-radius: ${$isRangeStart ? borderRadius.md : 0}px;
        border-bottom-left-radius: ${$isRangeStart ? borderRadius.md : 0}px;
        border-top-right-radius: ${$isRangeEnd ? borderRadius.md : 0}px;
        border-bottom-right-radius: ${$isRangeEnd ? borderRadius.md : 0}px;
        color: ${colors.white};
        font-weight: ${typographyWeights.semibold};
        cursor: pointer;
      `
    }

    return css`
      color: ${!$isCurrentMonth && colors.gray45};
      cursor: pointer;
      font-weight: ${$isToday && typographyWeights.bold};

      &:hover {
        background-color: ${colors.blue96};
        border-radius: ${borderRadius.md}px;
      }
    `
  }}
`

export const StyledWeekRow = styled.div`
  display: inline-flex;
  padding-bottom: 2px; // TODO one-off size
  user-select: none;

  &:last-child {
    padding-bottom: 0;
  }

  ${StyledCell} {
    &:first-child {
      border-top-left-radius: ${borderRadius.md}px;
      border-bottom-left-radius: ${borderRadius.md}px;
    }

    &:last-child {
      border-top-right-radius: ${borderRadius.md}px;
      border-bottom-right-radius: ${borderRadius.md}px;
    }
  }
`
