import React from 'react'
import type { BeforeCallback } from './DelayedCallback'
import { useDelayedCallback } from './DelayedCallback'
import type { TimerApi } from './Timer'
import { useTimer } from './Timer'

/* TYPES */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export type Callback = () => void

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface DelayedToggleConfig {
  /**
   * @since 10.19.0
   */
  beforeDisable?: BeforeCallback
  /**
   * @since 10.19.0
   */
  beforeEnable?: BeforeCallback
  /**
   * @since 10.19.0
   */
  disableDelay?: number
  /**
   * @since 10.19.0
   */
  enableDelay?: number
  /**
   * @since 10.19.0
   */
  isEnabled?: boolean
  /**
   * @since 10.19.0
   */
  onDisable: Callback
  /**
   * @since 10.19.0
   */
  onEnable: Callback
  /**
   * @since 10.19.0
   */
  timer?: TimerApi
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface DelayedToggleProps extends DelayedToggleConfig {
  /**
   * @since 10.19.0
   */
  children: React.ReactNode
}

interface DelayedToggleRenderProps extends DelayedToggleConfig {
  /**
   * @since 10.19.0
   */
  children: React.ReactElement | ((props: Api) => React.ReactElement)
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export interface Api {
  /**
   * @since 10.19.0
   */
  disable: (event: any) => any
  /**
   * @since 10.19.0
   */
  enable: (event: any) => any
  /**
   * @since 10.19.0
   */
  toggle: (event: any) => any
}

/* HOOK */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useDelayedToggle({
  beforeDisable = () => true,
  beforeEnable = () => true,
  disableDelay = 0,
  enableDelay = 0,
  isEnabled = false,
  onDisable,
  onEnable,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  timer = useTimer({}),
}: DelayedToggleConfig): Api {
  const handleEnable = React.useCallback(() => {
    timer.reset()
    return true
  }, [timer])

  const handleCancel = React.useCallback(() => {
    timer.cancel()
    return false
  }, [timer])

  const enable = useDelayedCallback({
    after: onEnable,
    before: isEnabled ? handleEnable : beforeEnable,
    delay: enableDelay,
    timer,
  })

  const disable = useDelayedCallback({
    after: onDisable,
    before: isEnabled ? beforeDisable : handleCancel,
    delay: disableDelay,
    timer,
  })

  return {
    disable,
    enable,
    toggle: isEnabled ? disable : enable,
  }
}

/* RENDER PROP */

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export const DelayedToggle = React.forwardRef<
  HTMLElement,
  DelayedToggleRenderProps
>(function DelayedToggle(props, ref) {
  const { children, ...config } = props

  const toggle = useDelayedToggle(config)

  const element =
    typeof children === 'function'
      ? (children as (props: Api) => React.ReactElement)(toggle)
      : (children as React.ReactElement)

  return element && React.cloneElement(element, { ref })
})

/* CONTEXT */

const noop: (method: string) => (event?: any) => any = (method) => () =>
  console.warn(
    `Could not call \`${method}\`, please wrap your component in an \`<DelayedToggleProvider>\``
  )

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export const initDelayedToggle: Api = {
  disable: noop('disable'),
  enable: noop('enable'),
  toggle: noop('toggle'),
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export const DelayedToggleContext = React.createContext<Api>(initDelayedToggle)

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function DelayedToggleProvider({
  children,
  ...props
}: DelayedToggleProps) {
  return (
    <DelayedToggleContext.Provider value={useDelayedToggle(props)}>
      {children}
    </DelayedToggleContext.Provider>
  )
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useDelayedToggleContext() {
  return React.useContext(DelayedToggleContext)
}
