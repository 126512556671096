import React from 'react'
import { StyledImageThumbnailPreview } from './Thumbnail.styles'
import type { FilePreviewProps } from './Thumbnail.types'

export function ThumbnailPreview({ file }: FilePreviewProps) {
  const [fileUrl, setFileUrl] = React.useState('')

  React.useEffect(() => {
    if (!file) {
      setFileUrl('')
      return
    }

    setFileUrl((URL.createObjectURL?.(file) ?? '').toString())
  }, [file])

  React.useEffect(() => {
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL?.(fileUrl)
      }
    }
  }, [fileUrl])

  return fileUrl ? <StyledImageThumbnailPreview $bgImage={fileUrl} /> : null
}
