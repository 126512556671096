import { FlexList, useClickOutside } from '@procore/core-react'
import { Link } from 'gatsby'
import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import githubLogo from '../images/github-logo.png'
import coreLogo from '../images/logo.png'
import { useAuth0 } from '../utils/auth'
import { colors, layout } from '../utils/styles'
import GlobalSearch from './GlobalSearch'
import VersionDropdown from './VersionDropdown'

function getProps() {
  return (
    window.location.pathname +
    '\n' +
    Array.from(
      document.querySelectorAll(
        '[class^="PropsTablestyles__StyledPropsContainer"]'
      )
    )
      .map((div, i) => {
        // @ts-ignore
        const name = div.previousElementSibling.innerText
        const props = Array.from(
          div.querySelectorAll('tbody tr td:first-child')
        )
          // @ts-ignore
          .map((td) => td.innerText)
          .sort((a, b) => (a > b ? 1 : -1))
          .join()
        return `Table #${i + 1} - ${props.length} - ${name} - ${props}`
      })
      .sort((a, b) => (a > b ? 1 : -1))
      .join('.\n')
  )
}
function GetPropsScript() {
  React.useLayoutEffect(() => {
    if (!(window as any).getProps) {
      ;(window as any).getProps = getProps
    }
  })

  return null
}

const HeaderView = styled.header<{ fillBackground?: boolean }>`
  align-items: center;
  background: ${(props) => (props.fillBackground ? colors.f1f3f3 : '')};
  border-bottom: 1px solid ${colors.b3b3b3};
  display: flex;
  height: ${layout.headerHeight}px;
  justify-content: center;
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;
  padding: 0 ${layout.pagePadding}px;

  justify-content: space-between;
`

const LogoView = styled.img`
  height: 32px;
  line-height: 0;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const LinksView = styled(FlexList)`
  width: 375px;

  a {
    color: ${colors._202020};
    font-size: 14px;
    text-decoration: none;

    animation: ${fadeIn} 1s linear;

    &:hover {
      color: ${colors._006ddf};
    }
  }
`

const AuthLinkView = styled.div`
  width: 75px;
`

export const Header: React.FC<{ fillBackground?: boolean }> = function Header({
  fillBackground,
}) {
  const { isAuthenticated, login, logout, loading } = useAuth0()
  const globalSearchRef = React.useRef(null)
  const [globalSearchHitsDisplayed, setGlobalSearchHitsDisplayed] =
    React.useState(false)

  // Justification for try/catch:
  // useClickOutside references window
  // gatsby breaks on references to window
  // the normal solution is to perform a check of the window object and conditionally invoke it
  // but because this is a hook, it's not possible to conditionally invoke it
  try {
    useClickOutside({
      onClickOutside: () => {
        setGlobalSearchHitsDisplayed(false)
      },
      refs: [globalSearchRef],
    })
  } catch {
    console.log('server side')
  }

  return (
    <HeaderView fillBackground={fillBackground}>
      <GetPropsScript />
      <FlexList size="md">
        <Link to="/">
          <LogoView src={coreLogo} alt="Core Design System" />
        </Link>
        <VersionDropdown />
      </FlexList>
      <LinksView
        size="md"
        alignItems="center"
        justifyContent="space-between"
        basis="0"
      >
        <GlobalSearch
          ref={globalSearchRef}
          hitsDisplayed={globalSearchHitsDisplayed}
          setHitsDisplayed={setGlobalSearchHitsDisplayed}
        />
        {/* <a href="#">Resources</a>
          <a href="#">What's New</a> */}
        {!loading && isAuthenticated ? (
          <a href="https://github.com/procore/core" target="_blank">
            <LogoView src={githubLogo} alt="Core GitHub" />
          </a>
        ) : null}
        {loading ? null : isAuthenticated ? (
          <AuthLinkView>
            <a
              href="#"
              onClick={() => {
                logout()
              }}
            >
              Log Out
            </a>
          </AuthLinkView>
        ) : (
          <AuthLinkView>
            <a
              href="#"
              onClick={() => {
                login()
              }}
            >
              Log In
            </a>
          </AuthLinkView>
        )}
      </LinksView>
    </HeaderView>
  )
}
