import { Help } from '@procore/core-icons'
import React from 'react'
import styled from 'styled-components'
import type { OverlayTriggerProps } from '../OverlayTrigger'
import type { TooltipProps } from '../Tooltip'
import { Tooltip } from '../Tooltip'
import { useI18nContext } from '../_hooks/I18n'
import { colors } from '../_styles/colors'
import { getGapOutlineFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const CheckboxTooltip: React.FC<
  Omit<OverlayTriggerProps & TooltipProps, 'children'>
> = (props) => {
  const I18n = useI18nContext()

  return (
    <Tooltip role="tooltip" trigger={['hover', 'focus']} {...props}>
      <StyledCheckboxTooltipIcon
        aria-label={I18n.t('core.form.field.tooltipHelp')}
        aria-hidden={false}
        size="sm"
        tabIndex={0}
      />
    </Tooltip>
  )
}

const StyledCheckboxTooltipIcon = styled(Help)`
  margin-left: ${spacing.sm}px;
  color: ${colors.gray45};
  vertical-align: middle;

  &:focus-visible {
    border-radius: 50%;
    ${getGapOutlineFocus}
    // Icon does not touch viewBox borders. Already has 1px gap
    outline-offset: 0;
  }
`
