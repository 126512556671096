import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Highlight, { defaultProps, Language } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/github'

import { CodeLive } from './CodeLive'

const isBrowser = typeof window !== 'undefined'

interface Scope {
  [k: string]: any
}

interface CodeProps {
  children: string
  className: string
  inert?: boolean
  live?: boolean
}

export function Code(scope: Scope) {
  return function Code({ children, className = '', inert, live }: CodeProps) {
    const assets = useStaticQuery(
      graphql`
        query {
          allFile(filter: { relativeDirectory: { eq: "" } }) {
            nodes {
              name
              publicURL
            }
          }
        }
      `
    ).allFile.nodes.reduce(
      (a: any, c: any) => ({ ...a, [c.name]: c.publicURL }),
      {}
    )

    if (!isBrowser) return <div></div>

    const language = className.replace(/language-/, '')

    if (!Boolean(live) && !Boolean(inert)) {
      return (
        <Highlight
          {...defaultProps}
          code={children}
          language={language as Language}
          theme={theme}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => {
            // filter out last line if it's empty
            const lines = tokens.filter(
              (line: any[], i: number) =>
                i < tokens.length - 1 ||
                line
                  .map((t) => t.content)
                  .join('')
                  .trim() !== ''
            )

            return (
              <pre className={className} style={style}>
                {lines.map((line, i) => (
                  <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                ))}
              </pre>
            )
          }}
        </Highlight>
      )
    }

    return (
      <CodeLive
        editable={!Boolean(inert)}
        language={language as Language}
        scope={{ ...scope, assets }}
      >
        {children}
      </CodeLive>
    )
  }
}
