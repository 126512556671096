import styled, { css } from 'styled-components'
import { Box } from '../Box/Box'
import { StyledButton } from '../Button/Button.styles'
import { StyledCheckboxLabel } from '../Checkbox/Checkbox.styles'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

const optionMinHeight = 32

// Do not merge with StyledMenu, needed for max-height working in IE
export const StyledWrapper = styled.div`
  border-radius: inherit;
  display: flex;
  max-height: inherit;
  min-width: inherit;
  outline: none;
  width: inherit;
`

export const StyledMenu = styled.div`
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  outline: 0;
  width: 100%;
`

export const StyledOptions = styled.div<{ $scrollable?: boolean }>`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: ${optionMinHeight}px;
  ${({ $scrollable = true }) => $scrollable && `overflow-y: auto`};
  padding: ${spacing.sm}px 0;
  position: relative;

  &:focus-visible {
    ${getFocus()}
    margin: -1px;
  }
`

export const StyledGrip = styled.div`
  padding: 0 2px;
  height: 24px;
  visibility: hidden;
`

export const StyledHeader = styled.div``

export const StyledSearch = styled.div`
  padding: ${spacing.lg}px ${spacing.lg}px ${spacing.sm}px;

  ${StyledButton} > * {
    pointer-events: none;
  }
`

export const StyledGroup = styled.div<{ $clickable?: boolean }>`
  ${getTypographyIntent('body')}

  color: ${colors.gray15};
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
  font-weight: ${typographyWeights.semibold};
  padding: ${spacing.xs}px ${spacing.lg}px;

  &:not(:first-child) {
    padding-top: ${spacing.md + spacing.xs}px;
  }
`

export const StyledItem = styled.div<{
  $disabled?: boolean
  $selected?: boolean
  $highlighted?: boolean
  $isDraggable?: boolean
  $isDragging?: boolean
}>`
  ${getTypographyIntent('body')};

  ${({
    // @ts-expect-error
    as,
  }) =>
    as === 'a' &&
    css`
      display: block;
      text-decoration: none;
    `}

  cursor: pointer;
  list-style-type: none;
  padding: ${spacing.xs}px ${spacing.lg}px;

  a {
    // We decided to use a negative margin trick here to allow us to continue
    // to use listNavigation without having to rewrite a bunch of code in js
    color: ${colors.gray15};
    display: block;
    margin: -${spacing.xs}px -${spacing.lg}px;
    padding: ${spacing.xs}px ${spacing.lg}px;
    text-decoration: none;

    &:hover {
      color: ${colors.gray15};
      text-decoration: none;
    }
  }

  &[data-highlighted='true'] {
    background-color: ${colors.gray96};
  }

  ${({ $highlighted }) => $highlighted && `background-color: ${colors.gray96};`}

  &:hover,
  &:active {
    ${StyledGrip} {
      visibility: visible;
    }
  }

  ${StyledCheckboxLabel} {
    color: inherit;
    cursor: pointer;
  }

  ${({ $disabled, $selected }) => {
    if ($disabled) {
      return css`
        &,
        ${StyledCheckboxLabel} {
          color: ${colors.gray85};
          cursor: default;
        }
      `
    } else if ($selected) {
      return css`
        color: ${colors.blue50};
        font-weight: 700;

        a,
        a:hover {
          color: ${colors.blue50};
          text-decoration: none;
        }

        &[data-highlighted='true'] {
          background-color: ${colors.gray96};
        }
      `
    }

    return css`
      color: ${colors.gray15};

      &[data-highlighted='true'] {
        background-color: ${colors.gray96};
      }
    `
  }}

  ${({ $isDraggable }) => {
    if ($isDraggable) {
      return css`
        display: flex;
        align-items: center;

        ${StyledCheckboxLabel} {
          cursor: grab;

          // The unchecked and checked icons
          &:before,
          &:after {
            cursor: pointer;
          }
        }

        &&& {
          padding-left: 0;
        }
      `
    }
  }}

  ${({ $isDragging }) => {
    if ($isDragging) {
      return css`
        background-color: ${colors.gray96};

        ${StyledGrip} {
          visibility: visible;
        }
      `
    }
  }}
`

export const StyledDroppable = styled.div`
  ${StyledGroup}, ${StyledItem} {
    padding-left: 28px;
  }
`

export const StyledFooter = styled(Box)`
  border-top: 1px solid ${colors.gray85};
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`
