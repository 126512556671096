import { useCallback, useMemo, useState } from 'react'

// eslint-disable-next-line no-useless-escape
const periodDashAndWhitespaceRegex = /[\.\-\s]/g

function matchString(value: string, searchQuery: string) {
  const lowerCasedKeyValue = (value && value.toLowerCase()) || ''

  const valueWithoutSeparators = lowerCasedKeyValue.replace(
    periodDashAndWhitespaceRegex,
    ''
  )

  return valueWithoutSeparators.includes(searchQuery)
}

function matchKeyInObject<T extends Record<string, any>, P extends keyof T>(
  item: T,
  key: P,
  searchQuery: string
): boolean {
  if (typeof item[key] === 'string') {
    return matchString(item[key] as string, searchQuery)
  }

  if (typeof item[key] === 'number') {
    return matchString(`${item[key]}`, searchQuery)
  }

  return false
}

function filterCondition<T extends Record<string, any>, P extends keyof T>(
  item: T,
  searchTerm: string = '',
  keysToMatch: P[] = Object.keys(item) as P[]
) {
  const lowerCasedSearchTerm = searchTerm.trim().toLowerCase()
  const sanitizedSearchQuery = lowerCasedSearchTerm.replace(
    periodDashAndWhitespaceRegex,
    ''
  )

  return keysToMatch.some((key) =>
    matchKeyInObject(item, key, sanitizedSearchQuery)
  )
}

interface SearchConfig<T> {
  items: T[]
  keysToSearch?: string[]
}

interface SearchApi<T> {
  searchTerm: string
  clearSearch: () => void
  filteredItems: T[]
  setSearch: (value: string) => void
}

export function useSearch<T extends Record<string, any>>(
  config: SearchConfig<T>
): SearchApi<T> {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredItems = useMemo(() => {
    if (searchTerm === '') {
      return config.items
    }

    return config.items.filter((item) =>
      filterCondition(item, searchTerm, config.keysToSearch)
    )
  }, [config, searchTerm])

  const setSearch = useCallback(
    (value: string) => {
      setSearchTerm(value)
    },
    [setSearchTerm]
  )

  const clearSearch = useCallback(() => {
    setSearchTerm('')
  }, [setSearchTerm])

  return {
    clearSearch,
    filteredItems,
    setSearch,
    searchTerm,
  }
}
