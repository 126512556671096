import styled, { css } from 'styled-components'
import { Avatar } from '../Avatar/Avatar'
import { Typography } from '../Typography/Typography'
import { typographyWeights } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getLineClamp } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const ContactItemAvatarWrapper = styled.div`
  display: inline-flex;
  align-self: flex-start;
`

export const ContactItemAvatar = styled(Avatar)`
  cursor: inherit;
  font-weight: ${typographyWeights.bold};
`

export const ContactItemTextWrapper = styled.div`
  overflow: hidden;
  margin-left: ${spacing.sm}px;
`

export const ContactItemText = styled(Typography)`
  display: flex;
  ${getLineClamp()}
`

export const ContactItemWrapper = styled.div<{
  $selected: boolean
  $disabled: boolean
  $clickable: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacing.sm}px ${spacing.lg}px ${spacing.sm}px ${spacing.sm}px;

  ${({ $disabled, $clickable }) => {
    if ($clickable) {
      return $disabled
        ? css`
            cursor: not-allowed;
          `
        : css`
            cursor: pointer;

            &:hover,
            &.hovered {
              background-color: ${colors.gray96};
            }
          `
    }
  }}

  ${({ $selected }) =>
    $selected &&
    css`
      ${ContactItemText} {
        color: ${colors.blue40};
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      ${ContactItemText} {
        color: ${colors.gray70};
      }
    `}
`
