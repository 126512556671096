import { Clear } from '@procore/core-icons'
import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { spacing } from '../_styles/spacing'
import { PillColorPalets } from './Pill.colorPalette'
import type { PillColor } from './Pill.types'

const pillButtonSize = 16

export const StyledPill = styled.span<{ $color: PillColor }>`
  ${getTypographyIntent('small')}
  display: inline-block;
  padding: 1px ${spacing.sm}px;
  border: 1px solid;
  border-radius: 10px;
  font-weight: 600;

  & + & {
    margin-left: ${spacing.xs}px;
  }

  ${({ $color }) => {
    return css`
      background-color: ${PillColorPalets[$color].backgroundColor};
      border-color: ${PillColorPalets[$color].borderColor};
      color: ${PillColorPalets[$color].color};
    `
  }}
`
export const StyledRemove = styled(Clear)<{
  $color: PillColor
}>`
  border-radius: 100%;
  cursor: pointer;
  flex: 0 0 auto;
  height: ${pillButtonSize}px;
  /* if pillButtonSize is 16 => 8px is the radios - 2px padding = 6px */
  margin: 0 -${pillButtonSize / 2 - spacing.xxs}px 0 ${spacing.xxs}px;
  width: ${pillButtonSize}px;
  vertical-align: top;

  ${({ $color }) => {
    return css`
      &:hover {
        background: ${PillColorPalets[$color].color};
        color: ${PillColorPalets[$color].backgroundColor};
      }
    `
  }}
`
