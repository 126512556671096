import styled, { css } from 'styled-components'
import { Pill } from '../Pill/Pill'
import { colors } from '../_styles/colors'
import { getEllipsis } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

const checkedIconPlaceholderWidth = 32

export const PillSelectOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PillSelectCheckedContainer = styled.div`
  align-items: center;
  color: ${colors.blue50};
  display: inline-flex;
  padding-left: ${spacing.sm}px;
  width: ${checkedIconPlaceholderWidth}px;
`

export const PillSelectLabel = styled(Pill)`
  ${getEllipsis}
`

export const PillSelectLabelWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  max-width: 100%;
  pointer-events: auto;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`
