const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac tortor dignissim convallis aenean et. Facilisis volutpat est velit egestas dui id. In nulla posuere sollicitudin aliquam ultrices. Lorem mollis aliquam ut porttitor. Convallis aenean et tortor at risus viverra adipiscing at. Euismod nisi porta lorem mollis aliquam ut porttitor. Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Viverra tellus in hac habitasse platea dictumst. Scelerisque viverra lawn gnome in aliquam sem fringilla ut morbi tincidunt. Ullamcorper morbi tincidunt ornare massa eget. Gravida cum sociis natoque penatibus. Leo vel fringilla est ullamcorper eget nulla. Vitae proin sagittis nisl rhoncus mattis rhoncus urna. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien et. Id aliquet lectus proin nibh troll condimentum id venenatis.'

function Of(lengthOrLengthProp: number | { length: number }) {
  return recursiveGrow(
    typeof lengthOrLengthProp === 'number'
      ? lengthOrLengthProp
      : lengthOrLengthProp.length
  )
}

const recursiveGrow = (length = 1, out = LOREM_IPSUM): string => {
  if (out.length < length) {
    return recursiveGrow(length, out + LOREM_IPSUM)
  }
  if (out.length >= length) {
    return out.slice(0, length - 1) + '.'
  }
  return 'Lorem ipsum grow failed.'
}

export default {
  Of,
  Short: () => 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  Medium: () =>
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac tortor dignissim convallis aenean et.',
  Long: () =>
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac tortor dignissim convallis aenean et. Facilisis volutpat est velit egestas dui id. In nulla posuere sollicitudin aliquam ultrices. Lorem mollis aliquam ut porttitor. Convallis aenean et tortor at risus viverra adipiscing at. Euismod nisi porta lorem mollis aliquam ut porttitor. Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Viverra tellus in hac habitasse platea dictumst. Scelerisque viverra mauris in aliquam sem fringilla ut morbi tincidunt. Ullamcorper morbi tincidunt ornare massa eget. Gravida cum sociis natoque penatibus. Leo vel fringilla est ullamcorper eget nulla. Vitae proin sagittis nisl rhoncus mattis rhoncus urna. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien et. Id aliquet lectus proin nibh nisl condimentum id venenatis.',
}
