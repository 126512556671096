import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../../Typography/Typography.styles'
import { colors } from '../../_styles/colors'
import { getEllipsis, getFocus } from '../../_styles/mixins'
import { spacing } from '../../_styles/spacing'

const sourceItemHeight = 48
const sourceItemPadding = spacing.xl

const focused = css`
  ${getFocus()}
  // compensate for border shift
  padding: 0 ${sourceItemPadding - 1}px 0 ${sourceItemPadding - 1}px;
`

export const StyledSourceItem = styled.div<{
  selected: boolean
  error: boolean
  disabled: boolean
  focused: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${sourceItemPadding}px 0 ${sourceItemPadding}px;
  color: ${colors.gray45};
  ${getTypographyIntent('body')}
  cursor: pointer;
  height: ${sourceItemHeight}px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 4px;
    height: 100%;
    background-color: ${colors.blue45};
    transform: scaleX(0);
    transform-origin: center left;
    transition: transform 200ms ease-out;
    // prevent a one-pixel gap between focused and selected source items due to the box-shadow
    z-index: 1;
  }

  ${({ selected }) =>
    selected &&
    `
    font-weight: 700;
    background-color: ${colors.blue96};
    color: ${colors.blue45};

    ${StyledIcon} {
      color: ${colors.blue50};
    }

    &:before {
      transform: scaleX(1);
    }
  `}

  ${({ error }) =>
    error &&
    css`
      color: ${colors.red50};

      ${StyledIcon} {
        color: ${colors.red50};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    color: ${colors.gray70};
    ${StyledIcon} {
      color: ${colors.gray85};
    }
  `}

  ${({ selected, disabled, error }) =>
    !selected &&
    css`
      &:hover {
        transition: background-color 150ms ease-out 150ms, color 150ms ease-out;

        ${!disabled &&
        css`
          background-color: ${colors.gray96};
        `}

        ${!disabled &&
        !error &&
        css`
          color: ${colors.gray15};
        `}
      }
    `}
  

  ${(props) => props.focused && focused}

  &:focus {
    ${focused}
  }
`

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
`

export const StyledLabel = styled.div`
  ${getEllipsis()}
`
