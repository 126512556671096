import React from 'react'
import {
  StyledRadioButton,
  StyledRadioButtonInput,
  StyledRadioButtonLabel,
} from './RadioButton.styles'
import type { RadioButtonProps } from './RadioButton.types'

/**

 We use radio buttons to allow users to select a single, exclusive option from
 a list of multiple options.

 To present options in a dropdown, use a single select.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-radiobutton--demo)

 @see [Design Guidelines](https://design.procore.com/radio-button)

 */
export const RadioButton = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & RadioButtonProps
>(function RadioButton(
  { children, className, error = false, style, ...props },
  ref
) {
  return (
    <StyledRadioButton
      className={className}
      style={style}
      $children={children}
      $error={error}
    >
      <StyledRadioButtonInput type="radio" ref={ref} {...props} />
      <StyledRadioButtonLabel>{children}</StyledRadioButtonLabel>
    </StyledRadioButton>
  )
})

RadioButton.displayName = 'RadioButton'
