import styled, { css } from 'styled-components'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

export const StyledListPageMain = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-width: 0;
  padding: ${spacing.lg}px ${spacing.xl}px;
  width: 100%;
`

export const StyledListPageBanner = styled.div`
  flex: 0 0 auto;
`

export const StyledListPageToolHeader = styled.div`
  flex: 0 0 auto;
  padding-bottom: ${spacing.lg}px;
`

interface StyledListPageControlProps {
  $alignRight: boolean
  $block: boolean
  $search: boolean
}

export const StyledListPageControl = styled.div<StyledListPageControlProps>`
  flex: ${({ $block }) => ($block ? '1 0 auto' : '0 0 auto')};

  ${({ $alignRight }) => {
    if ($alignRight) {
      return css`
        margin-left: auto;
      `
    }
  }}

  ${({ $search }) => {
    if ($search) {
      return css`
        width: 240px;
      `
    }
  }}
`

export const StyledListPageContentControls = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: ${spacing.md}px 0;

  ${StyledListPageControl} + ${StyledListPageControl} {
    margin-left: ${spacing.lg}px;
  }
`

export const StyledListPageBody = styled.div`
  display: flex;
  flex: 1 0 auto;
  padding-top: ${spacing.md}px;
`

export const StyledListPageBodyContent = styled.div`
  flex: 1 1 auto;
`

export const StyledListPage = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  ${StyledListPageBanner} + ${StyledListPageToolHeader} {
    padding-top: ${spacing.lg}px;
  }

  ${StyledListPageContentControls} + ${StyledListPageContentControls} {
    border-top-color: ${colors.gray85};
    border-top-style: solid;
    border-top-width: 1px;
  }

  ${StyledListPageToolHeader} + ${StyledListPageContentControls},
  ${StyledListPageToolHeader} + ${StyledListPageBody} {
    border-top-color: ${colors.gray85};
    border-top-style: solid;
    border-top-width: 1px;
  }

  ${StyledListPageContentControls} + ${StyledListPageBody} {
    padding-top: 0;
  }
`
