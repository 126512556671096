import React from 'react'
import styled, { css } from 'styled-components'
import { colors, spacing } from '@procore/core-react'

const spaceToColor = {
  xxs: colors.yellow15,
  xs: colors.magenta70,
  sm: colors.green70,
  md: colors.red70,
  lg: colors.blue70,
  xl: colors.yellow70,
  xxl: colors.purple70,
}

const StyledSpacingTableContainer = styled.div`
  table {
    border-spacing: 0px;
    margin: 0px;
  }
  tr {
    border: 0px;
  }
  td {
    padding: ${spacing.md}px;
    vertical-align: middle;
  }
  th {
    font-weight: bold;
  }
`

const StyledSpacingBox = styled.div<{ $name: string; $number: number }>`
  width: 150px;
  ${({ $name, $number }) => css`
    background-color: ${spaceToColor[$name]};
    height: ${$number}px;
  `};
`

export function SpacingTable() {
  return (
    <StyledSpacingTableContainer>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Number</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(spacing).map(([name, number]) => {
            return (
              <tr>
                <td>{name}</td>
                <td>{number}</td>
                <td>
                  <StyledSpacingBox $name={name} $number={number} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </StyledSpacingTableContainer>
  )
}
