import {
  arrow as floatingArrow,
  flip as floatingFlip,
  limitShift,
  offset as floatingOffset,
  shift as floatingShift,
  size as floatingSize,
} from '@floating-ui/react-dom'
import { FocusScope } from '@react-aria/focus'
import { useId } from '@react-aria/utils'
import React from 'react'
import { Arrow } from '../Overlay/OverlayArrow'
import { arrowSize } from '../Overlay/OverlayArrow.styles'
import type { OverlayMiddleware } from '../Overlay/useOverlay'
import { useOverlay } from '../Overlay/useOverlay'
import { Portal } from '../Portal'
import type { ClickOutsideConfig } from '../_hooks/ClickOutside'
import { useClickOutside } from '../_hooks/ClickOutside'
import {
  DelayedToggleContext,
  useDelayedToggle,
  useDelayedToggleContext,
} from '../_hooks/DelayedToggle'
import type { TriggerApi } from '../_hooks/Trigger'
import { Trigger, useTrigger } from '../_hooks/Trigger'
import { useVisibility } from '../_hooks/Visibility'
import { mergeRefs } from '../_utils/mergeRefs'
import type { Placement } from '../_utils/placement'
import type { ReactElementWithRef } from '../_utils/types'
import { useOverlayTriggerA11y } from './a11yPresets'
import type {
  DeprecatedOverlayTriggerRef,
  OverlayTriggerProps,
} from './OverlayTrigger.types'

const ClickOutside = ({
  container,
  onClickOutside,
  refs,
}: ClickOutsideConfig) => {
  useClickOutside({
    onClickOutside,
    refs,
    container,
  })

  return <></>
}

/**

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-overlaytrigger--demo)

 */
export const OverlayTrigger = React.forwardRef<
  DeprecatedOverlayTriggerRef,
  OverlayTriggerProps
>(function OverlayTrigger(
  {
    afterHide = () => {},
    afterShow = () => {},
    ['aria-describedby']: ariaDescribedby,
    ['aria-details']: ariaDetails,
    ['aria-labelledby']: ariaLabelledby,
    ['aria-label']: ariaLabel,
    autoFocus,
    beforeHide = () => true,
    beforeShow = () => true,
    canFlip = true,
    children,
    clickOutsideIgnoreRefs = [],
    containFocus,
    container,
    hideDelay = 100,
    hideKeys: hideKeys_,
    initialIsVisible = false,
    overlay,
    padding = 2,
    placement = 'top',
    restoreFocusOnHide = true, // TODO: change default to be false, but that is a breaking change
    showDelay = 0,
    showKeys = ['ArrowDown', 'Down'],
    shrinkOverlay = false,
    trigger = 'click',
    arrow = false,
    overlayRef: overlayApiRef,
    role,
    passA11yPropsToOverlay = false,
  },
  ref
) {
  const isDialogRole = role === 'dialog'

  if (isDialogRole && (trigger === 'hover' || trigger?.includes('hover'))) {
    console.warn(
      `@procore/core-react: OverlayTigger has role ${role} and a hover trigger, this may cause weird focus management and is unrecommended. Review autoFocus prop.`
    )
  }

  const hideKeys = hideKeys_ ?? {
    // Note by default OverlayTrigger closes on tab. Dialog role changes the default.
    overlay:
      containFocus ?? isDialogRole
        ? ['Escape', 'Esc']
        : ['Escape', 'Esc', 'Tab'],
    target: ['Escape', 'Esc'],
  }

  const triggerElRef = React.useRef<HTMLElement>(null)
  const overlayElRef = React.useRef<HTMLElement>(null)
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLElement | null>(null)
  const clickedOutsideRef = React.useRef(true)

  const visibility = useVisibility({
    afterHide,
    afterShow,
    initialIsVisible,
  })

  React.useImperativeHandle(overlayApiRef, () => ({
    show: visibility.show,
    hide: visibility.hide,
  }))

  const triggers = React.useMemo(
    () => (Array.isArray(trigger) ? trigger : [trigger]),
    [trigger]
  )

  React.useEffect(() => {
    if (triggers.indexOf('none') >= 0 && visibility.isVisible) {
      visibility.hide()
    }
    if (triggers.indexOf('always') >= 0 && !visibility.isVisible) {
      visibility.show()
    }
  }, [triggers, visibility.isVisible])

  // TODO delete in a separate branch for testing and rely only on FocusScope
  React.useEffect(() => {
    if (
      [true, 'core-react'].includes(restoreFocusOnHide) &&
      !visibility.isVisible &&
      !clickedOutsideRef.current
    ) {
      triggerElRef.current && triggerElRef.current.focus()
    }

    clickedOutsideRef.current = false
  }, [restoreFocusOnHide, triggerElRef, visibility.isVisible])

  const delayedToggle = useDelayedToggle({
    beforeDisable: beforeHide,
    beforeEnable: beforeShow,
    disableDelay: hideDelay,
    enableDelay: showDelay,
    isEnabled: visibility.isVisible,
    onDisable: visibility.hide,
    onEnable: visibility.show,
  })

  const triggerApi = useTrigger({
    isEnabled: visibility.isVisible,
    enable: delayedToggle.enable,
    disable: delayedToggle.disable,
    enableKeys: showKeys,
    disableKeys: hideKeys.target,
    trigger,
    triggerRef: triggerElRef,
  })

  const triggerElement =
    typeof children === 'function'
      ? (children as (props: TriggerApi) => ReactElementWithRef)(triggerApi)
      : (children as ReactElementWithRef)

  const overlayId = useId()

  const {
    wrapperProps,
    overlayProps,
    portalProps,
    triggerProps,
    focusScopeProps,
    // Does not use. User needs to wire up when using dialog role!
    labelProps,
  } = useOverlayTriggerA11y({
    canPropOverlayUp: React.isValidElement(overlay) && passA11yPropsToOverlay,
    'aria-describedby': ariaDescribedby,
    'aria-details': ariaDetails,
    'aria-labelledby': ariaLabelledby,
    'aria-label': ariaLabel,
    id: overlayId,
    isOpen: visibility.isVisible,
    role,
  })

  const wrappedTriggerElement = React.cloneElement(triggerElement, {
    open: triggerApi.isVisible,
    ref: triggerElement.ref
      ? mergeRefs(triggerElement.ref, ref, triggerElRef, setReferenceElement)
      : mergeRefs(ref, triggerElRef, setReferenceElement),
    ...triggerProps,
  })

  return (
    <DelayedToggleContext.Provider value={delayedToggle}>
      {wrappedTriggerElement}
      {visibility.isVisible && (
        <>
          <ClickOutside
            {...{
              container: container,
              onClickOutside: (event) => {
                clickedOutsideRef.current = true
                delayedToggle.disable(event)
              },
              refs: [overlayElRef, triggerElRef, ...clickOutsideIgnoreRefs],
            }}
          />
          <Portal container={container} {...portalProps}>
            <Trigger
              {...{
                isEnabled: visibility.isVisible,
                enable: delayedToggle.enable,
                disable: delayedToggle.disable,
                disableKeys: hideKeys.overlay,
                trigger: trigger === 'click' ? 'none' : trigger,
                triggerRef: overlayElRef,
              }}
            >
              <TriggerOverlay
                autoFocus={autoFocus ?? focusScopeProps.autoFocus}
                containFocus={containFocus ?? focusScopeProps.contain}
                restoreFocus={[true, 'react-aria-focus-scope'].includes(
                  restoreFocusOnHide
                )}
                ref={overlayElRef}
                referenceElement={referenceElement}
                overlay={overlay}
                shrinkOverlay={shrinkOverlay}
                placement={placement}
                padding={padding}
                canFlip={canFlip}
                arrow={arrow}
                wrapperProps={wrapperProps}
                overlayCloneProps={overlayProps}
              />
            </Trigger>
          </Portal>
        </>
      )}
    </DelayedToggleContext.Provider>
  )
})

export function CloseOnFocus({ hide }: { hide: (event: any) => any }) {
  return (
    // in IE, the hidden input's cursor appears right under the menu
    // this positions it arbitrarily away where it won't be noticed
    <div
      style={{
        height: 0,
        position: 'absolute',
        top: 1000,
        width: 0,
      }}
    >
      <input onFocus={hide} style={{ opacity: 0 }} />
    </div>
  )
}

export function useOverlayTriggerContext() {
  const { enable: show, disable: hide, toggle } = useDelayedToggleContext()

  return {
    hide,
    show,
    toggle,
  }
}

interface TriggerOverlayProps {
  containFocus?: boolean
  autoFocus?: boolean
  restoreFocus?: boolean
  placement: Placement
  canFlip?: boolean
  arrow?: boolean
  padding: number
  shrinkOverlay: boolean
  overlay: React.ReactNode
  overlayCloneProps: any
  wrapperProps: Record<string, unknown>
  referenceElement: HTMLElement | null
}

export const TriggerOverlay = React.forwardRef<
  HTMLElement,
  TriggerOverlayProps
>(function (
  {
    autoFocus,
    containFocus,
    restoreFocus,
    placement,
    canFlip,
    arrow,
    padding,
    shrinkOverlay,
    overlay,
    referenceElement,
    wrapperProps,
    overlayCloneProps,
  },
  ref
) {
  const arrowRef = React.useRef<HTMLDivElement>(null)

  const arrowPadding = arrowSize / 2 + 1
  const middleware = [
    floatingOffset({ mainAxis: arrow ? padding + arrowPadding : padding }),
    canFlip ? floatingFlip() : null,
    floatingShift({ limiter: limitShift() }),
    !shrinkOverlay
      ? floatingSize({
          apply({ elements }) {
            Object.assign(elements.floating.style, {
              minWidth: `${elements.reference.getBoundingClientRect().width}px`,
            })
          },
        })
      : null,
    floatingArrow({
      element: arrowRef,
      padding: 6,
    }),
  ].filter((middleware) => middleware !== null) as OverlayMiddleware[]

  const {
    isPositioned,
    overlayStyle,
    referenceRef: referenceRefCallback,
    overlayRef,
    middlewareData,
    placement: currentPlacement,
  } = useOverlay({
    middleware,
    placement,
  })

  React.useEffect(() => {
    referenceRefCallback(referenceElement)
  }, [referenceElement, referenceRefCallback])

  const mergedRefs = mergeRefs(ref, overlayRef)

  // TODO could just move clone and clone props to parent
  if (!React.isValidElement(overlay) && overlayCloneProps) {
    console.warn(
      'PLEASE REPORT ISSUE @procore/core-react: OverlayTrigger TriggerOverlay is not a valid React element but wants to clone "overlay" with props. Cannot pass props to the element.'
    )
  }

  const shownStyles: React.CSSProperties = {
    ...overlayStyle,
    left: isPositioned ? overlayStyle.left : '-99999px',
    top: isPositioned ? overlayStyle.top : '-99999px',
  }

  return (
    <FocusScope
      autoFocus={autoFocus}
      contain={containFocus}
      restoreFocus={restoreFocus}
    >
      <div
        data-qa="core-overlay-trigger-overlay-wrapper"
        ref={mergedRefs}
        style={shownStyles}
        {...wrapperProps}
      >
        {React.isValidElement(overlay)
          ? React.cloneElement(overlay, overlayCloneProps)
          : overlay}
        {arrow && (
          <Arrow
            ref={arrowRef}
            arrow={middlewareData.arrow}
            placement={currentPlacement}
          />
        )}
      </div>
    </FocusScope>
  )
})
