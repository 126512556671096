import styled, { css, keyframes } from 'styled-components'
import { Loader } from '../Loader/Loader'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

const loaderGridUnit = spacing.sm

const animationDelay = '300ms'

const fadeIn = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const StyledProgressBarLoader = styled(Loader)``

export const StyledProgressBar = styled.div<{
  $complete: boolean
  $finalized: boolean
}>`
  overflow: hidden;
  position: relative;
  display: block;
  height: ${loaderGridUnit}px;
  background-color: ${({ $complete }) =>
    $complete ? colors.blue50 : colors.gray94};
  border-radius: ${loaderGridUnit * 2}px;
  margin: ${loaderGridUnit * 2}px 0;
  transition: background-color 0s linear;

  ${({ $finalized }) =>
    $finalized &&
    css`
      transition-delay: ${animationDelay};

      ${StyledProgressBarLoader} {
        animation: ${fadeIn} 0 ease-out ${animationDelay} forwards;
      }
    `}

  ${({ $complete, $finalized }) =>
    $complete &&
    !$finalized &&
    css`
      ${StyledProgressBarLoader} {
        opacity: 0;
      }
    `}
`
