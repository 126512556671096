import { CaretDown, CaretUp, ChevronRight } from '@procore/core-icons'
import styled, { css } from 'styled-components'
import { Card } from '../Card'
import { Checkbox } from '../Checkbox'
import {
  StyledDateInputDelimiter,
  StyledDateInputIconContainer,
  StyledDateInputSegment,
} from '../DateInput/DateInput.styles'
import { DateSelect } from '../DateSelect'
import { Input } from '../Input'
import { Select } from '../Select'
import {
  StyledSelectArrow,
  StyledSelectButtonLabel,
  StyledSelectClearIcon,
} from '../Select/Select.styles'
import { TextArea } from '../TextArea'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'
import type { InputCellSize, TableColumnSortOrder } from './Table.types'

const tableBgColor = colors.white
const tableBorderRadius = 4
const tableBorderStyle = 'solid'
const tableBorderWidth = 1
const tableBorderColor = colors.gray85
const tableBorder = `${tableBorderWidth}px ${tableBorderStyle} ${tableBorderColor}`

const tableGroupRowBgColor = colors.gray98
const tableGroupBodyRowIndentation = spacing.md
const tableGroupHeaderRowIndentation = spacing.xl

const tableHeaderBgColor = colors.gray96
const tableHeaderBgColorHover = colors.gray94
const tableHeaderBgColorActive = colors.gray94
const tableHeaderBorderColor = colors.gray90
const tableHeaderBorderRight = `${tableBorderWidth}px ${tableBorderStyle} ${tableHeaderBorderColor}`

const tableHeaderFontColor = colors.gray30
const tableHeaderFontColorActive = colors.gray15
const tableHeaderFontWeight = typographyWeights.semibold
const tableHeaderHeight = 48
const tableHeaderPadding = spacing.lg

const tableSortFontColor = colors.gray70
const tableSortFontColorHover = colors.gray45
const tableSortFontColorActive = colors.blue50

const tableCellTextColor = colors.gray15
const tableCellIconColor = colors.gray45
const tableCellInputPlaceholderColor = colors.gray70
const tableCellInputBorder = '1px solid transparent'
const tableCellInputRowHoverBgColor = colors.gray96
const tableCellInputFocusBgColor = colors.white
const tableCellInputHoverBgColor = colors.blue94
const tableCellInputFocusBorder = `1px solid ${colors.blue50}`
const tableCellInputErrorBgColor = colors.red98
const tableCellInputErrorBorder = `1px solid ${colors.red50}`

const tableRowBorderBottom = css`
  border-bottom: ${tableBorder};

  &:last-child {
    border-bottom: 0;
  }
`

export const StyledTableHeaderCellSortableContainer = styled.div``

export const StyledTableHeaderCellLabel = styled.div`
  display: inline-block;
  flex: 1 1 auto;
  position: relative;
`

export const StyledCheckbox = styled(Checkbox)``

export const StyledTableBodyCell = styled.td<{ $snugfit: boolean }>`
  position: relative;
  vertical-align: middle;

  ${({ $snugfit }) =>
    $snugfit &&
    css`
      white-space: nowrap;
      width: 0.1%;
    `}
`

export const StyledTableHeaderCell = styled.th<{
  $sortable: boolean
  $snugfit: boolean
  $sortOrder?: TableColumnSortOrder
}>`
  ${getTypographyIntent('small')}
  font-weight: ${tableHeaderFontWeight};
  border-right: ${tableHeaderBorderRight};
  color: ${tableHeaderFontColor};
  height: ${tableHeaderHeight}px;
  padding: ${tableHeaderPadding}px;
  position: relative;
  text-align: left;
  transition: background-color 0.2s ease-in-out;

  &:last-child {
    border-right: 0;
  }

  ${StyledTableHeaderCellSortableContainer} {
    display: flex;
    align-items: center;
  }

  ${StyledTableHeaderCellSortableContainer} {
    ${StyledTableHeaderCellLabel} {
      min-height: 14px;
    }
  }

  ${({ $sortable }) =>
    $sortable &&
    css`
      cursor: pointer;
      padding: ${spacing.lg}px;

      ${StyledTableHeaderCellSortIconAsc},
      ${StyledTableHeaderCellSortIconDesc} {
        color: ${tableSortFontColor};
        opacity: 0;
        transition: all 0.15s ease-in-out;
        position: absolute;
      }

      ::before,
      ::after {
        font-size: 16px;
      }

      &:hover {
        background-color: ${tableHeaderBgColorHover};

        ${StyledTableHeaderCellSortIconAsc} {
          color: ${tableSortFontColorHover};
        }
      }
    `}

  ${({ $sortOrder }) =>
    ($sortOrder === 'asc' || $sortOrder === 'desc') &&
    css`
      background-color: ${tableHeaderBgColorActive};
      color: ${tableHeaderFontColorActive};

      &::before,
      &::after,
      ${StyledTableHeaderCellSortIconAsc},
        ${StyledTableHeaderCellSortIconDesc} {
        opacity: 1;
      }
    `}

  ${({ $sortOrder }) =>
    $sortOrder === 'asc' &&
    css`
      ${StyledTableHeaderCellSortIconAsc},
      &::before {
        color: ${tableSortFontColorActive};
      }

      &:hover {
        ${StyledTableHeaderCellSortIconAsc},
        &::before {
          color: ${tableSortFontColorActive};
        }
        ${StyledTableHeaderCellSortIconDesc},
        &::after {
          color: ${tableSortFontColorHover};
        }
      }
    `}

  ${({ $sortOrder }) =>
    $sortOrder === 'desc' &&
    css`
      ${StyledTableHeaderCellSortIconDesc},
      &::after {
        color: ${tableSortFontColorActive};
      }

      &:hover {
        ${StyledTableHeaderCellSortIconAsc},
        &::before {
          color: ${tableSortFontColorHover};
        }
        ${StyledTableHeaderCellSortIconDesc},
        &::after {
          color: ${tableSortFontColorActive};
        }
      }
    `}

  ${({ $snugfit }) =>
    $snugfit &&
    css`
      width: 0.1%;
    `}
`

export const StyledTableHeaderCellIconWrapper = styled.div`
  height: 20px;
  line-height: 20px;
  padding: 0 ${spacing.lg}px 0 ${spacing.sm}px;
  width: 20px;
`

export const StyledTableHeaderCellSortIconAsc = styled(CaretUp)`
  margin-top: -${spacing.sm}px;
`

export const StyledTableHeaderCellSortIconDesc = styled(CaretDown)``

export const StyledHead = styled.thead`
  background-color: ${tableHeaderBgColor};
  transition: background-color 0.2s ease-in-out;
  ${tableRowBorderBottom}

  &:hover {
    ${StyledTableHeaderCell} {
      ${StyledTableHeaderCellSortIconAsc},
      ${StyledTableHeaderCellSortIconDesc} {
        &::after,
        &::before {
          opacity: 1;
        }
      }
    }
  }
`

export const StyledCaratWrapper = styled.div`
  font-size: 16px;
  color: ${colors.gray70};
  padding-right: ${spacing.xs}px;
  transition: all 0.2s ease-in-out;

  & + ${StyledCheckbox} {
    margin: 0 ${spacing.xs}px;
  }
`

export const StyledCarat = styled(ChevronRight)<{ $expanded: boolean }>`
  ${({ $expanded }) =>
    $expanded &&
    css`
      transform: rotate(90deg);
    `}
`

const cellInputCSS = css`
  // Define particular color, simply not to rely on browser's choice of transparent value
  // https://stackoverflow.com/questions/46309641/what-happens-in-safari-with-the-transparent-color
  background-color: rgba(255, 255, 255, 0);

  &&& {
    border: ${tableCellInputBorder};
    border-radius: 4px;
    width: 100%;

    &::placeholder {
      color: ${tableCellInputPlaceholderColor};
    }

    &::-ms-clear {
      display: none;
    }

    &:hover {
      border-color: transparent;
    }

    &:focus {
      /**
        focus background overrides everything, so it is the only cell-defined background style.
        the rest come from StyledInlineEditContainer
      */
      background-color: ${colors.white};
      border-style: solid;
      border-width: 1px;
      border-color: ${colors.blue40};
      box-shadow: 0 0 0 1px ${colors.blue40};
      outline: none;
    }

    &:disabled {
      border: ${tableCellInputBorder};
      color: ${colors.gray70};
    }
  }
`

export const StyledTableCellSelect = styled(Select)<{
  $hasClearIcon: boolean
  $placeholder: boolean
}>`
  ${cellInputCSS};

  background-color: transparent;
  border-color: transparent;
  height: auto;

  &:hover {
    background-color: transparent;
  }

  &:active:hover {
    border-color: ${colors.gray85};
  }

  &:focus {
    background-color: ${colors.white};
    cursor: pointer;
    ${getFocus()}

    ${StyledSelectButtonLabel} {
      white-space: nowrap;
    }

    ${StyledSelectArrow} {
      opacity: 1;
      pointer-events: all;
    }

    ${({ $hasClearIcon }) =>
      $hasClearIcon &&
      css`
        ${StyledSelectClearIcon} {
          opacity: 1;
          pointer-events: all;
        }
      `}

    ${({ $placeholder = false }) =>
      $placeholder &&
      css`
        ${StyledSelectButtonLabel} {
          opacity: 1;
          pointer-events: all;
        }
      `}
  }

  ${StyledSelectButtonLabel} {
    white-space: normal;
  }

  ${StyledSelectArrow}, ${StyledSelectClearIcon} {
    opacity: 0;
    pointer-events: none;
  }

  ${({ $placeholder = false }) =>
    $placeholder &&
    css`
      ${StyledSelectButtonLabel} {
        opacity: 0;
        pointer-events: none;
      }
    `}

  ${({ loading }) =>
    loading &&
    css`
      background-color: ${colors.white};
      border-color: ${colors.gray85};

      ${StyledSelectButtonLabel} {
        white-space: nowrap;
      }
    `}
`
export const StyledTableCellInput = styled(Input)`
  ${cellInputCSS}
`
export const StyledTableCellDateInput = styled(DateSelect)`
  background-color: transparent;
  border-color: transparent;

  &:active:hover {
    border-color: ${colors.gray45};
  }

  // increase specificity to avoid border re-style
  &&&:focus-within {
    ${getFocus()};
    background-color: ${colors.white};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.red50};
    `}

  ${StyledDateInputIconContainer} {
    opacity: 0;
  }

  ${StyledDateInputDelimiter} {
    &[data-qa-table-visible='false'] {
      opacity: 0;
    }
  }

  ${StyledDateInputSegment} {
    &[data-placeholder='true'] {
      opacity: 0;
    }
  }

  &:focus-within {
    ${StyledDateInputIconContainer} {
      opacity: 1;
    }
    ${StyledDateInputDelimiter} {
      &[data-qa-table-visible='false'] {
        opacity: 1;
      }
    }
    ${StyledDateInputSegment} {
      &[data-placeholder='true'] {
        opacity: 1;
      }
    }
  }
`

export const StyledTableCellTextArea = styled(TextArea)<{
  $resizable: boolean
}>`
  ${cellInputCSS}
  && {
    height: ${spacing.xxl}px;
    min-height: ${spacing.xxl}px;
    overflow: hidden;
    padding-top: ${spacing.xs}px;
    resize: none;
    width: 100%;

    ${({ $resizable }) =>
      $resizable &&
      css`
        &:focus {
          min-height: 64px;
          overflow: auto;
          resize: vertical;
        }
      `}
  }
`

export const StyledTableCellText = styled.div<{
  $percent?: boolean
  $currency?: boolean
}>`
  margin: ${spacing.lg}px;
  display: flex;
  color: ${tableCellTextColor};

  ${({ $percent, $currency }) =>
    ($percent || $currency) &&
    css`
      justify-content: flex-end;
    `}
`

export const StyledIconCell = styled.div`
  display: flex;
  color: ${tableCellIconColor};
`

export const StyledSelectCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTableInlineEditErrorBox = styled.div<{
  $size?: InputCellSize
}>`
  ${getTypographyIntent('small')}
  color: ${colors.white};
  font-style: italic;
  background: ${colors.red50};
  border-radius: 0 2px 2px 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 6px ${spacing.md}px;
  position: absolute;
  max-width: 160px;
  z-index: 1;

  ${({ $size }) => {
    const sizes: Record<InputCellSize, number | null> = {
      xs: 36,
      sm: 48,
      md: 80,
      lg: 112,
      block: null,
      xl: null,
    }

    if (!$size || !sizes[$size]) {
      return ''
    }

    return css`
      min-width: ${sizes[$size]}px;
    `
  }}
`

interface StyledTableInlineEditContainerProps {
  $disabled?: boolean
  $error?: boolean
  $size: InputCellSize
}

export const StyledTableInlineEditContainer = styled.div<StyledTableInlineEditContainerProps>`
  background-color: ${tableBgColor};
  margin: ${spacing.sm}px ${spacing.xs}px;
  position: relative;

  ${({ $error }) =>
    $error &&
    css`
      position: static;
      background-color: ${tableCellInputErrorBgColor};

      ${StyledTableCellDateInput},
      ${StyledTableCellInput},
      ${StyledTableCellSelect},
      ${StyledTableCellTextArea} {
        border: ${tableCellInputErrorBorder};
        border-radius: 4px 4px 0 0;
        border-bottom: none;

        &:hover {
          border-bottom: none;
        }

        &:focus {
          background-color: ${tableCellInputFocusBgColor};
          border: ${tableCellInputFocusBorder};
          border-bottom: none;
        }
      }
    `}

  ${({ $size }) => {
    const sizes: Record<InputCellSize, number | null> = {
      xs: 36,
      sm: 48,
      md: 80,
      lg: 112,
      block: null,
      xl: null,
    }

    if ($size === 'block') {
      return css`
        position: relative;

        ${StyledTableInlineEditErrorBox} {
          width: 100%;
          max-width: 100%;
        }
      `
    }

    if (!$size || !sizes[$size]) {
      return ''
    }

    return css`
      width: ${sizes[$size]}px;
    `
  }}
`

export const StyledTableBodyRow = styled.tr<{
  $overdue: boolean
}>`
  ${tableRowBorderBottom}

  ${({ $overdue }) =>
    $overdue &&
    css`
      ${StyledTableCellText},
      ${StyledTableCellSelect},
      ${StyledTableCellInput},
      ${StyledTableCellDateInput},
      ${StyledTableCellTextArea} {
        color: ${colors.red50};
      }
    `}

  &:focus-within {
    ${StyledTableInlineEditContainer} {
      background-color: ${tableCellInputRowHoverBgColor};

      &.disabled {
        background-color: transparent;
      }
    }
    ${StyledTableInlineEditContainer}.error {
      background-color: ${tableCellInputErrorBgColor};
    }
  }

  &:hover {
    ${StyledTableInlineEditContainer} {
      background-color: ${tableCellInputRowHoverBgColor};
      cursor: pointer;

      &:hover {
        background-color: ${tableCellInputHoverBgColor};
      }

      &.disabled {
        &,
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }

    ${StyledTableInlineEditContainer}.error {
      background-color: ${tableCellInputErrorBgColor};

      &:hover {
        background-color: ${tableCellInputErrorBgColor};
      }

      ${StyledTableCellInput},
      ${StyledTableCellSelect},
      ${StyledTableCellTextArea} {
        border: ${tableCellInputErrorBorder};
        border-bottom: none;

        &:hover {
          border-bottom: none;
        }

        &:focus {
          border: ${tableCellInputFocusBorder};
          border-bottom: none;
        }
      }
    }
  }
`

export const StyledTableGroupRowContent = styled.div``

export const StyledTableGroupRowHeader = styled.div``

export const StyledTableGroupRowTitle = styled.span``

export const StyledTableGroupRow = styled.tr<{ $depth?: number | boolean }>`
  background-color: ${tableGroupRowBgColor};
  ${tableRowBorderBottom}

  ${StyledTableGroupRowContent} {
    margin-left: ${spacing.lg}px;
    padding: ${spacing.lg}px 0;
    display: flex;
    flex-direction: row;
  }

  ${StyledTableGroupRowHeader} {
    display: flex;
    align-items: center;
  }

  ${StyledTableGroupRowTitle} {
    font-weight: ${tableHeaderFontWeight};
    padding-left: ${spacing.lg}px;
  }

  ${({ $depth }) => {
    if (typeof $depth !== 'number') {
      return ''
    }

    return css`
      > td {
        padding-left: ${$depth * tableGroupHeaderRowIndentation}px !important;
      }

      ~ ${StyledTableBodyRow} {
        > td:first-child {
          padding-left: ${($depth + 1) * tableGroupHeaderRowIndentation +
          tableGroupBodyRowIndentation}px !important;
        }
      }
    `
  }}
`

export const StyledTableWrapper = styled(Card)<{ $withShelf: boolean }>`
  border-radius: ${tableBorderRadius}px;
  border: ${tableBorder};

  ${StyledHead}, tbody {
    &:first-child {
      tr:first-child {
        ${StyledTableHeaderCell}, td {
          &:first-child {
            border-top-left-radius: ${tableBorderRadius}px;
          }

          &:last-child {
            border-top-right-radius: ${tableBorderRadius}px;
          }
        }
      }
    }

    &:last-child {
      tr:last-child {
        ${StyledTableHeaderCell}, td {
          &:first-child {
            border-bottom-left-radius: ${tableBorderRadius}px;
          }
          &:last-child {
            border-bottom-right-radius: ${tableBorderRadius}px;
          }
        }
      }
    }
  }

  ${({ $withShelf }) =>
    $withShelf &&
    css`
      position: relative;
      height: 100%;
    `}
`

const BodyRow = StyledTableBodyRow

const GroupRow = StyledTableGroupRow

export const StyledTable = styled.table<{ $inline?: boolean; $fixed: boolean }>`
  background-color: ${tableBgColor};
  border-collapse: collapse;
  border-radius: ${tableBorderRadius}px;
  ${getTypographyIntent('body')}
  width: 100%;

  td:first-child ${StyledCheckbox} {
    padding-left: ${spacing.lg}px;
  }

  ${StyledTableGroupRow} ${StyledCaratWrapper} + ${StyledCheckbox} {
    margin-left: ${spacing.xs}px;
    padding-left: 0;
  }

  ${({ $fixed }) =>
    $fixed &&
    css`
      table-layout: fixed;
    `}

  ${({ $inline }) =>
    $inline &&
    css`
      border-top: 1px solid ${colors.gray85};
      border-bottom: 1px solid ${colors.gray85};
    `}

  ${GroupRow} + ${GroupRow} > td {
    padding-left: ${tableGroupHeaderRowIndentation}px;
  }

  ${GroupRow} + ${GroupRow} + ${GroupRow} > td {
    padding-left: ${2 * tableGroupHeaderRowIndentation}px;
  }

  ${GroupRow} + ${GroupRow} + ${GroupRow} + ${GroupRow} > td {
    padding-left: ${3 * tableGroupHeaderRowIndentation}px;
  }

  ${GroupRow} + ${GroupRow} + ${GroupRow} + ${GroupRow} + ${GroupRow} > td {
    padding-left: ${4 * tableGroupHeaderRowIndentation}px;
  }

  ${GroupRow} ~ ${BodyRow} {
    ${StyledCheckbox} {
      padding-left: 0;
      margin: 0 ${spacing.xs}px;
    }

    & > td:first-child {
      padding-left: ${tableGroupHeaderRowIndentation +
      tableGroupBodyRowIndentation}px;
    }
  }

  ${GroupRow} + ${GroupRow} ~ ${BodyRow} {
    & > td:first-child {
      padding-left: ${2 * tableGroupHeaderRowIndentation +
      tableGroupBodyRowIndentation}px;
    }
  }

  ${GroupRow} + ${GroupRow} + ${GroupRow} ~ ${BodyRow} {
    & > td:first-child {
      padding-left: ${3 * tableGroupHeaderRowIndentation +
      tableGroupBodyRowIndentation}px;
    }
  }

  ${GroupRow} + ${GroupRow} + ${GroupRow} + ${GroupRow} ~ ${BodyRow} {
    & > td:first-child {
      padding-left: ${4 * tableGroupHeaderRowIndentation +
      tableGroupBodyRowIndentation}px;
    }
  }

  ${GroupRow} + ${GroupRow} + ${GroupRow} + ${GroupRow} + ${GroupRow} ~ ${BodyRow} {
    & > td:first-child {
      padding-left: ${5 * tableGroupHeaderRowIndentation +
      tableGroupBodyRowIndentation}px;
    }
  }
`

export const StyledLoadingContainer = styled.div`
  cursor: default;
  align-items: center;
  background: ${colors.gray96};
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
  z-index: 2;
`
