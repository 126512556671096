import React from 'react'
import { areEqual } from 'react-window'
import { Spinner } from '../../Spinner/Spinner'
import { Tree } from '../../Tree/Tree'
import type { Node as TreeNode, TreeNodeId } from '../../Tree/Tree.types'
import { useResizeObserver } from '../../_hooks/ResizeObserver'
import type { DivAttributes } from '../../_utils/types'
import { useFileSelectContext } from '../FileSelect'
import { StyledFileTreeSpinner } from './TreeSource.styles'
import type { FileTreeProps, TreeSourceRef } from './TreeSource.types'

const topPadding = 24

function noop() {}

/**
 * `innerElementType` and `outerElementType` elements are needed to create top padding.
 * Be aware that because of virtualization, it is not a trivial task.
 * 1. First of all, we pass height to the `Tree` component without padding, it is needed for correct work of keyboard navigation.
 * 2. Then we counterbalance height in outerElementType component (by adding padding to the height).
 * 3. The last step - make padding using the shift of a relatively positioned element.
 */
const innerElementType = React.memo(
  React.forwardRef<HTMLDivElement, any>(
    ({ style, children, ...props }, ref) => (
      <div
        ref={ref}
        style={{
          ...style,
          position: 'relative',
          top: `${topPadding}px`,
          height: `${parseFloat(style.height) - topPadding}px`,
        }}
        {...props}
      >
        {children}
      </div>
    )
  ),
  areEqual
)

const outerElementType = React.memo(
  React.forwardRef<HTMLDivElement, any>(
    ({ style, children, ...props }, ref) => (
      <div
        ref={ref}
        style={{
          ...style,
          height: `${parseFloat(style.height) + topPadding}px`,
        }}
        {...props}
      >
        {children}
      </div>
    )
  ),
  areEqual
)

export const TreeSource = React.forwardRef<
  TreeSourceRef,
  FileTreeProps & Omit<DivAttributes, 'onChange'>
>(
  (
    {
      sourceId,
      title,
      icon,
      options,
      isLoading = false,
      onChange = noop,
      onExpand = () => Promise.resolve(),
      ...props
    },
    ref
  ) => {
    const {
      maxFileNumber,
      register,
      currentSource,
      onChange: onSourceChange,
      onResetValue,
    } = useFileSelectContext()

    const [value, setValue] = React.useState<TreeNode[]>([])
    const [expandedItems, setExpandedNodes] = React.useState<TreeNodeId[]>([])

    React.useEffect(() => {
      onSourceChange(sourceId, value)
    }, [value])

    React.useEffect(() => {
      const unregister = register(sourceId, {
        title,
        icon,
      })
      const unsubscribe = onResetValue(() => {
        setValue([])
      })
      return () => {
        unsubscribe()
        unregister()
      }
    }, [])

    const selectedFiles = React.useMemo(
      () => value.map((item: TreeNode) => item.id),
      [value]
    )

    const _onChange = (files: TreeNode[]) => {
      setValue(files)
      onChange(files)
    }

    const _onExpand = async (node: TreeNode) => {
      await onExpand(node)
      setExpandedNodes((prev) => [...prev, node.id])
    }

    const _onCollapse = (node: TreeNode) => {
      setExpandedNodes((prev) => prev.filter((nodeId) => nodeId !== node.id))
    }

    const [containerSize, setContainerSize] = React.useState({
      width: 0,
      height: 0,
    })

    const handleContainerResize = React.useCallback(
      (entries: ResizeObserverEntry[]) => {
        if (entries[0]) {
          const { clientHeight: height, clientWidth: width } = entries[0].target
          setContainerSize({ width, height })
        }
      },
      [setContainerSize]
    )

    const setResizeObserverTarget = useResizeObserver(handleContainerResize)

    React.useImperativeHandle(ref, () => ({
      setExpandedNodes,
      setValue,
    }))

    if (currentSource !== sourceId) {
      return null
    }

    return !isLoading ? (
      <Tree
        {...props}
        ref={(instance) => {
          if (instance?.rootEl) {
            setResizeObserverTarget(instance.rootEl)
          }
        }}
        options={options}
        onSelect={_onChange}
        onExpand={_onExpand}
        onCollapse={_onCollapse}
        expanded={expandedItems}
        selected={selectedFiles}
        selectionLimit={maxFileNumber + value.length}
        visibleHeight={containerSize.height - topPadding}
        innerElementType={innerElementType}
        outerElementType={outerElementType}
        autoExpandParent={false}
      />
    ) : (
      <StyledFileTreeSpinner>
        <Spinner />
      </StyledFileTreeSpinner>
    )
  }
)
