import React from 'react'
import { useI18nContext } from '../_hooks/I18n'
import {
  StyledRequiredContainer,
  StyledRequiredMark,
  StyledRequiredText,
} from './Required.styles'
import type { RequiredProps } from './Required.types'

/**

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-required--demo)

 */
export const Required = React.forwardRef<HTMLSpanElement, RequiredProps>(
  (
    { children, i18nScope = 'core.required', showLabel = false, ...props },
    ref
  ) => {
    const I18n = useI18nContext()
    const labelOrChildren = showLabel || Boolean(children)

    return (
      <StyledRequiredContainer {...props} ref={ref}>
        <StyledRequiredMark $showLabel={labelOrChildren}>*</StyledRequiredMark>
        {labelOrChildren ? (
          <StyledRequiredText>
            {children
              ? children
              : I18n.t('requiredFields', { scope: i18nScope })}
          </StyledRequiredText>
        ) : null}
      </StyledRequiredContainer>
    )
  }
)

Required.displayName = 'Required'
