interface Callback<T> {
  (item: T): Promise<any>
}

function workers<T>(
  threads: number,
  iterator: IterableIterator<T>
): IterableIterator<T>[] {
  return new Array(threads).fill(iterator)
}

function run<T>(func: Callback<T>) {
  return async (iterator: IterableIterator<T>) => {
    while (true) {
      const result = iterator.next()
      if (result.done) break
      await func(result.value)
    }
  }
}

export function runInQueue<T>(array: T[], func: Callback<T>, concurrency = 1) {
  return workers(concurrency, array.values()).map(run(func))
}
