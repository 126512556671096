import type { Scope } from './EventListener'
import { useEventListener } from './EventListener'

type EventType = 'keydown' | 'keyup'

interface Config {
  /**
   * @since 10.19.0
   */
  dependencies?: any[]
  /**
   * @since 10.19.0
   */
  event?: EventType
  /**
   * @since 10.19.0
   */
  handler: (event: KeyboardEvent) => void
  /**
   * @since 10.19.0
   */
  key: string | string[]
  /**
   * @since 10.19.0
   */
  preventDefault?: boolean
  /**
   * @since 10.19.0
   */
  scope?: Scope
  /**
   * @since 10.19.0
   */
  stopPropagation?: boolean
}

function bindHotkeys({
  dependencies = [],
  event = 'keydown',
  handler = () => {},
  key,
  preventDefault,
  stopPropagation,
}: Config) {
  return (scope: Scope) => {
    const keys = Array.isArray(key) ? key : [key]

    useEventListener({
      dependencies,
      event,
      handler: (e: KeyboardEvent) => {
        if (keys.includes(e.key)) {
          handler(e)
          preventDefault && e.preventDefault()
          stopPropagation && e.stopPropagation()
        }
      },
      scope,
    })
  }
}

/**
 * @deprecatedSince 9.7.0
 * @deprecated Pass functions directly to JSX elements. For window events, add a new listener and remove it in an effect
 */
export function useHotkey({ scope = window, ...config }: Config) {
  const scopes = Array.isArray(scope) ? scope : [scope]

  scopes.forEach(bindHotkeys(config))
}
