import React from 'react'
import { Modal } from '../../Modal/Modal'
import type { ModalProps } from '../../Modal/Modal.types'
import { Portal } from '../../Portal/Portal'
import type { PortalProps } from '../../Portal/Portal.types'
import { useScrollLock } from '../../_hooks/ScrollLock'
import type { DivAttributes } from '../../_utils/types'
import { StyledModalContainer, StyledModalOverlay } from './FileExplorer.styles'

function noop() {}

export const FileExplorerModal = React.forwardRef<
  HTMLDivElement,
  ModalProps & DivAttributes & PortalProps
>(function FileExplorerModal(
  {
    children,
    onClickOverlay = noop,
    open = false,
    placement = 'center',
    ...props
  },
  ref
) {
  useScrollLock(open)

  return (
    <Portal container={props.container}>
      <StyledModalOverlay $open={open} ref={ref}>
        <Modal.Scrim onClick={onClickOverlay} />

        <StyledModalContainer {...props} placement={placement}>
          <Modal.Content>{children}</Modal.Content>
        </StyledModalContainer>
      </StyledModalOverlay>
    </Portal>
  )
})
