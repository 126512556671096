import { isOptgroupSymbol } from './SuperSelect.constants'
import type {
  SuperSelectConfig,
  SuperSelectOptgroup,
  SuperSelectOption,
} from './SuperSelect.types'

export const getGroupNameIsValid = (groupName: unknown) => {
  const isGroupDefined = ![undefined, null, ''].includes(groupName as string)

  return isGroupDefined && typeof groupName === 'string' && groupName.length > 0
}

export const getOptionsSortingAlgorithm =
  ({
    getOptionIsBatch,
    getOptionLabel,
  }: {
    getOptionIsBatch: NonNullable<SuperSelectConfig['getOptionIsBatch']>
    getOptionLabel: NonNullable<SuperSelectConfig['getOptionLabel']>
  }) =>
  (a: SuperSelectOption, b: SuperSelectOption) => {
    const isOptionBatch1 = getOptionIsBatch(a)
    const isOptionBatch2 = getOptionIsBatch(b)

    // move batched options up
    if (isOptionBatch1 && isOptionBatch2) {
      return compareStrings(getOptionLabel(a), getOptionLabel(b))
    } else if (isOptionBatch1) {
      return -1
    } else if (isOptionBatch2) {
      return 1
    }

    return compareStrings(getOptionLabel(a), getOptionLabel(b))
  }

export const getBatchOptionFormatter =
  ({
    value,
    multiple,
    getOptionIsBatch,
    getOptionValue,
  }: {
    value: SuperSelectConfig['value']
    multiple: NonNullable<SuperSelectConfig['multiple']>
    getOptionIsBatch: NonNullable<SuperSelectConfig['getOptionIsBatch']>
    getOptionValue: NonNullable<SuperSelectConfig['getOptionValue']>
  }) =>
  (option: SuperSelectOption) => {
    if (isMultiple(multiple, value) && getOptionIsBatch(option)) {
      const val = getOptionValue(option)

      const disabled = Array.isArray(val) && val.every((v) => value.includes(v))

      return {
        ...option,
        disabled,
      }
    }
    return option
  }

export const RESERVED_UNGROUPED_OPTGROUP_NAME =
  'RESERVED_UNGROUPED_OPTGROUP_NAME'

export const collectGroupsInOrderOfOccurrence = (
  opts: SuperSelectOption[],
  getOptionGroup: NonNullable<SuperSelectConfig['getOptionGroup']>
): {
  groups: string[]
  groupedOptions: Record<string, SuperSelectOption[]>
} => {
  const output: {
    groups: string[]
    groupedOptions: Record<string, SuperSelectOption[]>
  } = opts.reduce(
    ({ groups, groupedOptions }, option) => {
      const groupName = getOptionGroup(option)

      // if group name is invalid, consider the option ungrouped
      const isGroupNameValid = getGroupNameIsValid(groupName)
      const adjustedGroupName = isGroupNameValid
        ? groupName
        : RESERVED_UNGROUPED_OPTGROUP_NAME

      const groupOptions = groupedOptions[adjustedGroupName] ?? []

      // push/assign for performance considerations
      groupOptions.push(option)
      groupedOptions[adjustedGroupName] = groupOptions

      return {
        groups: groups.includes(adjustedGroupName)
          ? groups
          : [...groups, adjustedGroupName],
        groupedOptions,
      }
    },
    {
      groups: [],
      groupedOptions: {},
    }
  )

  // if ungrouped options were found, lift them to the top
  if (output.groups.includes(RESERVED_UNGROUPED_OPTGROUP_NAME)) {
    return {
      groupedOptions: output.groupedOptions,
      groups: [
        RESERVED_UNGROUPED_OPTGROUP_NAME,
        ...output.groups.filter(
          (group) => group !== RESERVED_UNGROUPED_OPTGROUP_NAME
        ),
      ],
    }
  }

  return output
}

function findParentOptgroup(list: SuperSelectOption[], index: number) {
  for (let i = index; i >= 0; i--) {
    const option = list[i]
    const isOptgroup = getOptionIsOptgroup(option)

    if (isOptgroup) {
      return option.id
    }
  }
  // no parent optgroup was found, so the option is considered ungrouped
  return RESERVED_UNGROUPED_OPTGROUP_NAME
}

export function reorder(
  options: SuperSelectOption[],
  startIndex: number,
  endIndex: number
) {
  const nextOptions = [...options]
  const [removed] = nextOptions.splice(startIndex, 1)
  nextOptions.splice(endIndex, 0, removed)

  const prevGroup = findParentOptgroup(options, startIndex)
  const nextGroup = findParentOptgroup(nextOptions, endIndex)

  return { nextOptions, prevGroup, nextGroup }
}

function compareStrings(a: string, b: string) {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
}

export function isMultiple<T>(multiple: boolean, value: T | T[]): value is T[] {
  return multiple && Array.isArray(value)
}

export const getOptionIsOptgroup = (
  item: SuperSelectOption
): item is SuperSelectOptgroup => {
  return Boolean(item[isOptgroupSymbol])
}

export const sortOptgroups = (groupA: string, groupB: string) => {
  // always put ungrouped options on top
  if (groupA === RESERVED_UNGROUPED_OPTGROUP_NAME) {
    return -1
  }
  if (groupB === RESERVED_UNGROUPED_OPTGROUP_NAME) {
    return 1
  }
  return compareStrings(groupA, groupB)
}

export function getIsAllOptionsUngrouped(groups: string[]) {
  return groups.length === 1 && groups[0] === RESERVED_UNGROUPED_OPTGROUP_NAME
}

export function createOptgroup(
  groupName: string,
  groupOptions: SuperSelectOption[]
) {
  // ungrouped options should not have a group header
  if (groupName === RESERVED_UNGROUPED_OPTGROUP_NAME) {
    return groupOptions
  }

  const optgroupHeader: SuperSelectOptgroup = {
    [isOptgroupSymbol]: true,
    id: groupName,
    label: groupName,
  }

  return [optgroupHeader, ...groupOptions]
}

export function removeEmptyOptGroups(options: SuperSelectOption[]) {
  return options.filter((opt, index, arr) => {
    const currentElementIsLast = index === arr.length - 1
    const currentElementIsOptgroup = getOptionIsOptgroup(opt)
    const nextElementIsOptgroup =
      !currentElementIsLast && getOptionIsOptgroup(arr[index + 1])

    return !(
      currentElementIsOptgroup &&
      (nextElementIsOptgroup || currentElementIsLast)
    )
  })
}
