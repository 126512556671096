import { Check } from '@procore/core-icons/dist'
import React from 'react'
import { Pill } from '../Pill/Pill'
import { Select } from '../Select/Select'
import { Tooltip } from '../Tooltip/Tooltip'
import { OverflowObserver } from '../_hooks/OverflowObserver'
import type { DivAttributes } from '../_utils/types'
import {
  PillSelectCheckedContainer,
  PillSelectLabel,
  PillSelectLabelWrapper,
  PillSelectOptionWrapper,
} from './PillSelect.styles'
import type {
  PillOptGroup,
  PillOption,
  PillOptionProps,
  PillSelectProps,
} from './PillSelect.types'

const emptyArr: Array<any> = []

function defaultGetId(option: PillOption | PillOptGroup) {
  return option.id
}

function defaultGetLabel(option: PillOption | PillOptGroup) {
  return option.label
}

function defaultGetGroup(option: PillOption) {
  return option.groupId
}

function defaultGetSuggested(option: PillOption) {
  return option.suggested
}

export function defaultGetColor(option: PillOption) {
  return option.color
}

const PillSelectOption = React.forwardRef<HTMLDivElement, PillOptionProps>(
  function PillSelectOption({ color, label, selected, ...props }, ref) {
    return (
      <Select.Option selected={selected} {...props} ref={ref}>
        <PillSelectOptionWrapper>
          <Pill color={color}>{label}</Pill>
          <PillSelectCheckedContainer>
            {selected && <Check size="sm" />}
          </PillSelectCheckedContainer>
        </PillSelectOptionWrapper>
      </Select.Option>
    )
  }
)

const PillSelectOptGroup = React.forwardRef<HTMLDivElement, DivAttributes>(
  function PillSelectOptGroup(props, ref) {
    return <Select.OptGroup {...props} ref={ref} />
  }
)

/**

 Pill Selects are used when you need a user to select between one of several
 options that are shown as pills. Often this will be a status, such as marking
 an item “closed”, “draft”, etc.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-pillselect--demo)

 @see [Design Guidelines](https://design.procore.com/pill-select)

 */
export const PillSelect = React.forwardRef<
  HTMLDivElement,
  PillSelectProps<any, any>
>(function PillSelect(
  {
    afterHide: afterHide_,
    afterShow: afterShow_,
    getColor = defaultGetColor,
    getGroup = defaultGetGroup,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    getSuggested = defaultGetSuggested,
    groupGetId = defaultGetId,
    groupGetLabel = defaultGetLabel,
    optgroups,
    options = emptyArr,
    value,
    ...props
  },
  ref
) {
  const { color, id, label } = React.useMemo(() => {
    if (!value) {
      return {}
    }
    return {
      color: getColor(value),
      id: getId(value),
      label: getLabel(value),
    }
  }, [getColor, getId, getLabel, value])

  const optionsByGroup = React.useMemo(
    () =>
      optgroups
        ?.map((optgroup) => ({
          optgroup,
          options: options.filter(
            (option) => getGroup(option) === groupGetId(optgroup)
          ),
        }))
        .filter(({ options }) => options.length),
    [options, optgroups, groupGetId, getGroup]
  )

  const [isOpened, setIsOpened] = React.useState(false)

  const afterShow = React.useCallback(() => {
    setIsOpened(true)
    afterShow_?.()
  }, [afterShow_])

  const afterHide = React.useCallback(() => {
    setIsOpened(false)
    afterHide_?.()
  }, [afterHide_])

  return (
    <Select
      afterHide={afterHide}
      afterShow={afterShow}
      label={
        label ? (
          <OverflowObserver>
            {({ isOverflowing, ref }) => {
              const pill = (
                <PillSelectLabel color={color} ref={ref}>
                  {label}
                </PillSelectLabel>
              )

              const showTooltip = isOverflowing && !isOpened

              return (
                <PillSelectLabelWrapper disabled={props.disabled}>
                  {showTooltip ? (
                    <Tooltip trigger="hover" overlay={label}>
                      {pill}
                    </Tooltip>
                  ) : (
                    pill
                  )}
                </PillSelectLabelWrapper>
              )
            }}
          </OverflowObserver>
        ) : null
      }
      {...props}
      ref={ref}
    >
      {optionsByGroup ? (
        optionsByGroup.map(({ optgroup, options }) => (
          <React.Fragment key={groupGetId(optgroup)}>
            <PillSelectOptGroup key={groupGetId(optgroup)}>
              {groupGetLabel(optgroup)}
            </PillSelectOptGroup>
            {options.map((option) => (
              <PillSelectOption
                key={getId(option)}
                color={getColor(option)}
                label={getLabel(option)}
                selected={getId(option) === id}
                suggested={getSuggested(option)}
                value={option}
              />
            ))}
          </React.Fragment>
        ))
      ) : (
        <>
          {options.map((option) => (
            <PillSelectOption
              key={getId(option)}
              color={getColor(option)}
              label={getLabel(option)}
              selected={getId(option) === id}
              suggested={getSuggested(option)}
              value={option}
            />
          ))}
        </>
      )}
    </Select>
  )
})

PillSelect.displayName = 'PillSelect'
