import React from 'react'
import styled from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { useDeprecation } from '../_hooks/Deprecation'
import { colors } from '../_styles/colors'
import type { SpanAttributes } from '../_utils/types'
import type { NotationProps } from './Notation.types'

const variantColorMap = {
  required: 'orange50',
  error: 'red50',
  pagination: 'gray45',
} as const

const StyledNotation = styled.i<NotationProps>`
  ${getTypographyIntent('small')}
  color: ${({ variant }) =>
    variant ? colors[variantColorMap[variant]] : colors.gray15};
  font-style: italic;
`
/**
 * @deprecated Please use `<Typography italic />` component for plain text
 * notations or the `<Required />` component for required notations. `Notation`
 * will be removed in a future version.
 * @deprecatedSince 11
 *
 *  @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_coverage-notation--basic-props)
 */
export const Notation = React.forwardRef<
  HTMLSpanElement,
  SpanAttributes & NotationProps
>(function Notation({ children, variant = '', ...props }, ref) {
  useDeprecation({ oldThing: 'Notation', newThing: 'Typography or Required' })

  return (
    <StyledNotation {...props} variant={variant} ref={ref}>
      {children}
    </StyledNotation>
  )
})

Notation.displayName = 'Notation'
