import type { CurrencyOptions } from '@procore/globalization-toolkit'
import { CurrencyFormatter } from '@procore/globalization-toolkit'
import { useId } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import cx from 'classnames'
import React from 'react'
import type { CheckboxProps } from '../Checkbox/Checkbox.types'
import { Link as LinkElement } from '../Link'
import { tableSelectClassName } from '../Select/Select.styles'
import { Spinner } from '../Spinner'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type {
  AnchorAttributes,
  DivAttributes,
  SpanAttributes,
  SVGAttributes,
  TableAttributes,
  TbodyAttributes,
  TdAttributes,
  TextAreaAttributes,
  ThAttributes,
  TheadAttributes,
  TrAttributes,
} from '../_utils/types'
import {
  StyledCarat,
  StyledCaratWrapper,
  StyledCheckbox,
  StyledHead,
  StyledIconCell,
  StyledLoadingContainer,
  StyledSelectCellWrapper,
  StyledTable,
  StyledTableBodyCell,
  StyledTableBodyRow,
  StyledTableCellDateInput,
  StyledTableCellInput,
  StyledTableCellSelect,
  StyledTableCellText,
  StyledTableCellTextArea,
  StyledTableGroupRow,
  StyledTableGroupRowContent,
  StyledTableGroupRowHeader,
  StyledTableGroupRowTitle,
  StyledTableHeaderCell,
  StyledTableHeaderCellIconWrapper,
  StyledTableHeaderCellLabel,
  StyledTableHeaderCellSortableContainer,
  StyledTableHeaderCellSortIconAsc,
  StyledTableHeaderCellSortIconDesc,
  StyledTableInlineEditContainer,
  StyledTableInlineEditErrorBox,
  StyledTableWrapper,
} from './Table.styles'
import type {
  BodyCellProps,
  BodyProps,
  BodyRowProps,
  CaratProps,
  ContainerProps,
  CurrencyCellProps,
  DateSelectCellProps,
  GroupProps,
  GroupTitleProps,
  HeaderProps,
  HeaderRowProps,
  IconCellProps,
  InputCellProps,
  LinkCellProps,
  PercentCellProps,
  TableColumnSortOrder,
  TableHeaderCellProps,
  TableProps,
  TableSelectCellProps,
  TableTextCellProps,
  TextAreaCellProps,
} from './Table.types'

export const Table_ = React.forwardRef<
  HTMLTableElement,
  TableAttributes & TableProps
>(function Table({ children, inline, variant, ...props }, ref) {
  return (
    <StyledTable
      ref={ref}
      {...props}
      $inline={inline}
      $fixed={variant === 'fixed'}
    >
      {children}
    </StyledTable>
  )
})

export const Carat = React.forwardRef<
  HTMLDivElement,
  SVGAttributes & CaratProps
>(function Carat({ expanded = false, ...props }, ref) {
  return (
    <StyledCaratWrapper ref={ref}>
      <StyledCarat $expanded={expanded} {...props} />
    </StyledCaratWrapper>
  )
})

export const Container = React.forwardRef<
  HTMLDivElement,
  DivAttributes & ContainerProps
>(function Container({ children, shelf = false, ...props }, ref) {
  return (
    <StyledTableWrapper
      {...props}
      ref={ref}
      shadowStrength={0}
      $withShelf={shelf}
    >
      {children}
    </StyledTableWrapper>
  )
})

export const Header = React.forwardRef<
  HTMLTableSectionElement,
  TheadAttributes & HeaderProps
>(function Header({ children, ...props }, ref) {
  return (
    <StyledHead ref={ref} {...props}>
      {children}
    </StyledHead>
  )
})

export const HeaderRow = React.forwardRef<
  HTMLTableRowElement,
  TrAttributes & HeaderRowProps
>(function HeaderRow({ children, ...props }, ref) {
  return (
    <tr ref={ref} {...props}>
      {children}
    </tr>
  )
})

export const HeaderCell = React.forwardRef<
  HTMLTableHeaderCellElement,
  ThAttributes & TableHeaderCellProps
>(function HeaderCell(
  {
    sortOrder: sortOrder_,
    onClick,
    onKeyUp,
    onSortOrderChange: onSortOrderChange_,
    sortable,
    children,
    snugfit = false,
    variant,
    ...props
  },
  ref
) {
  const I18n = useI18nContext()
  const descriptionId = useId()
  const sortOrder: TableColumnSortOrder | undefined =
    sortOrder_ ?? (variant !== 'snugfit' ? variant : undefined)
  const isSortable = sortOrder !== undefined && sortable !== false

  const ariaSortValues = {
    asc: 'ascending',
    desc: 'descending',
    '': 'none',
  } as const

  const onSortOrderChange = () => {
    if (!onSortOrderChange_) {
      return
    }

    const nextValue =
      sortOrder === '' ? 'asc' : sortOrder === 'asc' ? 'desc' : ''
    onSortOrderChange_(nextValue)
  }

  return (
    <StyledTableHeaderCell
      ref={ref}
      {...props}
      role="columnheader"
      aria-sort={isSortable ? ariaSortValues[sortOrder ?? ''] : undefined}
      tabIndex={isSortable ? 0 : undefined}
      aria-describedby={isSortable ? descriptionId : undefined}
      $sortable={isSortable}
      $sortOrder={sortOrder}
      $snugfit={snugfit || variant === 'snugfit'}
      onKeyUp={(e) => {
        if (['Space', 'Enter'].includes(e.code) && isSortable) {
          onSortOrderChange()
        }
        return onKeyUp?.(e)
      }}
      onClick={(e) => {
        if (isSortable) {
          onSortOrderChange()
        }
        return onClick?.(e)
      }}
    >
      <StyledTableHeaderCellSortableContainer>
        <StyledTableHeaderCellLabel>{children}</StyledTableHeaderCellLabel>
        {isSortable && (
          <>
            <StyledTableHeaderCellIconWrapper aria-hidden="true">
              <StyledTableHeaderCellSortIconAsc />
              <StyledTableHeaderCellSortIconDesc />
            </StyledTableHeaderCellIconWrapper>
            <VisuallyHidden aria-hidden="true" id={descriptionId}>
              {I18n.t('core.table.sortableColumn')}
            </VisuallyHidden>
          </>
        )}
      </StyledTableHeaderCellSortableContainer>
    </StyledTableHeaderCell>
  )
})

export const Body = React.forwardRef<
  HTMLTableSectionElement,
  TbodyAttributes & BodyProps
>(function Body({ children, ...props }, ref) {
  return (
    <tbody ref={ref} {...props}>
      {children}
    </tbody>
  )
})

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(props, ref) {
    return <StyledCheckbox ref={ref} {...props} />
  }
)

export const GroupTitle = React.forwardRef<
  HTMLSpanElement,
  SpanAttributes & GroupTitleProps
>(function GroupTitle({ children, ...props }, ref) {
  return (
    <StyledTableGroupRowTitle ref={ref} {...props}>
      {children}
    </StyledTableGroupRowTitle>
  )
})

export const Group = React.forwardRef<
  HTMLTableRowElement,
  TrAttributes & GroupProps
>(function Group({ children, colSpan = 1000, depth = false, ...props }, ref) {
  return (
    <StyledTableGroupRow ref={ref} $depth={depth} {...props}>
      <td colSpan={colSpan}>
        <StyledTableGroupRowContent>
          <StyledTableGroupRowHeader>{children}</StyledTableGroupRowHeader>
        </StyledTableGroupRowContent>
      </td>
    </StyledTableGroupRow>
  )
})

export const BodyRow = React.forwardRef<
  HTMLTableRowElement,
  TrAttributes & BodyRowProps
>(function BodyRow({ children, overdue = false, ...props }, ref) {
  return (
    <StyledTableBodyRow ref={ref} $overdue={overdue} {...props}>
      {children}
    </StyledTableBodyRow>
  )
})

export const BodyCell = React.forwardRef<
  HTMLTableDataCellElement,
  TdAttributes & BodyCellProps
>(function BodyCell(
  { children, snugfit = false, variant = '', ...props },
  ref
) {
  return (
    <StyledTableBodyCell
      ref={ref}
      $snugfit={snugfit || variant === 'snugfit'}
      {...props}
    >
      {children}
    </StyledTableBodyCell>
  )
})

export const CurrencyCell = React.forwardRef<
  HTMLDivElement,
  DivAttributes & CurrencyCellProps
>(function CurrencyCell({ children, value, currencyOptions, ...props }, ref) {
  const I18n = useI18nContext()

  if (children) {
    return (
      <StyledTableCellText $currency ref={ref} {...props}>
        {children}
      </StyledTableCellText>
    )
  }

  const internalCurrencyOptions: CurrencyOptions = {
    locale: I18n.locale,
    currencyIsoCode: 'USD', // fallback to USD if currencyIsoCode is not provided
    ...currencyOptions,
  }
  const formatter = new CurrencyFormatter(internalCurrencyOptions)

  const internalValue = value ? formatter.formatCurrency(value) : null

  return (
    <StyledTableCellText $currency ref={ref} {...props}>
      {internalValue}
    </StyledTableCellText>
  )
})

export const DateSelectCell = React.forwardRef<
  HTMLDivElement,
  DateSelectCellProps
>(function DateSelectCell(
  {
    children,
    className,
    disabled = false,
    errorMessage,
    processing = false,
    ...props
  },
  ref
) {
  const error = Boolean(errorMessage)
  return (
    <StyledTableInlineEditContainer
      $disabled={disabled}
      $error={error}
      $size="block"
      className={cx({ disabled, error })}
    >
      {processing && (
        <StyledLoadingContainer>
          <Spinner loading={processing} size="md" />
        </StyledLoadingContainer>
      )}
      <StyledTableCellDateInput
        {...props}
        disabled={processing || disabled}
        ref={ref}
        variant={error ? 'error' : ''}
      />
      {!processing && error && (
        <StyledTableInlineEditErrorBox>
          {errorMessage}
        </StyledTableInlineEditErrorBox>
      )}
    </StyledTableInlineEditContainer>
  )
})

export const IconCell = React.forwardRef<
  HTMLDivElement,
  DivAttributes & IconCellProps
>(function IconCell({ children, ...props }, ref) {
  return (
    <StyledIconCell ref={ref} {...props}>
      {children}
    </StyledIconCell>
  )
})

export const InputCell = React.forwardRef<HTMLInputElement, InputCellProps>(
  function InputCell(
    {
      disabled = false,
      errorMessage = '',
      processing = false,
      size = 'block', // TODO FIX THIS TYPE
      ...props
    },
    ref
  ) {
    const error = Boolean(errorMessage)
    return (
      <StyledTableInlineEditContainer
        $disabled={disabled}
        $error={error}
        $size={size}
        className={cx({ disabled, error })}
      >
        {processing && (
          <StyledLoadingContainer>
            <Spinner loading={processing} size="md" />
          </StyledLoadingContainer>
        )}
        <StyledTableCellInput
          {...props}
          ref={ref}
          disabled={processing || disabled}
        />
        {!processing && error && (
          <StyledTableInlineEditErrorBox $size={size}>
            {errorMessage}
          </StyledTableInlineEditErrorBox>
        )}
      </StyledTableInlineEditContainer>
    )
  }
)

export const LinkCell = React.forwardRef<
  HTMLDivElement,
  AnchorAttributes & LinkCellProps
>(function LinkCell({ children, href = '', ...props }, ref) {
  return (
    <TextCell ref={ref}>
      <LinkElement href={href} {...props}>
        {children}
      </LinkElement>
    </TextCell>
  )
})

export const PercentCell = React.forwardRef<
  HTMLDivElement,
  DivAttributes & PercentCellProps
>(function PercentCell({ children, ...props }, ref) {
  return (
    <StyledTableCellText $percent ref={ref} {...props}>
      {children}
    </StyledTableCellText>
  )
})

export const SelectCell = React.forwardRef<
  HTMLDivElement,
  TableSelectCellProps
>(function SelectCell(
  {
    block = true,
    children,
    disabled,
    errorMessage,
    className,
    processing = false,
    ...props
  },
  ref
) {
  const error = Boolean(errorMessage)
  const hasClearIcon = !disabled && !props.loading && Boolean(props.label)
  return (
    <StyledTableInlineEditContainer
      $disabled={disabled}
      $error={error}
      $size="block"
      className={cx({ disabled, error })}
    >
      {processing && (
        <StyledLoadingContainer>
          <Spinner loading={processing} size="md" />
        </StyledLoadingContainer>
      )}
      <StyledSelectCellWrapper>
        <StyledTableCellSelect
          {...props}
          $placeholder={!props.label}
          $hasClearIcon={hasClearIcon}
          block={block}
          className={cx(tableSelectClassName, className)}
          disabled={processing || disabled}
          error={error}
          ref={ref}
        >
          {children}
        </StyledTableCellSelect>
      </StyledSelectCellWrapper>
      {!processing && errorMessage && (
        <StyledTableInlineEditErrorBox>
          {errorMessage}
        </StyledTableInlineEditErrorBox>
      )}
    </StyledTableInlineEditContainer>
  )
})

export const TextAreaCell = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaAttributes & TextAreaCellProps
>(function TextAreaCell(
  {
    disabled,
    errorMessage = '',
    processing = false,
    resizable = false,
    ...props
  },
  ref
) {
  const textareaRef =
    (ref as React.RefObject<HTMLTextAreaElement>) ||
    React.createRef<HTMLTextAreaElement>()

  const setHeight = () => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }
  const error = Boolean(errorMessage)
  return (
    <StyledTableInlineEditContainer
      $disabled={disabled}
      $error={error}
      $size="block"
      className={cx({ disabled, error })}
    >
      {processing && (
        <StyledLoadingContainer>
          <Spinner loading={processing} size="md" />
        </StyledLoadingContainer>
      )}
      <StyledTableCellTextArea
        {...props}
        $resizable={resizable}
        disabled={processing || disabled}
        onBlur={(event) => {
          setHeight()
          props.onBlur && props.onBlur(event)
        }}
        ref={textareaRef}
        resize="none"
        rows={1}
      />
      {!processing && error && (
        <StyledTableInlineEditErrorBox>
          {errorMessage}
        </StyledTableInlineEditErrorBox>
      )}
    </StyledTableInlineEditContainer>
  )
})

export const TextCell = React.forwardRef<
  HTMLDivElement,
  DivAttributes & TableTextCellProps
>(function TextCell({ children, ...props }, ref) {
  return (
    <StyledTableCellText ref={ref} {...props}>
      {children}
    </StyledTableCellText>
  )
})

Table_.displayName = 'Table'

Body.displayName = 'Table.Body'

BodyCell.displayName = 'Table.BodyCell'

BodyRow.displayName = 'Table.BodyRow'

Carat.displayName = 'Table.Carat'

Checkbox.displayName = 'Table.Checkbox'

Container.displayName = 'Table.Container'

CurrencyCell.displayName = 'Table.CurrencyCell'

DateSelectCell.displayName = 'Table.DateSelect'

Group.displayName = 'Table.Group'

GroupTitle.displayName = 'Table.GroupTitle'

Header.displayName = 'Table.Header'

HeaderCell.displayName = 'Table.HeaderCell'

HeaderRow.displayName = 'Table.HeaderRow'

IconCell.displayName = 'Table.IconCell'

InputCell.displayName = 'Table.InputCell'

LinkCell.displayName = 'Table.LinkCell'

PercentCell.displayName = 'Table.ParentCell'

TextAreaCell.displayName = 'Table.TextAreaCell'

TextCell.displayName = 'Table.TextCell'

/**

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-table--demo)

 */
export const Table = addSubcomponents(
  {
    Body,
    BodyCell,
    BodyRow,
    Carat,
    Checkbox,
    Container,
    CurrencyCell,
    DateSelectCell,
    Group,
    GroupTitle,
    Header,
    HeaderCell,
    HeaderRow,
    IconCell,
    InputCell,
    LinkCell,
    PercentCell,
    SelectCell,
    TextAreaCell,
    TextCell,
  },
  Table_
)
