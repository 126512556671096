import React from 'react'
import { useListNavigation } from '../_hooks/ListNavigation'
import { useSet } from '../_hooks/useSet'
import { isRootNode } from './Tree'
import type { LevelWise, Node, UseTreeProps } from './Tree.types'

export function useTree<T extends Node = Node>({
  rootNodes,
  expanded,
  selected,
  selectionLimit,
  onSelect,
  multiple,
  autoExpandParent,
  getChildren,
  getParentId,
  onCollapse,
  onExpand,
}: UseTreeProps<T>) {
  type VisibleNode = T & LevelWise
  type TreeNode = VisibleNode & {
    items?: (TreeNode | VisibleNode)[]
  }

  const getNodes = React.useCallback(
    (nodes: T[], node: T): T[] => {
      return [...nodes, node, ...getChildren(node).reduce(getNodes, [])]
    },
    [getChildren]
  )

  const nodes = React.useMemo(() => {
    return rootNodes.map((el: T) => getNodes([], el)).flat()
  }, [rootNodes, getNodes])

  const getNodeById = React.useCallback(
    (nodeId: T['id']): T =>
      nodes.find((item: T) => {
        return item.id === nodeId
      }) as T,
    [nodes]
  )

  const {
    has: isLoading,
    add: startLoading,
    remove: stopLoading,
  } = useSet<T['id']>()

  const handleChange = React.useCallback(
    (selectedNodes: Set<T['id']>) =>
      onSelect(Array.from(selectedNodes).map(getNodeById)),
    [getNodeById, onSelect]
  )

  const {
    collection: selectedIds,
    has: isSelected,
    add: addSelected,
    remove: removeSelected,
    setCollection: setSelected,
  } = useSet<T['id']>({
    initialValue: new Set(selected),
    onChange: handleChange,
  })

  const toggleSingleSelected = React.useCallback(
    (nodeId: T['id']) => {
      setSelected(new Set([nodeId]))
    },
    [setSelected]
  )

  const toggleSelected = React.useCallback(
    (nodeId: T['id']) => {
      if (!isSelected(nodeId)) {
        if (selectedIds.size < selectionLimit) {
          addSelected(nodeId)
        }
      } else {
        removeSelected(nodeId)
      }
    },
    [addSelected, isSelected, removeSelected, selectedIds.size, selectionLimit]
  )

  const toggleSelection = React.useMemo(
    () => (multiple ? toggleSelected : toggleSingleSelected),
    [multiple, toggleSelected, toggleSingleSelected]
  )

  const handleSelection = React.useCallback(
    (node: T) => toggleSelection(node.id),
    [toggleSelection]
  )

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    Array.from(selectedIds)
      .filter((id) => !selected.includes(id))
      .forEach(removeSelected)
    selected.filter((id) => !isSelected(id)).forEach(addSelected)
  }, [selected])
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleExpand = React.useCallback(
    async (nodeId: T['id']) => {
      try {
        startLoading(nodeId)
        await onExpand(getNodeById(nodeId))
      } finally {
        stopLoading(nodeId)
      }
    },
    [getNodeById, onExpand, startLoading, stopLoading]
  )

  const handleCollapse = React.useCallback(
    (nodeId: T['id']) => onCollapse(getNodeById(nodeId)),
    [getNodeById, onCollapse]
  )

  const getExpandedIds: (nodeIds: T['id'][], currentNode: T) => T['id'][] =
    React.useCallback(
      (nodeIds, currentNode) => {
        const parentId = getParentId(currentNode)
        if (
          rootNodes.find((rootNode) => parentId === rootNode.id) ||
          isRootNode(parentId)
        ) {
          return [...nodeIds, currentNode.id, parentId]
        } else {
          return getExpandedIds(
            [...nodeIds, currentNode.id],
            getNodeById(parentId)
          )
        }
      },
      [getNodeById, getParentId, rootNodes]
    )

  const expandedIds = React.useMemo(() => {
    if (autoExpandParent) {
      return nodes
        .filter((node) =>
          expanded.concat(Array.from(selectedIds)).includes(node.id)
        )
        .reduce(getExpandedIds, [])
    }
    return expanded
  }, [autoExpandParent, expanded, getExpandedIds, nodes, selectedIds])

  const {
    add: addExpanded,
    has: isExpanded,
    toggle: toggleExpanded,
    setCollection: setExpanded,
  } = useSet<T['id']>({
    initialValue: new Set(expandedIds),
    onAdd: handleExpand,
    onRemove: handleCollapse,
  })

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    expandedIds.filter((id) => !isExpanded(id)).forEach(addExpanded)
  }, [expandedIds])
  /* eslint-enable react-hooks/exhaustive-deps */

  const listNavigation = useListNavigation({
    circular: false,
    initialIndex: 0,
    size: 0,
  })

  const getSelectableItems = React.useCallback(
    (visibleNodes: TreeNode[], node: TreeNode): TreeNode[] => {
      const visibleItems =
        isExpanded(node.id) && !isLoading(node.id) && node.items
          ? node.items.reduce(getSelectableItems, [])
          : []
      return [...visibleNodes, node, ...visibleItems]
    },
    [isExpanded, isLoading]
  )

  const getChildrenNodes = React.useCallback(
    (nodes: TreeNode[], node: T, level: number): TreeNode[] => {
      const children = getChildren(node)

      if (children.length > 0) {
        return [
          ...nodes,
          {
            ...node,
            level,
            items: children.reduce(
              (acc, curr) => getChildrenNodes(acc, curr, level + 1),
              [] as TreeNode[]
            ),
          },
        ]
      } else {
        return [...nodes, { ...node, level }]
      }
    },
    [getChildren]
  )

  const nodesTree = React.useMemo(
    () =>
      Array.isArray(rootNodes)
        ? rootNodes.map((el: T) => getChildrenNodes([], el, 0)).flat()
        : [],
    [rootNodes, getChildrenNodes]
  )

  const visibleNodes = React.useMemo(
    () => nodesTree.reduce(getSelectableItems, []),
    [getSelectableItems, nodesTree]
  )

  const highlight = React.useCallback(
    (node: T) => {
      const index = visibleNodes.findIndex(
        (visibleNode: T) => visibleNode.id === node.id
      )
      if (index !== -1) {
        listNavigation.set(index)
      }
    },
    [listNavigation, visibleNodes]
  )

  React.useEffect(() => {
    listNavigation.setSize(visibleNodes.length)
  }, [listNavigation, visibleNodes.length])

  const isHighlighted = React.useCallback(
    (node: T) =>
      visibleNodes[listNavigation.index] &&
      visibleNodes[listNavigation.index].id === node.id,
    [listNavigation.index, visibleNodes]
  )

  const highlightedNode = React.useMemo(() => {
    return visibleNodes[listNavigation.index]
  }, [listNavigation.index, visibleNodes])

  return {
    nodes: visibleNodes,
    isExpanded: React.useCallback(
      (node: T) => isExpanded(node.id),
      [isExpanded]
    ),
    isSelected: React.useCallback(
      (node: T) => isSelected(node.id),
      [isSelected]
    ),
    isLoading: React.useCallback((node: T) => isLoading(node.id), [isLoading]),
    highlightedNode,
    isHighlighted,
    highlight,
    handleSelection,
    handleExpansion: React.useCallback(
      (node: T) => toggleExpanded(node.id),
      [toggleExpanded]
    ),
    setSelected,
    setExpanded,
    isFileLimitReached: selectedIds.size >= selectionLimit,
    listNavigation,
  }
}
