import styled, { css, keyframes } from 'styled-components'
import { StyledButton, StyledLinkButton } from '../Button/Button.styles'
import { DropdownButton } from '../Dropdown/Dropdown'
import { Flex } from '../Flex/Flex'
import type { FlexProps } from '../Flex/Flex.types'
import {
  getTypographyIntent,
  typographyWeights,
} from '../Typography/Typography.styles'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { focusable } from '../_styles/mixins'
import { getShadow } from '../_styles/shadows'
import { spacing } from '../_styles/spacing'
import type { BannerProps, BannerVariant } from './Banner.types'

const bannerLeft = 8

const bannerVariantColors = {
  attention: {
    primary: colors.yellow40,
    secondary: colors.yellow94,
  },
  error: {
    primary: colors.red50,
    secondary: colors.red98,
  },
  info: {
    primary: colors.blue50,
    secondary: colors.blue96,
  },
  UNSAFE_attention_emphasized: {
    primary: colors.orange50,
    secondary: colors.orange98,
  },
} as const

const miniBannerVariantColors = {
  attention: colors.yellow50,
  error: colors.red50,
  info: colors.blue50,
  UNSAFE_attention_emphasized: colors.green50,
}

const bannerShadow = getShadow(1, 'bottom')

const expandContent = (maxHeight: number) => keyframes`
  from {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
    min-height: 0;
    overflow-y: hidden;
  }
  to {
    padding-top: ${spacing.sm}px;
    padding-bottom: ${spacing.sm}px;
    min-height: 32px;
    max-height: ${maxHeight}px;
    overflow-y: auto;
  }
`

export const StyledBannerContainer = styled.div<
  { isExpandable: boolean } & BannerProps
>`
  align-items: center;
  border-left: ${bannerLeft}px solid;
  border-radius: ${borderRadius.md}px;
  color: ${colors.gray15};
  display: flex;
  justify-content: space-between;
  min-height: 52px;
  padding: 0 ${spacing.lg}px;

  ${bannerShadow}

  ${({ variant }) => {
    const { primary, secondary } = bannerVariantColors[variant]

    return css`
      border-left-color: ${primary};
      background-color: ${secondary};

      & ${StyledIconContainer} {
        color: ${primary};
      }
    `
  }}

  ${({ isExpandable }) =>
    isExpandable &&
    css`
      flex-direction: column;
      align-items: stretch;
      padding: 0;
    `}
`

export const StyledContent = styled.div`
  align-self: center;
  margin: ${spacing.sm}px ${spacing.lg}px;
  width: 100%;
`

export const StyledExpandableContentContainer = styled(Flex)`
  color: ${colors.gray15};
  min-height: 52px;
  padding: 0 ${spacing.lg}px;
  position: relative;
  z-index: 1;

  ${bannerShadow}
`

export const StyledExpandableContent = styled(Flex)<
  FlexProps & { isOverflowingY: boolean; expandableContentMaxHeight: number }
>`
  animation-duration: 450ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  background-color: ${colors.white};
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  overflow-y: auto;
  padding: 0 ${spacing.xl * 2 + spacing.sm}px;
  position: relative;
  z-index: 0;

  ${({ isOverflowingY }) => isOverflowingY && focusable}
  ${({ expandableContentMaxHeight }) =>
    css`
      animation-name: ${expandContent(expandableContentMaxHeight)};
    `}
`

export const StyledTitle = styled.div`
  ${getTypographyIntent('body')}
  color: ${colors.gray15};
  font-weight: ${typographyWeights.semibold};
`

export const StyledBody = styled.div`
  ${getTypographyIntent('body')}
  color: ${colors.gray15};
`

export const StyledIconContainer = styled.div`
  flex: 0 0 auto;
  margin: ${spacing.lg}px 0;
`

export const StyledActionsTrigger = styled(DropdownButton)`
  flex-shrink: 0;

  & + ${StyledButton}, & + ${StyledLinkButton} {
    margin-left: ${spacing.sm}px;
  }
`

export const StyledAction = styled.div`
  margin: ${spacing.sm}px 0;

  & + ${StyledButton}, & + ${StyledLinkButton}, & + ${StyledActionsTrigger} {
    margin-left: ${spacing.sm}px;
  }

  ${StyledButton}, ${StyledLinkButton} {
    background: rgba(0, 0, 0, 0.05);
    color: ${colors.gray15};

    &:hover {
      background: ${colors.gray85};
      color: ${colors.gray15};
    }
  }
`

function getMiniBannerIconColor({ variant }: { variant: BannerVariant }) {
  return css`
    & ${StyledIconContainer} {
      color: ${miniBannerVariantColors[variant]};
    }
  `
}

export const StyledMiniBannerContainer = styled.div<{
  variant: BannerVariant
}>`
  align-items: center;
  background-color: ${colors.gray96};
  display: flex;
  justify-content: space-between;
  padding: 0 ${spacing.md}px;

  ${getTypographyIntent('small')}

  ${getMiniBannerIconColor}

  & ${StyledContent} {
    margin: ${spacing.sm}px;
  }
`
