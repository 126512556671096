import * as React from 'react'
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js'

type Auth0ContextProps = {
  user?: {
    name: string
    email: string
  }
  isAuthenticated?: boolean
  loading?: boolean
  popupOpen?: boolean
  login: () => void
  logout: () => void
}

interface Auth0ProviderProps {
  children: React.ReactNode
  domain: string
  client_id: string
}

export const Auth0Context = React.createContext<Auth0ContextProps>({
  login: () => {},
  logout: () => {},
})
export const useAuth0 = () => React.useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  domain,
  client_id,
}: Auth0ProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<
    undefined | boolean
  >()
  const [user, setUser] = React.useState()
  const [auth0Client, setAuth0] = React.useState<undefined | Auth0Client>()
  const [loading, setLoading] = React.useState(true)
  const [popupOpen, setPopupOpen] = React.useState(false)

  React.useEffect(() => {
    const initAuth0 = async () => {
      let auth0FromHook
      try {
        auth0FromHook = await createAuth0Client({ domain, client_id })
      } catch {
        return
      }
      setAuth0(auth0FromHook)

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)
      }

      setLoading(false)
    }
    initAuth0()
  }, [domain, client_id])

  const login = async (params = {}) => {
    if (!auth0Client) return
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)

      const user = await auth0Client.getUser()
      setUser(user)
      setIsAuthenticated(true)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
  }

  const logout = () => {
    if (!auth0Client) return
    auth0Client.logout({ localOnly: true })
    location.reload()
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated:
          process.env.NODE_ENV === 'development' || isAuthenticated,
        user,
        loading,
        popupOpen,
        login,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
