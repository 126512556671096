import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const dataQa = {
  focused: 'core-radio-focused',
}

const radioButtonSize = 20

export const StyledRadioButton = styled.label<{
  $children?: React.ReactNode
  $error?: boolean
}>`
  color: ${colors.gray15};
  display: flex;
  position: relative;
  vertical-align: middle;

  & + & {
    margin-top: ${spacing.md}px;
  }

  ${({ $error }) =>
    $error &&
    css`
      ${StyledRadioButtonLabel}:before,
      ${StyledRadioButtonInput}:checked:not(:disabled) + ${StyledRadioButtonLabel}:before {
        border-color: ${colors.red50};
      }

      ${StyledRadioButtonInput}:checked:disabled + ${StyledRadioButtonLabel}:before {
        border-color: ${colors.gray85};
      }
    `}

  ${({ $children }) =>
    $children &&
    css`
      ${StyledRadioButtonLabel} {
        &:before {
          margin-right: ${spacing.md}px;
        }
      }
    `}
`

export const StyledRadioButtonLabel = styled.span`
  ${getTypographyIntent('body')};

  cursor: default;
  display: flex;

  &:before {
    background: ${colors.white};
    border-radius: 100%;
    border: 2px solid ${colors.gray70};
    content: '';
    display: inline-block;
    flex: 0;
    height: ${radioButtonSize}px;
    min-width: ${radioButtonSize}px;
    width: ${radioButtonSize}px;
  }
`

export const StyledRadioButtonInput = styled.input`
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;

  &:focus:not(:checked),
  &[data-qa=${dataQa.focused}]:not(:checked) {
    &:not(:disabled) + ${StyledRadioButtonLabel}:before {
      ${getFocus()}
    }
  }

  &:focus:checked:not(:disabled),
  &[data-qa=${dataQa.focused}]:checked:not(:disabled) {
    + ${StyledRadioButtonLabel}:before {
      box-shadow: 0 0 0 2px ${colors.blue40};
    }
  }

  &:checked + ${StyledRadioButtonLabel}:before {
    border: 6px solid ${colors.blue50};
  }

  &:disabled + ${StyledRadioButtonLabel}:before {
    border-color: ${colors.gray85};
  }

  &:disabled:checked + ${StyledRadioButtonLabel}:before {
    border-color: ${colors.blue85};
  }
`
