import React from 'react'
import { AnchorNavigationProvider } from '../AnchorNavigation/AnchorNavigationProvider'
import { DetailPage, DetailPageViewContext } from '../DetailPage/DetailPage'
import { Page } from '../PageLayout/PageLayout'
import { spacing } from '../_styles/spacing'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type {
  SettingsPageFooterProps,
  SettingsPageProps,
} from './SettingsPage.types'

const SettingsPage_ = React.forwardRef<HTMLDivElement, SettingsPageProps>(
  function SettingsPage_(
    { width = 'block', hasNavigation = true, children, ...props },
    ref
  ) {
    return (
      <DetailPageViewContext.Provider value={{ width, hasNavigation }}>
        <AnchorNavigationProvider offset={spacing.xs}>
          <Page ref={ref} {...props}>
            {children}
          </Page>
        </AnchorNavigationProvider>
      </DetailPageViewContext.Provider>
    )
  }
)

export const Footer = React.forwardRef<HTMLDivElement, SettingsPageFooterProps>(
  function Footer({ visible, ...props }, ref) {
    return visible ? <DetailPage.Footer ref={ref} {...props} /> : null
  }
)

SettingsPage_.displayName = 'SettingsPage'
Footer.displayName = 'SettingsPage.Footer'

/**

 A settings page is basically a copy of DetailPage. However, it may have some differences in a future.

  - SettingsPage
    - SettingsPage.Main
      - SettingsPage.Header
        - SettingsPage.Breadcrumbs
          - Breadcrumbs
        - SettingsPage.Banner
          - Banner
        - SettingsPage.Title
          - Title or H1
        - SettingsPage.Tabs
          - Tabs
      - SettingsPage.Body
        - SettingsPage.Title
        - SettingsPage.Banner
        - SettingsPage.Card
          - SettingsPage.Banner
          - SettingsPage.Section
            - SettingsPage.Section
          - SettingsPage.Section
      - SettingsPage.Footer or Form.SettingsPageFooter
        - SettingsPage.FooterNotation
        - SettingsPage.FooterActions
          - Button
      - Panel

 @since TBD

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-detailpage--edit)

 @see [Design Guidelines](https://design.procore.com/detail)

 */
export const SettingsPage = addSubcomponents(
  {
    Main: Page.Main,
    Header: Page.Header,
    Breadcrumbs: Page.Breadcrumbs,
    Card: DetailPage.Card,
    Banner: Page.Banner,
    Title: Page.Title,
    Heading: DetailPage.Heading,
    Tabs: Page.Tabs,
    Footer,
    FooterActions: DetailPage.FooterActions,
    FooterNotation: DetailPage.FooterNotation,
    Section: DetailPage.Section,
    Body: DetailPage.Body,
  },
  SettingsPage_
)
