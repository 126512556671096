import styled from 'styled-components'

export const arrowSize = 8

export const StyledArrow = styled.div`
  position: absolute;
  background: inherit;
  width: ${arrowSize}px;
  height: ${arrowSize}px;
  transform: rotate(45deg);
`
