import styled, { css } from 'styled-components'
import { Input } from '../Input/Input'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

export const addonRootFontSize = '14px'
export const addonRootFontFamily = 'inherit'
export const addonRootPadding = spacing.sm
export const inputDefaultXPadding = spacing.md

const incrementerWidth = 24
const inputBgColor = colors.white
const inputBorder = `${colors.gray70} solid 1px`

const StyledRootAddon = styled.span<{ disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  color: ${({ disabled }) => (disabled ? colors.gray70 : colors.gray45)};
  ${getTypographyIntent('body')};
`

export const StyledPrefix = styled(StyledRootAddon)`
  left: ${spacing.md}px;
`

export const StyledSuffix = styled(StyledRootAddon)<{
  withIncrementer: boolean
}>`
  right: ${({ withIncrementer }) =>
    withIncrementer ? spacing.md + incrementerWidth : spacing.md}px;
`

export const StyledIncrementerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1px;
  right: 1px;
  height: 100%;
`

export const StyledIncrementerButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${incrementerWidth}px;
  height: calc(50% - 1px);
  border: none;
  border-radius: 0px;
  outline: none;
  background-color: ${inputBgColor};
  padding: 0;
  cursor: pointer;

  &:first-child,
  &:last-child {
    border-left: ${inputBorder};

    &:disabled {
      border-color: ${colors.gray70};
      background-color: ${colors.gray94};
    }
  }

  &:first-child {
    border-top-right-radius: 2px;
  }

  &:last-child {
    border-top: ${inputBorder};
    border-bottom-right-radius: 2px;
  }

  svg {
    fill: ${colors.gray45};
  }

  &:hover {
    svg {
      fill: ${colors.gray10};
    }
  }

  &:disabled {
    cursor: default;

    svg {
      fill: ${colors.gray70};
    }
  }
`

export const StyledInput = styled(Input)``

export const StyledWrapper = styled.div<{
  hasIncrementer: boolean
}>`
  position: relative;
  ${({ hasIncrementer }) =>
    hasIncrementer &&
    css`
      ${StyledInput} {
        padding-right: ${spacing.md + incrementerWidth}px;
      }
    `}
`
