import { useFocusWithin } from '@react-aria/interactions'
import React from 'react'
import type { GridChildComponentProps, VariableSizeGrid } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { Spinner } from '../Spinner/Spinner'
import { Tooltip } from '../Tooltip/Tooltip'
import { useI18nContext } from '../_hooks/I18n'
import { mergeRefs } from '../_utils/mergeRefs'
import type { DivAttributes } from '../_utils/types'
import { useGridNavigation } from './ThumbnailGrid.hooks'
import {
  StyledCheckbox,
  StyledGroupCell,
  StyledGroupTitle,
  StyledSpinnerWrapper,
  StyledThumbnail,
  StyledThumbnailGrid,
  StyledVariableSizeGrid,
} from './ThumbnailGrid.styles'
import type {
  GridCellData,
  ThumbnailGridGroup,
  ThumbnailGridGroupTitle,
  ThumbnailGridItem,
  ThumbnailGridProps,
} from './ThumbnailGrid.types'

function noop() {}

const asyncNoop = () => Promise.resolve()

const titleHeight = 20
const titleTopPadding = 24
const thumbnailHeight = 138
const thumbnailWidh = 104
const defaultColumnsCount = 5
const gutterSize = 12
const columnWidth = thumbnailWidh + gutterSize
const gridLeftPadding = 24
const defaultGridWidth = gridLeftPadding + columnWidth * defaultColumnsCount
const defaultGridHeight = (thumbnailHeight + gutterSize) * 3
const getId = (item: ThumbnailGridItem) => item.id

const getGridRows = ({
  items,
  groups,
  title,
  isFetching = false,
  groupTitleFallback,
  columnsCount = defaultColumnsCount,
}: {
  items: ThumbnailGridItem[]
  groups?: ThumbnailGridGroup[]
  title?: string
  isFetching: boolean
  groupTitleFallback: string
  columnsCount: number
}) => {
  const splitToRows = (items: GridCellData[]) =>
    items.reduce((acc: GridCellData[][], item: GridCellData, index: number) => {
      const rowIndex = Math.floor(index / columnsCount)
      const row = (acc[rowIndex] = acc[rowIndex] || [])
      row.push(item)
      return acc
    }, [] as GridCellData[][])

  const splitToGroupedRows = (
    items: ThumbnailGridItem[],
    groups: ThumbnailGridGroup[]
  ) => {
    let groupedRows: GridCellData[][] = []

    const groupedThumbnails = items.reduce((acc, item) => {
      const groupId = item.groupId ?? String(item.groupId)
      const group = acc.get(groupId)
      if (group) {
        group.push(item)
      } else {
        acc.set(groupId, [item])
      }
      return acc
    }, new Map<string | number, GridCellData[]>(groups.map(({ id }) => [id, []])))

    groupedThumbnails.forEach((group, groupId) => {
      const groupRows = splitToRows(group)

      if (groupRows.length) {
        const groupTitleRow = [
          {
            title:
              groups.find(({ id }) => groupId === id)?.title ||
              groupTitleFallback,
            groupId,
          } as ThumbnailGridGroupTitle,
        ]
        groupRows.unshift(groupTitleRow)
      }

      groupedRows = [...groupedRows, ...groupRows]
    })

    return groupedRows
  }

  const setGridTitle = (rows: GridCellData[][]) => {
    if (title) {
      rows.unshift([{ title: title }])
    }
    return rows
  }

  const setLoadingIndicator = (rows: GridCellData[][]) => {
    if (isFetching) {
      rows.push([{ isFetching }])
    }
    return rows
  }

  const gridRows = groups
    ? splitToGroupedRows(items, groups)
    : splitToRows(items)

  const gridRowsWithOptionalTitle = setGridTitle(gridRows)
  const gridRowsWithOptionalLoadingIndicator = setLoadingIndicator(
    gridRowsWithOptionalTitle
  )

  return gridRowsWithOptionalLoadingIndicator
}

const filterByGroupId = (item: ThumbnailGridItem, groupId: string) => {
  return String(item.groupId) === groupId
}

const GroupCell = ({
  children,
  style,
  columnsCount,
  ...props
}: DivAttributes & { columnsCount: number }) => {
  return (
    <StyledGroupCell
      style={{
        ...style,
        left: (style?.left as number) + gridLeftPadding,
        top: (style?.top as number) + gutterSize,
        width: columnWidth * columnsCount,
      }}
      {...props}
    >
      {children}
    </StyledGroupCell>
  )
}

const ThumbnailCell = ({
  children,
  className,
  style,
  ...props
}: DivAttributes) => {
  return (
    <div
      style={{
        ...style,
        left: (style?.left as number) + gridLeftPadding,
        top: (style?.top as number) + gutterSize,
      }}
      {...props}
    >
      {children}
    </div>
  )
}

// See https://github.com/bvaughn/react-window/issues/130
const GridOuterContainer = React.forwardRef<HTMLDivElement, DivAttributes>(
  (props, ref) => <div ref={ref} tabIndex={-1} {...props} />
)

const GridInnerContainer = React.forwardRef<HTMLDivElement, DivAttributes>(
  ({ style, ...props }, ref) => (
    <div
      ref={ref}
      tabIndex={-1}
      style={{
        ...style,
        paddingLeft: gridLeftPadding,
        width: (style?.width as number) + gridLeftPadding,
      }}
      {...props}
    />
  )
)

export const ThumbnailGrid = React.forwardRef<
  HTMLDivElement,
  Omit<DivAttributes, 'onSelect'> & ThumbnailGridProps
>(
  (
    {
      items,
      selected = [],
      groups,
      title = '',
      gridWidth = defaultGridWidth,
      gridHeight = defaultGridHeight,
      onSelect = noop,
      onDeselect = noop,
      disableSelection = {
        value: false,
        tooltip: '',
      },
      isFetching = false,
      loadMoreItems = asyncNoop,
      qa,
      allowSelectAll = false,
      onSelectAll = noop,
      onDeselectAll = noop,
      onSelectAllGroup = noop,
      onDeselectAllGroup = noop,
      ...props
    },
    forwardRef
  ) => {
    const gridContainerRef = React.useRef<HTMLDivElement>(null)
    const I18n = useI18nContext()
    const gridContainer = gridContainerRef.current
    const gridRef = React.useRef<VariableSizeGrid | null>(null)
    const [gridContainerWidth, setGridContainerWidth] =
      React.useState<number>(gridWidth)
    const [columnsCount, setColumnsCount] =
      React.useState<number>(defaultColumnsCount)
    const gridInstance = gridRef.current
    let { focusWithinProps } = useFocusWithin({
      onFocusWithin: (e) => setIsGridFocused(true),
      onBlurWithin: (e) => setIsGridFocused(false),
    })

    React.useEffect(() => {
      const handleResize = () => {
        if (gridContainer?.offsetWidth) {
          const containerWidth = gridContainer.offsetWidth - gridLeftPadding
          const currentColumnsCount = Math.floor(containerWidth / columnWidth)
          setGridContainerWidth(containerWidth)
          setColumnsCount(currentColumnsCount || 1)
        }
      }

      handleResize() // Initial resize calculation

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [gridContainer])

    const rows = React.useMemo(
      () =>
        getGridRows({
          items,
          groups,
          isFetching,
          title,
          columnsCount,
          groupTitleFallback: I18n.t(
            'core.fileExplorer.ungroupedThumbnailGridGroup'
          ),
        }),
      [items, groups, title, isFetching, columnsCount]
    )

    const isTitleRow = (rowCells: GridCellData[]): boolean =>
      'title' in rowCells[0]

    const isFetchingRow = (rowCells: GridCellData[]): boolean =>
      'isFetching' in rowCells[0]

    const isNonNavigableRow = (rowCells: GridCellData[]) =>
      isTitleRow(rowCells) || isFetchingRow(rowCells)

    const rowHeights = React.useMemo(() => {
      return rows.map((rowCells, index) => {
        const titleRow = isTitleRow(rowCells)
        const titleRowIsNotFirstGridRow = titleRow && index > 0
        const rowPadding =
          (titleRowIsNotFirstGridRow ? titleTopPadding : 0) + gutterSize

        if (titleRow) {
          return titleHeight + rowPadding
        } else {
          return thumbnailHeight + rowPadding
        }
      })
    }, [rows])

    const nonNavigableRowIndices = React.useMemo(
      () =>
        rows
          .map((rowCells, index) => ({
            index,
            isNonNavigableRow: isNonNavigableRow(rowCells),
          }))
          .filter(({ isNonNavigableRow }) => isNonNavigableRow)
          .map(({ index }) => index),
      [rows]
    )

    const navigationIndicesRows = React.useMemo(
      () =>
        rows
          .filter((rowCells) => !isNonNavigableRow(rowCells))
          .map((rowCells) =>
            rowCells.map((cell) =>
              items.findIndex(
                (item) => item.id === (cell as ThumbnailGridItem).id
              )
            )
          ),
      [rows, items]
    )

    const getNumberOfNonNavigableRowsBefore = React.useCallback(
      (rowIndex: number) =>
        nonNavigableRowIndices.filter((index) => rowIndex > index).length,
      [nonNavigableRowIndices]
    )

    const getThumbnailNavigationIndex = React.useCallback(
      (rowIndex: number, cellIndex: number) => {
        return navigationIndicesRows[
          rowIndex - getNumberOfNonNavigableRowsBefore(rowIndex)
        ][cellIndex]
      },
      [navigationIndicesRows, getNumberOfNonNavigableRowsBefore]
    )

    const thumbnailNavigationIndexToRowIndexMap = React.useMemo(
      () =>
        rows.reduce((acc, rowCells, rowIndex) => {
          if (isNonNavigableRow(rowCells)) return acc

          const mappedRowCells = rowCells.reduce((acc, _, cellIndex) => {
            const navIndex = String(
              getThumbnailNavigationIndex(rowIndex, cellIndex)
            )
            acc[navIndex] = rowIndex
            return acc
          }, {} as Record<string, number>)

          return { ...acc, ...mappedRowCells }
        }, {} as Record<string, number>),
      [rows, getThumbnailNavigationIndex]
    )

    const [isGridFocused, setIsGridFocused] = React.useState(false)

    const navigation = useGridNavigation({
      indicesRows: navigationIndicesRows,
    })

    const isThumbnailSelected = (thumbnail: ThumbnailGridItem) =>
      selected.some(({ id }) => thumbnail.id === id)

    const toggle = (thumbnail: ThumbnailGridItem) => {
      if (isThumbnailSelected(thumbnail)) {
        onDeselect(thumbnail)
      } else if (!disableSelection.value) {
        onSelect(thumbnail)
      }
    }

    const itemsIds = React.useMemo(() => items.map(getId), [items])
    const selectedIds = React.useMemo(() => selected.map(getId), [selected])

    const isFullySelected = React.useMemo(() => {
      return (
        itemsIds.length > 0 && itemsIds.every((id) => selectedIds.includes(id))
      )
    }, [selected, items])

    const isPartiallySelected = React.useMemo(() => {
      return (
        selectedIds.length > 0 &&
        !isFullySelected &&
        itemsIds.some((id) => selectedIds.includes(id))
      )
    }, [selected, isFullySelected])

    const isGroupFullySelected = (groupId: string) => {
      const currentGroupItems = items.filter((item) =>
        filterByGroupId(item, groupId)
      )
      const selectedGroupItems = selected.filter((item) =>
        filterByGroupId(item, groupId)
      )

      return (
        currentGroupItems.length > 0 &&
        currentGroupItems
          .map(getId)
          .every((id) => selectedGroupItems.map(getId).includes(id))
      )
    }

    const isGroupPartiallySelected = (groupId: string) => {
      const currentGroupItems = items.filter((item) =>
        filterByGroupId(item, groupId)
      )
      const selectedGroupItems = selected.filter((item) =>
        filterByGroupId(item, groupId)
      )

      return (
        selectedGroupItems.length > 0 &&
        !isGroupFullySelected(groupId) &&
        selectedGroupItems
          .map(getId)
          .some((id) => currentGroupItems.map(getId).includes(id))
      )
    }

    const onSelectAllChange = (e: React.FormEvent<HTMLInputElement>) => {
      e.stopPropagation()
      if (!isFullySelected) {
        onSelectAll?.()
      } else {
        onDeselectAll?.()
      }
    }

    const onSelectAllGroupChange = (
      e: React.FormEvent<HTMLInputElement>,
      groupId: string
    ) => {
      e.stopPropagation()
      if (!isGroupFullySelected(groupId)) {
        onSelectAllGroup?.(groupId)
      } else {
        onDeselectAllGroup?.(groupId)
      }
    }

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
    }

    const updateNavigationPosition = (
      e: React.KeyboardEvent<HTMLDivElement>
    ): number => {
      if (e.key === 'Tab' && e.nativeEvent.shiftKey) {
        e.preventDefault()
        return navigation.left(e)
      }
      switch (e.key) {
        case 'ArrowDown':
        case 'Down':
          e.preventDefault()
          return navigation.down(e)
        case 'ArrowUp':
        case 'Up':
          e.preventDefault()
          return navigation.up(e)
        case 'ArrowLeft':
        case 'Left':
          e.preventDefault()
          return navigation.left(e)
        case 'Tab':
        case 'ArrowRight':
        case 'Right':
          e.preventDefault()
          return navigation.right(e)
        default:
          return -1
      }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isGridFocused) return

      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        toggle(items[navigation.index])
      } else {
        const newPosition = updateNavigationPosition(e)

        if (newPosition !== -1) {
          gridInstance?.scrollToItem({
            rowIndex: thumbnailNavigationIndexToRowIndexMap[newPosition],
          })
        }
      }
    }

    React.useEffect(() => {
      navigation.setIndicesRows(navigationIndicesRows)
    }, [navigationIndicesRows, navigation])

    React.useEffect(() => {
      // VariableSizeGrid caches offsets and measurements for each row index for performance purposes.
      // Method below clears cached data and triggers recalucation when a new value of search filter recomposites grid structure.
      gridInstance?.resetAfterRowIndex(0)
    }, [items, gridInstance])

    return (
      <StyledThumbnailGrid
        {...props}
        {...focusWithinProps}
        ref={mergeRefs(forwardRef, gridContainerRef)}
        data-qa={qa?.grid}
        tabIndex={0}
        onMouseDown={onMouseDown}
      >
        <InfiniteLoader
          loadMoreItems={isFetching ? asyncNoop : loadMoreItems}
          itemCount={rows.length}
          isItemLoaded={(index) => index < rows.length - 1}
          threshold={0}
        >
          {({ onItemsRendered, ref: infiniteLoaderRef }) => (
            <StyledVariableSizeGrid
              width={gridContainerWidth}
              height={gridHeight}
              rowCount={rows.length}
              rowHeight={(index) => rowHeights[index]}
              columnCount={columnsCount}
              columnWidth={() => columnWidth}
              innerElementType={GridInnerContainer}
              overscanRowCount={2}
              onItemsRendered={({
                visibleRowStartIndex,
                visibleRowStopIndex,
                overscanRowStopIndex,
                overscanRowStartIndex,
              }) => {
                onItemsRendered({
                  overscanStartIndex: overscanRowStartIndex,
                  overscanStopIndex: overscanRowStopIndex,
                  visibleStartIndex: visibleRowStartIndex,
                  visibleStopIndex: visibleRowStopIndex,
                })
              }}
              ref={mergeRefs(gridRef, infiniteLoaderRef)}
              style={{ overflow: 'hidden' }}
            >
              {({ columnIndex, rowIndex, style }: GridChildComponentProps) => {
                const cellData = rows[rowIndex][columnIndex]

                if (!cellData) return null

                if ('title' in cellData) {
                  const isGlobalTitle = rowIndex === 0
                  const groupId = String(cellData?.groupId)
                  const dataQa = isGlobalTitle
                    ? 'global-select-all'
                    : `group-${groupId}-select-all`

                  const onChangeCallback = isGlobalTitle
                    ? onSelectAllChange
                    : (e: React.FormEvent<HTMLInputElement>) =>
                        onSelectAllGroupChange(e, groupId)
                  const isChecked = isGlobalTitle
                    ? isFullySelected || isPartiallySelected
                    : isGroupPartiallySelected(groupId) ||
                      isGroupFullySelected(groupId)
                  const isIndeterminate = isGlobalTitle
                    ? isPartiallySelected
                    : isGroupPartiallySelected(groupId)

                  return (
                    <GroupCell style={style} columnsCount={columnsCount}>
                      {allowSelectAll ? (
                        <StyledCheckbox
                          data-qa={dataQa}
                          onChange={onChangeCallback}
                          checked={isChecked}
                          indeterminate={isIndeterminate}
                        >
                          <StyledGroupTitle weight="bold" color="gray15">
                            {cellData.title}
                          </StyledGroupTitle>
                        </StyledCheckbox>
                      ) : (
                        <StyledGroupTitle weight="bold" color="gray15">
                          {cellData.title}
                        </StyledGroupTitle>
                      )}
                    </GroupCell>
                  )
                }
                if ('isFetching' in cellData) {
                  return (
                    <GroupCell style={style} columnsCount={columnsCount}>
                      <StyledSpinnerWrapper>
                        <Spinner />
                      </StyledSpinnerWrapper>
                    </GroupCell>
                  )
                }

                const thumbnailNavigationIndex = getThumbnailNavigationIndex(
                  rowIndex,
                  columnIndex
                )
                const isNavigationTarget =
                  navigation.index === thumbnailNavigationIndex
                const isFocused = isGridFocused && isNavigationTarget
                const isSelected = isThumbnailSelected(cellData)
                const isDisabled = disableSelection.value && !isSelected

                return (
                  <ThumbnailCell style={style}>
                    <Tooltip
                      key={cellData.id}
                      trigger={isDisabled ? 'hover' : 'none'}
                      overlay={disableSelection.tooltip}
                      showKeys={[]}
                    >
                      <StyledThumbnail
                        data-thumbnail
                        data-qa={qa?.thumbnail?.(cellData.id)}
                        focused={isFocused}
                        selected={isSelected}
                        disabled={isDisabled}
                        caption={cellData.name}
                        src={cellData.src}
                        label={cellData.label}
                        hasCaptionPlaceholder
                        role={'checkbox'}
                        value={String(thumbnailNavigationIndex)}
                        onKeyDown={onKeyDown}
                        onChange={() => {
                          toggle(cellData)
                        }}
                        onClick={({ target }: React.MouseEvent) => {
                          const isCaptionTooltipClick = (target as HTMLElement)
                            .dataset?.captionTooltip

                          if (isCaptionTooltipClick) return

                          navigation.setIndex(thumbnailNavigationIndex)
                          toggle(cellData)
                          gridContainer?.focus()
                        }}
                      />
                    </Tooltip>
                  </ThumbnailCell>
                )
              }}
            </StyledVariableSizeGrid>
          )}
        </InfiniteLoader>
      </StyledThumbnailGrid>
    )
  }
)
