import React from 'react'
import { StyledGradient, StyledLoader } from './Loader.styles'
import type { LoaderProps } from './Loader.types'

export const minProgressValue = 0
export const maxProgressValue = 100

export const clamp = (
  value: number,
  minValue: number = minProgressValue,
  maxValue: number = maxProgressValue
) => Math.max(minValue, Math.min(maxValue, Math.floor(value)))

/**
 * @deprecatedSince 11
 * @deprecated Intended for internal library development.
 */
export const Loader: React.FC<LoaderProps> = function Loader({
  value = 0,
  animated = false,
  children,
  ...props
}) {
  const progressValue = clamp(value)

  return (
    <StyledLoader {...props} style={{ width: `${progressValue}%` }}>
      <StyledGradient $animated={animated}>{children}</StyledGradient>
    </StyledLoader>
  )
}

Loader.displayName = 'Loader'
