import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "legacy-change-logs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#legacy-change-logs",
        "aria-label": "legacy change logs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Legacy Change Logs`}</h1>
    <h2 {...{
      "id": "970-2021-01-16",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#970-2021-01-16",
        "aria-label": "970 2021 01 16 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v9.6.0...v9.7.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`9.7.0`}</a>{` (2021-01-16)`}</h2>
    <h3 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Update Icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8064",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8064`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/7563cd0f34d3d8deb3f9fb0a4c028074530691a8",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`7563cd0`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "950-2020-12-03",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#950-2020-12-03",
        "aria-label": "950 2020 12 03 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v9.4.0...v9.5.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`9.5.0`}</a>{` (2020-12-03)`}</h2>
    <h3 {...{
      "id": "features-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-1",
        "aria-label": "features 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`[GLUE-2650]`}{` Generate React icons directly (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/7716",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#7716`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/26357ebb5a723af20e736a65a89c5ebe7c4bfd06",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`26357eb`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "900-2020-10-09",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#900-2020-10-09",
        "aria-label": "900 2020 10 09 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.25.0...v9.0.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`9.0.0`}</a>{` (2020-10-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "8240-2020-09-28",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8240-2020-09-28",
        "aria-label": "8240 2020 09 28 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.23.1...v8.24.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.24.0`}</a>{` (2020-09-28)`}</h2>
    <h3 {...{
      "id": "features-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-2",
        "aria-label": "features 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`adding in error icon (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/7564",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#7564`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/7696b55f143c47be7692cdebab6021d3b2bf6964",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`7696b55`}</a>{`)`}</li>
      <li parentName="ul">{`introduce FileSelect component (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/7137",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#7137`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/7325ab403c61633062daecda024d8b76ea0e524a",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`7325ab4`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "8231-2020-09-14",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8231-2020-09-14",
        "aria-label": "8231 2020 09 14 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.23.0...v8.23.1",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.23.1`}</a>{` (2020-09-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "8183-2020-07-16",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8183-2020-07-16",
        "aria-label": "8183 2020 07 16 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.18.2...v8.18.3",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.18.3`}</a>{` (2020-07-16)`}</h2>
    <h3 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`[GLUE-2124]`}{` top left portal interrupts pointer events (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/7267",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#7267`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/d272fe650d46251dbe99f4ccb20f4b90ecca473e",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`d272fe6`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "8180-2020-07-13",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8180-2020-07-13",
        "aria-label": "8180 2020 07 13 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.17.0...v8.18.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.18.0`}</a>{` (2020-07-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "8170-2020-06-26",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8170-2020-06-26",
        "aria-label": "8170 2020 06 26 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.16.1...v8.17.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.17.0`}</a>{` (2020-06-26)`}</h2>
    <h3 {...{
      "id": "features-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-3",
        "aria-label": "features 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`adding icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/7074",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#7074`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/6bc90506939ba173ffb7b2fab5a5f2402e211755",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`6bc9050`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "8161-2020-06-25",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8161-2020-06-25",
        "aria-label": "8161 2020 06 25 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.16.0...v8.16.1",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.16.1`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "8160-2020-06-16",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8160-2020-06-16",
        "aria-label": "8160 2020 06 16 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.15.0...v8.16.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.16.0`}</a>{` (2020-06-16)`}</h2>
    <h3 {...{
      "id": "features-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-4",
        "aria-label": "features 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`core-react,core-docs,core-css:`}</strong>{` introduce FileAttacher component (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/66ee19db4f45a849760ac5bd6436d1b1bc321985",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`66ee19d`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "8150-2020-06-04",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8150-2020-06-04",
        "aria-label": "8150 2020 06 04 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.14.1...v8.15.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.15.0`}</a>{` (2020-06-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "8120-2020-05-08",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8120-2020-05-08",
        "aria-label": "8120 2020 05 08 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.11.0...v8.12.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.12.0`}</a>{` (2020-05-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "8110-2020-04-27",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8110-2020-04-27",
        "aria-label": "8110 2020 04 27 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.10.0...v8.11.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.11.0`}</a>{` (2020-04-27)`}</h2>
    <h3 {...{
      "id": "bug-fixes-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-1",
        "aria-label": "bug fixes 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency css-loader to v3.5.3 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/6405",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#6405`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/2fa21245a170eaa996ceef8898af6846a5603305",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`2fa2124`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "8100-2020-04-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8100-2020-04-10",
        "aria-label": "8100 2020 04 10 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.9.1...v8.10.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.10.0`}</a>{` (2020-04-10)`}</h2>
    <h3 {...{
      "id": "bug-fixes-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-2",
        "aria-label": "bug fixes 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency css-loader to v3.5.0 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/6253",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#6253`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/597f0be0bb4791dec283cc689c01590299f44fe0",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`597f0be`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency css-loader to v3.5.1 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/6268",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#6268`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/4fe2ed0534cb91473e07adfbc45bffcb6b7a557e",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`4fe2ed0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency css-loader to v3.5.2 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/6307",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#6307`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/30a1fe6e145944a77fb10d2058fb331f43794823",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`30a1fe6`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-5",
        "aria-label": "features 5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/6264",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#6264`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/9d316e5ffa07804f980bb1a243414095decb53d0",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`9d316e5`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "890-2020-03-26",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#890-2020-03-26",
        "aria-label": "890 2020 03 26 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.8.1...v8.9.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.9.0`}</a>{` (2020-03-26)`}</h2>
    <h3 {...{
      "id": "bug-fixes-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-3",
        "aria-label": "bug fixes 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency file-loader to v6 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/6084",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#6084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/fb0863705193739b456ce2e59a8bdd01e9e78200",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`fb08637`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "880-2020-03-05",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#880-2020-03-05",
        "aria-label": "880 2020 03 05 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.7.0...v8.8.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.8.0`}</a>{` (2020-03-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "870-2020-02-28",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#870-2020-02-28",
        "aria-label": "870 2020 02 28 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.6.0...v8.7.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.7.0`}</a>{` (2020-02-28)`}</h2>
    <h3 {...{
      "id": "bug-fixes-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-4",
        "aria-label": "bug fixes 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency file-loader to v5.1.0 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5888",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5888`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/b4cf5aa036a78e7871c78bb327d00752d2917d71",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`b4cf5aa`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "860-2020-02-18",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#860-2020-02-18",
        "aria-label": "860 2020 02 18 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.5.3...v8.6.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.6.0`}</a>{` (2020-02-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "840-2020-02-06",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#840-2020-02-06",
        "aria-label": "840 2020 02 06 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.3.0...v8.4.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.4.0`}</a>{` (2020-02-06)`}</h2>
    <h3 {...{
      "id": "bug-fixes-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-5",
        "aria-label": "bug fixes 5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update babel monorepo to v7.8.4 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5694",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5694`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/867e8795e3f9ba8490ac0e6d095134f5aff6d8dc",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`867e879`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "830-2020-01-27",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#830-2020-01-27",
        "aria-label": "830 2020 01 27 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.2.1...v8.3.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.3.0`}</a>{` (2020-01-27)`}</h2>
    <h3 {...{
      "id": "bug-fixes-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-6",
        "aria-label": "bug fixes 6 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency css-loader to v3.4.2 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5528",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5528`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/1ba5892ac746c764c29263f68cd25d63a5afe9a1",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`1ba5892`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency sass-loader to v8.0.1 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5527",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5527`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/a703bd3c991f73885e21208581410acf1be1d6cd",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`a703bd3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency sass-loader to v8.0.2 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5543",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5543`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/34b6c59b932a4731568e6f10fc5d4b8c2668e263",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`34b6c59`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "820-2020-01-09",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#820-2020-01-09",
        "aria-label": "820 2020 01 09 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.1.0...v8.2.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.2.0`}</a>{` (2020-01-09)`}</h2>
    <h3 {...{
      "id": "bug-fixes-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-7",
        "aria-label": "bug fixes 7 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency css-loader to v3.4.0 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5332",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5332`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/06c73807f043526089403c0daf50fdefbb4ab12f",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`06c7380`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency css-loader to v3.4.1 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5488",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5488`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/0e7153c93ff2945e8957115a47fb8396b04b7c64",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`0e7153c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency mini-css-extract-plugin to v0.8.1 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5426",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5426`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/8908aad17662c4168e3ca8a6a08cfc6c88d19cf9",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`8908aad`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency mini-css-extract-plugin to v0.8.2 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5427",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5427`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/2cfcdbe47baad742007d5c3cccbfce8edf50fe6d",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`2cfcdbe`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency mini-css-extract-plugin to v0.9.0 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5454",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5454`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/c57dc82aec2ea1c0cd07e4a6bc7f1461aa05c8d9",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`c57dc82`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency stylegator to v0.20.1 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5352",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5352`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/be28d5e374b80915a97254a7196641f27fe1f8d0",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`be28d5e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency webpack to v4.41.3 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5412",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5412`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/d7e92d9c5c618c1939ab3091b870abd2dc359aaf",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`d7e92d9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency webpack to v4.41.4 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5444",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5444`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/20572b0f073f66963176389820647741a56a6249",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`20572b0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency webpack to v4.41.5 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5479",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5479`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/013c39951a1872165f11d965311bc23efa986f98",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`013c399`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "810-2019-12-03",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#810-2019-12-03",
        "aria-label": "810 2019 12 03 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v8.0.1...v8.1.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.1.0`}</a>{` (2019-12-03)`}</h2>
    <h3 {...{
      "id": "bug-fixes-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-8",
        "aria-label": "bug fixes 8 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`[GLUE-1787]`}{` Disable esModules in file-loader (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5338",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5338`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/126c10f6dee0365070c671831b849b3ed3f3466f",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`126c10f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency file-loader to v4.3.0 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5287",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5287`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/af924f99f139f20b0141bd114214f4757d9eb1a4",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`af924f9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency file-loader to v5 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5309",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5309`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/8cb35a296a9809b4f468108900873fd228bbbbb2",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`8cb35a2`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "800-2019-11-15",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#800-2019-11-15",
        "aria-label": "800 2019 11 15 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v7.21.3...v8.0.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`8.0.0`}</a>{` (2019-11-15)`}</h2>
    <h3 {...{
      "id": "bug-fixes-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-9",
        "aria-label": "bug fixes 9 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update babel monorepo to v7.7.2 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5180",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5180`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/dba58dcc41de30790e5e7ccb20df964334c5f757",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`dba58dc`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "chore",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#chore",
        "aria-label": "chore permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`chore`}</h3>
    <ul>
      <li parentName="ul">{`write core-react into the dist folder instead of lib (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5191",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5191`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/3b8e863fe32d7c2b4e5eb1207c51a0938084b0c6",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`3b8e863`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-6",
        "aria-label": "features 6 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Icon renders SVG paths (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5003",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5003`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/fb3b90c6b4981732ff0fbb057f7240b7e3dddca4",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`fb3b90c`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`rename /lib/ output to /dist/`}</li>
    </ul>
    <h2 {...{
      "id": "7211-2019-10-17",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#7211-2019-10-17",
        "aria-label": "7211 2019 10 17 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v7.21.0...v7.21.1",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`7.21.1`}</a>{` (2019-10-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "7210-2019-10-16",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#7210-2019-10-16",
        "aria-label": "7210 2019 10 16 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v7.20.0...v7.21.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`7.21.0`}</a>{` (2019-10-16)`}</h2>
    <h3 {...{
      "id": "bug-fixes-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-10",
        "aria-label": "bug fixes 10 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency webpack to v4.41.1 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5024",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5024`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/79dff2aa76d09306d9d0d4de944f093dba062a1d",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`79dff2a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency webpack to v4.41.2 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/5044",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#5044`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/b63fa862bcaeb7346a20c1b876957c9bf57a4a53",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`b63fa86`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "7200-2019-10-03",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#7200-2019-10-03",
        "aria-label": "7200 2019 10 03 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v7.19.0...v7.20.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`7.20.0`}</a>{` (2019-10-03)`}</h2>
    <h3 {...{
      "id": "bug-fixes-11",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-11",
        "aria-label": "bug fixes 11 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependency webpack to v4.41.0 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/4890",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#4890`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/e727ab3",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`e727ab3`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      