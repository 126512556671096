import React from 'react'

interface FocusWithinApi {
  /**
   * @since 10.19.0
   */
  isFocusWithin: boolean
  /**
   * @since 10.19.0
   */
  containerProps: {
    onFocus: () => void
    onBlur: () => void
  }
}

export function useFocusWithin(): FocusWithinApi {
  const [isFocusWithin, setFocusWithin] = React.useState(false)

  const onBlur = React.useCallback(() => {
    if (isFocusWithin) {
      setFocusWithin(false)
    }
  }, [isFocusWithin])

  const onFocus = React.useCallback(() => {
    if (!isFocusWithin) {
      setFocusWithin(true)
    }
  }, [isFocusWithin])

  return {
    isFocusWithin,
    containerProps: {
      onFocus,
      onBlur,
    },
  }
}
