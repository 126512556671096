import { File, Folder } from '@procore/core-icons/dist'
import type { FunctionComponent, PropsWithChildren } from 'react'
import React from 'react'
import { areEqual, FixedSizeList } from 'react-window'
import { Spinner } from '../Spinner/Spinner'
import { Tooltip } from '../Tooltip'
import { useI18nContext } from '../_hooks/I18n'
import { useIntersectionObserver } from '../_hooks/IntersectionObserver'
import { useResizeObserver } from '../_hooks/ResizeObserver'
import { useTimer } from '../_hooks/Timer'
import { useVisibility } from '../_hooks/Visibility'
import { parseFilename } from '../_utils/filename'
import { mergeRefs } from '../_utils/mergeRefs'
import { useTree } from './Tree.hooks'
import {
  StyledChevron,
  StyledChevronContainer,
  StyledFilenameCaption,
  StyledIconContainer,
  StyledTree,
  StyledTreeRowContainer,
  StyledTreeRowContent,
  StyledTreeRowWrapper,
} from './Tree.styles'
import type {
  CustomDataType,
  IconContainerProps,
  Node,
  TreeDefaultNode,
  TreeNodeId,
  TreeNodeProps,
  TreeProps,
  TreeRef,
  TreeRowContainerProps,
  TreeRowProps,
  TreeRowTooltipProps,
} from './Tree.types'

const spacing = 32
const rootSpacing = 24
const defaultRowHeight = 36

// Selected nodes have a bold text, and browsers occasionally miscalculate scrollWidth by one pixel down.
// That situations might cause an issue when we get a second horizontal scrollbar.
// This padding serves as compensation to prevent the described issue.
const rowRightPadding = 12
// For most of browsers it usually varies between 12px and 20px.
// To avoid tricky cross-browser calculations of scrollbar width. The maximum width is used.
const scrollbarWidth = 20

function getPadding(level: number) {
  return rootSpacing + level * spacing
}

const intersectionThresholds = Array.from({ length: 101 }).map(
  (_, index) => index / 100
)

const tooltipDelay = 300

// TODO refactor this to not be upper snake case
export const UNSAFE_treeRootNodeId = 'ROOT_ID'

const defaultIsExpandable: NonNullable<TreeProps['isExpandable']> = (
  node: TreeDefaultNode
) => defaultGetType(node) === 'branch'
const defaultIsSelectable: NonNullable<TreeProps['isSelectable']> = (
  node: TreeDefaultNode
) => node.type === 'leaf'
const defaultGetType: NonNullable<TreeProps['getType']> = (
  node: TreeDefaultNode
) => node.type
const defaultGetParentId: NonNullable<TreeProps['getParentId']> = (
  node: TreeDefaultNode
) => node.parentId
const defaultGetLabel: NonNullable<TreeProps['getLabel']> = (
  node: TreeDefaultNode
) => node.name
function noop() {}

export function isRootNode(parentId: TreeNodeId): boolean {
  const rootValues = [UNSAFE_treeRootNodeId, null, undefined] as TreeNodeId[]
  return rootValues.includes(parentId)
}

/**

 The tree allows users to navigate between folder / file directories,
 and optionally select those files.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-tree--demo)

 @see [Design Guidelines](https://design.procore.com/tree)

 */
export const Tree = React.forwardRef(
  <T extends Node = Node, P extends CustomDataType<T> = Array<T>>(
    {
      options,
      expanded = [],
      selected = [],
      selectionLimit = Infinity,
      getRoot: getRoot_,
      getChildren: getChildren_,
      getIcon: getIconBase,
      getLabel = defaultGetLabel,
      getParentId: getParentId_ = defaultGetParentId,
      getType = defaultGetType,
      isExpandable = defaultIsExpandable,
      isSelectable = defaultIsSelectable,
      multiple = true,
      onSelect = noop,
      onCollapse = noop,
      onExpand = () => Promise.resolve(),
      rowRenderer: rowRendererBase,
      maxVisibleNodes = Infinity,
      visibleHeight,
      autoExpandParent = true,
      innerElementType,
      outerElementType,
      onKeyDown,
      children,
      ...props
    }: TreeProps<T, P>,
    ref: React.Ref<TreeRef<T>>
  ) => {
    const rowHeight = defaultRowHeight
    const wrapperRef = React.useRef<HTMLDivElement | null>(null)
    const listRef = React.useRef<FixedSizeList>(null)
    const listInnerRef = React.useRef<HTMLDivElement>(null)
    const listOuterRefRef = React.useRef<HTMLDivElement>(null)
    const [contentMaxWidth, setContentMaxWidth] = React.useState(0)

    const getParentId = React.useMemo(() => getParentId_, [])

    const defaultGetRoot = React.useCallback(
      (nodes: P): T | T[] =>
        nodes.filter((node: T) => {
          return isRootNode(getParentId(node))
        }),
      [getParentId]
    )

    const getRoot = React.useMemo(
      () => getRoot_ || defaultGetRoot,
      [defaultGetRoot]
    )

    const rootNodes = React.useMemo(() => {
      const roots = getRoot(options)
      return Array.isArray(roots) ? roots : [roots]
    }, [getRoot, options])

    React.useEffect(() => {
      if (!rootNodes.length) {
        console.warn(
          'Tree must have at least one root node. Look up `Tree#getParentId` for details.'
        )
      }
    }, [rootNodes])

    const [isTreeFocused, setIsTreeFocused] = React.useState(false)
    const [containerSize, setContainerSize] = React.useState({
      width: 0,
      height: 0,
    })

    const handleContainerResize = React.useCallback(
      (entries: ResizeObserverEntry[]) => {
        if (entries[0]) {
          const { clientHeight: height, clientWidth: width } = entries[0]
            .target as HTMLElement
          setContainerSize({ width, height })
        }
      },
      [setContainerSize]
    )

    const setResizeObserverTarget = useResizeObserver(handleContainerResize)

    const defaultGetChildren = React.useCallback(
      (node: T) =>
        Array.isArray(options)
          ? options.filter((childNode: T) => getParentId(childNode) === node.id)
          : ([] as T[]),
      [getParentId, options]
    )

    const getChildren = getChildren_ || defaultGetChildren

    const {
      nodes,
      isExpanded,
      isSelected,
      isLoading,
      highlightedNode,
      isHighlighted: isNodeHighlighted,
      highlight,
      handleSelection,
      handleExpansion,
      setExpanded: _setExpanded,
      setSelected: _setSelected,
      isFileLimitReached,
      listNavigation,
    } = useTree<T>({
      rootNodes,
      expanded,
      selected,
      selectionLimit,
      multiple,
      autoExpandParent,
      onSelect,
      onCollapse,
      onExpand,
      getChildren,
      getParentId,
    })

    const setSelected = React.useCallback(
      (selected: T | T[]) => {
        const nodes = Array.isArray(selected) ? selected : [selected]

        _setSelected(new Set(nodes.map((node) => node.id)))
      },
      [_setSelected]
    )

    const setExpanded = React.useCallback(
      (expanded: T | T[]) => {
        const nodes = Array.isArray(expanded) ? expanded : [expanded]

        _setExpanded(new Set(nodes.map((node) => node.id)))
      },
      [_setExpanded]
    )

    React.useImperativeHandle(ref, () => ({
      rootEl: wrapperRef.current,
      setSelected,
      setExpanded,
      toggleSelected: (node: T) => handleSelection(node),
      toggleExpanded: (node: T) => handleExpansion(node),
    }))

    const defaultGetIcon = React.useCallback(
      (node: T): React.ReactNode => {
        const isFolder = getType(node) === 'branch'
        return isFolder ? <Folder /> : <File />
      },
      [getType]
    )

    const getIcon = getIconBase || defaultGetIcon

    const isHighlighted = React.useCallback(
      (node: T) => isTreeFocused && isNodeHighlighted(node),
      [isNodeHighlighted, isTreeFocused]
    )

    const handleAccessibility = (e: React.KeyboardEvent<HTMLDivElement>) => {
      onKeyDown?.(e)
      const { key } = e

      switch (key) {
        case 'Enter':
          e.preventDefault()
          isSelectable(highlightedNode) && handleSelection(highlightedNode)
          break
        case 'Space Bar':
        case ' ':
          e.preventDefault()
          isExpandable(highlightedNode) && handleExpansion(highlightedNode)
          break
        case 'ArrowDown':
        case 'Down':
          e.preventDefault()
          listNavigation.increment()
          break
        case 'ArrowUp':
        case 'Up':
          e.preventDefault()
          listNavigation.decrement()
          break
      }
    }

    React.useEffect(() => {
      if (isTreeFocused) {
        listRef.current?.scrollToItem(listNavigation.index)
      }
    }, [isTreeFocused, listNavigation.index])

    const defaultRowRenderer = React.useCallback(
      (props: TreeRowProps) => <TreeRow {...props} />,
      []
    )

    React.useEffect(() => {
      const rowContentSizes: number[] = []

      const childNodes = Array.from(listInnerRef.current?.childNodes || [])
      childNodes.forEach((child) => {
        const rowContent = (child as HTMLElement).querySelector(
          '[data-row-content]'
        )

        if (rowContent) {
          const leftPadding = (child as HTMLElement).dataset.padding ?? '0'
          rowContentSizes.push(
            rowContent.scrollWidth + parseInt(leftPadding, 10) + rowRightPadding
          )
        }
      })

      const maxSizeRowContent = Math.max(...rowContentSizes) + scrollbarWidth
      setContentMaxWidth(maxSizeRowContent)
    })

    const rowRenderer = rowRendererBase || defaultRowRenderer

    const itemData = React.useMemo(
      () => ({
        nodes,
        getIcon,
        getLabel,
        getType,
        isExpandable,
        isSelectable,
        isExpanded,
        isSelected,
        isLoading,
        highlight,
        isHighlighted,
        handleSelection,
        handleExpansion,
        rowRenderer,
        isFileLimitReached,
        selectionLimit,
        isTreeFocused,
        treeContainer: wrapperRef.current as HTMLDivElement,
      }),
      [
        getIcon,
        getLabel,
        getType,
        handleExpansion,
        handleSelection,
        highlight,
        isExpandable,
        isExpanded,
        isFileLimitReached,
        isHighlighted,
        isLoading,
        isSelectable,
        isSelected,
        isTreeFocused,
        nodes,
        rowRenderer,
        selectionLimit,
      ]
    )

    if (!rootNodes) {
      console.error('Tree must have a valid root node')
      return null
    }

    return (
      <StyledTree
        {...props}
        ref={mergeRefs(wrapperRef, setResizeObserverTarget)}
        tabIndex={0}
        onFocus={() => setIsTreeFocused(true)}
        onBlur={() => setIsTreeFocused(false)}
        onKeyDown={handleAccessibility}
      >
        <FixedSizeList
          className="size-list"
          ref={listRef}
          height={
            visibleHeight ||
            Math.min(nodes.length * rowHeight, rowHeight * maxVisibleNodes)
          }
          innerRef={listInnerRef}
          outerRef={listOuterRefRef}
          itemCount={nodes.length}
          itemSize={rowHeight}
          innerElementType={innerElementType}
          outerElementType={outerElementType}
          itemData={itemData}
          width={Math.max(contentMaxWidth, containerSize.width)}
        >
          {TreeNode}
        </FixedSizeList>
      </StyledTree>
    )
  }
)

const TreeNode: React.FC<TreeNodeProps> = React.memo(
  ({
    data: { nodes, rowRenderer, highlight, isTreeFocused, ...props },
    index,
    style,
  }) => {
    const node = nodes[index]

    const isHighlighted = props.isHighlighted(node)
    const [isMouseOver, setMouseOver] = React.useState(false)

    const timer = useTimer({})
    const {
      isVisible: shouldShowTooltip,
      show: showTooltip,
      hide: hideTooltip,
    } = useVisibility({})

    React.useEffect(() => {
      if (isHighlighted && !shouldShowTooltip) {
        timer.setTimer(showTooltip, tooltipDelay)
      }

      return () => {
        if (isTreeFocused && !isHighlighted && shouldShowTooltip) {
          hideTooltip()
        }
      }
    })

    const onMouseEnter = () => {
      setMouseOver(true)
      if (!shouldShowTooltip) {
        timer.setTimer(showTooltip, tooltipDelay)
      }
      highlight(node)
    }

    const onMouseLeave = () => {
      setMouseOver(false)
      timer.cancel()
      hideTooltip()
    }

    return (
      <StyledTreeRowWrapper
        tabIndex={-1}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={style}
        data-padding={getPadding(node.level)}
      >
        {rowRenderer({
          node,
          shouldShowTooltip,
          isMouseOver,
          ...props,
        })}
      </StyledTreeRowWrapper>
    )
  },
  areEqual
)

const TreeRow: React.FC<TreeRowProps> = ({
  node,
  getIcon,
  getLabel,
  handleExpansion,
  handleSelection,
  selectionLimit,
  isFileLimitReached,
  shouldShowTooltip,
  isMouseOver,
  treeContainer,
  ...props
}) => {
  const expanderRef = React.useRef<HTMLDivElement>(null)
  const isSelected = props.isSelected(node)
  const isSelectable = props.isSelectable(node)
  const isExpandable = props.isExpandable(node)
  const isExpanded = props.isExpanded(node)
  const isLoading = props.isLoading(node)
  const isHighlighted = props.isHighlighted(node)
  const nodeType = props.getType(node)
  const rowLabel = getLabel(node)

  const onClick = React.useCallback(
    (event: React.MouseEvent) => {
      if (isSelectable && isExpandable) {
        // @ts-ignore
        expanderRef?.current?.contains(event.target)
          ? handleExpansion(node)
          : handleSelection(node)
      } else if (isSelectable) {
        handleSelection(node)
      } else if (isExpandable) {
        handleExpansion(node)
      }
    },
    [handleExpansion, handleSelection, isExpandable, isSelectable, node]
  )

  const onMouseDown = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      treeContainer.focus()
    },
    [treeContainer]
  )

  return (
    <TreeRowContainer
      level={node.level}
      onClick={onClick}
      onMouseDown={onMouseDown}
      isSelected={isSelected}
      isSelectable={isSelectable}
      isExpandable={isExpandable}
      isHighlighted={isHighlighted}
      isFileLimitReached={isFileLimitReached}
      data-qa="core-tree-row-container"
    >
      {
        // WARNING!
        // Be aware that this data attribute is used for calculations, changing this data attribute will break its operability.
      }
      <StyledTreeRowContent data-row-content>
        <TreeRowChevron
          ref={expanderRef}
          isExpandable={isExpandable}
          isExpanded={isExpanded}
        />
        <TreeRowIcon
          isLoading={isLoading}
          isSelected={isSelected}
          icon={getIcon(node)}
        />
        <TreeRowTooltip
          isSelected={isSelected}
          isSelectable={isSelectable}
          isHighlighted={isHighlighted}
          selectionLimit={selectionLimit}
          isFileLimitReached={isFileLimitReached}
          shouldShowTooltip={shouldShowTooltip}
          isMouseOver={isMouseOver}
          fileName={rowLabel}
          nodeType={nodeType}
          treeContainer={treeContainer}
        >
          <TreeRowName value={rowLabel} />
        </TreeRowTooltip>
      </StyledTreeRowContent>
    </TreeRowContainer>
  )
}

const IconContainer: React.FC<IconContainerProps> = ({
  children,
  isSelected,
  ...props
}) => (
  <StyledIconContainer $isSelected={isSelected} {...props}>
    {children}
  </StyledIconContainer>
)

const TreeRowChevron = React.forwardRef<
  HTMLDivElement,
  {
    isExpanded: boolean
    isExpandable: boolean
  }
>(({ isExpanded, isExpandable }, ref) => {
  return (
    <StyledChevronContainer ref={ref}>
      {isExpandable ? <StyledChevron $isExpanded={isExpanded} /> : null}
    </StyledChevronContainer>
  )
})

const TreeRowIcon: React.FC<{
  isSelected: boolean
  isLoading: boolean
  icon: React.ReactNode
}> = ({ isSelected, isLoading, icon }) => (
  <IconContainer isSelected={isSelected} marginLeft="sm" marginRight="sm">
    {isLoading ? <Spinner size="sm" /> : icon}
  </IconContainer>
)

const TreeRowName: React.FC<{ value: string }> = ({ value }) => (
  <StyledFilenameCaption>{value}</StyledFilenameCaption>
)

const getSelectionLimitMessage = (selectionLimit: number) => ({
  key: 'selectionLimit',
  options: {
    count: selectionLimit,
  },
})

const getUnsupportedFileTypeMessage = (fileName: string) => {
  const { extension, isFilename } = parseFilename(fileName)
  const key = isFilename ? 'specific' : 'unspecific'

  return {
    key: `unsupportedFileType.${key}`,
    options: {
      fileType: `.${extension.toUpperCase()}`,
    },
  }
}

const TreeRowTooltip: FunctionComponent<
  PropsWithChildren<TreeRowTooltipProps>
> = ({
  children,
  isHighlighted: isFocused,
  isFileLimitReached,
  isSelectable,
  isSelected,
  nodeType,
  selectionLimit,
  shouldShowTooltip,
  isMouseOver,
  fileName,
  treeContainer,
}) => {
  const I18n = useI18nContext()
  const rowRef = React.useRef<HTMLDivElement>(null)
  const isUserInteracting = isFocused || isMouseOver
  const isTooltipVisible = isUserInteracting && shouldShowTooltip && !isSelected
  const isFile = nodeType === 'leaf'
  const [isCropped, setCropped] = React.useState(false)

  const registerRowEl = useIntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (
          entry.rootBounds &&
          entry.rootBounds.left - entry.boundingClientRect.left >= 0
        ) {
          setCropped(true)
          return
        }
        setCropped(false)
      })
    },
    {
      root: treeContainer,
      rootMargin: '0px',
      threshold: intersectionThresholds,
    }
  )

  const selectionLimitMessage = getSelectionLimitMessage(selectionLimit)
  const unsupportedFileTypeMessage = getUnsupportedFileTypeMessage(fileName)

  const message = isSelectable
    ? isFileLimitReached && selectionLimitMessage
    : isFile && unsupportedFileTypeMessage

  const trigger = isTooltipVisible && !isCropped && message ? 'always' : 'none'

  return (
    <Tooltip
      trigger={trigger}
      overlay={
        message ? I18n.t(`core.tree.${message.key}`, message.options) : null
      }
      placement="top-left"
    >
      <div ref={mergeRefs(rowRef, registerRowEl)}>{children}</div>
    </Tooltip>
  )
}

const TreeRowContainer: React.FC<TreeRowContainerProps> = ({
  children,
  isHighlighted,
  isSelected,
  isSelectable,
  isExpandable,
  isFileLimitReached,
  level,
  ...props
}) => {
  return (
    <StyledTreeRowContainer
      isSelectable={
        (isSelectable &&
          ((isFileLimitReached && isSelected) || !isFileLimitReached)) ||
        isExpandable
      }
      isHighlighted={isHighlighted}
      isSelected={isSelected}
      style={{ paddingLeft: getPadding(level) }}
      {...props}
    >
      {children}
    </StyledTreeRowContainer>
  )
}
