import { css } from 'styled-components'
import type { Placement } from '../_utils/placement'

const base = 8

export function getShadow(
  strength: number = 0,
  placement: Placement = 'center'
) {
  const blur = base * strength
  const distance = blur / 4
  const opacity = (blur * 2) / 100
  const spread = -1 * (distance / 2)

  const xDelta = placement.match('(left|right)') ? distance : 0
  const yDelta = placement.match('(top|bottom)') ? distance : 0
  const xMagnitude = placement.match('left') ? -1 : 1
  const yMagnitude = placement.match('top') ? -1 : 1

  return css`
    box-shadow: ${`${xMagnitude * xDelta}px ${
      yMagnitude * yDelta
    }px ${blur}px ${spread}px hsla(200, 10%, 15%, ${opacity})`};
  `
}
