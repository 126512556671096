import type { DateTimeOptions } from '@procore/globalization-toolkit'
import {
  DateTimeFormatter,
  getStartDayOfTheWeek,
} from '@procore/globalization-toolkit'
import {
  addDays as addDaysFn,
  addMonths as addMonthsFn,
  eachDayOfInterval as eachDayFn,
  format as formatFn,
  getDate as getDateFn,
  getDay as getDayFn,
  getMonth as getMonthFn,
  getYear as getYearFn,
  isAfter as isAfterFn,
  isBefore as isBeforeFn,
  isSameDay as isSameDayFn,
  isSameMonth as isSameMonthFn,
  isToday as isTodayFn,
  isWithinInterval as isWithinRangeFn,
  startOfMonth as startOfMonthFn,
  startOfWeek,
  subDays as subDaysFn,
  subMonths as subMonthsFn,
  subYears as subYearsFn,
} from 'date-fns'
import {
  de,
  enUS as en,
  es,
  fr,
  is,
  ja,
  pl,
  ptBR,
  th,
  zhCN,
} from 'date-fns/esm/locale'
import { range, splitEvery } from 'ramda'
import type { Locale } from '../_hooks/I18n'

type DateFnsLocales = { [key in Locale]: any }

const dateFnsLocales: DateFnsLocales = {
  en: en,
  'en-CA': en,
  'fr-CA': fr,
  'fr-FR': fr,
  es: es,
  'es-ES': es,
  'en-AE': en,
  'en-AU': en,
  'en-GB': en,
  'en-SG': en,
  'th-TH': th,
  'pt-BR': ptBR,
  'ja-JP': ja,
  'is-IS': is,
  'de-DE': de,
  'zh-SG': zhCN,
  pseudo: en,
  'pl-PL': pl,
}

export const maxMonth = 12

export const minYear: number = 1000

// need to be function to make mocking of new Date works in tests.
export const getMaxYear = () => new Date().getFullYear() + 100

// rows in calendar
const calendarWeeks: number = 6

const daysInWeek: number = 7

export const daysInCalendar: number = calendarWeeks * daysInWeek

export const addDays: (date: Date, num: number) => Date = addDaysFn

export const addMonths: (date: Date, num: number) => Date = addMonthsFn

export const eachDay: (date1: Date, date2: Date) => Date[] = (date1, date2) =>
  eachDayFn({ start: date1, end: date2 })

export const format: (date: Date, formatString: string) => string = formatFn

// An entire date object has to be formed, format(month, 'MMM') breaks for month >= 10 (it just outputs 'Dec')
export const formatMonth: (month: number) => string = (month) =>
  formatFn(normalizeNewDate(2019, month, 2), 'MMM')

export const getDate: (date: Date) => number = getDateFn

export const getDates: (date: Date, locale: Locale) => Date[] = (
  date,
  locale
) => {
  const startDayOfWeek: number = getStartDayOfTheWeek(locale)

  // Shift first day appearing on the calendar based on day of the week of the first day of the month and based on the start day of the week
  const dayShift = 7 - startDayOfWeek
  const subDays = (getDayFn(startOfMonthFn(date)) + dayShift) % 7

  const start = subDaysFn(startOfMonthFn(date), subDays)
  const end = addDaysFn(start, daysInCalendar - 1)

  return eachDayFn({ start, end })
}

export const getMonth: (date: Date) => number = getMonthFn

export const getRows: (dates: Date[]) => Date[][] = splitEvery(7)

export const getYear: (date: Date) => number = getYearFn

export const isAfter: (date1: Date, date2: Date) => boolean = (date1, date2) =>
  isAfterFn(normalizeExistingDate(date1), normalizeExistingDate(date2))

export const isBefore: (date1: Date, date2: Date) => boolean = (date1, date2) =>
  isBeforeFn(normalizeExistingDate(date1), normalizeExistingDate(date2))

export const isSameDay: (date1: Date, date2: Date) => boolean = (
  date1,
  date2
) => isSameDayFn(normalizeExistingDate(date1), normalizeExistingDate(date2))

export const isSameMonth: (date1: Date, date2: Date) => boolean = isSameMonthFn

export const isToday: (date: Date) => boolean = isTodayFn

export const isWithinRange: (
  date: Date,
  start?: Date,
  end?: Date
) => boolean = (date, start, end) => {
  if (!start || !end || start > end) {
    return false
  }

  return isWithinRangeFn(normalizeExistingDate(date), {
    start: normalizeExistingDate(start),
    end: normalizeExistingDate(end),
  })
}

export const normalizeExistingDate: (day: Date) => Date = (day) =>
  normalizeNewDate(getYearFn(day), getMonthFn(day), getDateFn(day))

export const normalizeNewDate: (
  year: number,
  month: number,
  day: number,
  hour?: number,
  minute?: number,
  second?: number,
  millisecond?: number
) => Date = (
  year,
  month,
  day,
  hour = 0,
  minute = 0,
  second = 0,
  millisecond = 0
) => {
  const normalizedDate = new Date()
  normalizedDate.setFullYear(year, month, day)
  normalizedDate.setHours(hour)
  normalizedDate.setMinutes(minute)
  normalizedDate.setSeconds(second)
  normalizedDate.setMilliseconds(millisecond)

  return normalizedDate
}

interface SelectOption {
  label: string
  value: number
}

export const getMonthLabel = (month: number, locale: Locale = 'en') => {
  const date: Date = normalizeNewDate(1970, month, 1)
  const options: DateTimeOptions = {
    locale: locale || 'en',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  const formatter = new DateTimeFormatter(options)
  const monthPart = formatter
    .formatDateTimeToParts(date)
    .find((part) => part.type === 'month')!.value
  return monthPart
}

export const getMonthOptions = (locale: Locale): SelectOption[] => {
  return range(0, 12).map((value) => ({
    value,
    label: getMonthLabel(value, locale),
  }))
}

export const getYearOptions = (date: Date): SelectOption[] => {
  const start = Math.max(minYear, getYear(subYearsFn(date, 10)))

  return range(start, start + 21).map((value) => ({
    value,
    label: String(value).padStart(4, '0'),
  }))
}

export const getDayOfWeekLabel = (day: number, locale: Locale = 'en') => {
  return formatFn(addDaysFn(startOfWeek(new Date()), day), 'iiiiii', {
    locale: dateFnsLocales[locale],
  })
}

export const updateMonth = (date: Date, month: number) => {
  return normalizeNewDate(getYear(date), month, getDate(date))
}

export const updateYear = (date: Date, year: number) => {
  return normalizeNewDate(year, getMonth(date), getDate(date))
}

export const subDays: (date: Date, num: number) => Date = subDaysFn

export const subMonths: (date: Date, num: number) => Date = subMonthsFn

const CalendarHelpers = {
  addDays,
  addMonths,
  eachDay,
  format,
  formatMonth,
  getDate,
  getDates,
  getMonth,
  getMonthLabel,
  getMonthOptions,
  getRows,
  getYear,
  getYearOptions,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isToday,
  isWithinRange,
  normalizeExistingDate,
  normalizeNewDate,
  subDays,
  subMonths,
  updateMonth,
  updateYear,
}

export default CalendarHelpers
