import type React from 'react'
import type { PortalProps } from '../Portal/Portal.types'
import { useEventListener } from './EventListener'

export interface ClickOutsideConfig extends Pick<PortalProps, 'container'> {
  /**
   * @since 10.19.0
   */
  onClickOutside: (event: MouseEvent) => void
  /**
   * @since 10.19.0
   */
  refs: Array<React.RefObject<HTMLElement>>
}

export interface ClickOutsideProps extends ClickOutsideConfig {
  /**
   * @since 10.19.0
   */
  children: React.ReactElement
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
// To support MenuRef had to change to any. Could manually do <HTMLElement & MenuRef> but seems brittle
export function isEventSource(ref: React.RefObject<any>, event: Event) {
  if (
    ref?.current &&
    event &&
    (event.target instanceof HTMLElement || event.target instanceof SVGElement)
  ) {
    return ref.current.contains(event.target)
  } else {
    return false
  }
}

/**
 * @deprecatedSince 9
 * @deprecated Never officially documented/supported
 */
export function useClickOutside({
  refs = [],
  onClickOutside,
  container,
}: ClickOutsideConfig) {
  const handler = (event: MouseEvent) => {
    if (onClickOutside && !refs.find((ref) => isEventSource(ref, event))) {
      onClickOutside(event)
    }
  }

  const options = { capture: true }

  const containerDocument = container?.ownerDocument
  const scope = containerDocument?.defaultView || undefined

  useEventListener({ event: 'click', handler, options, scope: scope })

  useEventListener({ event: 'touchend', handler, options, scope: scope })
}
