import type React from 'react'

type RefCallback<T> = (newValue: T | null) => void

type RefObject<T> = React.MutableRefObject<T | null>

type ReactRef<T> = RefCallback<T> | RefObject<T> | null

export function assignRef<T>(ref: ReactRef<T>, value: T | null) {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref) {
    ref.current = value
  }
}

export function mergeRefs<T>(...refs: ReactRef<T>[]): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => assignRef(ref, value))
  }
}
