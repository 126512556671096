export function usingHookOrDefault<T, U>(
  usingHook: T | undefined,
  defaultHook: (config: U) => T
): (config: U) => T {
  return !usingHook
    ? defaultHook
    : function (config: U) {
        return usingHook
      }
}
