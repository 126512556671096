import { VariableSizeGrid } from 'react-window'
import styled from 'styled-components'
import { Checkbox } from '../Checkbox'
import { Thumbnail } from '../Thumbnail'
import { Typography } from '../Typography/Typography'
import { getEllipsis } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const StyledCheckbox = styled(Checkbox)`
  input {
    z-index: 2;
  }
`

export const StyledGroupCell = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: ${spacing.md}px;
`

export const StyledThumbnail = styled(Thumbnail)`
  display: inline-flex;

  &:focus {
    outline: none;
  }
`
// VariableSizeGrid has provided width and height as inline style, so to make it flexible and support responsive !important was added
export const StyledVariableSizeGrid = styled(VariableSizeGrid)`
  height: 100% !important;
  & > div {
    width: 100% !important;
  }
`
export const StyledGroupTitle = styled(Typography)`
  ${getEllipsis}
`

export const StyledSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const StyledThumbnailGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  outline: none;
`
