import { Clear } from '@procore/core-icons'
import React from 'react'
import { Button } from '../Button'
import { StyledActions } from '../Content/Content.styles'
import { Section } from '../Section'
import { useI18nContext } from '../_hooks/I18n'
import { useResizeObserver } from '../_hooks/ResizeObserver'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { mergeRefs } from '../_utils/mergeRefs'
import type { Props } from '../_utils/types'
import {
  SplitViewCardContextProvider,
  useSplitViewCard,
} from './SplitViewCard.context'
import {
  StyledSplitViewCardAside,
  StyledSplitViewCardBody,
  StyledSplitViewCardContainer,
  StyledSplitViewCardFooter,
  StyledSplitViewCardHeader,
  StyledSplitViewCardHeading,
  StyledSplitViewCardMain,
  StyledSplitViewCardTitle,
} from './SplitViewCard.styles'
import type { SplitViewCardProps } from './SplitViewCard.types'

const SplitViewCard_ = React.forwardRef<HTMLDivElement, SplitViewCardProps>(
  (
    { children, isClosable = true, isInitiallyOpen, onClose, ...props },
    ref
  ) => (
    <SplitViewCardContextProvider
      isInitiallyOpen={isInitiallyOpen}
      onClose={onClose}
      isClosable={isClosable}
    >
      <StyledSplitViewCardContainer ref={ref} {...props}>
        {children}
      </StyledSplitViewCardContainer>
    </SplitViewCardContextProvider>
  )
)

export const Main = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    const { open } = useSplitViewCard()

    return (
      <StyledSplitViewCardMain $open={open} ref={ref} {...props}>
        {children}
      </StyledSplitViewCardMain>
    )
  }
)

export const Panel = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    const { open } = useSplitViewCard()
    const [hasShadow, setHasShadow] = React.useState(false)

    const mainRef = React.useRef<HTMLDivElement>(null)

    const handleResize = React.useCallback((entries: ResizeObserverEntry[]) => {
      const element = entries[0]

      if (element && element.target.clientWidth <= 400) {
        setHasShadow(true)
        return
      }

      setHasShadow(false)
    }, [])

    const setResize = useResizeObserver(handleResize)

    React.useEffect(() => {
      setResize(mainRef.current)
    }, [setResize])

    return (
      <StyledSplitViewCardAside
        $open={open}
        $hasShadow={hasShadow}
        ref={mergeRefs(ref, mainRef)}
        {...props}
      >
        {children}
      </StyledSplitViewCardAside>
    )
  }
)

export const PanelHeading = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => (
    <StyledSplitViewCardHeading ref={ref} {...props}>
      {children}
    </StyledSplitViewCardHeading>
  )
)

export const PanelTitle = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    const I18n = useI18nContext()
    const { isClosable, onClose } = useSplitViewCard()

    return (
      <StyledSplitViewCardTitle ref={ref} {...props}>
        <StyledSplitViewCardHeading>{children}</StyledSplitViewCardHeading>
        {isClosable && (
          <Button
            aria-label={I18n.t('core.splitViewCard.a11y.close')}
            icon={<Clear />}
            variant="tertiary"
            onClick={onClose}
          />
        )}
      </StyledSplitViewCardTitle>
    )
  }
)

export const PanelHeader = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => (
    <StyledSplitViewCardHeader ref={ref} {...props}>
      {children}
    </StyledSplitViewCardHeader>
  )
)

export const PanelBody = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    const { setIsScrolling } = useSplitViewCard()
    const timer = React.useRef<NodeJS.Timer | null>(null)

    const handleScroll = React.useCallback(() => {
      setIsScrolling(true)

      if (timer.current) {
        clearTimeout(timer.current)
      }

      timer.current = setTimeout(() => {
        setIsScrolling(false)
      }, 100)
    }, [setIsScrolling])

    return (
      <StyledSplitViewCardBody
        ref={ref}
        {...props}
        onScroll={handleScroll}
        tabIndex={0}
      >
        {children}
      </StyledSplitViewCardBody>
    )
  }
)

export const PanelFooter = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    const { isScrolling } = useSplitViewCard()

    return (
      <StyledSplitViewCardFooter $hasShadow={isScrolling} ref={ref} {...props}>
        {children}
      </StyledSplitViewCardFooter>
    )
  }
)

const Actions = React.forwardRef<HTMLDivElement, Props>(function Actions(
  { children, ...props },
  ref
) {
  return (
    <StyledActions ref={ref} {...props}>
      {children}
    </StyledActions>
  )
})

SplitViewCard_.displayName = 'SplitViewCard'
Main.displayName = 'SplitViewCard.Main'
Panel.displayName = 'SplitViewCard.Panel'
PanelTitle.displayName = 'SplitViewCard.PanelTitle'
PanelHeader.displayName = 'SplitViewCard.PanelHeader'
PanelBody.displayName = 'SplitViewCard.PanelBody'
PanelFooter.displayName = 'SplitViewCard.PanelFooter'
Actions.displayName = 'SplitViewCard.Actions'

/**

 A SplitViewCard component is used to display additional details
 of an item from a collection inside a panel of a card.

 - SplitViewCard
   - SplitViewCard.Main
     - SplitViewCard.Section
       - Table or some other main content
   - SplitViewCard.Panel
     - SplitViewCard.PanelHeader
       - SplitViewCard.PanelTitle
         - text
       - Tabs
     - SplitViewCard.PanelBody
       - Content
     - SplitViewCard.PanelFooter
       - SplitViewCard.Actions

 @since 12.9.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/demos-splitviewcard--demo)

 */

export const SplitViewCard = addSubcomponents(
  {
    Actions,
    Main,
    Section,
    Panel,
    PanelTitle,
    PanelHeader,
    PanelBody,
    PanelFooter,
  },
  SplitViewCard_
)
