import React from 'react'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { Props } from '../_utils/types'
import {
  StyledListPage,
  StyledListPageBanner,
  StyledListPageBody,
  StyledListPageBodyContent,
  StyledListPageContentControls,
  StyledListPageControl,
  StyledListPageMain,
  StyledListPageToolHeader,
} from './ListPage.styles'
import type { ListPageControlProps, ListPageProps } from './ListPage.types'

export const ListPage_ = React.forwardRef<HTMLDivElement, ListPageProps>(
  function ListPage({ children, ...props }, ref) {
    return (
      <StyledListPage {...props} ref={ref}>
        {children}
      </StyledListPage>
    )
  }
)

export const Main = React.forwardRef<HTMLDivElement, Props>(function Main(
  { children, ...props },
  ref
) {
  return (
    <StyledListPageMain {...props} ref={ref}>
      {children}
    </StyledListPageMain>
  )
})

export const Banner = React.forwardRef<HTMLDivElement, Props>(function Banner(
  { children, ...props },
  ref
) {
  return (
    <StyledListPageBanner {...props} ref={ref}>
      {children}
    </StyledListPageBanner>
  )
})

export const ToolHeader = React.forwardRef<HTMLDivElement, Props>(
  function ToolHeader({ children, ...props }, ref) {
    return (
      <StyledListPageToolHeader {...props} ref={ref}>
        {children}
      </StyledListPageToolHeader>
    )
  }
)

export const ContentControls = React.forwardRef<HTMLDivElement, Props>(
  function ContentControls({ children, ...props }, ref) {
    return (
      <StyledListPageContentControls {...props} ref={ref}>
        {children}
      </StyledListPageContentControls>
    )
  }
)

export const Body = React.forwardRef<HTMLDivElement, Props>(function Body(
  { children, ...props },
  ref
) {
  return (
    <StyledListPageBody {...props} ref={ref}>
      <StyledListPageBodyContent>{children}</StyledListPageBodyContent>
    </StyledListPageBody>
  )
})

export const Control = React.forwardRef<HTMLDivElement, ListPageControlProps>(
  function Control(
    {
      alignRight = false,
      block = false,
      children,
      search = false,
      variant,
      ...props
    },
    ref
  ) {
    return (
      <StyledListPageControl
        {...props}
        $alignRight={alignRight || variant === 'align-right'}
        $block={block || variant === 'block'}
        $search={search || variant === 'search'}
        ref={ref}
      >
        {children}
      </StyledListPageControl>
    )
  }
)

Banner.displayName = 'ListPage.Banner'

Body.displayName = 'ListPage.Body'

ContentControls.displayName = 'ListPage.ContentControls'

Control.displayName = 'ListPage.Control'

Main.displayName = 'ListPage.Main'

ToolHeader.displayName = 'ListPage.ToolHeader'

/**
 * @deprecated This `ListPage` layout is no longer supported and it is now recommended to use a `ToolLandingPage` layout instead.
 *
 * @deprecatedSince 12.9.0
 *
 * @see https://stories.core.procore.com/?path=/story/core-react_demos-toollandingpage--demo
 */
export const ListPage = addSubcomponents(
  {
    Banner,
    Body,
    ContentControls,
    Control,
    Main,
    ToolHeader,
  },
  ListPage_
)
