import { Clear, Search as SearchIcon } from '@procore/core-icons/dist'
import { mergeProps } from '@react-aria/utils'
import React from 'react'
import { useI18nContext } from '../_hooks/I18n'
import { mergeRefs } from '../_utils/mergeRefs'
import { usingHookOrDefault } from '../_utils/usingHookOrDefault'
import { useSearch } from './Search.hooks'
import {
  StyledClearButton,
  StyledInput,
  StyledSearch,
  StyledSearchButton,
} from './Search.styles'
import type { SearchProps } from './Search.types'

/**

 Our users use search to find items on a tool’s landing page or, in forms, users
 use a search typeahead to find information to associate with the form.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-search--demo)

 @see [Design Guidelines](https://design.procore.com/search-component)

 */
export const Search = React.forwardRef<HTMLInputElement, SearchProps>(
  function Search(
    {
      onChange = () => {},
      onClear = () => {},
      onSubmit = () => {},
      usingHook,
      value = '',
      ...props
    },
    ref
  ) {
    const i18n = useI18nContext()
    const search = usingHookOrDefault(
      usingHook,
      useSearch
    )({
      initialValue: value,
      onChange,
      onClear,
      onSubmit,
    })

    const inputRef = React.useRef<HTMLInputElement>(null)

    const setFocusToInput = () => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }

    return (
      <StyledSearch>
        <StyledInput
          {...mergeProps(props, search.domHandlers)}
          data-qa="core-search-input"
          ref={mergeRefs(ref, inputRef)}
          value={search.value}
        />
        {!search.isEmpty && (
          <StyledClearButton
            aria-label={i18n.t('core.search.clear')}
            data-qa="core-search-clear-icon"
            icon={<Clear />}
            size="sm"
            variant="tertiary"
            disabled={props.disabled}
            onClick={() => {
              search.clear()

              setFocusToInput()
            }}
            tabIndex={-1} // TODO revisit this accessibility
          />
        )}
        {
          <StyledSearchButton
            aria-label={i18n.t('core.search.goSearch')}
            data-qa="core-search-search-icon"
            icon={<SearchIcon />}
            size="sm"
            variant="tertiary"
            disabled={props.disabled}
            onClick={() => {
              search.submit()

              setFocusToInput()
            }}
            tabIndex={-1} // TODO revisit this accessibility
          />
        }
      </StyledSearch>
    )
  }
)

Search.displayName = 'Search'
