import type {
  CurrencyDisplay,
  CurrencyOptions,
} from '@procore/globalization-toolkit'
import { formatCurrencyToParts } from '@procore/globalization-toolkit'
import { findIndex } from 'ramda'
import React from 'react'
import { useI18nContext } from '../_hooks/I18n'
import { spacing } from '../_styles/spacing'
import { mergeRefs } from '../_utils/mergeRefs'
import {
  defaultCurrencyFillDecimalScale,
  defaultCurrencyInputPrefix,
} from './NumberInput.constants'
import { useNumberFormat, usePrevious } from './NumberInput.hooks'
import {
  addonRootFontFamily,
  addonRootFontSize,
  addonRootPadding,
  inputDefaultXPadding,
  StyledIncrementerButton,
  StyledIncrementerWrapper,
  StyledInput,
  StyledPrefix,
  StyledSuffix,
  StyledWrapper,
} from './NumberInput.styles'
import type {
  CurrencyInputProps,
  CurrencyProps,
  CursorPosition,
  NumberInputProps,
  PressedKey,
} from './NumberInput.types'
import {
  backspaceKey,
  commaKey,
  controlKey,
  copyKey,
  defaultLocale,
  deleteKey,
  emptyString,
  format,
  getNewCursorPosition,
  getSeparators,
  highlightAllKey,
  identicalLocales,
  identifyPressedKey,
  maxDigitLength,
  metaKeyIdentifier,
  minus,
  numericKeypadKey,
  periodKey,
  prepareValueToFormatting,
  toFixed,
  unformat,
} from './NumberInput.utils'

function noop() {}

const PlusIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.5 0H3.5V3.5H0V4.5H3.5V8H4.5V4.5H8V3.5H4.5V0Z" />
  </svg>
)

const MinusIcon = () => (
  <svg
    width="8"
    height="2"
    viewBox="0 0 8 2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.5 0.5H3.5H0V1.5H3.5H4.5H8V0.5H4.5Z" />
  </svg>
)

/**

 The number input allows users to add numbers to a given form or table.
 The currency input will be used for data with a monetary value.
 Both components allow characters 0-9, periods, and commas. In order to avoid
 the loss of precision, the number length is limited by 15 digits.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-numberinput--demo-number-input)

 @see [Design Guidelines](https://design.procore.com/number-and-currency-input)

 */
export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      decimalScale = true,
      defaultValue,
      disabled = false,
      error = false,
      fillDecimalScale = 'none',
      onChange: _onChange,
      onKeyDown: _onKeyDown = noop,
      onBlur: _onBlur = noop,
      prefix = emptyString,
      readOnly,
      step = 0,
      style,
      suffix = emptyString,
      value,
      qa,
      ...props
    },
    ref
  ) => {
    const I18n = useI18nContext()
    const contextLocale = I18n.locale || defaultLocale
    const inputRef = React.useRef<HTMLInputElement>(null)

    const {
      decimalScale: verifiedDecimalScale,
      fillDecimalScaleOnBlur,
      fixedDecimalScale,
      formatValue,
      getValueLengthInfo,
      locale,
    } = useNumberFormat({
      decimalScale,
      fillDecimalScale,
      locale: contextLocale,
    })

    const isControlled = value !== undefined
    const isControlledWithoutOnChangeHandlerAndReadOnlyAttribute =
      isControlled && !_onChange && !readOnly
    const hasIncrementer = step > 0 && !readOnly

    const { group, decimal } = React.useMemo(
      () => getSeparators(locale),
      [locale]
    )
    const decimalSeparatorKey = React.useMemo(() => {
      return decimal === periodKey ? periodKey : commaKey
    }, [decimal])

    const inputPadding = React.useMemo((): {
      left: number
      right: number | null
    } => {
      const fontContext = `${addonRootFontSize} "${addonRootFontFamily}"`

      if (prefix || suffix) {
        const element = document.createElement('canvas')
        const context = element?.getContext('2d')

        if (context) {
          context.font = fontContext

          const extraBasePadding = inputDefaultXPadding + addonRootPadding

          const suffixPadding =
            extraBasePadding + Math.ceil(context.measureText(suffix).width)

          const getRightPadding = () => {
            if (hasIncrementer && suffix) {
              return suffixPadding + spacing.xl
            }
            if (suffix) {
              return suffixPadding
            }
            return null
          }

          return {
            left: prefix
              ? extraBasePadding + Math.ceil(context.measureText(prefix).width)
              : inputDefaultXPadding,
            right: getRightPadding(),
          }
        }
      }

      return {
        left: inputDefaultXPadding,
        right: null,
      }
    }, [prefix, suffix, hasIncrementer])

    const getValueChange = React.useCallback(
      (value: string) => {
        const { parsedNumber } = unformat(locale, value)
        return {
          value,
          parsedNumber: isNaN(parsedNumber) ? null : parsedNumber,
        }
      },
      [locale]
    )

    const externalOnChange = React.useCallback(
      (value: string) => _onChange && _onChange(getValueChange(value)),
      [_onChange, getValueChange]
    )

    const [inputValue, setInputValue] = React.useState(
      formatValue(defaultValue)
    )
    const [prevValue, setPrevValue] = React.useState(
      isControlled ? formatValue(value) : inputValue
    )
    const [prevCursorPosition, setPrevCursorPosition] =
      React.useState<CursorPosition>({
        selectionStart: 0,
        selectionEnd: 0,
        selection: emptyString,
      })
    const [pressedKey, setPressedKey] = React.useState()
    const prevLocale = usePrevious(locale)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useLayoutEffect(() => {
      const formattedValue = formatValue(value)
      if (isControlled && inputValue !== formattedValue) {
        setInputValue(formattedValue)
      }

      if (isControlledWithoutOnChangeHandlerAndReadOnlyAttribute) {
        console.error(
          `Warning: Failed prop type: You provided a \`value\` prop to a form field without an \`onChange\` handler.
        This will render a read-only field. If the field should be mutable use \`defaultValue\`.
        Otherwise, set either \`onChange\` or \`readOnly\`.`
        )
      }
    }, [value])

    React.useEffect(() => {
      if (prevLocale && !identicalLocales(prevLocale, locale)) {
        setInputValue((prev) => {
          const newValue = formatValue(unformat(prevLocale, prev).parsedNumber)
          externalOnChange(newValue)
          return newValue
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevLocale, formatValue])

    React.useLayoutEffect(() => {
      const displayValue = inputRef.current?.value || ''
      if (!_onChange || !displayValue) {
        return
      }
      const formattedValue = formatValue(value)

      if (displayValue !== formattedValue) {
        const lastChar = displayValue[displayValue.length - 1]
        const lastCharIsPending = [periodKey, commaKey].includes(lastChar)

        const decimals = displayValue.split(decimal)[1] || ''
        const lastDecimalIsZero =
          decimals && decimals[decimals.length - 1] === '0'

        const onlyMinusDisplayed = displayValue === minus

        // a client state and display mismatch is only valid when
        if (lastCharIsPending || lastDecimalIsZero || onlyMinusDisplayed) {
          return
        }

        // otherwise sync display with client state
        setInputValue(formattedValue)
      }
    })

    const getNewValueAndCursorPosition = (
      value: string,
      pressedKey: PressedKey
    ) => {
      const preparedValue = prepareValueToFormatting(
        decimal,
        decimalSeparatorKey,
        group,
        pressedKey,
        prevCursorPosition,
        prevValue,
        verifiedDecimalScale,
        value
      )
      const newValue = formatValue(preparedValue)

      return {
        newValue,
        newCursorPosition: getNewCursorPosition(
          decimal,
          decimalSeparatorKey,
          group,
          newValue,
          pressedKey,
          prevCursorPosition,
          prevValue,
          value,
          verifiedDecimalScale,
          locale,
          fillDecimalScale
        ),
      }
    }

    const adjustCursor = (newPosition: number) => {
      inputRef.current?.setSelectionRange(newPosition, newPosition)
      // Mobile Chrome adjustment
      setTimeout(
        () => inputRef.current?.setSelectionRange(newPosition, newPosition),
        0
      )
    }

    const makeStep = (down = false) => {
      const getSignificantPrecision = (fraction: string) => {
        const reversedFraction = fraction.split(emptyString).reverse()
        const indexOfLastSignificantFractionDigit = findIndex(
          (i) => i !== '0',
          reversedFraction
        )

        return indexOfLastSignificantFractionDigit > -1
          ? reversedFraction.slice(indexOfLastSignificantFractionDigit).length
          : 0
      }

      const { parsedNumber, fraction } = unformat(locale, inputValue)
      const { fraction: stepFraction } = unformat(locale, step)

      const prevValuePrecision = fraction.length || 0
      const prevValueSignificantPrecision = getSignificantPrecision(fraction)
      const rawStepPrecision = stepFraction.length || 0
      const rawStepPrecisionExceedsDecimalScale =
        rawStepPrecision > verifiedDecimalScale
      const stepPrecision = rawStepPrecisionExceedsDecimalScale
        ? verifiedDecimalScale
        : rawStepPrecision

      if (rawStepPrecisionExceedsDecimalScale) {
        console.warn(
          `WARNING: Step's value was rounded since its scale (${rawStepPrecision}) exceeds the decimal scale (${verifiedDecimalScale}).`
        )
      }

      const diff = toFixed(step, stepPrecision) * (down ? -1 : 1)
      const precision = Math.max(
        stepPrecision,
        fixedDecimalScale ? verifiedDecimalScale : prevValuePrecision
      )
      const significantPrecision = Math.max(
        prevValueSignificantPrecision,
        stepPrecision
      )
      const calculated = inputValue
        ? ((parsedNumber || 0) + diff).toFixed(significantPrecision)
        : diff

      const { isSafeLength, isSafeIntegerLength } = getValueLengthInfo(
        calculated,
        true
      )

      const rejectStepByLengthLimit = !isSafeLength || !isSafeIntegerLength

      if (rejectStepByLengthLimit) {
        console.warn(
          `WARNING: Incrementer's step was not fulfilled since result oversteps input ${maxDigitLength}-digit limit and can't be presented without a loss of precision.`
        )
        return
      }

      const formatted = format(locale, precision, 'always', calculated, true)

      setInputValue(formatted)
      externalOnChange(formatted)
    }

    const internalOnKeyDown = (e: any) => {
      const {
        ctrlKey,
        metaKey,
        key,
        target: { value, selectionStart, selectionEnd },
      } = e

      const selection = value.slice(selectionStart, selectionEnd)

      setPressedKey(key)
      setPrevCursorPosition({
        selectionStart,
        selectionEnd,
        selection,
      })
      setPrevValue(value)

      const preserveDecimalSeparator = () => {
        const decimalBackspaced =
          !selection &&
          [backspaceKey].includes(key) &&
          value[selectionEnd - 1] === decimal

        const decimalDeleted =
          !selection &&
          [deleteKey, numericKeypadKey].includes(key) &&
          value[selectionEnd] === decimal

        const nonReplacingKeys =
          [metaKeyIdentifier, controlKey, highlightAllKey, copyKey].includes(
            key
          ) &&
          (ctrlKey || metaKey)

        const decimalReplacedAsSelection =
          selection &&
          selection.includes(decimal) &&
          selection.length < value.length &&
          !nonReplacingKeys

        const preserveDecimal =
          decimalBackspaced || decimalDeleted || decimalReplacedAsSelection

        if (preserveDecimal) {
          const base = decimalReplacedAsSelection
            ? selectionStart
            : selectionEnd

          const shift = (decimalBackspaced && -1) || (decimalDeleted && 1) || 0

          const cursorPosition = base + shift

          e.preventDefault()
          adjustCursor(cursorPosition)
        }
      }

      const isDecimalSeparatorPreserved = () => {
        const { safeIntegerLength, integerLength, fractionLength } =
          getValueLengthInfo(value)

        const decimalSeparatorCanNotBeRemoved =
          safeIntegerLength - integerLength < fractionLength

        return (
          (fillDecimalScaleOnBlur && decimalSeparatorCanNotBeRemoved) ||
          fixedDecimalScale
        )
      }

      if (isDecimalSeparatorPreserved()) {
        preserveDecimalSeparator()
      }

      const handleIncrementer = () => {
        switch (key) {
          case 'ArrowUp':
          case 'Up':
            e.preventDefault()
            makeStep()
            break
          case 'ArrowDown':
          case 'Down':
            e.preventDefault()
            makeStep(true)
            break
        }
      }

      if (hasIncrementer) {
        handleIncrementer()
      }

      _onKeyDown(e)
    }

    const internalOnChange = (e: any) => {
      if (isControlledWithoutOnChangeHandlerAndReadOnlyAttribute) return

      const {
        target: { value },
        nativeEvent: { inputType },
      } = e

      const { newValue, newCursorPosition } = getNewValueAndCursorPosition(
        value,
        identifyPressedKey(
          decimalSeparatorKey,
          inputType,
          pressedKey,
          prevValue,
          value
        )
      )
      // Prevent cursor flickering
      e.target.value = newValue

      setInputValue(newValue)
      adjustCursor(newCursorPosition)

      if (newValue !== prevValue) {
        externalOnChange(newValue)
      }
    }

    const internalOnBlur = (e: any) => {
      const { integer, fraction } = unformat(locale, inputValue)

      const shouldValueBeCleared =
        inputValue === minus ||
        inputValue === decimal ||
        inputValue === `${minus}${decimal}`
      const shouldIntegerPartBeFilled =
        fraction.length > 0 && (integer === emptyString || integer === minus)
      const isDecimalSeparatorRedundant =
        verifiedDecimalScale > 0 &&
        inputValue[inputValue.length - 1] === decimal &&
        !fillDecimalScaleOnBlur
      const isDecimalScaleNotFilled = verifiedDecimalScale - fraction.length > 0
      const shouldDecimalScaleBeFilled =
        fillDecimalScaleOnBlur && isDecimalScaleNotFilled

      if (
        shouldValueBeCleared ||
        shouldIntegerPartBeFilled ||
        isDecimalSeparatorRedundant ||
        shouldDecimalScaleBeFilled
      ) {
        const getNewValue = () => {
          if (shouldValueBeCleared) {
            return emptyString
          }
          if (shouldIntegerPartBeFilled) {
            const numberOfZerosToPad = verifiedDecimalScale - fraction.length
            return `${integer}0${decimal}${
              shouldDecimalScaleBeFilled
                ? fraction.concat(
                    Array.from({ length: numberOfZerosToPad }, (_) => '0').join(
                      emptyString
                    )
                  )
                : fraction
            }`
          }
          if (isDecimalSeparatorRedundant) {
            return inputValue.replace(
              new RegExp(`[${decimal}]`, 'g'),
              emptyString
            )
          }
          if (shouldDecimalScaleBeFilled) {
            return format(locale, verifiedDecimalScale, 'always', inputValue)
          }

          return inputValue
        }
        const newValue = getNewValue()
        setInputValue(newValue)
        externalOnChange(newValue)
      }

      _onBlur(e)
    }

    return (
      <StyledWrapper hasIncrementer={hasIncrementer} data-qa={qa?.wrapper}>
        <StyledInput
          value={inputValue}
          disabled={disabled}
          error={error}
          onKeyDown={internalOnKeyDown}
          onChange={internalOnChange}
          onBlur={internalOnBlur}
          inputMode="numeric"
          readOnly={readOnly}
          style={{
            ...style,
            ...{
              paddingLeft: `${inputPadding.left}px`,
              paddingRight: `${inputPadding.right}px`,
            },
          }}
          {...props}
          ref={mergeRefs(ref, inputRef)}
        />
        {prefix && <StyledPrefix disabled={disabled}>{prefix}</StyledPrefix>}
        {suffix && (
          <StyledSuffix withIncrementer={hasIncrementer} disabled={disabled}>
            {suffix}
          </StyledSuffix>
        )}
        {hasIncrementer && (
          <StyledIncrementerWrapper>
            <StyledIncrementerButton
              aria-hidden="true"
              type="button"
              disabled={disabled}
              tabIndex={-1}
              data-qa={qa?.increment}
              onClick={() => makeStep()}
            >
              <PlusIcon />
            </StyledIncrementerButton>
            <StyledIncrementerButton
              aria-hidden="true"
              type="button"
              disabled={disabled}
              tabIndex={-1}
              data-qa={qa?.decrement}
              onClick={() => makeStep(true)}
            >
              <MinusIcon />
            </StyledIncrementerButton>
          </StyledIncrementerWrapper>
        )}
      </StyledWrapper>
    )
  }
)

NumberInput.displayName = 'NumberInput'

export const getCurrencyProps = (
  locale: string,
  _decimalScale: NumberInputProps['decimalScale'],
  symbol?: string,
  currencyIsoCode?: string,
  currencyDisplay?: CurrencyDisplay
): CurrencyProps => {
  const currencyOptions: CurrencyOptions = {
    locale,
    currencyIsoCode: currencyIsoCode || 'USD',
    currencyDisplay: currencyDisplay || 'narrowSymbol',
  }
  const parts = formatCurrencyToParts(1, currencyOptions)

  const formatterDecimalScale =
    parts.find((part) => part.type === 'fraction')?.value.length || 0
  const decimalScale = _decimalScale || formatterDecimalScale

  const placement =
    parts.findIndex((object) => {
      return object.type === 'currency'
    }) <
    parts.findIndex((part) => {
      return part.type === 'integer'
    })
      ? 'prefix'
      : 'suffix'

  const currencyIsoSymbol = currencyIsoCode
    ? parts.find((part) => part.type === 'currency')?.value.toString()
    : undefined

  const currencyProps: CurrencyProps =
    placement === 'prefix'
      ? {
          decimalScale: decimalScale as NumberInputProps['decimalScale'],
          prefix: currencyIsoSymbol || symbol || '',
        }
      : {
          decimalScale: decimalScale as NumberInputProps['decimalScale'],
          suffix: currencyIsoSymbol || symbol || '',
        }

  return currencyProps
}

export const CurrencyInput = React.forwardRef<
  HTMLInputElement,
  CurrencyInputProps
>(
  (
    {
      currencyDisplay,
      currencyIsoCode,
      decimalScale: _decimalScale,
      fillDecimalScale = defaultCurrencyFillDecimalScale,
      prefix = defaultCurrencyInputPrefix,
      suffix,
      ...props
    },
    ref
  ) => {
    const I18n = useI18nContext()
    const contextLocale = props.locale || I18n.locale || defaultLocale

    const currencyProps: CurrencyProps = getCurrencyProps(
      contextLocale as string,
      _decimalScale,
      prefix || suffix,
      currencyIsoCode,
      currencyDisplay
    )

    return (
      <NumberInput
        fillDecimalScale={fillDecimalScale}
        {...props}
        {...currencyProps}
        ref={ref}
      />
    )
  }
)

CurrencyInput.displayName = 'CurrencyInput'
