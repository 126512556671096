import { Clear } from '@procore/core-icons'
import React from 'react'
import { Button } from '../Button/Button'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type {
  ButtonAttributes,
  DivAttributes,
  Props,
  SpanAttributes,
} from '../_utils/types'
import { StyledFilterToken, StyledLabel } from './FilterToken.styles'
import type { FilterTokenProps } from './FilterToken.types'

const Parent = React.forwardRef<
  HTMLDivElement,
  DivAttributes & FilterTokenProps
>(function FilterToken({ children, focused = false, ...props }, ref) {
  return (
    <StyledFilterToken {...props} ref={ref} $focused={focused}>
      {children}
    </StyledFilterToken>
  )
})

const Remove = React.forwardRef<HTMLButtonElement, ButtonAttributes & Props>(
  function TokenRemove({ children, ...props }, ref) {
    const i18n = useI18nContext()

    return (
      <Button
        aria-label={i18n.t('core.token.remove')}
        ref={ref}
        {...props}
        variant="tertiaryContrast"
        size="sm"
        style={{ ...props.style, marginLeft: '6px' }}
        icon={<Clear />}
      />
    )
  }
)

const Label = React.forwardRef<HTMLSpanElement, SpanAttributes & Props>(
  function Label({ children, ...props }, ref) {
    return (
      <StyledLabel ref={ref} {...props}>
        {children}
      </StyledLabel>
    )
  }
)

Parent.displayName = 'FilterToken'

Remove.displayName = 'FilterToken.Remove'

Label.displayName = 'FilterToken.Label'

export const FilterToken = addSubcomponents(
  {
    Label,
    Remove,
  },
  Parent
)
