import type { MiddlewareData } from '@floating-ui/react-dom'
import React from 'react'
import { arrowSize, StyledArrow } from './OverlayArrow.styles'
import type { OverlayPlacement } from './useOverlay'

interface ArrowProps {
  placement: OverlayPlacement
  x?: number
  y?: number
  arrow: MiddlewareData['arrow']
}

export const Arrow = React.forwardRef<HTMLDivElement, ArrowProps>(
  function Arrow({ placement, arrow }, ref) {
    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]]

    const arrowX = arrow?.x ?? null
    const arrowY = arrow?.y ?? null

    return (
      <StyledArrow
        ref={ref}
        style={{
          position: 'absolute',
          left: arrowX !== null ? `${arrowX}px` : undefined,
          top: arrowY !== null ? `${arrowY}px` : undefined,
          right: '',
          bottom: '',
          [staticSide as string]: `-${arrowSize / 2}px`,
        }}
        data-overlay-arrow
      />
    )
  }
)
