import { Button } from '@procore/core-react'
import theme from 'prism-react-renderer/themes/github'
import * as React from 'react'
import {
  LiveEditor,
  LiveError,
  LivePreview,
  LiveProvider,
  LiveProviderProps,
} from 'react-live'
import styled, { css } from 'styled-components'

interface CodeLiveProps extends LiveProviderProps {
  children?: string
}

interface EditableView {
  editable: boolean
}

const ContainerView = styled.div<EditableView>`
  border: ${({ editable }) => (editable ? '1px solid #d9d9d9 !important' : '')};
  display: flex;
  flex-direction: column;
  overflow: ${({ editable }) => (editable ? 'auto' : '')};
  width: 100%;
`

const EditorView = styled.div`
  width: 100%;

  textarea {
    z-index: 1;
  }

  > div pre {
    border-top: 1px solid #d9d9d9 !important;
  }
`

const PreviewView = styled.div<EditableView>`
  margin: 16px 0;
  padding: 0;

  width: 100%;

  ${({ editable }) =>
    editable &&
    css`
      margin: 0;
      padding: 16px;
    `}
`

const ErrorView = styled.div`
  background: red;
`

const ButtonView = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    border-top: 0;
  }
`

interface CodeLiveProps extends LiveProviderProps {
  editable?: boolean
}

export const CodeLive: React.FC<CodeLiveProps> = ({
  children,
  editable,
  language,
  scope = {},
}: CodeLiveProps) => {
  const [editorDisplayed, setEditorDisplayed] = React.useState(false)

  return (
    <React.Fragment>
      <LiveProvider
        code={children ? children.trim() : ''}
        language={language}
        scope={scope}
        theme={theme}
      >
        <ContainerView editable={editable}>
          <PreviewView editable={editable}>
            <LivePreview />
          </PreviewView>

          {editorDisplayed ? (
            <EditorView>
              <LiveEditor />
            </EditorView>
          ) : null}
        </ContainerView>

        <ErrorView>
          <LiveError />
        </ErrorView>
      </LiveProvider>
      {editable ? (
        <ButtonView>
          <Button
            onClick={() => setEditorDisplayed(!editorDisplayed)}
            variant="secondary"
          >
            {'</>'}
          </Button>
        </ButtonView>
      ) : null}
    </React.Fragment>
  )
}
