import styled from 'styled-components'
import type { BadgeLabelProps, BadgeProps } from './Badge.types'

export const StyledBadge = styled.div<BadgeProps>`
  display: inline-block;
  position: relative;
`

export const StyledBadgeLabel = styled.span<BadgeLabelProps>`
  bottom: 100%;
  position: absolute;
  right: 0;
  transform: translateX(50%) translateY(50%);
`
