import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'
import { colors } from '../_styles/colors'
import type { Intent, TypographyProps, Weight } from './Typography.types'

export interface StyledTypographyProps extends Omit<TypographyProps, 'color'> {
  $color?: TypographyProps['color']
}

export const typographyWeights: {
  [key in Weight]: number
} = {
  regular: 400,
  semibold: 600,
  bold: 700,
}

export const typographyLineHeight: Record<Intent, number> = {
  small: 16,
  body: 20,
  h1: 32,
  h2: 28,
  h3: 24,
  label: 20,
}

const body = css`
  font-family: inherit;
  font-size: 14px;
  font-weight: ${typographyWeights.regular};
  letter-spacing: 0.15px;
  line-height: ${typographyLineHeight.body}px;
`

const h1 = css`
  font-family: inherit;
  font-size: 24px;
  font-weight: ${typographyWeights.bold};
  letter-spacing: 0.15px;
  line-height: ${typographyLineHeight.h1}px;
`

const h2 = css`
  font-family: inherit;
  font-size: 20px;
  font-weight: ${typographyWeights.semibold};
  letter-spacing: 0.15px;
  line-height: ${typographyLineHeight.h2}px;
`

const h3 = css`
  font-family: inherit;
  font-size: 16px;
  font-weight: ${typographyWeights.semibold};
  letter-spacing: 0.15px;
  line-height: ${typographyLineHeight.h3}px;
`

const small = css`
  font-family: inherit;
  font-size: 12px;
  font-weight: ${typographyWeights.regular};
  letter-spacing: 0.25px;
  line-height: ${typographyLineHeight.small}px;
`

const label = css`
  font-family: inherit;
  font-size: 14px;
  font-weight: ${typographyWeights.semibold};
  letter-spacing: 0.15px;
  line-height: ${typographyLineHeight.label}px;
`

const typographyIntents: {
  [key in Intent]: FlattenSimpleInterpolation
} = {
  body,
  h1,
  h2,
  h3,
  small,
  label,
}

export function getTypographyIntent(intent: Intent) {
  return typographyIntents[intent]
}

export const semiboldRules = () => {
  return css`
    & > strong,
    > b {
      font-weight: 600;
    }
  `
}

export const StyledTypography = styled.span<StyledTypographyProps>`
  ${({ intent = 'body' }) => typographyIntents[intent]}
  color: ${({ $color = 'gray15' }) => colors[$color]};
  font-style: ${({ italic = false }) => (italic ? 'italic' : 'inherit')};
  font-weight: ${({ weight }) => weight && typographyWeights[weight]};
  ${semiboldRules}
  ${({ breakWord }) =>
    breakWord &&
    `
  word-wrap: break-word;
  word-break: break-word;
`}
  em,
  i {
    font-style: italic;
  }
`
